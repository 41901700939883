import React from 'react';
import PropTypes from "prop-types";
import BarcodeScanner from "./BarcodeScanner";

const QRScanner = (props) => {
    const {
        onScan = () => {},
        onScanClose = () => {},
        promptText = "",
    } = props;

    return (
        <BarcodeScanner
            onScan={onScan}
            onScannerClose={onScanClose}
            prompt={promptText}
        />
    );
};

QRScanner.propTypes = {
    onScan: PropTypes.func,
    onScanClose: PropTypes.func,
    promptText: PropTypes.string,
};

export default QRScanner;