import React from "react";
import PropTypes from "prop-types";
import ProviderUtils from "src/utils/blockchain/ProviderUtils";
import BlockExplorerUtils from "src/utils/blockchain/BlockExplorerUtils";
import Modal from "src/components/Modal";
import ModalBody from "./components/ModalBody";

const TransactionSuccessModal = (props) => {
    const {
        transactionHash = "",
        isVisible = false,
        onCloseClick = () => {},
    } = props;

    const transactionURl = BlockExplorerUtils.scanUrl(
        transactionHash,
        ProviderUtils.activeBlockchain()
    );

    return (
        <Modal
            open={isVisible}
            onClose={onCloseClick}
            modalBody={
                <ModalBody
                    transactionURl={transactionURl}
                    transactionHash={transactionHash}
                    onCloseClick={onCloseClick}
                />
            }
        />
    );
};

TransactionSuccessModal.propTypes = {
    transactionHash: PropTypes.string,
    isVisible: PropTypes.bool,
    onCloseClick: PropTypes.func,
};

export default TransactionSuccessModal;