import React from "react";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import history from "../../history";
import SendIcon from '@material-ui/icons/Send';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ROUTE_COMING_SOON } from "../../constants/routes";
import { getCurrencyIcon, CURRENCY_UOWN } from "../../constants/currencies";


const DigitalAssetBalance = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const icon = getCurrencyIcon(CURRENCY_UOWN);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
    if(props.onToggle) {
    	props.onToggle(props.CUR);
    }
  };

  const handleClose = (action) => {  	
    switch(action) {
      case 'sell':
        history.push(ROUTE_COMING_SOON);
        break;
      case 'iod-report':
        history.push(ROUTE_COMING_SOON);
        break;
      default: 
        setAnchorEl(null);
    }
    if(props.onToggle) {
    	props.onToggle(props.CUR);
    }
  };

  let { CUR, balance } = props;
  const balanceCur = balance[CUR.blockchain];
  
  return (
    <>
	    <Box className={`dashboard__actions dashboard__actions-balance balance-uown`} align="left" pt={3}>        
        <Box onClick={handleClick} className={`dashboard__actions-text`} align="left" pr={1} pl={1}>
          <Typography variant="h6" align="left" gutterBottom>
              &nbsp;{CUR.name}
          </Typography>
          <Typography className="dashboard__actions-text-balance" variant="h5" align="left" gutterBottom style={{width: "70%"}}>                                
                { (balanceCur === '' || balanceCur === '?') && (
                  <CircularProgress color="secondary" />
                )}
                { balanceCur !== '?' && balanceCur >= 0 && (
                  <>$ {balanceCur}</>
                )}
          </Typography>
          <img src={icon} alt="coin-icon" className="dashboard__actions-icon-eth"/>
        </Box>
	    </Box>    	
      { false && (<Menu
        className="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={()=>{handleClose('sell')}}><SendIcon />&nbsp;Sell on iOWNX</MenuItem>
        <MenuItem onClick={()=>{handleClose('iod-report')}}><SendIcon />&nbsp;Shareholder Report</MenuItem>
      </Menu> )}
    </>
  );
}

export default DigitalAssetBalance;