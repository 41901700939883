import React from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import InfoIcon from "@material-ui/icons/Info";

import { ROUTE_NX_PAYMENT } from "../../constants/routes";
import BNUtils from "../../utils/bnUtils";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    tableWrapper: {
        overflowX: 'hidden',
    },
    table: {
        whiteSpace: 'nowrap',
    },
    thead: {
        backgroundColor: "#f5f5f5",
    },
    td: {
        fontSize: "16px",
        height: "36px",
        padding: "5px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "14px",
        },
        '&:first-child': {
            paddingLeft: "10px",
            [theme.breakpoints.down('sm')]: {
                width: '35px',
            }
        }
    },
    td_content: {
        fontSize: "14px",
        color: "#010101",
        fontWeight: 400,
        padding: "5px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "12px",
            maxWidth: 0,
        },
        '&:first-child': {
            [theme.breakpoints.down('sm')]: {
                width: '35px',
                textAlign: 'center'
            }
        }
    },
    th_td_status: {
        '@media (max-width: 300px)' : {
            display: 'none'
        }
    },
    td_link: {
        fontSize: "12px",
        color: "#0082ca",
        fontWeight: 400,
    },
    pagination: {
        fontSize: "14px",
        color: "#010101"
    },
    noWrap: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }
}));

export default function In4xPaymentHistoryTable({transactions, page, onChangePage, totalCount, rowsPerPage = 15, isPaginate = false, isPending = false, isLoading = false}) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                {!isLoading ? (
                    transactions?.length > 0 ? (
                        <>
                            <div className={classes.tableWrapper}>
                                <Table className={classes.table} size="small">
                                    <TableHead className={classes.thead}>
                                        <TableRow>
                                            <TableCell className={classes.td}></TableCell>
                                            <TableCell align={'center'} className={classes.td}>Amount</TableCell>
                                            <TableCell className={`${classes.td} ${classes.noWrap} ${classes.th_td_status}`}>Status</TableCell>
                                            <TableCell align={'right'} className={`${classes.td} ${classes.noWrap}`}>Timestamp</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {transactions.map((row, index) => (
                                            <TableRow key={`${index + 'trans'}`}>
                                                <TableCell component="th" scope="row" className={classes.td_content}>
                                                    <Link to={`${ROUTE_NX_PAYMENT}${row.REFERENCE_ID}`}>
                                                        <InfoIcon className="color-nx" />
                                                    </Link>
                                                </TableCell>
                                                <TableCell align={'center'} className={classes.td_content+" color-nx"}>
                                                    {BNUtils.ensureDecimals(row.AMOUNT, 3)}
                                                </TableCell>
                                                <TableCell className={`${classes.td_content} ${classes.noWrap} color-nx ${classes.th_td_status}`}>
                                                    {row.STATUS.toUpperCase()}
                                                </TableCell>
                                                <TableCell align={'right'} className={`${classes.td_content} ${classes.noWrap}`}>
                                                    {row.RECEIVED_AT ? moment.unix(row.RECEIVED_AT).format("hh:mm A DD-MM-YYYY") : '-'}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                            {isPaginate && (
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    component="div"
                                    count={totalCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    onChangePage={onChangePage}
                                    className={classes.pagination}
                                />
                            )}
                        </>
                    ) : (
                        <Box align={'center'} p={3}>
                            <Typography variant="subtitle1">
                                You don't have transactions
                            </Typography>
                        </Box>
                    )
                ) : (
                    <Box align={'center'} p={3}>
                        <CircularProgress/>
                    </Box>
                )}
            </Paper>
        </div>
    );
}
