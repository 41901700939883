import React, { useState } from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import moment from "moment";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import {withStyles} from "@material-ui/core";

import {NormalizeUtil} from "../../utils/normalize";
import { getCurrencyName } from "../../constants/currencies";
import { TRANSACTION_STATUS_FAILED, TRANSACTION_STATUS_SUCCESS } from "../../constants/transactionStatus";
import CustomSnackbar from "../../components/CustomSnackbar";
import BlockchainLabel from "../../components/BlockchainLabel";
import {openLink} from '../../utils/linksOpenPolyfill';
import { CopyToClipboard } from "react-copy-to-clipboard";
import BlockExplorerUtils from '../../utils/blockchain/BlockExplorerUtils';
import ProviderUtils from '../../utils/blockchain/ProviderUtils';
import GasUtils from "../../utils/blockchain/GasUtils";

const styles = () => ({
    title: {
      marginBottom: "1.5rem",
    },
    box: {
        color: "rgba(0,0,0,0.54)",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    span: {
        fontSize: "16px",
        fontWeight: "600",
    },
    link: {
        wordBreak: "break-all",
        color: "#0082ca",
        lineHeight: "1.5",
    },
    text: {
        fontSize: "16px",
        fontWeight: "400",
    },
})

const TransactionInfo = (props) => {
    const getTransactionStatusName = status => status === TRANSACTION_STATUS_SUCCESS ? "Success" : status === TRANSACTION_STATUS_FAILED ? "Failed" : "Pending";
    const getTransactionStatusLevel = status => status === TRANSACTION_STATUS_SUCCESS? "success" : status === TRANSACTION_STATUS_FAILED ? "error" : "info";
    const goBack = () => {
        props.history.goBack();
    };
    const {blockchain: {wallet, pending, transactions, sendingCurrencyObj}, classes} = props;
    const transactionsArr = props.match.params.pending ? pending : transactions;
    const transactionId = props.match.params.id;
    const trans = transactionsArr[transactionId];
    if (!trans || !trans.hash || !wallet || Object.keys(wallet).length === 0) {
        props.history.goBack();
    }
    const [openAlert, setAlertStatus] = useState(false);
    const showAlert = () => setAlertStatus(true);
    const hideAlert = () => setAlertStatus(false);
    const blockchain = ProviderUtils.activeBlockchain();
    const scanUrl = trans && trans.hash ? BlockExplorerUtils.scanUrl(trans.hash, blockchain): '';
    const gasUnits = GasUtils.gasUnitsForBlockchain() ? GasUtils.gasUnitsForBlockchain().toUpperCase() : '';
    const isNft = sendingCurrencyObj.type === 'erc721';

    return (
        <Container className="home" component="main" maxWidth="sm">
            <Grid container spacing={1} className="centered-grid">
                <Grid item xs={12}>
                    <BlockchainLabel />
                    <Typography variant="h5" align="center" className={classes.title}>
                        Transaction Info
                    </Typography>
                </Grid>
                {trans && (
                    <Grid item xs={12} className="left-grid">
                        <Alert variant="outlined" severity={getTransactionStatusLevel(trans.status)}>Transaction {getTransactionStatusName(trans.status)}</Alert>
                    </Grid>
                )}
                {trans && (
                    <Grid item xs={12} className="left-grid">
                        <Box mb={2} className={classes.box}>
                            <span className={classes.span}>Hash: </span>
                            <a className={classes.link} 
                                href={scanUrl} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                onClick={(e)=> openLink(e,scanUrl)}
                            >
                                {trans.hash}
                            </a>
                        </Box>

                        <Box mb={2} className={classes.box}>
                            <span className={classes.span}>Recipient: </span>
                            <CopyToClipboard text={trans.to} onCopy={() => showAlert()}>
                                <span className={classes.link}>
                                    {trans.to}
                                </span>
                            </CopyToClipboard>
                        </Box> 

                        <Box mb={2} className={classes.box}>
                            <span className={classes.span}>Sender: </span>
                            <CopyToClipboard text={trans.from} onCopy={() => showAlert()}>
                            <span className={classes.link}>
                                {trans.from}
                            </span>
                            </CopyToClipboard>
                        </Box>

                        { !isNft && (<Box mb={2} className={classes.box}>
                            <span className={classes.span}>Amount: </span>
                            <span className={classes.text}>
                                {NormalizeUtil.formatBigValues(trans.value)} {trans.value !== 0 ? getCurrencyName(trans.currency) : ""}
                            </span>
                        </Box>)}

                        { isNft && !trans.tokenID && (<Box mb={2} className={classes.box}>
                            <span className={classes.span}>Token ID: </span>
                            <span className={classes.text}>
                               # {NormalizeUtil.formatBigValues(trans.value)}
                            </span>
                        </Box>)}

                        { isNft && trans.tokenID && (<Box mb={2} className={classes.box}>
                            <span className={classes.span}>Token ID: </span>
                            <span className={classes.text}>
                               # {trans.tokenID}
                            </span>
                        </Box>)}

                        {trans.fee !== undefined && (
                            <Box mb={2} className={classes.box}>
                                <span className={classes.span}>Transaction fee: </span>
                                <span className={classes.text}>
                                    {trans.fee} {gasUnits}
                                </span>
                            </Box> 
                        )}

                        <Box mb={2} className={classes.box}>
                            <span className={classes.span}>Confirmations: </span>
                            <span className={classes.text}>
                                {trans.confirmations || 0}
                            </span>
                        </Box>

                        {trans.timeStamp && (
                            <Box mb={2} className={classes.box}>
                                <span className={classes.span}>Timestamp: </span>
                                <span className={classes.text}>
                                    {moment.unix(trans.timeStamp).format("hh:mm A DD-MM-YYYY")}
                                </span>
                            </Box>
                        )}
                        { trans.nonce && (
                            <Box mb={2} className={classes.box}>
                                <span className={classes.span}>Sequence: </span>
                                <span className={classes.text}>
                                    {trans.nonce || '?'}
                                </span>
                            </Box>
                        )}
                    </Grid>
                )}
                <Button className="spaced__btn" fullWidth variant="contained" onClick={goBack}>
                    Back
                </Button>
            </Grid>
            <CustomSnackbar
                    anchorOrigin={{horizontal: "center", vertical: "bottom"}}
                    variant="success"
                    open={openAlert}
                    message={"Copied to clipboard"}
                    onClose={hideAlert}
                />
        </Container>
    );
};

const mapState2props = state => ({
    blockchain: state.blockchain
});

export default compose(
    withStyles(styles),
    connect(mapState2props)
)(TransactionInfo);
