import React from "react";
import { Grid, Typography, CircularProgress } from "@material-ui/core";

const AssetLoaderProgress = (props) => {
	return (
	    <Grid item xs={12} className="centered-grid">	         		
     		<CircularProgress />
     		<Typography color="primary" align="center" gutterBottom className="spaced-top">Loading data...</Typography>
     	</Grid>
	);
}

export default AssetLoaderProgress;