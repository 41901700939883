import React from "react"
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { List, ListItem, ListItemText, Divider } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import history from "../../history";

import { ROUTE_SIGNUP, ROUTE_SIGNIN_CLEAR_DATA } from "../../constants/routes";
import IownIcon from 'src/components/IownIcon';

const NewAccountSelection = (props) => {
    return (
        <Container component="main" maxWidth="sm">
        	<Grid
                container
                direction={'column'}
                justifyContent="space-between"
                wrap={'nowrap'}
                className="centered-grid spaced-top"
            >
                <IownIcon />
                <Typography variant="h5" color="primary" align="center" gutterBottom>Get Started</Typography>
            	<List component="nav" aria-label="accounts">
            		<ListItem button>
            			<ListItemText primary="Login by Email and Secret String" color="primary" onClick={() => { history.push(ROUTE_SIGNIN_CLEAR_DATA)} }/>
            		</ListItem>
            		<Divider />
            		<ListItem button>
            			<ListItemText primary="Create a New Wallet" color="primary" onClick={() => { history.push(ROUTE_SIGNUP)} }/>
            		</ListItem>
            		<Divider />
            	</List>
            </Grid>
            <Button className="spaced-top" onClick={() => props.history.goBack()} fullWidth variant="contained">Back</Button>
        </Container>
    )
}

export default NewAccountSelection;