import EtherUtil from "src/utils/ethers"

export const transformError = (errorObject) => {
    const { error, code } = errorObject;
    const message = error?.message;
    const transformed = errorObject.toString();

    if (message) return {
        helperText: message,
    };

    if (code === "REPLACEMENT_UNDERPRICED") return {
        helperText: "You have a stuck transaction, and are attempting to replace it with a new one with lower fee, you need to wait for the stuck transaction.",
    };

    if (transformed.includes("Gas price is too low")) {
        return {
            helperText: transformed,
        }
    }

    return {
        helperText: "There was an error during transaction confirm. Let us know, or try again later.",
    };
};

export const submitWithdrawal = async (params) => {
    const { newTokenWithdrawAllTransaction } = EtherUtil;
    const {
        passwordHash,
        stakingAddress,
        currency,
        withdrawAmount,
        sendingTrxType,
        onError = val => val,
        checkWalletPassword = async val => val,
        loginWithWallet = async val => val,
        onSuccess = val => val,
        addPendingTransactionToBackend = val => val,
    } = params;

    try {
        const wallet = await checkWalletPassword(passwordHash);
        const { success } = await loginWithWallet(wallet, passwordHash);
        if (!success) return;
        const transactionReceipt = await newTokenWithdrawAllTransaction(wallet, currency, stakingAddress);
        const { blockchain, network, hash } = transactionReceipt;
        addPendingTransactionToBackend(
            wallet.address,
            {
                blockchain,
                network,
                hash,
                receiver: stakingAddress,
                currency,
                amount: withdrawAmount,
                sendingTrxType,
            }
        );
        EtherUtil.monitorPendingTransaction(transactionReceipt, () => {
            onSuccess(hash);
        });
    } catch (errorObject) {
        const nextError = transformError(errorObject);
        onError(nextError);
    }
}