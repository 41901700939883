import React, {
    createContext,
    useState,
    useEffect,
    useCallback,
} from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
    checkWalletPassword,
    loginWithWallet
} from "src/ducks/signin";

import {
    changeSendingTrxType,
    changeSendingAmount,
    changeSendingAddress,
    changeSendingCurrency,
} from "src/ducks/blockchain";
import { addPendingTransactionToBackend } from "src/ducks/blockchain";
import { resetGas } from "src/ducks/blockchainGas";
import {
    getStakingData
} from "src/ducks/staking";

export const ReduxBridge = createContext({
    storeData: {},
    actions: {},
});

const ReduxBridgeContext = (props) => {
    // Staking only staking state being parsed since not clear requirements
    const {
        children,
        staking,
        blockchain,
        blockchainGas,
        resetGas,
        dashboard,
        addPendingTransactionToBackend,
        getStakingData,
        checkWalletPassword,
        loginWithWallet,
        changeSendingTrxType,
        changeSendingAmount,
        changeSendingAddress,
        changeSendingCurrency
        } = props;

    const [reduxData, setReduxData] = useState(null);

    const changeGasCalculationConfig = useCallback((params) => {
        const {
            transactionType = "",
            sendingAddress = "",
            amount = 0,
            currencyName,
            currencyObject,
        } = params;
        changeSendingTrxType(transactionType);
        changeSendingCurrency(currencyName, currencyObject);
        changeSendingAmount(amount);
        changeSendingAddress(sendingAddress);
    }, [changeSendingAddress, changeSendingAmount, changeSendingCurrency, changeSendingTrxType]);

    useEffect(() => {
        setReduxData({
            storeData: {
                ...staking,
                ...blockchain,
                blockchainGas,
                dashboard
            },
            actions: {
                checkWalletPassword,
                loginWithWallet,
                changeGasCalculationConfig,
                getStakingData,
                resetGas,
                addPendingTransactionToBackend
            }
        });
    }, [
        addPendingTransactionToBackend,
        blockchain,
        blockchainGas,
        changeGasCalculationConfig,
        checkWalletPassword,
        dashboard,
        getStakingData,
        loginWithWallet,
        resetGas,
        staking
    ]);

    return (
        <ReduxBridge.Provider value={{ ...reduxData }}>
            { children }
        </ReduxBridge.Provider>
    )
};

const mapStateToProps = (state) => ({ ...state });
const mapDispatchToProps = {
    checkWalletPassword,
    loginWithWallet,
    changeSendingTrxType,
    changeSendingAmount,
    changeSendingAddress,
    changeSendingCurrency,
    addPendingTransactionToBackend,
    getStakingData,
    resetGas
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(ReduxBridgeContext);