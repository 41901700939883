import React from "react";
import PropTypes from "prop-types";
import TextField from '@material-ui/core/TextField';

const AddressField = (props) => {
    const {
    	label, value, error, helperText, disabled,
        onChange = value => value,
    } = props;

    return (
        <TextField
            id={"dashboard-sending-address"}
            label={label}
            error={error}
            value={value}
            helperText={helperText}
            onChange={e => onChange(e.target.value)}
            required
            fullWidth
            disabled={disabled}
            margin="normal"
            variant="outlined"
        />
    )
};

AddressField.prototype = {
    onChange: PropTypes.func
}
export default AddressField;