import React from "react";
import {connect} from "react-redux";
import { getFormValues, isValid } from "redux-form";

import { Container, Typography, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

import SAMobileStepper from "../../components/SAMobileStepper";
import SellTokensForm from '../../components/Forms/SellTokensForm';
import SenderBankForm from '../../components/Forms/SenderBankForm';
import SendTokens from "../../components/SendTokens";

import { 
	getIn4xDepositInfo, 
	getIn4xBank, 
	setIn4xBank, 
	createIn4xWireTransfer, 
	getExchangeRate,
	getFeeSchedule
} from '../../ducks/in4x';
import {
	changeSendingCurrency,
	changeSendingAmount,
	changeSendingAddress
} from '../../ducks/blockchain';
import {
	setSellTokensValues,
	setSenderBankValues,
	setWireTransferValues,
	resetForms
} from '../../ducks/FormData';
import { 
	ROUTE_DEFAULT_LOGGEDIN, 
	ROUTE_WALLET_ASSETS, 
	ROUTE_E_WALLET_BANK_WITHDRAW,
	ROUTE_NX_WIRETRANSFER,
	ASSET_TYPE_LIQUID,
	ROUTE_NX_TRANSACTION_HISTORY
} from '../../constants/routes';
import { IN4X_METHOD_WIRE } from '../../constants/in4xConstants';

class WireTransferWithdraw extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeStep: 0,
			total: '',
			showConfirmation: false,
			minBalChecked: false,
			showEth: true,
			showErr: '',
			receiveAddress: '',
			activeCur: '',
			activeBc: '',
		};
	}

	componentDidMount = async() => {
		const { pathname } = this.props.history.location;
		let urlSplit = pathname.substring(pathname.indexOf(ROUTE_E_WALLET_BANK_WITHDRAW) + ROUTE_E_WALLET_BANK_WITHDRAW.length).split("/");
		let activeBc = urlSplit[0];
		let activeCur = urlSplit[1];
  		if(activeCur) {
  			this.setState({activeCurrency: activeCur, activeBc});
  		} else if(this.props.in4x.activeCurrency) {
  			this.setState({activeCurrency: this.props.in4x.activeCurrency.name, activeBc: this.props.in4x.activeCurrency.blockchain});
  			activeCur = this.props.in4x.activeCurrency.name;
  		} else {
  			this.props.history.push(ROUTE_DEFAULT_LOGGEDIN);
  			return;
  		}
  		if(!activeCur) {
  			return;
  		}
  		this.props.setSellTokensValues({'payCur': activeCur.toUpperCase(), 'recCur': 'USD'});
  		this.props.getFeeSchedule();
  		await this.props.getIn4xBank();
  		this.setState({receiveAddress: this.props.in4x.bankInfo.WITHDRAW_ADDRESS});
  	}

  	componentWillUnmount = () => {
		this.props.resetForms();
	}

	nextStep = () => {
		switch(this.state.activeStep) {
			case 0: 
				return this.showSellTokens();
			case 1:
				return this.processSellTokens();
			case 2:
				return this.processSenderBank();
			case 4:
				if(this.props.in4x.transferInfo) {
					this.props.history.push(ROUTE_NX_WIRETRANSFER+this.props.in4x.transferInfo.REFERENCE_ID);	
				} else {
					this.props.history.push(ROUTE_NX_TRANSACTION_HISTORY);
				}
				//no break;
				// eslint-disable-next-line no-fallthrough
			default:
				this.setState((state) => ({ activeStep: state.activeStep + 1 }));
		}
	}

	prevStep = () => {
		if(this.state.activeStep === 0) {
			this.props.history.push(ROUTE_WALLET_ASSETS + ASSET_TYPE_LIQUID);
		}
		else {
			this.setState((state) => ({ activeStep: state.activeStep - 1 }));
		}
	}

	showSellTokens = async() => {
		this.setState((state) => ({ activeStep: state.activeStep + 1 }));
		this.props.getExchangeRate('USD', this.state.activeCurrency);
	}

	processSellTokens = () => {
		const { sellFormValues } = this.props;
		const { activeCurrency } = this.state;
		const { balance } = this.props.blockchain;
		if(this.props.sellFormValid && sellFormValues && sellFormValues.total > 0) {
			const total = Number(sellFormValues.total).toFixed(6);
			if(total > Number(balance[activeCurrency])) {
				this.setState({showErr: 'Insufficient balance' });
				return;
			}
			this.setState({total: total, showErr: ''});
			this.props.setSellTokensValues(sellFormValues);
			this.props.setSenderBankValues(this.props.in4x.bankInfo);
			this.props.setWireTransferValues({
				amountBase: Number(sellFormValues.amount),
				amountQuote: Number(sellFormValues.receiveAmount),
				amountEth: 0,
				eth: 0,
				payCur: sellFormValues.payCur,
				recCur: 'usd',
				direction: 'out',
				total: total,
				feeAmount: Number(sellFormValues.fee),
				feeEth: 0,
			});
		}
		this.selectCurrency();
	}

	processSenderBank = async() => {
		if(JSON.stringify(this.props.formValues) !== JSON.stringify(this.props.in4x.bankInfo)) {
			this.props.setSenderBankValues(this.props.formValues);
			await this.props.setIn4xBank(this.props.formValues);
		}
		const { receiveAddress, total } = this.state;
		this.props.changeSendingAmount(total);
		this.props.changeSendingAddress(receiveAddress);
		this.setState((state) => ({ activeStep: state.activeStep + 1 }));
	}

	selectCurrency = () => {
		const { activeCurrency } = this.state;
		if(!this.props.dashboard.assetBalancesLoaded) {
			return;
		}
		this.props.dashboard.userAssetTypes.forEach((v, idx) => {
			if(v.name.toLowerCase() === activeCurrency.toLowerCase()) {
				this.props.changeSendingCurrency(v.name, v);
				return false;
			}
		});
		this.setState((state) => ({ activeStep: state.activeStep + 1 }));
	}

	createWireTransfer = async(trxHash) => {
		let { wireTransferValues } = this.props;
		wireTransferValues.trxHash = trxHash;
		if(wireTransferValues && wireTransferValues.total) {
			await this.props.createIn4xWireTransfer(wireTransferValues);
			if(!this.props.in4x.error) {
				this.setState((state) => ({ activeStep: state.activeStep + 1 }));
			} else {
				this.setState({catchErr: this.props.in4x.error});
			}
		}
	}

	transactionFail = () => {
	  	this.setState({catchErr: 'Failed to submit blockchain transaction for withdrawal'});
	}

	render() {
    	if (this.state.catchErr) throw this.state.catchErr;
    	const { sellFormValid, sellFormValues, formValid } = this.props;
    	const { activeStep, showErr, activeBc } = this.state;
    	const {
    		in4xTransferLoading,
    		exRate, 
    		in4xRateLoading,
    		in4xFeesLoading,
    		in4xFees,
    		in4xLimits,
    	} = this.props.in4x;
    	const disablePrev = activeStep > 3;
    	const disabledNext = (activeStep === 1 && (!sellFormValid  || !sellFormValues)) ||
    						 (activeStep === 2 && !formValid) ||
    						 activeStep === 3 || 
    						 in4xTransferLoading || in4xFeesLoading;
    	return (
    		<Container className="home" component="main" maxWidth="sm">
    			<Typography variant="h5" align="center" gutterBottom className="color-nx">Wire Transfer Withdrawal</Typography>
    			<Grid container spacing={1} className="centered-grid">
	    			{activeStep === 0 && (
						<Grid item xs={12}>
	    					<Typography align="left" gutterBottom style={{width: '100%'}}>Balance withdrawals are processed by:</Typography>
	    					<Typography variant="h5" className="color-nx" style={{marginTop: "1rem", marginBottom: "1rem"}}>IN4X Global Bank Account</Typography>
	    					<Typography align="left" gutterBottom style={{marginTop: "0.5rem", width: '100%'}}>Please keep the following in mind:</Typography>
				            <Typography align="left" gutterBottom>
				                <PriorityHighIcon color="secondary" fontSize="small"/> Your withdrawal is subject to bank fees (including your own bank).
				            </Typography>
				            <Typography align="left" gutterBottom>
				                <PriorityHighIcon color="secondary" fontSize="small"/> IN4X Global charges apply.
				            </Typography>
				            <Typography align="left" gutterBottom>
			                   <PriorityHighIcon color="secondary" fontSize="small"/> Withdrawals take several business days depending on your country and the corresponding banks
		  	              	</Typography>
			            </Grid>
	    			)}
	    			{ activeStep === 1 && (
						<Grid item xs={12}>
							<img src="/img/nx-logo-wide.svg" alt={'IN4X Global'} style={{marginTop: '10px', marginBottom: '20px'}}/>
							<SellTokensForm
								blockchain={activeBc} 
								quote={'USD'} 
								rate={exRate.high}
								fees={in4xFees}
								limits={in4xLimits}
								isLoading={in4xRateLoading || in4xFeesLoading}
								handleSubmit={this.processSellTokens}
								handleCancel={this.prevStep} 
								method={IN4X_METHOD_WIRE}
								hideButtons
							/>
							{ showErr && (
								<Alert severity="error" variant="outlined">{showErr}</Alert>
							)}
						</Grid>
	    			)}
	    			{ activeStep === 2 && (
		    				<Grid item xs={12}>
								<img src="/img/nx-logo-wide.svg" alt={'IN4X Global'} style={{marginTop: '10px', marginBottom: '20px'}}/>
								<Typography variant="h5" className="color-nx" style={{marginTop: "1rem", marginBottom: "1rem"}}>Your Bank Info</Typography>
								<SenderBankForm />
							</Grid>
	    			)}
	    			{ activeStep === 3 && (
	    				<Grid item xs={12} className="centered-grid forced-nx">
		    				<Typography className="color-nx" align="center" gutterBottom>Submit Tokens to Withdrawal Wallet</Typography>
		    				<SendTokens hideBack disabled
		        				onTransactionSuccess={(trxHash) => { this.createWireTransfer(trxHash); }} 
		        				onTransactionFail={this.transactionFail} 
		        				onTransactionBack={this.prevStep} />	
	    				</Grid>
	    			)}
					{ activeStep === 4 && (
						<Grid item xs={12}>
					    	<Alert variant="outlined" severity="success">Withdrawal Request Created!</Alert>
					    	<Typography align="left">Funds will be credited to your bank account as soon as your transfer is received.</Typography>
				    	</Grid>
					)}
					<SAMobileStepper maxSteps={5} activeStep={this.state.activeStep} onNext={this.nextStep} onPrev={this.prevStep} disablePrev={disablePrev} disabledNext={disabledNext} />
			    </Grid>
    		</Container>
    	);
    }
}

const mapState2props = state => ({
    in4x: state.in4x,
    blockchain: state.blockchain,
    dashboard: state.dashboard,
    formValues: getFormValues("sender-bank-form")(state),
  	formValid: isValid("sender-bank-form")(state),
  	sellFormValues: getFormValues('sell-tokens')(state),
  	sellFormValid: isValid("sell-tokens")(state),
  	wireTransferValues: state.FormData.WireTransfer
});
const mapDispatch2props = {
	getIn4xDepositInfo,
	getIn4xBank,
	setIn4xBank,
	createIn4xWireTransfer,
	getExchangeRate,
	getFeeSchedule,
	setSellTokensValues,
	setSenderBankValues,
	setWireTransferValues,
	changeSendingCurrency,
	changeSendingAmount,
	changeSendingAddress,
	resetForms
};

export default connect(mapState2props, mapDispatch2props)(WireTransferWithdraw);