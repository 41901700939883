import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import {amber, green} from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import {makeStyles} from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';


const useStyles = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const CustomSnackbar = (props) => {
    const classes = useStyles();
    const {className, message, onClose, variant = 'error', ...other} = props;

    return (
        <Snackbar
            onClose={onClose}
            TransitionComponent={Slide}
            {...other}
        >
            <SnackbarContent
                className={`${classes[variant]} ${className}`}
                aria-describedby="client-snackbar"
                message={
                    <span className={classes.message}>{message}</span>
                }
                action={[
                    <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
                        <CloseIcon className={classes.icon}/>
                    </IconButton>,
                ]}
            />
        </Snackbar>
    );
};

CustomSnackbar.propTypes = {
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

export default CustomSnackbar;