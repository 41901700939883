import React from "react";
import PropTypes from "prop-types";
import {
    Box,
    CircularProgress,
    Typography
} from "@material-ui/core";
import { getCurrencyIcon } from "src/constants/currencies";

const BalanceBox = (props) => {
    const {
        ticker = "",
        balance = "",
    } = props;

    const icon = getCurrencyIcon(ticker);

    return (
        <Box
            className={"dashboard__actions dashboard__actions-balance"}
            align="left"
            pt={3}
        >
            <Box
                className={`dashboard__actions-text`}
                align="left"
                pr={1}
                pl={1}
            >
                <Typography variant="h6" align="left">
                    { ticker }
                </Typography>
                <img
                    src={icon}
                    alt="coin-icon"
                    className="dashboard__actions-icon-eth"
                />
                <Typography
                    className="dashboard__actions-text-balance"
                    variant="h5"
                    align="left"
                    gutterBottom
                >
                    {!balance && (<CircularProgress color="secondary" />)}
                    <div className={"balance"}>
                        { balance }
                    </div>
                </Typography>
            </Box>
        </Box>
    )
};

BalanceBox.propTypes = {
    ticker: PropTypes.string,
    balance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default BalanceBox;