import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
    Container,
    Grid,
    Typography,
    Button,
    debounce,
} from "@material-ui/core";
import { submitSafeTransferFrom } from './utils';
import SendNftFields from "./components/send-nft-fields";
import { TRX_TYPE_SAFE_TRANSFER_FROM, TRX_TYPE_NFT_MULTI_TRANSFER } from "src/constants/blockchain";
import { ROUTE_WALLET_ASSETS, ASSET_TYPE_NFT } from "src/constants/routes";
import BlockchainTransactionConfirm from "src/common/blockchain-transaction-confirm";
import ProcessingAlert from "src/common/processing-alert";
import BlockchainLabel from "src/common/blockchain-label";
import TransactionCreatedModal from "src/views/modals/TransactionCreatedModal";
import TransactionFailedModal from "src/views/modals/TransactionFailedModal";
import TransactionFeeConfiguration from "src/components/TransactionFeeConfiguration";
import TransactionStatusBlock from "src/components/TransactionStatusBlock";
import { resetGas } from "src/ducks/blockchainGas";
import {
  changeSendingAmount,
  changeSendingAddress,
  changeSendingTrxType,
  clearTransactionData,
  addTransaction,
  removePending,
  removePendingListFromBackend,
  addPendingTransactionToBackend,
} from "src/ducks/blockchain";

const SendNft = props => {

  const {
    history,
    match,
    dashboard: {
      assetBalancesLoading,
      userAssetTypesLoading
    },
    blockchain: {
      sendingAddress,
      sendingAmount,
      sendingTrxType,
      sendingCurrency,
      sendingCurrencyObj,
      gasFee,
      submitEnabled
    },
    blockchainGas: {
      gasReady,
      estimateGas
    },
    changeSendingAddress,
    changeSendingAmount,
    changeSendingTrxType,
    clearTransactionData,
    resetGas,
  } = props;

  const [processingMessage, setProcessingMessage] = useState(null);
  const [isCheckingPassword, setIsCheckingPassword] = useState(false);
  const [isShowSuccessModal, setShowSuccessModal] = useState(false);
  const [caughtError, setCaughtError] = useState(null);
  const [trxHash, setTrxHash] = useState("");
  const { params: { tokenId } } = match;
  const isMultiSend = tokenId.includes("m");
  const nextDefaultIds = isMultiSend ? tokenId.replace("m", "") : tokenId;

  useEffect(() => {
    if (!sendingCurrency) {
      history.goBack();
    }
    if (!isMultiSend && sendingTrxType !== TRX_TYPE_SAFE_TRANSFER_FROM) {
      changeSendingTrxType(TRX_TYPE_SAFE_TRANSFER_FROM);
    }

    if(isMultiSend && sendingTrxType !== TRX_TYPE_NFT_MULTI_TRANSFER) {
      changeSendingTrxType(TRX_TYPE_NFT_MULTI_TRANSFER);
    }
  }, [changeSendingTrxType, history, isMultiSend, sendingCurrency, sendingTrxType]);

  useEffect(() => {
    changeSendingAmount(nextDefaultIds, isMultiSend);
  }, [changeSendingAmount, isMultiSend, nextDefaultIds]);

  const handleBack = () => {
    clearTransactionData();
    resetGas();
    history.goBack();
  }

  const handleFailedModal = () => {
    setCaughtError(null);
    if (props.onTransactionFail) {
      props.onTransactionFail();
    } else {
      history.push(ROUTE_WALLET_ASSETS + ASSET_TYPE_NFT);
    }
  }

  const handleSuccessModal = () => {
    setShowSuccessModal(false);
    if (props.onTransactionSuccess) {
      props.onTransactionSuccess(trxHash);
    } else {
      history.push(ROUTE_WALLET_ASSETS + ASSET_TYPE_NFT);
    }
  }

  const handleSubmitTransaction = async() => {
    try {
	    setProcessingMessage('Processing NFT Transaction...');
	    setIsCheckingPassword(false);
	    const txHash = await submitSafeTransferFrom(
            {
              ...props,
              isMultiSend
            },
            () => {
            props.clearTransactionData();
            setShowSuccessModal(true);
            setProcessingMessage(null);
          });
	    setProcessingMessage('Waiting for mining...');
	    setTrxHash(txHash);
  	} catch(error) {
  		setCaughtError(error.message || error);
  		setProcessingMessage(null);
  	}
  }

  const updateSendingId = debounce((value) => changeSendingAmount(value, isMultiSend), 550);
  const validationError = sendingAmount.error || sendingAddress.error || gasFee.error;
  const isSubmitAllowed = submitEnabled && gasReady && !validationError && !!estimateGas;

  return (
    <Container className="home" component="main" maxWidth="sm">
			<Grid container spacing={1} justifyContent="center" pt={3}>
				{!isCheckingPassword && (
					<Grid item xs={12} className="centered-grid spaced-top">
	            		<BlockchainLabel />
		            	<Typography variant="h5" align="center" gutterBottom color="primary">Send {sendingCurrency}</Typography>
		            </Grid>
				)}
        {!processingMessage && (
        	<Grid item xs={12} className="centered-grid">
          	{ !isCheckingPassword && (
              <SendNftFields
                inputValue={nextDefaultIds}
                isMultiSend={isMultiSend}
                tokenId={sendingAmount}
            	onChangeId={updateSendingId}
            	sendingAddress={sendingAddress}
                sendingCurrencyObj={sendingCurrencyObj}
            	onChangeAddress={changeSendingAddress}
          	  />
            )}
          	<TransactionFeeConfiguration
                keepHiddenOn={isCheckingPassword}
                transformGasPrice={isMultiSend}
            />
          	{!isCheckingPassword && (
              <>
              <Button color="primary" className="spaced__btn" fullWidth disabled={!isSubmitAllowed}
                onClick={() => setIsCheckingPassword(true) } variant="contained">
                Send Transaction
              </Button>
              <Button color="default" className="spaced__btn" fullWidth variant="contained" onClick={handleBack}>
                Back
              </Button>
              </>
            )}
          </Grid>
        )}
        {(assetBalancesLoading || userAssetTypesLoading) && (
            <ProcessingAlert message={"Loading blockchain assets..."} />
        )}
        { isCheckingPassword && (
            <BlockchainTransactionConfirm onBack={() => {setIsCheckingPassword(false)}} onValidPassword={handleSubmitTransaction} />
        )}
        { processingMessage && (
            <ProcessingAlert message={processingMessage} />
        )}
        { trxHash && processingMessage && (
        	<TransactionStatusBlock hash={trxHash} />
        )}
        <TransactionCreatedModal openOn={isShowSuccessModal} onClose={handleSuccessModal} trxhash={trxHash} />
        <TransactionFailedModal openOn={!!caughtError} onClose={handleFailedModal} errorText={caughtError} />
    </Grid>
  </Container>
  );
}


const mapState2props = state => ({
  dashboard: state.dashboard,
  blockchain: state.blockchain,
  blockchainGas: state.blockchainGas
});

const mapDispatch2props = {
  changeSendingAddress,
  changeSendingAmount,
  changeSendingTrxType,
  clearTransactionData,
  addTransaction,
  removePending,
  removePendingListFromBackend,
  addPendingTransactionToBackend,
  resetGas,
};

export default compose(
  connect(mapState2props, mapDispatch2props)
)(SendNft);