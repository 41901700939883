import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import Alert from '@material-ui/lab/Alert';
import { Grid, TextField, InputAdornment, Typography } from "@material-ui/core";

import {
  changeSendingCurrency,
  clearTransactionData
} from "../../ducks/blockchain";
import {
  getExchangeRate
} from "../../ducks/in4x";

import TokenAssetBalance from "./TokenAssetBalance";
import DigitalAssetBalance from "./DigitalAssetBalance";
import NftAssetBalance from "./NftAssetBalance";
import UownAssetBalance from "./UownAssetBalance";

import In4xKycActivate from "../In4xKyc/In4xKyActivate";
import In4xKyc from "../In4xKyc";

import { CURRENCY_USD, CURRENCY_IOWN } from "../../constants/currencies";
import { BLOCKCHAIN_ETH } from "../../constants/blockchain";
import { ASSET_TYPE_SPOT, ASSET_TYPE_LIQUID, ASSET_TYPE_DIGITAL, ASSET_TYPE_NFT } from "../../constants/routes";
import EtherUtil from "../../utils/ethers";
import ProviderUtils from "../../utils/blockchain/ProviderUtils";
import { exRateIdxKey } from "../../utils/in4xUtils";

class Balances extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exchIOWN: 0,
      catchErr: null,
    }
  }

  componentWillMount = async () => {
    const key = exRateIdxKey(CURRENCY_USD, CURRENCY_IOWN);
    if (!this.props.in4x.exRates[key]) {
      await this.props.getExchangeRate(CURRENCY_USD, CURRENCY_IOWN);
    }
    if (this.props.in4x.exRates[key] && this.props.in4x.exRates[key].high) {
      this.setState({ exchIOWN: this.props.in4x.exRates[key].high });
    }
  }

  handleToggle = async (currencyObj) => {
    await this.changeCurrency(currencyObj);
  };

  changeCurrency = async currencyObj => {
    try {
      if (currencyObj.name !== this.props.blockchain.sendingCurrency) {
        this.props.clearTransactionData();
        this.props.changeSendingCurrency(currencyObj.name, currencyObj);
      }
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  getBalance = CUR => {
    const { balance } = this.props.blockchain;
    return CUR && balance[CUR.blockchain] && balance[CUR.blockchain][CUR.name] >= 0 ? balance[CUR.blockchain][CUR.name] : '?';
  }

  render() {
    if (this.state.catchErr) throw this.state.catchErr;
    const { userAssetTypes, kycLevel, userAssetTypesLoaded } = this.props.dashboard;
    const { currentAssetType, bcChanges } = this.props;
    const { balance } = this.props.blockchain;
    const { exchIOWN } = this.state;
    const activeBlockchain = ProviderUtils.activeBlockchain();
    const usdTotal = EtherUtil.balanceInUsd(balance, activeBlockchain, exchIOWN);

    return (
      <div id="balance-block" data-changes={bcChanges}>
        { currentAssetType === ASSET_TYPE_LIQUID && exchIOWN > 0  && usdTotal >= 0 && (
          <Grid container className="balance-total">
            <Grid item xs={6}>
              <TextField
                color="primary"
                label="USD Value"
                id="balance-total-usd"
                className="wallet-balance-input"
                value={usdTotal.toLocaleString()}
                InputProps={{
                  startAdornment: <InputAdornment color="primary" position="start">$</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                color="primary"
                label="iOWN Rate"
                id="iown-rate"
                className="wallet-balance-input"
                value={Number(exchIOWN).toFixed(4)}
                InputProps={{
                  startAdornment: <InputAdornment color="primary" position="start">$</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
        )}
        { currentAssetType === ASSET_TYPE_NFT && (
          <Grid item xs={12} className="centered-grid">
            <Typography variant="h6" color="primary">Your NFT Portfolio</Typography>
          </Grid>
        )}
        { userAssetTypes && userAssetTypes.map((CUR, idx) =>
            (CUR.asset === currentAssetType && (
              <span key={idx} className={"balance-block-"+currentAssetType}>
                {/*{CUR.blockchain === 'in4x' && kycLevel !== false &&  (*/}
                {/*  <EWalletAssetBalance CUR={CUR} balance={this.getBalance(CUR)} onToggle={this.handleToggle}/>*/}
                {/*)}*/}
                {CUR.blockchain !== 'error' && CUR.asset === ASSET_TYPE_SPOT && CUR.blockchain === BLOCKCHAIN_ETH && (
                  <TokenAssetBalance CUR={CUR} balance={this.getBalance(CUR)} onToggle={this.handleToggle} enableMenus />
                )}
                {CUR.blockchain !== 'error' && CUR.asset === ASSET_TYPE_LIQUID && CUR.blockchain === activeBlockchain && (
                  <TokenAssetBalance CUR={CUR} balance={this.getBalance(CUR)} onToggle={this.handleToggle} className={"bal-"+CUR.name} enableMenus />
                )}
                {CUR.blockchain !== 'error' && CUR.asset === ASSET_TYPE_DIGITAL && CUR.name === 'uown' && (
                  <UownAssetBalance CUR={CUR} balance={this.getBalance(CUR)} onToggle={this.handleToggle}/>
                )}
                {CUR.blockchain !== 'error' && CUR.asset === ASSET_TYPE_DIGITAL && CUR.name !== 'uown' && (
                  <DigitalAssetBalance CUR={CUR} balance={CUR.shareNo} onToggle={this.handleToggle}/>
                )}
                {CUR.blockchain !== 'error' && CUR.asset === ASSET_TYPE_NFT && (
                  <NftAssetBalance CUR={CUR} balance={this.getBalance(CUR)} onToggle={this.handleToggle} />
                )}
                {CUR.blockchain === 'error' && (
                  <Alert severity={CUR.type}>{CUR.name}</Alert>
                )}
              </span>
            ))
        )}
        { userAssetTypesLoaded && currentAssetType === ASSET_TYPE_SPOT && kycLevel === false && (
          <In4xKycActivate />
        )}
        { userAssetTypesLoaded && currentAssetType === ASSET_TYPE_SPOT && kycLevel !== false && (
          <In4xKyc kycLevel={kycLevel} />
        )}
        <div className="push-bottom" />
      </div>
    );
  }
}

const mapState2props = state => ({
  dashboard: state.dashboard,
  blockchain: state.blockchain,
  in4x: state.in4x
});

const mapDispatch2props = {
  changeSendingCurrency,
  clearTransactionData,
  getExchangeRate
};

export default compose(
  connect(mapState2props, mapDispatch2props, null, { forwardRef: true })
)(Balances);