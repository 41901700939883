import React, { useContext } from "react";
import { UserAssetsContext } from "src/contexts/user-assets";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import NewSignIn from './NewSignIn';

import IownIcon from "src/components/IownIcon";

const SignIn = () => {
    const { updatePasswordHash } = useContext(UserAssetsContext)
    return (
        <Container component="main" maxWidth="sm" className="centered-grid spaced-top">
            <IownIcon />
            <Typography variant="h5" align="center" gutterBottom>
                Login
            </Typography>

            <NewSignIn updatePasswordHash={updatePasswordHash} />
        </Container>
    );
};

export default SignIn;
