
export const WERT_PROD_API_KEY = '01GFQX3ES0WMY1WZ4HF35DJ1G4';
export const WERT_TEST_API_KEY = '01GE4S9XSAHJ0AD9NQR3RED3FE';
export const CHANGELLY_PROD_API_KEY = "MxwWMBP-kWxt8FHZ&";
export const WERT_COMMODITY = {
	ETH_MAINNET: 'ETH:ethereum',
	ETH_GOERLI: 'ETH:Ethereum-Goerli', //testnet
	MATIC_MAINNET: 'MATIC:polygon', //same for testnet
	USDC_ETH: 'USDC:ethereum',
	USDC_MATIC: 'USDC:polygon'
};