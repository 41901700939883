import EtherUtil from "src/utils/ethers";
import GasUtils from "src/utils/blockchain/GasUtils";
import GtagUtil from "src/utils/gtagUtils";
import { ethers } from "ethers";

export const submitSafeTransferFrom = async(props, onSuccessMine) => {
    const { isMultiSend } = props;
	const { 
        wallet,
        sendingAddress,
        sendingAmount,
        sendingCurrency,
        sendingCurrencyObj,
        sendingTrxType
    } = props.blockchain;

    let txProps = GasUtils.generateTransactionGas(props.blockchainGas);
    const txArgs = !isMultiSend ? [
        wallet.address,  //from
        sendingAddress.value, //to
        sendingAmount.value, //tokenId
    ] : [
        sendingAddress.value,
        sendingAmount.value.split(",").map(item => ethers.utils.formatUnits(`${item}`, sendingCurrencyObj?.bits))
    ];

    const tx = await EtherUtil.newSmartContractTransaction(
        wallet,
        sendingTrxType,
        sendingCurrencyObj.address,
        txArgs,
        txProps
    );

    props.addTransaction(tx);

    EtherUtil.monitorPendingTransaction(tx, () => {
        props.removePending(tx.hash);
        props.removePendingListFromBackend(wallet.address, [tx.hash]);
        if(onSuccessMine) {
            onSuccessMine(tx);
        }
    });
    props.addPendingTransactionToBackend(wallet.address, {
        blockchain: tx.blockchain,
        network: tx.network,
        hash: tx.hash,
        receiver: sendingAddress.value,
        currency: sendingCurrency,
        amount: sendingAmount.value,
        trxType: sendingTrxType
    });
    GtagUtil.trackTransfer(sendingAmount.value, sendingCurrency, tx.blockchain);
    return tx.hash;
}