import React from "react";

import { Typography, Paper } from '@material-ui/core';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

let In4xPaymentSummary = props => {
	const { in4xPayment } = props;
 	return (
		<Accordion TransitionProps={{ unmountOnExit: true }} defaultExpanded style={{marginBottom: '0.5rem', marginTop:'0.5rem'}}>
	        <AccordionSummary
	          expandIcon={<ExpandMoreIcon />}
	          aria-controls="panel1a-content"
	          id="panel1a-header"
	        >
	        	<Typography className="color-nx">Payment Summary</Typography>
	        </AccordionSummary>
				<AccordionDetails style={{padding: '0'}}>
	    			<TableContainer component={Paper}>
				      <Table size="small" aria-label="a dense table">
				        <TableBody>
				        		<TableRow key="pp">
				              <TableCell component="th" scope="row">To</TableCell>
				              <TableCell align="right"><Typography fontSize="2" className="color-nx" style={{wordBreak: "break-all"}}>{in4xPayment.partnerName}</Typography></TableCell>
				            </TableRow>
				            { in4xPayment.orderDescription && (<TableRow key="pl">
				              <TableCell component="th" scope="row">Label</TableCell>
				              <TableCell align="right"><Typography fontSize="2" className="color-nx" style={{wordBreak: "break-all"}}>{in4xPayment.orderDescription}</Typography></TableCell>
				            </TableRow> )}
				            { in4xPayment.referenceId && (<TableRow key="pr">
				              <TableCell component="th" scope="row">Ref</TableCell>
				              <TableCell align="right"><Typography fontSize="2" className="color-nx" style={{wordBreak: "break-all"}}>{in4xPayment.referenceId}</Typography></TableCell>
				            </TableRow> )}
				            { in4xPayment.orderCurrency && in4xPayment.orderAmount && (<TableRow key="pa">
				              <TableCell component="th" scope="row">Original Amount</TableCell>
				              <TableCell align="right"><Typography fontSize="2" className="color-nx">{in4xPayment.orderCurrency.toUpperCase() + ' ' + in4xPayment.orderAmount}</Typography></TableCell>
				            </TableRow>)}
				            { in4xPayment.payCurrency && in4xPayment.payAmount && (<TableRow key="pc">
				              <TableCell component="th" scope="row">Order Amount</TableCell>
				              <TableCell align="right"><Typography fontSize="2" className="color-nx">{in4xPayment.payCurrency.toUpperCase() + ' ' + in4xPayment.payAmount}</Typography></TableCell>
				            </TableRow>)}
				            { in4xPayment.status.toLowerCase() !== "draft" && in4xPayment.nxFee > 0 && (<TableRow key="pf">
				              <TableCell component="th" scope="row">Payment Fee</TableCell>
				              <TableCell align="right"><Typography fontSize="2" className="color-nx">{in4xPayment.payCurrency.toUpperCase() + ' ' + in4xPayment.nxFee}</Typography></TableCell>
				            </TableRow>)}
				            { in4xPayment.status.toLowerCase() === "in progress" && in4xPayment.receivedSofar >= 0 && (<TableRow key="prsf">
				              <TableCell component="th" scope="row">Received So Far</TableCell>
				              <TableCell align="right"><Typography fontSize="2" className="color-nx">{in4xPayment.payCurrency.toUpperCase() + ' ' + in4xPayment.receivedSofar}</Typography></TableCell>
				            </TableRow>)}
				            { in4xPayment.status.toLowerCase() !== "draft" && (<TableRow key="ps">
					              <TableCell component="th" scope="row">Status</TableCell>
					              <TableCell align="right"><Typography fontSize="2" className="color-nx">{in4xPayment.status.toUpperCase()}</Typography></TableCell>
					            </TableRow> )}
				        </TableBody>
				      </Table>
				 	</TableContainer>
			</AccordionDetails>
		</Accordion>
	)
}

export default In4xPaymentSummary;