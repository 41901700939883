import React, { useState, useEffect, useCallback } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Container } from "@material-ui/core";

import CustomSnackbar from "src/components/CustomSnackbar";

import BlockchainTransactionConfirm from 'src/common/blockchain-transaction-confirm';
import ProcessingAlert from "src/common/processing-alert";

import ActiveSessions from "./components/active-sessions"
import WalletConnectBlock from "./components/connect";
import CallRequestModal from "./components/call-request-modal";

import { getError } from 'src/utils/transformErrors';
import {
	onConnect,
	recoverSessions,
	onRejectCallRequest,
	onApproveCallRequest,
} from "./utils";
import {
	setConnectCode,
	setConnectedSession,
	removeSession,
	addCallRequest,
	removeCallRequest
} from "src/ducks/walletConnect";
import {
	resetAssets
} from "src/ducks/dashboard";
import {
	loadGasOracle
} from "src/ducks/blockchainGas";

const WalletConnectView = props => {
	const {
		walletConnect: {
			walletConnectors = [],
			connectCode,
			activeSessions = [],
			hasCallRequest,
			callRequest,
			callRequestPeerName
		},
	} = props;

	const [ isApproveRequested, setIsApproveRequested ] = useState(false);
	const [ isCameraOpen, setIsCameraOpen ] = useState(false);
	const [ isRecovered, setIsRecovered ] = useState(activeSessions.length > 0);
	const [ successMessage, setSuccessMessage ] = useState(null);
	const [ errorMessage, setErrorMessage ] = useState(null);
	const [ isProcessing, setIsProcessing ] = useState(false);
	const handleCameraOpen = () => setIsCameraOpen(true);

	const handleOnScan = (value) => {
		props.setConnectCode(value);
		setIsCameraOpen(false);
	};

	const handleScanClose = () => {
		props.setConnectCode('');
		setIsCameraOpen(false)
	};

	const handleOnCodeChange = ({ target }) => {
		const { value } = target;
		props.setConnectCode(value);
	};

	const handleDisconnectClick = (sessionId) => {
		props.removeSession(sessionId);
	}

	const handleCallRequestReject = () => {
		onRejectCallRequest(props);
		setErrorMessage('Request Rejected');
		setSuccessMessage(null);
	}

	const handleCallRequestApprove = async() => {
		setIsApproveRequested(true);
	};

	const handleConfirmApproveCallRequest = async () => {
		try {
			setIsApproveRequested(false);
			setIsProcessing(true);
			await onApproveCallRequest(props);
			setSuccessMessage('Request Approved Successfully');
			setErrorMessage(null);
		} catch (error) {
			setErrorMessage(getError(error));
		} finally{
			setIsProcessing(false);
		}
	};

	const handlePasswordBackClick = () => {
		setIsApproveRequested(false);
	};

	const handleConnect = () => {
		onConnect(props).then(() => {
			setSuccessMessage('Connected, listening for session requests...');
		})
		.catch((error) => {
			setErrorMessage(getError(error));
		});
	}

	const checkConnectUriInSearch = useCallback( (historySearch) => {
		if(!historySearch) {
			return;
		}
		historySearch = historySearch.substring(1); //removes the '?'
		const parts = historySearch.split('&');
		if(parts.length === 2) { //uri= & key=
			props.setConnectCode(historySearch.substring(4));//same search without the uri=
		}
	}, [props]);

	const recoverSessionsOnLoad = useCallback(() => {
		recoverSessions(isRecovered, props, walletConnectors);
		setIsRecovered(true);
	}, [isRecovered, setIsRecovered, walletConnectors, props]);

	useEffect(() => {
		if (isRecovered) return;
		checkConnectUriInSearch(props.history.location.search);
		recoverSessionsOnLoad();
	});

	return (
        <Container
			className="home"
			component="main"
			maxWidth="sm"
		>
			{
				isApproveRequested ? (
					<BlockchainTransactionConfirm onBack={handlePasswordBackClick} onValidPassword={handleConfirmApproveCallRequest} />
				) : (
					<>
						{ !errorMessage && !isProcessing && (<CallRequestModal
							callRequest={callRequest}
							peerDisplayName={callRequestPeerName}
							onRejectCallRequest={handleCallRequestReject}
							onApproveCallRequest={handleCallRequestApprove}
							openOn={hasCallRequest}
						/>)}
						<CustomSnackbar
			              anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
			              variant="success"
			              open={!!successMessage}
			              message={successMessage}
			              onClose={() => {setSuccessMessage(null)}}
			            />
						<CustomSnackbar
			              anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
			              variant="error"
			              open={!!errorMessage}
			              message={errorMessage}
			              onClose={() => {setErrorMessage(null)}}
			            />
						<WalletConnectBlock
							scannedCode={connectCode}
							handleCameraOpen={handleCameraOpen}
							handleConnectClick={handleConnect}
							handleOnScan={handleOnScan}
							handleScanClose={handleScanClose}
							handleOnCodeChange={handleOnCodeChange}
							isCameraOpen={isCameraOpen}
						/>
						{ isProcessing && (<ProcessingAlert message={"Processing your action..."} />)}
						<ActiveSessions
							activeSessionsList={activeSessions}
							onDisconnectClick={handleDisconnectClick}
						/>
					</>
				)
			}
        </Container>
    )
}

WalletConnectView.propTypes = {
	walletConnect: PropTypes.object,
	blockchain: PropTypes.object,
	setConnectCode: PropTypes.func,
	setConnectedSession: PropTypes.func,
	removeSession: PropTypes.func,
	addCallRequest: PropTypes.func,
	removeCallRequest: PropTypes.func
};

const mapState2props = state => ({
    walletConnect: state.walletConnect,
    blockchain: state.blockchain,
});

const mapDispatch2props = {
    setConnectCode,
    setConnectedSession,
    removeSession,
    addCallRequest,
    removeCallRequest,
    resetAssets,
    loadGasOracle
}

export default compose(
    connect(mapState2props, mapDispatch2props)
)(WalletConnectView);