import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextField, MenuItem } from "@material-ui/core";

const SimpleSelect = (props) => {
    const {
        label = "Simple Select",
        onChange = val => val,
        options = [],
        disabled = false,
    } = props;
    const [selected, setSelected] = useState("");

    const handleChange = ({ target }) => {
        const { value } = target;
        setSelected(value);
        onChange(value)
    };

    const renderOptions = options.map((item, index) => {
        const { label, value } = item;
        return (
            <MenuItem
                key={`select-item-${index}`}
                value={value}
            >
                { label }
            </MenuItem>
        )
    });

    return (
        <TextField
            label={label}
            value={selected}
            onChange={handleChange}
            variant={"outlined"}
            className={"spaced-top"}
            fullWidth
            select
            disabled={disabled}
        >
            { renderOptions }
        </TextField>
    )
};

SimpleSelect.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
}

export default SimpleSelect;