import React from "react";

import Grid from '@material-ui/core/Grid';

const IownIcon = (props) => {
	return(
		<Grid item xs={12} className="centered-grid">
			<img src="/img/iown-blue-icon2.png" style={{height:"50px", width: "50px", marginBottom: "1rem"}} alt="iOWN Logo" />
		</Grid>
    );
}

export default IownIcon;