import React from "react"
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

const ComingSoon = (props) => {
    return (
        <Container component="main" className="coming-soon" maxWidth="sm">
            <img style={{maxHeight: '50vh'}} className={""} src="/img/iown-coming-soon-320x480.png" alt="Coming soon..."/>
            <Button color="primary" onClick={() => props.history.goBack()} className="spaced__btn" fullWidth variant="contained">Back</Button>
        </Container>
    )
}

export default ComingSoon;