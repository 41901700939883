/**
 * @name getError
 * @description transform error code to text
 * @param {string} `error`
 * @returns {string}
 */
export const getError = (error) => {

  if (`${error}`.includes("EXCESSIVE_INPUT_AMOUNT")) {
    return "Probably Insufficient Liquidity"
  }
  switch (error) {
    case 'USER_NOT_FOUND':
      return 'Account not found. Please check your credentials or create a new account.';
    case 'EMAIL_ALREADY_EXIST':
      return 'User already signed up. Please login, or choose another email.';
    case 'INVALID_EMAIL':
      return 'Invalid email address';
    case 'EMAIL_NOT_VALID':
      return 'Email address does not exist or it is invalid';
    case 'PHONE_NOT_VALID':
      return 'Phone number is not valid. Please use this format ("Add proper format here: Sample +447512345678" where +44 is your country code, followed by 9 digits)';
    case 'PHONE_ALREADY_EXIST':
      return 'User with such phone number already signed up. Please use an unique phone number.';
    case 'WALLET_ALREADY_EXIST':
      return 'Wallet already exists. Please login';
    case 'WALLET_NOT_FOUND':
      return 'Wallet not found';
    case 'AUTH_ERROR':
      return 'Credentials provided are incorrect';
    case 'USER_NOT_VERIFIED':
      return 'Account not activated yet! Please check your email.';
    case 'INCORRECT_OLD_PASSWORD':
      return 'Your old password is incorrect';
    case 'PASSWORDS_MISMATCH':
      return 'Passwords do not match';
    case 'PASSWORD_FIELD_MISMATCH':
      return 'This field must match with your password field';
    case 'NEW_PASSWORD_FIELD_MISMATCH':
      return 'This field must match with New Password field';
    case 'INCORRECT_PASSWORD':
      return 'Password is incorrect, if you have recovered it please <a href="/sign-in">relogin</a><br><br>To reset it click <a href="/reset-password">here</a><br><br>Note: You need your secret phrases after password recovery';
    case 'PASSWORD_CHANGED':
      return 'Your credentials have changed, you need to <a href="/sign-in">relogin</a> with your email and secret phrases';
    case 'PASSWORD_NOT_VALID':
      return 'Your password is invalid';
    case 'PASSWORD_SHOULD_DIFFER':
      return 'Your password cannot be the same as old one';
    case 'WALLET_NOT_AVAILABLE':
      return 'Your wallet private key is not available (not loaded)';
    case 'IBAN_FORMAT':
      return 'IBAN is not valid (Ex: AE070331234567890123456)';
    case 'SWIFT_FORMAT':
      return 'Swift code is not valid (Ex: EBILAEADDMB)';
    case 'CAPTCHA_CONFIGURE_ERROR':
      return 'Recaptcha is not configured.';
    case 'CAPTCHA_VERIFYING_ERROR':
      return 'Captcha verification failed';
    case 'TOKEN_NOT_VALID':
      return 'Captcha verification failed';
    case 'SERVER_CONNECTION_FAILED':
      return 'Network connection failed.';
    case 'SERVER_CONFIGURATION_FAILED':
      return 'An error occurred while communicating with server';
    case 'SERVER_DOWN_ERROR':
      return 'Server is unavailable or down for maintenance';
    case 'SERVER_CONNECTION_TIMEOUT':
      return 'Network connection timeout.';
    case 'GAS_CALCULATION_ERROR':
      return 'An error occured while preparing transaction gas';
    case 'AMOUNT_NOT_VALID':
      return 'The amount provided is invalid';
    case 'QUOTED_AMOUNT_NOT_VALID':
      return 'The quoted amount is not valid';
    case 'INVALID_TARGET_CURRENCY':
      return 'Invalid target currency';
    case 'IN4X_CONNECTION_FAILED':
      return 'Connection to IN4X Global failed';
    case 'IN4X_CONFIGURATION_FAILED':
      return 'IN4X Global rejected this request';
    case 'IN4X_MALFORMED_RESPONSE':
      return 'IN4X Global returned a malformed response';
    case 'IN4X_INVALID_REFERENCE_CODE':
      return 'Reference code is in an invalid format';
    case 'IN4X_LOCAL_PROFILE_EXISTS':
      return 'Your profile has already been created, please contact administrator';
    case 'IN4X_FAILED_TO_CREATE_PROFILE':
      return 'An error occured during creating your account';
    case 'IN4X_INVALID_PARTNER_TOKEN':
      return 'Invalid payment request (partner info is missing)';
    case 'IN4X_INVALID_CURRENCY_PAIR':
      return 'Invalid currency pair for tokens-swap request';
    case 'IN4X_INVALID_PARTNER_REQUEST_ID':
      return 'Invalid payment request (partner request ID is required)';
    case 'IN4X_INVALID_CURRENCY_CODE':
      return 'Invalid payment request (currency is missing or invalid)';
    case 'IN4X_INVALID_TARGET_NAME':
      return 'Invalid payment request (target must have a name/label)';
    case "IN4X_INVALID_BASE_CURRENCY":
      return 'Invalid pay/quote currency';
    case "IN4X_INVALID_QUOTE_CURRENCY":
      return 'Invalid receive/target currency';
    case 'KYC_SUBMISSION_EXISTS':
      return 'KYC_SUBMISSION_EXISTS';
    case 'NUMBER_FORMAT':
      return 'Must be a number';
    case 'ALPHA_FORMAT':
      return 'Only alpha characters';
    case 'PASSWORD_FORMAT':
      return 'Password should be: Minimum 8 characters, 1 character at least that\'s: an UPPERCASE, a lowercase, a number, and a special character from ._{}!@#*()+:^;<>$%&';
    case 'MNEMONIC_FORMAT':
      return 'Mnemonic should be phrase of 12 words divided with a space';
    case 'REQUIRED':
      return 'Required';
    case 'CRITICAL_ERROR':
      return 'An internal application error has occured, please close application and retry later';
    case 'BIOMETRIC_NO_SECRET_FOUND':
      return 'Biometric unlock disabled since phone status changed';
    //Copied from SendTokens componenet:
    case 'ERROR_ETH_BALANCE_TOO_LOW':
      return "You don't have enough ETH to pay the transaction fee.";
    case 'ERROR_GAS_TOO_LOW':
      return "Fee amount isn't enough/Or Always Failing Transaction.";
    case 'ERROR_PROVIDER_GAS':
      return "Gas price is lower than the network price (Possibly price increased?)"
    case 'ERROR_UNDERPRICED_REPLACEMENT_TRX':
      return "You have a stuck transaction, and are attempting to replace it with a new one with lower fee, you need to wait for the stuck transaction.";
    case 'ERROR_CONFIRM_TRX':
      return "There was an error during transaction confirm. Let us know, or try again later."
    case 'STAKING_CURRENCY_UNAVAILABLE':
      return  "Staking currency load error";
    default:
      return (error? transformBlockchainError(error.toString()): null);
  }
};

export const blockchainErrorTransform = (error = "") => {

    if (!error) return "";

    const errorRegEx = /({.+}})/gm;
    const errorValuesRaw = error.match(errorRegEx);

    if (!errorValuesRaw) return { message: error };

    const parseResponse = errorValuesRaw[0]
        .replaceAll("\\", "")
        .replaceAll('"{', '{') + "}";

    return JSON.parse(parseResponse)?.body?.error;
};

/**
 * @name transformError
 * @description transform error to text
 * @param {array | string} `errors`
 * @returns {string | void}
 */
export const transformError = errors => {
  let errList = "";
  if (Array.isArray(errors)) {
    errors.forEach(el => {
      errList = `${errList + getError(el)} `
    });

    return errList;
  } else {
    return getError(errors)
  }
};

/**
 * @description Checks if error is a JSON RPC error, and extracts message from it
 * @param {string} `error`
 * @returns {string} error || error message extracted
 */
export const transformBlockchainError = error => {
  if(error && error.indexOf('body="{\\"jsonrpc\\":\\"2.0\\"')) {
      const msgSplit = error.split('=');
      if(msgSplit.length > 1) {
        let errorBody;
        try {
          errorBody = msgSplit[1].substr(1, msgSplit[1].length - 9).replaceAll('\\"', '"');
          const errorObj = JSON.parse(errorBody);
          return 'Miner Error: '+ errorObj.error.message;
        } catch(e){
          if(errorBody) {
            return errorBody;
          }
        }
      }
  }
  return error;
}