import WalletConnect from "@walletconnect/client";

import { utils } from "ethers";

import { isAllowedChainId, getChainId, chainIdToBlockchain, chainIdToNetwork } from 'src/constants/blockchain'
import { EVENT_PROVIDER_BLOCKCHAIN_CHANGE } from "src/constants/events";
import ProviderUtils from 'src/utils/blockchain/ProviderUtils';
import GasUtils from 'src/utils/blockchain/GasUtils';

import { WC_CLIENT_META, EIP155_SIGNING_METHODS } from './constants';
import { WALLET_CONNECT_PREFIX } from "src/constants/storageKeys";

export const convertHexDatatoUtf8 = (dataItem) => {
    if(!utils.isAddress(dataItem) && utils.isHexString(dataItem)) {
        return utils.toUtf8String(dataItem);
    }
    return dataItem;
}

/**
 * Gets message from various signing request methods by filtering out
 * a value that is not an address (thus is a message).
 * If it is a hex string, it gets converted to utf8 string
 */
export function getSignParamsMessage(params) {
  const message = params.filter(p => !utils.isAddress(p))[0]
  return convertHexDatatoUtf8(message);
}

/**
 * Gets data from various signTypedData request methods by filtering out
 * a value that is not an address (thus is data).
 * If data is a string convert it to object
 */
export const getSignTypedDataParamsData = (params) => {
    const data = params.filter(p => !utils.isAddress(p))[0]
    if (typeof data === 'string') {
        return JSON.parse(data)
    }
    return data;
}

/**
 * Extracts a transaction object from a callRequest coming from WalletConenct socket
 * @param {IJsonRpcRequest} `callRequest` WalletConnect call request
 * @param {object} `wallet` A blockchain wallet (used to ensure we're getting gas for correct blockchain, can be null
 * @return {object} Blockchain transaction (with gas settings from Oracle)
 */
export const getTransactionFromCallRequest = async(callRequest, wallet) => {
    if(callRequest && callRequest.params) {
        let transaction = callRequest.params[0];
        if(transaction.gas) {
            transaction.gasLimit = transaction.gas;
            delete transaction.gas;
        }
        const gasMetrics = await GasUtils.loadGasOracle(wallet?.provider?.connectedBlockchain, wallet?.provider?.connectedNetwork);
        return {...transaction, ...GasUtils.defaultGas(gasMetrics)};
    }
    return null;
}

export const getSessionParamsFromConnector = (walletConnector) => {
    return {
        chainId: walletConnector.chainId,
        peerMeta: walletConnector.peerMeta,
        peerId: walletConnector.peerId
    };
}

export const registerListeners = (walletConnector, props) => {
    let { blockchain: {wallet}, setConnectedSession, removeSession } = props;

    // blockchainEventListener is hooked because a disconnect can happen anywhere (remote and local)
    walletConnector.blockchainEventListener = (event) => {
        walletConnector.updateSession({
            chainId: getChainId(event.detail.blockchain, ProviderUtils.activeNetwork()),
            accounts: [wallet.address]
        })
    };
    // If user changes active blockchain, we notify walletConnect DAPP
    window.addEventListener(EVENT_PROVIDER_BLOCKCHAIN_CHANGE, walletConnector.blockchainEventListener);

    // Occurs usually right after connectSession: remote DAPP request the session via the bridge
    walletConnector.on("session_request", (error, payload) => {
        if(error) {
            throw error;
        }
        const { params } = payload;
        if(params[0].chainId && isAllowedChainId(params[0].chainId)) {
            walletConnector.rejectSession({message: `Unsupported chain $(chainId)`});
        } else {
            const activeChainId = getChainId(ProviderUtils.activeBlockchain(), ProviderUtils.activeNetwork());
            walletConnector.approveSession({
              chainId: activeChainId,
              accounts: [wallet.address]
            });
            walletConnector.remotePeerId = params[0].peerId; //This is used to remove the session on disconnect
            setConnectedSession(walletConnector, params[0]);
        }
    });

    // Occurs when remote DAPP requests a wallet action
    walletConnector.on('call_request', (error, payload) => {
        if(error) {
            throw error;
        }
        props.addCallRequest(payload, walletConnector);
    });

    // Occurs when remote DAPP disconnects the session
    walletConnector.on('disconnect', () => {
        // This is the remote disconnect
        window.removeEventListener(EVENT_PROVIDER_BLOCKCHAIN_CHANGE, walletConnector.blockchainEventListener);
        removeSession(walletConnector.remotePeerId);
        if(walletConnector.connected) {
            walletConnector.killSession();
        }
    });

    // Occurs when remote DAPP requests a session change (like a new chainId)
    walletConnector.on('session_update', (error, payload) => {
            if (error) {
                throw error;
            }
            const {params} = payload;
            if (params[0].chainId && isAllowedChainId(params[0].chainId)) {
                walletConnector.rejectSession({
                    message: `Unsupported chain ${params[0].chainId}`
                });
            } else {
                if (params[0].chainId) {
                    props.resetAssets();
                    ProviderUtils.changeDefaultBlockchain(chainIdToBlockchain(params[0].chainId));
                    ProviderUtils.changeDefaultNetwork(chainIdToNetwork(params[0].chainId));
                }
                //DO nothing
            }

        }
    );

    return walletConnector;
}

export const recoverSessions = async(isRecovered, props, existingConnectors) => {
    if(isRecovered) {
        return;
    }
    const items = { ...localStorage };
    Object.keys(items).map((storageKey) => {
        if(storageKey.indexOf(WALLET_CONNECT_PREFIX) !== -1) {
            const sessionInfo = JSON.parse(items[storageKey]);

            if(Object.keys(existingConnectors).includes(sessionInfo.peerId)) {
                return null;
            }

            let walletConnector = new WalletConnect({
                storageId: storageKey,
                bridge: sessionInfo.bridge,
                clientMeta: WC_CLIENT_META
            });

            registerListeners(walletConnector, props);

            const sessionParams = getSessionParamsFromConnector(walletConnector);
            props.setConnectedSession(walletConnector, sessionParams);
        }
        return storageKey;
    });
}

export const onConnect = async(params) => {
    let {
        walletConnect = {},
    } = params;
    const { connectCode } = walletConnect;

    if(connectCode) {
        //local storage key: different one to distinguish different connections
        const storageId = connectCode.substring(3, 39); //grabs the topic part of the code
        let walletConnector = new WalletConnect({
            uri: connectCode,
            storageId: WALLET_CONNECT_PREFIX + storageId,
            clientMeta: WC_CLIENT_META
          }
        );
        registerListeners(walletConnector, params);
        if(walletConnector.connected) {
            const sessionParams = getSessionParamsFromConnector(walletConnector);
            if(!sessionParams.peerId || sessionParams.peerId === '' || !sessionParams.peerMeta) {
                await walletConnector.killSession();
            } else {
                params.setConnectedSession(sessionParams, walletConnector);
            }
        } else {
            return walletConnector.createSession().then((r) => {
            }).catch((ee) => {
                return ee;
            });
        }
    }
};

export const onApproveCallRequest = async(params) => {
    const { walletConnect: { callRequest }, removeCallRequest} = params;
    let result;
    switch(callRequest.method) {
        case EIP155_SIGNING_METHODS.PERSONAL_SIGN:
        case EIP155_SIGNING_METHODS.ETH_SIGN: 
            result = await processEthSignCallRequest(params);
            break;
        case EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION: 
            result = await processSignTransactionCallRequest(params);
            break;
        case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA: 
        case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3: 
        case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4: 
            result = await processTypedDataCallRequest(params);
            break;
        case EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION:
            result = await processSendTransactionCallRequest(params); //Sends a transaction
            break;
        case EIP155_SIGNING_METHODS.WALLET_SWITCH_CHAIN:
            result = processSwitchChain(params);
            break;
        case EIP155_SIGNING_METHODS.ETH_SEND_RAW_TRANSACTION:
        default: 
            throw new Error('INVALID_WC_CALL_REQUEST');
    }

    callRequest.walletConnector.approveRequest({
        id: callRequest.id,
        jsonrpc: "2.0",
        result
    });
    removeCallRequest();
    return result;
}

export const onRejectCallRequest = params => {
    const { walletConnect: { callRequest }, removeCallRequest } = params;
    if(callRequest && callRequest.walletConnector) {
        callRequest.walletConnector.rejectRequest({
            id: callRequest.id,
            error: {
                message: 'Rejected by client'
            }
        });
        removeCallRequest();
    } else {
        throw new Error('INVALID_WC_CALL_REQUEST');
    }
}

export const processEthSignCallRequest = params => {
    const { walletConnect: { callRequest },  blockchain: { wallet }} = params;
    return wallet.signMessage(getSignParamsMessage(callRequest.params));
}

export const processTypedDataCallRequest = params => {
    const { walletConnect: { callRequest },  blockchain: { wallet }} = params;
    const { domain, types, message: data } = getSignTypedDataParamsData(callRequest.params);
    // Cheated for WalletConnect example
    // https://github.com/ethers-io/ethers.js/issues/687#issuecomment-714069471
    delete types.EIP712Domain;

    return wallet._signTypedData(domain, types, data);
}

export const processSignTransactionCallRequest = async(params) => {
    const { walletConnect: { callRequest },  blockchain: { wallet }} = params;
    const signTransaction = await getTransactionFromCallRequest(callRequest, wallet);
    return wallet.signTransaction(signTransaction);
}

export const processSendTransactionCallRequest = async(params) => {
    const { walletConnect: { callRequest },  blockchain: { wallet }} = params;
    const { chainId } = callRequest;
    if(chainId) {
        const blockchain = chainIdToBlockchain(chainId);
        const network = chainIdToNetwork(chainId);
        wallet.connect(ProviderUtils.providerFor(network, blockchain));
    }
    const sendTransaction = await getTransactionFromCallRequest(callRequest, wallet);
    const { hash } = await wallet.sendTransaction(sendTransaction);
    return hash;
}

export const processSwitchChain = (params) => {
    const { walletConnect: { callRequest }} = params;
    const { chainId } = callRequest.params[0];
    const blockchain = chainIdToBlockchain(chainId);
    const network = chainIdToNetwork(chainId);
    ProviderUtils.changeDefaultBlockchain(blockchain);
    ProviderUtils.changeDefaultNetwork(network);
    return true;
}