import React from "react";
import {connect} from "react-redux";
import { getFormValues, isValid } from "redux-form";

import { Container, Typography, Grid, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import CircularProgress from '@material-ui/core/CircularProgress';

import SAMobileStepper from "../../components/SAMobileStepper";
import In4xWireTransferInfo from '../../components/In4xWireTransferInfo';

import BlockchainLabel from "../../components/BlockchainLabel";
import BuyTokensForm from '../../components/Forms/BuyTokensForm';
import WireTransferForm from '../../components/Forms/WireTransferForm';
import TopupEtherForm from '../../components/Forms/TopupEtherForm';

import CopyToClipboardButton from '../../components/CopyToClipboardButton';
import WireTransferConfirmationModal from '../modals/WireTransferConfirmationModal';

import { openLink } from '../../utils/linksOpenPolyfill';
import { 
	getIn4xDepositInfo, 
	getIn4xBank, 
	setIn4xBank, 
	createIn4xWireTransfer, 
	getExchangeRate,
	getFeeSchedule
} from '../../ducks/in4x';

import {
	setBuyTokensValues,
	setTopupEthValues,
	getTopupEthValues,
	setWireTransferValues,
	resetForms,
	FORM_BUY_TOKENS,
	FORM_TOPUP_GAS_TOKENS,
	FORM_WIRETRANSFER
} from '../../ducks/FormData';

import { 
	ROUTE_DEFAULT_LOGGEDIN,
	ROUTE_WALLET_ASSETS, 
	EXTERNAL_CONTACTUS, 
	ROUTE_E_WALLET_BANK_DEPOSIT, 
	ROUTE_NX_WIRETRANSFER,
	ASSET_TYPE_LIQUID,
	ROUTE_NX_TRANSACTION_HISTORY
} from '../../constants/routes';
import { IN4X_METHOD_WIRE } from '../../constants/in4xConstants';

import ProviderUtils from '../../utils/blockchain/ProviderUtils';
import GasUtils from '../../utils/blockchain/GasUtils';

class WireTransferDeposit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeStep: 0,
			total: '',
			showConfirmation: false,
			minBalChecked: false,
			showGasCur: true,
			gasCur: '',
			activeCurrency: '',
			activeBc: '',
			payCur: 'usd',
		};
	}

	componentDidMount = () => {
		const { pathname } = this.props.history.location;
		if(pathname.indexOf(ROUTE_E_WALLET_BANK_DEPOSIT) !== 0) {
			return;
		}
		let urlSplit = pathname.substring(pathname.indexOf(ROUTE_E_WALLET_BANK_DEPOSIT) + ROUTE_E_WALLET_BANK_DEPOSIT.length).split("/");
		let activeBc = urlSplit[0];
		let activeCur = urlSplit[1];
		let gasCur;
  		if(activeCur && activeBc) {
  			ProviderUtils.changeDefaultBlockchain(activeBc);
  			gasCur = GasUtils.gasUnitsForBlockchain();
  			this.setState({activeCurrency: activeCur, activeBc: activeBc, gasCur});
  		} else if(this.props.in4x.activeCurrency) {
  			ProviderUtils.changeDefaultBlockchain(this.props.in4x.activeCurrency.blockchain);
  			gasCur = GasUtils.gasUnitsForBlockchain();
  			this.setState({activeCurrency: this.props.in4x.activeCurrency.name, activeBc: this.props.in4x.activeCurrency.blockchain, gasCur});
  			activeCur = this.props.in4x.activeCurrency.name;
  		} else {
  			this.props.history.push(ROUTE_DEFAULT_LOGGEDIN);
  			return;
  		}
  		let showGasCur = gasCur && this.props.blockchain.gasBalanceLow.includes(gasCur);
  		this.setState({showGasCur: activeCur.toUpperCase() === 'eth'? false: showGasCur});
  		this.props.setBuyTokensValues({'payCur': 'USD', 'recCur': activeCur.toUpperCase()});
  		this.props.getFeeSchedule();
  		this.props.getIn4xDepositInfo();
  	}

  	componentWillUnmount = () => {
  		this.props.resetForms();
  	}

	nextStep = () => {
		switch(this.state.activeStep) {
			case 0: 
				return this.showBuyTokens();
			case 1:
				return this.processBuyTokens();
			case 2:
				return this.processTopupEther();
			case 4:
				return this.showConfirmationModal();
			case 5:
				return this.createWireTransfer();
			case 6:
				if(this.props.in4x.transferInfo) {
					this.props.history.push(ROUTE_NX_WIRETRANSFER+this.props.in4x.transferInfo.REFERENCE_ID);	
				} else {
					this.props.history.push(ROUTE_NX_TRANSACTION_HISTORY);
				}
				//no break;
				// eslint-disable-next-line no-fallthrough
			default:
				this.setState((state) => ({ activeStep: state.activeStep + 1 }));
		}
	}

	prevStep = async() => {
		switch(this.state.activeStep) {
			case 0:
				this.props.history.push(ROUTE_WALLET_ASSETS + ASSET_TYPE_LIQUID);
				break;
			case 2:
				this.setState((state) => ({ activeStep: state.activeStep - 1 }));
				await this.props.getExchangeRate('USD', this.state.activeCurrency);
				break;
			case 3:
				const inc = this.state.showGasCur? 1 : 2;
				this.setState((state) => ({ activeStep: state.activeStep - inc }));
				break;
			default:
				this.setState((state) => ({ activeStep: state.activeStep - 1 }));	
		}
	}

	showBuyTokens = async() => {
		this.setState((state) => ({ activeStep: state.activeStep + 1 }));
		await this.props.getExchangeRate('USD', this.state.activeCurrency);
	}

	handleCloseModal = (actionType) => {
		switch(actionType) {
			case 'success':
				this.setState((state) => ({ activeStep: state.activeStep + 1, showConfirmation: false }));
				break;
			case 'help':
				this.setState({ showConfirmation: false });
				openLink(null, EXTERNAL_CONTACTUS);
				break;
			default:
				this.setState({ showConfirmation: false });
		}
	}

	processBuyTokens = async() => {
		const inc = this.state.showGasCur? 1 : 2;
		if(this.state.showGasCur) {
			await this.props.getExchangeRate('USD', GasUtils.gasUnitsForBlockchain());
		}
		if(this.props.buyFormValid && this.props.buyFormValues && this.props.buyFormValues.total > 0) {
			this.props.setBuyTokensValues(this.props.buyFormValues);
			this.updateTopupFormValues(Number(this.props.buyFormValues.total));
			this.setState((state) => ({ activeStep: state.activeStep + inc, amount: this.props.buyFormValues.amount, total: this.props.buyFormValues.total }));
		}
	}

	updateTopupFormValues = (buyTotal) => {
		const formData = this.props.FormData.TopupEth;
		if(!formData || formData.total === 0) {
			this.props.setTopupEthValues({ prevAmount: buyTotal, total: buyTotal});
			return;
		}
		if(buyTotal !== formData.prevAmount) {
			const { amount, fee, total } = formData;
			const topupTotal = (Number(amount) > 0 && Number(fee) > 0)? buyTotal + Number(amount) + Number(fee) : total;
			this.props.setTopupEthValues({ prevAmount: buyTotal, total: topupTotal });
		}
	}

	processTopupEther = async() => {
		if(this.props.topupFormValid && this.props.topupFormValues && this.props.topupFormValues.total > 0) {
			this.props.setTopupEthValues(this.props.topupFormValues);
			this.setState((state) => ({ activeStep: state.activeStep + 1, total: this.props.topupFormValues.total, amountEth: this.props.topupFormValues.amount }));
		}
	}

	showConfirmationModal = () => {
		this.setState({ showConfirmation: true });
		const amountData = this.props.FormData.BuyTokens;
		const ethData = this.props.FormData.TopupEth;

		this.props.setWireTransferValues({
			amountBase: Number(amountData.amount),
			amountQuote: Number(amountData.receiveAmount),
			amountEth: Number(ethData.amount),
			eth: Number(ethData.receiveAmount),
			payCur: amountData.payCur,
			recCur: amountData.recCur,
			direction: 'in',
			total: Math.max(ethData.total, amountData.total),
			feeAmount: Number(amountData.fee),
			feeEth: Number(ethData.fee),
		});
	}

	createWireTransfer = async() => {
		if(this.props.wireTransferValid) {
			await this.props.createIn4xWireTransfer(this.props.wireTransferValues, this.state.activeCurrency);
			if(!this.props.in4x.error) {
				this.setState((state) => ({ activeStep: state.activeStep + 1 }));
			} else {
				this.setState({catchErr: this.props.in4x.error});
			}
		}
	}

	render() {
    	if (this.state.catchErr) throw this.state.catchErr;
    	const { wireTransferValid, buyFormValid, buyFormValues, topupFormValid, topupFormValues } = this.props;
    	const { activeStep, showConfirmation, activeCurrency, activeBc, payCur, gasCur } = this.state;
    	const {
    		in4xTransferLoading,
    		depositInfo: {
    			beneficiary, intermediary, copyAddress
    		},
    		exRate, 
    		in4xRateLoading,
    		in4xFeesLoading,
    		in4xFees,
    		in4xLimits,
    	} = this.props.in4x;
    	const disablePrev = activeStep > 5 || in4xTransferLoading;
    	const disabledNext = (activeStep === 1 && (!buyFormValid  || !buyFormValues)) || 
    						 (activeStep === 2 && (topupFormValues?.isAdd && !topupFormValid)) ||
    						 (activeStep === 5 && !wireTransferValid) || 
    						 in4xTransferLoading || in4xFeesLoading;
    	return (
    		<Container className="home" component="main" maxWidth="sm">
    			<Grid container spacing={1} className="centered-grid">
    				<Grid item xs={12}>
	        			<BlockchainLabel />
	        			<Typography variant="h5" align="center" gutterBottom className="color-nx">OTC Transaction</Typography>
	        		</Grid>
	    			{activeStep === 0 && (
						<Grid item xs={12}>
	    					<Typography align="left" gutterBottom style={{width: '100%'}}>You can topup your account by creating a wire transfer to:</Typography>
	    					<Typography variant="h5" className="color-nx" style={{marginTop: "1rem", marginBottom: "1rem"}}>IN4X Global Bank Account</Typography>
	    					<Typography align="left" gutterBottom style={{marginTop: "0.5rem", width: '100%'}}>Please keep the following in mind:</Typography>
			              <Typography align="left" gutterBottom>
			                  <PriorityHighIcon color="secondary" fontSize="small"/> Your deposit is subject to bank fees (including your own bank).
			              </Typography>
			              <Typography align="left" gutterBottom>
			                  <PriorityHighIcon color="secondary" fontSize="small"/> IN4X Global charges apply.
			              </Typography>
			              <Alert severity="error" align="left"> Important! Insert the following code in the transfer reference field: <strong>00020701 IN4X Global</strong>
			              </Alert>
			            </Grid>
	    			)}
	    			{ activeStep === 1 && (
						<Grid item xs={12}>
							<BuyTokensForm
								base="USD"
								blockchain={activeBc}
								quote={activeCurrency}
								rate={exRate.low}
								fees={in4xFees}
								limits={in4xLimits}
								isLoading={in4xRateLoading || in4xFeesLoading}
								handleSubmit={this.processBuyTokens}
								handleCancel={this.prevStep} 
								method={IN4X_METHOD_WIRE}
								hideButtons
							/>
						</Grid>
	    			)}
	    			{ activeStep === 2 && (
	    				<Grid item xs={12}>
	    					<Typography align="left" gutterBottom>Add {gasCur.toUpperCase()} to your topup?</Typography>
							<TopupEtherForm rate={exRate.low} fees={in4xFees} limits={in4xLimits} method={IN4X_METHOD_WIRE} payCur={payCur} gasCur={gasCur}/>
	    				</Grid>
	    			)}
	    			{ activeStep === 3 && (
	    				<Grid item xs={12}>
	    					<Typography align="left" gutterBottom>We have prepared a guide to assist you in the bank wire transfer process.</Typography>
	    					<img  src="/img/wiretransfer-guide.png" alt="Wire transfer Guide" style={{maxWidth: '200px'}} className="spaced-top" />
	    					<Typography align="left" gutterBottom className="spaced-top">Download our guide for assistance.</Typography>
	    					<Button className="bg-nx spaced-top" variant="contained" fullWidth onClick={(e) => openLink(e, `https://www.in4xglobal.com/wp-content/uploads/2021/10/IN4X-Guide-to-Make-a-Deposit-Bank-Wire-Transfer.pdf`)}>Download</Button>
		               	</Grid>
	    			)}
	    			{ activeStep === 4 && (
	    				<Grid item xs={12}>
	    					<In4xWireTransferInfo beneficiary={beneficiary} intermediary={intermediary} />
	    					<CopyToClipboardButton text={copyAddress} />
		               	</Grid>
					)}
					{ activeStep === 5 && (
						<Grid item xs={12}>
							<Typography align="left" className="spaced-top">Please add amount and refernce (from your bank) of your transfer after sending it</Typography>
							{ !in4xTransferLoading && (
								<WireTransferForm handleSubmit={this.createWireTransfer} />
							)}
							{ in4xTransferLoading && (
								<div style={{"textAlign": "center", padding: "20px"}}><CircularProgress color="secondary" /></div>
							)}
							{ !in4xTransferLoading && activeCurrency === 'iown' ? (
								<Alert severity="warning" align="left">Actual token amount will be calculated at wire transfer receive time (from iOWN Rate)
									and is subject to change due to bank and remittence fees.
								</Alert> ) : (
								<Alert severity="warning" align="left" >Actual token amount will be calculated at wire transfer receive time
									and is subject to change due to bank and remittence fees.
								</Alert>
							)}
						</Grid>
					)}
					{ activeStep === 6 && (
						<Grid item xs={12}>
					    	<Alert variant="outlined" severity="success">Transfer Request Created!</Alert>
					    	<Typography align="left">Funds will be credited to your eWallet as soon as your transfer is received.</Typography>
				    	</Grid>
					)}
					<WireTransferConfirmationModal openOn={showConfirmation} onClose={this.handleCloseModal} />
					<SAMobileStepper maxSteps={7} activeStep={this.state.activeStep} onNext={this.nextStep} onPrev={this.prevStep} disabledPrev={disablePrev} disabledNext={disabledNext} />
			    </Grid>
    		</Container>
    	);
    }
}

const mapState2props = state => ({
    in4x: state.in4x,
    blockchain: state.blockchain,
    dashboard: state.dashboard,
    FormData: state.FormData,
  	buyFormValues: getFormValues(FORM_BUY_TOKENS)(state),
  	buyFormValid: isValid(FORM_BUY_TOKENS)(state),
  	topupFormValues: getFormValues(FORM_TOPUP_GAS_TOKENS)(state),
  	topupFormValid: isValid(FORM_TOPUP_GAS_TOKENS)(state),
  	wireTransferValues: getFormValues(FORM_WIRETRANSFER)(state),
  	wireTransferValid: isValid(FORM_WIRETRANSFER)(state),
});
const mapDispatch2props = {
	getIn4xDepositInfo,
	getIn4xBank,
	setIn4xBank,
	createIn4xWireTransfer,
	getExchangeRate,
	getFeeSchedule,
	setBuyTokensValues,
	setTopupEthValues,
	getTopupEthValues,
	setWireTransferValues,
	resetForms
};

export default connect(mapState2props, mapDispatch2props)(WireTransferDeposit);