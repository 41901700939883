export const API_SIGNUP = "/sign-up";
export const API_SIGNUP_CAPTCHA = "/sign-up/captcha";
export const API_SIGNUP_ACTIVATION_CHECK = "/sign-up/activation-check";
export const API_CREATE_WALLET = "/profile/save-wallet";
export const API_USER_ASSETS = "/asset-types";
export const API_EDIT_PASSWORD = "/password";
export const API_SIGNIN_EMAIL = "/sign-in/email";

export const API_SIGNIN_WALLET = "/sign-in/wallet";
export const API_LOGOUT = "/logout";
export const API_VALIDATION_EMAIL = "/validation/email";

export const API_VALIDATION_PHONE = "/validation/phone";
export const API_TRX_PENDING_GET = "/pending/get";

export const API_TRX_PENDING_SAVE = "/pending/save";
export const API_TRX_PENDING_REMOVE = "/pending/remove";
export const API_BACKUP_MNEMONIC = "/backup-mnemonic";

export const API_GAS_ORACLE = "/gas/oracle";

//For iOWN Digital Integration:

export const API_IOD_IDENTITY = "/iod/identity";
export const API_IOD_OTP = "/iod/otp";
export const API_IOD_LINK = "/iod/link";
// For IN4X

export const API_IN4X_PROFILE_CREATE = "/in4x/create";
export const API_IN4X_PROFILE = "/in4x/profile";
export const API_IN4X_DEPOSIT_INFO = "/in4x/deposit-info";
export const API_IN4X_BANK_INFO_GET = "/in4x/bank-info/get";
export const API_IN4X_BANK_INFO_SET = "/in4x/bank-info/set";
export const API_IN4X_WIRE_TRANSFER_REQ_CREATE = "/in4x/wire-transfer/create";
export const API_IN4X_WIRE_TRANSFER_REQ = "/in4x/wire-transfer/get";
export const API_IN4X_PAYMENT_REQ_CREATE = "/in4x/payment/create";
export const API_IN4X_PAYMENT_INFO = "/in4x/payment/get";
export const API_IN4X_PAYMENT_QUOTES = "/in4x/payment/quotes";
export const API_IN4X_PAYMENT_UPDATE = "/in4x/payment/update";
export const API_IN4X_EXCH_RATE = "/in4x/exchange/rate";
export const API_IN4X_FEE_SCHEDULE = "/in4x/fees/get";
export const API_IN4X_EXCH_BASE_CURRENCIES = "/in4x/exchange/base-currencies";
export const API_IN4X_EXCH_REQ_CREATE = "/in4x/exchange/request";
export const API_IN4X_EXCH_REQ = "/in4x/exchange/transaction";
export const API_IN4X_CASH_LOCATIONS_GET = "/in4x/cash-locations/get";
export const API_IN4X_CASH_REQ_CREATE = "/in4x/cash-transfer/create";
export const API_IN4X_CASH_REQ = "/in4x/cash-transfer/get";
export const API_STAKING_INFO = "/assets/staking";

export const API_GET_SWAP_CURRENCIES = "/exchange-currencies/get";
export const API_USER_PROFILE = "/profile";

export const API_SWAP_TRANSFER_CREATE = "/in4x/swap-transfer/create";
export const API_SWAP_TRANSFER_GET = "/in4x/swap-transfer/get";

// Multi endpoint:
export const API_IN4X_TRANSACTION_HISTORY = "/in4x/transaction/history";

// Recover password
export const API_AUTH_RECOVER_PASSWORD = "/recover-password";
export const API_AUTH_NEW_PASSWORD = "/new-password";
export const API_AUTH_VALIDATE_TOKEN = "/validate-token";

// Address book
export const API_ADDRESS_BOOK_COMMON = "/address-book/common-list";
export const API_ADDRESS_BOOK_SAVED = "/address-book/saved-list";
export const API_ADDRESS_BOOK_ADD = "/address-book/add";
export const API_ADDRESS_BOOK_REMOVE = "/address-book/remove";
