import React from 'react'
import { Field, reduxForm } from 'redux-form'

import CustomInput from 'src/components/Forms/Field';
import { required, password, newPasswordConfirm } from 'src/utils/validation';

const NewPasswordForm = props => {

    const { handleSubmit } = props;

    return (
        <form onSubmit={handleSubmit}>
            <Field
                name="password"
                component={CustomInput}
                type="password"
                validate={[required, password]}
                label="New password"
                required
            />
            <Field
                name="confirmPassword"
                component={CustomInput}
                type="password"
                validate={[required, newPasswordConfirm]}
                label="Confirm New password"
                required
            />
        </form>
    )
}

export default reduxForm({
    form: 'new-password-form'
})(NewPasswordForm)