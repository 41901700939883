import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { Button, Box, Typography, IconButton, CircularProgress, withStyles } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';

import EtherUtil from "../../utils/ethers";
import TransactionsTable from '../../components/Table';

import {changeSendingCurrency} from "../../ducks/blockchain";
import { getAssets } from "../../ducks/dashboard";
import history from "../../history";
import { ROUTE_TRANSACTION_HISTORY, ROUTE_DEFAULT_LOGGEDIN } from "../../constants/routes";
import {
  lockTransactions,
  changePageCount,
  changeTransactions,
  fetchPending
} from "../../ducks/blockchain";

const styles = theme => ({
  table_header: {
    backgroundColor: "#0082BC",
    position: "relative",
    height: "85px",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    color: "#fff",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "center",
    display: "flex",
    paddingLeft: "20px",
    paddingRight: "55px",
  },
  table_title: {
    fontWeight: 700,
    fontSize: "20px",
    color: "#fff",
  },
  table_sub: {
    fontFamily: "Mont",
    fontSize: "13px",
    margin: "5px 0 0"
  },
  refresh_button: {
    position: "absolute",
    top: "20px",
    right: "10px",
  },
  refresh_icon: {
    fill: "#fff"
  }
});

const rowsPerPage = 20;
let isUnloading = false;

class TransactionHistoryTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      refreshing: false
    };
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);    
  }

  componentWillMount() {
    isUnloading = false;
  }

  componentWillUnmount() {
    isUnloading = true;
  }

  componentDidMount = async() => {
    if(isUnloading) { return false; }
    if(!this.props.blockchain.sendingCurrency) {
      await this.loadCurrencyFromLocation();
    }
    this.refresh(false);
  }

  loadCurrencyFromLocation = async() => {
    await this.props.getAssets();
    const locationSplit = history.location.pathname.substring(history.location.pathname.indexOf(ROUTE_TRANSACTION_HISTORY) + ROUTE_TRANSACTION_HISTORY.length).split("/");
    let locationBC = locationSplit[0];
    let locationCur = locationSplit[1];
    let { userAssetTypes } = this.props.dashboard;
    if(userAssetTypes && userAssetTypes.length > 1) {
        userAssetTypes.forEach((cur, idex) => {
            if(cur.name === locationCur && cur.blockchain === locationBC) {
                this.props.changeSendingCurrency(cur.name, cur);
                return false;
            }
        });
    }
  }

  onChangePage = async (page, force) => {
    try {
      this.props.lockTransactions();      
      var { blockchain: { wallet, sendingCurrency, sendingCurrencyObj } } = this.props;
      const { transactions, count } =  await EtherUtil.getTransactions(
        sendingCurrency, 
        wallet.address, 
        sendingCurrencyObj.address,
        sendingCurrencyObj.blockchain,
        sendingCurrencyObj.bits,
        page, 
        rowsPerPage, 
        force,
        sendingCurrencyObj.type === 'erc721',
        wallet
      );      
      this.props.changePageCount(Math.ceil(count / rowsPerPage), count);
      this.props.changeTransactions(transactions, page);
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  handleBackButtonClick() {    
    try {
      history.push(ROUTE_DEFAULT_LOGGEDIN);
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  forceRefresh = async() => {
    this.refresh(true);
  }

  refresh = async(force) => {
    this.setState({ refreshing: true });  
    try {
        const promises = [];
        const sendingCurrency = this.props.blockchain.sendingCurrency;
        const wallet = this.props.blockchain.wallet;
        if (!wallet || !Object.keys(wallet).length || !sendingCurrency) {
            return;
        }
        promises.push(this.props.fetchPending(wallet.address, sendingCurrency));
        promises.push(this.onChangePage(0, force));
        await Promise.all(promises);
    } catch (err) {
        this.setState({ catchErr: err });
    } finally{
      this.setState({ refreshing: false });
    };
  };

  render() {
    if (this.state.catchErr) throw this.state.catchErr;
    const refreshing = this.state.refreshing;
    const { classes } = this.props;
    const {
      transactions,
      transactionsLoaded,
      totalCount,
      pageCount,
      page,
      pending,
      sendingCurrencyObj
    } = this.props.blockchain;
    let transactionsFinal = [].concat(pending, transactions);
    return (
    	<>        
    	 <Box className={classes.table_header}>
            <Typography variant="h5" className={classes.table_title}>
                {sendingCurrencyObj.ticker} Transactions
            </Typography>
            <Typography paragraph className={classes.table_sub}>
                Your transaction history
            </Typography>
            <IconButton className={classes.refresh_button} onClick={this.forceRefresh} disabled={!transactionsLoaded}>
                <RefreshIcon className={classes.refresh_icon} />
            </IconButton>
        </Box>
        { refreshing && (
          <div style={{"textAlign": "center", padding: "20px"}}>
              <CircularProgress />
           </div>
        )}
        { !refreshing && (<TransactionsTable 
            isPaginate
            pageCount={pageCount}
            page={page}
            rowsPerPage={rowsPerPage}
            transactionsLoaded={transactionsLoaded}
            transactions={transactionsFinal}
            totalCount={totalCount}
            onChangePage={this.onChangePage}
            isLoading={!transactionsLoaded}
            pendingLength={pending.length}
        />)}
        <Button type="submit" fullWidth variant="contained" color="primary" onClick={this.handleBackButtonClick}>Back</Button>
        </>
    );
  }
};

const mapState2props = state => ({
  dashboard: state.dashboard,
  blockchain: state.blockchain
});

const mapDispatch2props = {
  lockTransactions,
  changePageCount,
  changeTransactions,
  changeSendingCurrency,
  fetchPending,
  getAssets
};

export default compose(
  withStyles(styles),
  connect(mapState2props, mapDispatch2props)
)(TransactionHistoryTable);