import React from "react"
import { Container, Grid, CircularProgress, Typography, Button } from '@material-ui/core';
import { compose } from "redux";
import { connect } from "react-redux";
import Alert from '@material-ui/lab/Alert';

import { ROUTE_DEFAULT_LOGGEDIN, ROUTE_NX_PAYMENT } from "../../constants/routes";

import { createIn4xPaymentRequest } from "../../ducks/in4x";

class Invest extends React.Component {

  constructor(props) {
  	super(props);
  	let urlSplit = props.history.location.pathname.split("/");
  	let invSplit = props.history.location.search.split("&");
		this.state = {
			catchErr: null,
			activeStep: 0,
			cur: false, //default
			echCur: 'USD',
			partner: (urlSplit.length === 3? urlSplit[2].replace("-"," ") : 'iownx'),
			requestId: '',
			targetId: '',
			amount: 0,
			finalAmount: 0,
			targetLabel: 'Unknown Item',
			isInvestment: false,
			isCurrencyPrecelected: false,
		};
		if(invSplit && invSplit.length > 0) {
			invSplit.forEach((vU, idx) => {
				const v = vU.toLowerCase();
				if(v.indexOf('requestid=') !== -1) {
					this.state.requestId = v.substring(v.indexOf('requestid=') + 10);
				} else if(v.indexOf('targetid=') !== -1) {
					this.state.targetId = v.substring(v.indexOf('targetid=') + 9);
				} else if(v.indexOf('amount=') !== -1) {
					this.state.amount = v.substring(v.indexOf('amount=') + 7);
				} else if(v.indexOf('targetname=') !== -1) {
					this.state.targetLabel = decodeURIComponent(v.substring(v.indexOf('targetname=')+11));
				} else if(v.indexOf('isinvestment=') !== -1) {
					this.state.isInvestment = v.substring(v.indexOf('isinvestment=')+11);
				} else if(v.indexOf('currency=') !== -1) {
					const echCur = v.substring(v.indexOf('currency=')+9).toLowerCase();
					if(echCur === 'usd' || echCur === 'aed' || echCur === 'eur') {
						this.state.echCur = echCur;
					}
				} else if(v.indexOf('paywith=') !== -1) {
					this.state.cur = v.substring(v.indexOf('paywith=') + 8).toLowerCase();
				} else if(v.indexOf('key') !== -1) {
					this.state.partnerKey = v.substring(v.indexOf('key=') + 4);
				}
			});
		}
 	}

 	componentDidMount = async() => {
 		const req = {
 			RequestId: this.state.requestId,
 			Amount: this.state.amount,
 			Currency: this.state.echCur,
 			TargetName: this.state.targetLabel,
 			PartnerToken: this.state.partnerKey,
 		};
 		await this.props.createIn4xPaymentRequest(req);
 		if(this.props.in4x.in4xpaymentRequestLoaded && this.props.in4x.in4xPayment) {
 			this.props.history.push(ROUTE_NX_PAYMENT + this.props.in4x.in4xPayment.REFERENCE_ID);
 		} else if(!this.props.in4x.error) {
 			this.setState({catchErr: 'IN4X Payment not returned correctly'});
 		}
 	}

 	back = () => {
 		this.props.history.push(ROUTE_DEFAULT_LOGGEDIN);
 	}

	render() {
		if (this.state.catchErr) throw this.state.catchErr;
		const { requestId } = this.state;
		const { in4xpaymentRequestLoading, in4xpaymentRequestLoaded, error } = this.props.in4x;
    return (
        <Container component="main" className="" maxWidth="sm">
        <Grid item xs={12} className="centered-grid">
        	<img src="/img/nx-logo-wide.svg" alt={'IN4X Global'} className="spaced-top"/>
        	{ in4xpaymentRequestLoading && (
        		<Grid item xs={12} className="centered-grid">	         		
		     			<CircularProgress />
		     			<Typography color="primary" align="center" gutterBottom>Processing request...</Typography>
		     		</Grid>
		     		)}
		     	{ !in4xpaymentRequestLoading && !in4xpaymentRequestLoaded && error && (
		     		<Grid item xs={12} className="centered-grid">
		     			<Alert severity="error">{ error }</Alert>
		     			<Typography align="center" gutterBottom className="spaced-top">
		     				The link/button which took you to here is invalid, please contact the owner of the site/application to remediate
		     			</Typography>
		     			{ requestId && (
		     				<Alert severity="warning" className="spaced-top">Reference ID: <strong>{requestId}</strong></Alert>
		     			)}
		     			<Button fullWidth variant="contained" color="primary" onClick={this.back} className="spaced-top">Cancel</Button>
		     		</Grid>
		     		)}
        </Grid>
        </Container>
    )
	}
}

const mapState2props = state => ({
  in4x: state.in4x,
});

const mapDispatch2props = {
  createIn4xPaymentRequest
};

export default compose(
  connect(mapState2props, mapDispatch2props)
)(Invest);