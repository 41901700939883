import {
    CURRENCY_ETH,
    CURRENCY_MATIC,
    CURRENCY_USD,
    CURRENCY_USDC
} from "src/constants/currencies";
import {
    BLOCKCHAIN_ETH,
    BLOCKCHAIN_MATIC,
    NETWORK_MAINNET
} from "src/constants/blockchain";
import ProviderUtils from "src/utils/blockchain/ProviderUtils";
import {
    WERT_COMMODITY,
    WERT_PROD_API_KEY,
    WERT_TEST_API_KEY,
    CHANGELLY_PROD_API_KEY,
} from "./constants";

export const getWertCommodityFromTicker = (ticker, blockchain) => {
    const code = `${ticker.toLowerCase()}_${blockchain}`;

    switch(code) {
        case `${CURRENCY_ETH}_${BLOCKCHAIN_ETH}`:
            return ProviderUtils.activeNetwork() !== NETWORK_MAINNET ? WERT_COMMODITY.ETH_GOERLI: WERT_COMMODITY.ETH_MAINNET;
        case `${CURRENCY_MATIC}_${BLOCKCHAIN_MATIC}`:
            return WERT_COMMODITY.MATIC_MAINNET;
        case `${CURRENCY_USDC}_${BLOCKCHAIN_ETH}`:
            return WERT_COMMODITY.USDC_ETH;
        case `${CURRENCY_USDC}_${BLOCKCHAIN_MATIC}`:
            return WERT_COMMODITY.USDC_MATIC;
        default:
            return "";
    }
};

export const getWertLink = (params = {}) => {
    const {
        ticker = "",
        blockchain = "",
        network = "",
        wallet = ""
    } = params;

    if (!ticker) return "";

    const isMainNet = network === NETWORK_MAINNET;
    const commodity = getWertCommodityFromTicker(ticker, blockchain);
    const getParams = new URLSearchParams({
        mainnet: isMainNet,
        id: isMainNet ? WERT_PROD_API_KEY : WERT_TEST_API_KEY,
        currency: CURRENCY_USD.toUpperCase(),
        address: wallet,
        commodity: commodity,
        commodities: commodity
    });

    return `https://pay.iownx.com/api/topup/wert?${getParams.toString()}`
};

export const getChangellyLink = (params = {}) => {
    const {
        ticker = "",
        wallet = "",
    } = params;

    const getParams = new URLSearchParams({
        id: CHANGELLY_PROD_API_KEY,
        currency: CURRENCY_USD,
        address: wallet,
        commodity: ticker.toLowerCase(),
        amount: 100,
    });


    return `https://pay.iownx.com/api/topup/changelly?${getParams.toString()}`;
}