import React, { useState, useEffect } from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import PropTypes from "prop-types";

const OwnStateTextField = (props) => {
    const {
        adornment,
        value,
        onChange,
    } = props;
    const [fieldState, setFieldState] = useState(value);

    const handleChange = ({ target }) => {
        const { value } = target;
        setFieldState(value);
        onChange(value);
    }

    useEffect(() => {
        setFieldState(value);
    }, [value])

    return (
        <TextField
            {...props}
            InputProps={{
                endAdornment: <InputAdornment color="primary" position="end">{ adornment }</InputAdornment>
            }}
            value={fieldState}
            variant="outlined"
            onChange={handleChange}
        />
    )
};

OwnStateTextField.propTypes = {
    adornment: PropTypes.any,
    value: PropTypes.any,
    onChange: PropTypes.func,
};

export default OwnStateTextField;