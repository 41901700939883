import React from "react";
import {connect} from "react-redux";
import {getFormValues, isValid} from 'redux-form';
import {compose} from "redux";
import {withStyles} from "@material-ui/core";
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import EditPasswordForm from "../../components/Forms/EditPassword";
import history from '../../history';
import {editPassword, resetEdit} from "../../ducks/dashboard";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PriorityHigh from "@material-ui/icons/PriorityHigh";
import Modal from "../../components/Modal";
import IownIcon from "src/components/IownIcon";

const styles = () => ({
    iconTitle: {
        display: 'flex',
        alignItems: 'center',
    }
});

class EditPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmModal: false,
            catchErr: null,
        };
        this.props.resetEdit();
    }

    submit = () => {
        try {
            const {oldPassword, password, confirm} = this.props.formValues;
            this.props.editPassword({oldPassword: oldPassword, password, confirm: confirm});
            this.handleModal();
        } catch (err) {
            this.setState({catchErr: err});
        }
    };

    goBack = () => {
        try {
            history.goBack();
        } catch (err) {
            this.setState({catchErr: err});
        }
    };

    handleModal = () => {
        try {
            this.setState((state) => ({confirmModal: !state.confirmModal}));
        } catch (err) {
            this.setState({catchErr: err});
        }
    };

    render() {
        if (this.state.catchErr) throw this.state.catchErr;

        const {formValid, classes} = this.props;
        const {editPasswordLoaded, editPasswordError, editPasswordSuccess} = this.props.dashboard;

        return (
            <Container component="main" maxWidth="sm" className="spaced-top">
                <Grid container spacing={1} justifyContent="center" alignItems="center" direction="column">
                    <Grid item xs={12}>
                        <IownIcon />
                        <Typography variant="h5" align="center" gutterBottom color="primary">Change Password</Typography>
                        <EditPasswordForm
                            formValid={formValid}
                            editPasswordLoaded={editPasswordLoaded}
                            editPasswordError={editPasswordError}
                            editPasswordSuccess={editPasswordSuccess}
                            onSubmit={this.handleModal}
                        />

                        <Box pt={2}>
                            <Button onClick={this.goBack} fullWidth variant="contained" color="default">
                                Back
                            </Button>
                        </Box>

                        <Modal
                            open={this.state.confirmModal}
                            onClose={this.handleModal}
                            modalBody={(
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant={'h6'}
                                            color={'error'}
                                            gutterBottom
                                            className={classes.iconTitle}
                                        >
                                            <PriorityHigh color="error"/>&nbsp;Important:
                                        </Typography>

                                        <Typography gutterBottom>
                                            Are you sure you want to change your password?<br/>
                                            Remember this password encrypts your wallet private key, 
                                            and you still need the secrent phrases to access your wallet if you lose this device.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    onClick={this.handleModal}
                                                >
                                                    Close
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.submit}
                                                >
                                                    Confirm
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    }
};

const mapState2props = state => ({
    dashboard: state.dashboard,
    formValues: getFormValues('edit-password')(state),
    formValid: isValid('edit-password')(state)
});

const mapDispatch2props = {
    editPassword,
    resetEdit
};

export default compose(
    withStyles(styles),
    connect(mapState2props, mapDispatch2props)
)(EditPassword);
