export const QUICK_NODE = {
    ETH_MAINNET: 'https://winter-cool-surf.quiknode.pro/fd89bbe33ec7dfa9dfbfc2cb08ccf17cf3ced06c/',
    BSC_MAINNET: 'https://attentive-broken-isle.bsc.quiknode.pro/5872c5e18e8b1de81cba918a7913c3c24d969168/',
    MATIC_MAINNET: 'https://green-crimson-resonance.matic.quiknode.pro/d2227e7976bca8ed98834f7930900b1015a19bda/',
    // ETH_ROPSTEN: 'https://still-restless-glade.ropsten.quiknode.pro/b750db886006d5188deba1e8b4da346d230bae57/', //Removed
    ETH_GOERLI: 'https://delicate-patient-shard.ethereum-goerli.quiknode.pro/a6a29527f4ebeff94e9394e93ebcdebf370a9e69/',
    ETH_TESTNET: 'https://still-restless-glade.ropsten.quiknode.pro/b750db886006d5188deba1e8b4da346d230bae57/', //Copy
    BSC_TESTNET: 'https://evocative-cosmological-firefly.bsc-testnet.quiknode.pro/579b599b329ffbb315b24ab9ac1880da7c954d4a/',
    MATIC_MUMBAI: 'https://cool-fragrant-meadow.matic-testnet.quiknode.pro/d20f21814d5d40a54dcf293cf06d9c0d4bbf442a/',
    MATIC_TESTNET: 'https://cool-fragrant-meadow.matic-testnet.quiknode.pro/d20f21814d5d40a54dcf293cf06d9c0d4bbf442a/', //copy
}

const moralisApiKey = process.env.REACT_APP_MORALIS_ID || '9e439e8e5a1fc8e0a8df533d';

export const MORALIS = {
    ETH_MAINNET: 'https://speedy-nodes-nyc.moralis.io/' + moralisApiKey + '/eth/mainnet',
    BSC_MAINNET: 'https://speedy-nodes-nyc.moralis.io/' + moralisApiKey + '/bsc/mainnet',

    ETH_ROPSTEN: 'https://speedy-nodes-nyc.moralis.io/' + moralisApiKey + '/eth/ropsten',
    ETH_GOERLI: 'https://speedy-nodes-nyc.moralis.io/' + moralisApiKey + '/eth/goerli',
    BSC_TESTNET: 'https://speedy-nodes-nyc.moralis.io/' + moralisApiKey + '/bsc/testnet',
}

// Taken from Metamask, Matic and BSC are from scanner sites like bscscan.com when they add network to metamask
export const FALLBACK = {
    ETH_MAINNET: 'https://mainnet.infura.io/v3/',
    BSC_MAINNET: 'https://bsc-dataseed1.binance.org/',
    MATIC_MAINNET: 'https://polygon-rpc.com/',
    ETH_ROPSTEN: 'https://ropsten.infura.io/v3/',
    ETH_GOERLI: 'https://goerli.infura.io/v3/',
    BSC_TESTNET: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    MATIC_MUMBAI: 'https://matic-mumbai.chainstacklabs.com',
}

export const ALCHEMY = {
    MATIC_MAINNET: 'https://polygon-mainnet.g.alchemy.com/v2/ZBUoFgZgupvFZLQP6-gkuBCTfif2OOWQ',
    MATIC_MUMBAI: 'https://polygon-mumbai.g.alchemy.com/v2/ZxG_9mUpBQh2ZnlJC01f664WU1VTVKvi',
}
