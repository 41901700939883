import ProviderUtils from "./ProviderUtils";
import { BLOCKCHAIN_ETH, BLOCKCHAIN_BSC, BLOCKCHAIN_MATIC } from "src/constants/blockchain";
import { API_GAS_ORACLE } from "src/constants/apiRoutes";
import { KEY_GAS_METRICS } from "src/constants/storageKeys";
import { CURRENCY_ETH, CURRENCY_BNB, CURRENCY_MATIC } from "src/constants/currencies";
import { 
    GAS_FEE_METASWAP,
    GAS_FEE_TYPE_LONDON,
    GAS_FEE_TYPE_LEGACY
} from "src/constants/gasFeeTypes";
import StorageUtils from "../storageUtils";
import BNUtils from "../bnUtils";

import { handleFetch } from "src/utils/fetch";
import { performResult } from "src/utils/stateManipulator";
import { transformError } from "src/utils/transformErrors";

const ethers = require("ethers");

export default class GasUtils {

    static activeGasMetricType() {
        return StorageUtils.getItem(KEY_GAS_METRICS) || GAS_FEE_METASWAP;
    }

    static recommendedGasType() {
        switch(ProviderUtils.activeBlockchain()) {
            case BLOCKCHAIN_ETH:
            case BLOCKCHAIN_MATIC:
                return GAS_FEE_TYPE_LONDON;
            default:
                return GAS_FEE_TYPE_LEGACY;
        }
    }

    static gasUnitsForBlockchain() {
        switch(ProviderUtils.activeBlockchain()) {
            case BLOCKCHAIN_ETH:
                return CURRENCY_ETH;
            case BLOCKCHAIN_BSC:
                return CURRENCY_BNB;
            case BLOCKCHAIN_MATIC:
                return CURRENCY_MATIC;
            default:
                return CURRENCY_BNB;
        }
    }

    /**
     * @dev Loads gas oracle from backend for a given blockchain and network
     * @param {string} `blockchain` Blockchain code (ex: 'bsc')
     * @param {string} `network` A blockchain network, anything other than 'mainnet' is consider testnetwork
     * @returns {object} `gasMetrics` Gas metrics from oracle endpoint
     */
    static loadGasOracle = async(blockchain, network) => {
        if(!blockchain) {
            blockchain = ProviderUtils.activeBlockchain();
        }
        if(!network) {
            network = ProviderUtils.activeNetwork();
        }
        return handleFetch(API_GAS_ORACLE, "POST", {blockchain, network})
            .then(res => performResult(res, () => {
                return res.payload;
            }))
            .catch(err => () => {
                return transformError(err)
            });
    }

    /**
     * @dev Chooses default speed provided by the gas oracle
     * @returns {Object} `gasParams` {gas, maxFeePerGas, maxPriorityFeePergas}...etc
     */
    static defaultGas = (gasMetrics) => {
        if(gasMetrics && gasMetrics.defaultSpeed && gasMetrics[gasMetrics.defaultSpeed]){
            return GasUtils.bigNumberifyGas(gasMetrics[gasMetrics.defaultSpeed]);
        }
        return {};
    }

    /**
     * @description Converts an object conaining gas units to hax representation of big numbers
     * @param {object} `gasOverrides` An object containing one or more of gas params as keys (like gasLimit, gas, maxFeePerGas..etc)
     * @returns {object} Updated overrides
     */
    static bigNumberifyGas = (gasOverrides) => {
        if(!gasOverrides || typeof gasOverrides !== 'object') {
            return gasOverrides;
        }
        if(gasOverrides.gasLimit) {
            gasOverrides.gasLimit = ethers.utils.parseUnits(`${gasOverrides.gasLimit}`, 'wei').toHexString();
        }
        if(gasOverrides.gasPrice) {
            gasOverrides.gasPrice = ethers.utils.parseUnits(`${gasOverrides.gasPrice}`, 'gwei').toHexString();
        }
        if(gasOverrides.maxFeePerGas) {
            gasOverrides.maxFeePerGas = ethers.utils.parseUnits(BNUtils.ensureDecimals(`${gasOverrides.maxFeePerGas}`, 9), 'gwei').toHexString();   
        }
        if(gasOverrides.maxPriorityFeePerGas) {
            gasOverrides.maxPriorityFeePerGas = ethers.utils.parseUnits(BNUtils.ensureDecimals(`${gasOverrides.maxPriorityFeePerGas}`, 9), 'gwei').toHexString();   
        }
        return gasOverrides;
    }

    /**
     * @name generateTransactionGas
     * @description Creates an object containing properties related to gas (limit, price..etc)
     * @param {object} `blockchainGasProps` BlockchainGas duck values (object)
     * @return {object} formatted new transaction gas
     */
    static generateTransactionGas = blockchainGasProps => {
        try {
            let gasLimit = ethers.utils.parseUnits(blockchainGasProps.gasLimit.value+'', 'wei');
            switch(GasUtils.recommendedGasType()) {
                case GAS_FEE_TYPE_LONDON: 
                    let maxFeePerGas = ethers.utils.parseUnits(BNUtils.ensureDecimals(blockchainGasProps.maxFeePerGas.value, 9)+'', 'gwei');
                    let maxPriorityFeePerGas = ethers.utils.parseUnits(BNUtils.ensureDecimals(blockchainGasProps.maxPriorityFeePerGas.value, 9)+'', 'gwei');
                    return {
                        gasLimit: gasLimit.toHexString(),
                        maxFeePerGas: maxFeePerGas.toHexString(),
                        maxPriorityFeePerGas: maxPriorityFeePerGas.toHexString(),
                    };
                default:
                    let gasPrice = ethers.utils.parseUnits(blockchainGasProps.gasPrice.value+'', 'gwei');
                    return {
                        gasLimit: gasLimit.toHexString(),
                        gasPrice: gasPrice.toHexString()
                    };
            }
        } catch(e) {
            throw new Error('GAS_CALCULATION_ERROR');
        }
    };
}