import React from "react";
import PropTypes from "prop-types";
import { getError } from "src/utils/transformErrors";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";

const ErrorAlert = (props) => {
    const {
        isVisible = true,
        donNotTransform = false,
        onClose = () => {},
        error = "",
    } = props;

    if (!isVisible) return null;

    const nextError = donNotTransform ? error : getError(error);

    return(
        <Grid item xs={12} align="center" className="left-grid">
            <Alert
                variant="outlined"
                severity="error"
                onClose={onClose}
            >
                { nextError }
            </Alert>
        </Grid>
    );
}

ErrorAlert.propTypes = {
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
    error: PropTypes.string,
    donNotTransform: PropTypes.bool,
};

export default ErrorAlert;