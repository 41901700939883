import React from "react";

import { Link } from "react-router-dom";
import { Grid, Typography, Button } from '@material-ui/core';

import { ROUTE_NX_ACTIVATE } from "../../constants/routes";

let In4xKycActivate = props => {
 	return (
		<Grid item xs={12} style={{paddingTop: "4em", textAlign: 'center'}}>
            <Typography variant="h6" className="color-nx" align="center" gutterBottom>IN4X Global Exchange Account</Typography>
            <img src="/img/in4x-how-it-works.png" alt="IN4X Exchange" />
            <Typography className="color-nx spaced-top" align="center" gutterBottom>
              IN4X Global is your exchange gateway for fiat - token transactions!
            </Typography>
            <Typography className="color-nx spaced-top" align="center" gutterBottom>
              Activate your IN4X Global account to be able to deposit money using wire transfer and cards.
            </Typography>
            <Link to={ROUTE_NX_ACTIVATE}>
                <Button className="bg-nx spaced-top" variant="contained" fullWidth>Activate</Button>
            </Link>
        </Grid>
    )
};

export default In4xKycActivate;