let apiLoaded = null;

export const LoadUserSnap = (userInfo) => {
	window.onUsersnapCXLoad = function(api) {
		if(userInfo && userInfo.email && userInfo.wallet) {
			api.init({
			    user: {
			    	userId: userInfo.wallet,
			    	email: userInfo.email
			   	}
			});
		} else {
    		api.init();
    	}
    	apiLoaded = api;
    }
    var script = document.createElement('script');
    script.defer = 1;
    script.src = 'https://widget.usersnap.com/global/load/523caea1-bc8b-47f9-b4b0-3de1809d3b87?onload=onUsersnapCXLoad';
    document.getElementsByTagName('head')[0].appendChild(script);
};

export const ShowUserSnap = () => {
	if(!window.onUsersnapCXLoad) {
		LoadUserSnap();
	}
	
	if(apiLoaded && process.env.REACT_APP_USERSNAP_KEY) {
		apiLoaded.show(process.env.REACT_APP_USERSNAP_KEY);
	}
};

export const IsUserSnapLoaded = () => {
	return apiLoaded && window.onUsersnapCXLoad !== undefined;
}