import React from "react";
import sha512 from "js-sha512";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { compose } from "redux";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import BiometricsUnlock from 'src/components/BiometricsUnlock';
import CheckPassword from "src/views/CheckPassword"; //TODO: This should be a component

import BlockchainLabel from "src/common/blockchain-label";

import { checkWalletPassword, loginWithWallet } from "src/ducks/signin";

const BlockchainTransactionConfirm = (props) => {
	const {
		onBack = () => {},
		onValidPassword = () => {},
		hideLabel = false,
	} = props;

	const [passwordError, setPasswordError] = React.useState(null);

	const hashAndCheckPassword = ({ password }) => {
		checkPassword(sha512(password));
	}

	const checkPassword = async(hashPassword) => {
		try {
			const wallet = await props.checkWalletPassword(hashPassword);
            if (wallet) {
                const result = await props.loginWithWallet(wallet, hashPassword);
                if (result && result.success) {
                	onValidPassword();
                }
            }
            setPasswordError('Invalid password');
		} catch(err) {
			if (err && err.message) {
                setPasswordError(err.message);
            }
			setPasswordError('Error checking password: ' + err);
		}
	}

	return (
		<Grid item xs={12} className="centered-grid spaced-top">
			{!hideLabel && (<BlockchainLabel />)}
            <Typography variant="h5" align="center" color="primary" gutterBottom>
                Confirm Your Transaction
            </Typography>
            <CheckPassword
                onSubmit={hashAndCheckPassword}
                goBack={onBack}
                signin={{failed: !!passwordError, error: passwordError}}
            />
            <BiometricsUnlock onSuccess={checkPassword} />
        </Grid>);
}

BlockchainTransactionConfirm.prototype = {
	onValidPassword: PropTypes.func,
    onBack: PropTypes.func,
    processingLabel: PropTypes.string
}

const mapDispatch2props = {
	checkWalletPassword,
	loginWithWallet
}

export default compose(
    connect(null, mapDispatch2props)
)(BlockchainTransactionConfirm);