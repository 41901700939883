import React from "react"
import history from "../../history";
import LinearProgress from "@material-ui/core/LinearProgress";

import { ROUTE_DEFAULT_LOGGEDIN } from "../../constants/routes";

const Resume = (props) => {
	let toUrl = history.location.search.indexOf('to=') === -1? ROUTE_DEFAULT_LOGGEDIN: history.location.search.substring(5);

	 setTimeout(() => {
        history.replace(toUrl);
      }, 1000);
        
    return (
        <div style={{textAlign: "center", marginTop: "10%"}}>
        	<LinearProgress />
        </div>
    )
}

export default Resume;