import { KEY_ACTIVE_ETH_NET } from "../constants/storageKeys";
import StorageUtils from "../utils/storageUtils";

export default class CacheUtil {

    static getCache(primaryCache, cacheKey) {
        let network = StorageUtils.getItem(KEY_ACTIVE_ETH_NET) || process.env.REACT_APP_DEFAULT_NETWORK || "mainnet";
        primaryCache = primaryCache + "_" + network;
        let cached = StorageUtils.getItem(primaryCache);
        if(!cached) {
            return null;
        }        
        cached = JSON.parse(cached);
        if(!cached || Object.keys(cached).length === 0) {
            return null;
        }
        let recache = false;
        Object.entries(cached).map((v,idx) => {
            if(v.since <= Date.now() - 300000) {
                recache = true; //force a save
                delete cached[idx];
            }
            return v;
        });
        if(recache && cached.length) {
            StorageUtils.setItem(primaryCache, cached);
        }
        if(Object.entries(cached).length === 0) {
            StorageUtils.removeItem(primaryCache);   
        }
        return (cached[cacheKey] && cached[cacheKey].value ? cached[cacheKey].value:null);
    }

    static setCache(primaryCache, cacheKey, cachedValue) {
        let network = StorageUtils.getItem(KEY_ACTIVE_ETH_NET) || process.env.REACT_APP_DEFAULT_NETWORK || "mainnet";
        primaryCache = primaryCache + "_" + network;
    	let cached = StorageUtils.getItem(primaryCache);
    	if(!cached) {
    		cached = {};
    	} else {
            cached = JSON.parse(cached);
        }
    	cached[cacheKey] = {value: cachedValue, since: Date.now()};
    	StorageUtils.setItem(primaryCache, JSON.stringify(cached));
    }
};