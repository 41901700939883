import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import sha512 from "js-sha512";
import CheckPassword from "../../../../../../src/views/CheckPassword";
import BiometricsUnlock from "../../../../../../src/components/BiometricsUnlock";
import ProcessingAlert from "src/common/processing-alert";
import { submitWithdrawal } from "./utils"
import TransactionCreatedModal from "../../../../../views/modals/TransactionCreatedModal";

const UnStakeProcess = (props) => {
    const {
        actions = {},
        stakeData = {},
        onError = val => val,
        onPrvClick = () => {},
        goToDashBoard = () => {},
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [transactionHash,setTransactionHash] = useState();
    const { getStakingData } = actions;
    const { dashboard, wallet } = stakeData;
    const onSuccess = (transactionHash) => {
        setIsLoading(false);
        setTransactionHash(transactionHash);
    };

    const handleFinish = async () => {
        const { currencyIown } = dashboard
        await getStakingData(currencyIown, wallet);
        goToDashBoard()
    };

    const handleSubmit = async (passwordHash) => {
        setIsLoading(true);
        await submitWithdrawal({
            ...stakeData,
            ...actions,
            onError,
            onSuccess,
            passwordHash,
        })
    };

    const handlePasswordSubmit = async ({ password }) => {
        const passwordHash = sha512(password);
        await handleSubmit(passwordHash);
    };

    return (
        <Grid item xs={12}>
            <TransactionCreatedModal
                openOn={!!transactionHash}
                onClose={handleFinish}
                trxhash={transactionHash}
            />
            {
                isLoading ? (
                    <ProcessingAlert
                        message={"Processing request..."}
                    />
                ) : (
                    <>
                        <CheckPassword
                            onSubmit={handlePasswordSubmit}
                            goBack={onPrvClick}
                        />
                        <BiometricsUnlock onSuccess={handleSubmit}/>
                    </>
                )
            }
        </Grid>
    )
};

UnStakeProcess.propTypes = {
    actions: PropTypes.object,
    onError: PropTypes.func,
    goToDashBoard: PropTypes.func,
};

export default UnStakeProcess