import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Drawer, IconButton } from "@material-ui/core";
import { Toolbar, Badge } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import { Chip, Menu, MenuItem } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';

import { changeWallet } from "../../ducks/blockchain";
import StorageUtils from "../../utils/storageUtils";
import ProviderUtils from "../../utils/blockchain/ProviderUtils";

import ImportExportIcon from '@material-ui/icons/ImportExport';
import {
  isGuestRoute,
  isNetworkSwitchingAllowed
} from "../../constants/routes";
import {
  KEY_CFG_MNEMONIC_SHOWN,
  KEY_CFG_STAKING_SHOWN
} from "../../constants/storageKeys";
import { getCurrencyIcon, CURRENCY_BNB, CURRENCY_MATIC, CURRENCY_ETH } from '../../constants/currencies';
import { BLOCKCHAIN_ETH, BLOCKCHAIN_BSC, BLOCKCHAIN_MATIC, NETWORK_MAINNET, getBlockchainName } from "../../constants/blockchain";
import { EVENT_PROVIDER_BLOCKCHAIN_CHANGE, EVENT_PROVIDER_NETWORK_CHANGE } from "../../constants/events";
import SidebarMenu from "../sidebarMenu";

class Header extends React.Component {
  state = {
    sidebarOpened: false,
    menuOpened: false,
    networkMenuOpened: false,
    networkMenuAnchorEl: null,
    catchErr: null,
    safeArea: {}
  };

  componentDidMount() {
    this.unlisten = this.props.history.listen(this.closeMenu);
    const { onBlockchainNetworkChange } = this;
    window.addEventListener(EVENT_PROVIDER_BLOCKCHAIN_CHANGE, onBlockchainNetworkChange);
    window.addEventListener(EVENT_PROVIDER_NETWORK_CHANGE, onBlockchainNetworkChange);
  }

  componentWillUnmount() {
    if(this.unlisten) {
      this.unlisten();
      this.unlisten = null;
    }
    const { onBlockchainNetworkChange } = this;
    window.removeEventListener(EVENT_PROVIDER_BLOCKCHAIN_CHANGE, onBlockchainNetworkChange);
    window.removeEventListener(EVENT_PROVIDER_NETWORK_CHANGE, onBlockchainNetworkChange);
  }

  onBlockchainNetworkChange = () => {
    this.setState({});
  }

  openSidebar = () => {
    this.setState({ sidebarOpened: true });
  };

  closeSidebar = () => {
    this.setState({ sidebarOpened: false });
  };

  openMenu = () => {
    this.setState({ menuOpened: true });
  };

  closeMenu = () => {
    this.setState({ menuOpened: false });
  };

  handleClose = (selectedBlockchain) => {
    try {
      this.setState({ networkMenuOpened: false });
      if (typeof selectedBlockchain === "string") {
        ProviderUtils.changeDefaultBlockchain(selectedBlockchain);
        if(ProviderUtils.activeNetwork() !== NETWORK_MAINNET) {
          const testNet = ProviderUtils.pickTestnetForBlockchain(selectedBlockchain);
          ProviderUtils.changeDefaultNetwork(testNet);
        }
        this.props.changeWallet(this.props.wallet.connect(ProviderUtils.activeProvider()));

      }
    } catch (err) {
      this.setState({ catchErr: err });
    }
  }

  openNetworkMenu = (e) => {
    this.setState({ networkMenuOpened: true, networkMenuAnchorEl: e.currentTarget });
  }

  render() {
    if (this.state.catchErr) throw this.state.catchErr;

    const { wallet, history } = this.props;
    const hasNewFeature = !StorageUtils.getItem(KEY_CFG_MNEMONIC_SHOWN) || !StorageUtils.getItem(KEY_CFG_STAKING_SHOWN);
    const { networkMenuOpened, networkMenuAnchorEl } = this.state;
    const currencyBlockchain = ProviderUtils.activeBlockchain();
    const currentNetwork = ProviderUtils.activeNetwork();
    const chainLabel = getBlockchainName(currencyBlockchain);
    const isDashboard = isNetworkSwitchingAllowed(history.location.pathname);
    const isTestNet = currentNetwork !== "mainnet";

    return (
      <Toolbar id="header" className="header" component="header">
          {wallet && !!Object.keys(wallet).length && !isGuestRoute(history.location.pathname) && (
            <>
              <IconButton onClick={this.openMenu} className="header__menu-btn__right">
                <MenuIcon />
                <Badge color="secondary" badgeContent=" " variant="dot" overlap="rectangular" className={(hasNewFeature? "":"MuiBadge-invisible")}><span></span></Badge>
              </IconButton>
            </>
          )}
          <Drawer anchor="left" open={this.state.menuOpened} onClose={this.closeMenu}>
            <SidebarMenu />
          </Drawer>
          <div className="header__flex">
          </div>
          {wallet && !!Object.keys(wallet).length && (
            <>
            { currentNetwork !== NETWORK_MAINNET && (
              <Chip size="small" label="TestNet" color="secondary" />
            )}
            <IconButton aria-controls="network-menu" aria-haspopup="true" onClick={this.openNetworkMenu} disabled={!isDashboard}>
              {currencyBlockchain && (
                <Typography style={{fontSize: "12px"}}>{chainLabel}</Typography>
              )}
              <ImportExportIcon />
            </IconButton>
            <Menu
              id="network-menu"
              anchorEl={networkMenuAnchorEl}
              keepMounted
              open={networkMenuOpened}
              onClose={this.handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={() => {this.handleClose(BLOCKCHAIN_BSC)}} className={`${currencyBlockchain===BLOCKCHAIN_BSC?"active":""}`}><img src={getCurrencyIcon(CURRENCY_BNB)} style={{maxHeight:"30px",marginRight:"10px"}} alt="Bsc"/>Binance (BSC)</MenuItem>
              <MenuItem onClick={() => {this.handleClose(BLOCKCHAIN_ETH)}} className={`${currencyBlockchain===BLOCKCHAIN_ETH?"active":""}`}><img src={getCurrencyIcon(CURRENCY_ETH)} style={{maxHeight:"30px",marginRight:"10px"}} alt="Eth"/>Ethereum (ETH)</MenuItem>
              {!isTestNet && (
                  <MenuItem onClick={() => {this.handleClose(BLOCKCHAIN_MATIC)}} className={`${currencyBlockchain===BLOCKCHAIN_MATIC? "active":""}`}><img src={getCurrencyIcon(CURRENCY_MATIC)} style={{maxHeight:"30px",marginRight:"10px"}} alt="Matic"/>Polygon (MATIC)</MenuItem>
              )}
            </Menu>
            </>
          )}
        </Toolbar>
    );
  }
}

const mapStateToProps = state => ({
  wallet: state.blockchain.wallet
});

const mapDispatchToprops = {
  changeWallet
};

export default connect(
  mapStateToProps,
  mapDispatchToprops
)(withRouter(Header));