import React from "react";
import { Typography, Paper, CircularProgress } from '@material-ui/core';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';
import BlockExplorerUtils from "../../utils/blockchain/BlockExplorerUtils";

import { openLink } from '../../utils/linksOpenPolyfill';
import ProviderUtils from "../../utils/blockchain/ProviderUtils";
import EtherUtil from "../../utils/ethers";

class TransactionStatusBlock extends React.Component {

  	constructor(props) {
	    super(props);
	    this.state = {
	    	trxStatus: {isError: false, message: 'Unknown Status'},
	    	isTrxRefreshing: false
	    }
	};

	componentDidMount = async() => {
		if(this.props.hash) {
			this.refreshTransaction();
		}
	}

	refreshTransaction = async() => {
	  	this.setState(() => ({ isTrxRefreshing: true }));
		const trx = await EtherUtil.getTransaction(this.props.hash, this.props.blockchain);
		let trxStatus = {
			isError: true,
			message: 'Unknown Status'
		};
		if(trx && trx.blockNumber && trx.confirmations > 1) {// Mined
			const trxReceipt = await EtherUtil.getTransactionReceipt(this.props.hash, this.props.blockchain);
			if(trxReceipt && trxReceipt.status === 1) {
				trxStatus.isError = false;
				trxStatus.message = 'Transaction Success';
			} else {
				trxStatus.isError = true;
				trxStatus.message = 'Transaction Failed';
			}
		} else if(trx) {//we have a transaction but not mined yet
			trxStatus.isError = false;
			trxStatus.message = 'Transaction Created';
		}
		this.setState(() => ({ isTrxRefreshing: false, trxStatus }));
	}

	render() {
		const { hash, blockchain } = this.props;
		const { trxStatus, isTrxRefreshing } = this.state;
		const trxBlockchain = blockchain || ProviderUtils.activeBlockchain();
		const scanUrl = BlockExplorerUtils.scanUrl(hash, trxBlockchain);

		return (
			<Accordion TransitionProps={{ unmountOnExit: true }} defaultExpanded>
		        <AccordionSummary
		          expandIcon={<ExpandMoreIcon />}
		          aria-controls="panel1a-content"
		          id="panel1a-header"
		        >
		        	<Typography className="color-nx">Transaction Summary</Typography>
		        </AccordionSummary>
  				<AccordionDetails style={{padding: '0'}}>
	    			<TableContainer component={Paper}>
				      <Table size="small" aria-label="a dense table">
				        <TableBody>
				        	<TableRow key="ta">
				              <TableCell component="th" scope="row">Trx Hash</TableCell>
				              <TableCell align="right">
				              	<Typography fontSize="2" className="color-nx" style={{wordBreak: "break-all"}}>
					              	<a className="color-nx" href={scanUrl}
					                  target="_blank" rel="noopener noreferrer"
					                  onClick={(e) => openLink(e, scanUrl)}>
					              		{hash}
					              	</a>
				              	</Typography>
				              </TableCell>
				            </TableRow>
				            { !isTrxRefreshing && trxStatus.isError && (<TableRow key="ts">
				              <TableCell component="td" scope="row" colSpan={2}>
				              	<Alert variant="outlined" severity="error">{trxStatus.message}</Alert>
				              </TableCell>
				            </TableRow> )}
				            { !isTrxRefreshing && !trxStatus.isError && (<TableRow key="ts">
				              <TableCell component="td" scope="row" colSpan={2}>
				              	<Alert variant="outlined" severity="success">{trxStatus.message}</Alert>
				              </TableCell>
				            </TableRow> )}
				            { isTrxRefreshing && (<TableRow key="ts">
				              <TableCell component="td" scope="row" colSpan={2} style={{ textAlign: "center" }}>
				              	<CircularProgress color="secondary" />
				              </TableCell>
				            </TableRow> )}
				        </TableBody>
				      </Table>
				    </TableContainer>
			    </AccordionDetails>
			</Accordion>
		);
	};
}

export default TransactionStatusBlock;