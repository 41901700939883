import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { Grid, LinearProgress, TextField } from "@material-ui/core";

import {
	changeGasLimit,
	changeGasPrice,
} from "../../ducks/blockchainGas";

let AdvancedGasLegacy = props => {
	const { gasLimit, gasPrice, gasLoading } = props.blockchainGas;
	const { changeGasLimit, calculateFee, changeGasPrice } = props;

	const updateFee = () => {
		if(!gasPrice.error && !gasLimit.error) {
			calculateFee(gasLimit.value, gasPrice.value);
		}
	}

	return (
		<Grid item xs={12}>
		  { gasLoading && <LinearProgress />}
		  <TextField
		      label={"Price (GWEI)"}
		      error={gasPrice.error}
		      value={gasPrice.value}
		      helperText={gasPrice.helperText}
		      type="number"
		      onChange={(e) => {
		      	changeGasPrice(e.target.value);
		      	updateFee();
		      }}
		      fullWidth
		      margin="normal"
		      variant="outlined"
		  />
		  <TextField
		      label={"Limit (WEI)"}
		      error={gasLimit.error}
		      value={gasLimit.value}
		      helperText={gasLimit.helperText}
		      onChange={(e) => {
		        changeGasLimit(e.target.value);
		        updateFee();
		      }}
		      fullWidth
		      margin="normal"
		      variant="outlined"
		  />
		</Grid>
	);
}

const mapState2props = state => ({
    blockchainGas: state.blockchainGas,
});

const mapDispatch2props = {
	changeGasLimit,
	changeGasPrice
}

export default compose(connect(mapState2props, mapDispatch2props))(AdvancedGasLegacy);