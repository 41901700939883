import React from "react";
import { connect } from "react-redux";
import { getFormValues, isValid } from "redux-form";
import sha512 from "js-sha512";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

import SignInOldForm from "../../components/Forms/SignInOld";
import history from "src/history";
import {
  loginWithWallet,
  resetSignIn,
  checkWalletPassword
} from "src/ducks/signin";
import { changeWallet } from "src/ducks/blockchain";
import { ROUTE_INDEX, isNextAllowed, ROUTE_DEFAULT_LOGGEDIN } from "src/constants/routes";

import BiometricsUnlock from "src/components/BiometricsUnlock";
import {getError} from "src/utils/transformErrors";
import ProviderUtils from "src/utils/blockchain/ProviderUtils";

class OldSignIn extends React.Component {
  state = {
    catchErr: null,
    isSubmitting: false,
  };

  componentDidMount() {
    this.props.resetSignIn();
    if(this.props.userLoaded) {
      history.replace(ROUTE_DEFAULT_LOGGEDIN);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { failed } = this.props.signin;

    if (failed && this.state.isSubmitting) {
        this.setState({ isSubmitting: false });
    }
  }

  getNextUrl() {
    let nextUrl = ROUTE_DEFAULT_LOGGEDIN;
    if(history.location.search && history.location.search.indexOf('next=') !== -1) {
      const qStart = history.location.search.indexOf('next=') + 5;      
      const historyNext = history.location.search.substring(qStart);
      if(isNextAllowed(historyNext)) {
        return historyNext;
      }
    }
    return nextUrl;
  }

  submitLoginWallet = async () => {
    try {
      this.setState({isSubmitting: true});
      if (this.props.formValid) {
        const { password } = this.props.formValues;
        await this.processLoginWallet(sha512(password));
      } else {
        this.setState({isSubmitting: false});
      }      
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  processLoginWallet = async(hashPassword) => {
    try {
      const wallet = await this.props.checkWalletPassword(hashPassword);
      if (wallet) {
        this.props.updatePasswordHash(hashPassword);
        this.props.changeWallet(wallet);
        const result = await this.props.loginWithWallet(wallet, hashPassword);
        if (result && result.success) {
          ProviderUtils.resetNetwork();
          history.push(this.getNextUrl());
        } else if(result && result.err) {
          this.setState({ showErr: result.err });
        }
      } else {
        this.setState({ showErr: getError('INCORRECT_PASSWORD') });
        this.setState({isSubmitting: false});
      }
    } catch(err) {
      this.setState({ catchErr: err, isSubmitting: false });
    }
  }

  goBack() {
    try {
      history.push(ROUTE_INDEX);
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  render() {
    const { showErr, catchErr } = this.state;
    if (catchErr) throw this.state.catchErr;

    let { failed, error } = this.props.signin;

    let hasNewLine = false;
    let errorsArray = [];
    if(error && error.length) {
      errorsArray = error.split("<br/>");
    }
    if (failed) {
      if (errorsArray.length > 1) {
        hasNewLine = true;
      }
    }

    if(showErr) {     
      error = this.state.showErr;
      failed = true;
    }

    const { formValid, userAssetTypesLoading } = this.props;

    if(userAssetTypesLoading) {
      return (<></>);
    }

    return (
      <>
        <SignInOldForm onSubmit={this.submitLoginWallet} />
        {!hasNewLine ? (
            <>
              {failed && (
                  <div className="register__error" dangerouslySetInnerHTML={{ __html: error }}></div>
              )}
            </>
        ) :
          (
            <div className="register__error">
              {failed && (
                  <>
                    <div className="register__error" dangerouslySetInnerHTML={{ __html: errorsArray[0] }}></div>
                    <div className="register__error" dangerouslySetInnerHTML={{ __html: errorsArray[1] }}></div>
                  </>
              )}
            </div>
          )}
        
        {this.state.isSubmitting && (
          <div align="center" className="spaced-top">
            <CircularProgress />
          </div>
          )}

        {!this.state.isSubmitting && (<Button
          className="spaced__btn"
          onClick={this.submitLoginWallet}
          fullWidth
          variant="contained"
          disabled={!formValid || this.state.isSubmitting}
          color="primary"
        >
          Submit
        </Button>)}


        {!this.state.isSubmitting && (<Box pt={2}>
          <Button
            onClick={this.goBack}
            fullWidth
            variant="contained"
            color="default"
          >
            Back
          </Button>
        </Box>)}

        <BiometricsUnlock onSuccess={this.processLoginWallet} />
      </>
    );
  }
}

const mapState2props = state => ({
  history: state.history,
  userLoaded: state.dashboard.userLoaded,
  userAssetTypesLoading: state.dashboard.userAssetTypesLoading,
  signin: state.signin,
  formValues: getFormValues("signin-old")(state),
  formValid: isValid("signin-old")(state)
});

const mapDispatch2props = {
  loginWithWallet,
  changeWallet,
  resetSignIn,
  checkWalletPassword
};

export default connect(
  mapState2props,
  mapDispatch2props
)(OldSignIn);
