import React from "react";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core";

import IownIcon from "src/components/IownIcon";
import BiometricsActivate from "../../components/BiometricsActivate";

const styles = theme => ({
    title: {
        fontWeight: 600,
        fontSize: "31px",
        textAlign: "center",
        marginTop: "20px",
        marginBottom: "20px",
        color: "#0082BC"
    },
})

const BiometricsEnable = (props) => {
    const { classes } = props;
    return (
        <Container component="main" maxWidth="sm">
            <IownIcon />
            <Typography variant="h5" align="center" gutterBottom className={classes.title}>
                Activate Biometrics
            </Typography>

            <BiometricsActivate />
        </Container>
    );
};

export default withStyles(styles)(BiometricsEnable);
