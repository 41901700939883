import WalletUtil from "src/utils/blockchain/WalletUtils";
import { fetchHelper } from "src/utils/helpers";
import { API_SIGNIN_WALLET } from "src/constants/apiRoutes";
import { transformError } from "src/utils/transformErrors";

export const loginWithWallet = async (params) => {
    const {
        passwordHash = "",
        onStart = () => {},
        onSuccess = () => {},
        onError = () => {},
    } = params;

    const wallet = await WalletUtil.getWalletInstance(passwordHash);

    if (!wallet) {
        onError({
            failed: true,
            error: transformError("INCORRECT_PASSWORD"),
        });
        return;
    }
    onStart();
    fetchHelper({
        endPoint: API_SIGNIN_WALLET,
        method: "POST",
        fetchParams: {
            wallet: wallet?.address,
            password: passwordHash,
        },
        onSuccess,
        onFail: (error) => {
            onError(error)
        },
    })
}