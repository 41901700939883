import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import history from "../../history";
import {
  Box,
  Typography,
  Menu, MenuItem,
  CircularProgress,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import CropFreeIcon from '@material-ui/icons/CropFree';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import PageviewIcon from '@material-ui/icons/Pageview';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import {
    ROUTE_SEND,
    ROUTE_STAKE_DASH,
    ROUTE_SHOW_ADDRESS,
    ROUTE_TRANSACTION_HISTORY,
    ROUTE_E_WALLET_METHODS,
    ROUTE_NX_ACTIVATE,
    ROUTE_SWAP,
} from "../../constants/routes";
import { getCurrencyIcon } from "../../constants/currencies";
import { changeSendingCurrency } from "../../ducks/blockchain";
import { changeActiveCurrency } from "../../ducks/in4x";
import BlockExplorerUtils from '../../utils/blockchain/BlockExplorerUtils';
import { openLink } from '../../utils/linksOpenPolyfill';

class TokenAssetBalance extends React.Component {
  constructor(props) {
    super(props);
    let icon = getCurrencyIcon(props.CUR.ticker);
    this.state = {
      anchorEl: null,
      icon: icon
    };
  }

  handleClick = (event) => {    
    event.preventDefault();
    this.setState({anchorEl: event.currentTarget});
    if(this.props.onToggle) {
    	this.props.onToggle(this.props.CUR);
    }
  };

  handleClose = (action) => {
    const { kycLevel } = this.props.dashboard;
    this.props.changeSendingCurrency(this.props.CUR.name, this.props.CUR);
    switch(action) {
      case 'send':
      	history.push(ROUTE_SEND + this.props.CUR.blockchain + '/' + this.props.CUR.name);
      	break;
      case 'exchange':
      	history.push(`${ROUTE_SWAP}/${this.props.CUR.name}`);
      	break;
      case 'receive':
      	history.push(ROUTE_SHOW_ADDRESS);
      	break;
      case 'history':
        history.push(ROUTE_TRANSACTION_HISTORY + this.props.CUR.blockchain + '/' + this.props.CUR.name + "/" + this.props.CUR.address);
        break;
      case 'buy':
        this.props.changeActiveCurrency(this.props.CUR);
        history.push((kycLevel === false || kycLevel < 1)? ROUTE_NX_ACTIVATE: ROUTE_E_WALLET_METHODS + 'topup/' + this.props.CUR.blockchain + '/' + this.props.CUR.name);
        break;
      case 'withdraw':
        this.props.changeActiveCurrency(this.props.CUR);
        history.push((kycLevel === false || kycLevel < 1)? ROUTE_NX_ACTIVATE: ROUTE_E_WALLET_METHODS + 'withdraw/' + this.props.CUR.blockchain + '/' + this.props.CUR.name);
        break;
      case 'stake':
        history.push(ROUTE_STAKE_DASH);
        break;
      default: 
        this.setState({anchorEl: null});
    }
    if(this.props.onToggle) {
      this.props.onToggle(this.props.CUR);
    }
  };

  render() {
    const { anchorEl, icon } = this.state;
    let { CUR, balance, enableMenus, blockchain: {wallet} } = this.props;
    const scanner = CUR.type !== 'coin'? BlockExplorerUtils.scanToken(CUR.address, CUR.blockchain) + '?a='+ wallet.address: false;
    return (
      <>
  	    <Box onClick={this.handleClick} className={`dashboard__actions dashboard__actions-balance balance-`+CUR.name} align="left" pt={3}>
          <Box className={`dashboard__actions-text`} align="left" pr={1} pl={1}>
            <Typography variant="h6" align="left">
                &nbsp;{CUR.ticker}
            </Typography>
            <img src={icon} alt="coin-icon" className="dashboard__actions-icon-eth"/>
            <Typography className="dashboard__actions-text-balance" variant="h5" align="left" gutterBottom>
                  { (balance === '' || balance === '?') && (
                    <CircularProgress color="secondary" />
                  )}
                  { balance !== '?' && balance >= 0 && (
                    <>{Number(balance).toLocaleString(undefined, {maximumFractionDigits: 18})}</>
                  )}
            </Typography>
          </Box>
  	    </Box>
        { enableMenus && (<Menu
          className="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={()=>{this.handleClose('send')}}><SendIcon />&nbsp;Send</MenuItem>
          <MenuItem onClick={()=>{this.handleClose('receive')}}><CropFreeIcon />&nbsp;Receive</MenuItem>
          { CUR?.swappable && (<MenuItem onClick={()=>{this.handleClose('exchange')}}><LocalAtmIcon />&nbsp;Swap</MenuItem>)}
          { CUR.deposit && CUR.deposit.length > 0 && (<MenuItem onClick={()=>{this.handleClose('buy')}}><ShoppingCartIcon />&nbsp;Buy</MenuItem>)}
          { CUR.withdraw && CUR.withdraw.length > 0 && (<MenuItem onClick={()=>{this.handleClose('withdraw')}}><RemoveShoppingCartIcon />&nbsp;Sell</MenuItem>)}
          { CUR.stake && (<MenuItem onClick={()=>{this.handleClose('stake')}}><AccountBalanceIcon />&nbsp;Stake</MenuItem>)}
          <MenuItem onClick={()=>{this.handleClose('history')}}><ReceiptIcon />&nbsp;Transaction History</MenuItem>
          { scanner && (
            <MenuItem onClick={()=>{openLink(null, scanner)}}><PageviewIcon />&nbsp;Block Explorer</MenuItem>
          )}
        </Menu>)}
      </>
    );
  }
}

const mapState2props = state => ({
  dashboard: state.dashboard,
  blockchain: state.blockchain
});

const mapDispatch2props = {
  changeSendingCurrency,
  changeActiveCurrency,
}

export default compose(
    connect(mapState2props, mapDispatch2props)
)(TokenAssetBalance);