import React, {useEffect, useState} from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { useSwipeable } from "react-swipeable";

import {
    Container,
    Menu, MenuItem,
} from '@material-ui/core';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import DashboardIcon from '@material-ui/icons/Dashboard';

import { openLink } from '../../utils/linksOpenPolyfill';
import StorageUtils from '../../utils/storageUtils';

import BalanceBlock from "../../components/BalanceBlock";
import StakeTokensAccordion from "../../components/StakeTokensAccordion";
import AssetLoaderProgress from "../../components/AssetLoaderProgress";

import { CURRENCY_IOWN } from "../../constants/currencies";
import { BLOCKCHAIN_BSC } from "../../constants/blockchain";
import { EVENT_USER_BALANCES_LOADED } from "../../constants/events";
import { KEY_CFG_STAKING_SHOWN } from "../../constants/storageKeys";
import {
    EXTERNAL_STAKING,
    EXTERNAL_NFT_PROGRAM,
    ROUTE_STAKE,
    ROUTE_UNSTAKE,
    ROUTE_WALLET_ASSETS,
    ASSET_TYPE_LIQUID,
} from "../../constants/routes";

import { getStakingData } from "../../ducks/staking";

let eventListerAdded = false;

const StakingDashboard = (props) => {

    const handleStakeMore = () => { props.history.push(ROUTE_STAKE + BLOCKCHAIN_BSC + '/'+ CURRENCY_IOWN);	}
    const handleUnStake = () => { props.history.push(`${ROUTE_UNSTAKE}${BLOCKCHAIN_BSC}/${CURRENCY_IOWN}`);	}
    const handleBack = () => { props.history.push(ROUTE_WALLET_ASSETS + ASSET_TYPE_LIQUID); }

    const [isStakingUpdateSwipeCalled, setStakingUpdateSwipeCall] = useState(false);
    const [anchorEl,setAchorEl] = useState(null);

    useEffect(() => {
        onUserAssetTypesRefreshed();
        if(!eventListerAdded) {
            eventListerAdded = true;
            window.addEventListener(EVENT_USER_BALANCES_LOADED, onUserAssetTypesRefreshed);
        }
        StorageUtils.setItem(KEY_CFG_STAKING_SHOWN, true);
        return () => {
            if(eventListerAdded) {
                eventListerAdded = false;
                window.removeEventListener(EVENT_USER_BALANCES_LOADED, onUserAssetTypesRefreshed);
            }
        }
    })

    const onUserAssetTypesRefreshed = () => {
        if (props.dashboard.userAssetTypesLoaded && !props.staking.stakingDataLoading && !props.staking.stakingDataLoaded) {
            props.getStakingData(currencyIown, props.blockchain.wallet);
        }
    }

    const handleMenu = (action) => {
        switch(action) {
            case 'status':
                openLink(null, EXTERNAL_STAKING);
                break;
            case 'stake':
                props.history.push(ROUTE_STAKE + BLOCKCHAIN_BSC + '/'+ CURRENCY_IOWN);
                break;
            case 'unstake':
                handleUnStake();
                break;
            default: 
                setAchorEl(null);
        }
    }

    const swipeHandlers = useSwipeable({
        onSwiping: (eventData) => {
            if (eventData.deltaY > 200 && (eventData.deltaX > -60 && eventData.deltaX < 60)) {
                setStakingUpdateSwipeCall(true);
            }
        },
        onSwipedDown: (eventData) => {
            if (eventData.deltaY > 200) {
                props.getStakingData(currencyIown, props.blockchain.wallet);
                setStakingUpdateSwipeCall(false);
            }
        }
    });

    const { currencyIown } = props.dashboard;
    const { stakingReward, stakingBalance, stakingDataLoading, stakingDataLoaded, stakingAllowed, stakingTransactions, stakingTransactionsLoaded } = props.staking;
    const showInfo = stakingTransactionsLoaded && (!stakingTransactions || stakingTransactions.length === 0);
    const ctaLabel = showInfo? 'Stake Now' : 'Stake More';
    return (
        <Container className="home" component="main" maxWidth="sm">
            <div {...swipeHandlers} >
                <Grid container spacing={1} justifyContent="center" alignItems="center" className="spaced-top">
                    {(stakingDataLoading || !stakingDataLoaded) && (
                        <Grid item xs={12} className="spaced-top">
                            <AssetLoaderProgress/>
                        </Grid>
                    )}
                    {(stakingDataLoaded) && (
                        <>
                            { isStakingUpdateSwipeCalled && (
                                <Grid item xs={12} style={{textAlign: 'center', paddingTop: '55px'}}>
                                    <ExpandLessIcon color="primary" className="swipe-icon down"
                                                    style={{marginTop: '35px'}}/>
                                    <Typography color="primary">Release to refresh balances!</Typography>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <BalanceBlock balance={stakingBalance} label={currencyIown?.name} icon="/img/iown-icon.png" onClick={(e) => {setAchorEl(e.target)}} />
                                <Menu
                                  className="simple-menu"
                                  anchorEl={anchorEl}
                                  keepMounted
                                  open={Boolean(anchorEl)}
                                  onClose={handleMenu}
                                >
                                  {stakingDataLoaded && stakingAllowed && (<MenuItem onClick={()=>{handleMenu('stake')}}><LockIcon />&nbsp;{ctaLabel}</MenuItem>)}
                                  <MenuItem onClick={()=>{handleMenu('status')}}><DashboardIcon />&nbsp;Program Status</MenuItem>
                                  {stakingDataLoaded && stakingBalance > 0 && (<MenuItem onClick={()=>{handleMenu('unstake')}}><LockOpenIcon />&nbsp;Unstake Request</MenuItem>)}
                                </Menu>
                            </Grid>
                            { stakingReward > 0 && (<Grid container justifyContent="space-between" alignItems="center" style={{padding: '1rem', borderTop: '1px solid lightgrey'}}>
                                <Grid item xs={6}>
                                    <Typography>Total NFTs</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                   <Grid container spacing={1} justifycontent="space-between" alignItems="center">
                                        <Grid container justifyContent="flex-end" alignItems="center">
                                            <Typography fontSize="2">{stakingReward} x &nbsp;</Typography>
                                            <img src="/img/nft.gif" alt="NFT" id="amlak-nft" style={{width: '45px'}} onClick={(e) => openLink(e, EXTERNAL_NFT_PROGRAM)}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>)}
                            {stakingTransactionsLoaded && stakingTransactions && stakingTransactions.length > 0 && (
                                <Grid item xs={12}>
                                    <StakeTokensAccordion stakes={stakingTransactions} />
                                </Grid>
                            )}
                            { showInfo && (
                                <Grid item xs={12} className="centered-grid">
                                    <Typography color="primary">Welcome to iOWN Staking program (Genesis NFT Pool)</Typography>
                                    <Typography>You don't have any staked tokens, click <Link color="secondary" onClick={(e) => openLink(e, EXTERNAL_STAKING)}>here</Link> to learn more about program.</Typography>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                {stakingDataLoaded && stakingAllowed && (
                                    <Button color="primary" variant="contained" fullWidth
                                            onClick={handleStakeMore}
                                            className="spaced-top">{ctaLabel}</Button>)}
                                <Button variant="contained" fullWidth onClick={handleBack}
                                        className="spaced-top">Back</Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </div>
        </Container>
    );
}

const mapState2props = state => ({
  blockchain: state.blockchain,
  staking: state.staking,
  dashboard: state.dashboard
});

const mapDispatch2props = {
  getStakingData
};

export default compose(
  connect(mapState2props, mapDispatch2props, null, {forwardRef: true})
)(StakingDashboard);