// System-level keys
export const KEY_MULTI_USER_ACTIVE = "active-account";
export const KEY_MULTI_USER_ACCOUNTS = "all-accounts";
export const KEY_MULTI_USER_BIO_ACTIVE = "bio-seed-on";
export const KEY_GAS_METRICS = "eth-gas";
export const KEY_LATOKEN_RATES = "ex-la-rates";
// Added to email: email_archive will contain old wallet-level keys (ARCHIVE_ENABLED_KEYS=>values)
export const KEY_SUFFIX_ARCHIVE = "_archive";

// Wallet-level keys (PK + mnemonic are encrypted)
export const KEY_ACTIVE_MNEMONIC = "wallet-mnemonic";
export const KEY_ACTIVE_PK = "wallet-private-key";
export const KEY_ACTIVE_BLOCKCHAIN = "blockchain";
export const KEY_ACTIVE_ETH_NET = "eth-network";
export const KEY_ACTIVE_BIO_HASH = "wallet-bio-hash";

// Not ussed directly, uses concatenation with network:
export const KEY_CACHE_TRX = 'tx_cache';
export const KEY_CACHE_TRX_ETH_MAINNET = KEY_CACHE_TRX + '_mainnet';
export const KEY_CACHE_TRX_ETH_ROPSTEN = KEY_CACHE_TRX + '_ropsten';

export const KEY_CACHE_PRIMARY_IOD = "iod";

export const KEY_CFG_MNEMONIC_SHOWN = "mnemonic-shown";
export const KEY_CFG_STAKING_SHOWN = "staking-shown";
// End Wallet-level keys

export const KEY_PROMO_SHOWN = "welcome-promo-shown";

// Prefix for wallet connect connections
export const WALLET_CONNECT_PREFIX = 'wallet-connect-';

// Keys which are archived (swapped out on wallet "switch")
export const ARCHIVE_ENABLED_KEYS = [
  KEY_ACTIVE_MNEMONIC,
  KEY_ACTIVE_PK,
  KEY_ACTIVE_BLOCKCHAIN,
  KEY_ACTIVE_ETH_NET,
  KEY_ACTIVE_BIO_HASH,
  KEY_CACHE_TRX_ETH_MAINNET,
  KEY_CACHE_TRX_ETH_ROPSTEN,
  KEY_CFG_MNEMONIC_SHOWN,
  KEY_CFG_STAKING_SHOWN,
  KEY_PROMO_SHOWN
];