import React from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm, getFormValues, isValid, submit, change, blur } from 'redux-form';

import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Container, Grid, Typography, Button } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import PriorityHigh from '@material-ui/icons/PriorityHigh';
import CropFreeIcon from '@material-ui/icons/CropFree';
import LaunchIcon from '@material-ui/icons/Launch';

import SAMobileStepper from "../../components/SAMobileStepper";
import SignUpStartForm from "../../components/Forms/SignUpStart";
import SignUpEndForm from "../../components/Forms/SignUpEnd";
import RecaptchaBox from "../../components/captcha/RecaptchaBox";
import Mnemonic from "../../components/mnemonic/Mnemonic";
import CustomInput from "../../components/Forms/Field";

import sha512 from "js-sha512";
import history from "../../history";

import { 
  importOTPValidate, 
  changeIodOTP 
} from "../../ducks/iod";
import {
  setFormInfo,
  registerCaptcha,
  setMnemonicRaw,
  changeMnemonicConfirm,
  enableNextMnemonicStep,
  registerAccount,
} from "../../ducks/signup";
import { 
  changeWallet 
} from "../../ducks/blockchain";

import {openLink} from '../../utils/linksOpenPolyfill'
import WalletUtils from "../../utils/blockchain/WalletUtils";
import { encryptDES } from "../../utils/cryptoUtil";
import MultiAccountUtils from "../../utils/multiAccountUtils";
import asyncValidation from "../../utils/asyncValidation";

import { ROUTE_IMPORT_WALLET_SCANNER, ROUTE_SUCCESS_REGISTRATION } from '../../constants/routes';

const useStyles = theme => ({
  button: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fullHeight: {
    height: '100%',
  },
  wrapCenter: {
    flex: 1,
    textAlign: 'center',
    alignItems: 'center',
  },
  wideMargin: {
    marginTop: '2em',
  }
});

class ImportWallet extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      catchErr: null, //Fatel (causes restart)
      showErr: null, // not fatal (allows back/forward)
      activeStep: 0,
      isVerifying: false,
      emailInUse: false,
      email: '',
      fullName: ''
    };
  }

  componentDidMount() {
    if (this.props.iod.otp) {
      this.setState({ activeStep: 2, isVerifying: true });
      this.validateOtp();
    }
    const { clearOldServerError, signup } = this.props;
    if (signup.serverError) {
      clearOldServerError()
    }
  }

  reset = () => {
    this.setState({ catchErr: null, showErr: null, isVerifying: false, activeStep: 0, emailInUse: false });
  }

  nextStep = async () => {
    try {
      switch (this.state.activeStep) {
        case 0:
          const { otpForm } = this.props;

          if(otpForm && otpForm.otp && otpForm.otp.length > 0) {
            // TODO: Give a name magic regex
            if(/^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/.test(otpForm.otp)) {
              await this.props.changeIodOTP(otpForm.otp);              
              this.setState({activeStep: 2, isVerifying: true });
              await this.validateOtp();
            } else {
              this.setState({catchErr: 'Invalid OTP', activeStep: 2});
            }              
          } else {
            this.props.changeIodOTP('');
            history.push(ROUTE_IMPORT_WALLET_SCANNER);
          }
          break;
        case 2:
          this.generateMnemonicConfirm();
          break;
        case 3:
          if(this.validateMnemonicConfirm()) {
            this.setState((state) => ({ activeStep: state.activeStep + 1 }));
            this.props.dispatch(blur('signup-start', 'fullName', this.state.fullName));
            this.props.dispatch(blur('signup-start', 'email', this.state.email));
          }
          break;
        case 4:
          this.handleSubmitForm();
          break;
        case 5:
          this.handlePasswordForm();
          break;
        default:
          this.setState((state) => ({ activeStep: state.activeStep + 1 }));
          break;
      }
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  prevStep = () => {
    try {
      if (this.state.activeStep <= 2) { //step 2 is where we check the otp
        this.setState({ otp: null });
        this.setState({ mnEditable: false });
      }
      if(this.state.activeStep === 4) {
        this.props.dispatch(blur('signup-start', 'fullName', this.state.fullName));
        this.props.dispatch(blur('signup-start', 'email', this.state.email));
      }
      this.setState({ catchErr: null, showErr: null });
      if (this.state.activeStep === 0) {
        history.goBack();
      } else {
        this.setState((state) => ({ activeStep: state.activeStep === 2 ? state.activeStep - 2 : state.activeStep - 1 })); //skip state = 1
      }
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  back = () => {
    history.goBack();
  };

  validateOtp = async () => {
    await this.props.importOTPValidate({ otp: this.props.iod.otp });
    const result = this.props.iod.id; //returned identity
    if (result && result.user && result.user.username) {
      const fullName = result.user.firstname + ' ' + result.user.lastname;
      const email = result.user.username;
      try {
        await asyncValidation({email: email, fullName: fullName});
      } catch(err) {
        if(err && err.email) {
          this.setState({emailInUse: true, catchErr: 'You cannot import this wallet because its email is in use'});
        }
      }
      this.setState({email: email, fullName: fullName});
    }
    if (result && result.wallet && result.wallet.mnemonic && result.wallet.privateKey) {
      this.props.setMnemonicRaw(result.wallet.mnemonic);
    } else {
      this.setState({ catchErr: 'Scanned QR is invalid or expired' });
    }
    this.setState({ isVerifying: false });
  };

  generateMnemonicConfirm() {
    try {
      const { mnemonic, mnemonicConfirm } = this.props.signup;
      if (!mnemonicConfirm || mnemonicConfirm === []) {
        throw new Error('Invalid mnemonic');
      }
      const enabledIndexes = [];
      let counter = 0;
      let newMnemonicConfirm;
      while (counter !== 3) {
        // eslint-disable-next-line no-loop-func
        newMnemonicConfirm = mnemonic.map((i, j) => (Math.random() <= 0.5 && (counter !== 3)) ? (counter++, enabledIndexes.push(j), "") : i);
      }
      this.props.enableNextMnemonicStep(newMnemonicConfirm, enabledIndexes);      
      this.setState((state) => ({ activeStep: state.activeStep + 1 }));
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  validateMnemonicConfirm() {
    try {
      const { mnemonic, mnemonicConfirm } = this.props.signup;
      const isValid = mnemonic.every((i, j) => i === mnemonicConfirm[j].trim());
      if(!isValid) {
        this.setState({showErr: 'Confirm secret words are not correct'});
      }
      return isValid;
    } catch(err) {
      this.setState({ catchErr: err }); 
    }
    return false;
  }

  onCaptcha = (captcha) => {
    this.setState({ captcha: captcha });
    this.props.dispatch(change('signup-start', 'captcha', captcha));
  };

  submitCaptcha = async () => {
    try {
      const result = await this.props.registerCaptcha({ captcha: this.state.captcha });
      if (result && result.success) {
        this.setState((state) => ({ activeStep: state.activeStep + 1 }));
      }
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  handleSubmitForm = () => {
    try {
      this.props.dispatch(submit('signup-start'));
      this.props.setFormInfo(this.props.startFormValues);
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  handlePasswordForm = async(values) => {
    try {
      this.props.setFormInfo(values);
      await this.createWallet();
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  createWallet = async () => {
    try {
      const {
        mnemonic,
        mnemonicRaw,
        mnemonicConfirm,
        form: {
          fullName,
          phone,
          email,
          token,
        }
      } = this.props.signup;
      const { password } = this.props.endFormValues;
      const hashPassword = sha512(password);
      const isValid = mnemonic.every((i, j) => i === mnemonicConfirm[j].trim());

      if (isValid) {
        const wallet = await WalletUtils.createFromMnemonic(mnemonicRaw);
        const encryptedKey = encryptDES(wallet.privateKey, hashPassword);
        const encryptedMnemonic = encryptDES(mnemonicConfirm.join(' '), hashPassword);
        MultiAccountUtils.addAccount(email, encryptedKey, encryptedMnemonic);
        await this.props.registerAccount({
          fullName,
          phone,
          email,
          token,
          password: hashPassword,
          wallet: wallet.address
        });

        await this.props.changeWallet(wallet);
        history.push(ROUTE_SUCCESS_REGISTRATION);
      } else {
        this.setState({ openAlert: true, alertText: "Warning! Incorrect Secret Phrase" })
      }
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  render() {
    const { catchErr, showErr, activeStep, isVerifying, otp, emailInUse } = this.state;
    const { startFormValid, endFormValid, classes } = this.props;
    const { mnemonic, mnemonicConfirm, enabledIndexes } = this.props.signup;
    if(this.activeStep) {
      this.props.dispatch(blur('signup-start', 'fullName', this.state.fullName));
      this.props.dispatch(blur('signup-start', 'email', this.state.email));
    }
    const catchMsg = catchErr && catchErr.message? catchErr.message :catchErr;
    const showMsg = showErr && showErr.message? showErr.message: showErr;
    const disabledNext = catchErr !==null || isVerifying || emailInUse || (activeStep === 1 && otp === null) || (activeStep === 4 && !startFormValid);
    return (
      <Container className="home" component="main" maxWidth="sm">
      <Grid container direction={'column'} justifyContent="space-between" wrap={'nowrap'} className={classes.fullHeight}>
        <Typography variant="h5" align="center" gutterBottom>
          Import iOWN Wallet
        </Typography>
        { catchErr && (
          <Alert severity="error" onClose={this.reset}>{catchMsg}</Alert>
        )}
        { showErr && (
          <Alert severity="error" onClose={() => { this.setState({showErr: null}); }}>{showMsg}</Alert>
        )}
        <Grid
          container
          direction={'column'}
          justify="center"
          wrap={'nowrap'}
          className={classes.wrapCenter}
        >
        { activeStep === 0 && (
          <>
            <Typography align="center" gutterBottom className={classes.wideMargin}>
              You can import a wallet from other iOWN services here.
            </Typography>
            <Button className={classes.wideMargin} color="primary" align="center" onClick={(e) => openLink(e, "https://www.iowndigital.io/wallet")}>
                Take me to iOWN Digital <LaunchIcon />
              </Button>
            <Typography align="center" gutterBottom className={classes.wideMargin}>
              Scan OTP QR Code:
            </Typography>
            <Button 
              className={classes.wideMargin + ' btn-circle'}
              onClick={this.nextStep}
            >
              <CropFreeIcon/>
            </Button>
            <Typography align="center" gutterBottom className={classes.wideMargin}>
                Or paste it here:            
              </Typography>
              <form style={{width: '100%'}}>
                <Field name="otp" component={CustomInput} type="text" label="(Paste OTP here)" autoComplete="off" />
              </form>
          </>
        )}
        { activeStep === 2 && isVerifying && (
          <>
            <CircularProgress className={classes.wideMargin}/>            
            <Typography align="center" gutterBottom className={classes.wideMargin}>
              Validating OTP...
            </Typography>
          </>
        )}
        { activeStep === 2 && !isVerifying && catchErr === null && showErr === null && (
          <>
            <Typography align="center" color="primary" gutterBottom className={classes.wideMargin}>
              This is the imported secret phrase
            </Typography>
            <Typography variant="subtitle2" align="center" className={this.props.classes.iconTitle} gutterBottom>
              <PriorityHigh color="error" fontSize="small" /><div>&nbsp;Important: make sure you write this on a
                piece of paper, secure it and never share it with anyone, <span style={{ color: 'red', 'fontWeight': 'bold' }}>losing it will cause the loss of all your funds</span>!</div>
            </Typography>
            <Mnemonic mnemonic={mnemonic} />
          </>
        )}
        { activeStep === 2 && !isVerifying && (catchErr !== null || showErr !== null )&& (
          <>
            { !emailInUse && ( <Typography align="center" color="primary" gutterBottom className={classes.wideMargin}>
              It seems the wallet QR code you've scanned is invalid, or has expired, please refresh the QR code page, or retry.
              </Typography>)}
            { emailInUse && ( <Typography align="center" color="primary" gutterBottom className={classes.wideMargin}>
              Please login/unlock your existing wallet under this email and link it instead.
              </Typography>) }
          </>
        )}
        </Grid>
      </Grid>
      { activeStep === 3 && (
        <>
          <Typography align="center" color="primary" gutterBottom className={classes.wideMargin}>
            This is the imported secret phrase
          </Typography>
          <Typography variant="subtitle2" align="center" className={this.props.classes.iconTitle} gutterBottom>
            <PriorityHigh color="error" fontSize="small" /><div>&nbsp;Important: make sure you write this on a
              piece of paper, secure it and never share it with anyone, <span style={{ color: 'red', 'fontWeight': 'bold' }}>losing it will cause the loss of all your funds</span>!</div>
          </Typography>
          <Mnemonic 
            editable
            enabledIndexes={enabledIndexes}
            onChange={this.props.changeMnemonicConfirm}
            mnemonic={mnemonicConfirm} />
        </>
      )}
      { activeStep === 4 && (
        <>
          <SignUpStartForm onSubmit={this.submitCaptcha} disableEmail={true}/>
          <RecaptchaBox onChange={this.onCaptcha} onExpired={this.onCaptcha} />
        </>
      )}
      { activeStep === 5 && (
        <>
          <Typography variant="h5" align="center" gutterBottom>
              Pick a password for the wallet
          </Typography>
          <Typography variant="subtitle2" align="center" gutterBottom>
              Note: Take care not to lose this password, consider using a <b>password manager</b>
          </Typography>
          <SignUpEndForm
              currentPassword={this.props.startFormValues}
              onSubmit={this.handlePasswordForm}
              btnDisabled={!startFormValid || !endFormValid}
          />
        </>
      )}
      <SAMobileStepper maxSteps={5} activeStep={this.state.activeStep} onNext={this.nextStep} onPrev={this.prevStep} disabledNext={disabledNext} />
      </Container>
    )
  };
}

const mapState2props = state => ({
  iod: state.iod,
  signup: state.signup,
  startFormValues: getFormValues('signup-start')(state),
  startFormValid: isValid('signup-start')(state),
  endFormValues: getFormValues('signup-end')(state),
  endFormValid: isValid('signup-end')(state),
  otpForm: getFormValues('iod-otp')(state)
});

const mapDispatch2props = (dispatch) => ({
  dispatch,
  importOTPValidate: (data) => dispatch(importOTPValidate(data)),
  changeIodOTP: (otp) => dispatch(changeIodOTP(otp)),
  setMnemonicRaw: (mn) => dispatch(setMnemonicRaw(mn)),
  changeMnemonicConfirm: (v, idx) => dispatch(changeMnemonicConfirm(v, idx)),
  enableNextMnemonicStep: (mn, ns) => dispatch(enableNextMnemonicStep(mn, ns)),
  setFormInfo: (data) => dispatch(setFormInfo(data)),
  registerCaptcha: (data) => dispatch(registerCaptcha(data)),
  registerAccount: (data) => dispatch(registerAccount(data)),
  changeWallet: (w)=> dispatch(changeWallet(w)),
});

export default compose(
  withStyles(useStyles),
  connect(mapState2props, mapDispatch2props),
  reduxForm({
    form: 'iod-otp',
    enableReinitialize: true,
  }),
)(ImportWallet);