import React from "react";
import { Grid, Button, Typography, TextField } from "@material-ui/core";
import Modal from "src/components/Modal";
import {
    callRequestMethodLabel,
    parseSignTypedData,
    parseTransactionSign,
    parsePersonalSign,
    parseSwitchWallet
} from './utils';
import { EIP155_SIGNING_METHODS } from '../../constants';

const CallRequestModal = (props) => {
    const { callRequest = {}, peerDisplayName } = props;
    const method = callRequest?.method || '';
    const params = callRequest?.params || [];

    const nextData = params.reduce((values, item) => {
        let nextValues;
        if (method === EIP155_SIGNING_METHODS.ETH_SIGN || method === EIP155_SIGNING_METHODS.PERSONAL_SIGN) {
            nextValues = parsePersonalSign(item)
        }
        if (method === EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION || method === EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION) {
            nextValues = parseTransactionSign(item);
        }
        if (method === EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA || method === EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3 || method === EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4) {
           nextValues = parseSignTypedData(item);
        }
        if (method === EIP155_SIGNING_METHODS.WALLET_SWITCH_CHAIN) {
            nextValues = parseSwitchWallet(item);
        }

        if (nextValues) {
            return [ ...values, ...nextValues]
        }

        return [...values, item];
    }, []);

    const paramList = nextData.map((item, idx) => {
        const value = item && item.value? item.value: '';
        const label = item && item.label? item.label: `(Unspecified)`;
        return (
                <TextField 
                    key={`data-${idx}`}
                    label={label}
                    value={value}
                    fullWidth
                    multiline
                    size="small"
                    variant="outlined"
                    style={{ marginTop: 10 }}
                    inputProps={{
                        maxLength: 20,
                    }}
                />
            );
    });

	return (
		<Modal
          open={props.openOn}
          onClose={props.onRejectCallRequest}
          modalBody={
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5" align="center" gutterBottom color="primary">
                    WalletConnect Request
                </Typography>
              </Grid>
              <Grid item xs={12} style={{maxHeight: '80vh', overflowY: 'auto'}}>
                    <Typography>
                        {callRequestMethodLabel(method)}
                    </Typography>
                    <Typography variant="h6" color="primary" gutterBottom>
                        { peerDisplayName }
                    </Typography>
                    { paramList }
              </Grid>
              <Grid item xs={6}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={props.onRejectCallRequest}
                    >
                        Reject
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={props.onApproveCallRequest}
                    >
                        Approve
                    </Button>
              </Grid>
            </Grid>}
        ><>{""}</></Modal>
	);
}

export default CallRequestModal;