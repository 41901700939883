import React, {useState, useEffect} from 'react';
import {Grid, Typography} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const backDelayInMs = 1500;

const BarcodeScanner = (props) => {

    const {
        onScan = () => {},
        prompt = val => val,
        onValidate = () => true,
        onScannerClose = () => {},
    } = props;

    const [isLoading, setLoading] = useState(true);
    const [scannerError, setScannerError] = useState(null);

    useEffect(() => {
        if (scannerError) {
            setTimeout(onScannerClose, backDelayInMs);
        }
        // to prevent infinity loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scannerError]);

    useEffect(() => {
        const scanText = prompt || "Scan an Ethereum Address";

        function scan() {
            const pluginNotInitialized = !Boolean(window?.cordova?.plugins?.barcodeScanner);
            if (pluginNotInitialized) {
                setLoading(false);
                setScannerError('Scanner is not available');
                return;
            }
            window.cordova.plugins.barcodeScanner.scan(async (result) => {
                    setLoading(false);
                    try {
                        const qrCodeVal = await result?.text;
                        if (!onValidate(qrCodeVal)) {
                            setScannerError(`Invalid QR Code: ${qrCodeVal}`);
                            return;
                        }
                        onScan(qrCodeVal);
                    } catch (e) {
                        setScannerError('QR Scanning error');
                    }
                }, () => {
                    setLoading(false);
                    setScannerError('QR Scanned is invalid');
                },
                {
                    preferFrontCamera: false, // iOS and Android
                    showFlipCameraButton: false, // iOS and Android
                    showTorchButton: true, // iOS and Android
                    torchOn: false, // Android, launch with the torch switched on (if available)
                    saveHistory: false, // Android, save scan history (default false)
                    prompt: scanText, // Android
                    resultDisplayDuration: 0, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
                    formats: "QR_CODE", // default: all but PDF_417 and RSS_EXPANDED
                    orientation: "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
                    disableAnimations: true, // iOS
                    disableSuccessBeep: false // iOS and Android
                });
        }

        scan();
        // on mount only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {isLoading && (
                <Grid container justifyContent="center" alignItems="center">
                    <Typography color="primary" gutterBottom>Initializing scanner...</Typography>
                </Grid>
            )}
            {scannerError && (
                <Grid container spacing={1} justifyContent="center" alignItems="center" className="scanner-alert">
                    <Alert style={{width: '100%'}} severity="warning">{scannerError}</Alert>
                </Grid>
            )}
        </>
    );
}

export default BarcodeScanner;