import { reset } from 'redux-form';
import { RegExps } from "../constants/regExps";
import { ValidationUtil } from "../utils/validationUtil";
import { handleFetch } from "../utils/fetch";
import { performResult } from "../utils/stateManipulator";
import { transformError, getError } from '../utils/transformErrors';
import { decryptDES } from "../utils/cryptoUtil";
import WalletUtils from "../utils/blockchain/WalletUtils";
import StorageUtils from "../utils/storageUtils";
import GtagUtil from "../utils/gtagUtils";
import { KEY_ACTIVE_PK } from "../constants/storageKeys";
import { API_SIGNIN_EMAIL, API_SIGNIN_WALLET } from "../constants/apiRoutes";

const initialState = {
  loginMnemonic: {
    value: ["", "", "", "", "", "", "", "", "", "", "", ""],
    error: false,
    helperText: "",
  },

  activeStep: 1,
  walletAddress: "",

  failed: false,
  submitLoading: false,
  error: "",
};

const LOGIN_WALLET_SENT = "LOGIN_WALLET_SENT";
const LOGIN_WALLET_SUCCESS = "LOGIN_WALLET_SUCCESS";
const LOGIN_WALLET_FAILED = "LOGIN_WALLET_FAILED";

const LOGIN_EMAIL_SENT = "LOGIN_EMAIL_SENT";
const LOGIN_EMAIL_SUCCESS = "LOGIN_EMAIL_SUCCESS";
const LOGIN_EMAIL_FAILED = "LOGIN_EMAIL_FAILED";
const LOGIN_EMAIL_RESET = "LOGIN_EMAIL_RESET";

const WALLET_NOT_FOUND = "WALLET_NOT_FOUND";

const CHANGE_LOGIN_MNEMONIC = "CHANGE_LOGIN_MNEMONIC";
const CHANGE_WHOLE_MNEMONIC = "CHANGE_WHOLE_MNEMONIC";
const MNEMONIC_ERROR = "MNEMONIC_ERROR";

export const walletNotFound = () => ({ type: WALLET_NOT_FOUND });
export const mnemonicError = error => ({ type: MNEMONIC_ERROR, error });

export const checkWalletPassword = (hashPassword) => async (dispatch) => {
  const encryptedKey = StorageUtils.getItem(KEY_ACTIVE_PK);
  const key = decryptDES(encryptedKey, hashPassword);
  const wallet = await WalletUtils.accessWalletByPrivateKey(key);

  if (!wallet) {
    dispatch({ type: "LOGIN_WALLET_FAILED", err: transformError("INCORRECT_PASSWORD") });
  }

  return wallet;
};

export const changeLoginMnemonic = (value, index) => ({
  type: CHANGE_LOGIN_MNEMONIC,
  index,
  value
});
export const changeWholeMnemonic = mnemonic => ({ type: CHANGE_WHOLE_MNEMONIC, mnemonic });

export const resetSignIn = () => dispatch => {
  dispatch(reset('signin-new'));
  dispatch({ type: LOGIN_EMAIL_RESET })
};

export const loginWithWallet = (wallet, password) => dispatch => {
  dispatch({ type: LOGIN_WALLET_SENT });
  return handleFetch(API_SIGNIN_WALLET, "POST", {
      wallet: wallet.address,
      password
    })
    .then(res => performResult(res, () => {
      GtagUtil.trackLogin(wallet.address, 'Wallet');
      dispatch({ type: LOGIN_WALLET_SUCCESS });
      return res;
    }))
    .catch(err => dispatch({ type: LOGIN_WALLET_FAILED, err: transformError(err) }));
};

export const loginWithEmail = ({ email, password, captcha }, errCallback) => dispatch => {
  dispatch({ type: LOGIN_EMAIL_SENT });
  return handleFetch(API_SIGNIN_EMAIL, "POST", {
      email,
      password,
      captcha
    })
    .then(res => performResult(res, () => {
      GtagUtil.trackLogin(res.payload, 'Email');
      dispatch({ type: LOGIN_EMAIL_SUCCESS, address: res.payload });
    }))
    .catch(err => {
      if (errCallback) errCallback();
      dispatch({ type: LOGIN_EMAIL_FAILED, err: transformError(err) });
    });
};


export const signin = (state = initialState, action) => {
  let error;
  let newState;

  switch (action.type) {

    case CHANGE_LOGIN_MNEMONIC:
      const mnemonicArray = state.loginMnemonic.value;
      mnemonicArray[action.index] = action.value;

      const mnemonicString = mnemonicArray.join(' ').toString();
      error = !ValidationUtil.isValid(mnemonicString, RegExps.mnemonic);
      newState = {
        ...state,
        loginMnemonic: { value: mnemonicArray, error, helperText: error ? getError("MNEMONIC_FORMAT") : "" },
      };
      return { ...newState, submitEnabled: isReadyToSubmit(newState) };

    case CHANGE_WHOLE_MNEMONIC:
      const newMnemonicArray = action.mnemonic.trim().split(" ");
      error = !ValidationUtil.isValid(action.mnemonic, RegExps.mnemonic);
      newState = {
        ...state,
        loginMnemonic: { value: newMnemonicArray, error, helperText: error ? getError("MNEMONIC_FORMAT") : "" },
      };
      return { ...newState, submitEnabled: isReadyToSubmit(newState) };

    case WALLET_NOT_FOUND:
      return { ...state, failed: true, error: getError("WALLET_NOT_FOUND") };

    case MNEMONIC_ERROR:
      return { ...state, failed: true, error: action.error };

    case LOGIN_WALLET_SENT:
      return { ...state, failed: false, submitLoading: true };

    case LOGIN_WALLET_SUCCESS:
      return { ...state, submitLoading: false };

    case LOGIN_WALLET_FAILED:
      return { ...state, failed: true, error: action.err, submitLoading: false };

    case LOGIN_EMAIL_SENT:
      return { ...state, failed: false, submitLoading: true };

    case LOGIN_EMAIL_SUCCESS:
      return { ...state, activeStep: 2, failed: false, submitLoading: false, walletAddress: action.address };

    case LOGIN_EMAIL_FAILED:
      return { ...state, failed: true, error: action.err, submitLoading: false };

    case LOGIN_EMAIL_RESET:
      return { ...state,
        failed: false,
        error: "",
        submitLoading: false,
        activeStep: 1,
        loginMnemonic: {
          value: ["", "", "", "", "", "", "", "", "", "", "", ""],
          error: false,
          helperText: "",
        }
      };

    default:
      return state;
  }
};

function isReadyToSubmit(state) {

  let fields = state.walletPassword ? "password" : "loginMnemonic";
  let options = {
    notEmpty: [fields],
    isValid: [fields]
  };

  return ValidationUtil.isReadyToSubmit(state, options);
}