import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {changeSendingAddress} from "../../ducks/blockchain";
import BarcodeScanner from "./BarcodeScanner";

const AddressQRScanner = (props) => {

    const {
        history = {
            goBack: () => {}
        },
        changeSendingAddress = val => val,
        afterScanDelay = 1000,
        networkStarters = ["ethereum:"]
    } = props;

    function onScan(text = "") {
        let nextAddress = text;
        const starter = networkStarters.find(starter => text.includes(starter));
        if (starter) {
            nextAddress = text.split(starter)[1];
        }
        changeSendingAddress(nextAddress);
        setTimeout(history.goBack, afterScanDelay);
    }

    return (
        <BarcodeScanner
            onScan={onScan}
            onScannerClose={history.goBack}
            prompt="Scan an Ethereum Address"
        />
    );
}

export default compose(
    connect(null, {changeSendingAddress})
)(AddressQRScanner);