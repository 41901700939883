import ProviderUtils from "./ProviderUtils";
import StorageUtils from "../storageUtils";
import {KEY_ACTIVE_PK} from "../../constants/storageKeys";
import {decryptDES} from "../cryptoUtil";

const ethers = require("ethers");

export default class WalletUtil {
	    /**
     * @name accessWalletByMnemonic
     * @description accesses to ethereum blockchain wallet by mnemonic phrase
     * @param {string} `mnemonic`
     * @return {Promise<object>} wallet object
     */
    static accessWalletByMnemonic = async mnemonic => {
        mnemonic = mnemonic.trim();
        try {
            return await ethers.Wallet.fromMnemonic(mnemonic).connect(ProviderUtils.activeProvider())
        } catch (err) {
            return null;
        }
    };

    /**
     * @name accessWalletByPrivateKey
     * @description accesses to ethereum blockchain wallet by private key
     * @param {string} `key`
     * @return {Promise<object>} wallet object
     */
    static accessWalletByPrivateKey = async (key) => {
        try {
            return await new ethers.Wallet(key, ProviderUtils.activeProvider())
        } catch (err) {
            return false;
        }
    };

    /**
     * @name generateMnemonic
     * @description generation of mnemonic phrase
     * @return {string} wallet object
     */
    static generateMnemonic = () => {
        let m = ethers.utils.entropyToMnemonic(ethers.utils.randomBytes(16));
        return m;
    }

    /**
     * @name createFromMnemonic
     * @description generation of mnemonic phrase
     * @param {string} `mnemonic`
     * @return {Promise<object>} wallet object
     */
    static createFromMnemonic = async mnemonic => {
        try {
            return await ethers.Wallet.fromMnemonic(mnemonic).connect(ProviderUtils.activeProvider());
        } catch (err) {
            return false;
        }
    };

    static pkFromMnemonic = mnemonic => {
        return ethers.Wallet.fromMnemonic(mnemonic).privateKey;
    }

    /**
     * getWalletInstance - get wallet instance by password hash
     * @param {string} passwordHash - string stored password
     * @returns {Promise<Object>}
     */
    static getWalletInstance = async (passwordHash = "") => {
        const encryptedKey = StorageUtils.getItem(KEY_ACTIVE_PK);
        const key = decryptDES(encryptedKey, passwordHash);
        return await this.accessWalletByPrivateKey(key);
    }
}