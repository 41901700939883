import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getFormValues, isValid, submit, change } from 'redux-form';

import { withStyles } from "@material-ui/core";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import SAMobileStepper from "../../components/SAMobileStepper";
import SignUpStartForm from "../../components/Forms/SignUpStart";
import SignUpEndForm from "../../components/Forms/SignUpEnd";
import MnemonicImportanceModal from '../modals/MnemonicImportanceModal';
import RecaptchaBox from "../../components/captcha/RecaptchaBox";
import IownIcon from 'src/components/IownIcon';

import { 
  setFormInfo, 
  registerCaptcha, 
  clearOldServerError 
} from "../../ducks/signup";
import { ROUTE_INDEX, ROUTE_CREATE_WALLET } from "../../constants/routes";

import history from '../../history';

const styles = theme => ({
  hide: {
    opacity: '0',
    height: '0',
    margin: '0',
    overflow: 'hidden',
  },
  iconTitle: {
    display: 'flex',
    alignItems: 'center',
  }
});

class SignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      captcha: false,
      confirmModal: false,
      activeStep: 0,
      catchErr: null,
    };
  }

  componentDidMount() {
    const { clearOldServerError, signup } = this.props;
    if (signup.serverError) {
      clearOldServerError()
    }
  }

  handlePasswordForm = (values) => {
    try {
      this.props.setFormInfo(values);      
      this.handleModal();
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  handleRegisterCaptcha = async (values) => {
    try {
      const result = await this.props.registerCaptcha(values);
      if (result && result.success) {
        this.setState((state) => ({ activeStep: state.activeStep + 1 }));
      }
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  nextStep = () => {
    try {
      switch(this.state.activeStep) {
        case 0:
          this.props.dispatch(submit('signup-start'));
          break;
        case 1:
          this.props.dispatch(submit('signup-end'));
          break;
        default:
          this.setState((state) => ({ activeStep: state.activeStep + 1 }));
      }
    } catch (err) {
      this.setState({ catchErr: err });
    }
  }

  prevStep = () => {
    try {
      if (this.state.activeStep === 0) {
        this.props.history.push(ROUTE_INDEX);
      } else {
        this.resetRecaptcha();
        this.setState((state) => ({ activeStep: state.activeStep - 1 }));
      }
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  onCaptchaChange = captcha => {
    try {
      this.props.dispatch(change('signup-start', 'captcha', captcha));
      this.setState({ captcha });
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  expiredCallback = () => {
    try {
      this.props.dispatch(change('signup-start', 'captcha', null));
      this.setState({ captcha: false });
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  toCreateAccount = () => {
    try {
      history.push(ROUTE_CREATE_WALLET);
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  handleModal = () => {
    try {
      this.setState((state) => ({ confirmModal: !state.confirmModal }));
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  resetRecaptcha = () => {
    if (window.grecaptcha) window.grecaptcha.reset();
  };

  render() {
    if (this.state.catchErr) throw this.state.catchErr;

    const { startFormValid, endFormValid, classes } = this.props;
    const disabledNext = (this.state.activeStep === 1 && !endFormValid) || (this.state.activeStep === 0 && (!this.state.captcha || !startFormValid));

    return (
      <Container component="main" maxWidth="sm">
        <MnemonicImportanceModal openOn={this.state.confirmModal} onClose={this.handleModal} onConfirm={this.toCreateAccount} />

        <Grid
                container
                direction={'column'}
                justifyContent="space-between"
                wrap={'nowrap'}
                className="centered-grid spaced-top"
            >
          <IownIcon />
          <Box mb={5} className={this.state.activeStep !== 0 ? classes.hide : ''}>
              <Typography variant="h5" align="center" gutterBottom>
                  Create a New Wallet
              </Typography>
              <SignUpStartForm onSubmit={this.handleRegisterCaptcha} />
              <RecaptchaBox onChange={this.onCaptchaChange} onExpired={this.expiredCallback} />
          </Box>

          <Box mb={5} className={this.state.activeStep !== 1 ? classes.hide : ''}>
              <Typography variant="h5" align="center" gutterBottom>
                  Pick a Password
              </Typography>
              <Typography variant="subtitle2" align="center" gutterBottom>
                  Note: This password will "encrypt" your wallet, take care not to lose it, consider using a <b>password manager</b>
              </Typography>
              <SignUpEndForm
                  currentPassword={this.props.startFormValues}
                  onSubmit={this.handlePasswordForm}
                  // btnDisabled={!startFormValid || !endFormValid}
                  btnHidden
              />
          </Box>
        </Grid>
        <SAMobileStepper maxSteps={5} activeStep={this.state.activeStep} onNext={this.nextStep} onPrev={this.prevStep} disabledNext={disabledNext} />
      </Container>
    );
  }
}

const mapState2props = state => ({
  signup: state.signup,
  startFormValues: getFormValues('signup-start')(state),
  startFormValid: isValid('signup-start')(state),
  endFormValues: getFormValues('signup-end')(state),
  endFormValid: isValid('signup-end')(state),
});

const mapDispatch2props = (dispatch) => ({
  dispatch,
  setFormInfo: (data) => dispatch(setFormInfo(data)),
  registerCaptcha: (data) => dispatch(registerCaptcha(data)),
  clearOldServerError: () => dispatch(clearOldServerError()),
});

export default compose(
  withStyles(styles),
  connect(mapState2props, mapDispatch2props)
)(SignUp);