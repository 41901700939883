import React from "react"
import { compose } from "redux";
import { connect } from "react-redux";
import { getFormValues, isValid } from "redux-form";

import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import { Button, Grid, Typography, CircularProgress, InputAdornment } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import BlockchainLabel from "../../components/BlockchainLabel";
import SAMobileStepper from "../../components/SAMobileStepper";
import BuyTokensForm from '../../components/Forms/BuyTokensForm';
import TopupEtherForm from '../../components/Forms/TopupEtherForm';
import LocationCurrencyPicker from '../../components/Forms/LocationCurrencyPicker';
import ErrorAlert from '../../wallet-components/error-alert';

import { ROUTE_DEFAULT_LOGGEDIN, ROUTE_CASH_TOKEN, ROUTE_WALLET_ASSETS, ROUTE_NX_CASHTRANSFER, ASSET_TYPE_LIQUID } from "../../constants/routes";
import { IN4X_METHOD_OTC } from '../../constants/in4xConstants';

import {
	getFiatCurrencies,
	getExchangeRate,
	getCashRequestLocations,
	createCashRequest,
	getFeeSchedule
} from "../../ducks/in4x";
import {
	setBuyTokensValues,
	setTopupEthValues,
	getTopupEthValues,
	resetForms,
	FORM_BUY_TOKENS,
	FORM_TOPUP_GAS_TOKENS
} from "../../ducks/FormData";

import ProviderUtils from '../../utils/blockchain/ProviderUtils';
import GasUtils from '../../utils/blockchain/GasUtils';
import { exRateIdxKey } from '../../utils/in4xUtils';

class CashTransaction extends React.Component {

	constructor(props) {
  	super(props);
		this.state = {
			catchErr: null,
			bc: '',
			cur: '',
			activeStep: 0,
			showGasCur: false,
			location: '',
			payCur: '',
			gasCur: '',
			payExRateKey: '',
			gasExRateKey: ''
		}
 	}

	componentWillMount = () => {
		const { pathname } = this.props.history.location;
		const urlSplit = pathname.substring(pathname.indexOf(ROUTE_CASH_TOKEN) + ROUTE_CASH_TOKEN.length).split('/');
		let activeBc = urlSplit[0];
		let activeCur = urlSplit[1];
		let gasCur;
		if(activeCur && activeCur !== '' && activeBc && activeBc !== '') {
			ProviderUtils.changeDefaultBlockchain(activeBc);
			gasCur = GasUtils.gasUnitsForBlockchain();
			this.setState({cur: activeCur, bc: activeBc, gasCur});
		} else if(this.props.in4x.activeCurrency) {
			ProviderUtils.changeDefaultBlockchain(this.props.in4x.activeCurrency.blockchain);
			gasCur = GasUtils.gasUnitsForBlockchain();
			this.setState({
				cur: this.props.in4x.activeCurrency.name, 
				bc: this.props.in4x.activeCurrency.blockchain, 
				gasCur
			});
			activeCur = this.props.in4x.activeCurrency.name;
		}
		if(!activeCur) {
			this.props.history.push(ROUTE_DEFAULT_LOGGEDIN);
			return;
		}
		let showGasCur = gasCur && this.props.blockchain.gasBalanceLow.includes(gasCur);
		this.setState({showGasCur: (activeCur && activeCur.toLowerCase() === gasCur? false: showGasCur)});
		this.props.setBuyTokensValues({'recCur': activeCur.toUpperCase()});
		this.props.getCashRequestLocations();		
		this.props.getFeeSchedule();
		if(this.props.in4x.error) {
			this.setState({catchErr: this.props.in4x.error});
		}
	}

	componentWillUnmount = () => {
		this.props.resetForms();
	}


	onLocationChange = async(location) => {
		this.setState({location: location});
		this.props.setBuyTokensValues({location: location});
		await this.props.getFiatCurrencies(location); //Load currencies for picked location
	}

	onCurrencyChange = async(currency) => {
		const { cur } = this.state;
		const payExRateKey = exRateIdxKey(currency, cur);
		const gasExRateKey = exRateIdxKey(currency, GasUtils.gasUnitsForBlockchain());
		this.setState({payCur: currency, payExRateKey, gasExRateKey });
		this.props.setBuyTokensValues({payCur: currency});
		await this.props.getExchangeRate(currency, cur);
	}

	onBuySubmit = async() => {
		const amountData = this.props.FormData.BuyTokens;
		const ethData = this.props.FormData.TopupEth;
		const req = {
			amountBase: Number(amountData.amount),
			amountQuote: Number(amountData.receiveAmount),
			amountEth: Number(ethData.amount),
			eth: Number(ethData.receiveAmount),
			payCur: amountData.payCur,
			recCur: amountData.recCur,
			total: Math.max(ethData.total, amountData.total),
			feeAmount: Number(amountData.fee),
			feeEth: Number(ethData.fee),
			location: amountData.location,
			agentCode: amountData.agentCode,
		}
		await this.props.createCashRequest(req);
		if(this.props.in4x.error) {
			this.setState({catchErr: this.props.in4x.error});
		}
		const trxReq = this.props.in4x.in4xCashTransaction;
		const error = this.props.in4x.error;

		if(error) {
			this.setState({catchErr: error});
		} else {
			this.props.history.push(ROUTE_NX_CASHTRANSFER+trxReq.REFERENCE_ID);
		}
	}

	onBuyCancel = async() => {
		this.props.history.push(ROUTE_WALLET_ASSETS + ASSET_TYPE_LIQUID);
	}

	nextStep = () => {
		switch(this.state.activeStep) {
			case 1:
				return this.processBuyTokens();
			case 2:
				return this.processTopupEther();
			case 3:
				return this.onBuySubmit();
			default: 
				this.setState((state) => ({ activeStep: state.activeStep + 1 }));
		}
	}

	prevStep = () => {
		if(this.state.activeStep === 0) {
			this.props.history.push(ROUTE_WALLET_ASSETS + ASSET_TYPE_LIQUID);
		}
		const inc = this.state.activeStep === 3 && !this.state.showGasCur? 2 : 1;
		this.setState((state) => ({ activeStep: state.activeStep - inc }));
	}

	processBuyTokens = async() => {
		const inc = this.state.showGasCur? 1 : 2;
		if(this.state.showGasCur) {
			await this.props.getExchangeRate(this.state.payCur, this.state.gasCur);
		}
		if(this.props.buyFormValid && this.props.buyFormValues && this.props.buyFormValues.total > 0) {
			this.props.setBuyTokensValues(this.props.buyFormValues);
			this.updateTopupFormValues(Number(this.props.buyFormValues.total));
			this.setState((state) => ({ 
				activeStep: state.activeStep + inc, 
				amount: this.props.buyFormValues.amount, 
				total: this.props.buyFormValues.total, 
				receiveAmount: this.props.buyFormValues.receiveAmount 
			}));
		}
	}

	updateTopupFormValues = (buyTotal) => {
		const formData = this.props.FormData.TopupEth;
		if(!formData) {
			this.props.setTopupEthValues({ prevAmount: buyTotal, total: buyTotal});
			return;
		}
		if(buyTotal !== formData.prevAmount) {
			const { amount, fee, total } = formData;
			const topupTotal = (Number(amount) > 0 && Number(fee) > 0)? buyTotal + Number(amount) + Number(fee) : total;
			this.props.setTopupEthValues({ prevAmount: buyTotal, total: topupTotal });
		}
	}

	processTopupEther = async() => {
		if(this.props.topupFormValues.isAdd === false || (!this.props.topupFormValid && (!this.props.topupFormValues.isAdd || this.props.topupFormValues.amount === '' || this.props.topupFormValues.amount === 0))) {
				this.setState((state) => ({
					activeStep: state.activeStep + 1,
					total: this.props.topupFormValues.prevAmount,
				}));
				return;
		}

		if(this.props.topupFormValid && this.props.topupFormValues && this.props.topupFormValues.total > 0) {
			this.props.setTopupEthValues(this.props.topupFormValues);
			this.setState((state) => ({ 
				activeStep: state.activeStep + 1, 
				total: this.props.topupFormValues.total, 
				amountEth: this.props.topupFormValues.amount,
				receiveEth: this.props.topupFormValues.receiveAmount
			}));
		}
	}

	render() {
    	const { cur, catchErr, activeStep, total, payCur, location, gasCur, receiveAmount, receiveEth, payExRateKey, gasExRateKey } = this.state;
    	const { buyFormValid, topupFormValid, topupFormValues } = this.props;
    	const { exRates, in4xRateLoading, in4xCashTransactionLoading, in4xFeesLoading, in4xFeesLoaded, in4xFees, in4xLimits,
    					in4xCashLocations, in4xCashLocationsLoading, in4xCashLocationsLoaded,
    					in4xBaseCurrencies, in4xCurrenciesLoading } = this.props.in4x;
    	const disabledNext = in4xCashTransactionLoading || !in4xFeesLoaded || !!catchErr || !buyFormValid 
    				|| (activeStep === 0 && (!payCur || !location)) || (activeStep === 2 && !topupFormValid && topupFormValues.isAdd);
    	const showForm = !in4xCashTransactionLoading  && in4xFeesLoaded && !catchErr && !in4xCashLocationsLoading && in4xCashLocationsLoaded;
    	const activeBc = ProviderUtils.activeBlockchain();
		const payRate = exRates && exRates[payExRateKey]? exRates[payExRateKey] : {};
		const gasRate = exRates && exRates[gasExRateKey]? exRates[gasExRateKey] : {};
	    return (
	        <Container className="home" component="main" maxWidth="sm">
	        	<Grid container spacing={1} className="centered-grid">
	        		<Grid item xs={12}>
	        			<BlockchainLabel />
	        			<Typography variant="h5" align="center" gutterBottom className="color-nx">OTC Transaction</Typography>
	        		</Grid>
		        	<Grid item xs={12} className="centered-grid">
							  { activeStep === 0 && showForm && (
		        			<LocationCurrencyPicker 
		        				locations={in4xCashLocations} 
		        				currencies={in4xBaseCurrencies} 
		        				onLocationChange={this.onLocationChange} 
		        				onCurrencyChange={this.onCurrencyChange}
		        				location={location}
		        				currency={payCur}
		        			/>
		        		)}
		        		{ activeStep === 1 && showForm && (
		        			<BuyTokensForm 
		        				base={payCur.toUpperCase()}
		        				quote={cur.toUpperCase()} 
		        				rate={payRate.low}
		        				blockchain={activeBc}
		        				isLoading={in4xCashLocationsLoading || in4xCurrenciesLoading || in4xRateLoading || in4xFeesLoading} 
		        				handleSubmit={this.onBuySubmit} 
		        				handleCancel={this.onBuyCancel} 
		        				showAgent
		        				method={IN4X_METHOD_OTC}
		        				fees={in4xFees}
		        				limits={in4xLimits}
		        				hideButtons
		        			/>
		        		)}
		        		{ activeStep === 2 && (
		        			<Grid item xs={12}>
								<Typography align="left">Add {gasCur.toUpperCase()} to your request?</Typography>
								<TopupEtherForm
									prevAmount={total}
									fees={in4xFees}
									limits={in4xLimits}
									method={IN4X_METHOD_OTC}
									rate={gasRate.low}
									payCur={payCur}
									gasCur={gasCur}
								/>
							</Grid>
		        		)}
		        		{ activeStep === 3 && (
		        			<Grid item xs={12}>
		        				<Typography align="left">Confirm creating a request?</Typography>
		        				<TextField
								    	type="text" 
								    	value={receiveAmount}
								    	name="amount"
								    	label={"Tokens (Estimate)"}
								    	InputProps={{
											startAdornment: <InputAdornment position="start">{cur.toUpperCase()}</InputAdornment>,
										}}
								    	disabled
								    	fullWidth
								    	variant="outlined"
								    	className="override-nx spaced-top-s"
								    />
								    { receiveEth > 0 && (<TextField
								    	type="text" 
								    	value={receiveEth}
								    	name="amountEth"
								    	label={"Gas Tokens (Estimate)"}
								    	InputProps={{
											startAdornment: <InputAdornment position="start">{gasCur.toUpperCase()}</InputAdornment>,
										}}
								    	disabled
								    	fullWidth
								    	variant="outlined"
								    	className="override-nx spaced-top-s"
								    />)}
		        				<TextField
								    	type="text" 
								    	value={total}
								    	name="cur_to"
								    	label="Amount To Pay"
								    	InputProps={{
											startAdornment: <InputAdornment position="start">{payCur.toUpperCase()}</InputAdornment>,
										}}
								    	disabled
								    	fullWidth
								    	variant="outlined"
								    	className="override-nx spaced-top-s"
								    />
								    <Button className="bg-nx spaced-top" variant="contained" fullWidth onClick={this.nextStep} disabled={disabledNext}>Submit</Button>
		        			</Grid>
		        		)}
		        		{ activeStep === 4 && (
		        			<Grid item xs={12}>
							    	<Alert variant="outlined" severity="success">Exchange Request Created!</Alert>
							    	<Typography align="left">Our agent will get in touch with you shortly.</Typography>
							    	<Typography align="left">Funds will be credited to your wallet when you meet our agent.</Typography>
					    		</Grid>
		        		)}
		        		{ (in4xCashLocationsLoading || in4xFeesLoading || in4xCashTransactionLoading) && !catchErr && (
		        			<Grid item xs={12} className="centered-grid">
							    	<CircularProgress />
							    	<Typography className="color-nx" gutterBottom>Processing your request...</Typography>
						    	</Grid>
		        		)}
		        		{ catchErr && (<ErrorAlert onClose={() => { this.setState({catchErr: null}); }} error={this.state.catchErr}/>)}
		        		<SAMobileStepper maxSteps={4} activeStep={activeStep} onNext={this.nextStep} onPrev={this.prevStep} disabledNext={disabledNext} />
		        	</Grid>
	        	</Grid>
	        </Container>
	    )
	}
}

const mapState2props = state => ({
  in4x: state.in4x,
  blockchain: state.blockchain,
  FormData: state.FormData,
  buyFormValues: getFormValues(FORM_BUY_TOKENS)(state),
  buyFormValid: isValid(FORM_BUY_TOKENS)(state),
  topupFormValues: getFormValues(FORM_TOPUP_GAS_TOKENS)(state),
  topupFormValid: isValid(FORM_TOPUP_GAS_TOKENS)(state),
});

const mapDispatch2props = {
	getFiatCurrencies,
  getExchangeRate,
  getCashRequestLocations,
  createCashRequest,
  getFeeSchedule,
  setBuyTokensValues,
	setTopupEthValues,
	getTopupEthValues,
	resetForms
};

export default compose(
  connect(mapState2props, mapDispatch2props)
)(CashTransaction);