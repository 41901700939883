import EtherUtil from "../../utils/ethers";

export const getInventory = async params => {
    const {
        onSuccess = value => value,
        onError = value => value
    } = params;

    const { getNFTInventoryForToken } = EtherUtil;

    try {
        const response = await getNFTInventoryForToken(params);
        onSuccess(response)
    } catch (error) {
        onError(error);
    }
}