import { erc20Abi } from "./abi/erc20";
import { erc721Abi } from "./abi/erc721";
import { uownAbi } from "./abi/uown";
import { stakingVaultAbi } from "./abi/stakingVault";

import { 
    CURRENCY_USD, 
    CURRENCY_ETH,
    CURRENCY_BNB,
    CURRENCY_MATIC
 } from "../constants/currencies";
import {
    BLOCKCHAIN_ETH,
    TRX_TYPE_BALANCE_OF,
    TRX_TYPE_APPROVE,
    TRX_TYPE_TRANSFER,
    TRX_TYPE_DEPOSIT,
    TRX_TYPE_WITHDRAW,
    TRX_TYPE_STAKE,
    TRX_TYPE_SAFE_TRANSFER_FROM,
    TRX_TYPE_NFT_MULTI_TRANSFER,
} from "../constants/blockchain";
import {
    DEFAULT_LIMIT_NATIVE,
} from "../constants/defaultGasSettings";
import {
    TRANSACTION_STATUS_FAILED,
    TRANSACTION_STATUS_PENDING,
    TRANSACTION_STATUS_SUCCESS
} from "../constants/transactionStatus";
import { removePendingListFromBackend } from "../ducks/blockchain";
import BNUtils from "./bnUtils";
import CacheUtil from "./cacheUtil";
import ProviderUtils from "./blockchain/ProviderUtils";
import GasUtils from "./blockchain/GasUtils";
import BlockExplorerUtils from "./blockchain/BlockExplorerUtils";
import {handleFetch} from "./fetch";
import { KEY_CACHE_TRX } from "../constants/storageKeys";
import {erc20And721Abi} from "./abi/mixedTokens";

const ethers = require("ethers");

export default class EtherUtil {

    /**
     * @name monitorPendingTransaction
     * @description adds blockchain listener for pending transaction
     * @param {string} `hash` will be taken from ethers.js transaction object
     * @param {function} `callback` callback called after transaction enters a blockchain
     * @return void
     */
    static monitorPendingTransaction = ({ hash }, callback) => {
        const provider = ProviderUtils.activeProvider();
        provider.on(hash, async (receipt) => {
            const tx = await provider.getTransaction(hash);
            if (tx.blockNumber && callback) {
                callback(receipt);
            }
            provider.removeAllListeners(hash);
        });
    };

    /**
     * @name filterPendingTransactions
     * @description checks transactions' status; adds listeners for pending transactions; sends request for removing non-pending transaction to api
     * @param {array} `txs` api transactions
     * @param {string} `address` user's wallet address
     * @param {function} `dispatch` redux dispatch function
     * @return {Promise<Array>} formatted pending transactions
     */
    static filterPendingTransactions = async (txs, address, dispatch) => {
        const pending = [];
        const remove = [];
        const provider = ProviderUtils.activeProvider();
        return Promise.all(txs.map(async i => {
            const tx = await provider.getTransaction(i.hash);
            if (tx.blockNumber) {
                remove.push(tx.hash);
            } else {
                tx.currency = i.currency;
                tx.value = i.amount;
                pending.push(EtherUtil._formatPendingTx(tx, i.amount, address));
            }
            return pending;
        })).then(() => {
            if (remove.length) {
                dispatch(removePendingListFromBackend(address, remove));
            }

            return pending;
        });
    };

    /**
     * @name estimateTx
     * @description Estimates the gas required for a transaction
     * 
     * @param {string} `address` user's wallet address Target address (receiver, staking address, spender..etc)
     * @param {string} `contractAddress` Address of the smart contract itself (staking address is here if TRX type is TRX_TYPE_DEPOSIT)
     * @param {string} `currency` Currency code (used to check if this is a native transaction)
     * @param {number} `amount` Amount for the transaction
     * @param {string} `trxType` Code for the blockchain transaction type (constants/blockchain)
     * @param {number} `tokenBits` Number of token bits (used for the formatting of the number only)
     * @param {object} `wallet` Wallet which will execute the transaction
     * @param {boolean} `doNotTransform` - do not transform amount into big number format (for values that are not number)
     * @return {Promise<BigNumber>}
     * 
     * @throws GAS_ESTIMATION_ERROR
     */
    static estimateTxGas = async(address, contractAddress, currency, amount, trxType, tokenBits, wallet, doNotTransform) => {
        if (!doNotTransform) {
            amount = ethers.utils.parseUnits(BNUtils.ensureDecimals(amount, tokenBits), tokenBits);
            amount = ethers.BigNumber.from(amount);
        }

        if(currency === GasUtils.gasUnitsForBlockchain()) {
            return ethers.BigNumber.from(DEFAULT_LIMIT_NATIVE);
        }
        switch(trxType) {
            case TRX_TYPE_APPROVE: //not really used
                return await EtherUtil.estimateERC20ApproveTrx(contractAddress, address, amount, wallet);
            case TRX_TYPE_WITHDRAW:
                return await EtherUtil.estimateWithdrawAllTrx(address, wallet);
            case TRX_TYPE_DEPOSIT: //not really used
                return await EtherUtil.estimateDepositTrx(address, amount, wallet);
            case TRX_TYPE_STAKE:
                let estimate = await EtherUtil.estimateERC20ApproveTrx(contractAddress, address, amount, wallet);
                estimate.add(await EtherUtil.estimateDepositTrx(address, amount, wallet)); //to address is actually the staking address
                return estimate;
            case TRX_TYPE_SAFE_TRANSFER_FROM: //NFTs ususally are here, amount = tokenId
                return await EtherUtil.estimateERC721TransferFromTrx(contractAddress, address, amount, tokenBits, wallet);
            case TRX_TYPE_NFT_MULTI_TRANSFER:
                return await EtherUtil.estimateMultiERC721TransferFromTrx(contractAddress, address, amount, tokenBits, wallet);
            default:
                return await EtherUtil.estimateERC20TransferTrx(contractAddress, address, amount, wallet);
        }
    };

    /**
     * @name estimateTrxGas
     * @description Uses Ethers.JS to estimate gas required for a smart contract call
     * 
     * @param {string} `trxType` Type of transaction (function name from ABI) use TRX_TYPE_* constrants
     * @param {string} `contractAddress` Address of the smart contract
     * @param {array} `args` An array of argumest which is expanded as parameters of the smart contract call
     * @param {array} `abi` JSON Array of the smart contract ABI
     * @param {object} `wallet` The wallet instance
     * 
     * @throws GAS_ESTIMATION_ERROR
     */
    static estimateTrxGas = async(trxType, contractAddress, args, abi, wallet) => {
        try {
            wallet = wallet.connect(ProviderUtils.activeProvider());
            const contract = new ethers.Contract(contractAddress, abi, wallet);
            const estimateFn = contract.estimateGas[trxType];
            const estimatedGas = await estimateFn(...args);
            return ethers.BigNumber.from(estimatedGas);
        } catch (e) {
            EtherUtil.onEstimationError(e);
        }
    }

    static estimateERC20TransferTrx = async(contractAddress, address, amount, wallet) => {
        return EtherUtil.estimateTrxGas(TRX_TYPE_TRANSFER, contractAddress, [address, amount], erc20Abi, wallet);
    }

    static estimateERC721TransferFromTrx = async(contractAddress, address, tokenId, tokenBits, wallet) => {
        const tokenIdBN = ethers.utils.hexlify(tokenId);
        return EtherUtil.estimateTrxGas(TRX_TYPE_SAFE_TRANSFER_FROM, contractAddress, [wallet.address, address, tokenIdBN], erc721Abi, wallet);
    }

    static estimateMultiERC721TransferFromTrx = async(contractAddress, address, tokenIds, tokenBits, wallet) => {
        const tokenIdBNs = tokenIds.split(",").map(tokenid =>  ethers.utils.parseUnits(tokenid, tokenBits).toHexString());

        return EtherUtil.estimateTrxGas(
            TRX_TYPE_NFT_MULTI_TRANSFER,
            contractAddress,
            [address, tokenIdBNs],
            erc721Abi,
            wallet
        );
    }

    static estimateERC20ApproveTrx = async(contractAddress, spender, amount, wallet) => {
        return EtherUtil.estimateTrxGas(TRX_TYPE_APPROVE, contractAddress, [spender, amount.toString()], erc20Abi, wallet);
    }

    static estimateDepositTrx = async(stakingAddress, stakingAmount, wallet) => {
        return EtherUtil.estimateTrxGas(TRX_TYPE_DEPOSIT, stakingAddress, [stakingAmount.toString()], stakingVaultAbi, wallet);
    }

    static estimateWithdrawAllTrx = async(stakingAddress, wallet) => {
        return EtherUtil.estimateTrxGas(TRX_TYPE_WITHDRAW, stakingAddress, [], stakingVaultAbi, wallet);
    }

    static onEstimationError = (e) => {
        if(e && e.error && e.error.body) {
            const decode = JSON.parse(e.error.body);
            if(decode && decode.error && decode.error.message) {
                throw new Error(decode.error.message);
            }
        }
        throw new Error('GAS_ESTIMATION_ERROR');
    }

    /**
     * @name getBalance
     * @description fetches user's eth balance
     * @param {string} `blockchain` Blockchain of the balance ex: `eth`
     * @param {string} `network` Network of the balance ex `goerli`
     * @param {object} `wallet` ethers.js wallet object
     * @return {Promise<BigNumber>}
     */
    static getBalance = (blockchain, network, wallet) => {
        if(wallet.provider.connectedBlockchain !== blockchain || wallet.provider.connectedNetwork !== network) {
            wallet = wallet.connect(ProviderUtils.providerFor(network, blockchain));
        }
        return wallet.getBalance();
    }

    /**
     * @name getTokenBalance
     * @description fetches user's erc20 token balance
     * @param tokenAddress
     * @param {string} `blockchain` Blockchain of the token ex: `eth`
     * @param {string} `network` Network of the token ex `goerli`
     * @param {object} `wallet` ethers.js wallet object
     * @return {Promise<BigNumber>}
     */
    static getTokenBalance = async (tokenAddress, blockchain, network, wallet) => {
        if(!tokenAddress || !wallet) {
            return 0;
        }
        if(wallet.provider.connectedBlockchain !== blockchain || wallet.provider.connectedNetwork !== network) {
            wallet = wallet.connect(ProviderUtils.providerFor(network, blockchain));
        }
        const contract = new ethers.Contract(tokenAddress, erc20Abi, wallet);
        return await contract.functions.balanceOf(wallet.address);
    };

    static getNftBalance = async(tokenAddress, blockchain, network, wallet) => {
        if(!tokenAddress || !wallet) {
            return 0;
        }
        if(wallet.provider.connectedBlockchain !== blockchain || wallet.provider.connectedNetwork !== network) {
            wallet = wallet.connect(ProviderUtils.providerFor(network, blockchain));
        }
        const contract = new ethers.Contract(tokenAddress, erc721Abi, wallet);
        const balanceOf = contract.functions[TRX_TYPE_BALANCE_OF];
        return await balanceOf(wallet.address);  
    }

    static getNFTInventoryForToken = async (params) => {
        const { tokenAddress, blockchain, wallet, pageKey } = params;
        const owner = wallet?.address || "";
        const alchemyUrl = ProviderUtils.alchemyFor(blockchain, ProviderUtils.activeNetwork());
        const response = await handleFetch(`${alchemyUrl}/getNFTs/`, 'GET', { owner, pageKey, 'contractAddresses[]': tokenAddress }, true);
        const nextNFTs = response?.ownedNfts || [];
        const formattedNftList = nextNFTs.map(item => {
            item.tokenId = parseInt(item.id.tokenId);
            return item;
        });

        return {
            ...response,
            ownedNfts: formattedNftList,
        }
    };
    /**
     * @name getStakingBalance
     * @description fetches user's staking data
     * @param stakingAddress
     * @param blockchain
     * @param {object} `wallet` ethers.js wallet object
     * @return {Promise<Object>}
     */
    static getStakingBalance = async (stakingAddress, blockchain, wallet) => {
        if(!stakingAddress || !wallet) {
            return 0;
        }
        if(blockchain !== ProviderUtils.activeBlockchain() || wallet.provider.network !== ProviderUtils.activeNetwork()) {
            wallet = wallet.connect(ProviderUtils.providerFor(ProviderUtils.activeNetwork(), blockchain));
        }
        const contract = new ethers.Contract(stakingAddress, stakingVaultAbi, wallet);
        const balanceOf = contract.functions[TRX_TYPE_BALANCE_OF];
        const stakingBalanceBN = await balanceOf(wallet.address);
        return EtherUtil.lowValue(stakingBalanceBN, 18);
    };

    /**
     * @name getStakingBalance
     * @description fetches user's staking data
     * @param stakingAddress
     * @param blockchain
     * @param {object} `wallet` ethers.js wallet object
     * @return {Promise<Array>}
     */
    static getStakingTransactions = async (stakingAddress, blockchain, wallet) => {
        if(!stakingAddress || !wallet) {
            return [];
        }
        if(blockchain !== ProviderUtils.activeBlockchain() || wallet.provider.network !== ProviderUtils.activeNetwork()) {
            wallet = wallet.connect(ProviderUtils.providerFor(ProviderUtils.activeNetwork(), blockchain));
        }
        const contract = new ethers.Contract(stakingAddress, stakingVaultAbi, wallet);
        const depositRecords = await contract.functions.getUserDepositRecords(wallet.address);
        //TODO process depositRecords, each record is:
        // struct UserStakingDepositsRec
        // {
        //     uint256 depositAmount; //Tracking the deposit amount
        //     uint    depositTimeStamp; //The deposit timestamp
        //     uint    rewardProjectedTimeStamp; // The expected datetime stamp for the reward
        //     bool    isWithdrawn; // Indicate if the deposit withdrawn
        //     bool    isWithdrawnWithPenalty; // incdicate if the deposit withdrawn at early stage with penalty
        //     uint256 totalRewards; //Indicates the number of rewards
        // }
        let trxList = [];
        let rewards = 0;

        if(depositRecords && depositRecords.length > 0 && depositRecords[0].length > 0) {//Array in array
            depositRecords[0].forEach((trx) => {
                if(trx.isWithdrawnWithPenalty === false) {
                    rewards += trx.totalRewards.toNumber();
                }
                trxList.push({
                    amount: EtherUtil.lowValue(trx.depositAmount, 18),
                    reward: trx.totalRewards.toNumber(),
                    stakingStatus: EtherUtil.getStakingStatus(trx),
                    startingDate: EtherUtil.bnToDate(trx.depositTimeStamp),
                    releaseDate: EtherUtil.bnToDate(trx.rewardProjectedTimeStamp),
                });
            });    
        }
        trxList.reverse(); //newest first
        return {trxList, rewards};
    }

    /**
     * @name getTokenValueOf
     * @description fetches user's erc223Govered token balance
     * @param tokenAddress
     * @param {object} `wallet` ethers.js wallet object
     * @return {Promise<BigNumber>}
     */
    static getTokenValueOf = async (tokenAddress, wallet) => {
      if(!tokenAddress || !wallet) {
          return 0;
      }
      wallet = wallet.connect(ProviderUtils.activeProvider());
      const contract = new ethers.Contract(tokenAddress, uownAbi, wallet);
      return await contract.functions.balanceOf(wallet.address);
    };

    /**
     * @name getUownBalance
     * @description fetches user's uown balance in a principal (company/venture)
     * @param {string} tokenAddress Address of the uOWN Token
     * @param {string} principal Address of the principal contract
     * @param {object} `wallet` ethers.js wallet object
     * @return {Promise<number>}
     */
    static getUownBalance = async (tokenAddress, principal, wallet) => {
        if(!tokenAddress || !principal || !wallet) {
          return 0;
        }
        wallet = wallet.connect(ProviderUtils.activeProvider());
        const contract = new ethers.Contract(tokenAddress, uownAbi, wallet);
        return await contract.functions.balanceIn(principal, wallet.address);
    }

    static calculateFee = (gasLimit, gasPrice) => {

        if (!gasLimit || !gasPrice) return 0;

        let fee = ethers.utils.parseUnits(BNUtils.ensureDecimals(gasPrice, 9), 'gwei');
        fee = fee.mul(gasLimit);

        return ethers.utils.formatEther(fee);
    }

    /**
     * Decodes data of a transaction against known ERC20, ERC721 contract functions
     * @param data `string` An encoded smart contract data attribute of a transaction
     * @param value `string` Value attribute of a transaction
     * @return {TransactionDescription} Transaction description object of data
     */
    static decodeTransactionData = (data, value) => {
        let decoded = data;
        if(data) {
            try {//We attempt to parse as an ERC20 or ERC721, otherwise show original data
                const contractInterface = new ethers.utils.Interface(erc20And721Abi);
                let decodedData = contractInterface.parseTransaction({data, value});
                decodedData.toString = () => {
                    return `${decodedData.name}( ${decodedData.args.join(', ')} )`
                }
                return decodedData;
            } catch(e) {}
        }
        return decoded;
    }

    /**
     * @name isValidAddress
     * @description validates ethereum wallet address
     * @param {string} `address`
     * @return {boolean}
     */
    static isValidAddress = address => {
        try {
            ethers.utils.getAddress(address);
        } catch (err) {
            return false;
        }
        return true;
    };

    /**
     * @name lowValue
     * @description formats ethereum blockchain numbers (10^18) to human-usable numbers
     * @param {BigNumber | number | string} value
     * @param tokenBits
     * @return {number}
     */
    static lowValue = (value, tokenBits) => {
        if(!value) {
            return 0;
        }
        if(value === '?') {
            return value;
        }
        if(ethers.BigNumber.isBigNumber(value)) {
            return ethers.utils.formatUnits(value,  Number(tokenBits));
        } else if(value && typeof value === 'object' && value[0] && ethers.BigNumber.isBigNumber(value[0])) {
            //balanceOf functions usually are here ([0: BigNumber, balance: BigNumber])
            return ethers.utils.formatUnits(value[0],  Number(tokenBits));
        }
        let val = ethers.BigNumber.from(value);//.div(tokenBit);
        return ethers.utils.formatUnits(val, Number(tokenBits));
    };

    static bnToDate = (timestampBn) => {
        if(!ethers.BigNumber.isBigNumber(timestampBn)) {
            return '';
        }
        const timestampSeconds = timestampBn.toNumber();
        let date = new Date(timestampSeconds * 1000);
        return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    }

    static abiForTrxType = (trxType) => {
        switch(trxType) {
            case TRX_TYPE_DEPOSIT:
            case TRX_TYPE_WITHDRAW:
                return stakingVaultAbi;
            case TRX_TYPE_SAFE_TRANSFER_FROM:
                return erc721Abi;
            case TRX_TYPE_NFT_MULTI_TRANSFER:
                return erc721Abi;
            default:
                return erc20Abi;

        }
    }

    static getStakingStatus(stakingTrx) {
        if(!stakingTrx) {
            return '';
        }
        if(stakingTrx.isWithdrawnWithPenalty) {
            return 'Unstaked';
        }
        if(stakingTrx.isWithdrawn) {
            return 'Withdrawn';
        }
        if(ethers.BigNumber.isBigNumber(stakingTrx.rewardProjectedTimeStamp) && stakingTrx.rewardProjectedTimeStamp.toNumber > (new Date()).getTime()) {
            return 'Awarded';
        }
        return 'Staking';
    }


    /**
     * @name newApproveTransaction
     * @description Submits a new blockchain signed transaction (smart contract write function)
     *
     * @param {object} `wallet` user's ethers.js wallet object
     * @param {string} `tokenAddress` Address of the target smart contract
     * @param {string} `spenderAddress` Address of the spender (the one who receives the allowance)
     * @param {string} `approveAmount` Allowance amount to add
     * @param tokenBits
     * @param {object} `overrideOptions` Miner override options
     */
    static newApproveTransaction = async(wallet, tokenAddress, spenderAddress, approveAmount, tokenBits, overrideOptions) => {
        let value = ethers.utils.parseUnits(approveAmount, tokenBits);
        value = ethers.BigNumber.from(value);
        return EtherUtil.newSmartContractTransaction(wallet, TRX_TYPE_APPROVE, tokenAddress, [spenderAddress, value], overrideOptions);
    }

    /**
     * @name newDepositTransaction
     * @description Submits a new blockchain signed transaction (smart contract write function)
     * 
     * @param {object} `wallet` user's ethers.js wallet object
     * @param {string} `stakingContract` Address of the target smart contract
     * @param {string} `depositAmount` Amount to deposit (Requires that amount is in allowance of the staking contract)
     * @param {int} `tokenBits` Token bits used (ususally sendingCurrencyObj.bits or 18)
     * @param {object} `overrideOptions` Miner override options 
     */
    static newDepositTransaction = async(wallet, stakingContract, depositAmount, tokenBits, overrideOptions) => {
        let value = ethers.utils.parseUnits(depositAmount, tokenBits);
        value = ethers.BigNumber.from(value);
        return EtherUtil.newSmartContractTransaction(wallet, TRX_TYPE_DEPOSIT, stakingContract, [value], overrideOptions);
    }

    /**
     * @name newWithdrawTransaction
     * @description Submits a new blockchain signed transaction (smart contract write function)
     *
     * @param {object} `wallet` user's ethers.js wallet object
     * @param {string} `stakingContract` Address of the target smart contract
     * @param {object} `overrideOptions` Miner override options
     */
    static newWithdrawTransaction = async(wallet, stakingContract, overrideOptions) => {
        return EtherUtil.newSmartContractTransaction(wallet, TRX_TYPE_WITHDRAW, stakingContract, [], overrideOptions);
    }

    /**
     * @name newSmartContractTransaction
     * @description Submits a new blockchain signed transaction (smart contract write function)
     * 
     * @param {object} `wallet` user's ethers.js wallet object
     * @param {string} `trxType` Abi name of the function (can be used for keccak256 calculations), see constants/blockchain
     * @param {string} `targetAddress` Address of the target smart contract
     * @param {object} `args` Smart contract function parameters (arguments)
     * @param {object} `overrideOptions` Miner override options 
     * 
     * @see https://docs.ethers.io/v5/api/contract/contract/#Contract--write
     */
    static newSmartContractTransaction = async(wallet, trxType, targetAddress, args, overrideOptions) => {
        wallet = wallet.connect(ProviderUtils.activeProvider());
        const abi = EtherUtil.abiForTrxType(trxType);
        const contract = new ethers.Contract(targetAddress, abi, wallet);

        let tx = await contract.functions[trxType](...args, overrideOptions);
        const amount = args.amount || 0;
        tx = EtherUtil._formatPendingTx(tx, amount, wallet.address);
        return tx;
    }

    /**
     * @name newTokenTransaction
     * @description creates new Token transaction and sends it to blockchain
     * @param {object} `wallet` user's ethers.js wallet object
     * @param sendingCurrency
     * @param tokenAddress
     * @param {string} `to` target address
     * @param {number} `amount` token amount
     * @param {object} `gasOptions` Gas options (ususally GasUtils.generateTransactionGas())
     * @param {number} `tokenBits` how many bits the token uses
     * @return {Promise<object | string>} formatted pending transaction or error message
     */
    static newTokenTransaction = async (wallet, sendingCurrency, tokenAddress, to, amount, gasOptions, tokenBits) => {
        if(parseInt(tokenBits) %1 !== 0) {//invalid integer
            throw new Error('Invalid number of bits: '+ tokenBits);
        }
        wallet = wallet.connect(ProviderUtils.activeProvider());
        const contract = new ethers.Contract(tokenAddress, erc20Abi, wallet);
        let value = ethers.utils.parseUnits(amount, tokenBits);
        value = ethers.BigNumber.from(value);

        let tx = await contract.functions.transfer(to, value.toString(), gasOptions);
        tx.currency = sendingCurrency;
        tx = EtherUtil._formatPendingTx(tx, amount, wallet.address);
        return tx;
    }

    /**
     * @name newTokenWithdrawAllTransaction
     * @description creates new Token transaction and sends it to blockchain
     * @param { object } `wallet` user's ethers.js wallet object
     * @param { string } sendingCurrency quote currency
     * @param { string } tokenAddress  token contract address
     * @param amount
     * @return {Promise<object | string>} formatted pending transaction or error message
     */
    static newTokenWithdrawAllTransaction = async (wallet, sendingCurrency, tokenAddress, amount) => {
        const provider = ProviderUtils.activeProvider();
        const walletInstance = wallet.connect(provider);
        const contract = new ethers.Contract(tokenAddress, stakingVaultAbi, walletInstance);
        const transaction = await contract.functions.withdrawAll();
        transaction.currency = sendingCurrency;

        return EtherUtil._formatPendingTx(transaction, amount, wallet.address);
    }

    /**
     * @name newSimpleTransaction
     * @description creates new transaction (which doesn't contain data)
     * @param {object} `wallet` user's ethers.js wallet object
     * @param {string} `to` target address
     * @param {number | string} `amount` ethereum amount
     * @param {object} `gasOptions` Gas options (usually GasUtils.generateTransactionGas())
\     * @return {Promise<object>} formatted pending transaction
     */
    static newSimpleTransaction = async (wallet, to, amount, gasOptions) => {
        let value = ethers.utils.parseUnits(amount, 18);
        value = ethers.BigNumber.from(value);
        // Allowed keys are "accessList", "chainId", "data", "from", "gasLimit", "gasPrice", "maxFeePerGas", "maxPriorityFeePerGas", "nonce", "to", "type", "value"
        let transaction = Object.assign({
            to,
            value: ethers.utils.hexValue(value),
        }, gasOptions);

        wallet = wallet.connect(ProviderUtils.activeProvider());

        let tx = await wallet.sendTransaction(transaction);
        tx.currency = GasUtils.gasUnitsForBlockchain();
        tx = EtherUtil._formatPendingTx(tx, amount, wallet.address);
        return tx;
    };

    static balanceInUsd = (balance, blockchain, iownRate) => {
        let total = 0;
        if(!balance || !balance[blockchain]) {
            return 0;
        }
        balance = balance[blockchain];
        if(balance['iown'] !== '?' && balance['iown'] > 0) total+= Number(balance['iown']) * iownRate;
        if(balance['usdt'] !== '?' && balance['usdt'] > 0) total+= Number(balance['usdt']);
        if(balance['usdc'] !== '?' && balance['usdc'] > 0) total+= Number(balance['usdc']);
        if(balance['busd'] !== '?' && balance['busd'] > 0) total+= Number(balance['busd']);
        return total;
    }

    /**
     * @name _formatPendingTx
     * @description formats pending transaction object for ui purpose
     * @param {object} `tx` ethers.js transaction
     * @param {number} `amount` transaction value
     * @param {string} `address` user's wallet address
     * @return {object}
     * @private
     */
    static _formatPendingTx = (tx, amount, address) => {
        tx.status = TRANSACTION_STATUS_PENDING;
        tx.direction = tx.to === address ? "SELF" : "OUT";
        tx.value = amount;
        tx.confirmations = 0;
        tx.pending = true;
        tx.blockchain = ProviderUtils.activeBlockchain();
        tx.network = ProviderUtils.activeNetwork();
        return tx;
    };

    /**
     * @name _formatTx
     * @description formats transaction object for ui purpose
     * @param {object} `tx` ethers.js transaction
     * @param blockchain
     * @param network
     * @param blockchain
     * @param network
     * @param {string} `address` user's wallet address
     * @param {string} `currency` Currency Name
     * @param {integer} `tokenBits` Token bits
     * @return {object}
     * @private
     */
    static _formatTx = (tx, blockchain, network, address, currency, tokenBits = 18) => {
        address = address.toLowerCase();
        tx.direction = tx.from === address && tx.to === address ? "SELF" : tx.from === address ? "OUT" : "IN";
        tx.blockchain = blockchain;
        tx.network = network;
        if (!tx.to) {
            tx.to = "";
        }        
        tx.fee = ethers.utils.formatEther(ethers.BigNumber.from(tx.gasPrice).mul(tx.gasUsed));
        tx.currency = currency;
        tx.value = tx.value ? EtherUtil.lowValue(tx.value, tokenBits) : 0;
        tx.status = !tx.blockNumber ? TRANSACTION_STATUS_PENDING : tx.txreceipt_status === "0" ? TRANSACTION_STATUS_FAILED : TRANSACTION_STATUS_SUCCESS;

        return tx;
    };

    static getTransaction = async(transactionHash, blockchain = null) => {
        const provider = blockchain? ProviderUtils.providerFor(ProviderUtils.activeNetwork(), blockchain): ProviderUtils.activeProvider();
        return provider.getTransaction(transactionHash);
    }

    static getTransactionReceipt = async(transactionHash, blockchain = null) => {
        const provider = blockchain? ProviderUtils.providerFor(ProviderUtils.activeNetwork(), blockchain): ProviderUtils.activeProvider();
        return provider.getTransactionReceipt(transactionHash);
    }

    static getTransactions = async(currency, address, tokenAddress = null, blockchain = BLOCKCHAIN_ETH, tokenBits = 18, page = 1, perPage = 15, force = false, isNft = false) => {
        page++;
        let count = await EtherUtil.getTxCountCacheEnabled(blockchain, currency, address, tokenAddress, force, isNft);
        if(count === 0) {
            return { transactions: [], count: 0 };
        }
        let trxPage = Math.ceil((page * perPage) / 10000);
        let txs = await EtherUtil.getTransactionsCacheEnabled(currency, address, tokenAddress, blockchain, isNft, trxPage, 0); //no force since cache is updated above
        txs = txs.slice((page - 1) * perPage, page * perPage); //pagination
        return { transactions: txs.map(i => EtherUtil._formatTx(i, blockchain, ProviderUtils.activeNetwork(), address, currency, tokenBits)), count };
    };

    static getTransactionsCacheEnabled = async(currency, address, tokenAddress = null, blockchain = BLOCKCHAIN_ETH, isNft = false, page = 1, sinceBlock = 0, force = false, perPage = 10000) => {        
        if(!currency || !address) {
            return [];
        }
        const cacheKey = blockchain + '_' + currency + '_' + address + '_' + (tokenAddress? tokenAddress + '_' : '') + page + '_' + sinceBlock;

        let cache = CacheUtil.getCache(KEY_CACHE_TRX, cacheKey);
        if(!force && cache) { return cache; } //cache hit
        let trxList;

        switch(currency) {
            case CURRENCY_USD:
                trxList = []; break;
            case CURRENCY_ETH:
                trxList = await BlockExplorerUtils.getEthTransactionsWithBlock(address, page, sinceBlock, perPage); break;
            case CURRENCY_BNB:
                trxList = await BlockExplorerUtils.getBscTransactionsWithBlock(address, page, sinceBlock, perPage); break;
            case CURRENCY_MATIC:
                trxList = await BlockExplorerUtils.getMaticTransactionsWithBlock(address, page, sinceBlock, perPage); break;
            default: //Handle everything else as token
                trxList = await BlockExplorerUtils.getTokenTransactionsWithBlock(address, tokenAddress, blockchain, page, sinceBlock, perPage, isNft); break;
        }     
        if(trxList && trxList.length) {
            CacheUtil.setCache(KEY_CACHE_TRX, cacheKey, trxList);
            //Cache also as though we're used the sinceBlock
            if(sinceBlock === 0 && trxList.length > 0) {
                const dKey = currency + '_' + address + '_' + (tokenAddress? tokenAddress + '_' : '') + page + '_' + trxList[0].blockNumber;
                CacheUtil.setCache(KEY_CACHE_TRX, dKey, trxList);
            }
        }
        return trxList;
    };

    static getTxCountCacheEnabled = async(blockchain, currency, address, tokenAddress = null, force = false, isNft = false) => {        
        if(!currency || !address) {
            return 0;
        }
        let options = {
            sinceBlocks: {},
            counts: {},
            hashes: {},
            since: {}
        }
        options.sinceBlocks[currency] = 0;
        options.counts[currency] = 0;
        options.hashes[currency] = "";
        options.since[currency] = 0;

        let cache = CacheUtil.getCache(KEY_CACHE_TRX, address);     
        if(!force && cache && cache.length) {
            if(cache.since[currency] >= Date.now() + 300000) { return cache.counts[currency]; } //Cache hit
            options.sinceBlocks = Object.assign(options.sinceBlocks, cache.sinceBlocks);
            options.counts = Object.assign(options.counts, cache.counts);
            options.hashes = Object.assign(options.hashes, cache.hashes);
            options.since = Object.assign(options.since, cache.since);
        }
        let page = 1, trxList;
        do {
            trxList = await EtherUtil.getTransactionsCacheEnabled(currency, address, tokenAddress, blockchain, isNft, page++, options.sinceBlocks[currency], force);
            if(trxList.length > 0 && options.sinceBlocks[currency] !== trxList[0].blockNumber) {
                options.sinceBlocks[currency] = trxList[0].blockNumber;
                options.counts[currency] += trxList.length;
                options.hashes[currency] = trxList[0].hash;
            }
            options.since[currency] = Date.now();
        } while(trxList.length === 10000);
        CacheUtil.setCache(KEY_CACHE_TRX, address, options);
        return options.counts[currency];
    };


    /**
     * Executes blockchain transaction
     * @param {object} params - estimate function call parameters
     * @param {object} params.walletInstance - users wallet instance in ethers format.
     * @param {string} params.contractAddress - blockchain contract address.
     * @param {object} params.contractAbi - contract abi.
     * @param {string} params.transactionType - name of contract method to be estimated.
     * @param {array} params.transactionParams - arguments of contract method to be called with.
     * @param {boolean} params.estimate - estimate gas limit for method call.
     * @returns result
     */
    static blockChainExecute = async (params) => {
        const {
            wallet = {},
            contractAddress = "",
            contractAbi = {},
            transactionType = "",
            transactionParams = [],
            estimate = false,
            transactionOverrides = {},
        } = params;

        const contractInstance = new ethers.Contract(
            contractAddress,
            contractAbi,
            wallet,
        );

        if (estimate) {
            return await contractInstance.estimateGas[transactionType](...transactionParams);
        }

        return await contractInstance[transactionType](...transactionParams, transactionOverrides);
    }
}

