import React from "react";
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';

const StakeTokensAccordion = ({ stakes = [] }) => {

	const [expanded, setExpanded] = React.useState(null);
	const handleChange = (panel) => (event, newExpanded) => {
    	setExpanded(newExpanded ? panel : false);
  	};

	return (
		<>
			{ stakes.map((s, idx) => (
				<Accordion key={"panel-"+idx} square expanded={expanded === 'panel-'+idx} onChange={handleChange('panel-'+idx)}>
					<AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className={s.stakingStatus === 'Unstaked' ? 'bg-grey' : "forced-bg-primary"}>
						<Grid container spacing={1} justifycontent="space-between" alignItems="center">
							<Grid item xs={6}>
								<Typography>Stake #{stakes.length-idx}</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography>iOWN {Number(s.amount).toLocaleString(undefined, {maximumFractionDigits: 18})}</Typography>
							</Grid>
						</Grid>
			        </AccordionSummary>
			        <AccordionDetails className="no-padding">
			        	<Table size="small" aria-label="Stake Details">
                            <TableBody>
                                <TableRow key="rw">
                                    <TableCell component="td" scope="row">Reward NFTs:</TableCell>
                                    <TableCell align="right">
	                                    <Grid container justifyContent="flex-end" alignItems="center">
	                                    	<Typography fontSize="2">{s.reward} x &nbsp;</Typography>
	                                    	<img src="/img/nft.gif" alt="NFT" id="amlak-nft" style={{width: '45px'}} />
	                                    </Grid>
                                   	</TableCell>
                                </TableRow>
                                <TableRow key="sd">
                                    <TableCell component="td" scope="row">Staking Date:</TableCell>
                                    <TableCell align="right"><Typography
                                        fontSize="2">{s.startingDate}</Typography></TableCell>
                                </TableRow>
                                <TableRow key="b1d">
                                    <TableCell component="td" scope="row">Token Release:</TableCell>
                                    <TableCell align="right"><Typography
                                        fontSize="2">{s.releaseDate}</Typography></TableCell>
                                </TableRow>
                                <TableRow key="st">
                                    <TableCell component="td" scope="row">Status</TableCell>
                                    <TableCell align="right"><Typography
                                        fontSize="2">{s.stakingStatus}</Typography></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
		        	</AccordionDetails>
	      		</Accordion>
			))}
		</>
	);
}

export default StakeTokensAccordion;