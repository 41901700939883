import React from 'react'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Typography from "@material-ui/core/Typography";
import { Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({

    listItemText: {
        '& p': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        }
    }
}));

const AddressList = props => {

    const classes = useStyles();

    const {
        title,
        addresses,
        deleteButtonClick,
        disableDeleteButton,
        addressItemClick
    } = props;

    return (
        <Grid item xs={12}>
            <Accordion disabled={!addresses || addresses.length === 0} style={{backgroundColor: '#fff'}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: '#0082BC'}} />}>
                    <Typography style={{color: '#0082BC'}}>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List style={{width: '100%'}}>
                        {addresses?.map((item, key) => (
                            <ListItem
                                key={key}
                                style={{paddingLeft: '0px'}}
                                onClick={() => addressItemClick(item)}
                            >
                                <ListItemText
                                    className={classes.listItemText}
                                    primary={item.name}
                                    secondary={item.address}
                                />
                                { !(disableDeleteButton === true) && (
                                    <ListItemSecondaryAction
                                        style={{right: '0px'}}
                                    >
                                        <IconButton edge="end" aria-label="delete" onClick={() => deleteButtonClick(item) }>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                )}
                            </ListItem>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}

AddressList.propTypes = {
    title: PropTypes.string.isRequired,
    addresses: PropTypes.array.isRequired,
};

export default AddressList;
