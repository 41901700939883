import React from "react";

import { Link, Button, Typography } from "@material-ui/core";
import { openLink } from '../../utils/linksOpenPolyfill';
import ProviderUtils from "../../utils/blockchain/ProviderUtils";
import BlockExplorerUtils from "../../utils/blockchain/BlockExplorerUtils";
import { ROUTE_DEFAULT_LOGGEDIN } from "../../constants/routes";

import Modal from "../../components/Modal";

const TransactionCreatedModal = (props) => {
  const scanUrl = BlockExplorerUtils.scanUrl(props.trxhash, ProviderUtils.activeBlockchain());
	return (
		<Modal
      open={props.openOn}
      onClose={props.onClose}
      modalBody={<>
          <Typography gutterBottom>
              Transaction <a color="primary"
                  href={scanUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => openLink(e, scanUrl)}>{props.trxhash}</a> successfully created
          </Typography>
          <Link to={ROUTE_DEFAULT_LOGGEDIN}>
              <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={props.onClose}
              >
                  Close
              </Button>
          </Link>
      </>}
    ><>{""}</></Modal>
	);
}

export default TransactionCreatedModal;