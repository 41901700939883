import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {changeIodOTP} from "../../ducks/iod";
import BarcodeScanner from "./BarcodeScanner";

const IodOTPScanner = (props) => {

    const {
        changeIodOTP = () => {},
        history = {
            goBack: () => {}
        }
    } = props;

    const onScan = (text) => {
        try {
            const { otp } = JSON.parse(text) || {};
            if (otp) {
                changeIodOTP(otp);
                history.goBack();
            }
        } catch (err) {
            setTimeout(history.goBack, 1500);
        }
    }

    return (
        <BarcodeScanner
            onScan={onScan}
            onScannerClose={props.history.goBack}
            prompt="Scan an OTP QR Code for a Wallet"
        />
    );
}

export default compose(
    connect(null, {changeIodOTP})
)(IodOTPScanner);