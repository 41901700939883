import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from "@material-ui/core/Modal";

const useStyles = makeStyles(theme => ({
  paperWrap: {
    height: '100%',
    maxWidth: '444px',
    display: 'flex',
    margin: 'auto',
    alignItems: 'center',
    outline: 'none',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    outline: 'none',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '25px',
    wordBreak: 'break-word',
  },
}));

export default function CustomModal({open, onClose, modalBody}) {
  const classes = useStyles();

  return (
      <Modal
          disableEnforceFocus
          open={open}
          onClose={onClose}
      >
        <div className={classes.paperWrap}>
          <div className={classes.paper}>
            {modalBody}
          </div>
        </div>
      </Modal>
  );
}
