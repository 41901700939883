import React from "react";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import Alert from '@material-ui/lab/Alert';

import Fingerprint from "../../BiometricsAuth";

import { KEY_ACTIVE_BIO_HASH, KEY_MULTI_USER_BIO_ACTIVE } from "../../constants/storageKeys";

import { getError } from "../../utils/transformErrors";
import { decryptDES } from "../../utils/cryptoUtil";
import StorageUtils from "../../utils/storageUtils";

class BiometricsUnlock extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showErr: null,
      catchErr: null,
      bioAvailable: false,
      bioEnabled: false,
      bioStatus: false,
    };
    const hash = StorageUtils.getItem(KEY_ACTIVE_BIO_HASH);
    const isActive = !!StorageUtils.getItem(KEY_MULTI_USER_BIO_ACTIVE);
    if (hash && hash.length > 0 && isActive) this.state.bioEnabled = true;
  }

  componentWillMount() {
    document.addEventListener("deviceready", () => {
      Fingerprint.isAvailable((result) => {
        if (result) {
          this.setState({ bioAvailable: true });
          if (this.state.bioEnabled) {
            this.bioUnlock();
          }
        }
      });
    });
  };

  bioUnlock = async () => {
    try {
      this.setState({bioStatus: false});
      await Fingerprint.loadBiometricSecret({
        title: 'Biometrics Wallet Unlock',
        description: 'Authenticate to Unlock',
        disableBackup: true
      }, (encSeed) => {
        if (encSeed && this.props.onSuccess) {
          let hashPassword = decryptDES(StorageUtils.getItem(KEY_ACTIVE_BIO_HASH), encSeed);
          this.setState({ bioStatus: 1 });
          this.props.onSuccess(hashPassword);
        } else if(!encSeed) {
          //App thinks biometrics is active when its not
          StorageUtils.setItem(KEY_MULTI_USER_BIO_ACTIVE, false);
        }
      }, (e) => {
        if (e && e.code !== -108 && e.message) {
          this.setState({ showErr: 'Biometrics unlock failed: ' + getError(e.message), bioStatus: -1 });
        }
      })
    } catch (e) {
      if (e && e.message) {
        this.setState({ showErr: 'Biometrics unlock error: ' + e.message, bioStatus: -1 });
      }
    }
  }

  render() {
    const { bioAvailable, bioStatus } = this.state;
    return ( 
      <Grid item xs={12} className="spaced-top"> {
        this.state.showErr && (
          <Alert variant="outlined" severity="error" onClose={() => { this.setState({showErr: null}); }}>{this.state.showErr}</Alert>
        )
      } {
        bioAvailable && (
          <div style={{textAlign: "center", marginTop: "50px"}}>
          <Button 
            className="btn-circle"
            onClick={this.bioUnlock}
            disabled={!this.state.bioEnabled}
          >
            { !bioStatus && ( <FingerprintIcon/> )}
            { bioStatus === 1 && ( <CheckCircleRoundedIcon /> )}
            { bioStatus === -1 && ( <ErrorRoundedIcon /> )}
          </Button>
        </div>
        )
      } 
      </Grid>
    )
  }
}

export default BiometricsUnlock;