import React, {
    useState,
    createContext,
    useCallback
} from "react";
import PropTypes from "prop-types";

export const UserAssetsContext = createContext({
    assets: [],
    balances: [],
    walletPasswordHash: "",
    updateAssets: val => val,
    updateBalances: val => val,
    updatePasswordHash: val => val,
});

const UserAssetsContextProvider = (props) => {
    const { children } = props;
    const [assets, setAssets] = useState(null);
    const [balances, setBalances] = useState(null);
    const [walletPasswordHash, setWalletPasswordHash] = useState("");

    const updateAssets = useCallback((assets) => {
        setAssets(assets);
    }, []);

    const updateBalances = useCallback((balances) => {
        setBalances(balances);
    }, []);

    const updatePasswordHash = useCallback((passwordHash) => {
        setWalletPasswordHash(passwordHash);
    }, []);


    const nextState = {
        assets,
        updateAssets,
        balances,
        updateBalances,
        walletPasswordHash,
        updatePasswordHash,
    };

    return (
        <UserAssetsContext.Provider value={nextState}>
            {children}
        </UserAssetsContext.Provider>
    )
};

UserAssetsContextProvider.propTypes = {
    children: PropTypes.any
};

export default UserAssetsContextProvider;