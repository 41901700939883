import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, CircularProgress,
    Grid,
    Paper,
    Table,
    TableBody, TableCell,
    TableContainer, TableRow,
    Typography
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import sha512 from "js-sha512";
import CheckPassword from "src/views/CheckPassword";
import {openLink} from "src/utils/linksOpenPolyfill";
import BlockExplorerUtils from "src/utils/blockchain/BlockExplorerUtils";
import BiometricsUnlock from "src/components/BiometricsUnlock";
import BlockchainLabel from "src/common/blockchain-label";
import TransactionSuccess from "src/views/modals/TransactionSuccess";
import TransactionFail from "src/views/modals/TransactionFail";
import ProviderUtils from "src/utils/blockchain/ProviderUtils";
import { loginWithWallet } from "./utils";

const TransactionProcess = (props) => {
    const {
        error = "",
        receipt = null,
        transactionHash = "",
        onLogin = () => {},
        onBackClick = () => {},
        onFinish = () => {},
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [transactionError, setTransactionError] = useState("");
    const [passWordError, setPasswordError] = useState(null);

    const handleSubmit = (passwordHash) => {
        loginWithWallet({
            passwordHash,
            onStart: () => setIsLoading(true),
            onSuccess: onLogin,
            onError: setPasswordError,
        })
    };

    const handlePasswordSubmit = ({ password }) => {
        const passwordHash = sha512(password);
        handleSubmit(passwordHash);
    };

    useEffect(() => {
        if (!receipt) return;
        const isSuccess = receipt?.confirmations > 0 && receipt?.status > 0;
        setIsLoading(false);
        if (!isSuccess) {
            setTransactionError("Transaction Failed To Execute On Blockchain")
        }
    }, [receipt])

    useEffect(() => {
        setTransactionError(error);
    }, [error]);

    const transactionURl = BlockExplorerUtils.scanUrl(
        transactionHash,
        ProviderUtils.activeBlockchain()
    );

    const handleOpenTransactionLink = (event) => {
        openLink(event, transactionURl)
    };

    return (
        <Grid item xs={12} className={"centered-grid"}>
            <TransactionSuccess
                isVisible={!!transactionHash && !!receipt && !transactionError}
                transactionHash={transactionHash}
                onCloseClick={onFinish}
            />
            <TransactionFail
                isVisible={!!transactionError}
                errorText={transactionError}
                transactionHash={transactionHash}
                onCloseClick={onFinish}
            />
            <BlockchainLabel />
            { isLoading ? (
                <Accordion TransitionProps={{ unmountOnExit: true }} defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className="color-nx">
                            Transaction Summary
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{padding: '0'}}>
                        <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableBody>
                                <TableRow key="ta">
                                    <TableCell component="th" scope="row">
                                        Trx Hash
                                    </TableCell>
                                <TableCell align="right">
                                    <Typography
                                        fontSize="2"
                                        className="color-nx"
                                        style={{wordBreak: "break-all"}}
                                    >
                                        <a
                                            href={"/#"}
                                            className="color-nx"
                                            target="_blank" rel="noopener noreferrer"
                                            onClick={handleOpenTransactionLink}
                                        >
                                            {transactionHash}
                                        </a>
                                    </Typography>
                                </TableCell>
                                </TableRow>
                                { transactionError && (
                                    <TableRow key="ts">
                                        <TableCell
                                        component="td"
                                        scope="row"
                                        colSpan={2}
                                        >
                                            <Alert
                                                variant="outlined"
                                                severity="error"
                                            >
                                                {transactionError}
                                            </Alert>
                                        </TableCell>
                                    </TableRow>
                                )}
                                { !!receipt && (
                                    <TableRow key="ts">
                                        <TableCell
                                            component="td"
                                            scope="row"
                                            colSpan={2}
                                        >
                                            <Alert
                                                variant="outlined"
                                                severity="success"
                                            >
                                                Success
                                            </Alert>
                                        </TableCell>
                                    </TableRow>
                                )}
                                { isLoading && (
                                    <TableRow key="ts">
                                        <TableCell
                                            component="td"
                                            scope="row"
                                            colSpan={2}
                                            style={{ textAlign: "center" }}
                                        >
                                            <CircularProgress color="secondary" />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
            ) : (
            <>
                <CheckPassword
                    onSubmit={handlePasswordSubmit}
                    goBack={onBackClick}
                    errors={passWordError}
                />
                <BiometricsUnlock
                    onSuccess={handleSubmit}
                />
            </>
            )}
        </Grid>
    )
};

TransactionProcess.propTypes = {
    error: PropTypes.string,
    transactionHash: PropTypes.string,
    onLogin: PropTypes.func,
    onBackClick: PropTypes.func,
    goHome: PropTypes.func,
};

export default TransactionProcess;