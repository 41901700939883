import React from "react";
import {withStyles} from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    cssLabel: {
        '&$cssFocused': {
            color: "primary",
        },
    },
    cssAsterisk: {
        color: "#0082BC",
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: "#0082BC",
        },
        '&:hover': {
            borderColor: "#0082BC",
        },
    },
    cssFocused:{
        borderColor: "#0082BC",
        '&:hover': {
            borderColor: "#0082BC",
        },
    },
    notchedOutline: {
        borderColor: "#0082BC",
        '&:hover': {
            borderColor: "#0082BC",
        },
    },
});

const CustomInput = ({ input, label, required, type, meta: { touched, error, warning }, classes, autoComplete, className, disabled, InputProps }) => {
    return (
        <TextField
            fullWidth
            label={label}
            type={type}
            error={touched && !!error}
            helperText={touched && error}
            required={required}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
                classes: {
                    root: classes.cssLabel,
                    asterisk: classes.cssAsterisk,
                    focused: classes.cssFocused,
                },
            }}
            InputProps={{
                classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                },
                disabled: (disabled === true),
                startAdornment: (InputProps && InputProps.startAdornment? InputProps.startAdornment: ''),
                endAdornment: (InputProps && InputProps.endAdornment? InputProps.endAdornment: ''),
            }}
            autoComplete={autoComplete}
            className={className}
            {...input}
        />
    );
};

export default withStyles(styles)(CustomInput);