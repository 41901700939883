import React from "react"
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "@material-ui/core/Link";
import {openLink} from '../../utils/linksOpenPolyfill'


const PrivacyPolicy = (props) => {
    return (
        <Container component="main" maxWidth="sm">
            <Typography variant="h5" align="center" paragraph>
                Private Policy
            </Typography>
            <Typography paragraph align="justify">
                Please find herein below the Privacy Policy of iOWN (hereinafter called “iOWN”, “we”, or “us”), a
                company incorporated under the laws of Cayman Islands with registration number ST-349468 and the address
                at c/o Stuarts Corporate Services Ltd. PO Box, 2510, Kensington House, 69 Dr. Roy’s Drive, Cayman
                Islands, Grand Cayman KY1-1104, Cayman Islands.
            </Typography>
            <Typography paragraph align="justify">
                We understand that your privacy is important and we are strongly committed to protecting it and the
                Personal Data or information that you may provide when you access and use content we own or operate on
                the websites located at [www.iowntoken.com] , or any other websites, app, pages, features, or content we
                own or operate (hereafter: the “Site(s)”) and/or when you use our mobile application and related
                services (referred to collectively hereinafter as “Services”).
            </Typography>
            <Typography paragraph align="justify">
                In addition, information that you may submit to iOWN in response to an email request for information or
                other outreach from iOWN, or through any iOWN application processes, will also be treated in accordance
                with this Privacy Policy.
            </Typography>
            <Typography paragraph align="justify">
                In this written Privacy Policy, iOWN describes the use of your Personal Data by iOWN and the scope and
                breadth of our commitment to privacy. We undertake to ensure that your Personal Data is handled
                responsibly and in compliance with the legal requirement of Cayman Islands’ Data Protection Law, 2017.
                Furthermore, we ensure compliance with the EU General Data Protection Regulation 2016/679.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                1. Acceptance of iOWN Privacy Policy
            </Typography>
            <Typography paragraph align="justify">
                By exploring and using iOWN’s Site and/or Services, and by submitting information to iOWN, you signify
                acceptance to the terms of our Privacy Policy.
            </Typography>
            <Typography paragraph align="justify">
                Where we require your consent to process your Personal Data, we will ask for your consent to the
                collection, use, and disclosure of your Personal Data as described further below.
            </Typography>
            <Typography paragraph align="justify">
                iOWN may provide additional disclosures or additional information about the data collection, use and
                sharing practices of specific Services, as and when necessary.
            </Typography>
            <Typography paragraph align="justify">
                If you do not agree with any aspect of this Privacy Policy, you should immediately discontinue access or
                use of our Services.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                2. Personal Data
            </Typography>
            <Typography paragraph align="justify">
                Personal Data is data that can be used to identify you directly or indirectly, or to contact you. Our
                Privacy Policy covers all Personal Data that you voluntarily submit to us and that we obtain from our
                partners. This Privacy Policy does not apply to anonymized data, as it cannot be used to identify you.
            </Typography>
            <Typography paragraph align="justify">
                You may be asked to provide Personal Data anytime you are in contact with us in order for us to provide
                the Services.
            </Typography>
            <Typography paragraph align="justify">
                iOWN may also combine your Personal Data with other information to provide and improve our products,
                services, and content (please see the section below).
            </Typography>
            <Typography paragraph align="justify">
                Except as described in this Privacy Policy, iOWN will not give, sell, rent or loan any Personal Data to
                any third party.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                2.1. Personal Data We Collect
            </Typography>
            <Typography paragraph align="justify">
                We collect personal data you provide directly to us or which you generate when you open an account with
                us, use our Services or our website(s) and platform(s).
            </Typography>
            <Typography paragraph align="justify">
                When we require certain personal data from users it is because we are required by law to collect this
                data (anti-fraud / anti-money laundering / counter financing of terrorism / know your customer
                obligations) or it is relevant for specified purposes. Any information you provide to us that is not
                required is voluntary.
            </Typography>
            <Typography paragraph align="justify">
                You are free to choose whether to provide us with the types of Personal Data requested, but we may not
                be able to serve you as effectively or offer you all of our Services when you do choose not to share
                certain information with us. However, we shall strive to provide you with the best possible Services
                always.
            </Typography>
            <Typography paragraph align="justify">
                We also collect Personal Data when you use or request information about our Services, subscribe to
                marketing communications, request support, complete surveys, or sign up for one of our events.
            </Typography>
            <Typography paragraph align="justify">
                We may also collect Personal Data from you offline, such as when you attend one of our events, or when
                you contact customer support.
            </Typography>
            <Typography paragraph align="justify">
                We may use this information in combination with other information we collect about you as set forth in
                this Privacy Policy.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                2.2 Data Collection When Registering / Using Our Services
            </Typography>
            <Typography paragraph align="justify">
                When registering on our Site(s), you may be asked to enter:
            </Typography>
            <List>
                <ListItemText inset><b>Personal Identification Information:</b>&nbsp;Full name, date of birth, age,
                    nationality, gender, signature, utility bills, photographs, phone number, home address, and/or
                    email.
                </ListItemText>
                <ListItemText inset><b>Account Identification Information:</b>&nbsp;Tax ID number, passport number,
                    driver’s license details, national identity card details, photograph identification cards, and/or
                    resident permit (visa) information.
                </ListItemText>
                <ListItemText inset><b>Financial Information:</b>&nbsp;Bank account information, payment card primary
                    account number (PAN), transaction history, trading data, and/or tax identification.
                </ListItemText>
                <ListItemText inset><b>Transaction Information:</b>&nbsp;Information about the transactions you make
                    on our Services, such as the name of the recipient, your name, the amount, and/or timestamp.We may
                    also collect Personal Data from third party partners and public sources as required or permitted by
                    applicable law, such as public databases, credit bureaus, ID verification partners, resellers and
                    channel partners, joint marketing partners, and social media platforms which include:
                </ListItemText>
                <ListItemText inset><b>Financial information</b></ListItemText>
                <ListItemText inset><b>Reputational information</b></ListItemText>
                <ListItemText inset><b>Corporate/business activities for corporate customers</b></ListItemText>
            </List>
            <Typography paragraph align="justify">
                [We use public databases and ID verification partners to verify your identity. Indeed, ID verification
                partners use a combination of government records and publicly available information about you to verify
                your identity. Such information includes your name, address, job position, public employment profile,
                credit history, status on any sanctions lists maintained by public authorities, and other relevant data.
                We obtain such information to comply with our legal obligations, such as anti-money laundering laws.]
            </Typography>
            <Typography paragraph align="justify">
                In some cases, we may process additional data about you to ensure our Services are not used fraudulently
                or for other illicit activities. In such instances, processing is necessary for us to continue to
                perform our contract with you and others.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                2.3 Data Collection When Visiting Our Website
            </Typography>
            <Typography align="justify">
                When visiting our website, we store every instance of access in a log file and therefore, the following
                Data are stored in this process:
            </Typography>
            <List>
                <ListItemText inset>- Computer or mobile device information;</ListItemText>
                <ListItemText inset>- Website from which our domain is accessed and website usage
                    information;</ListItemText>
                <ListItemText inset>- Operating system of your computer;</ListItemText>
                <ListItemText inset>- Country from which our website is accessed;</ListItemText>
                <ListItemText inset>- Name of your Internet provider;</ListItemText>
                <ListItemText inset>- Name and URL of the Data accessed;</ListItemText>
                <ListItemText inset>- Date and time of access;</ListItemText>
                <ListItemText inset>- IP address of the accessing computer.</ListItemText>
            </List>
            <Typography paragraph align="justify">
                These Data are generally anonymized with no personal reference and only used to establish connection
                with the website, to ensure ongoing system security and stability and for statistical purposes. These
                Data are not associated or stored with Personal Data.
            </Typography>
            <Typography paragraph align="justify">
                IP address of the accessing computers will be analyzed only in the event of an attack on the network
                infrastructure or in case of suspicion of otherwise unauthorized or improper use of the website and for
                the sole purposes of defense or use as part of criminal proceedings for identification purposes and for
                criminal and/or civil proceedings against the user involved. IP address is also used to filter out
                geographical zones from accessing our Services.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                3. HOW YOUR PERSONAL DATA IS USED
            </Typography>
            <Typography paragraph align="justify">
                Our primary purpose in collecting personal information is to provide you with a secure, smooth,
                efficient, and customized experience. In general, we use personal information to create, develop,
                operate, deliver, and improve our Services, content and advertising, and for loss prevention and
                anti-fraud purposes. We may use this information in the following ways:
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                3.1. To Maintain Legal and Regulatory Compliance
            </Typography>
            <Typography paragraph align="justify">
                Some of our Services are subject to laws and regulations requiring us to collect and use your personal
                identification information, formal identification information, financial information, transaction
                information, employment information, online identifiers, and/or usage data in certain ways.
            </Typography>
            <Typography paragraph align="justify">
                We must identify and verify customers using our Services in order to comply with anti-money laundering
                and terrorist financing laws across jurisdictions or as may be more specifically required by Cayman
                Islands’ Anti-Money Laundering Regulations. In addition, we may use third parties to verify your
                identity by comparing the personal information you provided against third-party databases and public
                records.
            </Typography>
            <Typography paragraph align="justify">
                We may require you to provide additional information which we may use in collaboration with service
                providers acting on our behalf to verify your identity or address, and/or to manage risk as required
                under applicable law. If you do not want to have your personal information processed for such purposes,
                then we shall terminate your account as we cannot perform the Services in accordance with legal and
                regulatory requirements.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                3.2. To Enforce Our Terms in Our User Agreement and Other Agreements
            </Typography>
            <Typography paragraph align="justify">
                We handle sensitive information, such as your identification and financial data, so it is very important
                for us and our customers that we are actively monitoring, investigating, preventing and mitigating any
                potentially prohibited or illegal activities, enforcing our agreements with third parties, and/or
                violations of our posted user agreement or agreement for other Services. In addition, we may need to
                collect fees based on your use of our Services. We collect information about your account usage and
                closely monitor your interactions with our Services. We may use any of your personal information
                collected on our Services for these purposes. The consequences of not processing your personal
                information for such purposes is the termination of your account as we cannot perform our Services in
                accordance with our terms.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                3.3. To Provide iOWN Services
            </Typography>
            <Typography paragraph align="justify">
                We process your personal information in order to provide the Services to you.
            </Typography>
            <Typography paragraph align="justify">
                We cannot provide you with Services without such information.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                3.4. To Provide Service Communications
            </Typography>
            <Typography paragraph align="justify">
                We send administrative or account-related information to you to keep you updated about our Services,
                inform you of relevant security issues or updates, or provide other transaction-related information.
                Without such communications, you may not be aware of important developments relating to your account
                that may affect how you can use our Services. [You may inform us about the same in the event you do not
                wish to avail such services.]
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                3.5. To Provide Customer Service
            </Typography>
            <Typography paragraph align="justify">
                We process your personal information when you contact us to resolve any question, dispute, collected
                fees, or to troubleshoot problems. We may process your information in response to another customer’s
                request, as relevant. Without processing your personal information for such purposes, we cannot respond
                to your requests and ensure your uninterrupted use of the Services. [You may inform us about the same in
                the event you do not wish to avail such services.]
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                3.6. To Ensure Quality Control
            </Typography>
            <Typography paragraph align="justify">
                We process your personal information for quality control and staff training to make sure we continue to
                provide you with accurate information. If we do not process personal information for quality control
                purposes, you may experience issues on the Services such as inaccurate transaction records or other
                interruptions. Our basis for such processing is based on the necessity of performing our contractual
                obligations with you. [You may inform us about the same in the event you do not wish to avail such
                services.]
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                3.7. To Ensure Network and Information Security
            </Typography>
            <Typography paragraph align="justify">
                We process your personal information in order to enhance security, monitor and verify identity or
                service access, combat spam or other malware or security risks and to comply with applicable security
                laws and regulations. The threat landscape on the internet is constantly evolving, which makes it more
                important than ever that we have accurate and up-to-date information about your use of our Services.
                Without processing your personal information, we may not be able to ensure the security of our Services.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                3.8. For Research and Development Purposes
            </Typography>
            <Typography paragraph align="justify">
                We process your personal information to better understand the way you use and interact with our
                Services. In addition, we use such information to customize, measure, and improve the Services and the
                content and layout of our website and applications, and to develop new services. Without such
                processing, we cannot ensure your continued enjoyment of our Services. Our basis for such processing is
                based on legitimate interest. [You may inform us about the same in the event you do not wish to avail
                such services.]
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                3.9. To Enhance Your Website Experience
            </Typography>
            <Typography paragraph align="justify">
                We process your personal information to provide a personalized experience and implement the preferences
                you request. For example, you may choose to provide us with access to certain personal information
                stored by third parties. Without such processing, we may not be able to ensure your continued enjoyment
                of part or all of our Services. [You may inform us about the same in the event you do not wish to avail
                such services.]
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                3.10. To Facilitate Corporate Acquisitions, Mergers, or Transactions
            </Typography>
            <Typography paragraph align="justify">
                We may process any information regarding your account and use of our Services as is necessary in the
                context of corporate acquisitions, mergers, or other corporate transactions. You have the option of
                closing your account if you do not wish to have your personal information processed for such purposes.
            </Typography>
            <Typography paragraph align="justify">
                EEA residents: pursuant to EEA Data Protection Law, we process this personal information to satisfy our
                legitimate interests as described above.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                3.11. To Engage in Marketing Activities
            </Typography>
            <Typography paragraph align="justify">
                Based on your communication preferences, we may send you marketing communications to inform you about
                our events or our partner events; to deliver targeted marketing; and to provide you with promotional
                offers based on your communication preferences. We use information about your usage of our Services and
                your contact information to provide marketing communications. You can opt-out of our marketing
                communications at any time.
            </Typography>
            <Typography paragraph align="justify">
                If you are a current customer residing in the EEA, we will only contact you by electronic means (email
                or SMS) with information about our Services that are similar to those which were the subject of a
                previous sale or negotiations of a sale to you.
            </Typography>
            <Typography paragraph align="justify">
                If you are a new customer and located in the EEA, we will contact you if you are located in the EU by
                electronic means for marketing purposes only if you have consented to such communication. If you do not
                want us to use your personal information in this way, or to pass your personal information on to third
                parties for marketing purposes, please follow the opt-out links included in marketing communications or
                contact us at [<Link href="mailto:info@iowntoken.com"><i>info@iowntoken.com</i></Link>]. You may raise
                such objection with regard to initial or further processing for purposes of direct marketing, at any
                time and free of charge. Direct marketing includes any communications to you that are only based on
                advertising or promoting products and services.
            </Typography>
            <Typography paragraph align="justify">
                We will not use your personal information for purposes other than those purposes we have disclosed to
                you, without your permission. From time to time we may request your permission to allow us to share your
                personal information with <u><b>third parties.</b></u>. You may <u><b>opt out</b></u> of having your
                personal information shared with third parties or allowing us to use your personal information for any
                purpose that is incompatible with the purposes for which we originally collected it or subsequently
                obtained your authorization. If you choose to so limit the use of your personal information, certain
                features or our Services may not be available to you. However, we shall strive to provide you with the
                best possible Services always.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                4. Cookies – Collection & Use of Data Collected Automatically
            </Typography>
            <Typography align="justify" paragraph>
                Cookies are small files that a site or its service provider transfers to your
                computer’s hard drive through your web browser (if you have permitted) that enables the site’s or
                service provider’s systems to recognize your browser and capture and remember certain information.
                They are widely used in order to make websites work, or work more efficiently, as well as to provide
                information to the owners of the site.
            </Typography>
            <Typography align="justify" paragraph>
                Cookies are stored on the hard drive of your computer and do not necessarily transmit your Personal Data
                to&nbsp;<i>iOWN</i>.
            </Typography>
            <Typography align="justify" paragraph>
                Cookies are used to help&nbsp;<i>iOWN</i>&nbsp;understand your preferences based on previous or current
                site activity, which enables&nbsp;<i>iOWN</i>&nbsp;to provide you with improved services.
            </Typography>
            <Typography align="justify" paragraph>
                Cookies are also used for security purposes and to compile aggregate Data about site traffic and site
                interaction so that better site experiences and tools can be offered in the future.
            </Typography>
            <Typography align="justify">
                <i>iOWN</i> may also use trusted third-party service providers that track this information
                on <i>iOWN</i>’s behalf. Our service provider(s) will place cookies on the hard drive of your computer
                and will receive data that we select to educate us notably on:
            </Typography>
            <List>
                <ListItemText inset>
                    a. How visitors navigate around our Site
                </ListItemText>
                <ListItemText inset>
                    b. What products are browsed
                </ListItemText>
                <ListItemText inset>
                    c. General transaction information
                </ListItemText>
            </List>
            <Typography align="justify" paragraph>
                Our service provider(s) analyses this data and provide(s) us with aggregate reports.
                The data and analysis provided by our service provider(s) will be used to assist us in better
                understanding our visitors’ interests in our Site and how to better serve those interests. The data
                collected by our service provider(s) may be linked to and combined with data that we collect about
                you while you are using the Platform. Our service provider(s) is/are contractually restricted from
                using information they receive from our Site other than to assist us.
            </Typography>
            <Typography align="justify" paragraph>
                You can choose to have your computer warn you (through your browser settings) each time a cookie is
                being sent, or you can choose to turn off all cookies. If you choose to turn cookies off, some of the
                features that make your site experience more efficient may not function properly.
            </Typography>
            <Typography align="justify" paragraph>
                Specifically for analytics providers, we use Google Analytics, which is a web analytics service offered
                by Google that tracks and reports website traffic. Google uses the data collected to track and monitor
                the use of our website. This data is shared with other Google services. Google may use the collected
                data to contextualize and personalize the ads of its own advertising network.
            </Typography>
            <Typography paragraph align="justify">
                You can opt-out of having your activity on the website made available to Google Analytics by installing
                the Google Analytics opt-out browser add-on (<Link href="https://tools.google.com/dlpage/gaoptout"
                                                                   target="_blank"
                                                                   rel="noopener noreferrer"
                                                                   onClick={(e)=> openLink(e,"https://tools.google.com/dlpage/gaoptout")}>https://tools.google.com/dlpage/gaoptout</Link>)
                for your web browser. The Google Analytics opt-out browser add-on provides visitors with the ability to
                prevent their data from being collected and used by Google Analytics. The add-on prevents the Google
                Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics
                about visits activity. For more information on the privacy practices of Google, please visit the Google
                Privacy &amp; Terms web page:&nbsp;<Link href="http://www.google.com/intl/en/policies/privacy/"
                                                         target="_blank"
                                                         rel="noopener noreferrer"
                                                         onClick={(e)=> openLink(e,"http://www.google.com/intl/en/policies/privacy/")}>http://www.google.com/intl/en/policies/privacy/</Link>
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                5. Disclosing and Transferring Personal Data
            </Typography>
            <Typography paragraph align="justify">
                We may disclose your Personal Data to third parties and legal and regulatory authorities, and transfer
                your Personal Data, as described below.
            </Typography>
            <Typography paragraph align="justify">
                There are certain circumstances where we may transfer your personal data to employees, contractors and
                to other parties.
            </Typography>
            <Typography paragraph align="justify">
                We may share information about you with other members of our group of companies, so we can provide
                the best service across our group. They are bound to keep your information in accordance with this
                Privacy Policy;
            </Typography>
            <Typography paragraph align="justify">
                We may also share your information with certain contractors or service providers. They may process
                your personal data for us, for example, if we use a marketing agency. Other recipients/service
                providers include advertising agencies, IT specialists, database providers, backup and disaster
                recovery specialists, email providers or outsourced call centers. Our suppliers and service
                providers will be required to meet our standards on processing information and security. The
                information we provide them, including your information, will only be provided in connection with
                the performance of their function;
            </Typography>
            <Typography paragraph align="justify">
                We may also share your information with certain other third parties. We will do this either when we
                receive your consent or because we need them to see your information to provide products or services
                to you. These include credit reference agencies, anti-fraud databases, screening agencies and other
                partners we do business with.
            </Typography>
            <Typography align="justify">
                Your personal data may be transferred to other third-party organizations in certain scenarios:
            </Typography>
            <List>
                <ListItemText inset>- If we’re discussing selling or transferring part or all of our business – the
                    information may be transferred to prospective purchasers under suitable terms as to
                    confidentiality
                </ListItemText>
                <ListItemText inset>- If we are reorganized or sold, information may be transferred to a buyer who
                    can continue to provide services to you
                </ListItemText>
                <ListItemText inset>- If we’re required to by law, or under any regulatory code or practice we
                    follow, or if we are asked by any public or regulatory authority – for example the Police
                </ListItemText>
                <ListItemText inset>- If we are defending a legal claim your information may be transferred as
                    required in connection with defending such claim
                </ListItemText>
            </List>
            <Typography paragraph align="justify">
                Your personal data may be shared if it is made anonymous and aggregated, as in such circumstances the
                information will cease to be personal data.
            </Typography>
            <Typography paragraph align="justify">
                Your information will not be sold, exchanged, or shared with any third parties without your consent,
                except to provide&nbsp;iOWN&nbsp;Services or as required by law.
            </Typography>
            <Typography paragraph align="justify">
                If a service provider is located in a country that does not apply the standard of data protection of
                Cayman law and EU General Data Protection Regulation, iOWN will use a contract to ensure that your
                Personal Data has the same level of protection as if protected in accordance with Cayman Data
                Protection Law and EU General Data Protection Regulation.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                5.1. How Personal Information is Shared With Third Party Sites and Services
            </Typography>
            <Typography paragraph align="justify">
                Please note that merchants you interact with may have their own privacy policies. Hence iOWN is not
                responsible for their operations, including, but not limited to, their information practices.
            </Typography>
            <Typography paragraph align="justify">
                Information collected by third parties, which may include such things as contact details or location
                data, is governed by their privacy practices. We encourage you to learn about the privacy policies of
                those third parties.
            </Typography>
            <Typography paragraph align="justify">
                If you authorize one or more third-party applications to access your iOWN Account, then information you
                have provided to iOWN may be shared with those third parties. Unless you provide further authorization,
                these third parties are not allowed to use this information for any purpose other than to facilitate
                your transactions using iOWN Services.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                6. Transfer of Data
            </Typography>
            <Typography paragraph align="justify">
                We store and process your Personal Data in data centers around the world, wherever we have our premises
                or service providers are located.
            </Typography>
            <Typography paragraph align="justify">
                As such, we may transfer your Personal Data outside of Cayman Islands or the European Union. Some of the
                countries to which your personal data may be transferred do not benefit from an appropriate protection
                regulation.
            </Typography>
            <Typography paragraph align="justify">
                These specific countries can be found here: <Link
                href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e)=> openLink(e, "https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en")}>https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en</Link>.
            </Typography>
            <Typography paragraph align="justify">
                For such international countries, we shall have specific data-protection clauses in our agreements and
                arrangements with them.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                7. Security of Data
            </Typography>
            <Typography paragraph align="justify">
                We store all your Personal Data on a server operated by {'{Amazon Web Services}'}
            </Typography>
            <Typography paragraph align="justify">
                We take all reasonable effort on technical and organizational security measures to protect your Data
                from being manipulated, lost or accessed by unauthorized third-parties.
            </Typography>
            <Typography paragraph align="justify">
                Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make
                your visit to our Site as safe as possible.
            </Typography>
            <Typography paragraph align="justify">
                Your Personal Data is contained behind secured networks and is only accessible by a limited number of
                individuals who have special access rights to such systems and are required to keep the information
                confidential.
            </Typography>
            <Typography paragraph align="justify">
                Although no method of transmission over the Internet, or method of electronic storage is one hundred
                percent secure, we strive to continually update and improve our security measures with the most recent
                technological developments.
            </Typography>
            <Typography paragraph align="justify">
                We would like to draw your attention on the fact that we normally never ask for financial or payment
                information, such as your credit card number, passcode, account number or pin number, in an e-mail, text
                or any other communication that we send to you. Please always check that any website on which you are
                asked for financial or payment information in relation to our reservations or services is operated
                by <i>iOWN</i>. The risk of impersonating hackers exists and should be taken into account when using our
                website and/or Services.
            </Typography>
            <Typography paragraph align="justify">
                If you do receive a suspicious request, do not provide your information and report it by contacting one
                of our member service representatives as set in this Privacy Policy.
            </Typography>
            <Typography paragraph align="justify">
                Since we cannot 100% guarantee that loss, misuse, unauthorized acquisition, or alteration of your data
                will not occur, please accept that you play a vital role in protecting your own Personal Data. When
                registering with us, it is important to choose an appropriate password of sufficient length and
                complexity, to not reveal this password to any third-parties, and to immediately notify us if you become
                aware of any unauthorized access to or use of your account.
            </Typography>
            <Typography paragraph align="justify">
                Furthermore, we cannot ensure or warrant the security or confidentiality of information you transmit to
                us or receive from us by Internet or wireless connection, including email, phone, or SMS, since we have
                no way of protecting that information once it leaves and until it reaches us. If you have reason to
                believe that your data is no longer secure, please contact us at the email address, mailing address or
                telephone number listed at the end of this Privacy Policy.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                8. Retention of Data
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                8.1. Personal Data
            </Typography>
            <Typography paragraph align="justify">
                <i>iOWN</i> will retain your Personal Data only for as long as is necessary for the purposes set out in
                this Privacy Policy.
            </Typography>
            <Typography paragraph align="justify">
                <i>iOWN</i> will retain and use your Personal Data to the extent necessary to comply with our legal
                obligations (for example, if we are required to retain your Data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
            </Typography>
            <Typography paragraph align="justify">
                Therefore, and in accordance with our record keeping obligations, we will retain Account and other
                Personal Data for at least five years (and some up to ten years, as required by applicable law) after an
                Account is closed.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                8.2. Data Collected Automatically
            </Typography>
            <Typography paragraph align="justify">
                <i>iOWN</i> will also retain Data Collected Automatically either generated by the use of the website or
                from the website infrastructure itself (for example, the duration of a page visit)) for internal
                analysis purposes.
            </Typography>
            <Typography paragraph align="justify">
                This Data is generally retained for a shorter period of time, except when this Data is used to
                strengthen the security or to improve the functionality of our Service, or we are legally obligated to
                retain this Data for longer time periods.
            </Typography>
            <Typography paragraph align="justify">
                Hence, this kind of Data collected via technical means such as cookies, webpage counters and other
                analytics tools is normally kept for a period of up to one year from expiry of the cookie.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                9. Disclosures Due to Legal Compliance
            </Typography>
            <Typography paragraph align="justify">
                <i>iOWN</i> complies with all applicable privacy laws and regulations.
            </Typography>
            <Typography paragraph align="justify">
                <i>iOWN</i> may be compelled to surrender personal user or customer information to legal authorities
                without express user consent if presented with a court order or similar legal or administrative order,
                or as required or permitted by the laws, rules, and regulations of any nation, state, or other
                applicable jurisdiction.
            </Typography>
            <Typography paragraph align="justify">
                Also, in the event of a violation of the Terms & Conditions of use of the Site or a violation of any
                restrictions on use of materials provided in or through the Site, we may disclose personal user
                information to our affected business partners or legal authorities.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                10. Your Rights
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                10.1. General
            </Typography>
            <Typography paragraph align="justify">
                iOWN is the Data Controller with respect to your Personal Data. We determine the means and purposes of
                processing you Data.
            </Typography>
            <Typography paragraph align="justify">
                We may process your Personal Data if you consent to the processing, to satisfy our legal obligations, if
                it is necessary to carry out our obligations arising from any contracts we entered with you, or to take
                steps at your request prior to entering into a contract with you, or for our legitimate interests to
                protect our property, rights or safety of iOWN, our customers or others.
            </Typography>
            <Typography paragraph align="justify">
                Whenever made possible, you can update your Personal Data directly within your account settings section.
                If you are unable to change your Personal Data, please contact us to make the required changes.
            </Typography>
            <Typography paragraph align="justify">
                We reserve our right to verify your identity before responding to such requests.
            </Typography>
            <Typography paragraph align="justify">
                Please note that according to Swiss law, specific Data must be stored for a determined period of time.
                Such Data must be therefore remained with iOWN until the legal period has expired. These specific Data
                are blocked in our system and used only in order to meet legal requirements.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                10.2. Individual Rights in Relation with Your Data
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                10.2.1. Right to Access Your Personal Data
            </Typography>
            <Typography paragraph align="justify">
                You are entitled to ask iOWN if we are processing your Data. In case the answer is positive, you can
                request that we provide you a copy of your Personal Data we hold. We may require you to specify the
                information or processing activities to which your request relates.
            </Typography>
            <Typography paragraph align="justify">
                This information will be provided without undue delay.
            </Typography>
            <Typography paragraph align="justify">
                Your right to access shall only be limited where the burden or expense of providing access would be
                disproportionate to the risks to your privacy in the case in question, or where the rights of persons
                other than you would be violated.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                10.2.2. Right to Correct Your Personal Data
            </Typography>
            <Typography paragraph align="justify">
                You are entitled to request that any incomplete or inaccurate Personal Data we hold about you is
                corrected or updated.
            </Typography>
            <Typography paragraph align="justify">
                Your right to access and correction shall only be limited where the burden or expense of providing
                access would be disproportionate to the risks to your privacy in the case in question, or where the
                rights of persons other than you would be violated.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                10.2.3. Right to Withdraw Your Consent
            </Typography>
            <Typography paragraph align="justify">
                You have the right to withdraw your consent to the processing of your Personal Data collected on the
                basis of your consent at any time. Your withdrawal will not affect the lawfulness of previous processing
                based on your consent before the withdrawal.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                10.2.4. Right to Erasure of Your Personal Data
            </Typography>
            <Typography paragraph align="justify">
                You are entitled to ask us to delete or remove personal data in certain circumstances. There are also
                certain exceptions where we may refuse a request for erasure, for example, where the personal data is
                required for compliance with law or in connection with claims.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                10.2.5. Right to Data Portability
            </Typography>
            <Typography paragraph align="justify">
                If we process your Personal Data based on a contract with you or based on your consent, or the
                processing is carried out by automated means, you may request to receive your Personal Data in a
                structured, commonly used and machine-readable format, and to have us transfer your Personal Data
                directly to another “controller” (natural or legal person that determines the purposes and means of the
                processing of your Personal Data), where technically feasible, unless exercise of this right adversely
                affects the rights and freedoms of others.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                10.2.6. Right to Restriction of Processing
            </Typography>
            <Typography align="justify">
                You have the right to restrict or object to us processing your Personal Data where one of the following
                applies:
            </Typography>
            <List>
                <ListItemText inset>
                    a. You contest the accuracy of your Personal Data that we processed. In such instances, we will
                    restrict processing during the period necessary for us to verify the accuracy of your Personal Data.
                </ListItemText>
                <ListItemText inset>
                    b. The processing is unlawful, and you oppose the erasure of your Personal Data and request the
                    restriction of its use instead.
                </ListItemText>
                <ListItemText inset>
                    c. We no longer need your Personal Data for the purposes of the processing, but it is required by
                    you to establish, exercise or defense of legal claims.
                </ListItemText>
            </List>

            <Typography variant="h6" align="center" paragraph>
                10.2.7. Right to Objection of Processing
            </Typography>
            <Typography paragraph align="justify">
                Where we are processing your personal data based on consent or legitimate interests (or those of a third
                party) you may object at any time to the processing of your Personal Data. However, we may be entitled
                to continue processing your Personal Information based on our legitimate interests or where this is
                relevant to the defense of legal claims.
            </Typography>
            <Typography paragraph align="justify">
                You also have the right to object where we are processing your personal data for direct marketing
                purposes.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                10.2.8. Automated Decisions
            </Typography>
            <Typography paragraph align="justify">
                You may contest any automated decision made about you where this has a legal or similar significant
                effect and ask for it to be reconsidered.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                10.3. Complaint
            </Typography>
            <Typography paragraph align="justify">
                If you believe that we have infringed your rights, we encourage you to contact us first at <Link
                href="mailto:info@iowntoken.com">info@iowntoken.com</Link> so that we can try to resolve the issue
                informally.
            </Typography>
            <Typography paragraph align="justify">
                You also have a right to lodge a complaint with a supervisory authority, in particular in the Member
                State in the European Union where you are habitually resident, where we are based, or where an alleged
                infringement of Data Protection law has taken place.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                11. Children’s Personal Data
            </Typography>
            <Typography paragraph align="justify">
                We do not knowingly request to collect Personal Data from any person under the age of 18.
            </Typography>
            <Typography paragraph align="justify">
                If a user submitting Personal Data is suspected of being younger than 18 years of age, iOWN will require
                the user to close his or her account.
            </Typography>
            <Typography paragraph align="justify">
                We will also take all necessary steps to delete his or her Data as soon as possible.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                12. Links to Other Websites
            </Typography>
            <Typography paragraph align="justify">
                iOWN’s agent program website may contain links to other sites that are not operated by iOWN. If you
                click on a third-party link, you will be directed to that third party’s site.
            </Typography>
            <Typography paragraph align="justify">
                iOWN strongly advises you to review the privacy policy of every site you visit.
            </Typography>
            <Typography paragraph align="justify">
                iOWN has no control over and assumes no responsibility for the content, privacy policies or practices of
                any third-party sites or services.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                13. Changes to this Privacy Policy
            </Typography>
            <Typography paragraph align="justify">
                iOWN may update the Privacy Policy from time to time. You shall be notified by or by any means of a
                notice on our Services prior to the change becoming effective.
            </Typography>
            <Typography paragraph align="justify">
                The changes of the Privacy Policy shall also be posted on this page.
            </Typography>
            <Typography paragraph align="justify">
                You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
                Policy are effective when they are posted on this page.
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                14. Contact Us
            </Typography>
            <Typography paragraph align="justify">
                If you have questions or concerns regarding this Privacy Policy, or if you have a complaint, you should
                first contact us at:<br/>
                <Link href="mailto:info@iowntoken.com">info@iowntoken.com</Link>
            </Typography>

            <Button color="primary" onClick={() => props.history.goBack()} className="spaced__btn" fullWidth
                    variant="contained">Back</Button>
        </Container>
    )
};

export default PrivacyPolicy;