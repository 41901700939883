import React from "react";
import TextField from '@material-ui/core/TextField';

const ExchangeCurrencyField = ({ input, value, label, required, type, meta: { touched, error }, autoComplete, className, disabled, customWidth  }) => {

    const widthStyle = {
        width: customWidth ? customWidth : '50%'
    };

    return (
        <TextField
            label={label}
            type={type}
            error={touched && !!error}
            helperText={touched && error}
            required={required}
            InputProps={{
                disabled: (disabled === true)
            }}
            autoComplete={autoComplete}
            className={"input-nx " + className}
            {...input}
            style={widthStyle}
        />
    );
};

export default ExchangeCurrencyField;