import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import moment from 'moment';

import AssetLoaderProgress from '../../components/AssetLoaderProgress';
import TransactionStatusBlock from '../../components/TransactionStatusBlock';

import { Container, Grid, Typography, CircularProgress, Button, Paper } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';

import { 
	ROUTE_NX_TRANSACTION_HISTORY,
} from "../../constants/routes";
import { 
	getIn4xCashTransfer
} from "../../ducks/in4x";

// Componenet: Should be a simple view which loads this
class In4xCashtransferTransaction extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount = async() => {
  	const locSplit = this.props.history.location.pathname.split('/');
  	if(locSplit.length >= 4) {
  		const ref = locSplit[3];
  		this.setState({ref: ref});
  		await this.props.getIn4xCashTransfer(ref);
  	}
  }

  goBack = () => {
  	this.props.history.push(ROUTE_NX_TRANSACTION_HISTORY + 'otc');
  };


  render() {
  	const { userAssetTypesLoading, assetBalancesLoading } = this.props.dashboard;
  	let { in4xCashTransaction, in4xCashTransactionLoading, in4xCashTransactionLoaded, error } = this.props.in4x;
	return (
		<Container>
			<Grid item xs={12} className="centered-grid spaced-top">
				<Typography align="center" className="color-nx" variant="h6">IN4X Cash Transaction</Typography>
				{ in4xCashTransactionLoading &&  (
					<Grid item xs={12} className="centered-grid">
						<CircularProgress />
						<Typography className="color-nx" align="center" gutterBottom>Loading Transaction...</Typography>
					</Grid>
				)}
				{ !in4xCashTransactionLoading && (userAssetTypesLoading || assetBalancesLoading) && (
					<AssetLoaderProgress />
				)}
				{ !in4xCashTransactionLoading && in4xCashTransactionLoaded && in4xCashTransaction && (
					<Accordion TransitionProps={{ unmountOnExit: true }} defaultExpanded>
					<AccordionSummary
					  expandIcon={<ExpandMoreIcon />}
					  aria-controls="panel1a-content"
					  id="panel1a-header"
					>
						<Typography className="color-nx">Transaction Summary</Typography>
					</AccordionSummary>
					<AccordionDetails style={{padding: '0'}}>
						<TableContainer component={Paper}>
						  <Table size="small" aria-label="a dense table">
							<TableBody>
									<TableRow key="td">
								  <TableCell component="td" scope="row">IN4X Reference</TableCell>
								  <TableCell align="right"><Typography fontSize="2" className="color-nx" style={{wordBreak: "break-all"}}>{in4xCashTransaction.REFERENCE_ID}</Typography></TableCell>
								</TableRow>
								<TableRow key="pl">
								  <TableCell component="td" scope="row">Location</TableCell>
								  <TableCell align="right"><Typography fontSize="2" className="color-nx" style={{wordBreak: "break-all"}}>{in4xCashTransaction.TRX_LOCATION}</Typography></TableCell>
								</TableRow>
								{ in4xCashTransaction.PAY_CUR && in4xCashTransaction.AMOUNT > 0 && (
									<TableRow key="am">
										<TableCell component="td" scope="row">Amount</TableCell>
										<TableCell align="right"><Typography fontSize="2" className="color-nx">{in4xCashTransaction.PAY_CUR.toUpperCase() + ' ' + in4xCashTransaction.AMOUNT}</Typography></TableCell>
									</TableRow>
								)}
								{ in4xCashTransaction.TOPUP_CUR && in4xCashTransaction.AMOUNT_TOKEN > 0 && (
									<TableRow key="at">
									<TableCell component="td" scope="row">Topup Amount</TableCell>
									<TableCell align="right"><Typography fontSize="2" className="color-nx">{in4xCashTransaction.TOPUP_CUR.toUpperCase() + ' ' + in4xCashTransaction.AMOUNT_TOKEN}</Typography></TableCell>
									</TableRow>
								)}
								{ in4xCashTransaction.PAY_CUR && in4xCashTransaction.NX_FEE && in4xCashTransaction.NX_FEE > 0 && (<TableRow key="pf">
								  <TableCell component="th" scope="row">Fee</TableCell>
								  <TableCell align="right"><Typography fontSize="2" className="color-nx">{in4xCashTransaction.PAY_CUR.toUpperCase() + ' ' + in4xCashTransaction.NX_FEE}</Typography></TableCell>
								</TableRow>
								)}
								<TableRow key="ps">
								  <TableCell component="td" scope="row">Status</TableCell>
								  <TableCell align="right"><Typography fontSize="2" className="color-nx">{in4xCashTransaction.STATUS.toUpperCase()}</Typography></TableCell>
								</TableRow>
								<TableRow key="ts">
								  <TableCell component="td" scope="row">Time</TableCell>
								  <TableCell align="right"><Typography fontSize="2" className="color-nx">{in4xCashTransaction.CREATED_AT ? moment.unix(in4xCashTransaction.CREATED_AT).format("hh:mm A DD-MM-YYYY") : '-'}</Typography></TableCell>
								</TableRow>
							</TableBody>
						  </Table>
						</TableContainer>
					</AccordionDetails>
					</Accordion>
				)}
				{ in4xCashTransaction.TRANSACTION_HASH && in4xCashTransaction.TRANSACTION_HASH.length > 0 && (
					<TransactionStatusBlock
						hash={in4xCashTransaction.TRANSACTION_HASH}
						blockchain={in4xCashTransaction.BLOCKCHAIN}
						network={in4xCashTransaction.BLOCKCHAIN_NETWORK}
					/>
				)}
				{ !in4xCashTransactionLoading && !in4xCashTransactionLoaded && error && (
					<Grid item xs={12} align="center" className="centered-grid">
						<Alert variant="outlined" severity="error">Invalid Cash Transfer Request/link</Alert>
						<Typography align="center" className="spaced-top">
							The link that took you here appears to be invalid! Please verify that the link is correct, and that it is still valid.
						</Typography>
					</Grid>
				)}
				<Grid item xs={12} className="centered-grid spaced-top">
					<Button onClick={this.goBack} fullWidth variant="contained" color="default" >Back</Button>
				</Grid>
			</Grid>
		</Container>
	);
  }
}

const mapState2props = state => ({
  in4x: state.in4x,
  dashboard: state.dashboard
});

const mapDispatch2props = {
	getIn4xCashTransfer
};

export default compose(
  connect(mapState2props, mapDispatch2props)
)(In4xCashtransferTransaction);