export const BLOCKCHAIN_ETH = "eth";
export const BLOCKCHAIN_BSC = "bsc";
export const BLOCKCHAIN_MATIC = 'matic';
export const BLOCKCHAIN_BTC = 'btc';

export const NETWORK_MAINNET = "mainnet";
export const NETWORK_ROPSTEN = "ropsten";
export const NETWORK_GOERLI = "goerli"
export const NETWORK_TESTNET = "testnet";
export const NETWORK_MUMBAI = "mumbai";

export const ChainId = {
    ETH_MAINNET: 1,
    ETH_ROPSTEN: 3,
    ETH_GOERLI: 5,
    BSC_MAINNET: 56,
    BSC_TESTNET: 97,
    MATIC_MAINNET: 137,
    MATIC_TESTNET: 80001
}
export const MainnetChainIds = {
	ETH_MAINNET: 1,
	BSC_MAINNET: 56,
	MATIC_MAINNET: 137,
}

export const TRX_TYPE_BALANCE_OF = 'balanceOf(address)';

export const TRX_TYPE_TRANSFER = 'transfer(address,uint256)';
export const TRX_TYPE_SAFE_TRANSFER_FROM = 'safeTransferFrom(address,address,uint256)';
export const TRX_TYPE_SWAP_TOKENS = 'swapTokensForExactTokens(uint256,uint256,address[],address,uint256)';
export const TRX_TYPE_NFT_MULTI_TRANSFER = 'transferMulti(address,uint256[])';
export const TRX_TYPE_APPROVE = 'approve(address,uint256)';
// For Staking:
export const TRX_TYPE_DEPOSIT = 'deposit(uint256)';
export const TRX_TYPE_WITHDRAW = 'withdrawAll()';
export const TRX_TYPE_STAKE = 'stake'; //approve + deposit --> speudo trx

export const getBlockchainName = blockchain => {
	switch(blockchain) {
		case BLOCKCHAIN_ETH:
			return 'Ethereum';
		case BLOCKCHAIN_BSC:
			return 'BSC Chain';
		case BLOCKCHAIN_MATIC:
			return 'Polygon';
		case BLOCKCHAIN_BTC:
			return 'Bitcoin';
		default:
			throw new Error('UNSUPPORTED_BLOCKCHAIN');
	}
}

export const isAllowedChainId = (chainId) => {
	if(process.env.REACT_APP_ALLOW_TEST_MODE !== 'true') {
		return Object.values(MainnetChainIds).indexOf(ChainId) !== -1; //Only mainnet
	}
	return Object.values(ChainId).indexOf(ChainId) !== -1;
}

export const chainIdToBlockchain = (chainId) => {
	if(chainId.toString().indexOf('0x') !== -1) {
		chainId = parseInt(chainId, 16);
	}
	switch(chainId) {
		case ChainId.ETH_MAINNET:
		case ChainId.ETH_ROPSTEN:
		case ChainId.ETH_GOERLI:
			return BLOCKCHAIN_ETH;
		case ChainId.BSC_MAINNET:
		case ChainId.BSC_TESTNET:
			return BLOCKCHAIN_BSC;
		case ChainId.MATIC_MAINNET:
		case ChainId.MATIC_TESTNET:
			return BLOCKCHAIN_MATIC;
		default: throw new Error('Invalid blockchain for chain id '+ chainId);
	}
}

export const chainIdToNetwork = (chainId) => {
	switch(chainId) {
		case ChainId.ETH_ROPSTEN:
			return NETWORK_ROPSTEN;
		case ChainId.ETH_GOERLI:
			return NETWORK_GOERLI;
		case ChainId.BSC_TESTNET:
			return NETWORK_TESTNET;
		case ChainId.MATIC_TESTNET:
			return NETWORK_MUMBAI;
		default: 
			return NETWORK_MAINNET;
	}	
}

export const getChainId = (blockchain, network) => {
	if(network === NETWORK_MAINNET) {
		switch(blockchain) {
			case BLOCKCHAIN_ETH:
				return ChainId.ETH_MAINNET;
			case BLOCKCHAIN_BSC:
				return ChainId.BSC_MAINNET;
			case BLOCKCHAIN_MATIC:
				return ChainId.MATIC_MAINNET;
			default: throw new Error('Unsupported Blockchain for chain id ' + blockchain);
		}
	} else {
		switch(blockchain) {
			case BLOCKCHAIN_ETH:
				return ChainId.ETH_GOERLI;
			case BLOCKCHAIN_BSC:
				return ChainId.BSC_TESTNET;
			case BLOCKCHAIN_MATIC:
				return ChainId.MATIC_TESTNET;
			default: throw new Error('Unsupported blockchain for chain id ' + blockchain);
		}
	}
}