export const WC_CLIENT_META = {
    description: 'iOWN Wallet',
    url: 'https://www.iowntoken.com',
    icons: ['https://www.iowntoken.com/wp-content/uploads/2021/08/cropped-fav.png'],
    name: "iOWNWallet",
};

export const EIP155_SIGNING_METHODS = {
    PERSONAL_SIGN: 'personal_sign',
    ETH_SIGN: 'eth_sign',
    ETH_SIGN_TRANSACTION: 'eth_signTransaction',
    ETH_SIGN_TYPED_DATA: 'eth_signTypedData',
    ETH_SIGN_TYPED_DATA_V3: 'eth_signTypedData_v3',
    ETH_SIGN_TYPED_DATA_V4: 'eth_signTypedData_v4',
    ETH_SEND_RAW_TRANSACTION: 'eth_sendRawTransaction',
    ETH_SEND_TRANSACTION: 'eth_sendTransaction',
    WALLET_SWITCH_CHAIN: 'wallet_switchEthereumChain',
};

