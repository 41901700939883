import React from "react";

import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';

const In4xWireTransferInfo = props => {
	const { beneficiary, intermediary } = props;
	return (
		<Grid item xs={12}>
			<Typography align="left">To deposit funds please create a wire transfer to the following beneficiary</Typography>
    			<Accordion TransitionProps={{ unmountOnExit: true }} defaultExpanded>
			        <AccordionSummary
			          expandIcon={<ExpandMoreIcon />}
			          aria-controls="panel1a-content"
			          id="panel1a-header"
			        >
			        	<Typography className="color-nx">Beneficiary Detail</Typography>
			        </AccordionSummary>
    				<AccordionDetails style={{padding: '0'}}>
		    			<TableContainer component={Paper}>
					      <Table size="small" aria-label="a dense table">
					        <TableBody>
					        	<TableRow key="acn">
					              <TableCell component="th" scope="row">Payee Name</TableCell>
					              <TableCell align="right"><Typography fontSize="2" className="color-nx">{beneficiary.name}</Typography></TableCell>
					            </TableRow>
					            <TableRow key="aca">
					              <TableCell component="th" scope="row">Payee Address</TableCell>
					              <TableCell align="right"><Typography fontSize="2" className="color-nx">{beneficiary.address}</Typography></TableCell>
					            </TableRow>
					            <TableRow key="acnu">
					              <TableCell component="th" scope="row">Payee Account</TableCell>
					              <TableCell align="right"><Typography fontSize="2" className="color-nx">{beneficiary.number}</Typography></TableCell>
					            </TableRow>
					            <TableRow key="rb">
					              <TableCell component="th" scope="row">Payee Bank Name</TableCell>
					              <TableCell align="right"><Typography fontSize="2" className="color-nx">{beneficiary.bank}</Typography></TableCell>
					            </TableRow>
					            <TableRow key="rba">
					              <TableCell component="th" scope="row">Bank Address</TableCell>
					              <TableCell align="right"><Typography fontSize="2" className="color-nx">{beneficiary.bankAddress}</Typography></TableCell>
					            </TableRow>
					            <TableRow key="aba">
					              <TableCell component="th" scope="row">Swift/ABA/Fedwire</TableCell>
					              <TableCell align="right"><Typography fontSize="2" className="color-nx">{beneficiary.fedwire}</Typography></TableCell>
					            </TableRow>
					            <TableRow key="ref">
					              <TableCell component="th" scope="row">Reference</TableCell>
					              <TableCell align="right"><Typography fontSize="2" color="secondary">{beneficiary.ref}</Typography></TableCell>
					            </TableRow>
					        </TableBody>
					      </Table>
					    </TableContainer>
				    </AccordionDetails>
				</Accordion>
				{ intermediary && intermediary.swift && intermediary.bank && intermediary.address && (
					<Accordion TransitionProps={{ unmountOnExit: true }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel2a-content"
							id="panel2a-header"
						>
							<Typography className="color-nx">Intermediary Bank (If Required)</Typography>
						</AccordionSummary>
						<AccordionDetails style={{padding: '0'}}>
							<TableContainer component={Paper}>
								<Table size="small" aria-label="a dense table">
									<TableBody>
										<TableRow key="sw">
											<TableCell component="th" scope="row">Bank Swift</TableCell>
											<TableCell align="right"><Typography fontSize="2" className="color-nx">{intermediary.swift}</Typography></TableCell>
										</TableRow>
										<TableRow key="bank">
											<TableCell component="th" scope="row">Bank</TableCell>
											<TableCell align="right"><Typography fontSize="2" className="color-nx">{intermediary.bank}</Typography></TableCell>
										</TableRow>
										<TableRow key="baddress">
											<TableCell component="th" scope="row">Bank Address</TableCell>
											<TableCell align="right"><Typography fontSize="2" className="color-nx">{intermediary.address}</Typography></TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</AccordionDetails>
					</Accordion>
				)}
  			</Grid>
	)
};

export default In4xWireTransferInfo;