import { handleFetch } from 'src/utils/fetch';
import { API_AUTH_NEW_PASSWORD, API_AUTH_RECOVER_PASSWORD, API_AUTH_VALIDATE_TOKEN } from "../../constants/apiRoutes";

export const sendEmailForRecovery = (email, captcha) => {
    return new Promise((resolve, reject) => {
        handleFetch(API_AUTH_RECOVER_PASSWORD, 'POST', { email, captcha })
            .then(res => {
                if (res.success) {
                    resolve();
                } else {
                    reject(res.payload && res.payload[0]);
                }
            }).catch((err) => {
                reject(err);
            });
    });
};


export const sendNewPasswordForRecovery = (password, recoverToken) => {
    return new Promise((resolve, reject) => {
        handleFetch(API_AUTH_NEW_PASSWORD, 'POST', { password, recoverToken })
            .then(res => {
                if (res.success) {
                    resolve();
                } else {
                    reject(res.payload && res.payload[0]);
                }
            }).catch((err) => {
                reject(err);
            });
    });
};


export const recoverPasswordValidateToken = (recoverToken) => {
    return new Promise((resolve, reject) => {
        handleFetch(API_AUTH_VALIDATE_TOKEN, 'POST', { recoverToken })
            .then(res => {
                if (res.success) {
                    resolve();
                } else {
                    reject(res.payload && res.payload[0]);
                }
            }).catch((err) => {
                reject(err);
            });
    });
};