import React, { useEffect, useState } from 'react'
import {compose} from "redux";
import {connect} from "react-redux";

import {FORM_CRYPTO_SWAP} from "src/ducks/FormData";
import {change, Field, isValid, reduxForm} from "redux-form";

import {Typography} from "@material-ui/core";
import ExchangeCurrencyField from "src/components/Forms/ExchangeCurrencyField";
import NXCustomInput from "src/components/Forms/NXField";
import AmountField from "src/components/Forms/AmountField";

import {getExchangeRate, getFeeSchedule} from "src/ducks/in4x";
import {changeSendingAmount, changeSendingAddress} from "src/ducks/blockchain";
import { feeForAmount, minLimit, maxLimit } from 'src/utils/in4xUtils';
import ProviderUtils from "src/utils/blockchain/ProviderUtils";
import { exRateIdxKey } from "src/utils/in4xUtils";

import {IN4X_ACTION_DEPOSIT, IN4X_METHOD_SWAP} from "src/constants/in4xConstants";
import Alert from "@material-ui/lab/Alert";

let CryptoSwapAmount = (props) => {

    const {
        blockchain, in4x, formValid,
        storedSwapTransactionData,
        onStoreSwapTransactionData = () => {},
        onDisabledNext = (disabled) => {},
        getExchangeRate = () => {},
        getFeeSchedule = () => {},
        changeSendingAddress = () => {},
        changeSendingAmount = () => {}
    } = props;

    const [payCurrency, setPayCurrency] = useState(null);
    const [receiveCurrency, setReceiveCurrency] = useState(null);

    const [lowBalance, setLowBalance] = useState(false);

    const [rate, setRate] = useState(0);
    const [amountLimits, setAmountLimits] = useState({ minAmount: 0, maxAmount: 0 });

    useEffect(() => {
        initFormData();
        // on mount trigger
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initFormData = () => {
        const payCur = blockchain?.sendingCurrencyObj?.name;
        setPayCurrency(payCur);
        const recCur = in4x?.activeCurrency?.name;
        setReceiveCurrency(recCur);
        props.dispatch(change(FORM_CRYPTO_SWAP, 'payCur', payCur?.toUpperCase()));
        props.dispatch(change(FORM_CRYPTO_SWAP, 'recCur', recCur?.toUpperCase()));
        getExchangeRate && getExchangeRate(payCur, recCur);
        if (!in4x?.in4xFeesLoading && !in4x?.in4xFeesLoaded && !in4x?.error) getFeeSchedule();
        changeSendingAddress(in4x?.activeCurrency?.swapAddress);
    }

    const { exRate, in4xFees } = in4x;
    useEffect(() => {
        const payCur = blockchain?.sendingCurrencyObj?.name;
        const recCur = in4x?.activeCurrency?.name;
        const rateKey = exRateIdxKey(payCur, recCur);
        const rate = in4x?.exRates[rateKey] && in4x.exRates[rateKey].low?.toFixed(2);
        if (rate) {
            props.dispatch(change(FORM_CRYPTO_SWAP, 'rateCur', rate));
            setRate(rate);
            initStoredSwapAmount();
        }
        // const rate = in4x?.exRate?.low?.toFixed(2);
        // if (rate) {
        //     props.dispatch(change(FORM_CRYPTO_SWAP, 'rateCur', rate));
        //     setRate(rate);
        // }

        // should trigger update only on exRate change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exRate]);

    useEffect(() => {
        const recCur = in4x?.activeCurrency?.name;
        const limits = in4x?.in4xLimits;
        if(in4x?.in4xFeesLoaded && limits[IN4X_ACTION_DEPOSIT] && limits[IN4X_ACTION_DEPOSIT][IN4X_METHOD_SWAP]) {
            setAmountLimits({
                minAmount: minLimit(limits, IN4X_ACTION_DEPOSIT, IN4X_METHOD_SWAP, recCur),
                maxAmount: maxLimit(limits, IN4X_ACTION_DEPOSIT, IN4X_METHOD_SWAP, recCur)
            });
        }
        // should trigger update only on in4xFees change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [in4xFees]);

    useEffect(() => {
        onDisabledNext(!formValid || lowBalance);
        // to avoid infinity loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValid, lowBalance]);

    const changedAmount = (amount) => {
        const recCur = in4x?.activeCurrency?.name;
        const fees = in4x?.in4xLimits;
        if(in4x?.in4xFeesLoaded && fees[IN4X_ACTION_DEPOSIT] && fees[IN4X_ACTION_DEPOSIT][IN4X_METHOD_SWAP]) {
            const currencyFee = feeForAmount(in4x?.in4xFees, IN4X_ACTION_DEPOSIT, IN4X_METHOD_SWAP, recCur, amount);
            const receiveAmount = rate && rate > 0 ? (amount * rate).toFixed(2) : 0;
            if (!amount) {
                props.dispatch(change(FORM_CRYPTO_SWAP, 'fee', ''));
                props.dispatch(change(FORM_CRYPTO_SWAP, 'receiveAmount', ''));
                props.dispatch(change(FORM_CRYPTO_SWAP, 'total', ''));
            } else {
                checkCurrencyBalance(amount);
                changeSendingAmount(amount);
                props.dispatch(change(FORM_CRYPTO_SWAP, 'fee', Number(currencyFee)));
                props.dispatch(change(FORM_CRYPTO_SWAP, 'receiveAmount', Number(receiveAmount)));
                props.dispatch(change(FORM_CRYPTO_SWAP, 'total', Number(receiveAmount) + Number(currencyFee)));
                onStoreSwapTransactionData({
                    amountBase: amount,
                    amountQuote: Number(receiveAmount),
                    feeAmount: Number(currencyFee),
                    payCur: payCurrency,
                    topupCur: receiveCurrency,
                    blockchain: in4x?.activeCurrency?.blockchain,
                    network: ProviderUtils.activeNetwork()
                });
            }
        }
    }

    const initStoredSwapAmount = () => {
        const cryptoSwapAmount = storedSwapTransactionData?.amountBase;
        if (cryptoSwapAmount && Number(cryptoSwapAmount) > 0) {
            props.dispatch(change(FORM_CRYPTO_SWAP, 'amount', Number(cryptoSwapAmount)));
            changedAmount(Number(cryptoSwapAmount));
        }
    }

    const getCurrencyBalance = () => {
        const {balance, sendingCurrencyObj} = blockchain;
        const chain = in4x?.activeCurrency?.blockchain;
        const cur = sendingCurrencyObj?.name;
        const currencyBalance = balance && balance[chain][cur];
        return currencyBalance ? Number(currencyBalance) : 0;
    }

    const checkCurrencyBalance = (amount) => {
        const currencyBalance = getCurrencyBalance();
        setLowBalance(amount > currencyBalance);
    }

    return (
        <>
            { payCurrency && receiveCurrency && (
                <Typography variant="h5" align="center" gutterBottom className="color-nx">Buy {receiveCurrency.toUpperCase()} with {payCurrency.toUpperCase()}</Typography>
            )}
            { in4x?.activeCurrency?.blockchain && (
                <Typography variant="subtitle2" align="center" gutterBottom className={"network-badge bg-"+in4x?.activeCurrency?.blockchain} style={{marginBottom: "1rem"}}>
                    Blockchain { in4x?.activeCurrency?.blockchain.toUpperCase() }
                </Typography>
            )}
            <form name={FORM_CRYPTO_SWAP}>
                <Field
                    name="payCur"
                    component={ExchangeCurrencyField}
                    type="text"
                    disabled
                    label="Pay"
                    className="override-nx"
                    customWidth="30%"
                />
                <Field
                    name="rateCur"
                    component={ExchangeCurrencyField}
                    type="text"
                    disabled
                    label="Rate"
                    className="override-nx"
                    customWidth="30%"
                />
                <Field
                    name="recCur"
                    component={ExchangeCurrencyField}
                    type="text"
                    disabled
                    label="Receive"
                    className="override-nx"
                    customWidth="40%"
                />
                <AmountField
                    minValue={amountLimits.minAmount}
                    maxValue={amountLimits.maxAmount}
                    onChange={changedAmount}
                />
                { lowBalance && payCurrency && (
                    <Alert variant="outlined" severity="error" icon={false}>
                        You don't have sufficient {payCurrency.toUpperCase()} balance, please receive {payCurrency.toUpperCase()} or topup to complete transaction
                    </Alert>
                )}
                <Field
                    component={NXCustomInput}
                    variant="outlined"
                    type="text"
                    name="fee"
                    label="Fee"
                    disabled
                    adornment=""
                />
                { receiveCurrency && (
                    <Field
                        component={NXCustomInput}
                        type="text"
                        name="receiveAmount"
                        label="Receive (Estimate)"
                        disabled
                        adornment={receiveCurrency.toUpperCase()}
                    />
                )}
                <Field
                    component={NXCustomInput}
                    type="text"
                    variant="outlined"
                    name="total"
                    label="Total (To be Transferred)"
                    disabled
                    adornment="TOTAL"
                />
            </form>
        </>
    )
};

const mapState2props = state => ({
    in4x: state.in4x,
    blockchain: state.blockchain,
    dashboard: state.dashboard,
    formValid: isValid(FORM_CRYPTO_SWAP)(state)
});

const mapDispatch2props = {
    getExchangeRate,
    getFeeSchedule,
    changeSendingAmount,
    changeSendingAddress
};

CryptoSwapAmount = reduxForm({
    form: FORM_CRYPTO_SWAP,
})(CryptoSwapAmount);

export default compose(
    connect(mapState2props, mapDispatch2props)
)(CryptoSwapAmount);

