export default class GtagUtil {

  static trackLogin = (address, method) => {
    if (window.gtag) {
      window.gtag('config', 'GA_MEASUREMENT_ID', { 'user_id': address });
      window.gtag('event', 'login', { method: method });
    }
  }

  static trackSignup = (method) => {
    if (window.gtag) window.gtag('event', 'sign_up', { method: "Email" });
  }

  static trackShare = (method, content_type, item_id) => {
    if (window.gtag) window.gtag('event', 'share', { method, content_type, item_id });
  }

  static trackActivateAccount = () => {
  	if(window.gtag) window.gtag('event', 'activate_account');
  }

  static trackTransfer = (value, currency, blockchain) => {
    if (window.gtag) window.gtag('event', 'transfer_tokens', {
      value,
      currency,
      blockchain
    });
  }

  static trackPurchase = (transaction_id, value, currency, item_name, blockchain, price, quantity) => {
  	if(!price || price === 0) {
  		return;
  	}
    if (window.gtag) window.gtag('event', 'purchase', {
      transaction_id,
      // affiliation: "Google Merchandise Store",
      value,
      // tax: 4.90,
      // shipping: 5.99,
      currency,
      // coupon: "SUMMER_SALE",
      items: [{
        // item_id,
        item_name,
        // affiliation: "Google Merchandise Store",
        // coupon: "SUMMER_FUN",
        currency,
        // discount: 2.22,
        index: 0,
        // item_brand: "Google",
        // item_category: "Apparel",
        // item_category2: "Adult",
        // item_category3: "Shirts",
        // item_category4: "Crew",
        // item_category5: "Short sleeve",
        // item_list_id: "related_products",
        // item_list_name: "Related Products",
        item_variant: blockchain,
        // location_id: "L_12345",
        price,
        quantity
      }]
    });
  }

  static trackEarnVirtualCurrency = (virtual_currency_name, value) => {
    if(window.gtag) window.gtag('event', 'earn_virtual_currency', {virtual_currency_name, value});
  }
}