import React from 'react'
import {Field, reduxForm} from 'redux-form'
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomInput from '../Field';
import {password, newPasswordConfirm, required} from "../../../utils/validation";

const EditPasswordForm = props => {
    const {handleSubmit, editPasswordError, editPasswordSuccess, editPasswordLoaded, formValid} = props;

    return (
        <form onSubmit={handleSubmit}>
            <Field
                name="oldPassword"
                component={CustomInput}
                type="password"
                validate={[required, password]}
                label="Old Password"
                autoComplete={"current-password"}
            />
            <Field
                name="password"
                component={CustomInput}
                type="password"
                validate={[required, password]}
                label="New Password"
                autoComplete={"new-password"}
            />
            <Field
                name="confirm"
                component={CustomInput}
                type="password"
                validate={[required, newPasswordConfirm]}
                label="Confirm Password"
            />

            { editPasswordError && (
                <div className="register__error" dangerouslySetInnerHTML={{ __html: editPasswordError }}></div>
            )}

            { editPasswordSuccess && (
                <div className="register__success" dangerouslySetInnerHTML={{ __html: editPasswordSuccess }}></div>
            )}

            <Button
                type={'submit'}
                className="spaced__btn"
                fullWidth
                variant="contained"
                disabled={!formValid}
                color="primary"
            >
                {!editPasswordLoaded ? 'Save' : <CircularProgress size={22}/>}
            </Button>
        </form>
    )
};

export default reduxForm({
    form: 'edit-password'
})(EditPasswordForm)