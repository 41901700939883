import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    Grid,
    Typography
} from "@material-ui/core";
import { openLink } from "src/utils/linksOpenPolyfill";

const ModalBody = (props) => {
    const {
        transactionHash = "",
        transactionURl = "",
        errorText = "",
        onCloseClick = () => {},
    } = props;

    const handleOpenTransactionLink = (event) => {
        openLink(event, transactionURl)
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography
                    variant={'h6'}
                    color={'error'}
                    gutterBottom
                >
                    Transaction failed!
                </Typography>

                {errorText && (
                    <Typography gutterBottom>
                        {errorText}
                    </Typography>
                )}

                <a
                    color="primary"
                    href={transactionURl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleOpenTransactionLink}
                >
                    { transactionHash }
                </a>
            </Grid>
            <Grid item xs={12}>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={onCloseClick}
                >
                    Close
                </Button>
            </Grid>
        </Grid>
    )
};

ModalBody.propTypes = {
    errorText: PropTypes.string,
    transactionHash: PropTypes.string,
    transactionURl: PropTypes.string,
    onCloseClick: PropTypes.func,
};

export default ModalBody;