import React from "react";

import ProviderUtils from "../../utils/blockchain/ProviderUtils";
import { getBlockchainName } from "../../constants/blockchain";
import { getCurrencyIcon } from "../../constants/currencies";

const BlockchainLabel = (props) => {
	const blockchain = ProviderUtils.activeBlockchain();
	const BlockchainTextLabel = getBlockchainName(blockchain);
	return(
		<img src={getCurrencyIcon(blockchain)} style={{height:"50px", marginBottom: "1rem"}} alt={BlockchainTextLabel} />
    );
}

export default BlockchainLabel;