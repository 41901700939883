import React from "react";
import Container from '@material-ui/core/Container';

import TransactionHistoryTable from "../../components/TransactionHistoryTable";

class TransactionHistory extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
  	return (
  		<Container className="home dashboard" component="main" maxWidth="sm">
  			<TransactionHistoryTable />
  		</Container>
  	);
  };

}

export default TransactionHistory;