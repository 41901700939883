import React, { useEffect, useState } from 'react'
import { compose } from "redux";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import IconButton from '@material-ui/core/IconButton';
import ListIcon from '@material-ui/icons/List';
import Button from "@material-ui/core/Button";
import CheckBox from "@material-ui/core/Checkbox";
import {
    CircularProgress,
    Container,
    Grid,
    Menu,
    MenuItem,
    Typography
} from "@material-ui/core";
import BlockchainLabel from "src/common/blockchain-label";
import {
    ASSET_TYPE_NFT,
    ROUTE_WALLET_ASSETS,
    ROUTE_SEND_NFT,
} from "src/constants/routes";
import {
    setNFTLoadError,
    changeSendingCurrency,
    changeSendingAmount
} from "src/ducks/blockchain";
import BlockExplorerUtils from "src/utils/blockchain/BlockExplorerUtils";
import { openLink } from 'src/utils/linksOpenPolyfill';
import { getInventory } from "./utils";
import SendIcon from "@material-ui/icons/Send";
import PageViewIcon from "@material-ui/icons/Pageview";

const useStyles = makeStyles(() => ({
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    loadMoreBtn: {
        color: '#00499b !important',
        '&:hover': {
            color: '#00499b !important'
        }
    },
    menuHeader: {
        pointerEvents: 'none',
        fontWeight: 'bold'
    },
    multiSendButtonsContainer: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "20px",
        marginTop: "20px",
        width: "96%",
    },
    multiSendContainer: {
        paddingTop: "90px",
    },
    multiSelectHead: {
        position: "fixed",
        top: "46px",
        width: "90%",
        background: "#fff",
        zIndex: "9",
        paddingBottom: "20px"
    }
}));

const NftInventory = (props) => {
    const classes = useStyles();

    const {
        history,
        blockchain: {
            sendingCurrency,
            sendingCurrencyObj,
            nftInventory,
            isNftInventoryLoading,
            nftError,
            wallet
        },
        setNFTLoadError,
        changeSendingCurrency,
        changeSendingAmount
    } = props;

    if(!sendingCurrency) {
        history.push(ROUTE_WALLET_ASSETS + ASSET_TYPE_NFT);
    }
    const isMultiSend = history.location?.state?.isMultiSend;
    const preselectedNFTs = history.location?.state?.preselectedNFTs;
    const nextSelectedNFTs = preselectedNFTs ? preselectedNFTs.split(",") : [];
    const [loading, setIsLoading] = useState(false);
    const [nextPageKey, setNextPageKey] = useState();
    const [loadedNFTs, updateLoadedNFTs] = useState(null);
    const [nftMenuData, setNftMenuData] = useState({anchorEl: null, tokenId: null});
    const [isSelectAll, setSelectAll] = useState(false);
    const [selected, setSelected] = useState(nextSelectedNFTs);

    const fetchInventory = (pageKey = undefined) => {
        if(sendingCurrencyObj.address && nftInventory && !nftInventory[sendingCurrencyObj.address] && !isNftInventoryLoading) {
            setIsLoading(true);
            getInventory({
                tokenAddress: sendingCurrencyObj.address,
                blockchain: sendingCurrencyObj.blockchain,
                wallet,
                pageKey,
                onError: (error) => {
                    setNFTLoadError(error, sendingCurrencyObj.address)
                },
                onSuccess: (response) => {
                    const { ownedNfts, pageKey } = response;
                    const nfts = loadedNFTs !== null ? loadedNFTs : [];
                    if(Array.isArray(ownedNfts)) {
                        nfts.push(...ownedNfts);
                    }
                    updateLoadedNFTs(nfts);
                    setNextPageKey(pageKey);
                    setIsLoading(false)
                }
            }).then();
        }
    };

    useEffect(() => {
        fetchInventory();
    // eslint-disable-next-line
    }, []);

    const loadMoreNft = () => {
        fetchInventory(nextPageKey);
    };

    const handleClick = (event, tokenId) => {
        event.preventDefault();
        openNftMenu(event.currentTarget, tokenId);
    };

    const handleConfirmMultiSendConfirm = () => {
        const nextIds = selected.join(",");
        //m in route is expected and serve to define a multi send by send-nft component
        const route = `${ROUTE_SEND_NFT}${sendingCurrencyObj.blockchain}/${sendingCurrencyObj.address}/m${nextIds}`
        history.push(route)
    };

    const handleClose = (action) => {
        switch(action) {
            case 'send':
                changeSendingCurrency(sendingCurrencyObj.name, sendingCurrencyObj);
                changeSendingAmount(nftMenuData.tokenId);
                history.push(ROUTE_SEND_NFT + sendingCurrencyObj.blockchain + '/' + sendingCurrencyObj.address + '/' + nftMenuData.tokenId);
                break;
            case 'opensea':
                const externalUrl = BlockExplorerUtils.openseaNftAsset(sendingCurrencyObj.address, sendingCurrencyObj.blockchain, nftMenuData.tokenId);
                openLink(null, externalUrl);
                break;
            case 'polygonscan':
                const explorerUrl = BlockExplorerUtils.scanNft(sendingCurrencyObj.address, sendingCurrencyObj.blockchain, nftMenuData.tokenId);
                openLink(null, explorerUrl);
                break;
            default:
                break;
              
        }
        closeNftMenu();
    }

    const handleUpdateSelectedNFTList = (nftID) => {
        let list = [...selected];

        if (list.includes(nftID)) {
            list = list.filter(id => id !== nftID);
        } else {
            list.push(`${nftID}`);
        }

        setSelected(list);
    };

    const handleToggleSelectAll = () => {
        if (selected.length) {
            setSelected([]);
        } else {
            const nextSelectedList = loadedNFTs.map(item => item.tokenId);
            setSelected(nextSelectedList);
        }
        setSelectAll(prev => !prev);
    };

    const handleBack = () => {
        history.push(ROUTE_WALLET_ASSETS + ASSET_TYPE_NFT);
    }

    const openNftMenu = (anchorEl, tokenId) => {
        setNftMenuData({anchorEl, tokenId});
    }

    const closeNftMenu = () => {
        setNftMenuData({
            anchorEl: null,
            tokenId: null
        });
    };

    const sortedNFTs = loadedNFTs?.sort((a, b) => a.tokenId - b.tokenId);
    const nextNFTsList = sortedNFTs?.map((nft, key) => {
        const { media, tokenId } = nft;
        const onClick = (event) => handleClick(event, nft.tokenId);
        const handleNftSelectChange = () => handleUpdateSelectedNFTList(`${tokenId}`);
        const isChecked = selected.includes(`${tokenId}`) || isSelectAll;

        const nextActionItem = isMultiSend ? (
            <CheckBox
                checked={isChecked}
                style={{ color: "white" }}
                onClick={handleNftSelectChange}
            />
        ) : (
            <IconButton
                className={classes.icon}
                onClick={onClick}>
                <ListIcon />
            </IconButton>
        );

        return (
            <ImageListItem key={key} style={{ height: 'auto', minHeight: '165px', listStyle: "none" }}>
                { media?.length > 0 && (
                    <>
                        <div
                            align="center"
                            style={{position: 'absolute', width: '100%' }}
                            className='spaced-top'
                        >
                            <CircularProgress />
                        </div>
                        <img src={nft?.media[0]?.raw}
                             alt={`NFT ${nft.tokenId}`}
                             style={{ position: 'relative', height: 'auto'}}
                        />
                    </>
                )}
                { tokenId && (
                    <ImageListItemBar
                        title={`#${nft.tokenId}`}
                        className={classes.noStyleList}
                        actionIcon={nextActionItem}
                    />
                )}
            </ImageListItem>
        )
    });

    const pageTitle = isMultiSend ? `Multi Send ${sendingCurrency}` : `My ${sendingCurrency} NFTs`;
    const inventoryHeaderClass = isMultiSend ? classes.multiSelectHead : "regular-head";
    const inventoryContainerClass = isMultiSend ? classes.multiSendContainer : "regular-container";
    const selectAllButtonTitle = isSelectAll ? "Unselect All" : "Select All";

    return (
        <Container className={inventoryContainerClass} style={{display: 'flex', flexDirection: 'column'}}>
            <div className={inventoryHeaderClass} style={{flex: 'none', textAlign: 'center'}}>
                <BlockchainLabel />
                <Typography
                    variant="h5"
                    align="center"
                    gutterBottom
                    color="primary"
                    className="spaced-top-s"
                >
                   {pageTitle}
                </Typography>
                {
                    isMultiSend && (
                       <Grid className={classes.multiSendButtonsContainer}>
                           <Button
                               type="submit"
                               fullWidth
                               variant="contained"
                               color="default"
                               onClick={handleToggleSelectAll}
                           >
                               { selectAllButtonTitle }
                           </Button>
                           <Button
                               type="submit"
                               fullWidth
                               variant="contained"
                               color="primary"
                               onClick={handleConfirmMultiSendConfirm}
                           >
                               Confirm
                           </Button>
                       </Grid>
                    )
                }
            </div>
            <div style={{flex: 'auto'}}>
                { !nftError && (
                    <>
                        <ImageList cols={2} className="spaced-top-s"> >
                            { nextNFTsList }
                            { nextPageKey && (
                                <ImageListItem key="more" cols={2} style={{ height: 'auto' }}>
                                    <Button
                                        type="submit"
                                        className="spaced__btn"
                                        fullWidth variant="contained"
                                        color="primary"
                                        onClick={loadMoreNft}
                                    >
                                        Load More
                                    </Button>
                                </ImageListItem>
                            )}
                        </ImageList>
                        <Menu
                            className="simple-menu"
                            anchorEl={nftMenuData?.anchorEl}
                            keepMounted
                            open={Boolean(nftMenuData?.anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={()=>{handleClose('send')}}>
                                <SendIcon />&nbsp;Send
                            </MenuItem>
                            <MenuItem onClick={()=>{handleClose('opensea')}}>
                                <img src="/img/opensea.png" alt="OpenSea" style={{width:"20px"}} />&nbsp;Opensea
                            </MenuItem>
                            <MenuItem onClick={()=>{handleClose('polygonscan')}}>
                                <PageViewIcon />&nbsp;Block Explorer
                            </MenuItem>
                        </Menu>
                    </>
                )}
                { loading && (
                    <Grid item xs={12} className="centered-grid spaced-top">
                        <CircularProgress />
                        <Typography
                            color="primary"
                            align="center"
                            gutterBottom
                            className="spaced-top"
                        >
                            Loading NFTs...
                        </Typography>
                    </Grid>
                )}
            </div>
            <div style={{flex: 'none'}}>
                <Button
                    variant="contained"
                    fullWidth
                    onClick={handleBack}
                    className="spaced-top"
                >
                    Back
                </Button>
            </div>
        </Container>
    );
}

const mapState2props = state => ({
    blockchain: state.blockchain,
});

const mapDispatch2props = {
    setNFTLoadError,
    changeSendingAmount,
    changeSendingCurrency
}

export default compose(connect(mapState2props, mapDispatch2props))(NftInventory);
