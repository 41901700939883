export const ROUTE_INDEX = "/";
export const ROUTE_SIGNUP = "/signup";
export const ROUTE_SIGNIN = "/signin";
export const ROUTE_SIGNIN_CLEAR_DATA = "/sign-in";
export const ROUTE_CREATE_WALLET = "/create-wallet";
export const ROUTE_SUCCESS_REGISTRATION ="/success-registration";

export const ASSET_TYPE_SPOT = "spot";
export const ASSET_TYPE_LIQUID = "liquid-wallet";
export const ASSET_TYPE_DIGITAL = "asset";
export const ASSET_TYPE_LEGACY_LIQUID = "liquid";
export const ASSET_TYPE_NFT = 'nft';

export const ROUTE_WALLET_ASSETS = "/my-assets/";
export const ROUTE_WALLET_ASSETS_DEFN = ROUTE_WALLET_ASSETS + ":assetType?"
export const ROUTE_DEFAULT_LOGGEDIN = ROUTE_WALLET_ASSETS + ASSET_TYPE_LIQUID;
export const ROUTE_NFT_INVENTORY = "/nft/inventory/";
export const ROUTE_NFT_INVENTORY_DEFN = ROUTE_NFT_INVENTORY + ":blockchain/:currency";

export const ROUTE_ABOUT = "/about";
export const ROUTE_PRIVATE_POLICY = "/private-policy";
export const ROUTE_COOKIES_POLICY = "/cookie-policy";

export const ROUTE_COMING_SOON = "/coming-soon";
export const ROUTE_SHOW_MNEMONIC = "/show-mnemonic";
export const ROUTE_EDIT_ACCOUNT = "/edit-account";
export const ROUTE_EDIT_PASSWORD = "/edit-password";
export const ROUTE_SHOW_ADDRESS = "/show-address";
export const ROUTE_SCANNER = "/scanner";

export const ROUTE_STAKE_DASH = '/staking/dashboard';

export const ROUTE_SEND = "/send/";
export const ROUTE_SEND_DEFN = ROUTE_SEND + ":blockchain/:currency";
export const ROUTE_SEND_NFT = "/send-nft/";
export const ROUTE_SEND_NFT_DEFN = ROUTE_SEND_NFT + ":blockchain/:currency/:tokenId";
export const ROUTE_STAKE = "/tokens/stake/";
export const ROUTE_UNSTAKE = "/tokens/unstake/";
export const ROUTE_STAKE_DEFN = ROUTE_STAKE + ":blockchain/:currency";
export const ROUTE_UNSTAKE_DEFN = `${ROUTE_UNSTAKE}:blockchain/:currency`;
export const ROUTE_BUY_TOKEN = "/in4x/indacoin/buy/";
export const ROUTE_BUY_TOKEN_DEFN = ROUTE_BUY_TOKEN + ":blockchain/:currency";
export const ROUTE_CASH_TOKEN = "/in4x/cashtokens/"
export const ROUTE_CASH_TOKEN_DEFN = ROUTE_CASH_TOKEN + ":blockchain/:currency";
export const ROUTE_BUY_TOKEN_STATUS = "/in4x/indacoin/transaction/";
export const ROUTE_BUY_TOKEN_STATUS_DEFN = ROUTE_BUY_TOKEN_STATUS + ":transaction_id";
export const ROUTE_INVEST = "/invest/";
export const ROUTE_INVEST_DEFN = ROUTE_INVEST + ":partner";
export const ROUTE_CRYPTO_SWAP = "/in4x/cryptoswap/"
export const ROUTE_CRYPTO_SWAP_DEFN  = ROUTE_CRYPTO_SWAP + ":blockchain/:currency";
export const ROUTE_CRYPTO_SWAP_HISTORY = "/in4x/cryptoswap-history/";
export const ROUTE_CRYPTO_SWAP_HISTORY_DEFN = ROUTE_CRYPTO_SWAP_HISTORY + ":ref";
export const ROUTE_SWAP = '/swap';
export const ROUTE_SWAP_DEFN = `${ROUTE_SWAP}/:currency`;
export const ROUTE_TRANSACTION = "/transaction/";
export const ROUTE_TRANSACTION_DEFN = ROUTE_TRANSACTION + ":blockchain/:id/:pending?";
export const ROUTE_TRANSACTION_HISTORY = "/transaction/history/";
export const ROUTE_TRANSACTION_HISTORY_DEFN = ROUTE_TRANSACTION_HISTORY + ":blockchain/:currency/:address?";

export const ROUTE_BIOMETRICS_ACTIVATE = '/biometrics-activate';

export const ROUTE_E_WALLET_BASE = "/in4x/e-wallet/";
export const ROUTE_E_WALLET_METHODS = ROUTE_E_WALLET_BASE + "methods/";
export const ROUTE_E_WALLET_METHODS_DEFN = ROUTE_E_WALLET_METHODS + ":action/:blockchain/:currency";
export const ROUTE_E_WALLET_BANK_DEPOSIT = ROUTE_E_WALLET_BASE + "bank/deposit/";
export const ROUTE_E_WALLET_BANK_DEPOSIT_DEFN = ROUTE_E_WALLET_BANK_DEPOSIT + ':blockchain/:currency';
export const ROUTE_E_WALLET_BANK_WITHDRAW = ROUTE_E_WALLET_BASE + "bank/withdraw/";
export const ROUTE_E_WALLET_BANK_WITHDRAW_DEFN = ROUTE_E_WALLET_BANK_WITHDRAW + ':blockchain/:currency';

export const ROUTE_NEW_ACCOUNT = "/new-account";
export const ROUTE_IMPORT_WALLET = "/import-wallet";
export const ROUTE_IMPORT_WALLET_SCANNER = "/import-wallet/scanner";

export const DEEP_LINK_BASE = "iownw:/";
export const ROUTE_NX_ACTIVATE = "/in4x/activate";
export const ROUTE_NX_WIRETRANSFER = "/in4x/wiretransfer/";
export const ROUTE_NX_WIRETRANSFER_DEFN = ROUTE_NX_WIRETRANSFER + ":ref";
export const ROUTE_NX_CASHTRANSFER = "/in4x/cashtransfer/";
export const ROUTE_NX_CASHTRANSFER_DEFN = ROUTE_NX_CASHTRANSFER + ":ref";
export const ROUTE_NX_PAYMENT = "/in4x/payment/";
export const ROUTE_NX_PAYMENT_DEFN = ROUTE_NX_PAYMENT + ":ref";
export const ROUTE_NX_PAYMENT_BY_ADDRESS = "/in4x/address-pay/";
export const ROUTE_NX_PAYMENT_BY_ADDRESS_DEFN = ROUTE_NX_PAYMENT_BY_ADDRESS + ":payAddress";
export const EXTERNAL_NX_PAYMENT_WEB = "in4xglobal.com/api/intent/payment/";
export const ROUTE_HOME_SCANNER = "/home-scanner";
export const ROUTE_NX_TRANSACTION_HISTORY = "/in4x/transaction/history/";
export const ROUTE_NX_TRANSACTION_HISTORY_DEFN = ROUTE_NX_TRANSACTION_HISTORY + ":type";

export const ROUTE_WALLET_CONNECT = "/wallet-connect";

export const ROUTE_IOWN_DIGITAL_LINK = "/iod/link";
export const ROUTE_IOWN_DIGITAL_SCANNER = "/iod/scanner";
// This is a hack route, which should not be needed anymore and needs cleanup
export const ROUTE_RESUME = "/resume";

export const ROUTE_RESET_PASSWORD = "/reset-password";
export const ROUTE_RECOVER_PASSWORD = "/recover-password";

export const ROUTE_ADDRESS_BOOK = "/address-book";

export const EXTERNAL_CONTACTUS = 'https://www.in4xglobal.com/contact-us';
export const EXTERNAL_STAKING = 'https://www.iowntoken.com/stake/';
export const EXTERNAL_NFT_PROGRAM = 'https://www.amlakproperty.com/nft-program/';

export const isGuestRoute = (pathname) => {
  return pathname === ROUTE_SIGNIN ||
    pathname === ROUTE_SIGNIN_CLEAR_DATA ||
    pathname === ROUTE_SIGNUP ||
    pathname === ROUTE_INDEX ||
    pathname === ROUTE_CREATE_WALLET ||
    pathname === ROUTE_SUCCESS_REGISTRATION ||
    pathname === ROUTE_ABOUT ||
    pathname === ROUTE_PRIVATE_POLICY ||
    pathname === ROUTE_IMPORT_WALLET ||
    pathname === ROUTE_IMPORT_WALLET_SCANNER ||
    pathname === ROUTE_RESUME ||
    pathname === ROUTE_NEW_ACCOUNT ||
    pathname === ROUTE_COOKIES_POLICY ||
    pathname === ROUTE_RESET_PASSWORD ||
    pathname === ROUTE_RECOVER_PASSWORD;
};

export const isNextAllowed = (pathname) => {
    return pathname !== ROUTE_EDIT_PASSWORD;
}

export const isDeepLinkAllowed = (pathname, activeUser) => {
    if(!pathname) return false;
    if (activeUser) {
        return pathname.indexOf(ROUTE_SEND) !== -1 ||
            pathname.indexOf(ROUTE_WALLET_ASSETS) !== -1 ||
            pathname.indexOf(ROUTE_BUY_TOKEN) !== -1 ||
            pathname === ROUTE_IMPORT_WALLET ||
            pathname.indexOf(ROUTE_TRANSACTION) !== -1 ||
            pathname.indexOf(ROUTE_INVEST) !== -1 ||
            pathname.indexOf(ROUTE_WALLET_CONNECT) !== -1 ||
            pathname.indexOf(ROUTE_NX_PAYMENT) !== -1 ||
            pathname.indexOf(ROUTE_RECOVER_PASSWORD) !== -1;
    }
    return pathname.indexOf(ROUTE_RECOVER_PASSWORD) !== -1;
};

export const isNetworkSwitchingAllowed = (pathname) => {
    if(!pathname) return false;
    return pathname.indexOf(ROUTE_WALLET_ASSETS) !== -1 ||
        pathname.indexOf(ROUTE_WALLET_CONNECT) !== -1;
}

export const isIn4xRoute = (pathname) => {
    return pathname && pathname.indexOf('/in4x/') !== -1;
}

// Routes which we don't set on ?next=** on signing state
export const isNotRedirectableOnSigning = (pathname) => {
    return pathname === ROUTE_SIGNIN ||
        pathname === ROUTE_COMING_SOON ||
        pathname === ROUTE_SHOW_MNEMONIC ||
        isScannerRoute(pathname);
}

export const isScannerRoute = (pathname) => {
    return pathname === ROUTE_SCANNER ||
        pathname === ROUTE_HOME_SCANNER ||
        pathname === ROUTE_IMPORT_WALLET_SCANNER ||
        pathname === ROUTE_IOWN_DIGITAL_SCANNER;
}