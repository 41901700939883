const ethers = require("ethers");

export default class BNUtils {

    /**
     * @name ensureDecimals
     * description Ensures a number has at most "decimals" number of decimals after the '.'
     * @param {string} `value` A string representation of a number
     * @param {int} `decimals` Number of decimals to keep at most
     * @returns {string} formatted number
     */
    static ensureDecimals = (value, decimals) => {
        let v = value + '';
        const idx = v.indexOf(".");
        if(idx > 0) {
            const decimalPlaces = v.length - idx - 1;
            return decimals < decimalPlaces? v.slice(0, v.length - (decimalPlaces - decimals)) : v;
        }
        return v;
    }

    /**
     * @name maxEthBalance
     * @description Calculates etherbalance - transactionFee as bignumbers and returns a string result
     * @param {string} `etherBalance` total wallet balance in Ether
     * @param {string} `transactionFee` total fee (blockchain.gasFee.value usually)
     * @returns {string} computer Bignumber.toString()
     */
    static maxEthBalance = (etherBalance, transactionFee) => {
        if(!etherBalance || etherBalance === '?') etherBalance = "0";
        if(!transactionFee) transactionFee = "0";
        let etherBal = ethers.utils.parseUnits(BNUtils.ensureDecimals(etherBalance, 18));
        let etherFee = ethers.utils.parseUnits(BNUtils.ensureDecimals(transactionFee, 18));
        let ethBalBN = ethers.BigNumber.from(etherBal);
        let etherFeeBN = ethers.BigNumber.from(etherFee);
        if(ethBalBN.lt(etherFeeBN)) {
            return "0";
        }
        return ethers.utils.formatEther(ethBalBN.sub(etherFeeBN));
    }

    /**
     * @name isAmountValidForBalance
     * @description Checks if AmountBlance >= amount as big numbers
     * @param {string} `amount` An arbitrary amount
     * @param {string} `amountBalance` Wallet balance for the same currency as amount
     * @returns {boolean} Whether amount <= bal
     */
    static isAmountValidForBalance = (amount, amountBalance, decimals) => {
        if(!amount) amount = "0";
        if(!amountBalance || amountBalance === '?') amountBalance = "0"; //? is before balances are loaded
        let formatAmount = ethers.utils.parseUnits(BNUtils.ensureDecimals(amount, decimals), decimals);
        let formatBal = ethers.utils.parseUnits(amountBalance+'', decimals);
        return (ethers.BigNumber.from(formatBal).gte(ethers.BigNumber.from(formatAmount)));  
    }

    /**
     * @name isEtherValidForBalance
     * @description Checks if amountEth + feeEther <= balanceEther as big numbers
     * @param {string} `amountEth` An arbitrary amount
     * @param {string} `feeEther` Current Ether Fee
     * @param {string} `balanceEther` Wallet Ether balance
     * @returns {boolean} Whether amount <= bal
     */
    static isEtherValidForBalance = (amountEth, feeEther, balanceEther) => {
        if(!amountEth) amountEth = "0";
        if(!feeEther) feeEther = "0";
        if(!balanceEther || balanceEther === '?') balanceEther = "0";
        let formatAmount = ethers.utils.parseUnits(BNUtils.ensureDecimals(amountEth, 18));
        let formatBal = ethers.utils.parseUnits(balanceEther);
        let formatFee = ethers.utils.parseUnits(feeEther);
        let totalAmount = ethers.BigNumber.from(formatAmount).add(ethers.BigNumber.from(formatFee));
        let r = (ethers.BigNumber.from(formatBal).gte(totalAmount));
        return r;
    }

    /**
     * @name isBNGte
     * @description Checks if v1 >= v2 as big numbers with "decimals" precision
     * @param {string} `v1` An arbitrary amount
     * @param {string} `v2` An arbitrary amount
     * @param {int} `decimals` A precions (number of bits) in the big number
     * @returns {boolean} Whether v1 >= v2
     */
    static isBNGte(v1, v2, decimals) {
        if(!v1) v1 = "0";
        if(!v2) v2 = "0";
        let formatV1 = ethers.utils.parseUnits(BNUtils.ensureDecimals(v1, decimals), decimals);
        let formatV2 = ethers.utils.parseUnits(BNUtils.ensureDecimals(v2, decimals), decimals);
        return (ethers.BigNumber.from(formatV1).gte(ethers.BigNumber.from(formatV2)));
    }

    /**
     * @name gweiAdd
     * @description Calculates the SUM of 2 gwei strings as bignumbers
     * @param {string} `v1` An arbitrary amount
     * @param {string} `v2` An arbitrary amount
     * @returns {string} v1 + v2 as formatted string in gwei
     */
    static gweiAdd = (v1, v2) => {
        v1 = !v1? 0 : v1;
        v2 = !v2? 0 : v2;
        let v1g = ethers.utils.parseUnits(BNUtils.ensureDecimals(v1, 9), 'gwei');
        let v2g = ethers.utils.parseUnits(BNUtils.ensureDecimals(v2, 9), 'gwei');
        return ethers.utils.formatUnits(v1g.add(v2g), 'gwei');
    }

    static bnToString(v, decimals) {
        if(ethers.BigNumber.isBigNumber(v)) {
            return ethers.utils.formatUnits(v, decimals);
        } else {
            return v;
        }
    }
}