import React, {useEffect, useState} from 'react'
import {compose} from "redux";
import {connect} from "react-redux";

import {Container, Grid} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import BlockchainLabel from "src/common/blockchain-label";

import {ROUTE_SCANNER} from "src/constants/routes";
import AddressList from "./AddressList";

import {changeSendingAddress} from "src/ducks/blockchain";
import {getCommonAddressBookData, getSavedAddressBookData, removeAddressBookItem} from "src/ducks/addresses";
import ProviderUtils from "src/utils/blockchain/ProviderUtils";


const AddressBook = (props) => {

    const {
        history = {
            goBack: () => {},
            push: () => {}
        },
        changeSendingAddress = () => {},
        getCommonAddressBookData = () => {},
        removeAddressBookItem = () => {},
        getSavedAddressBookData = () => {},
    } = props;
    const {commonAddresses, savedAddresses, commonAddressesDataLoaded, savedAddressesDataLoaded} = props.addresses;

    useEffect(() => {
        if (!commonAddressesDataLoaded) {
            getCommonAddressBookData();
        }
        if (!savedAddressesDataLoaded) {
            const wallet = props?.blockchain?.wallet?.address;
            getSavedAddressBookData(wallet);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [filteredCommonAddresses, setFilteredCommonAddresses] = useState([]);
    const [filteredSavedAddresses, setFilteredSavedAddresses] = useState([]);

    useEffect(() => {
        const blockchain = ProviderUtils.activeBlockchain();
        const network = ProviderUtils.activeNetwork();
        setFilteredCommonAddresses(commonAddresses.filter((item) => item.blockchain === blockchain && item.network === network));
        setFilteredSavedAddresses(savedAddresses.filter((item) => item.blockchain === blockchain && item.network === network));
    }, [commonAddresses, savedAddresses]);


    const handleBack = () => {
        history.goBack();
    }

    const openQRScanner = () => {
        history.replace(ROUTE_SCANNER);
    }

    const addressItemClick = (item) => {
        changeSendingAddress(item.address);
        history.goBack();
    }

    const deleteSavedAddressButtonClick = (item) => {
        const wallet = props?.blockchain?.wallet?.address;
        removeAddressBookItem(wallet, item);
    }

    return (
        <Container className="home" component="main" maxWidth="sm">
            <Grid className="centered-grid">
                <BlockchainLabel />
            </Grid>
            <Typography variant='h5' align='center' gutterBottom>
                Address Book
            </Typography>
            <div className='spaced-top'>
                <Grid container onClick={openQRScanner}>
                    <Grid item xs={8}>
                        <Typography align='left' style={{ paddingTop: '5px', marginLeft: '14px', color: '#0082BC' }}>
                            Scan Address QR Code
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <img
                            style={{ paddingRight: '11px'}}
                            src="/img/qrcode-scan.png"
                            alt="qr-icon"
                            align='right'/>
                    </Grid>
                </Grid>
            </div>
            <div className='spaced-top-s'>
                <AddressList
                    className='spaced-top-s'
                    title={'Saved Addresses'}
                    addresses={filteredSavedAddresses}
                    addressItemClick={addressItemClick}
                    deleteButtonClick={deleteSavedAddressButtonClick}
                />
            </div>
            <div className='spaced-top-s'>
                {filteredCommonAddresses?.length > 0 && (
                    <AddressList
                        className='spaced-top-s'
                        title={'Predefined Addresses'}
                        addresses={filteredCommonAddresses}
                        addressItemClick={addressItemClick}
                        deleteButtonClick={deleteSavedAddressButtonClick}
                        disableDeleteButton={true}
                    />
                )}
            </div>
            <Button
                className='spaced-top-s'
                onClick={ handleBack }
                fullWidth
                variant="contained"
                color="default"
            >
                Back
            </Button>
        </Container>
    )
};

const mapState2props = state => ({
    blockchain: state.blockchain,
    addresses: state.addresses
});

const mapDispatch2props = {
    changeSendingAddress,
    getCommonAddressBookData,
    getSavedAddressBookData,
    removeAddressBookItem
};

export default compose(
    connect(mapState2props, mapDispatch2props, null, {forwardRef: true})
)(AddressBook);
