import React from "react";
import { Link } from "react-router-dom";

import Alert from '@material-ui/lab/Alert';
import { Grid } from '@material-ui/core';
import { ROUTE_NX_ACTIVATE } from "../../constants/routes";

let In4xKyc = props => {
	const { kycLevel } = props;
 	return (
		<Grid item xs={12}>
			{ kycLevel === false && (
				<Link to={ROUTE_NX_ACTIVATE}>
	              <Alert variant="outlined" severity="error">Your account is no active yes
	              </Alert>
	            </Link>
			)}
			{ kycLevel === 0 && (
				<Link to={ROUTE_NX_ACTIVATE}>
	              <Alert variant="outlined" severity="error">Your KYC was Rejected
	              </Alert>
	            </Link>
			)}
			{ kycLevel === 1 && (
				<Link to={ROUTE_NX_ACTIVATE}>
	              	<Alert variant="outlined" severity="info">Your KYC is pending (check your email)
	              	</Alert>
              	</Link>
			)}
			{ kycLevel === 10 && (
				<Link to={ROUTE_NX_ACTIVATE}>
	              <Alert variant="outlined" severity="error">Your KYC has expired
	              </Alert>
	            </Link>
			)}
			{ kycLevel === 11 && (
              	<Alert variant="outlined" severity="info">Your KYC is in review
              	</Alert>
			)}
			{ kycLevel === 2 && (
              	<Alert variant="outlined" severity="success">Your account is active
              	</Alert>
			)}
			{ kycLevel === 3 && (
              	<Alert variant="outlined" severity="success">Your account is active
              	</Alert>
			)}
		</Grid>
	)
}

export default In4xKyc;