import React from "react";
import sha512 from "js-sha512";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { change, getFormValues, isValid } from "redux-form";
import Recaptcha from "react-grecaptcha";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Mnemonic from "../../components/mnemonic/Mnemonic";
import SignInNewForm from "../../components/Forms/SignInNew";
import {ROUTE_SIGNUP, ROUTE_DEFAULT_LOGGEDIN, ROUTE_INDEX} from "../../constants/routes";
import history from "../../history";
import WalletUtils from "../../utils/blockchain/WalletUtils";
import StorageUtils from "../../utils/storageUtils";
import { getError } from "../../utils/transformErrors";
import { ValidationUtil } from "../../utils/validationUtil";
import { encryptDES } from "../../utils/cryptoUtil";
import { RegExps } from "../../constants/regExps";

import {
  changeLoginMnemonic,
  changeWholeMnemonic,
  loginWithEmail,
  mnemonicError,
  resetSignIn,
} from "../../ducks/signin";
import { changeWallet } from "../../ducks/blockchain";
import { AddAccountWithCookie } from "../../actware/webServices";
import MultiAccountUtils from "../../utils/multiAccountUtils";

class NewSignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      captcha: false,
      catchErr: null
    };

    window.addEventListener("orientationchange", this.resetRecaptcha);
  }

  componentDidMount() {
    this.props.resetSignIn();
    const isNewLogin = StorageUtils.getItem("IsNewLogin") === "true";
    this.setState({ isNewLogin: isNewLogin });
    StorageUtils.removeItem("IsNewLogin");
  }

  componentWillUnmount() {
    this.props.resetSignIn();
    window.removeEventListener("orientationchange", this.resetRecaptcha);
  }

  resetRecaptcha = () => {
    if (window.grecaptcha && !this.state.captcha) window.grecaptcha.reset();
  };

  submitLoginEmail = async () => {
    try {
      const { email, password, captcha } = this.props.formValues;
      const hashPassword = sha512(password);

      await this.props.loginWithEmail({ email: email.toLowerCase(), password: hashPassword, captcha },
        window.grecaptcha && window.grecaptcha.reset
      );

      AddAccountWithCookie(email.toLowerCase(), password);

    } catch (err) {
      this.setState({ catchErr: err });

      if (err === "CAPTCHA_VERIFYING_ERROR") {
        this.resetRecaptcha();
      }
    }
  };

  checkMnemonic = async () => {
    try {
      const { email, password } = this.props.formValues;
      const { walletAddress, loginMnemonic } = this.props.signin;
      let trimedLoginMnemonic = [];
      loginMnemonic.value.forEach(element => {
        trimedLoginMnemonic.push(element.trim());
      });
      loginMnemonic.value = trimedLoginMnemonic;
      const mnemonicString = loginMnemonic.value.join(" ");
      const errorMnemonicString = !ValidationUtil.isValid(
        mnemonicString,
        RegExps.mnemonic
      );
      if (errorMnemonicString) {
        return this.props.mnemonicError(
          "Write down the correct secret phrase of 12 words."
        );
      }

      const wallet = await WalletUtils.accessWalletByMnemonic(mnemonicString);
      if (!wallet) {
        return this.props.mnemonicError("This string is not valid");
      }

      const newWalletAddress = wallet.address;

      if (!newWalletAddress) {
        return this.props.mnemonicError("This wallet not found");
      }

      const isConfirm = newWalletAddress === walletAddress;

      if (!isConfirm) {
        return this.props.mnemonicError(
          "This wallet does not match with your account wallet"
        );
      } else {
        const hashPassword = sha512(password);
        const encryptedKey = encryptDES(wallet.privateKey, hashPassword);
        const encryptedMnemonic = encryptDES(mnemonicString, hashPassword);

        MultiAccountUtils.addAccount(email, encryptedKey, encryptedMnemonic);
        
        this.props.changeWallet(wallet);
        this.props.resetSignIn();
        history.push(ROUTE_DEFAULT_LOGGEDIN);
      }
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  onChangeMnemonic = (value, index) => {
    try {
      return value.trim().split(" ").length === 12 ?
        this.props.changeWholeMnemonic(value) :
        this.props.changeLoginMnemonic(value, index);
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  onCaptchaChange = captcha => {
    try {
      this.props.dispatch(change("signin-new", "captcha", captcha));
      this.setState({ captcha });
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  expiredCallback = () => {
    try {
      this.props.dispatch(change("signin-new", "captcha", null));
      this.setState({ captcha: false });
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  render() {

    if (this.state.catchErr) throw this.state.catchErr;

    const {
      loginMnemonic,
      failed,
      error,
      submitLoading,
      activeStep
    } = this.props.signin;
    const { formValid } = this.props;
    const { mnemonicLoader } = this.state;
    return (
      <>
        {
          activeStep === 1 && (
            <Box>
              <SignInNewForm />
              <Box>
                {process.env.REACT_APP_RECAPTCHA_KEY &&
                  process.env.REACT_APP_RECAPTCHA_KEY !== "" ? (
                    <Recaptcha
                      sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                      callback={this.onCaptchaChange}
                      expiredCallback={this.expiredCallback}
                    />
                  ) : (
                    <Typography color="error">
                      {getError("CAPTCHA_CONFIGURE_ERROR")}
                    </Typography>
                  )}
              </Box>
              {failed && (
                  <div className="register__error" dangerouslySetInnerHTML={{ __html: error }}></div>
              )}
              <Button
                className="spaced-bottom"
                onClick={this.submitLoginEmail}
                fullWidth
                variant="contained"
                disabled={!formValid || !this.state.captcha}
                color="primary"
              >
                {!submitLoading ? "Login" : <CircularProgress size={23} />}
              </Button>
              <Link to={ROUTE_SIGNUP}>
                <Button fullWidth variant="contained" color="primary" className="spaced-bottom">
                  Create a new wallet
                </Button>
              </Link>
              <Link to={ROUTE_INDEX}>
                <Button fullWidth variant="contained" className="spaced-bottom">
                  Home
                </Button>
              </Link>
            </Box>
          )}

        {
          activeStep === 2 && (
            <Box>
              <Mnemonic
                editable
                fullEditable
                mnemonic={loginMnemonic.value}
                onChange={this.onChangeMnemonic}
              />
              <div className="register__error">{failed && `${error}`}</div>
              <Button
                className="spaced__btn"
                onClick={this.checkMnemonic}
                fullWidth
                variant="contained"
                color="primary"
              >
                {!mnemonicLoader ? "Submit" : <CircularProgress size={23} />}
              </Button>
            </Box>
          )}
      </>
    );
  }
}

const mapState2props = state => ({
  signin: state.signin,
  formValues: getFormValues("signin-new")(state),
  formValid: isValid("signin-new")(state)
});

const mapDispatch2props = dispatch => ({
  dispatch,
  changeLoginMnemonic: (value, index) =>
    dispatch(changeLoginMnemonic(value, index)),
  loginWithEmail: (values, errCallback) =>
    dispatch(loginWithEmail(values, errCallback)),
  mnemonicError: error => dispatch(mnemonicError(error)),
  resetSignIn: () => dispatch(resetSignIn()),
  changeWholeMnemonic: mnemonic => dispatch(changeWholeMnemonic(mnemonic)),
  changeWallet: wallet => dispatch(changeWallet(wallet))
});

export default connect(
  mapState2props,
  mapDispatch2props
)(NewSignIn);