export const RegExps = {
  numeric: /^(0\.[0-9]|[1-9]+[0-9]*(\.[0-9])?)[0-9]*$/,
  firstName: /^[a-zA-Z]{2,30}$/,
  lastName: /^[a-zA-Z]{2,30}$/,
  email: /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/,
  digits: /[0-9]+/,
  // digitsNotZero: /^[1-9][0-9]*$/,
  digitsNotZeo: /^[1-9]\d*(\.\d+)?$/,
  passwordLetters: /^[a-zA-Z]+$/,
  minLength8: /^.{8,}$/,
  mnemonic: /^([a-z]+ ){11}[a-z]+$/,
  specialCharacters: /[._{}!@#*()+:^;<>$%&]/,
  bannedSpecialCharacters: /[,'`№№]+/,
};