export class NormalizeUtil {
    /**
     * @name formatBigValues
     * @description formats big numbers from math form to usual string
     * @param {number} `x` number to be formatted
     * @return {string}
     */
    static formatBigValues = x => {
        if(!x) {
            return '';
        }
        if (Math.abs(x) < 1.0) {
            const e = parseInt(x.toString().split("e-")[1]);
            if (e) {
                x *= Math.pow(10, e - 1);
                x = "0." + (new Array(e)).join("0") + x.toString().substring(2);
            }
        } else {
            let e = parseInt(x.toString().split("+")[1]);
            if (e > 20) {
                e -= 20;
                x /= Math.pow(10, e);
                x += (new Array(e + 1)).join("0");
            }
        }
        return x;
    };
}

export const sanitizeInputNumber = (value = "") => {
    const validChars = value.match(/\d+\.?\d*/g);

    if (!validChars) return "";

    return validChars.reduce((nextNum, numPart) => {
        if (!nextNum) return numPart;
        const nextNumPartClean = numPart.replaceAll(".", "");
        return `${nextNum}${nextNumPartClean}`
    }, "");
}