import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    Typography
} from "@material-ui/core";
import { openLink } from "src/utils/linksOpenPolyfill";

const ModalBody = (props) => {
    const {
        transactionURl = "",
        transactionHash = "",
        onCloseClick = () => {},
    } = props;

    const handleOpenTransactionLink = (event) => {
        openLink(event, transactionURl)
    };

    return (
        <>
            <Typography gutterBottom>
                Transaction
                <a
                    color="primary"
                    href={transactionURl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleOpenTransactionLink}
                >
                    { transactionHash }
                </a>
                successfully created
            </Typography>
            <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={onCloseClick}
            >
                Close
            </Button>
        </>
    );
};

ModalBody.propTypes = {
    transactionURl: PropTypes.string,
    transactionHash: PropTypes.string,
    onCloseClick: PropTypes.func,
};

export default ModalBody;