import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Field } from 'redux-form'
import NXField from "../NXField";
import { required, number, minVal, maxVal } from "../../../utils/validation";

const AmountField = (props) => {
    const {
        minValue = 0,
        maxValue = 0,
        onChange = value => value,
        component = NXField
    } = props;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const validateMinValue = useCallback(minVal(minValue), [minValue]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const validateMaxValue = useCallback(maxVal(maxValue), [maxValue]);

    return (
        <Field
            type="number"
            name="amount"
            label="Amount"
            component={component}
            onChange={({ target }) => onChange(target.value)}
            validate={[required,number,validateMinValue,validateMaxValue]}
        />
    )
};

AmountField.prototype = {
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    onChange: PropTypes.func
}
export default AmountField;