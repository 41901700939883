import React from 'react'
import { Field, reduxForm } from 'redux-form'

import CustomInput from 'src/components/Forms/Field';
import { required, email } from 'src/utils/validation';

const ResetEmailForm = props => {

    const { handleSubmit } = props;

    return (
        <form onSubmit={handleSubmit}>
            <Field
                name="email"
                component={CustomInput}
                type="email"
                validate={[required, email]}
                label="Email"
                required
                autoComplete={"email"}
            />
        </form>
    )
}

export default reduxForm({
    form: 'reset-email-form'
})(ResetEmailForm)