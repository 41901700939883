import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { Grid, LinearProgress, TextField } from "@material-ui/core";

import {
	changeGasLimit,
	changeGasMaxPrice,
	changeMaxGasPriorityPrice
} from "../../ducks/blockchainGas";

let AdvancedGasLondon = props => {
	const { gasLoading, gasLimit, maxPriorityFeePerGas, maxFeePerGas } = props.blockchainGas;
	const { changeGasLimit, calculateFee, changeMaxGasPriorityPrice, changeGasMaxPrice } = props;
	
	const updateFee = () => {
		if(!maxPriorityFeePerGas.error && !maxFeePerGas.error && !gasLimit.error) {
			calculateFee(gasLimit.value, maxFeePerGas.value);
		}
	}

	return (
		<Grid item xs={12}>
		  { gasLoading && <LinearProgress />}
		  <TextField
		      label={"Miner Incentive (GWEI)"}
		      placeholder="0.0"
		      error={maxPriorityFeePerGas.error}
		      value={maxPriorityFeePerGas.value}
		      helperText={maxPriorityFeePerGas.helperText}
		      type="number"
		      onChange={(e) => {
		      	changeMaxGasPriorityPrice(e.target.value);
		      	updateFee();
		      }}
		      fullWidth
		      margin="normal"
		      variant="outlined"
		  />
		  <TextField 
		  		label={"Max Fee (GWEI)"}
		        placeholder={"0.0"}
		        value={maxFeePerGas.value}
		        error={maxFeePerGas.error}
			    helperText={maxFeePerGas.helperText}
			    type="number"
			    onChange={(e) => {
			    	changeGasMaxPrice(e.target.value)
			    	updateFee();
			    }}
			    fullWidth
			    margin="normal"
		        variant="outlined"
		    />
		  <TextField
		      label={"Limit (WEI)"}
		      maxLength={4}
		      error={gasLimit.error}
		      value={gasLimit.value}
		      helperText={gasLimit.helperText}
		      onChange={(e) => {
		        changeGasLimit(e.target.value);
		        updateFee();
		      }}
		      fullWidth
		      margin="normal"
		      variant="outlined"
		  />
		</Grid>
	);
}

const mapState2props = state => ({
    blockchainGas: state.blockchainGas,
});

const mapDispatch2props = {
	changeGasLimit,
	changeGasMaxPrice,
	changeMaxGasPriorityPrice
}

export default compose(connect(mapState2props, mapDispatch2props))(AdvancedGasLondon);