import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change, getFormValues } from 'redux-form'

import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import { FormGroup, FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import NXField from '../NXField';
import AmountField from "../AmountField";

import { IN4X_METHOD_WIRE, IN4X_ACTION_DEPOSIT, IN4X_METHOD_CARD } from '../../../constants/in4xConstants';
import { feeForAmount, minLimit, maxLimit } from '../../../utils/in4xUtils';

import { getTopupEthValues, FORM_TOPUP_GAS_TOKENS } from '../../../ducks/FormData';

class TopupEtherForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		    catchErr: null,
		    minAmount: 0,
		    maxAmount: 0
		};
	}

	componentDidMount = () => {
		const { limits, gasCur } = this.props;
		this.setState({
			minAmount: minLimit(limits, IN4X_ACTION_DEPOSIT, IN4X_METHOD_CARD, gasCur), 
			maxAmount: maxLimit(limits, IN4X_ACTION_DEPOSIT, IN4X_METHOD_CARD, gasCur)
		});
		
		const { prevAmount, isAdd } = this.props.formValues || {};
		if(isAdd) {
			this.props.dispatch(change(FORM_TOPUP_GAS_TOKENS, 'amount', 0));
			this.props.dispatch(change(FORM_TOPUP_GAS_TOKENS, 'receiveAmount', 0));
			this.props.dispatch(change(FORM_TOPUP_GAS_TOKENS, 'fee', 0));
			this.props.dispatch(change(FORM_TOPUP_GAS_TOKENS, 'total', prevAmount ));
		}
	}

	changedAmount = (amount) => {
		const { fees, rate, gasCur, formValues: { prevAmount }} = this.props;
		amount = Number(amount);
		if(amount <= 0) {
			this.props.dispatch(change(FORM_TOPUP_GAS_TOKENS, 'fee', 0));
			this.props.dispatch(change(FORM_TOPUP_GAS_TOKENS, 'total', prevAmount ));
			this.props.dispatch(change(FORM_TOPUP_GAS_TOKENS, 'receiveAmount', 0));
			return;
		}
		if(fees) {
			//We treat this a wire transfer fee wise
			const feeAmount = feeForAmount(fees, IN4X_ACTION_DEPOSIT, IN4X_METHOD_WIRE, gasCur, amount); 
			if(feeAmount >= 0) {
				this.props.dispatch(change(FORM_TOPUP_GAS_TOKENS, 'fee', feeAmount));
				this.props.dispatch(change(FORM_TOPUP_GAS_TOKENS, 'total', (prevAmount + amount + feeAmount)));
			}
		} else {
			this.props.dispatch(change(FORM_TOPUP_GAS_TOKENS, 'fee', 0));
			this.props.dispatch(change(FORM_TOPUP_GAS_TOKENS, 'total', (prevAmount + amount)));
		}
		const amountToken = (Number(rate) > 0 ? (amount * Number(rate)).toFixed(4) : 0);
		this.props.dispatch(change(FORM_TOPUP_GAS_TOKENS, 'receiveAmount', amountToken));
	}

	handleToggle = () => {
		const { prevAmount, isAdd } = this.props.formValues;
		this.props.dispatch(change(FORM_TOPUP_GAS_TOKENS, 'isAdd', !isAdd));
		if(isAdd) {
			this.props.dispatch(change(FORM_TOPUP_GAS_TOKENS, 'amount', 0));
			this.props.dispatch(change(FORM_TOPUP_GAS_TOKENS, 'receiveAmount', 0));
			this.props.dispatch(change(FORM_TOPUP_GAS_TOKENS, 'fee', 0));
			this.props.dispatch(change(FORM_TOPUP_GAS_TOKENS, 'total', prevAmount ));
		} else {
			this.props.dispatch(change(FORM_TOPUP_GAS_TOKENS, 'amount', ''));
		}
	}

	render() {
		const { catchErr, minAmount, maxAmount } = this.state;
		if (catchErr) throw this.state.catchErr;
		const { handleSubmit, rate, isLoading, payCur, gasCur } = this.props;
   
   		let { formValues } = this.props;
		if(!formValues) formValues = {}; //Findout why this is undefined on 1st load
		const enabled = formValues.isAdd;

    return (
    		<>
				<form name={FORM_TOPUP_GAS_TOKENS} onSubmit={handleSubmit}>
					<FormGroup>
					  <FormControlLabel control={
					  	<Checkbox
					  		name="isAdd"
					  		className="color-nx"
								onChange={this.handleToggle}
								inputProps={{ 'aria-label': 'controlled' }}
						/>} label="Add to order" />
					</FormGroup>
					{ !enabled && (
						<Alert severity="warning" align="left">It seems you do not have enough {gasCur.toUpperCase()} to make transactions (pay blockchain fees) We recommend adding at least enough for a few transations.</Alert>
					)}
				    { enabled && (
				    	<>
						    <TextField
						    	type="text" 
						    	value={payCur.toUpperCase()}
						    	name="cur_from"
						    	label="Pay Currency" 
						    	disabled
						    	style={{width: '50%'}}
						    	className="override-nx"
						    />
						    <TextField
						    	type="text" 
						    	value={gasCur.toUpperCase()}
						    	name="cur_to"
						    	label="Receive" 
						    	disabled
						    	style={{width: '50%'}}
						    	className="override-nx"
						    />
						    <Field 
						    	type="number" 
						    	component={NXField} 
						    	name="prevAmount"
						    	label="Original Total"
						    	disabled
						    	adornment={payCur.toUpperCase()}
						    />
						    <AmountField
									minValue={minAmount}
									maxValue={maxAmount}
									onChange={this.changedAmount}
								/>
						   	{ !isLoading && rate && (
						   		<Field
							    	type="number"
							    	component={NXField}
							    	name="receiveAmount"
							    	label="Receive (Estimate)"
							    	adornment={gasCur.toUpperCase()}
							    	disabled
							    />
						   	)}
						   	{ !isLoading && formValues.fee > 0 && (
						   		<Field
						   			component={NXField} 
						   			variant="outlined"
							    	type="text" 
							    	name="fee"
							    	label="Fee" 
							    	disabled
							    	adornment={payCur.toUpperCase()}
							    />
						   	)}
						   	{ !isLoading && formValues.total > 0 && (
						   		<Field
						   			component={NXField} 
							    	type="text" 
							    	variant="outlined"
							    	name="total"
							    	label="Total (To be Transferred)" 
							    	disabled
							    	adornment={payCur.toUpperCase()}
							    />
						   	)}
						   	{ !isLoading && (
						   		<Alert severity="warning" style={{textAlign: 'left', marginTop: '1.5rem'}}>Actual amount will be calculated at exchange time</Alert>
						   	)}
						</>
					)}
				</form>
			</>
		)
	}
}

TopupEtherForm = reduxForm({
    form: FORM_TOPUP_GAS_TOKENS,
    enableReinitialize: true,
    destroyOnUnmount: true
})(TopupEtherForm);


TopupEtherForm = connect(
  state => ({
    initialValues: state.FormData.TopupEth,
    formValues: getFormValues(FORM_TOPUP_GAS_TOKENS)(state), //linked here to hide fee + total if they're empty
  }),
  { load: getTopupEthValues }               // bind account loading action creator
)(TopupEtherForm);

export default TopupEtherForm;