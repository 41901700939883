import React, { useEffect, useState } from 'react'

import TransactionStatusBlock from '../../components/TransactionStatusBlock';

import { Container, Grid, Typography, CircularProgress, Button, Paper } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import moment from 'moment';

import ErrorAlert from "src/wallet-components/error-alert";
import {getSwapTransferByRef} from "src/wallet-views/crypto-swap/data-utils";
import {ROUTE_NX_TRANSACTION_HISTORY} from 'src/constants/routes';
import BNUtils from "src/utils/bnUtils";

const In4xSwapTransaction = props => {

	const {
		match, history
	} = props;

	const [swapTransaction, setSwapTransaction] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const ref = match?.params?.ref;
		if(!swapTransaction) {
			getSwapTransferByRef(ref).then((data) => {
				setSwapTransaction(data);
				setLoading(false);
			}).catch(() => {
				setError(true);
			});
		}
	});

	const goBack = () => {
		history.push(ROUTE_NX_TRANSACTION_HISTORY + 'swaps');
	}

	return (
		<Container>
			<Grid item xs={12} className="centered-grid spaced-top">
				<Typography align="center" className="color-nx" variant="h6">Swap Transaction</Typography>
				{ loading && !error &&  (
					<Grid item xs={12} className="centered-grid">
						<CircularProgress />
						<Typography className="color-nx" align="center" gutterBottom>Loading Swap Transaction...</Typography>
					</Grid>
				)}
				{ !loading && !error && swapTransaction && (
					<Accordion TransitionProps={{ unmountOnExit: true }} defaultExpanded>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography className="color-nx">Transaction Summary</Typography>
						</AccordionSummary>
						<AccordionDetails style={{padding: '0'}}>
							<TableContainer component={Paper}>
								<Table size="small" aria-label="a dense table">
									<TableBody>
										<TableRow key="td">
											<TableCell component="td" scope="row">IN4X Reference</TableCell>
											<TableCell align="right"><Typography fontSize="2" className="color-nx" style={{wordBreak: "break-all"}}>{swapTransaction.REFERENCE_ID}</Typography></TableCell>
										</TableRow>
										<TableRow key="am">
											<TableCell component="td" scope="row">Amount</TableCell>
											<TableCell align="right"><Typography fontSize="2" className="color-nx">{swapTransaction.PAY_CUR.toUpperCase() + ' ' + BNUtils.ensureDecimals(swapTransaction.AMOUNT, 3)}</Typography></TableCell>
										</TableRow>
										{ swapTransaction.TOPUP_CUR && swapTransaction.AMOUNT_TOKEN > 0 && (
											<TableRow key="at">
												<TableCell component="td" scope="row">Swap Amount</TableCell>
												<TableCell align="right"><Typography fontSize="2" className="color-nx">{swapTransaction.TOPUP_CUR.toUpperCase() + ' ' + BNUtils.ensureDecimals(swapTransaction.AMOUNT_TOKEN, 3)}</Typography></TableCell>
											</TableRow>
										)}
										{ swapTransaction.NX_FEE && swapTransaction.NX_FEE > 0 && (<TableRow key="pf">
												<TableCell component="th" scope="row">Fee</TableCell>
												<TableCell align="right"><Typography fontSize="2" className="color-nx">{swapTransaction.PAY_CUR.toUpperCase() + ' ' + BNUtils.ensureDecimals(swapTransaction.NX_FEE, 3)}</Typography></TableCell>
											</TableRow>
										)}
										<TableRow key="ps">
											<TableCell component="td" scope="row">Status</TableCell>
											<TableCell align="right"><Typography fontSize="2" className="color-nx">{swapTransaction.STATUS.toUpperCase()}</Typography></TableCell>
										</TableRow>
										<TableRow key="ts">
											<TableCell component="td" scope="row">Time</TableCell>
											<TableCell align="right"><Typography fontSize="2" className="color-nx">{swapTransaction.CREATED_AT ? moment.unix(swapTransaction.CREATED_AT).format("hh:mm A DD-MM-YYYY") : '-'}</Typography></TableCell>
										</TableRow>
									</TableBody>
								</Table>
								{ swapTransaction.TRANSACTION_HASH && swapTransaction.TRANSACTION_HASH.length > 0 && (
									<TransactionStatusBlock hash={swapTransaction.TRANSACTION_HASH}/>
								)}
							</TableContainer>
						</AccordionDetails>
					</Accordion>
				)}
				{ error && (<ErrorAlert error="Invalid Swap Transfer, please try again" />)}
				<Grid item xs={12} className="centered-grid spaced-top">
					<Button onClick={goBack} fullWidth variant="contained" color="default" >Back</Button>
				</Grid>
			</Grid>
		</Container>
	)
};

export default In4xSwapTransaction;