import React, { useEffect, useState } from 'react'
import {compose} from "redux";
import {connect} from "react-redux";
import {Typography} from "@material-ui/core";
import SendTokens from "src/components/SendTokens";
import {createSwapTransfer} from "src/wallet-views/crypto-swap/data-utils";
import {transformError} from "../../../utils/transformErrors";
import Alert from "@material-ui/lab/Alert";

let CryptoSwapTransaction = (props) => {

    const {
        in4x,
        storedSwapTransactionData,
        onStoreSwapTransactionData = () => {},
        onPrevClick = () => {},
        onDisabledNext = val => val,
    } = props;

    const [createTransactionError, setCreateTransactionError] = useState(null);
    const [receiveCurrency, setReceiveCurrency] = useState(null);

    useEffect(() => {
        const recCur = in4x?.activeCurrency?.name;
        setReceiveCurrency(recCur);
        onDisabledNext(true);
    }, [in4x, setReceiveCurrency, onDisabledNext]);

    const transactionSuccess = (trxHash) => {
        if(storedSwapTransactionData && trxHash) {
            createSwapTransfer({...storedSwapTransactionData, ...{payTransactionHash: trxHash}})
                .then((data) => {
                    onStoreSwapTransactionData({...storedSwapTransactionData, ...{successData: data}});
                    onDisabledNext(false);
                }).catch((err) => {
                    if (err) {
                        setCreateTransactionError(transformError(err));
                    }
            });
        }
    }

    const transactionFail = () => {
        // TODO: TBD
    }

    return (
        <>
            { receiveCurrency && (
                <Typography
                    variant="h5"
                    align="center"
                    gutterBottom
                    className="color-nx">
                    Buy {receiveCurrency.toUpperCase()}
                </Typography>
            )}
            <SendTokens
                hideBack
                disabled
                swap
                onTransactionSuccess={(trxHash) => { transactionSuccess (trxHash); }}
                onTransactionFail={transactionFail}
                onTransactionBack={onPrevClick}
            />
            { createTransactionError && (
                <Alert variant="outlined" severity="error">
                    { createTransactionError }
                </Alert>
            )}
        </>
    )
};

const mapState2props = state => ({
    in4x: state.in4x,
    blockchain: state.blockchain
});

const mapDispatch2props = {
};

export default compose(
    connect(mapState2props, mapDispatch2props)
)(CryptoSwapTransaction);

