import React, { useState } from "react";
import { Grid, Typography } from '@material-ui/core';

import DropdownSelect from '../DropdownSelect';

let LocationCurrencyPicker = ({location,currency,currencies,locations,onLocationChange,onCurrencyChange}) => {

	const [currencySelectDisabled, setCurrencySelectDisabled] = useState(true);

	const locationChange = (value) => {
		onLocationChange(value);
		// Disable / clean currency dropdown when location is empty
		setCurrencySelectDisabled(!value);
		if(!value) {
			onCurrencyChange('');
		}
	}

	return (
		<>
			<Grid item xs={12} className="centered-grid spaced-top">
				<Typography variant="subtitle2" className="color-nx">Please choose your location:</Typography>
		   		<DropdownSelect id="location" name="location" value={location} options={locations} onChange={e => locationChange(e.target.value)} useKV label="Location" />
	   		</Grid>
	   		<Grid item xs={12} className="centered-grid spaced-top">
				<Typography variant="subtitle2" className="color-nx">Please choose your pay currency:</Typography>
	   			<DropdownSelect id="payCur" name="payCur" value={currency} options={currencies} onChange={e => onCurrencyChange(e.target.value)} useKV label="Currency" disabled={currencySelectDisabled} />
	   		</Grid>
	   	</>
	);
}

export default LocationCurrencyPicker;