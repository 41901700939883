import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from 'redux-form'
import { Button, Input, Typography } from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import CustomInput from '../../Forms/Field';
import NXCustomInput from '../../Forms/NXField';
import {required} from "../../../utils/validation";

import { getMakePaymentValues } from "../../../ducks/FormData";

class PaymentForm extends React.Component {
	state = {
	    catchErr: null,
	    receiveAmount: 0,
	    isAllowed: false,
	    updatedAmount: 0,
	    isTouched: false,
	};

	componentWillUnmount() {
		const { amount, balance } = this.props;
		if(amount > balance) {
    		this.setState({catchErr: 'Insufficient balance', isAllowed: false});
    	} else {
    		this.setState({catchErr: null, isAllowed: true});
    	}
	}

	render() {    	
    	const { catchErr, receiveAmount, isAllowed } = this.state;
			const { handleSubmit, handleCancel, 
				base, quote, rate, isLoading, 
				partner, requestId,
				hideButtons
			} = this.props;
			let { formValues } = this.props;
			if(!formValues) formValues = {};
    	return (
    		<>
    			{ catchErr && (
   		           <Alert variant="outlined" severity="error" onClose={() => { this.setState({catchErr: null}); }}>{catchErr}</Alert>
        	    )}
				<form onSubmit={handleSubmit}>
				    <Input
		                name="partner"
		                component={CustomInput}
		                type="hidden"
		                validate={[required]}
		                label="Partner"
		                value={partner}
		                disabled
		            />
		            <Input
		                name="requestId"
		                component={CustomInput}
		                type="hidden"
		                validate={[required]}
		                label="RequestID"
		                value={requestId}
		                disabled
		            />
		            <TextField
				    	type="text" 
				    	value={base}
				    	name="cur_from"
				    	label="Pay Cur." 
				    	disabled
				    	style={{width: '33%'}}
				    />
				    <TextField
				    	type="text" 
				    	value={rate}
				    	name="rate"
				    	label="Rate" 
				    	disabled
				    	style={{width: '33%'}}
				    />
				    <TextField
				    	type="text" 
				    	value={quote}
				    	name="cur_to"
				    	label="Order Cur." 
				    	disabled
				    	style={{width: '33%'}}
				    />
				    <Field 
				    	type="number" 
				    	component={NXCustomInput} 
				    	name="amount"
				    	label="Amount To Be Paid"
              disabled
             	adornment={base}
				    />
				    { !!formValues.fee && (
					    <Field 
					    	type="number" 
					    	component={NXCustomInput} 
					    	name="fee"
					    	label="IN4X Fee"
			          disabled
			          adornment={base}
					    />
				    )}
				     { !!formValues.total && (
					    <Field 
					    	type="number" 
					    	component={NXCustomInput} 
					    	name="total"
					    	label="Total"
			          disabled
			          adornment={base}
					    />
				    )}
				    { isLoading && (
				    	<>
					    	<CircularProgress className="spaced-top"/>
					    	<Typography className="color-nx" gutterBottom>Loading rate...</Typography>
				    	</>
				   	)}
				    { !hideButtons && (
				    	<>
				    	<Button color="primary" variant="contained" fullWidth onClick={handleSubmit} 
				    	disabled={isLoading || receiveAmount <= 0 || !isAllowed} className="spaced-top">Continue</Button>
				    	<Button variant="contained" fullWidth onClick={handleCancel} className="spaced-top">Cancel</Button>
				    	</>
				    )}				    
				</form>
			</>
		)
	}
}

PaymentForm = reduxForm({
    form: 'payment-form',
    enableReinitialize: true,
})(PaymentForm);

PaymentForm = connect(
  state => ({
    initialValues: state.FormData.MakePayment,
    formValues: getFormValues('payment-form')(state),
  }),
  { load: getMakePaymentValues }               // bind account loading action creator
)(PaymentForm);

export default PaymentForm;