/* eslint-disable no-throw-literal */
import {HTTP_GET} from "../constants/method";
import {serialize} from "cookie";
import {stringify} from "query-string";

require('es6-promise').polyfill();
require('isomorphic-fetch');

/**
 * @name handleFetch
 * @description handles async requests sending
 * @param {string} `url` target url
 * @param {string} `method` "POST" / "GET" / "PUT" / "DELETE"
 * @param {object} `body` body (or query for "GET" requests)
 * @param {boolean} isAbsolute Flag for absolute URLs
 * @param {object} `cookies`
 * @return {Promise<any | never>} server-side response
 */
export const handleFetch = (url, method, body, isAbsolute = false, cookies = null, headersOverride = null) => {
    const urlPostfix = (method === HTTP_GET) && body ? `?${stringify(body)}` : "";
    let URI;
    if(!isAbsolute) {
        URI = process.env.NODE_ENV === "development" ? `http://localhost:${process.env.REACT_APP_PORT}/api` : process.env.REACT_APP_API_URL;
    } else {
        URI = ''; //Use url as is
    }
    let headers = {
        'Content-Type': 'application/json',
        'Iown-Api-Key': process.env.REACT_APP_API_KEY || ''
    };

    if (cookies) {
        headers['Cookie'] = Object.keys(cookies).map(i => serialize(i, cookies[i]) + ";").join(" ");
    }
    if (headersOverride) {
        headers = headersOverride;
    }

    if (window.cordova) {
        window.cordova.plugin.http.setDataSerializer('json');

        const request = (url, options) => {
            return Promise.race([
                    new Promise((resolve, reject) =>
                    window.cordova.plugin.http.sendRequest(url, options, function (response) {
                        try {
                            return resolve(JSON.parse(response.data));
                        } catch (error) {
                            return error
                        }
                    }, function (response) {
                        if(response.status === 503) throw 'SERVER_DOWN_ERROR';
                        if (response.status >= 500) throw 'SERVER_CONNECTION_FAILED';
                        if (response.status === 404) return reject('SERVER_CONFIGURATION_FAILED');
                        return resolve(JSON.parse(response.error));
                    })
                ),
                new Promise(((_resolve, reject) => {
                    setTimeout(() => reject('SERVER_CONNECTION_TIMEOUT'), 10000);
                })),
            ]);
        };
        return request(`${URI}${url}${urlPostfix}`, {
            method,
            headers,
            credentials: 'include',
            data: body,
        });
    } else {
        return fetch(`${URI}${url}${urlPostfix}`, {
            method,
            headers,
            credentials: 'include',
            body: method !== HTTP_GET ? JSON.stringify(body) : undefined,
        }).then(res => {
            if(res.status === 503 || res.status === 504) throw 'SERVER_DOWN_ERROR';
            if (!res.ok && res.status >= 500) throw 'SERVER_CONNECTION_FAILED';
            if (!res.ok && res.status === 404) throw 'SERVER_CONFIGURATION_FAILED';
            else return res.json();
        });
    }
};
