import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux';
import { getFormValues, isValid } from 'redux-form';

import Recaptcha from 'react-grecaptcha';
import ResetEmailForm from './ResetEmailForm';
import { sendEmailForRecovery } from "../data-utils";

import { getError } from 'src/utils/transformErrors';

import IownIcon from 'src/components/IownIcon';
import { Container, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert } from "@material-ui/lab";

import { ROUTE_SIGNIN_CLEAR_DATA } from "../../../constants/routes";

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

const ResetPassword = props => {

    const [captcha, setCaptcha] = useState(null);

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [submitLoading, setSubmitLoading] = useState(false);
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        if (error && formValid) {
            setDisableButton(false);
            setError('');
            setCaptcha(null);
            window.grecaptcha.reset();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.formValues])

    const handleSubmit = () => {
        const { email } = props.formValues;
        setError('');
        setSuccess('');
        setSubmitLoading(true);
        sendEmailForRecovery(email, captcha).then((data) => {
            setSuccess('Email with recovery link sent to your mail');
            setCaptcha(null);
            setDisableButton(true);
            setTimeout(handleBack, 2000);
        }).catch((error) => {
            const errorMgs = error && error.toString().includes('Failed to fetch') ? 'Network error, please try again later' : getError(error);
            setError(errorMgs);
            setDisableButton(true);
        }).finally(() => setSubmitLoading(false));
    }

    const handleBack = () => {
        props.history.push(ROUTE_SIGNIN_CLEAR_DATA);
    }

    const onCaptchaChange = captcha => {
        captcha && setCaptcha(captcha);
    };

    const expiredCallback = () => {
    };

    const { formValid } = props;
    return (
        <Container className="home" component="main" maxWidth="sm">
            <IownIcon />
            <Typography variant='h5' align='center' gutterBottom>
                Reset Password
            </Typography>
            <div className="register__error" >
                <div>You are about to reset your password, which means that you will need to relogin with your email and new password and the “secret phrases”.</div>
                <br/>
                <div>Please make sure you have your secret phrases backed up.</div>
            </div>
            <ResetEmailForm />
            <Box className='spaced-top-s'>
                { RECAPTCHA_KEY && RECAPTCHA_KEY !== '' ? (
                    <Recaptcha
                        sitekey={RECAPTCHA_KEY}
                        callback={onCaptchaChange}
                        expiredCallback={expiredCallback}
                    />
                ) : (
                    <Typography color='error'>
                        { getError('CAPTCHA_CONFIGURE_ERROR') }
                    </Typography>
                )}
            </Box>
            <Grid item xs={12} className='centered-grid'>
                {error && (
                    <Alert severity='error' className='spaced-top'>{error}</Alert>
                )}
                {success && (
                    <Alert severity='success' className='spaced-top'>{success}</Alert>
                )}
            </Grid>
            <Button
                className='spaced__btn'
                onClick={ handleSubmit }
                fullWidth
                variant='contained'
                disabled={ !formValid || !captcha || disableButton }
                color='primary'
            >
                { !submitLoading ? 'Send' : <CircularProgress size={23} /> }
            </Button>
            <Button
                className='spaced-top-s'
                onClick={ handleBack }
                fullWidth
                variant="contained"
                color="default"
            >
                Back
            </Button>
        </Container>
    )
};

const mapState2props = state => ({
    formValues: getFormValues('reset-email-form')(state),
    formValid: isValid('reset-email-form')(state)
});

const mapDispatch2props = dispatch => ({
});

export default connect(
    mapState2props,
    mapDispatch2props
)(ResetPassword);


