import { handleFetch } from "../utils/fetch";
import { performResult } from "../utils/stateManipulator";
import { transformError } from "../utils/transformErrors";

import CacheUtil from "../utils/cacheUtil";
import { API_IOD_IDENTITY, API_IOD_OTP, API_IOD_LINK } from "../constants/apiRoutes";

const initialState = {
	failed: false,
	token: {},
	userName: '',
	otp: null,
	id: null
};

const cachePrimary = 'iod';

const IOD_ID_REQ = "IOD_ID_REQ";
const IOD_ID_SUCCESS = "IOD_ID_SUCCESS";
const IOD_ID_FAIL = "IOD_ID_FAIL";
const PBI_TOKEN_REQ = "PBI_TOKEN_REQ";
const PBI_TOKEN_SUCCESS = "PBI_TOKEN_SUCCESS";
const PBI_TOKEN_FAIL = "PBI_TOKEN_FAIL";

const IOD_OTP_SET = "IOD_OTP_SET";
const IOD_OTP_REQ = "IOD_OTP_REQ";
const IOD_OTP_SUCCESS = "IOD_OTP_SUCCESS";
const IOD_OTP_FAIL = "IOD_OTP_FAIL";

const IOD_LINK_WALLET_REQ = "IOD_LINK_WALLET_REQ";
const IOD_LINK_WALLET_SUCCESS = "IOD_LINK_WALLET_SUCCESS";
const IOD_LINK_WALLET_FAIL = "IOD_LINK_WALLET_FAIL";

const PBI_URL = 'https://iowndigital-pbi-api.azurewebsites.net/embedinfo/';

export const getIdentity = () => dispatch => {
	const cacheKey = 'pbi-id';
	let identity = CacheUtil.getCache(cachePrimary, cacheKey);
	if(identity) {
		return dispatch({ type: IOD_ID_SUCCESS, data: identity });
	}
	dispatch({ type: IOD_ID_REQ });
	return handleFetch(API_IOD_IDENTITY, "GET", null, false, null, false) 
		.then(res => performResult(res, () => {
			CacheUtil.setCache(cachePrimary, cacheKey, res.payload);
			return dispatch({ type: IOD_ID_SUCCESS, data: res.payload });
		 }))
		.catch(err => dispatch({ type: IOD_ID_FAIL, err: err}));
}

export const getToken = (userName, force) => dispatch => {
	const cacheKey = 'pbi-token';
	let cached = CacheUtil.getCache(cachePrimary, cacheKey);
	if(!force && cached) {		
		let expDate = (cached && cached.EmbedToken && cached.EmbedToken.Expiration? new Date(cached.EmbedToken.Expiration) : null);
		let now = new Date();
		if(expDate.getTime() > now.getTime()) {
			return dispatch({ type: PBI_TOKEN_SUCCESS, data: cached });
		}
	}
	dispatch({ type: PBI_TOKEN_REQ });
	return handleFetch(PBI_URL + userName, "GET", null, false, null, true) //userName
        .then(res => {
        	CacheUtil.setCache(cachePrimary, cacheKey, res);
        	const modRes = { success: true, payload: res};
	        return performResult(modRes, () => {	        	
	            return dispatch({ type: PBI_TOKEN_SUCCESS, data: res });
	        });
        })
        .catch(err => dispatch({ type: PBI_TOKEN_FAIL, err: err}));
}

export const changeIodOTP = (otp) => dispatch=> {
	dispatch({type: IOD_OTP_SET, otp});
};

export const requestToken = (userName) => dispatch => {
    dispatch({ type: PBI_TOKEN_REQ });
    return handleFetch(PBI_URL+userName, "GET", null, false, null, true)
        .then(res => performResult(res, () => {
            dispatch({ type: PBI_TOKEN_SUCCESS, data: res.payload });
        }))
        .catch(err => dispatch({ type: PBI_TOKEN_FAIL, err: transformError(err)}));
};

export const importOTPValidate = data => dispatch => {
	dispatch({ type: IOD_OTP_REQ });
	return handleFetch(API_IOD_OTP, "POST", data)
		.then(res => performResult(res, () => {
		  dispatch({ type: IOD_OTP_SUCCESS, data: res.payload });
	}))
		.catch(err => dispatch({ type: IOD_OTP_FAIL, err: transformError(err) }));
};

export const linkWallet = data => dispatch => {
	dispatch({ type: IOD_LINK_WALLET_REQ });
	return handleFetch(API_IOD_LINK, "POST", data)
		.then(res => performResult(res, () => {
		  dispatch({ type: IOD_LINK_WALLET_SUCCESS, data: res.payload });
	}))
		.catch(err => dispatch({ type: IOD_LINK_WALLET_FAIL, err: transformError(err) }));
};


export const iod = (state = initialState, action) => {
	switch (action.type) {
		case IOD_ID_REQ: 
			return	{...state, iodId: null, isLoading: true };
		case IOD_ID_SUCCESS:
			return { ...state, iodId: action.data, isLoading: false };
		case IOD_ID_FAIL:
			return { ...state, iodError: action.err, isLoading: false };
		case PBI_TOKEN_REQ: 
			return { ...state, token: null, isLoading: true };
		case PBI_TOKEN_SUCCESS:
			return { ...state, token: action.data, isLoading: false };
		case PBI_TOKEN_FAIL:
			return { ...state, tokenError: action.err, isLoading: false };
		case IOD_OTP_REQ:
			return { ...state, id:false, isLoading: true};
		case IOD_OTP_SUCCESS:
			return { ...state, id: action.data, isLoading: false};
		case IOD_OTP_SET:
			return { ...state, otp: action.otp.toString()};
		case IOD_LINK_WALLET_REQ:
			return { ...state, isLoading: true};
		case IOD_LINK_WALLET_SUCCESS:
			return {...state, linkStatus: action.data, isLoading: false};
		case IOD_LINK_WALLET_FAIL:
			return {...state, iodError: action.err, otp: null, isLoading: false};
		default:
            return state;
	}
};
