import React from "react";
import {connect} from "react-redux";
import {getFormValues, isDirty, isValid} from 'redux-form';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import EditAccountForm from "../../components/Forms/EditAccount";
import IownIcon from "src/components/IownIcon";
import history from '../../history';
import CircularProgress from '@material-ui/core/CircularProgress';
import {editUserInfo, resetEdit} from "../../ducks/dashboard";

class EditAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            catchErr: null,
        };
        this.props.resetEdit();
    }

    submit = () => {
        try {
            const {fullName, phone} = this.props.formValues;
            this.props.editUserInfo({fullName, phone});
        } catch (err) {
            this.setState({catchErr: err});
        }
    };

    goBack = () => {
        try {
            history.goBack();
        } catch (err) {
            this.setState({catchErr: err});
        }
    };

    render() {
        if (this.state.catchErr) throw this.state.catchErr;

        const {formValid, formDirty} = this.props;
        const {
            userInfo: {
                phone, fullName
            }, userInfoLoaded, editUserInfoLoaded, editUserInfoError, editUserInfoSuccess
        } = this.props.dashboard;

        return (
            <Container component="main" maxWidth="sm" className="spaced-top">
                <Grid container spacing={1} justifyContent="center" alignItems="center" direction="column">
                    <Grid item xs={12}>
                        <IownIcon />
                        <Typography variant="h5" align="center" gutterBottom color="primary">Edit Account Info</Typography>

                        {userInfoLoaded ? <CircularProgress/> : <EditAccountForm initialValues={{fullName, phone}}/>}

                        { editUserInfoError && (
                            <div className="register__error" dangerouslySetInnerHTML={{ __html: editUserInfoError }}></div>
                        )}

                        { editUserInfoSuccess && (
                            <div className="register__success" dangerouslySetInnerHTML={{ __html: editUserInfoSuccess }}></div>
                        )}

                        <Button className="spaced__btn" fullWidth onClick={this.submit} variant="contained"
                                disabled={!formValid || !formDirty} color="primary">
                            {!editUserInfoLoaded ? 'Save' : <CircularProgress size={22}/>}
                        </Button>

                        <Box pt={2}>
                            <Button onClick={this.goBack} fullWidth variant="contained">
                                Back
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        );
    }
};

const mapState2props = state => ({
    dashboard: state.dashboard,
    formValues: getFormValues('edit-account')(state),
    formValid: isValid('edit-account')(state),
    formDirty: isDirty('edit-account')(state)
});

const mapDispatch2props = {
    editUserInfo,
    resetEdit
};

export default connect(mapState2props, mapDispatch2props)(EditAccount);
