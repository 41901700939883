import React from "react";
import QRCode from "qrcode.react";
import {connect} from "react-redux";
import {compose} from "redux";
import {withStyles} from "@material-ui/core";
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CopyIcon from '@material-ui/icons/FileCopy';
import CustomSnackbar from "../../components/CustomSnackbar";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ROUTE_DEFAULT_LOGGEDIN } from "../../constants/routes";
import GtagUtil from "../../utils/gtagUtils";

const styles = () => ({
    qrcode: {
        height: 'auto !important',
        width: '100% !important',
        maxWidth: '250px',
    }
});

class ShowAddress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openAlert: false,
            catchErr: null,
        }
        this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    }

    componentDidMount = () => {
        const {
            wallet: {
                address = "randomaddress"
            }
        } = this.props.blockchain;
        GtagUtil.trackShare('Wallet', 'image', address);
    }

    copy = () => {
        this.setState({
            openAlert: true
        })
    }

    handleCloseAlert = () => {
        try {
            this.setState({openAlert: false});
        } catch (err) {
            this.setState({catchErr: err});
        }
    };

    handleBackButtonClick() {    
       this.props.history.push(ROUTE_DEFAULT_LOGGEDIN);
    };

    render() {
        if (this.state.catchErr) throw this.state.catchErr;

        const {
            wallet: {
                address = "randomaddress"
            }
        } = this.props.blockchain;
        const {openAlert} = this.state;
        return (
            <Container className="home" component="main" maxWidth="sm">
                <Typography variant="h5" align="center" gutterBottom>
                    Your iOWN Wallet
                </Typography>
                <Box align="center" pt={5}>
                    <QRCode value={address} size={200} className={this.props.classes.qrcode}/>
                </Box>
                <span className="copy__code">
                    <div className="copy__code__text micro-text">
                        {address}
                    </div>
                    <CopyToClipboard text={address} onCopy={this.copy}>
                     <CopyIcon/>
                    </CopyToClipboard>
                </span>
                <Button type="submit" fullWidth variant="contained" color="primary" onClick={this.handleBackButtonClick}>Back</Button>
                <CustomSnackbar
                    anchorOrigin={{horizontal: "center", vertical: "bottom"}}
                    variant="success"
                    open={openAlert}
                    message={"Copied to clipboard"}
                    onClose={this.handleCloseAlert}
                    autoHideDuration={6000}
                />
            </Container>
        );
    }
}

const mapState2props = state => ({
    blockchain: state.blockchain
});

export default compose(
    withStyles(styles),
    connect(mapState2props)
)(ShowAddress);
