import React, {useEffect, useState} from 'react'
import {compose} from "redux";
import {connect} from "react-redux";
import BalanceBlock from "src/components/BalanceBlock";
import ProcessingAlert from "src/common/processing-alert";
import {getCurrencyIcon} from "src/constants/currencies";
import {changeSendingCurrency} from "src/ducks/blockchain";
import { Typography, Grid } from "@material-ui/core";

const CryptoSwapBalances = (props) => {

    const {
        dashboard, blockchain, in4x,
        onNextClick = () => {},
        changeSendingCurrency = () => {},
    } = props;

    const [activeCurrency, setActiveCurrency] = useState(null);

    useEffect(() => {
        if(in4x?.activeCurrency) {
            setActiveCurrency(in4x?.activeCurrency);
        }
    },[in4x, setActiveCurrency]);

    const getBalance = cur => {
        const {balance} = blockchain;
        const chain = activeCurrency.blockchain;
        return cur && balance
            && balance[chain][cur] >= 0
                ? balance[chain][cur]
                : '?';
    }

    const getIcon = cur => {
        return getCurrencyIcon(cur);
    }

    const getLabel = cur => {
        return cur?.toUpperCase();
    }

    const handleClick = cur => {
        const curObj = getCurrencyData(cur);
        changeSendingCurrency(curObj.name, curObj);
        onNextClick();
    }

    const getCurrencyData = cur => {
        const chain = activeCurrency.blockchain;
        const currency = dashboard?.userAssetTypes?.filter(c => {
            return c.blockchain === chain && c.name === cur;
        })
        return currency[0];
    }

    const { sendingCurrency } = props.blockchain;
    return (
        <>
            <Grid item xs={12}>
                <Grid item xs={12}>
                    <Typography variant="h5" align="center" gutterBottom className="color-nx">Buy {sendingCurrency.toUpperCase()}</Typography>
                    <Typography align="center" gutterBottom className="color-nx spaced-top-s">Choose from currency</Typography>
                </Grid>
                { !activeCurrency && (<ProcessingAlert message={"Loading Crypto Swap Balances..."} />) }
                { activeCurrency?.swapCurrencies?.length > 0 && (
                    activeCurrency?.swapCurrencies?.map((item, key) => {
                        const balance = getBalance(item)
                        if (balance >= 0) {
                            return <BalanceBlock
                                balance={balance}
                                label={getLabel(item)}
                                icon={getIcon(item)}
                                onClick={() => handleClick(item)}
                                key={key}
                            />
                        }

                        return null;
                    }))
                }
            </Grid>
        </>
    );
};

const mapState2props = state => ({
    in4x: state.in4x,
    blockchain: state.blockchain,
    dashboard: state.dashboard
});

const mapDispatch2props = {
    changeSendingCurrency
};

export default compose(
    connect(mapState2props, mapDispatch2props)
)(CryptoSwapBalances);