import React from "react";
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

import { getSafeArea } from "../../utils/appUtils";

class SAMobileStepper extends React.Component {
	
	constructor(props) {
    	super(props);
    	this.state = {
    		safeArea: null,
    	}
    }

	componentWillMount = () => {
	    getSafeArea().then((sa) => {
	      this.setState({safeArea: sa});
	    });
	}

	render() {
		const { safeArea } = this.state;
		const { maxSteps, activeStep, onNext, onPrev, disabledNext, disabledPrev, nextButtonText, backButtonText } = this.props;
		const nbt = nextButtonText || (activeStep>=(maxSteps-1) && ("Done")) || ("Next");
		const bbt = backButtonText || "Back";

		return (
			<MobileStepper
		        variant="progress"
		        steps={maxSteps}
		        position="bottom"
		        activeStep={activeStep}
		        className="color-nx"
		        nextButton={
		            <Button size="small" onClick={onNext} disabled={disabledNext} >
		              {nbt} <KeyboardArrowRight/>
		            </Button>
		        }
		        backButton={
		            <Button size="small" onClick={onPrev} disabled={disabledPrev}><KeyboardArrowLeft/>{bbt}</Button>
		        }
		        style={(safeArea && safeArea.bottom? {paddingBottom: safeArea.bottom+'px'}:{})}
		    />
		);
	}
}

export default SAMobileStepper;