/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useState,
    useRef,
    useEffect,
    useCallback,
} from "react";
import PropTypes from "prop-types";
import {
    Grid,
    Typography,
    Slider,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Container
} from "@material-ui/core";
import GasUtils from "src/utils/blockchain/GasUtils";
import AdvancedGas from "./components/advanced-gas";
import ProcessingAlert from "src/common/processing-alert";
import {
    gasSpeedSliderConfig,
    londonFields,
    legacyGasFields
} from "./constants";
import {
    pollGas,
    getGasSpeedSliderNumeric,
    getGasFee,
} from "./utils";

const FeeConfiguration = (props) => {
    const {
        isRefreshing = false,
        gasDataRef = { current: {} },
        defaultGasLimit = 0,
    } = props;
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [isGasLoading, setIsGasLoading] = useState(false);
    const [gasFee, setGasFee] = useState(0);
    const [oracleGasForecastData, setOracleGasForecastData] = useState();
    const [gasSpeed, setGasSpeed] = useState("");
    const gasConfig = useRef({ pollId: undefined, gasSpeed: 0 });
    const gasManualData = useRef({ gasLimit: Number(defaultGasLimit) });

    const updateGasFee = useCallback(() => {
        const fee = getGasFee({
            estimatedLimit: defaultGasLimit,
            oracleGasForecastData,
            gasManualData: gasManualData.current,
        });

        setGasFee(fee);
    }, [defaultGasLimit, oracleGasForecastData]);

    const updateParentRef = useCallback((data = {}) => {
        gasDataRef.current = {
            ...gasDataRef.current,
            ...gasManualData.current,
            ...data,
        }
    }, [gasDataRef]);

    const handleStartPolling = () => {
        gasConfig.current.pollId = pollGas({
            updateGasLoading: () => setIsGasLoading(prevState => !prevState),
            onUpdate: (data, gasDefault) => {
                setOracleGasForecastData({
                    ...data,
                });
                updateParentRef(gasDefault);
            },
        });
    };

    const handleStopPolling = () => {
        const { pollId } = gasConfig.current;

        if (!pollId) return;

        pollGas({ id: pollId });
    };

    const handleSpeedChange = (...params) => {
        handleStopPolling();
        const [,value] = params;
        const gasSpeedKey = getGasSpeedSliderNumeric({
            gasSpeed: value,
            revers: true,
        });
        handleStartPolling()
        const nextSpeedParams = oracleGasForecastData[gasSpeedKey];
        gasConfig.current.gasSpeed = value;
        gasManualData.current['gasPrice'] = nextSpeedParams?.gasPrice;
        setGasSpeed(gasSpeedKey);
        updateParentRef(nextSpeedParams);
        updateGasFee();
    };

    const toggleShowAdvanced = (...params) => {
        const [,checked] = params;
        setShowAdvanced(checked);
    };

    const handleGasAdvancedChange = (advancedConfig) => {
        gasManualData.current = {
            ...gasManualData.current,
            ...advancedConfig,
        };
        updateGasFee();
        updateParentRef();
        handleStopPolling();
    };

    useEffect(() => {
        handleStartPolling();
    }, []);

    useEffect(() => {
        updateParentRef({
            gasLimit: defaultGasLimit,
        })
        updateGasFee()
    }, [defaultGasLimit, updateGasFee, updateParentRef])

    const advancedGasFields = oracleGasForecastData?.gasType < 4 ? londonFields : legacyGasFields;
    const defaultSpeedSliderNumeric = getGasSpeedSliderNumeric({
        gasSpeed: oracleGasForecastData?.defaultSpeed
    });
    const gasUnits = GasUtils.gasUnitsForBlockchain().toUpperCase();

    return (
        <Container>
            {!oracleGasForecastData && (
                <ProcessingAlert message={'Preparing transaction fee...'} />
            )}
            {!!oracleGasForecastData && isRefreshing && (
                <ProcessingAlert message={'Refreshing transaction fee...'} />
            )}
            <Grid
                item
                xs={12}
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="subtitle2" style={{ maxWidth: "50%" }}>
                    Estimate
                    fee: {gasFee} {gasUnits}
                </Typography>
                <Typography variant="subtitle2">
                    <FormGroup aria-label="position" row className="checkbox-full">
                        <FormControlLabel
                            value="showGasDetails"
                            control={
                                <Checkbox
                                    color="primary"
                                    onChange={toggleShowAdvanced}
                                />
                            }
                            label="Advanced"
                            labelPlacement="end"
                        />
                    </FormGroup>
                </Typography>
            </Grid>
            {showAdvanced && (
                <Slider
                    aria-labelledby="gas-slider"
                    onChangeCommitted={handleSpeedChange}
                    defaultValue={defaultSpeedSliderNumeric}
                    {...gasSpeedSliderConfig}
                />
            )}
            <AdvancedGas
                defaultSpeed={gasSpeed}
                defaultValues={oracleGasForecastData}
                defaultLimit={defaultGasLimit}
                gasLoading={isGasLoading}
                isVisible={showAdvanced}
                gasFields={advancedGasFields}
                onChange={handleGasAdvancedChange}
            />
        </Container>
    )
};

FeeConfiguration.propTypes = {
    isRefreshing: PropTypes.bool,
    defaultGasLimit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    gasDataRef: PropTypes.object,
};

export default FeeConfiguration;