import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getFormValues, destroy, isValid } from "redux-form";

import Alert from '@material-ui/lab/Alert';
import { Container, Grid, Typography, Link } from '@material-ui/core';
import { openLink } from '../../../utils/linksOpenPolyfill';
import { getError } from '../../../utils/transformErrors';
import GtagUtil from "../../../utils/gtagUtils";

import {
    changeSendingCurrency,
    changeSendingAmount,
    changeSendingAddress
 } from '../../../ducks/blockchain';
import {
    resetGas
} from "src/ducks/blockchainGas";
import {
    getStakingData
} from '../../../ducks/staking';

import {
    ROUTE_STAKE_DASH,
    EXTERNAL_STAKING,
    EXTERNAL_NFT_PROGRAM
} from '../../../constants/routes';

import {
    BLOCKCHAIN_BSC,
    TRX_TYPE_DEPOSIT
} from '../../../constants/blockchain';

import {
    CURRENCY_IOWN
} from '../../../constants/currencies';

import { FROM_STAKE_TOKENS, setStakingValues } from '../../../ducks/FormData';

import ApproveAndTransactTokens from '../../../components/ApproveAndTransactTokens';
import StakeTokensForm from '../../../components/Forms/StakeTokensForm';
import SAMobileStepper from "../../../components/SAMobileStepper";
import ProcessingAlert from "src/common/processing-alert";

import BlockchainLabel from "../../../components/BlockchainLabel";

class StakeTokensAction extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            catchErr: null,
            trxError: null,
            activeStep: 0,
            rewards: 0,
            isAgreeTnc: false,
            isTransactionSuccess: false
        }
    };

    setIsAgreeTnc = (isAgree) => {
        this.setState({isAgreeTnc: isAgree});
    }

    shouldComponentUpdate = async(nextProps) => {
        const iownCur = this.pickCurrencyIown();
        if(iownCur && !this.props.staking.stakingDataLoading && !this.props.staking.stakingDataLoaded && !this.props.staking.error) {
            this.props.getStakingData(iownCur, this.props.blockchain.wallet);
            return true;
        }
    }

    componentWillUnmount = () => {
        destroy(FROM_STAKE_TOKENS);
    }

    nextStep = () => {
        switch(this.state.activeStep) {
            case 1:
                this.checkAmount();
                break;
            case 2:
                this.transactionSuccessRedirect();
                break;
            default:
                this.setState((state) => ({ activeStep: state.activeStep + 1 }));
        }
    }

    prevStep = async() => {
        switch(this.state.activeStep) {
            case 0:
                this.props.history.push(ROUTE_STAKE_DASH);
                break;
            case 2:
                this.props.resetGas();
                //falls through
            default:
                this.setState((state) => ({ activeStep: state.activeStep - 1, trxError: null, catchErr: null }));    
        }
    }

    pickCurrencyIown() {
        const { userAssetTypes } = this.props.dashboard;
        let cur;
        userAssetTypes.forEach((v, idx) => {
            if(v.blockchain === BLOCKCHAIN_BSC && v.name === CURRENCY_IOWN) {
                cur = v;
                return false;
            }
        });
        return cur;
    }

    checkAmount() {
        const { formValues, staking: { stakingAddress } } = this.props;
        this.props.setStakingValues(formValues);
        const iownCur = this.pickCurrencyIown();
        if(iownCur) {
            this.props.changeSendingCurrency(iownCur.name, iownCur);
            this.props.changeSendingAmount(formValues.amount);
            this.props.changeSendingAddress(stakingAddress);
            this.setState((state) => ({ activeStep: state.activeStep + 1, rewards: formValues.receiveAmount })); 
        } else {
            this.setState({catchErr: 'Error picking iOWN Currency'});
        }
    }

    transactionSuccess() {
        this.setState(() => ({ isTransactionSuccess: true }));
    }

    transactionSuccessRedirect() {
        const iownCur = this.pickCurrencyIown();
        if(iownCur) {
            this.props.getStakingData(iownCur, this.props.blockchain.wallet);
        }
        GtagUtil.trackEarnVirtualCurrency('Amlak NFT', this.state.rewards);
        this.props.history.push(ROUTE_STAKE_DASH);
    }

    transactionFail(failureReason) {
        this.setState({trxError: getError(failureReason) });
    }

  	render() {
  		if(this.state.catchErr) throw this.state.catchErr;

        const { formValues, formValid } = this.props;
        const { balance } = this.props.blockchain;
        const { stakingRate, stakingMinTicket, stakingDataLoading, stakingDataLoaded, error, texts } = this.props.staking;
        const { activeStep, trxError, isAgreeTnc, isTransactionSuccess } = this.state;

        const disablePrev = (activeStep === 2 && isTransactionSuccess);
        const disabledNext = !!error || (activeStep === 2 && !isTransactionSuccess) || (activeStep === 1 && (!formValues || !formValues.amount || !formValid)) || (activeStep === 1 && !isAgreeTnc);
        const iownBalance = balance && balance[BLOCKCHAIN_BSC] && balance[BLOCKCHAIN_BSC][CURRENCY_IOWN];

        const showError = trxError || error;

        return (
            <Container className="home" component="main" maxWidth="sm">
      			<Grid item xs={12} className="centered-grid">
                    <BlockchainLabel />
                    <Typography variant="h5" align="center" gutterBottom>
    	                Stake iOWN
    	            </Typography>
                    {activeStep === 0 && !showError && (<>
                        <img src="/img/nft.gif" alt="Genesis Pool NFT" id="amlak-nft" onClick={(e) => openLink(e, EXTERNAL_NFT_PROGRAM)} style={{maxWidth: '80%'}}/>
                        <Typography align="left" gutterBottom className="spaced-top">Stake iOWN Token in the iOWN Genesis pool for only 182 days and get one NFT for every 50K iOWN Token you stake, each NFT represents 1 sqft worth $487.
                            As long as you own the NFT you will receive upto 5-6% of the current sqft value for lifetime!
                        </Typography>
                        <Typography align="left" gutterBottom>
                            To learn more about the staking program&nbsp;
                            <Link href={EXTERNAL_STAKING}
                               target="_blank"
                               rel="noopener noreferrer"
                               onClick={(e)=> openLink(e, EXTERNAL_STAKING)}
                               style={{textDecoration: 'underline'}}
                               colour="primary"
                            >click here</Link>
                        </Typography>
                    </>)}
        		</Grid>
                { !stakingDataLoading && showError && (
                   <Alert severity="error">{showError}</Alert>
                )}
                { !showError && (<Grid item xs={12} className="centered-grid spaced-top">
                    { activeStep > 0 && stakingDataLoading && (<ProcessingAlert message={"Loading Staking Information..."} />) }
                    { activeStep === 1 && stakingDataLoaded && (
                        <>
                            <Typography align="left" gutterBottom>
                                Choose amount you want to stake:
                            </Typography>
                            <StakeTokensForm balance={iownBalance} nftRate={stakingRate} minTicket={stakingMinTicket} setIsAgreeTnc={this.setIsAgreeTnc} />
                        </>
                    )}
                    { activeStep === 2 && stakingDataLoaded && (
                        <ApproveAndTransactTokens
                                hideBack
                                disabled
                                onTransactionSuccess={(trxHashApprove, trxHashExecute) => this.transactionSuccess (trxHashApprove, trxHashExecute) }
                                onTransactionFail={(failureReason) => this.transactionFail(failureReason) } 
                                onTransactionBack={this.prevStep}
                                trxType={TRX_TYPE_DEPOSIT}
                                actionLabel="swap"
                                targetAddressLabel="Swapping Address"
                                texts={texts}
                        />
                    )}
                </Grid>)}
                <SAMobileStepper
                    maxSteps={3}
                    activeStep={this.state.activeStep}
                    onNext={this.nextStep}
                    onPrev={this.prevStep}
                    disabledPrev={disablePrev}
                    disabledNext={disabledNext}
                />
            </Container>
  		)
  	}
}

const mapState2props = state => ({
    blockchain: state.blockchain,
    dashboard: state.dashboard,
    staking: state.staking,
    formValues: getFormValues(FROM_STAKE_TOKENS)(state),
    formValid: isValid(FROM_STAKE_TOKENS)(state)
});

const mapDispatch2props = {
    changeSendingAmount,
    changeSendingCurrency,
    changeSendingAddress,
    getStakingData,
    setStakingValues,
    resetGas
};

export default compose(
    connect(mapState2props, mapDispatch2props)
)(StakeTokensAction);