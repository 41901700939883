import React from "react";
import { connect } from "react-redux";
import { getFormValues, isValid } from "redux-form";
import sha512 from "js-sha512";
import { encryptDES } from "../../utils/cryptoUtil";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import SignInOldForm from "../../components/Forms/SignInOld";
import history from "../../history";
import {
  loginWithWallet,
  checkWalletPassword
} from "../../ducks/signin";
import { changeWallet } from "../../ducks/blockchain";
import { ROUTE_DEFAULT_LOGGEDIN } from "../../constants/routes";
import { KEY_ACTIVE_BIO_HASH, KEY_MULTI_USER_BIO_ACTIVE } from "../../constants/storageKeys";
import StorageUtils from "../../utils/storageUtils";

import Fingerprint from "../../BiometricsAuth";

class BiometricsActivate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      catchErr: null,
      bioAvailable: false, //hardware supported
      bioEnabled: !!StorageUtils.getItem(KEY_MULTI_USER_BIO_ACTIVE), //Active appwide
    };    
  }

  componentWillMount = async () => {
    const existingHash = StorageUtils.getItem(KEY_ACTIVE_BIO_HASH);
    if (existingHash && existingHash.length > 1 && existingHash !== 'null') {
      history.push(ROUTE_DEFAULT_LOGGEDIN);
      return;
    }
    await Fingerprint.isAvailable((result) => {
      if (result) {
        this.setState({ bioAvailable: true });
      } else {
        this.setState({ showErr: 'Biometrics not available' });
      }
    }, (error) => {
      this.setState({ showErr: 'Biometrics not available' });
    });
  }

  goBack() {
    try {
      history.push(ROUTE_DEFAULT_LOGGEDIN);
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  bioRegister = async (hashPassword) => {
    const encSeed = sha512(Math.random().toString(36).substring(2)); //A random decryption key loaded from biometrics
    await Fingerprint.registerBiometricSecret({
      title: 'Biometrics Wallet Unlock',
      description: 'Authenticate to Activate',
      secret: encSeed,
      invalidateOnEnrollment: true,
      disableBackup: true,
    }, (r) => {
      StorageUtils.setItem(KEY_ACTIVE_BIO_HASH, encryptDES(hashPassword, encSeed)); //per user
      StorageUtils.setItem(KEY_MULTI_USER_BIO_ACTIVE, true); //system-wide
      history.push(ROUTE_DEFAULT_LOGGEDIN);
    }, (e) => {
      this.setState({ showErr: 'Biometrics auth register failed: ' + e.message });
    });
  };

  bioReActivate = async (hashPassword) => {
    //We reload the encSeed from the other session(s) and use it
    await Fingerprint.loadBiometricSecret({
      title: 'Biometrics Wallet Unlock',
      description: 'Authenticate to Enable',
      disableBackup: true
    }, (encSeed) => {
      if (encSeed) {
        StorageUtils.setItem(KEY_ACTIVE_BIO_HASH, encryptDES(hashPassword, encSeed));
        history.push(ROUTE_DEFAULT_LOGGEDIN);
      } else {
        this.setState({ showErr: 'Biometrics auth activate failed (seed error)' });
      }
    }, (e) => {
      //Force it to happen fresh, to avoid not being able to register when fingerprints change
      // and google removes the secret
      StorageUtils.removeItem(KEY_ACTIVE_BIO_HASH);
      StorageUtils.setItem(KEY_MULTI_USER_BIO_ACTIVE, false);
      this.setState({ showErr: 'Biometrics auth activate error: ' + e.messasge, bioEnabled: false });
    });
  };

  bioActivate = async () => {
    try {
      if (this.props.formValid) {
        const { password } = this.props.formValues;
        const hashPassword = sha512(password);
        const wallet = await this.props.checkWalletPassword(hashPassword);
        if (wallet) {
          if (this.state.bioEnabled) {
            await this.bioReActivate(hashPassword);
          } else {
            await this.bioRegister(hashPassword);
          }
        }
      }
    } catch (e) {
      this.setState({ showErr: 'Biometrics auth register exception: ' + JSON.stringify(e), bioEnabled: false });
    }
  };

  render() {
    if (this.state.catchErr) throw this.state.catchErr;

    let { failed, error } = this.props.signin;

    let hasNewLine = false;
    let errorsArray = [];
    if (error && error.length) {
      errorsArray = error.split("<br/>");
    }
    if (failed) {
      if (errorsArray.length > 1) {
        hasNewLine = true;
      }
    }
    if (this.state.showErr) {
      error = this.state.showErr;
      failed = true;
    }

    const { bioAvailable } = this.state;
    const { formValid } = this.props;

    return (
        <>
          <SignInOldForm onSubmit={this.bioActivate} />
          {!hasNewLine ? (
              <>
                {failed && (
                    <div className="register__error" dangerouslySetInnerHTML={{ __html: error }}></div>
                )}
              </>
          ) : (
              <div className="register__error">
                {failed && (
                    <>
                      <div className="register__error" dangerouslySetInnerHTML={{ __html: errorsArray[0] }}></div>
                      <div className="register__error" dangerouslySetInnerHTML={{ __html: errorsArray[1] }}></div>
                    </>
                )}
              </div>
          )
      }

      <Button
          className="spaced__btn"
          onClick={this.bioActivate}
          fullWidth
          variant="contained"
          disabled={!formValid || !bioAvailable}
          color="primary"
        >
          Activate Now
        </Button>

      <Box pt = { 2 } >
      <Button
            onClick={this.goBack}
            fullWidth
            variant="contained"
          >
            Cancel
      </Button> </Box> 
      </>
    );
  }
}

const mapState2props = state => ({
  signin: state.signin,
  formValues: getFormValues("signin-old")(state),
  formValid: isValid("signin-old")(state)
});

const mapDispatch2props = {
  loginWithWallet,
  changeWallet,
  checkWalletPassword
};

export default connect(
  mapState2props,
  mapDispatch2props
)(BiometricsActivate);