import React from "react";
import Container from '@material-ui/core/Container';

import In4xTransactionHistoryTable from "../../components/In4xTransactionHistoryTable";

class In4xTransactionHistory extends React.Component {

  render() {
  	return (
  		<Container className="home dashboard" component="main" maxWidth="sm">
        <In4xTransactionHistoryTable />
  		</Container>
  	);
  };

}

export default In4xTransactionHistory;