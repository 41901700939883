export const CURRENCY_USD = 'usd';
export const CURRENCY_ETH = 'eth';
export const CURRENCY_IOWN = 'iown';
export const CURRENCY_UOWN = 'uown';
export const CURRENCY_USDT = 'usdt';
export const CURRENCY_USDC = 'usdc';

export const CURRENCY_BNB = 'bnb';
export const CURRENCY_BUSD = 'busd';
export const CURRENCY_MATIC = 'matic';

//https://api.latoken.com/v2/ticker/620f2019-33c0-423b-8a9d-cde4d7f8ef7f/0c3a106d-bde3-4c13-a26e-3fd2394529e5
export const LATOKEN_USDT = '0c3a106d-bde3-4c13-a26e-3fd2394529e5';
export const LATOKEN_ETH = '620f2019-33c0-423b-8a9d-cde4d7f8ef7f';
export const LATOKEN_IOWN = '61bd7930-7a27-480e-8c7c-dfa8179c2898';
export const LATOKEN_USDC = '894f0a35-1f7e-4ff1-b1c1-1705b27d9dc2';

export const getCurrencyName = currency => {
	switch(currency) {
		case CURRENCY_ETH: return 'Ether';
		case CURRENCY_IOWN: return 'iOWN ';
		case CURRENCY_UOWN: return 'uOWN';
		case CURRENCY_USDT: return 'USDT';
		case CURRENCY_MATIC: return 'Matic';
		default: return 'Token';
	}
};

export const getCurrencyIcon = ticker => {
	switch(ticker.toLowerCase()) {
		case CURRENCY_IOWN: return '/img/iown-icon.png';
		case CURRENCY_ETH: return '/img/eth-icon.svg';
		case CURRENCY_USDC: return '/img/usdc-icon.png';
		case CURRENCY_USDT: return '/img/usdt-icon.png';
		case CURRENCY_UOWN: return '/img/uown-icon.png';
		case CURRENCY_BNB: return '/img/bnb-icon.png';
		case CURRENCY_BUSD: return '/img/busd-icon.svg';
		case CURRENCY_USD: return '/img/usd-icon.png';
		case CURRENCY_MATIC: return '/img/matic-coin.png';
		case 'bsc': return '/img/bnb-icon.png';
		default: 
			return '/img/unknown-icon.png';
	}
}

export const getLaTokenKey = currency => {
	switch(currency) {
		case CURRENCY_IOWN: return LATOKEN_IOWN;
		case CURRENCY_ETH: return LATOKEN_ETH;
		case CURRENCY_USDC: return LATOKEN_USDC;
		default: return LATOKEN_USDT;
	}
};