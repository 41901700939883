import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { Button, Box, Typography, IconButton } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';

import In4xWireTransferHistoryTable from '../../components/Table/In4xWireTransferHistoryTable';
import In4xIndaHistoryTable from '../../components/Table/In4xIndaHistoryTable';
import In4xPaymentHistoryTable from '../../components/Table/In4xPaymentHistoryTable';
import In4xCashTransferHistoryTable from '../../components/Table/In4xCashTransferHistoryTable';
import history from "../../history";
import { 
  ROUTE_NX_TRANSACTION_HISTORY
} from "../../constants/routes";

import {
  loadTransactionHistory
} from "../../ducks/in4x";

class In4xTransactionHistoryTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      transactionType: false,
      page: 0,
      perPage: 20,
      title: ''
    };
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);    
  }

  componentDidMount = async() => {
    await this.loadTransactionTypeFromLocation();
    this.setPageTitle();
    await this.onChangePage();
  }

  loadTransactionTypeFromLocation = async() => {
    let transactionType = history.location.pathname.substring(history.location.pathname.indexOf(ROUTE_NX_TRANSACTION_HISTORY) + ROUTE_NX_TRANSACTION_HISTORY.length);
    this.setState({transactionType: transactionType});   
  }

  setPageTitle = () => {
    let title = '';
    switch(this.state.transactionType) {
      case 'transfers':
        title = 'IN4X Wire Transfers';
        break;
      case 'indacoin':
        title = 'Card Payments/Topups';
        break;
      case 'payments':
        title = 'Partner Payments';
        break;
      case 'withdrawals':
        title = 'Withdrawals';
        break;
      case 'swaps':
        title = 'Token Exchanges';
        break;
      default:
        title = 'IN4X Transactions';
    }
    this.setState({title: title});
  }

  onChangePage = async (event, page) => {
    try {
      if(page) {
        this.setState({page: page});
      } else {
        page = this.state.page;
      }
      this.props.loadTransactionHistory(this.state.transactionType, page, this.state.perPage);
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  handleBackButtonClick() {    
    try {
      history.push(ROUTE_NX_TRANSACTION_HISTORY);
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  render() {
    if (this.state.catchErr) throw this.state.catchErr;
    const { title, page, perPage, transactionType } = this.state;
    const { transactionList, transactionsTotal, in4xHistoryLoading, in4xHistoryLoaded } = this.props.in4x;
    return (
    	<>        
    	 <Box className="table-heading-nx">
            <Typography variant="h5" className="table-title">{title}</Typography>
            <Typography paragraph className="table-sub">
                Your transaction history
            </Typography>
            <IconButton className="table-refresh-button" onClick={this.onChangePage} disabled={in4xHistoryLoading}>
                <RefreshIcon className="table-refresh_icon" />
            </IconButton>
        </Box>
        { transactionType === 'transfers' && (<In4xWireTransferHistoryTable isPaginate
            page={page}
            rowsPerPage={perPage}
            transactionsLoaded={in4xHistoryLoaded}
            transactions={transactionList}
            totalCount={transactionsTotal}
            onChangePage={this.onChangePage}
            isLoading={in4xHistoryLoading}/>
        )}
        { transactionType === 'indacoin' && (<In4xIndaHistoryTable isPaginate
            page={page}
            rowsPerPage={perPage}
            transactionsLoaded={in4xHistoryLoaded}
            transactions={transactionList}
            totalCount={transactionsTotal}
            onChangePage={this.onChangePage}
            isLoading={in4xHistoryLoading}/>
        )}
        { transactionType === 'payments' && (<In4xPaymentHistoryTable isPaginate
            page={page}
            rowsPerPage={perPage}
            transactionsLoaded={in4xHistoryLoaded}
            transactions={transactionList}
            totalCount={transactionsTotal}
            onChangePage={this.onChangePage}
            isLoading={in4xHistoryLoading}/>
        )}
        { transactionType === 'otc' && (<In4xCashTransferHistoryTable isPaginate
            page={page}
            rowsPerPage={perPage}
            transactionsLoaded={in4xHistoryLoaded}
            transactions={transactionList}
            totalCount={transactionsTotal}
            onChangePage={this.onChangePage}
            isLoading={in4xHistoryLoading}/>
        )}
        { transactionType === 'withdrawals' && (<In4xPaymentHistoryTable isPaginate
            page={page}
            rowsPerPage={perPage}
            transactionsLoaded={in4xHistoryLoaded}
            transactions={transactionList}
            totalCount={transactionsTotal}
            onChangePage={this.onChangePage}
            isLoading={in4xHistoryLoading}/>
        )}
        { transactionType === 'swaps' && (<In4xCashTransferHistoryTable isPaginate
            transactionType={transactionType}
            page={page}
            rowsPerPage={perPage}
            transactionsLoaded={in4xHistoryLoaded}
            transactions={transactionList}
            totalCount={transactionsTotal}
            onChangePage={this.onChangePage}
            isLoading={in4xHistoryLoading}/>
        )}
        <Button type="submit" fullWidth variant="contained" className="bg-nx" onClick={this.handleBackButtonClick}>Back</Button>
        </>
    );
  }
};

const mapState2props = state => ({
  dashboard: state.dashboard,
  in4x: state.in4x
});

const mapDispatch2props = {
  loadTransactionHistory
};

export default compose(
  connect(mapState2props, mapDispatch2props)
)(In4xTransactionHistoryTable);