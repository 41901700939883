import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  ROUTE_ABOUT,
  ROUTE_COOKIES_POLICY,
  ROUTE_PRIVATE_POLICY,
  ROUTE_SIGNIN,
  ROUTE_NEW_ACCOUNT,
} from "../../constants/routes";
import {KEY_ACTIVE_PK, KEY_PROMO_SHOWN} from "../../constants/storageKeys";

import { List, ListItem, ListItemText, Divider } from "@material-ui/core";

import history from "../../history";
import MultiAccountUtils from "../../utils/multiAccountUtils";
import StorageUtils from "../../utils/storageUtils";
import { openLink } from '../../utils/linksOpenPolyfill';
import AddBoxIcon from '@material-ui/icons/AddBox';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import NftPromo from "../../wallet-components/nft-promo";

const useStyles = theme => ({
  button: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fullHeight: {
    height: '100%',
    paddingTop: '50px',
  },
  wrapCenter: {
    flex: 1,
    minHeight: '20vh'
  },
  accountsMenuItem: {
    textAlign: 'center',
    fontWeight: 'bold',
    wordBreak: 'break-word'
  }
});

const Home = ({ classes }) => {
  const isNewUser = !StorageUtils.getItem(KEY_ACTIVE_PK);
  const accounts = MultiAccountUtils.getAccounts();
  const appVersion = process.env.REACT_APP_VERSION;

  const [promoFinished, setPromoFinished] = useState(!!StorageUtils.getItem(KEY_PROMO_SHOWN));
  useEffect(() => {
    StorageUtils.setItem(KEY_PROMO_SHOWN, true);
  }, [promoFinished]);

  const activateAccount = (email) => {
    MultiAccountUtils.switchAccount(email);
    history.push(ROUTE_SIGNIN);
  };

  return (
    <Container className="home" component="main" maxWidth="sm">
        { !promoFinished && (
            <NftPromo
                onPromoFinish={setPromoFinished}
            />
        )}
        { promoFinished && (
            <Grid
                container
                direction={'column'}
                justifyContent="space-between"
                wrap={'nowrap'}
                className={classes.fullHeight}
            >
                <Typography variant="h5" align="center" gutterBottom >
                    Welcome to iOWN Wallet
                </Typography>
                <Typography variant="h5" align="center" gutterBottom className="spaced-top">
                    <img style={{maxWidth: '100px'}} src="/img/iown-blue-icon.png" alt="iOWN Group"/>
                </Typography>
                <Grid
                    container
                    direction={'column'}
                    justifyContent="center"
                    wrap={'nowrap'}
                    className={classes.wrapCenter}
                >
                    {!isNewUser && (
                        <Typography variant="h5" color="primary" align="center" gutterBottom className="spaced-top">
                            Login As:
                        </Typography>
                    )}
                    <List component="nav" aria-label="accounts">
                        { !isNewUser && accounts && accounts.map((acc, idx) => (
                            <span key={idx}>
                        <ListItem button className={classes.accountsMenuItem}>
                          <ListItemText primary={acc} color="primary" onClick={ ()=>{activateAccount(acc)} }/>
                        </ListItem>
                        <Divider />
                      </span>
                        ))}
                        { !isNewUser && (<ListItem button className={classes.accountsMenuItem} >
                                <ListItemText primary={<AddBoxIcon/>} color="primary" onClick={() => { history.push(ROUTE_NEW_ACCOUNT)} }/>
                            </ListItem>
                        )}
                        { isNewUser && (<ListItem button className={classes.accountsMenuItem} >
                                <ListItemText primary={(<Typography color="primary">Get Started <PlayArrowIcon className="icon-aligned" /></Typography>)} color="primary" onClick={() => { history.push(ROUTE_NEW_ACCOUNT)} }/>
                            </ListItem>
                        )}
                    </List>
                </Grid>
                <Grid item align={'center'}>
                    <Link to={ROUTE_ABOUT} style={{ display: "none" }}>
                        <Button fullWidth color="primary">
                            About&nbsp;<span className={'noUpperText'}>iOWNX</span>
                        </Button>
                    </Link>
                    <Link to={ROUTE_PRIVATE_POLICY} style={{ display: "none" }}>
                        <Button color="primary">
                            Privacy Policy
                        </Button>
                    </Link>
                    <Link to={ROUTE_COOKIES_POLICY} style={{ display: "none" }}>
                        <Button color="primary">
                            Cookie Policy
                        </Button>
                    </Link>
                    <a href={`https://www.iowntoken.com`} target="_blank" rel="noopener noreferrer" onClick={(e) => openLink(e, "https://www.iownx.com/company")}>
                        <Button color="primary" fullWidth>
                            About&nbsp;<span className={'noUpperText'}>iOWN</span>
                        </Button>
                    </a>
                    <a href={`https://www.iowntoken.com/wallet-privacy-policy/`} target="_blank" rel="noopener noreferrer" onClick={(e) => openLink(e, "https://www.iownx.com/privacy-policy")}>
                        <Button color="primary" >
                            Privacy Policy
                        </Button>
                    </a>
                    <a href={`https://www.iowntoken.com/wallet-terms`} target="_blank" rel="noopener noreferrer" onClick={(e) => openLink(e, "https://www.iownx.com/terms")}>
                        <Button color="primary" >
                            Terms of Use
                        </Button>
                    </a>
                    <Typography className="app-version-home" variant="caption">App Verison: {appVersion}</Typography>
                </Grid>
            </Grid>
        )}
        </Container>
  );
};

export default withStyles(useStyles)(Home);