import React from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import BNUtils from "../../utils/bnUtils";
import {getCurrencyName} from "../../constants/currencies";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Box from "@material-ui/core/Box";

import { ROUTE_TRANSACTION } from "../../constants/routes";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    tableWrapper: {
        overflowX: 'hidden',
    },
    table: {
        whiteSpace: 'nowrap',
    },
    thead: {
        backgroundColor: "#f5f5f5",
    },
    th: {
        fontSize: "16px",
        color: "#0083c0",
        height: "36px"
    },
    td: {
        fontSize: "16px",
        color: "#0083c0",
        height: "36px",
        padding: "5px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "14px",
        },
        '&:first-child': {
            paddingLeft: "10px",
            [theme.breakpoints.down('sm')]: {
                width: '35px',
            }
        }
    },
    td_content: {
        fontSize: "14px",
        color: "#010101",
        fontWeight: 400,
        padding: "5px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "12px",
            maxWidth: 0,
        },
        '&:first-child': {
            paddingLeft: "10px",
            [theme.breakpoints.down('sm')]: {
                width: '35px',
            }
        }
    },
    td_link: {
        fontSize: "12px",
        color: "#0082ca",
        fontWeight: 400,
    },
    pagination: {
        fontSize: "14px",
        color: "#010101"
    },
    noWrap: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }
}));

export default function DenseTable({transactions, page, onChangePage, totalCount, rowsPerPage = 15, isPaginate = false, isPending = false, isLoading = false, pendingLength = 0}) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                {!isLoading ? (
                    transactions?.length > 0 ? (
                        <>
                            <div className={classes.tableWrapper}>
                                <Table className={classes.table} size="small">
                                    <TableHead className={classes.thead}>
                                        <TableRow>
                                            <TableCell className={`${classes.th}`}>Tokens</TableCell>
                                            <TableCell align={'center'} className={classes.td}>Direction</TableCell>
                                            <TableCell align={'right'} className={`${classes.td} ${classes.noWrap}`}>Timestamp</TableCell>
                                            <TableCell className={classes.td}>Details</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {transactions.map((row, index) => (
                                            <TableRow key={`${index + 'trans'}`}>
                                                { row.tokenID && (
                                                    <TableCell className={`${classes.td_content} ${classes.noWrap}`}>
                                                        {getCurrencyName(row.currency) + ' ' + row.tokenID}
                                                    </TableCell>
                                                )}
                                                { !row.tokenID && (<TableCell className={`${classes.td_content} ${classes.noWrap}`}>
                                                    {row.value !== 0 ? getCurrencyName(row.currency) + ' ' + BNUtils.bnToString(row.value, row.tokenDecimal) + ' ' : (row.fee && row.fee !== 0? (row.fee*Math.pow(10,10)).toFixed(4) + " Gwei (fee)":"")}
                                                    </TableCell>
                                                )}
                                                <TableCell align={'center'} className={classes.td_content}>
                                                    {row.direction}
                                                </TableCell>
                                                <TableCell align={'right'} className={`${classes.td_content} ${classes.noWrap}`}>
                                                    {row.timeStamp ? moment.unix(row.timeStamp).format("hh:mm A DD-MM-YYYY") : '-'}
                                                </TableCell>
                                                <TableCell align={'right'} className={`${classes.td_content} ${classes.noWrap}`}>
                                                    <Link to={`${ROUTE_TRANSACTION}${row.blockchain}/${row.pending? index: index - pendingLength}${row.pending ? '/pending' : ''}`}>
                                                        <ArrowForwardIosIcon />
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                            
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                            {isPaginate && (
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    component="div"
                                    count={totalCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    onPageChange={(event, page) => onChangePage(page)}
                                    className={classes.pagination}
                                />
                            )}
                        </>
                    ) : (
                        <Box align={'center'} p={3}>
                            <Typography variant="subtitle1">
                                You don't have transactions
                            </Typography>
                        </Box>
                    )
                ) : (
                    <Box align={'center'} p={3}>
                        <CircularProgress/>
                    </Box>
                )}
            </Paper>
        </div>
    );
}
