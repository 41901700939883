import {handleFetch} from "src/utils/fetch";
import {API_SWAP_TRANSFER_CREATE, API_SWAP_TRANSFER_GET} from "src/constants/apiRoutes";

export const createSwapTransfer = transactionData => {
    return new Promise((resolve, reject) => {
        handleFetch(API_SWAP_TRANSFER_CREATE, "POST", transactionData)
            .then(res => {
                if (Object.keys(res?.payload).length === 0 || !res.success) {
                    reject(res.payload);
                } else {
                    resolve(res.payload);
                }
            }).catch((err) => {
                reject(err);
        });
    });
};

export const getSwapTransferByRef = target => {
    return new Promise((resolve, reject) => {
        return handleFetch(API_SWAP_TRANSFER_GET, "POST", {target})
            .then(res => {
                if (Object.keys(res?.payload).length === 0 || !res.success) {
                    reject(res.payload);
                } else {
                    resolve(res.payload);
                }
            }).catch((err) => {
                reject(err);
        });
    });
};