import React, {useCallback} from "react";
import {connect} from "react-redux";
import {Field, reduxForm, getFormValues, change} from 'redux-form'

import TextField from '@material-ui/core/TextField';
import CustomInput from '../Field';

import {CURRENCY_IOWN} from "../../../constants/currencies";

import {getStakingValues, FROM_STAKE_TOKENS} from "../../../ducks/FormData";
import {required, number, maxVal} from "../../../utils/validation";
import {FormControlLabel, FormGroup, Link, Typography} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import {openLink} from "../../../utils/linksOpenPolyfill";

const StakeTokensForm = (props) => {
    const {formValues = {}, handleSubmit, balance, nftRate, minTicket, setIsAgreeTnc} = props;

    const handleChange = (amount) => {
        props.dispatch(change(FROM_STAKE_TOKENS, 'receiveAmount', parseInt(amount * nftRate)));
    }

    const handleAgreeTnc = (isAgree) => {
        setIsAgreeTnc(isAgree);
    }

    const validateAmount = useCallback(value => {
        return value > 0 && value % minTicket > 0 ? `Multiples of ${minTicket} allowed` : undefined;
    }, [minTicket]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const validateMaxValue = useCallback(maxVal(balance), [balance]);

    return (
        <>
            <form name={FROM_STAKE_TOKENS} onSubmit={handleSubmit}>
                <TextField
                    type="text"
                    value={CURRENCY_IOWN.toUpperCase()}
                    name="cur_from"
                    label="Stake"
                    disabled
                    style={{width: '50%'}}
                />
                <TextField
                    type="text"
                    value={'NFT'}
                    name="cur_to"
                    label="Receive"
                    disabled
                    style={{width: '50%'}}
                />
                <Field className="spaced-top"
                       component={CustomInput}
                       type="number"
                       name="amount"
                       label="Amount to Stake"
                       adornment="iOWN"
                       onChange={({target}) => handleChange(target.value)}
                       validate={[validateAmount, required, number, validateMaxValue]}
                />
                {formValues.receiveAmount > 0 && (
                    <Field className="spaced-top"
                           component={CustomInput}
                           type="text"
                           name="receiveAmount"
                           label="NFTs to Receive"
                           disabled
                           adornment="NFTs"
                    />
                )}
                <FormGroup style={{marginTop: "25px"}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                onChange={({target}) => handleAgreeTnc(target.checked)}
                            />
                        }
                        label={
                            <Typography align="left" gutterBottom>
                                I have read and accept&nbsp;
                                <Link
                                    href="https://www.iowntoken.com/staking-terms-and-conditions/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={(e)=> openLink(e, "https://www.iowntoken.com/staking-terms-and-conditions/")}
                                    style={{textDecoration: 'underline'}}
                                >staking terms and conditions</Link>
                            </Typography>
                        }
                    />
                </FormGroup>
            </form>
        </>
    )
    // }
}

const Component = reduxForm({
    form: FROM_STAKE_TOKENS,
    enableReinitialize: true,
})(StakeTokensForm);

export default connect(
    state => ({
        initialValues: state.FormData.StakeTokens,
        formValues: getFormValues(FROM_STAKE_TOKENS)(state),
    }),
    {load: getStakingValues}               // bind account loading action creator
)(Component);