export const feeForAmount = (fees, action, actionType, token, usdAmount) => {
	if(!fees || !fees[action]) {
		return 0;
	}
	let feeType = 'pct';
	let feeAmount = 0;
	let feeFixed = 0;
	token = token.toLowerCase();
	if(fees[action][actionType][token]) {
		feeType = (fees[action][actionType][token]['type']);
		feeAmount = Number(fees[action][actionType][token]['amount']);
		feeFixed = Number(fees[action][actionType][token]['fixed']);
	} else {
		feeType = (fees[action][actionType]['other']['type']);
		feeAmount = Number(fees[action][actionType]['other']['amount']);
		feeFixed = Number(fees[action][actionType]['other']['fixed']);
	}
	switch(feeType) {
		case 'pct':
			return Number(usdAmount) * (feeAmount / 100) + feeFixed;
		case 'fixed':
			return Number(usdAmount) + feeAmount + feeFixed;
		default:
			throw new Error('CRITICAL_ERROR');
	}
}

export const minLimit = (limits, action, actionType, token) => {
	if(!limits || !limits[action]) {
		return 0;
	}
	token = token.toLowerCase();
	if(limits[action][actionType][token]) {
		return Number(limits[action][actionType][token]['min']);
	} else {
		return Number(limits[action][actionType]['other']['min']);
	}
}

export const maxLimit = (limits, action, actionType, token) => {
	if(!limits || !limits[action]) {
		return 0;
	}
	token = token.toLowerCase();
	if(limits[action][actionType][token]) {
		return Number(limits[action][actionType][token]['max']);
	} else {
		return Number(limits[action][actionType]['other']['max']);
	}
}

/**
 * Generates the key for IN4X reducer exRates for a pair of currencies
 * @param fromCur Base Currency Name (ex: usdt, iown)
 * @param toCur Quoted Currency Name (ex: iown, bnb)
 * @returns {string} Key for props.in4x.exRates (reducer value)
 */
export const exRateIdxKey = (fromCur, toCur) => {
	return fromCur.toLowerCase() + '_' + toCur.toLowerCase();
}