import React, { useContext } from "react";
import { UserAssetsContext } from "./contexts/user-assets";
import App from './App';

const AppWrapper = (props) => {
    const context = useContext(UserAssetsContext);

    return (
        <App
            {...props}
            context={context}
        />
    )
};

export default AppWrapper;