import React from "react";
import { withRouter } from "react-router-dom";
import { AppBar, BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import { getCurrencyIcon, CURRENCY_BNB, CURRENCY_ETH, CURRENCY_MATIC } from "../../constants/currencies";
import { ASSET_TYPE_LIQUID, ASSET_TYPE_DIGITAL, ROUTE_HOME_SCANNER, ASSET_TYPE_NFT } from "../../constants/routes";
import { BLOCKCHAIN_ETH, BLOCKCHAIN_BSC, BLOCKCHAIN_MATIC } from "../../constants/blockchain";
import { EVENT_PROVIDER_BLOCKCHAIN_CHANGE } from "../../constants/events";
import ProviderUtils from "../../utils/blockchain/ProviderUtils";

class BottomBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: ASSET_TYPE_LIQUID,
    };

    if(props.currentAssetType) {
      this.state.activeTab = props.currentAssetType;
    }
  };

  componentDidMount() {
    this.unlisten = this.props.history.listen((url) => {
      if(url.pathname.indexOf('/'+ASSET_TYPE_NFT) >= 0) {
        this.setState({activeTab: ASSET_TYPE_NFT});
      }
      else if(url.pathname.indexOf('/'+ASSET_TYPE_LIQUID) >= 0) {
        this.setState({activeTab: ASSET_TYPE_LIQUID});
      }
      else if(url.pathname.indexOf('/'+ASSET_TYPE_DIGITAL) >= 0) {
        this.setState({activeTab: ASSET_TYPE_DIGITAL});
      }
    });
    window.addEventListener(EVENT_PROVIDER_BLOCKCHAIN_CHANGE, this.onBlockchainChanged);
  }

  componentWillUnmount() {
    this.unlisten();
    window.removeEventListener(EVENT_PROVIDER_BLOCKCHAIN_CHANGE, this.onBlockchainChanged);
  }

  onBlockchainChanged = (e) => { 
    this.setState({}); 
  }

  onTabChange = (event, newValue) => {
    if(newValue === 'ScanQR') {
      this.props.history.push(ROUTE_HOME_SCANNER);
      return;
    }
    this.props.changeAssetType(null, newValue);
    this.setState({activeTab: newValue});
  };

  render() {
    const { safeArea } = this.props;
    const activeBlockchain = ProviderUtils.activeBlockchain();
    return (
      <AppBar position="fixed" style={(safeArea && safeArea.bottom? {paddingBottom: safeArea.bottom+'px', top: "auto", bottom: 0 }:{top: "auto", bottom: 0})}>
        <BottomNavigation value={this.state.activeTab} onChange={this.onTabChange}  >
          <BottomNavigationAction label="Assets" value={ASSET_TYPE_NFT} icon={<img src="/img/iown-assets.svg" alt="Assets" style={{height: "2em"}} />} />
            <BottomNavigationAction label="Scan QR" value={'ScanQR'} className="bottom-scanner-action" icon={<img src="/img/iown-blue-icon2.png" alt="Scan QR" style={{height:"80px"}} />} />
            { activeBlockchain === BLOCKCHAIN_ETH && (<BottomNavigationAction label="Wallet" value={ASSET_TYPE_LIQUID} icon={<img src={getCurrencyIcon(CURRENCY_ETH)} alt="ETH" style={{height: "2em"}} />} />)}
            { activeBlockchain === BLOCKCHAIN_BSC && (<BottomNavigationAction label="Wallet" value={ASSET_TYPE_LIQUID} icon={<img src={getCurrencyIcon(CURRENCY_BNB)} alt="BSC" style={{height: "2em"}} />} />)}
            { activeBlockchain === BLOCKCHAIN_MATIC && (<BottomNavigationAction label="Wallet" value={ASSET_TYPE_LIQUID} icon={<img src={getCurrencyIcon(CURRENCY_MATIC)} alt="MATIC" style={{height: "2em"}} />} />)}
        </BottomNavigation>
      </AppBar>
    );
  };
};

export default withRouter(BottomBar);