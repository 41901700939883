import React from 'react'
import {Field, reduxForm} from 'redux-form'
import CustomInput from '../Field';
import {password, passwordConfirm, required} from "../../../utils/validation";
import Button from "@material-ui/core/Button";

const SignUpEndForm = props => {
    const {handleSubmit, btnDisabled, btnHidden} = props;
    return (
        <form onSubmit={handleSubmit}>
            <Field
                name="password"
                component={CustomInput}
                type="password"
                validate={[required, password]}
                label="Password"
                required
                autoComplete="current-password"
            />
            <Field
                name="confirmpass"
                component={CustomInput}
                type="password"
                validate={[required, passwordConfirm]}
                label="Confirm Password"
                required
                autoComplete="off"
            />
            { !btnHidden && (<Button
                type="submit"
                className="spaced__btn"
                fullWidth
                variant="contained"
                disabled={btnDisabled}
                color="primary"
            >
                Create
            </Button>)}
        </form>
    )
}

export default reduxForm({
    form: 'signup-end',
    destroyOnUnmount: true,
})(SignUpEndForm)