import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { FormGroup, FormControlLabel, Typography, Checkbox } from '@material-ui/core';
import CustomInput from '../Field';
import {required, validIban, validSwift} from "../../../utils/validation";

import {
    getSenderBankValues 
} from '../../../ducks/FormData';

let SenderBankForm = props => {
  const [ showCorresponding, setShowCorresponding ] = React.useState(props.initialValues.COR_BANK_NAME && props.initialValues.COR_BANK_NAME.length > 0);
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
		<Field name="BANK_NAME"
            component={CustomInput}
            type="text"
            label="Your Bank"
            validate={[required]}
            required
            autoComplete={"off"}
        />
        <Field name="BRANCH_NAME"
            component={CustomInput}
            type="text"
            label="Your Branch"
            validate={[required]}
            required
            autoComplete={"off"}
        />
        <Field name="IBAN"
            component={CustomInput}
            type="text"
            label="Your Account IBAN"
            validate={[required, validIban]}
            required
            autoComplete={"off"}
        />
        <Field name="SWIFT"
            component={CustomInput}
            type="text"
            label="Your Account Swift Code"
            validate={[required, validSwift]}
            required
            autoComplete={"off"}
        />
        <Field name="BENEFICIARY_NAME"
            component={CustomInput}
            type="text"
            label="Account Holder Name"
            validate={[required]}
            required
            autoComplete={"name"}
        />
        <Field name="BRANCH_ADDRESS"
            component={CustomInput}
            type="text"
            label="Your Address"
            validate={[required]}
            required
            autoComplete={"address-line-1"}
        />
        <Typography variant="subtitle2" gutterBottom>
          <FormGroup aria-label="position" row className="checkbox-full">
              <FormControlLabel
                value="showCorresponding"
                control={<Checkbox color="primary" onChange={ () => { setShowCorresponding(!showCorresponding); } } />}
                label="Corresponding Bank"
                labelPlacement="end"
              />
          </FormGroup>
        </Typography>
        { showCorresponding && (<Field name="COR_BANK_NAME"
            component={CustomInput}
            type="text"
            label="Corresponding Bank Name"
            validate={[]}
            autoComplete={"off"}
        />)}
        { showCorresponding && (<Field name="COR_BANK_SWIFT"
            component={CustomInput}
            type="text"
            label="Corresponding Bank Swift"
            validate={[validSwift]}
            autoComplete={"off"}
        />)}
	</form>
   )
}

SenderBankForm = reduxForm({
  form: 'sender-bank-form',
})(SenderBankForm);


SenderBankForm = connect(
  state => ({
    initialValues: state.FormData.SenderBank,
    formValues: getFormValues('sender-bank-form')(state), //linked here to hide fee + total if they're empty
  }),
  { load: getSenderBankValues }               // bind FormData values
)(SenderBankForm);

export default SenderBankForm;


