import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import history from '../../history';
import CustomInput from "../../components/Forms/Field";
import { required } from "../../utils/validation";
import { resetSignIn } from "../../ducks/signin";

class CheckPassword extends React.Component {
  componentDidMount() {
    this.props.resetSignIn();
  }

  componentWillUnmount() {
    this.props.resetSignIn();
  }

  goBack = () => {
    if (this.props.goBack) this.props.goBack();
    else history.goBack();
  };

  render() {
    const {
        buttonName,
        handleSubmit,
        errors,
        signin,
    } = this.props;
    const  { failed, error } = errors || signin;
    return (
      <form onSubmit={handleSubmit} style={{marginBottom: "2em"}}>
        <Field
            name="password"
            component={CustomInput}
            type="password"
            validate={[required]}
            label="Type your password"
            required
            autoComplete={"current-password"}
        />
        {failed && (
            <div className="register__error" dangerouslySetInnerHTML={{ __html: error }}></div>
        )}
        <Button
            type="submit"
            className="spaced__btn"
            fullWidth
            variant="contained"
            color="primary"
        >
            {buttonName || 'Continue'}
        </Button>
        <Box pt={2}>
            <Button onClick={this.goBack} fullWidth variant="contained" color="default">
                Back
            </Button>
        </Box>
      </form>
    )
  }
}


const mapStateToProps = state => ({
  signin: state.signin,
});

const mapDispatchToProps = {
  resetSignIn
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'check-password',
    enableReinitialize: true,
  }),
)(CheckPassword);