import { handleFetch } from "../utils/fetch";
import { performResult } from "../utils/stateManipulator";
import { transformError } from "../utils/transformErrors";
import {
    API_IN4X_PROFILE, API_IN4X_DEPOSIT_INFO,
    API_IN4X_BANK_INFO_GET, API_IN4X_BANK_INFO_SET,
    API_IN4X_EXCH_BASE_CURRENCIES,
    API_IN4X_EXCH_RATE, API_IN4X_FEE_SCHEDULE,
    API_IN4X_EXCH_REQ_CREATE, API_IN4X_EXCH_REQ,
    API_IN4X_CASH_LOCATIONS_GET, API_IN4X_CASH_REQ_CREATE, API_IN4X_CASH_REQ,
    API_IN4X_PROFILE_CREATE,
    API_IN4X_PAYMENT_INFO,
    API_IN4X_WIRE_TRANSFER_REQ_CREATE, API_IN4X_WIRE_TRANSFER_REQ,
    API_IN4X_PAYMENT_REQ_CREATE, API_IN4X_PAYMENT_UPDATE,
    API_IN4X_TRANSACTION_HISTORY,
} from "../constants/apiRoutes";
import ProviderUtils from '../utils/blockchain/ProviderUtils';
import GtagUtil from "../utils/gtagUtils";
import { exRateIdxKey } from "../utils/in4xUtils";

const initialState = {
    in4xBaseCurrencies: [],
    in4xCurrenciesLoading: false,
    in4xCurrenciesLoaded: false,
	depositInfo: {},
    exRate: {}, //Deprecated in favor of exRates
    exRates: {},
    trxReq: {},
    activeCurrency: {},
    error: null,
    in4xAccountCreated: false,
    in4xProfileLoading: false,
    profileLoaded: false,
    profile: {},
    in4xpaymentRequestLoading: false,
    in4xpaymentRequestLoaded: false,
    in4xPayment: {},
    in4xPaymentLoading: false,
    in4xPaymentLoaded: false,
    in4xInfoLoading: false,
    in4xInfoLoaded: false,
    in4xBankLoading: false,
    in4xBankSet: false,
    bankInfo: {},
    in4xRateLoading: false,
    in4xExchangeRequest: {},
    in4xExchangeLoading: false,
    in4xExchangeLoaded: false,
    transferInfo: {},
    in4xTransferLoading: false,
    in4xTransferLoaded: false,
    transactionList: {},
    transactionsTotal: 0,
    in4xHistoryLoading: false,
    in4xHistoryLoaded: false,
    in4xCashLocations: {},
    in4xCashLocationsLoading: false,
    in4xCashLocationsLoaded: false,
    in4xCashTransaction: {},
    in4xCashTransactionLoading: false,
    in4xCashTransactionLoaded: false,
    in4xFees: {},
    in4xLimits: {},
    in4xFeesLoading: false,
    in4xFeesLoaded: false,
    in4xSwapTransaction: {},
    in4xSwapTransactionLoading: false,
    in4xSwapTransactionLoaded: false,
};

const CHANGE_ACTIVE_CURRENCY = 'CHANGE_ACTIVE_CURRENCY';

const IN4X_CURRENCIES_SENT = 'IN4X_CURRENCIES_SENT';
const IN4X_CURRENCIES_SUCCESS = 'IN4X_CURRENCIES_SUCCESS';
const IN4X_CURRENCIES_FAIL = 'IN4X_CURRENCIES_FAIL';

const IN4X_CREATE_SENT = "IN4X_CREATE_SENT";
const IN4X_CREATE_SUCCESS = "IN4X_CREATE_SUCCESS";
const IN4X_CREATE_FAIL = "IN4X_CREATE_FAIL";

const IN4X_PROFILE_SENT = "IN4X_PROFILE_SENT";
const IN4X_PROFILE_SUCCESS = "IN4X_PROFILE_SUCCESS";
const IN4X_PROFILE_FAIL = "IN4X_PROFILE_FAIL";

const IN4X_PAYMENT_REQ_SENT = "IN4X_PAYMENT_REQ_SENT";
const IN4X_PAYMENT_REQ_SUCCESS = "IN4X_PAYMENT_REQ_SUCCESS";
const IN4X_PAYMENT_REQ_FAIL = "IN4X_PAYMENT_REQ_FAIL";

const IN4X_PAYMENT_SENT = "IN4X_PAYMENT_SENT";
const IN4X_PAYMENT_SUCCESS = "IN4X_PAYMENT_SUCCESS";
const IN4X_PAYMENT_FAIL = "IN4X_PAYMENT_FAIL";

const IN4X_QUOTES_SENT = "IN4X_QUOTES_SENT";
const IN4X_QUOTES_SUCCESS = "IN4X_QUOTES_SUCCESS";
const IN4X_QUOTES_FAIL = "IN4X_QUOTES_FAIL";

const IN4X_DEPOSIT_INFO_SENT = "IN4X_DEPOSIT_INFO_SENT";
const IN4X_DEPOSIT_INFO_SUCCESS = "IN4X_DEPOSIT_INFO_SUCCESS";
const IN4X_DEPOSIT_INFO_FAILED = "IN4X_DEPOSIT_INFO_FAILED";

const IN4X_BANK_SENT = 'IN4X_BANK_SENT';
const IN4X_BANK_SUCCESS = 'IN4X_BANK_SUCCESS';
const IN4X_BANK_FAIL = 'IN4X_BANK_FAIL';

const IN4X_RATE_SENT = 'IN4X_RATE_SENT';
const IN4X_RATE_SUCCESS = 'IN4X_RATE_SUCCESS';
const IN4X_RATE_FAIL = 'IN4X_RATE_FAIL';

const IN4X_WIRE_TRANSFER_REQ_SENT = 'IN4X_WIRE_TRANSFER_REQ_SENT';
const IN4X_WIRE_TRANSFER_REQ_SUCCESS = 'IN4X_WIRE_TRANSFER_REQ_SUCCESS';
const IN4X_WIRE_TRANSFER_REQ_FAIL = 'IN4X_WIRE_TRANSFER_REQ_FAIL';

const IN4X_WIRE_TRANSFER_SENT = 'IN4X_WIRE_TRANSFER_SENT';
const IN4X_WIRE_TRANSFER_SUCCESS = 'IN4X_WIRE_TRANSFER_SUCCESS';
const IN4X_WIRE_TRANSFER_FAIL = 'IN4X_WIRE_TRANSFER_FAIL';

const IN4X_EXCHANGE_REQ_SENT = 'IN4X_EXCHANGE_REQ_SENT';
const IN4X_EXCHANGE_REQ_SUCCESS = 'IN4X_EXCHANGE_REQ_SUCCESS';
const IN4X_EXCHANGE_REQ_FAIL = 'IN4X_EXCHANGE_REQ_FAIL';

const IN4X_FEES_SENT = 'IN4X_FEES_SENT';
const IN4X_FEES_SUCCESS = 'IN4X_FEES_SUCCESS';
const IN4X_FEES_FAIL = 'IN4X_FEES_FAIL';

const IN4X_CASH_LOCATIONS_SENT = 'IN4X_CASH_LOCATIONS_SENT';
const IN4X_CASH_LOCATIONS_SUCCESS = 'IN4X_CASH_LOCATIONS_SUCCESS';
const IN4X_CASH_LOCATIONS_FAIL = 'IN4X_CASH_LOCATIONS_FAIL';

const IN4X_CASH_REQ_SENT = 'IN4X_CASH_REQ_SENT';
const IN4X_CASH_REQ_SUCCESS = 'IN4X_CASH_REQ_SUCCESS';
const IN4X_CASH_REQ_FAIL = 'IN4X_CASH_REQ_FAIL';

const IN4X_SWAP_REQ_SENT = 'IN4X_SWAP_REQ_SENT';
const IN4X_SWAP_REQ_SUCCESS = 'IN4X_SWAP_REQ_SUCCESS';
const IN4X_SWAP_REQ_FAIL = 'IN4X_SWAP_REQ_FAIL';

const IN4X_EXCHANGE_TRX_SENT = "IN4X_EXCHANGE_TRX_SENT";
const IN4X_EXCHANGE_TRX_SUCCESS = "IN4X_EXCHANGE_TRX_SUCCESS";
const IN4X_EXCHANGE_TRX_FAIL = "IN4X_EXCHANGE_TRX_FAIL";

const IN4X_PAYMENT_UPDATE_SENT = "IN4X_PAYMENT_UPDATE_SENT";
const IN4X_PAYMENT_UPDATE_SUCCESS = "IN4X_PAYMENT_UPDATE_SUCCESS";
const IN4X_PAYMENT_UPDATE_FAIL = "IN4X_PAYMENT_UPDATE_FAIL";

const IN4X_HISTORY_SENT = "IN4X_HISTORY_SENT";
const IN4X_HISTORY_SUCCESS = "IN4X_HISTORY_SUCCESS";
const IN4X_HISTORY_FAIL = "IN4X_HISTORY_FAIL";

const RESET_IN4X = 'rnx';

export const changeActiveCurrency = (activeCurrencyObj) => ({ type: CHANGE_ACTIVE_CURRENCY,  activeCurrencyObj });

export const getFiatCurrencies = (location) => dispatch => {
    dispatch({type: IN4X_CURRENCIES_SENT});
    return handleFetch(API_IN4X_EXCH_BASE_CURRENCIES, "POST", { location })
        .then(res => {
            performResult(res, () => {
            dispatch({ type: IN4X_CURRENCIES_SUCCESS, data: res.payload });
        })}
        )
        .catch(err => {
            dispatch({ type: IN4X_CURRENCIES_FAIL, err: transformError(err)})
         });
}

export const createIn4xProfile = () => dispatch => {
    dispatch({type: IN4X_CREATE_SENT});
    return handleFetch(API_IN4X_PROFILE_CREATE, "POST", {})
        .then(res => {
            performResult(res, () => {
            dispatch({ type: IN4X_CREATE_SUCCESS, data: res.payload });
        })}
        )
        .catch(err => {
            dispatch({ type: IN4X_CREATE_FAIL, err: transformError(err)})
         });
}

export const getIn4xProfile = () => dispatch => {
    dispatch({type: IN4X_PROFILE_SENT});
    return handleFetch(API_IN4X_PROFILE, "GET")
        .then(res => performResult(res, () => {
            dispatch({ type: IN4X_PROFILE_SUCCESS, data: res.payload });
        }))
        .catch(err => dispatch({ type: IN4X_PROFILE_FAIL, err: transformError(err)}));
}

export const createIn4xPaymentRequest = (req) => dispatch => {
    dispatch({type: IN4X_PAYMENT_REQ_SENT});
    return handleFetch(API_IN4X_PAYMENT_REQ_CREATE, "POST", req)
        .then(res => performResult(res, () => {
            dispatch({ type: IN4X_PAYMENT_REQ_SUCCESS, data: res.payload });
        }))
        .catch(err => dispatch({ type: IN4X_PAYMENT_REQ_FAIL, err: transformError(err)}));
}

export const getIn4xPaymentInfo = (ref, type) => dispatch => {
    dispatch({type: IN4X_PAYMENT_SENT});
    return handleFetch(API_IN4X_PAYMENT_INFO, "POST", {ref, type})
        .then(res => performResult(res, () => {
            dispatch({ type: IN4X_PAYMENT_SUCCESS, data: res.payload });
        }))
        .catch(err => dispatch({ type: IN4X_PAYMENT_FAIL, err: transformError(err)}));   
}

export const getIn4xDepositInfo = () => dispatch => {
    dispatch({ type: IN4X_DEPOSIT_INFO_SENT });
    return handleFetch(API_IN4X_DEPOSIT_INFO, "GET")
        .then(res => performResult(res, () => {
            dispatch({ type: IN4X_DEPOSIT_INFO_SUCCESS, data: res.payload });
        }))
        .catch(err => dispatch({ type: IN4X_DEPOSIT_INFO_FAILED, err: transformError(err)}));
};

export const getIn4xBank = () => dispatch => {
    dispatch({ type: IN4X_BANK_SENT });
    return handleFetch(API_IN4X_BANK_INFO_GET, "GET")
        .then(res => performResult(res, () => {
            dispatch({ type: IN4X_BANK_SUCCESS, data: res.payload });
        }))
        .catch(err => dispatch({ type: IN4X_BANK_FAIL, err: transformError(err)}));
};

export const setIn4xBank = (bankInfo) => dispatch => {
    dispatch({type: IN4X_BANK_SENT});
    return handleFetch(API_IN4X_BANK_INFO_SET, "POST", bankInfo)
        .then(res => performResult(res, () => {
            dispatch({ type: IN4X_BANK_SUCCESS, data: res.payload });
        }))
        .catch(err => dispatch({ type: IN4X_BANK_FAIL, err: transformError(err)}));
};

export const createIn4xWireTransfer = (transferInfo) => dispatch => {
    const network = ProviderUtils.activeNetwork();
    const blockchain = ProviderUtils.activeBlockchain();

    dispatch({type: IN4X_WIRE_TRANSFER_REQ_SENT});
    return handleFetch(API_IN4X_WIRE_TRANSFER_REQ_CREATE, "POST", {...transferInfo, isTest: network !== 'mainnet', blockchain, network})
        .then(res => performResult(res, () => {
            dispatch({ type: IN4X_WIRE_TRANSFER_REQ_SUCCESS, data: res.payload });
        }))
        .catch(err => dispatch({ type: IN4X_WIRE_TRANSFER_REQ_FAIL, err: transformError(err)}));
}

export const getIn4xWireTransfer = (ref) => dispatch => {
    dispatch({type: IN4X_WIRE_TRANSFER_SENT});
    return handleFetch(API_IN4X_WIRE_TRANSFER_REQ, "POST", {ref})
        .then(res => performResult(res, () => {
            dispatch({ type: IN4X_WIRE_TRANSFER_SUCCESS, data: res.payload });
        }))
        .catch(err => dispatch({ type: IN4X_WIRE_TRANSFER_FAIL, err: transformError(err)}));
}

export const getExchangeRate = (base, quote) => dispatch => {
    const network = ProviderUtils.activeNetwork();
    const blockchain = ProviderUtils.activeBlockchain();
    dispatch({type: IN4X_RATE_SENT});
    return handleFetch(API_IN4X_EXCH_RATE, "POST", { base, quote, blockchain, network })
        .then(res => performResult(res, () => {
            dispatch({ type: IN4X_RATE_SUCCESS, data: res.payload });
        }))
        .catch(err => dispatch({ type: IN4X_RATE_FAIL, err: transformError(err)}));   
};

export const getFeeSchedule = () => dispatch => {
    dispatch({type: IN4X_FEES_SENT});
    const network = ProviderUtils.activeNetwork();
    const blockchain = ProviderUtils.activeBlockchain();
    return handleFetch(API_IN4X_FEE_SCHEDULE, "POST", {blockchain, network})
        .then(res => performResult(res, () => {
            dispatch({ type: IN4X_FEES_SUCCESS, data: res.payload });
        }))
        .catch(err => dispatch({ type: IN4X_FEES_FAIL, err: transformError(err)}));
}

export const createCardExchangeRequest = (params, base, quote) => dispatch => {
    const network = ProviderUtils.activeNetwork();
    const blockchain = ProviderUtils.activeBlockchain();

    dispatch({type: IN4X_EXCHANGE_REQ_SENT});
    return handleFetch(API_IN4X_EXCH_REQ_CREATE, "POST", {...params,
            base: base, 
            quote: quote,
            blockchain,
            network,
            isTest: network !== 'mainnet'
        }).then(res => performResult(res, () => {
            dispatch({ type: IN4X_EXCHANGE_REQ_SUCCESS, data: res.payload });
        })).catch(err => dispatch({ type: IN4X_EXCHANGE_REQ_FAIL, err: transformError(err)}));
};

export const getExchangeRequest = (target) => dispatch => {
    const network = ProviderUtils.activeNetwork();
    const blockchain = ProviderUtils.activeBlockchain();

    dispatch({type: IN4X_EXCHANGE_TRX_SENT});
    return handleFetch(API_IN4X_EXCH_REQ, "POST", { target, blockchain, network })
           .then(res => performResult(res, () => {
            dispatch({ type: IN4X_EXCHANGE_TRX_SUCCESS, data: res.payload });
        })).catch(err => dispatch({ type: IN4X_EXCHANGE_TRX_FAIL, err: transformError(err)}));
};

export const getCashRequestLocations = () => dispatch => {
    dispatch({type: IN4X_CASH_LOCATIONS_SENT});
    return handleFetch(API_IN4X_CASH_LOCATIONS_GET, "GET")
           .then(res => performResult(res, () => {
            dispatch({ type: IN4X_CASH_LOCATIONS_SUCCESS, data: res.payload });
        })).catch(err => dispatch({ type: IN4X_CASH_LOCATIONS_FAIL, err: transformError(err)}));
}

export const createCashRequest = (req) => dispatch => {
    const network = ProviderUtils.activeNetwork();
    const blockchain = ProviderUtils.activeBlockchain();

    dispatch({type: IN4X_CASH_REQ_SENT});
    return handleFetch(API_IN4X_CASH_REQ_CREATE, "POST", { 
            ...req,
            blockchain,
            network,
            isTest: network !== 'mainnet'
        }).then(res => performResult(res, () => {
            dispatch({ type: IN4X_CASH_REQ_SUCCESS, data: res.payload });
        })).catch(err => dispatch({ type: IN4X_CASH_REQ_FAIL, err: transformError(err)}));
}

export const getIn4xCashTransfer = (target) => dispatch => {
    dispatch({type: IN4X_CASH_REQ_SENT});
    return handleFetch(API_IN4X_CASH_REQ, "POST", {target})
        .then(res => performResult(res, () => {
            dispatch({ type: IN4X_CASH_REQ_SUCCESS, data: res.payload });
        }))
        .catch(err => dispatch({ type: IN4X_CASH_REQ_FAIL, err: transformError(err)}));
}

export const updatePaymentInfo = (paymentReference, transactionHash, currency, amountPaid) => dispatch => {
    dispatch({type: IN4X_PAYMENT_UPDATE_SENT});
    return handleFetch(API_IN4X_PAYMENT_UPDATE, "POST", { 
            ref: paymentReference, 
            hash: transactionHash, 
            currency: currency.toLowerCase(),
            amount: amountPaid
        }).then(res => performResult(res, () => {
            dispatch({ type: IN4X_PAYMENT_UPDATE_SUCCESS, data: res.payload });
        })).catch(err => dispatch({ type: IN4X_PAYMENT_UPDATE_FAIL, err: transformError(err)}));
}

export const loadTransactionHistory = (transactionType, page, perPage) => dispatch => {
    if(!transactionType) {
        return;
    }
    dispatch({type: IN4X_HISTORY_SENT});
    return handleFetch(API_IN4X_TRANSACTION_HISTORY, "POST", { 
            type: transactionType,
            page: page,
            per_page: perPage
        }).then(res => performResult(res, () => {
            dispatch({ type: IN4X_HISTORY_SUCCESS, data: res.payload });
        })).catch(err => dispatch({ type: IN4X_HISTORY_FAIL, err: transformError(err)}));
}

export const resetIn4x = () => ({ type: RESET_IN4X });

export const in4x = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_ACTIVE_CURRENCY:
            return {...state, activeCurrency: action.activeCurrencyObj};
        case IN4X_CREATE_SENT:
            return { ...state, in4xAccountCreating: true, in4xAccountCreated: false, profile: {} };
        case IN4X_CREATE_SUCCESS:
            GtagUtil.trackActivateAccount();
            return { ...state, in4xAccountCreating: false, in4xAccountCreated: true };
        case IN4X_CREATE_FAIL:
            return { ...state, in4xAccountCreating: false, in4xAccountCreated: false, error: action.err };
        case IN4X_PROFILE_SENT:
            return { ...state, in4xProfileLoading: true, profileLoaded: false };
        case IN4X_PROFILE_SUCCESS:
            return { ...state, profile: action.data, in4xProfileLoading: false, profileLoaded: true };
    	case IN4X_PROFILE_FAIL:        
            return { ...state, in4xProfileLoading: false, profileLoaded: false };
        case IN4X_PAYMENT_REQ_SENT:
            return { ...state, in4xpaymentRequestLoading: true, in4xpaymentRequestLoaded: false, in4xPayment: {}};
        case IN4X_PAYMENT_REQ_SUCCESS:
            return { ...state, in4xpaymentRequestLoading: false, in4xpaymentRequestLoaded: true, in4xPaymentLoaded: true, in4xPayment: action.data };
        case IN4X_PAYMENT_REQ_FAIL:
            return { ...state, in4xpaymentRequestLoading: false, in4xpaymentRequestLoaded: false, error: action.err };
        case IN4X_PAYMENT_SENT:
            return { ...state, in4xPaymentLoading: true, in4xPaymentLoaded: false, in4xPayment: {} };
        case IN4X_PAYMENT_SUCCESS:
            if(action.data) {
                if(!action.data.AMOUNT_CUR) {
                    action.data.AMOUNT_CUR = 'USD';
                }
            }
            return { ...state, in4xPayment: action.data, in4xPaymentLoading: false, in4xPaymentLoaded: true };
        case IN4X_PAYMENT_FAIL:
            return { ...state, in4xPaymentLoading: false, in4xPaymentLoaded: false, in4xPayment: {}, error: action.err };
        case IN4X_QUOTES_SENT:
            return { ...state, in4xPaymentQuotes: {}, in4xPaymentQuotesLoading: true, in4xPaymentQuotesLoaded: false };
        case IN4X_QUOTES_SUCCESS:
            return { ...state, in4xPaymentQuotes: action.data, in4xPaymentQuotesLoading: false, in4xPaymentQuotesLoaded: true };    
        case IN4X_QUOTES_FAIL:
            return { ...state, in4xPaymentQuotesLoading: false, in4xPaymentQuotesLoaded: false, error: action.err };
        case IN4X_DEPOSIT_INFO_SENT:
            return { ...state, in4xInfoLoading: false, in4xInfoLoaded: false, error: action.err, profile: {} };
        case IN4X_DEPOSIT_INFO_SUCCESS:
            return { ...state, depositInfo: action.data, in4xInfoLoading: false, infoLoaded: true };
        case IN4X_DEPOSIT_INFO_FAILED:
            return { ...state, in4xInfoLoading: false, in4xInfoLoaded: false, error: action.err, depositInfo: {} };
        case IN4X_BANK_SENT:
            return { ...state, in4xBankLoading: true, in4xBankSet: false, error: null };
        case IN4X_BANK_SUCCESS:
            return { ...state, in4xBankLoading: false, in4xBankSet: true, bankInfo: action.data, error: null };
        case IN4X_BANK_FAIL: 
            return { ...state, in4xBankLoading: false, in4xBankSet: false, error: action.err, bankInfo: {} };
        case IN4X_WIRE_TRANSFER_REQ_SENT: 
            return { ...state, in4xTransferLoading: true, in4xTransferLoaded: false, error: false, transferInfo: {} };
        case IN4X_WIRE_TRANSFER_REQ_SUCCESS: 
            const wtPrice = action.data.AMOUNT_TOKEN > 0? action.data.AMOUNT/action.data.AMOUNT_TOKEN : 0;
            GtagUtil.trackPurchase(action.data.REFERENCE_ID, action.data.AMOUNT, 'USD', action.data.TOPUP_CUR, action.data.BLOCKCHAIN, wtPrice, action.data.AMOUNT_TOKEN);
            return { ...state, in4xTransferLoading: false, in4xTransferLoaded: true, error: false, transferInfo: action.data };
        case IN4X_WIRE_TRANSFER_REQ_FAIL: 
            return { ...state, in4xTransferLoading: false, in4xTransferLoaded: false, error: action.err, transferInfo: {} };
        case IN4X_WIRE_TRANSFER_SENT:
            return { ...state, in4xTransferLoading: true, in4xTransferLoaded: false, error: false, transferInfo: {} };
        case IN4X_WIRE_TRANSFER_SUCCESS: 
            return { ...state, in4xTransferLoading: false, in4xTransferLoaded: true, error: false, transferInfo: action.data };
        case IN4X_WIRE_TRANSFER_FAIL: 
            return { ...state, in4xTransferLoading: false, in4xTransferLoaded: false, error: action.err, transferInfo: {} };
        case IN4X_RATE_SENT:
            return { ...state, in4xRateLoading: true, exRate: {}, error: null };
        case IN4X_RATE_SUCCESS:
            let exRates = {...state.exRates };
            const key = exRateIdxKey(action.data.base, action.data.quote);
            exRates[key] = action.data;
            return { ...state, in4xRateLoading: false, exRate: action.data, exRates, error: null };
        case IN4X_RATE_FAIL:
            return { ...state, in4xRateLoading: false, error: action.err, exRate: {} };
        case IN4X_CURRENCIES_SENT:
            return { ...state, in4xCurrenciesLoading: true, in4xCurrenciesLoaded: false, error: null, in4xBaseCurrencies: [] };
        case IN4X_CURRENCIES_SUCCESS:
            return { ...state, in4xCurrenciesLoading: false, in4xCurrenciesLoaded: true, error: null, in4xBaseCurrencies: action.data };
        case IN4X_CURRENCIES_FAIL:
            return { ...state, in4xCurrenciesLoading: false, in4xCurrenciesLoaded: false, error: action.err, in4xBaseCurrencies: [] };
        case IN4X_EXCHANGE_REQ_SENT:
            return { ...state, in4xExchangeLoading: true, trxReq: {}, error: null };
        case IN4X_EXCHANGE_REQ_SUCCESS:
            const ctPrice = action.data.AMOUNT_TOKEN > 0? action.data.AMOUNT/action.data.AMOUNT_TOKEN : 0;
            GtagUtil.trackPurchase(action.data.REFERENCE_ID, action.data.AMOUNT, 'USD', action.data.TOPUP_CUR, action.data.BLOCKCHAIN, ctPrice, action.data.AMOUNT_TOKEN);
            return { ...state, in4xExchangeLoading: false, trxReq: action.data, error: null };
        case IN4X_EXCHANGE_REQ_FAIL:
            return { ...state, in4xExchangeLoading: false, error: action.err, trxReq: {} };
        case IN4X_FEES_SENT:
            return { ...state, in4xFeesLoading: true, in4xFeesLoaded: false, in4xFees: {}, in4xLimits: {}, error: null };
        case IN4X_FEES_SUCCESS:
            return { ...state, in4xFeesLoading: false, in4xFeesLoaded: true, in4xFees: action.data.fees, in4xLimits: action.data.limits, error: null };
        case IN4X_FEES_FAIL:
            return { ...state, in4xFeesLoading: false, in4xFeesLoaded: false, error: action.err, in4xFees: {}, in4xLimits: {} };
        case IN4X_EXCHANGE_TRX_SENT:
            return { ...state, in4xExchangeLoading: true, in4xExchangeLoaded: false, error: null, in4xExchangeRequest: {} };
        case IN4X_EXCHANGE_TRX_SUCCESS:
            return { ...state, in4xExchangeLoading: false, in4xExchangeLoaded: true, in4xExchangeRequest: action.data };
        case IN4X_EXCHANGE_TRX_FAIL:
            return { ...state, in4xExchangeLoading: false, in4xExchangeLoaded: false, error: action.err, in4xExchangeRequest: {} };
        case IN4X_CASH_LOCATIONS_SENT:
            return { ...state, in4xCashLocationsLoading: true, in4xCashLocationsLoaded: false, error: null, in4xCashLocations: {} };
        case IN4X_CASH_LOCATIONS_SUCCESS:
            return { ...state, in4xCashLocationsLoading: false, in4xCashLocationsLoaded: true, in4xCashLocations: action.data };
        case IN4X_CASH_LOCATIONS_FAIL:
            return { ...state, in4xCashLocationsLoading: false, in4xCashLocationsLoaded: false, error: action.err, in4xCashLocations: {} };
        case IN4X_CASH_REQ_SENT:
            return { ...state, in4xCashTransactionLoading: true, in4xCashTransactionLoaded: false, error: null, in4xCashTransaction: {} };
        case IN4X_CASH_REQ_SUCCESS:
            return { ...state, in4xCashTransactionLoading: false, in4xCashTransactionLoaded: true, in4xCashTransaction: action.data };
        case IN4X_CASH_REQ_FAIL:
            return { ...state, in4xCashTransactionLoading: false, in4xCashTransactionLoaded: false, error: action.err, in4xCashTransaction: {} };
        case IN4X_PAYMENT_UPDATE_SENT:
            return { ...state, in4xPaymentLoading: true, in4xPaymentLoaded: false }; //No clear, ususally in4xPayment is valid
        case IN4X_PAYMENT_UPDATE_SUCCESS:
            return { ...state, in4xPaymentLoading: false, in4xPaymentLoaded: true, in4xPayment: action.data };
        case IN4X_PAYMENT_UPDATE_FAIL:
            return { ...state, in4xPaymentLoading: false, in4xPaymentLoaded: false, error: action.err };
        case IN4X_HISTORY_SENT:
            return { ...state, in4xHistoryLoading: true, in4xHistoryLoaded: false, error: null, transactionList: {}, transactionsTotal: 0 };
        case IN4X_HISTORY_SUCCESS:
            return { ...state, in4xHistoryLoading: false, in4xHistoryLoaded: true, error: null, transactionList: action.data.list, transactionsTotal: action.data.total };
        case IN4X_HISTORY_FAIL:
            return { ...state, in4xHistoryLoading: false, in4xHistoryLoaded: false, error: action.err, transactionList: {}, transactionsTotal: 0 };
        case IN4X_SWAP_REQ_SENT:
            return { ...state, in4xSwapTransactionLoading: true, in4xSwapTransactionLoaded: false, error: null, in4xSwapTransaction: {} };
        case IN4X_SWAP_REQ_SUCCESS:
            return { ...state, in4xSwapTransactionLoading: false, in4xSwapTransactionLoaded: true, in4xSwapTransaction: action.data };
        case IN4X_SWAP_REQ_FAIL:
            return { ...state, in4xSwapTransactionLoading: false, in4xSwapTransactionLoaded: false, error: action.err, in4xSwapTransaction: {} };
        case RESET_IN4X:
            return {...initialState};
            
    	default:
            return state;
	}
}