import {GAS_FEE_TYPE_LONDON, GAS_FEE_TYPE_LEGACY} from "../constants/gasFeeTypes";
import {DEFAULT_LIMIT_NATIVE, DEFAULT_LIMIT_MARGIN} from "../constants/defaultGasSettings";

import {handleFetch} from "../utils/fetch";
import {ValidationUtil} from "../utils/validationUtil";
import {performResult} from "../utils/stateManipulator";
import { transformError } from "../utils/transformErrors";
import BNUtils from "../utils/bnUtils";

import {RegExps} from "../constants/regExps";
import {API_GAS_ORACLE} from "../constants/apiRoutes";

const initialState = {
	minGas: DEFAULT_LIMIT_NATIVE,
    feeType: GAS_FEE_TYPE_LONDON,
	estimateGas: false,
    gasLoading: false,
    gasLoaded: false,
    gasMetrics: {},
    defaultSpeed: '',
    isManualSpeed: false,
    selectedSpeed: false,
	gasPrice: {
        value: "",
        error: false,
        helperText: "",
    },
	gasLimit: {
        value: DEFAULT_LIMIT_NATIVE,
        error: false,
        helperText: "",
    },
	baseFeePerGas: 1,
	maxPriorityFeePerGas: {
        value: "",
        error: false,
        helperText: "",
    },
	maxFeePerGas: {
        value: "",
        error: false,
        helperText: "",
    },
    gasReady: false
}

const RESET_GAS = "RESET_GAS";

const LOAD_GAS_SENT = "LOAD_GAS_SENT";
const LOAD_GAS_SUCCESS = "LOAD_GAS_SUCCESS";
const LOAD_GAS_FAILED = "LOAD_GAS_FAILED";

const CHANGE_GAS_SPEED = "CHANGE_GAS_SPEED";
const CHANGE_FEE_TYPE = "CHANGE_FEE_TYPE";
const CHANGE_GAS_LIMIT = "CHANGE_GAS_LIMIT";
const CHANGE_GAS_PRICE = "CHANGE_GAS_PRICE";
const CHANGE_MAX_GAS_PRIORITY_PRICE = "CHANGE_MAX_GAS_PRIORITY_PRICE";
const CHANGE_MAX_GAS_PRICE = "CHANGE_MAX_GAS_PRICE";
const CHANGE_GAS_LIMIT_ESTIMATE = "CHANGE_GAS_LIMIT_ESTIMATE";

export const loadGasOracle = (blockchain, network) => dispatch => {
    dispatch({ type: LOAD_GAS_SENT });
    handleFetch(API_GAS_ORACLE, "POST", {blockchain, network})
        .then(res => performResult(res, () => {
            dispatch({ type: LOAD_GAS_SUCCESS, data: res.payload });
        }))
        .catch(err => dispatch({ type: LOAD_GAS_FAILED, err: transformError(err)}));
};
export const changeSpeed = (selectSpeed) => ({type: CHANGE_GAS_SPEED, selectSpeed });
export const changeFeeType = feeType => ({type: CHANGE_FEE_TYPE, feeType});
export const changeGasEstimate = gasLimit => ({type: CHANGE_GAS_LIMIT_ESTIMATE, gasLimit});
export const changeGasLimit = gasLimit => ({type: CHANGE_GAS_LIMIT, gasLimit});
export const changeGasPrice = gasPrice => ({type: CHANGE_GAS_PRICE, gasPrice});
//London fork:
export const changeMaxGasPriorityPrice  = maxPriorityFeePerGas => ({type: CHANGE_MAX_GAS_PRIORITY_PRICE, maxPriorityFeePerGas});
export const changeGasMaxPrice = maxFeePerGas => ({type: CHANGE_MAX_GAS_PRICE, maxFeePerGas});
export const resetGas = () => ({ type: RESET_GAS });

function isReadyToSubmit (state) {
    const options = state.feeType === GAS_FEE_TYPE_LONDON? {
        notEmpty: ["gasLimit", "maxPriorityFeePerGas", "maxFeePerGas"],
        isValid: ["gasLimit", "maxPriorityFeePerGas", "maxFeePerGas"],
    }: {
        notEmpty: ["gasLimit", "gasPrice"],
        isValid: ["gasLimit", "gasPrice"],
    };
    return ValidationUtil.isReadyToSubmit(state, options);
}

function chooseGasMetrics (selectSpeed, gasMetrics, gasType) {
    let update = {};
    if(gasType === GAS_FEE_TYPE_LONDON) {
        if(gasMetrics && gasMetrics[selectSpeed] && gasMetrics[selectSpeed].maxFeePerGas) {
            update.maxFeePerGas = {value: gasMetrics[selectSpeed].maxFeePerGas, error: false, helperText: ''}
        } else {
            update.maxFeePerGas = {value: '', error: true, helperText: 'No max fee per gas from oracle' }
        }
        if(gasMetrics && gasMetrics[selectSpeed] && gasMetrics[selectSpeed].maxPriorityFeePerGas) {
            update.maxPriorityFeePerGas = {value: gasMetrics[selectSpeed].maxPriorityFeePerGas, error: false, helperText: ''}
        } else {
            update.maxPriorityFeePerGas = {value: '', error: true, helperText: 'No priority fee per gas from oracle' }
        }
    } else {
        if(gasMetrics && gasMetrics[selectSpeed] && gasMetrics[selectSpeed].gasPrice) {
            update.gasPrice = {value: gasMetrics[selectSpeed].gasPrice, error: false, helperText: ''}
        } else {
            update.gasPrice = {value: '', error: true, helperText: 'No gas price from oracle' }
        }
    }
    return update;
}

export const blockchainGas = (state = initialState, action) => {
	let error, errorText, newState, updateMetrics, helperText;

    switch (action.type) {
        case RESET_GAS:
            return {...initialState};
        case LOAD_GAS_SENT:
            return {...state, gasLoading: true, error: false }
        case LOAD_GAS_SUCCESS:
            const defaultSpeed = action.data.defaultSpeed;
            const selectedSpeed = !state.selectedSpeed? action.data.defaultSpeed: state.selectedSpeed; //If user has selected a speed keep it
            if(!state.isManualSpeed) {
                updateMetrics = chooseGasMetrics(selectedSpeed, action.data, state.feeType);
            }
            newState = {...state, gasLoading: false, error: false, gasMetrics: action.data, gasLoaded: true, defaultSpeed, selectedSpeed, ...updateMetrics }
            return {...newState, gasReady: isReadyToSubmit(newState)};

        case LOAD_GAS_FAILED:
            return {...state, gasLoading: false, gasLoaded: false, error: action.err }

        case CHANGE_FEE_TYPE:
        	switch(action.feeType) {
        		case GAS_FEE_TYPE_LONDON:
        			newState = {...state, feeType: GAS_FEE_TYPE_LONDON};
        			break;
        		case GAS_FEE_TYPE_LEGACY:
        			newState = {...state, feeType: GAS_FEE_TYPE_LEGACY};
        			break;
        		default:
        			throw new Error('Invalid gas fee type: '+action.feeType);
        	}
        	return {...newState, gasReady: isReadyToSubmit(newState)};

        case CHANGE_GAS_SPEED:
            updateMetrics = chooseGasMetrics(action.selectSpeed, state.gasMetrics, state.feeType);
            newState = {
                ...state,
                ...updateMetrics,
                selectedSpeed: action.selectSpeed,
                gasLimit: {
                    value: state.estimateGas ? Number(state.estimateGas) + DEFAULT_LIMIT_MARGIN : state?.minGas,
                    error: false,
                    helperText: ''
                },
                isManualSpeed: false
            };
            return {...newState, gasReady: isReadyToSubmit(newState)};

        case CHANGE_GAS_LIMIT:
        	if(action.gasLimit) {
                action.gasLimit = (action.gasLimit+'').replace(/[^0-9]/g, ''); //keeps only digits
            }
            const minGas = state.estimateGas || state.minGas;
            error = !ValidationUtil.isValid(action.gasLimit, RegExps.digitsNotZero, value => +value >= minGas);
            errorText = BNUtils.isAmountValidForBalance(action.gasLimit, minGas, 18)? "Gas Limit less than estimate needed ("+minGas+")": "Gas limit is invalid";
            newState = {...state, gasLimit: {value: action.gasLimit, error: error? true:false, helperText: error ? errorText : ""}};
            return {...newState, gasReady: isReadyToSubmit(newState)};

        case CHANGE_GAS_PRICE:
        	if(action.gasPrice) {
                action.gasPrice = (action.gasPrice+'').replace(/[^0-9.]/g, ''); //keeps only digits
            }
            error = !ValidationUtil.isValid(action.gasPrice, RegExps.digitsNotZero, value => +value >= +state.baseFeePerGas);
			newState = {...state, gasPrice: {value: action.gasPrice.toString(), error: error? true:false, helperText: error ? "Gas price is invalid or too low" : ""}, isManualSpeed: true};
			return {...newState, gasReady: isReadyToSubmit(newState)};

		case CHANGE_MAX_GAS_PRIORITY_PRICE:
		    if(action.maxPriorityFeePerGas) {
                action.maxPriorityFeePerGas = (action.maxPriorityFeePerGas+'').replace(/[^\d.]/g, ''); //keeps only digits
            }
            error = !ValidationUtil.isValid(action.maxPriorityFeePerGas, RegExps.digitsNotZero, value => +value >= 1 && +value < 1000);
            helperText = error? 'Miner Incentive (Max Priority Fee) is invalid': '';
            if(!error && state.gasMetrics && state.gasMetrics.slow && 
                state.gasMetrics.slow.maxPriorityFeePerGas && action.maxPriorityFeePerGas < state.gasMetrics.slow.maxPriorityFeePerGas) {
                helperText = 'Miner Incent (Max Fee Per Gas) is lower than slow recommendation, transaction may get stuck';
            }
            if(!error && action.maxPriorityFeePerGas && state.maxFeePerGas.value && action.maxPriorityFeePerGas > state.maxFeePerGas.value) {
                helperText = 'Gas Price (Max Fee) cannot be lower than Miner Incentive (Max Priority Fee Per Gas)';
            }
            newState = {...state, maxPriorityFeePerGas: { value: action.maxPriorityFeePerGas, error, helperText }, isManualSpeed: true};
           	return {...newState, gasReady: isReadyToSubmit(newState)};

        case CHANGE_MAX_GAS_PRICE:
        	if(action.maxFeePerGas) {
                action.maxFeePerGas = (action.maxFeePerGas+'').replace(/[^\d.]/g, ''); //keeps only digits
            }
            error = !ValidationUtil.isValid(action.maxFeePerGas, RegExps.digitsNotZero, value => +value >= 1 && +value < 1000);
            helperText = error? 'Gas Price (Max Fee Per Gas) is invalid': '';
            if(!error && state.gasMetrics && state.gasMetrics.slow && 
                state.gasMetrics.slow.maxFeePerGas && action.maxFeePerGas < state.gasMetrics.slow.maxFeePerGas) {
                helperText = 'Gas Price (Max Fee) is lower than slow recommendation, transaction may get stuck';
            }
            if(!error && action.maxFeePerGas && state.maxPriorityFeePerGas.value && action.maxFeePerGas < state.maxPriorityFeePerGas.value) {
                helperText = 'Gas Price (Max Fee) cannot be lower than Miner Incentive (Max Priority Fee Per Gas)';
            }
            newState = {...state, maxFeePerGas: { value: !error && action.maxFeePerGas? action.maxFeePerGas + '' : '', error, helperText}, isManualSpeed: true};
            return {...newState, gasReady: isReadyToSubmit(newState)};

       	case CHANGE_GAS_LIMIT_ESTIMATE:
            error = !ValidationUtil.isValid(action.gasLimit, RegExps.digitsNotZero, value => +value >= DEFAULT_LIMIT_NATIVE);
            if(!error && action.gasLimit >= DEFAULT_LIMIT_NATIVE && action.gasLimit > state.gasLimit.value) {
                newState ={...state, gasLimit: {value: Number(action.gasLimit) + DEFAULT_LIMIT_MARGIN, error: false, helperText: ''}}; //10k is a margin extra
            } else {
                newState = state;
            }
            return {...newState, estimateGas: action.gasLimit, gasReady: isReadyToSubmit(newState)}; //margin is not added here

        default:
            return state;        	
    }
}