import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { openLink } from '../../utils/linksOpenPolyfill';

import { Grid, Typography, CircularProgress, Button } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

import Alert from '@material-ui/lab/Alert';

import IndaCoinPaymentDetails from "../../components/IndaCoin/IndaCoinPaymentDetails";

import {
	getExchangeRequest
} from "../../ducks/in4x";

import {
	ROUTE_BUY_TOKEN_STATUS,
	ROUTE_NX_TRANSACTION_HISTORY
} from "../../constants/routes";

class BuyTokensStatus extends React.Component {

	constructor(props) {
  		super(props);
  		this.state = {
  			transaction_id: 0,
  			intervalId: false,
  			in4xExchangeRequest: {}
  		};
  	}

	componentDidMount = async() => {
		const pathname = this.props.history.location.pathname;
		let transaction_id = pathname.substring(pathname.indexOf(ROUTE_BUY_TOKEN_STATUS) + ROUTE_BUY_TOKEN_STATUS.length);
		if(transaction_id > 0) {
			this.setState({transaction_id: transaction_id});
			await this.props.getExchangeRequest(transaction_id);
			const { in4xExchangeRequest }= this.props.in4x;
 			this.setState({in4xExchangeRequest: in4xExchangeRequest});
			if(in4xExchangeRequest && in4xExchangeRequest.STATUS === 'Draft') {
				this.startMonitor();
			}
		}
	}

	componentWillUnmount = () => {
		if(this.state.intervalId) {
			clearInterval(this.state.intervalId);
			this.setState({intervalId: false});
		}
	}

	startMonitor = () => {
		let intervalId = setInterval(async() => {
			await this.props.getExchangeRequest(this.state.transaction_id);
			const { in4xExchangeRequest }= this.props.in4x;
			this.setState({in4xExchangeRequest: in4xExchangeRequest});
			if(in4xExchangeRequest && in4xExchangeRequest.STATUS !== 'Draft') {
				clearInterval(intervalId);
				this.setState({intervalId: false});
			}
		}, 5000);
		this.setState({intervalId: intervalId});
	}

	goBack = () => {
		this.props.history.push(ROUTE_NX_TRANSACTION_HISTORY+'indacoin');
	}

	render() {
		const { intervalId, in4xExchangeRequest } = this.state;
		const { in4xExchangeLoading, error } = this.props.in4x;
		const st = in4xExchangeRequest.STATUS && in4xExchangeRequest.STATUS.toLowerCase();
		const isIncomplete = st === "draft" || st === "cancelled" || st === "failed";

		return (
			<Grid container justifyContent="center" alignItems="center" spacing={1} className="spaced-top">
				<Typography variant="h5" align="center" gutterBottom className='color-nx'>IN4X Payment Information</Typography>
				{ intervalId && (<Grid item xs={12} className="centered-grid">
					<LinearProgress className="color-nx" />
				</Grid> )}
				{ !intervalId && in4xExchangeLoading && (
    			<Grid item xs={12} className="centered-grid">
			    	<CircularProgress />
			    	<Typography className="color-nx" gutterBottom>Loading Payment Information...</Typography>
		    	</Grid>
		    )}
		    <Grid item xs={12} className="centered-grid">
					{ (!in4xExchangeLoading || intervalId) && !error && (<IndaCoinPaymentDetails indaPayment={in4xExchangeRequest} />)}
					{ !in4xExchangeLoading && error && (<Alert variant="outlined" severity="error">IN4X Error: {error}</Alert>)}
				</Grid>
				{ isIncomplete && in4xExchangeRequest && in4xExchangeRequest.url && (<Grid item xs={12} className="centered-grid spaced-top">
	      		<Button fullWidth variant="contained" className="bg-nx" onClick={(e) => { openLink(e, in4xExchangeRequest.url); }}>Resume</Button>
	      </Grid>)}
				<Grid item xs={12} className="centered-grid">
	      	<Button onClick={this.goBack} fullWidth variant="contained" color="default" >Back</Button>
	      </Grid>
			</Grid>
		);
	}
}

const mapState2props = state => ({
  in4x: state.in4x,
});

const mapDispatch2props = {
  getExchangeRequest,
};

export default compose(
  connect(mapState2props, mapDispatch2props)
)(BuyTokensStatus);