
export const getPercent = (percent, ofTotal) => {
    const nextAmount = ((Number(percent) / 100) * Number(ofTotal)).toFixed(2);

    return isNaN(Number(nextAmount)) ? "-" : nextAmount;
};

export const isNextDisabled = (params = {} ) => {
    const {
        activeStep = 0,
        blockchainGas = {},
    } = params;
    const { gasReady = false } = blockchainGas;

    return activeStep > 1 || (activeStep === 1 && !gasReady);
}