import React from "react";
import { connect } from "react-redux";
import QRCode from "qrcode.react";
import { compose } from "redux";

import { withStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import CopyIcon from "@material-ui/icons/FileCopy";
import Alert from "@material-ui/lab/Alert";

import { checkActivation } from "../../ducks/signup";

import history from "../../history";

import { ROUTE_SIGNUP } from "../../constants/routes";
import CustomSnackbar from "../../components/CustomSnackbar";
import IownIcon from "src/components/IownIcon";

const styles = () => ({
  qrcode: {
    height: "auto !important",
    width: "100% !important",
    maxWidth: "250px"
  }
});

let isUnloading = false;
let intervalId = false;

class SuccessRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAlert: false,
      catchErr: null
    };
    if (!this.props.walletAddress) {
      history.replace(ROUTE_SIGNUP);
    }
  }

  componentDidMount = () => {
    this.pollStatus();
  }

  componentWillUnmount() {
    clearInterval(intervalId);
  }

  copy = text => {
    try {
      const textField = document.createElement("textarea");
      textField.innerText = text;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      this.setState({ openAlert: true });
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  handleCloseAlert = () => {
    try {
      this.setState({ openAlert: false });
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  pollStatus = () => {
    clearInterval(intervalId);
    intervalId = setInterval(() => {
      if(isUnloading) {
        typeof intervalId > 0 && clearInterval(intervalId);
        intervalId = false;
        return;
      }
      this.checkAccountActivation();
    }, 10000); //recheck rate ever 10 seconds
  }

  checkAccountActivation = async() => {
    await this.props.checkActivation(this.props.walletAddress);
    if(this.props.isActivated) {
      clearInterval(intervalId);
    }
  }

  render() {
    if (this.state.catchErr) throw this.state.catchErr;

    const { walletAddress, isActivated, activationLoading } = this.props;
    const { openAlert } = this.state;
    return (
      <Container className="home" component="main" maxWidth="sm">
        {walletAddress && (
          <>
            <IownIcon />
            <Typography variant="h5" align="center" gutterBottom>
              Your iOWN Wallet is Created
            </Typography>
            <Box align="center" pt={5}>
              <QRCode
                value={walletAddress}
                size={250}
                className={this.props.classes.qrcode}
              />
            </Box>
            <div className="copy__code">
              <div className="copy__code__text">{walletAddress}</div>
              <CopyIcon onClick={() => this.copy(walletAddress)} />
            </div>
            { !isActivated && (<Alert variant="outlined" severity="info">Please check your email for account activation link.</Alert>)}
            { !activationLoading && isActivated && (<Alert variant="outlined" severity="success">Your account is activated.</Alert>)}
            { activationLoading && (<LinearProgress color="secondary" />)}

            <CustomSnackbar
              anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
              variant="success"
              open={openAlert}
              message={"Copied to clipboard"}
              onClose={this.handleCloseAlert}
              autoHideDuration={6000}
            />
          </>
        )}
      </Container>
    );
  }
}

const mapState2props = state => ({
  walletAddress: state.signup.walletAddress,
  isActivated: state.signup.isActivated,
  activationLoading: state.signup.activationLoading
});

export default compose(
  withStyles(styles),
  connect(mapState2props, {checkActivation})
)(SuccessRegistration);
