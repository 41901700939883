import React from "react";

import { Grid, Button, Typography } from "@material-ui/core";
import Modal from "../../components/Modal";

const TransactionFailedModal = (props) => {

	return (
		<Modal
      open={props.openOn}
      onClose={props.onClose}
      modalBody={
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
                variant={'h6'}
                color={'error'}
                gutterBottom
            >
                Transaction failed!
            </Typography>

            {props.errorText && (
              <Typography gutterBottom>
                {props.errorText}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
                fullWidth
                variant="contained"
                onClick={props.onClose}
            >
                Close
            </Button>
          </Grid>
        </Grid>}
    ><>{""}</></Modal>
	);
}

export default TransactionFailedModal;