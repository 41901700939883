import React from "react";

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

const ProcessingAlert = ({message = 'Processing...'}) => {
	return (
		<Grid item xs={12} className="left-grid">
           <Alert severity="info" variant="outlined" icon={<CircularProgress className="forced-primary" size="1.2rem" />}>{message}</Alert>
        </Grid>
    );
}

export default ProcessingAlert;