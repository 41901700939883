import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Badge, FormGroup, FormControlLabel, Switch } from "@material-ui/core";
import { Typography, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

import LockOpenIcon from "@material-ui/icons/LockOpen";
import HomeIcon from "@material-ui/icons/Home";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import ReportIcon from '@material-ui/icons/Report';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import LinkIcon from '@material-ui/icons/Link';
import CropFreeIcon from '@material-ui/icons/CropFree';

import { resetAssets, getAssets, logout } from "../../ducks/dashboard";
import { resetBlockchain } from "../../ducks/blockchain";
import { resetAddressbook } from "../../ducks/addresses";
import { resetGas } from "../../ducks/blockchainGas";

import { 
  ROUTE_DEFAULT_LOGGEDIN,
  ROUTE_SHOW_ADDRESS, 
  ROUTE_EDIT_ACCOUNT, 
  ROUTE_EDIT_PASSWORD, 
  ROUTE_SHOW_MNEMONIC, 
  ROUTE_BIOMETRICS_ACTIVATE,
  ROUTE_IOWN_DIGITAL_LINK,
  ROUTE_STAKE_DASH,
  ROUTE_WALLET_CONNECT,
  ROUTE_NX_TRANSACTION_HISTORY
} from "../../constants/routes";
import {
  NETWORK_MAINNET
} from "../../constants/blockchain";
import { 
  KEY_ACTIVE_BIO_HASH,
  KEY_MULTI_USER_BIO_ACTIVE, 
  KEY_CFG_MNEMONIC_SHOWN,
  KEY_CFG_STAKING_SHOWN
} from "../../constants/storageKeys";
import ProviderUtils from "../../utils/blockchain/ProviderUtils";
import StorageUtils from "../../utils/storageUtils";
import Fingerprint from "../../BiometricsAuth";

const ShowUserSnap = false;
class SidebarMenu extends React.Component {

	constructor(props) {
   	super(props);
		this.state = {	    
		    catchErr: null,
		    enableFingerprint: false,
		    bioEnabled: false,
        isMnemonicBackedUp: StorageUtils.getItem(KEY_CFG_MNEMONIC_SHOWN) || false,
        isStakingShown: StorageUtils.getItem(KEY_CFG_STAKING_SHOWN) || false,
        allowTestMode: process.env.REACT_APP_ALLOW_TEST_MODE === 'true' || false,
		};
    const hash = StorageUtils.getItem(KEY_ACTIVE_BIO_HASH);
    const isActive = !!StorageUtils.getItem(KEY_MULTI_USER_BIO_ACTIVE);
    if (hash && hash.length > 0 && isActive) this.state.bioEnabled = true;
	};

	componentWillMount() {
		document.addEventListener("deviceready", async() => {
      try {
  		  await Fingerprint.isAvailable((result) => {
  		    if(result) {
  		      this.setState({enableFingerprint: true});
  		    }
  		  }, (error) => {
  		     this.setState({enableFingerprint: false});
  		  });
      } catch(e) {
        this.setState({catchErr: e});
      }
      // if(!IsUserSnapLoaded()) LoadUserSnap(); this is an experiment
		});
	};

	toggleBiometric = async() => {
		if(this.state.bioEnabled) {
      try{
  			await Fingerprint.show({
  		      title: 'De-activate Biometrics Unlock?',
            description: 'Authenticate to disable'
  		    }, () => {
            StorageUtils.removeItem(KEY_ACTIVE_BIO_HASH);
  					this.setState({bioEnabled: false});
  		    });
      } catch(e) {
        this.setState({catchErr: e});
      }
		} else {
		  this.props.history.push(ROUTE_BIOMETRICS_ACTIVATE);
		}
	};

  enableTestnet = (event, v) => {
    const testNet = ProviderUtils.pickTestnetForBlockchain(ProviderUtils.activeBlockchain());
    const network = v? testNet: NETWORK_MAINNET;
    this.props.resetAssets();
    ProviderUtils.changeDefaultNetwork(network);
  };

  logUserOut = () => {
    this.props.resetAddressbook();
    this.props.resetBlockchain();
    this.props.resetGas();
    this.props.logout();
  }

	render() {
		const { catchErr, isMnemonicBackedUp, allowTestMode, bioEnabled, isStakingShown } = this.state;
    if(catchErr) throw catchErr;
    const appVersion = process.env.REACT_APP_VERSION;
    const showReportBtn = false;
    const showIod = false;
    const isTestnetActive = ProviderUtils.activeNetwork() !== NETWORK_MAINNET; 
		return (
			<>
				<Link to={ROUTE_DEFAULT_LOGGEDIN}>
          <ListItem button className="header__menu__item">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={"Home"} />
          </ListItem>
	      </Link>
        <Link to={ROUTE_SHOW_ADDRESS}>
          <ListItem button>
            <ListItemIcon>
              <CropFreeIcon />
            </ListItemIcon>
            <ListItemText primary={"My Address"} />
          </ListItem>
        </Link>
        <Link to={ROUTE_STAKE_DASH}>
          <ListItem button>
            <ListItemIcon>
              <>
                <AccountBalanceIcon />
                <Badge color="secondary" badgeContent=" " overlap="rectangular" variant="dot" className={(isStakingShown? "MuiBadge-invisible":"")}><span></span></Badge>
              </>
            </ListItemIcon>
            <ListItemText primary={"iOWN Staking"} />
          </ListItem>
        </Link>
        <Link to={ROUTE_NX_TRANSACTION_HISTORY}>
          <ListItem button>
            <ListItemIcon>
              <img src="/img/in4x-logo-white.png" alt="NX" style={{height: "1.5rem"}} />
            </ListItemIcon>
            <ListItemText primary={"Exchange Account"} />
          </ListItem>
        </Link>
        <Link to={ROUTE_WALLET_CONNECT}>
          <ListItem button>
            <ListItemIcon>
              <img src="/img/walletconnect-white.png" alt="NX" style={{height: "1.5rem"}} />
            </ListItemIcon>
            <ListItemText primary={"Wallet Connect"} />
          </ListItem>
        </Link>
        <Link to={ROUTE_EDIT_ACCOUNT}>
          <ListItem button>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={"Edit Account"} />
          </ListItem>
        </Link>
        <Link to={ROUTE_EDIT_PASSWORD}>
          <ListItem button>
            <ListItemIcon>
              <LockOpenIcon />
            </ListItemIcon>
            <ListItemText primary={"Edit Password"} />
          </ListItem>
        </Link>
        <Link to={ROUTE_SHOW_MNEMONIC}>
          <ListItem button>
            <ListItemIcon>
              <>
              <RemoveRedEye />
              <Badge color="secondary" badgeContent=" " overlap="rectangular" variant="dot" className={(isMnemonicBackedUp? "MuiBadge-invisible":"")}><span></span></Badge>
              </>
            </ListItemIcon>
            <ListItemText primary={"Show Secret Phrase"} />
          </ListItem>
        </Link>
        <ListItem button>
          <ListItemIcon>
            <FingerprintIcon style={{"color": "white"}}/>
          </ListItemIcon>
          <FormGroup className="sidebar-toggle small-padding">
            <FormControlLabel
              label="Use Biometrics"
              control={<Switch checked={bioEnabled} onChange={(e) => { this.toggleBiometric(); }} disabled={!this.state.enableFingerprint} />}
              labelPlacement="start"
            />
          </FormGroup>
        </ListItem>
        { allowTestMode && (<ListItem button>
          <ListItemIcon>
            <PermDataSettingIcon style={{"color": "white"}}/>
          </ListItemIcon>
          <FormGroup className="sidebar-toggle">
            <FormControlLabel
              label="Use Testnet"
              control={<Switch checked={isTestnetActive} onChange={this.enableTestnet}/>}
              labelPlacement="start"
            />
          </FormGroup>
        </ListItem>)}
        { showIod && (<Link to={ROUTE_IOWN_DIGITAL_LINK}>
          <ListItem button>
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText primary={"Link with iOWN Digital"} />
          </ListItem>
        </Link>)}
        { showReportBtn && (
          <Link onClick={ShowUserSnap}>
            <ListItem button>
              <ListItemIcon>
                  <ReportIcon />
                </ListItemIcon>
              <ListItemText primary={"Report An Issue"} />
            </ListItem>
          </Link>
        )}
        <Link to="/" onClick={this.logUserOut}>
          <ListItem button>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItem>
        </Link>
        <Typography className="app-version" variant="caption">App Verison: {appVersion}</Typography>
			</>
		)
	}
}

// Used by appUtils
const mapStateToProps = state => ({
   dashboard: state.dashboard,
   blockchain: state.blockchain
});

const mapDispatchToprops = {
  resetAssets,
  getAssets,
  logout,
  resetAddressbook,
  resetBlockchain,
  resetGas
};

export default connect(
  mapStateToProps,
  mapDispatchToprops
)(withRouter(SidebarMenu));