import React from 'react';
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from 'redux-form';
import CustomInput from '../Field';
import USDField from '../USDField';
import { required, minLength3 } from "../../../utils/validation";
import { Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { getWireTransferValues, FORM_WIRETRANSFER } from '../../../ducks/FormData';

let WireTransferForm = props => {
  const { handleSubmit } = props;

  return (
    <Grid container spacing={1} justifyContent="center" alignItems="center" direction="column">
      <Grid item xs={12}>
        <form name={FORM_WIRETRANSFER} onSubmit={handleSubmit}>
          <Field
                name="payCur"
                component={CustomInput}
                type="hidden"
                validate={[required]}
                className="hidden-input"
          />
          <Field
                name="recCur"
                component={CustomInput}
                type="hidden"
                validate={[required]}
                className="hidden-input"
          />
          <Field
                name="amountBase"
                component={CustomInput}
                type="hidden"
                validate={[required]}
                className="hidden-input"
          />
          <Field
                name="amountQuote"
                component={CustomInput}
                type="hidden"
                validate={[required]}
                className="hidden-input"
          />
          <Field
                name="amountEth"
                component={CustomInput}
                type="hidden"
                className="hidden-input"
          />
          <Field
                name="eth"
                component={CustomInput}
                type="hidden"
                className="hidden-input"
          />
          <Field
                name="direction"
                component={CustomInput}
                type="hidden"
                validate={[required]}
                className="hidden-input"
          />
          <Field
                name="feeAmount"
                component={CustomInput}
                type="hidden"
                className="hidden-input"
          />
          <Field
                name="feeEth"
                component={CustomInput}
                type="hidden"
                className="hidden-input"
          />
    		  <Field name="total"
                component={USDField}
                type="number"
                label="Amount Sent"
                validate={[required]}
                required
                disabled
                autoComplete={"off"}
          />
          <Field name="ref"
                component={CustomInput}
                type="text"
                label="Transfer Reference (Your bank)"
                validate={[required, minLength3]}
                required
                className="input-nx"
                autoComplete={"off"}
          />
    	  </form>
      </Grid>
      <Grid item xs={12}>
        <Alert severity="info" align="left" >Transfer reference is given from your bank (<strong>NOT</strong> 00020701 IN4X Global)</Alert>
      </Grid>
    </Grid>
   )
}

WireTransferForm = reduxForm({
  form: FORM_WIRETRANSFER,
})(WireTransferForm);

WireTransferForm = connect(
  state => ({
    initialValues: state.FormData.WireTransfer,
    formValues: getFormValues(FORM_WIRETRANSFER)(state),
  }),
  { load: getWireTransferValues }               // bind account loading action creator
)(WireTransferForm);

export default WireTransferForm;