import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Grid, Typography } from "@material-ui/core";
import BlockchainLabel from "src/common/blockchain-label";
import BalanceBox from "src/components/balance-box";
import SimpleSelect from "src/components/simple-select";
import FeeConfiguration from "src/components/fee-configuration";
import ErrorAlert from "src/wallet-components/error-alert";
import SwapForm from "./components/swap-form";
import ProcessingAlert from "src/common/processing-alert";

const SwapConfig = (props) => {
    const {
        balance = "",
        currency = "",
        swapRate = "",
        toCurrency = "",
        gasLimit = "",
        error = "",
        swapAmounts = null,
        gasDataRef = null,
        isSwapActivated = "",
        swappableCurrencies = [],
        onSwapChange = () => {},
        onAmountsChange = () => {},
        onCloseError = () => {},
        onCTAClick = () => {},
        onBackClick = () => {},
    } = props;

    const [isActivating, setIsActivating] = useState(false);
    const nextTicker = currency.toUpperCase();
    const isInactive = isSwapActivated === "unactivated";
    const ctaButtonTitle = isInactive ? "Activate" : "SWAP" ;
    const isButtonDisabled = (!gasLimit && !isInactive) || isActivating;

    const handleCTAClick = () => {
        if (isSwapActivated === "unactivated") {
            setIsActivating(true)
        }
        onCTAClick();
    };

    useEffect(() => {
        if (!isActivating) return;

        setIsActivating(false);
        // eslint not so almighty
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSwapActivated]);
    const nextBalance = balance ? Number(balance).toFixed(3) : "";
    const isRefreshing = !!swapAmounts?.send && isButtonDisabled && !error;

    return (
        <Grid
            container
            spacing={1}
            justifyContent="center"
            pt={3}
        >
            <Grid item xs={12} className="centered-grid spaced-top">
                <BlockchainLabel />
                <Typography
                    variant="h5"
                    align="center"
                    color="primary"
                    gutterBottom
                >
                    SWAP
                </Typography>
                <BalanceBox
                    ticker={nextTicker}
                    balance={nextBalance}
                />
            </Grid>
            <Grid item xs={12}>
                <SimpleSelect
                    label={"Select Currency"}
                    options={swappableCurrencies}
                    onChange={onSwapChange}
                    disabled={!swappableCurrencies.length}
                />
                {isInactive && !isActivating && (
                    <Typography
                        align="center"
                        color="secondary"
                        className={"spaced-top"}
                        gutterBottom
                    >
                        Swap contract is not activated on your account,
                        you need to allow contract activation to use this service.
                    </Typography>
                )}
                {isActivating && (
                    <ProcessingAlert message={"Activating Swap Contract ..."} />
                )}
                <SwapForm
                    balance={balance}
                    isSwapActivated={isSwapActivated}
                    fromCurrency={currency}
                    swapRate={swapRate}
                    toCurrency={toCurrency}
                    onAmountsChange={onAmountsChange}
                />
            </Grid>
            { !!swapAmounts && (
                <FeeConfiguration
                    isRefreshing={isRefreshing}
                    gasDataRef={gasDataRef}
                    defaultGasLimit={gasLimit}
                />
            )}
            <ErrorAlert
                isVisible={!!error}
                error={error}
                onClose={onCloseError}
                donNotTransform
            />
            {!!isSwapActivated && (
                <Button
                    className={"spaced-top"}
                    variant={"contained"}
                    color={"primary"}
                    onClick={handleCTAClick}
                    fullWidth
                    disabled={isButtonDisabled}
                >
                    { ctaButtonTitle }
                </Button>
            )}
            <Button
                className={"spaced-top"}
                variant={"contained"}
                color={"default"}
                onClick={onBackClick}
                fullWidth
            >
                Back
            </Button>
        </Grid>
    )
};

SwapConfig.propTypes = {
    balance: PropTypes.string,
    currency: PropTypes.string,
    swapRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    toCurrency: PropTypes.string,
    gasLimit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.string,
    swapAmounts: PropTypes.object,
    gasDataRef: PropTypes.object,
    isSwapActivated: PropTypes.string,
    swappableCurrencies: PropTypes.array,
    onSwapChange: PropTypes.func,
    onAmountsChange: PropTypes.func,
    onCloseError: PropTypes.func,
    onCTAClick: PropTypes.func,
    onBackClick: PropTypes.func,
}

export default SwapConfig;