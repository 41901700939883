import React from "react";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const NXCustomInput = ({ input, label, required, type, meta: { touched, error }, autoComplete, className, disabled, adornment }) => {
    if(adornment===undefined) adornment = '';
    return (
        <TextField
            fullWidth
            label={label}
            type={type}
            error={touched && !!error}
            helperText={touched && error}
            required={required}
            margin="normal"
            variant="outlined"
            InputProps={{
                disabled: (disabled === true),
                startAdornment: <InputAdornment position="start">{adornment}</InputAdornment>
            }}
            autoComplete={autoComplete}
            className={"input-nx " + className}
            {...input}
        />
    );
};

export default NXCustomInput;