import React from "react";

import {Container} from "@material-ui/core";
import SwipeableViews from 'react-swipeable-views';

const slidesArr = [ '/img/welcome-slide1.png', '/img/welcome-slide2.png', '/img/welcome-slide3.png', '/img/welcome-slide4.png', '' ];

const NftPromo = props => {

    const {
        onPromoFinish = () => {},
    } = props;

    const handleStepChange = (step) => {
        if(step === (slidesArr.length - 1)) {
            onPromoFinish(true);
        }
    };

    return (
        <Container>
            <SwipeableViews
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {slidesArr.map((slide, index) => (
                    <div key={index} style={{ marginLeft: "2rem", marginRight: "2rem", marginTop: "2rem" }}>
                        <img src={slide} alt="" />
                    </div>
                ))}
            </SwipeableViews>
        </Container>
    )
};

export default NftPromo;