import React, {useState} from "react";
import { Grid, Button, Typography, TextField } from "@material-ui/core";
import Modal from "src/components/Modal";

const SaveAddressModal = (props) => {

    const [name, setName] = useState('');

    const onSaveAddress = () => {
        props.onSubmit(props.addressToSave, name);
    }

	return (
		<Modal
          open={props.openOn}
          onClose={props.onClose}
          onSubmit={props.onSubmit}
          modalBody={
            <Grid container spacing={3}>
              <Grid item xs={12} style={{paddingBottom: '0px'}}>
                <Typography variant="h5" align="center" gutterBottom color="primary">
                    Save Address
                </Typography>
              </Grid>
              <Grid item xs={12} style={{paddingTop: '0px'}}>
                  <TextField
                      fullWidth
                      label={"Name"}
                      placeholder={"Add name"}
                      required
                      onChange= {e => { setName(e.target.value); }}
                      margin="normal"
                      variant="outlined"
                  />
                  <TextField
                      label={'Address'}
                      value={props.addressToSave}
                      fullWidth
                      multiline
                      size="small"
                      variant="outlined"
                      style={{ marginTop: 10 }}
                      inputProps={{
                          maxLength: 20,
                      }}
                      disabled={true}
                  />
              </Grid>
              <Grid item xs={6}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={props.onClose}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={onSaveAddress}
                        disabled={!Boolean(name)}
                    >
                        Save
                    </Button>
              </Grid>
            </Grid>}
        ></Modal>
	);
}

export default SaveAddressModal;