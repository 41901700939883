import React from "react";

import { compose } from "redux";
import { connect } from "react-redux";

import TokenAssetBalance from "../Balances/TokenAssetBalance";

class CurrencyPicker extends React.Component {
  state = {
    catchErr: null,
  }

  render() {
    if (this.state.catchErr) throw this.state.catchErr;
    const { hideEther, enableMenus, handleToggle } = this.props;
    const { userAssetTypes } = this.props.dashboard;
    const { balance } = this.props.blockchain;

    return (
      <div id="balance-block">
        { userAssetTypes && userAssetTypes.map((CUR, idx) =>
            (CUR.asset !== 'spot' && CUR.asset !== 'asset' && (!hideEther || CUR.type !== 'coin') &&(
              <span key={idx} className={"balance-block-liquid"}>
                { balance && balance[CUR.blockchain] && CUR.blockchain !== 'error' && (
                  <TokenAssetBalance CUR={CUR} balance={balance[CUR.blockchain][CUR.name]} enableMenus={enableMenus} onToggle={handleToggle} className={"bal-"+CUR.name} />
                )}
              </span>
            ))
        )}
      </div>
    );
  }
}

const mapState2props = state => ({
  dashboard: state.dashboard,
  blockchain: state.blockchain
});

export default compose(
  connect(mapState2props, null, null, {forwardRef: true})
)(CurrencyPicker);

