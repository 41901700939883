import React from 'react'
import { Field, reduxForm } from 'redux-form'
import CustomInput from '../Field';
import { required, email, password } from "../../../utils/validation";

const SignInNewForm = props => {

  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
        <Field
            name="email"
            component={CustomInput}
            type="email"
            validate={[required, email]}
            label="Email"
            required
            autoComplete={"email"}            
        />
        <Field
            name="password"
            component={CustomInput}
            type="password"
            validate={[required, password]}
            label="Type your password"
            required
            autoComplete={"current-password"}
        />
    </form>
  )
}

export default reduxForm({
  form: 'signin-new',
  destroyOnUnmount: false,
})(SignInNewForm)