import { Buffer } from 'buffer';

import { EVENT_PROVIDER_BLOCKCHAIN_CHANGE } from "src/constants/events";

if(!window.Buffer) {
  // @ts-ignore
  window.Buffer = Buffer;
}

const initialState = {
    walletConnectors: {},
  	connectCode: '',
    activeSessions: {},
    hasCallRequest: false,
    callRequest: null,
    callRequestPeerName: ''
}

const WALLET_CONNECT_CODE = "WALLET_CONNECT_CODE";
const WALLET_CONNECT_SESSION = "WALLET_CONNECT_SESSION";
const WALLET_CONNECT_REMOVE_SESSION = "WALLET_CONNECT_REMOVE_SESSION";
const ADD_CALL_REQUEST = "ADD_CALL_REQUEST";
const REMOVE_CALL_REQUEST = "REMOVE_CALL_REQUEST";

export const setConnectCode = (connectCode) => ({type: WALLET_CONNECT_CODE, connectCode });
export const setConnectedSession = (walletConnector, sessionParams) => ({type: WALLET_CONNECT_SESSION, sessionParams, walletConnector });
export const removeSession = (peerId) => ({type: WALLET_CONNECT_REMOVE_SESSION, peerId });
export const addCallRequest = (callRequest, walletConnector) => ({type: ADD_CALL_REQUEST, callRequest, walletConnector });
export const removeCallRequest = () => ({type: REMOVE_CALL_REQUEST });

export const walletConnect = (state = initialState, action) => {
    let walletConnectors = state.walletConnectors, activeSessions = state.activeSessions;
	switch(action.type) {
        case WALLET_CONNECT_CODE:
        	return { ...state, connectCode: action.connectCode }

        case WALLET_CONNECT_SESSION:
            activeSessions[action.sessionParams.peerId] = action.sessionParams;
            walletConnectors[action.sessionParams.peerId] = action.walletConnector;
            return { ...state, activeSessions, walletConnectors, connectCode: '' } //reset connect code

        case WALLET_CONNECT_REMOVE_SESSION:
            let updates = {};
            if(walletConnectors[action.peerId]) {
                const removeConnector = walletConnectors[action.peerId];
                if(removeConnector.connected) {
                    window.removeEventListener(EVENT_PROVIDER_BLOCKCHAIN_CHANGE, removeConnector.blockchainEventListener);
                    removeConnector.killSession();
                }
                localStorage.removeItem(`wallet-connect-${removeConnector.handshakeTopic}`);
                // Remote the pending callRequest (if any)
                if(state.callRequest && state.callRequest.walletConnector.remotePeerId === removeConnector.remotePeerId) {
                    updates.callRequest = null;
                    updates.hasCallRequest = false;
                }
                delete walletConnectors[action.peerId];
                updates.walletConnectors = walletConnectors;
            }
            if(activeSessions[action.peerId]) {
                delete activeSessions[action.peerId];
                updates.activeSessions = activeSessions;
            }
            return { ...state, ...updates }

        case ADD_CALL_REQUEST:
            let callRequest = action.callRequest;
            callRequest.walletConnector = action.walletConnector; // we hook it to refrence it easier
            const { peerMeta = {} } = callRequest.walletConnector || {};
            const callRequestPeerName = peerMeta?.name || peerMeta?.url;
            return { ...state, callRequest: action.callRequest, hasCallRequest: true, callRequestPeerName }

        case REMOVE_CALL_REQUEST:
            return { ...state, callRequest: null, hasCallRequest: false, callRequestPeerName: '' }

        default:
        	return state;
	}

}