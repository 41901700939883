import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux';
import { getFormValues, isValid } from 'redux-form';

import NewPasswordForm from './NewPasswordForm';
import {recoverPasswordValidateToken, sendNewPasswordForRecovery} from "../data-utils";

import { getError } from 'src/utils/transformErrors';

import IownIcon from 'src/components/IownIcon';
import {Container, Grid} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Alert} from "@material-ui/lab";
import {ROUTE_RECOVER_PASSWORD, ROUTE_SIGNIN_CLEAR_DATA} from "../../../constants/routes";
import sha512 from "js-sha512";

const RecoverPassword = props => {

    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [submitLoading, setSubmitLoading] = useState(false);
    const [validToken, setValidToken] = useState(true)

    useEffect(() => {
        if (token) {
            recoverPasswordValidateToken(token).then(() => {
                if(!window['cordova']) {
                    window.location.replace(`iownw:/${ROUTE_RECOVER_PASSWORD}?token=${token}`);
                }
            }).catch((error) => {
                setValidToken(false);
            });
        } else {
            setValidToken(false);
        }

    },[token]);

    const handleSubmit = () => {
        const { password } = props.formValues;
        const hashPassword = sha512(password);
        setError('');
        setSuccess('');
        setSubmitLoading(true);
        sendNewPasswordForRecovery(hashPassword, token).then(() => {
            setSuccess('Success, please relogin with your email and secret phrase');
            setTimeout(handleBack, 3000);
        }).catch((error) => {
            setError(getError(error));
        }).finally(() => setSubmitLoading(false));
    }

    const handleBack = () => {
        props.history.push(ROUTE_SIGNIN_CLEAR_DATA);
    }

    const { formValid } = props;
    return (
        <Container className="home" component="main" maxWidth="sm">
            <IownIcon />
            <Typography variant='h5' align='center' gutterBottom>
                Set New Password
            </Typography>
            {!validToken && (
                <>
                    <Alert severity='error' className='spaced-top'>Password recovery token is not valid. Please retry the process</Alert>
                </>
            )}
            {validToken && (
                <>
                    <NewPasswordForm />
                    <Grid item xs={12} className='centered-grid'>
                        {error && (
                            <Alert severity='error' className='spaced-top'>{error}</Alert>
                        )}
                        {success && (
                            <Alert severity='success' className='spaced-top'>{success}</Alert>
                        )}
                    </Grid>
                    <Button
                        className='spaced__btn'
                        onClick={ handleSubmit }
                        fullWidth
                        variant='contained'
                        disabled={ !formValid }
                        color='primary'
                    >
                        { !submitLoading ? 'Set' : <CircularProgress size={23} /> }
                    </Button>
                </>
            )}
            <Button
                className='spaced-top-s'
                onClick={ handleBack }
                fullWidth
                variant="contained"
                color="default"
            >
                Back
            </Button>
        </Container>
    )
};

const mapState2props = state => ({
    formValues: getFormValues('new-password-form')(state),
    formValid: isValid('new-password-form')(state)
});

const mapDispatch2props = dispatch => ({
});

export default connect(
    mapState2props,
    mapDispatch2props
)(RecoverPassword);




