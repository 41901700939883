import React from "react";
import PropTypes from "prop-types";

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const CurrencyAmountField = (props) => {
    const {
        label = "Amount",
        value = "",
        helperText = "",
        adornment = "",
        error = false,
        disabled = false,
        required = true,
        onChange = value => value
    } = props;

    const handleChange = ({ target }) => onChange(target.value);

    return (
        <TextField
            fullWidth
            required={required}
            label={label}
            placeholder={"0.0"}
            error={error}
            helperText={helperText}
            onChange={handleChange}
            value={value}
            margin="normal"
            variant="outlined"
            InputProps={{
                endAdornment: <InputAdornment color="primary" position="end">{ adornment }</InputAdornment>
            }}
            disabled={disabled}
        />
    )
};

CurrencyAmountField.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.bool,
    helperText: PropTypes.string,
    disabled: PropTypes.bool,
    adornment: PropTypes.string,
    onChange: PropTypes.func,
};

export default CurrencyAmountField;