import React, {useEffect, useState} from "react";
import { Container, Grid } from '@material-ui/core';

import SAMobileStepper from "src/components/SAMobileStepper";
import BlockchainLabel from "src/common/blockchain-label";

import CryptoSwapBalances from "src/wallet-views/crypto-swap/balances";
import CryptoSwapAmount from "src/wallet-views/crypto-swap/amount";
import CryptoSwapTransaction from "src/wallet-views/crypto-swap/transaction";
import {ROUTE_CRYPTO_SWAP_HISTORY, ROUTE_NX_TRANSACTION_HISTORY} from "../../constants/routes";

const CryptoSwap = props => {

    const {history} = props;

    const [cryptoSwapTransactionData, setCryptoSwapTransactionData] = useState({});
    const [mobileStepperShown, showMobileStepper] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [disableNext, setDisableNext] = useState(false);

    useEffect(() => {
        if (activeStep <= 0) {
            showMobileStepper(false);
        } else {
            showMobileStepper(true);
        }
    }, [activeStep]);

    const handleNextClick = () => setActiveStep(step => {
        if (activeStep === 2) {
            goToTransactionDetails();
        }
        setActiveStep(step + 1);
    });
    const handlePrevClick = () => setActiveStep(step => {
       setActiveStep(step - 1);
    });

    const goToTransactionDetails = () => {
        const referenceId = cryptoSwapTransactionData?.successData?.REFERENCE_ID;
        const redirectLink = referenceId ?
            ROUTE_CRYPTO_SWAP_HISTORY + referenceId :
            ROUTE_NX_TRANSACTION_HISTORY + 'swaps';
        history.push(redirectLink);
    }

    return (
        <Container className="home" component="main" maxWidth="sm">
            <Grid item xs={12} className="centered-grid">
                <BlockchainLabel />
                { activeStep === 0 && (
                    <CryptoSwapBalances
                        {...props}
                        onNextClick={handleNextClick} />
                )}
                { activeStep === 1 && (
                    <CryptoSwapAmount
                        {...props}
                        storedSwapTransactionData={cryptoSwapTransactionData}
                        onStoreSwapTransactionData={setCryptoSwapTransactionData}
                        onDisabledNext={setDisableNext}
                    />
                )}
                { activeStep === 2 && (
                    <CryptoSwapTransaction
                        {...props}
                        storedSwapTransactionData={cryptoSwapTransactionData}
                        onStoreSwapTransactionData={setCryptoSwapTransactionData}
                        onPrevClick={handlePrevClick}
                        onDisabledNext={setDisableNext}
                    />
                )}
            </Grid>
            {mobileStepperShown && (
                <SAMobileStepper
                    maxSteps={3}
                    activeStep={activeStep}
                    onNext={handleNextClick}
                    onPrev={handlePrevClick}
                    disabledNext={disableNext}
                />
            )}
        </Container>
    )
};

export default CryptoSwap;