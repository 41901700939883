import React from 'react'
import { Field, reduxForm } from 'redux-form'
import CustomInput from '../Field';
import { email, phone, required, minLength5 } from "../../../utils/validation";
import asyncValidate from '../../../utils/asyncValidation';

const SignUpStartForm = props => {
  const { handleSubmit, disableEmail } = props;

  return (
    <form onSubmit={handleSubmit}>
            <Field
                name="fullName"
                component={CustomInput}
                type="text"
                validate={[required, minLength5]}
                label="Full Name"
                required
                autoComplete="name"
            />
            <Field
                name="email"
                component={CustomInput}
                type="email"
                validate={[required, email]}
                label="Email Address"
                required
                disabled={disableEmail === true}
                autoComplete="email"
            />
            <Field
                name="phone"
                component={CustomInput}
                type="tel"
                validate={[phone]}
                label="Phone Number"
                autocomplete="tel"
            />
        </form>
  )
};

export default reduxForm({
  form: 'signup-start',
  destroyOnUnmount: true,
  asyncValidate,
  asyncBlurFields: ['email', 'phone']
})(SignUpStartForm)