import React, {
    useEffect,
    useRef,
    useState,
    useCallback
} from "react";
import PropTypes from "prop-types";
import { Grid, LinearProgress } from "@material-ui/core";
import OwnStateTextField from "src/common/own-state-text-field";

const AdvancedGas = (props) => {
    const {
        defaultSpeed = "",
        defaultLimit = 0,
        defaultValues = null,
        isVisible = false,
        gasLoading = false,
        gasFields = [],
        onChange = val => val,
    } = props;
    const values = useRef({});
    const [nextGasFields, setNextGasFields] = useState([]);

    const handleChange = useCallback((key, value) => {
        values.current[key] = value;
        onChange(values.current)
    }, [onChange]);

    const renderFields = nextGasFields.map((item, index) => {
        const { key } = item;
        const handleOnChange = value => handleChange(key, value);

        return (
            <OwnStateTextField
                {...item}
                fullWidth
                onChange={handleOnChange}
                key={`gas-field-${key}-${index}`}
                margin="normal"
                variant="outlined"
            />
        );
    });

    useEffect(() => {
        const nextDefaultConfig = defaultValues ?
            defaultSpeed ?
                defaultValues[defaultSpeed] :
                defaultValues[defaultValues?.defaultSpeed] :
            {};
        const next = gasFields.map(item => {
            const { key } = item;
            const nextValue = key === "gasLimit" ? defaultLimit : nextDefaultConfig[key];
            values.current[key] = nextValue;

            return {
                ...item,
                value: nextValue,
            }
        });

        setNextGasFields([...next]);
    }, [defaultLimit, defaultSpeed, defaultValues, gasFields]);

    if (!isVisible) return null;

    return (
        <Grid item xs={12}>
            <LinearProgress style={{ visibility: gasLoading ? "visible" : "hidden" }} />
            { renderFields }
        </Grid>
    )
};

AdvancedGas.propTypes = {
    defaultValues: PropTypes.object,
    isVisible: PropTypes.bool,
    gasLoading: PropTypes.bool,
    gasFields: PropTypes.array,
    onChange: PropTypes.func,
    defaultSpeed: PropTypes.string,
};

export default AdvancedGas;
