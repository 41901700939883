import React from "react";

import {compose} from "redux";
import {connect} from "react-redux";

import {
    Grid, Typography, Slider, FormGroup, FormControlLabel, Checkbox, Container
} from "@material-ui/core";

import AdvancedGasLondon from "src/components/AdvancedGas/AdvancedGasLondon";
import AdvancedGasLegacy from "src/components/AdvancedGas/AdvancedGasLegacy";
import ProcessingAlert from "src/common/processing-alert";

import EtherUtil from "src/utils/ethers";
import GasUtils from "src/utils/blockchain/GasUtils";
import ProviderUtils from "src/utils/blockchain/ProviderUtils";
import { exRateIdxKey } from "src/utils/in4xUtils";

import {
    loadGasOracle,
    changeSpeed,
    changeGasEstimate,
    changeFeeType,
    resetGas,
} from "src/ducks/blockchainGas";

import {
    changeFee
} from "src/ducks/blockchain";

import {
    getExchangeRate
} from "src/ducks/in4x";

import Alert from '@material-ui/lab/Alert';
import {GAS_FEE_TYPE_LONDON, GAS_FEE_TYPE_LEGACY} from "src/constants/gasFeeTypes";
import {CURRENCY_USD} from "src/constants/currencies";
import {BLOCKCHAIN_MATIC} from "src/constants/blockchain";

let isUnloading = false;
let intervalId = false;
let isDefaultSpeedUpdated = false;

const gasSpeeds = {
    slow: { selectedSpeed: 'slow', priority: 1 },
    avg: { selectedSpeed: 'avg', priority: 2 },
    fast: { selectedSpeed: 'fast', priority: 3 }
}

class TransactionFeeConfiguration extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showGasDetails: false,
            gasUnits: 'ETH',
            gasRateUSD: 0,
            priority: 2,
            selectedSpeed: 'avg',
        };
    }

    componentDidMount = async () => {
        if (isUnloading) {
            return true;
        }
        try {
            this.props.resetGas();
            isDefaultSpeedUpdated = false;
            this.props.changeFeeType(GasUtils.recommendedGasType());
            this.setState({gasUnits: GasUtils.gasUnitsForBlockchain()});
            const key = exRateIdxKey(GasUtils.gasUnitsForBlockchain(), CURRENCY_USD);
            await this.props.getExchangeRate(GasUtils.gasUnitsForBlockchain(), CURRENCY_USD);
            if (this.props.in4x.exRates[key] && this.props.in4x.exRates[key].high) {
                this.setState({gasRateUSD: this.props.in4x.exRates[key].high});
            }
            await this.monitorBasePrice();
            const {sendingAmount, sendingAddress} = this.props.blockchain;
            if (sendingAddress.value && sendingAmount.value) {
                await this.estimateTxGas(sendingAddress.value, sendingAmount.value);
            }
        } catch (err) {
            this.setState({catchErr: err});
        }
    };

    componentWillMount() {
        isUnloading = false;
    };

    componentWillUnmount() {
        isUnloading = true;
        if(intervalId) {
            clearInterval(intervalId);
            intervalId = null;
        }
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (isUnloading) {
            return false;
        }
        try {
            let requiresRecalculation = false;
            if (nextProps.blockchainGas.gasLimit.value !== this.props.blockchainGas.gasLimit.value && !nextProps.blockchainGas.gasLimit.error) {
                requiresRecalculation = true;
            } else if (nextProps.blockchainGas.maxFeePerGas.value !== this.props.blockchainGas.maxFeePerGas.value && !nextProps.blockchainGas.maxFeePerGas.error) {
                requiresRecalculation = true;
            } else if (nextProps.blockchainGas.gasPrice.value !== this.props.blockchainGas.gasPrice.value && !nextProps.blockchainGas.gasPrice.error) {
                requiresRecalculation = true;
            }
            if ((nextProps.blockchain.sendingAddress.value !== this.props.blockchain.sendingAddress.value && !nextProps.blockchain.sendingAddress.error) ||
                (nextProps.blockchain.sendingAmount.value !== this.props.blockchain.sendingAmount.value && !nextProps.blockchain.sendingAmount.error)) {
                this.estimateTxGas(nextProps.blockchain.sendingAddress.value, nextProps.blockchain.sendingAmount.value, nextProps.blockchainGas.baseFeePerGas.value);
                requiresRecalculation = true;
            }
            if (requiresRecalculation) {
                this.calculateFee(nextProps.blockchainGas.gasLimit,
                    (nextProps.blockchainGas.feeType === GAS_FEE_TYPE_LONDON) ?
                        nextProps.blockchainGas.maxFeePerGas :
                        nextProps.blockchainGas.gasPrice
                );
            }
            if(!isDefaultSpeedUpdated && nextProps.blockchainGas.defaultSpeed) {
                const defaultSpeed = nextProps.blockchainGas.defaultSpeed;
                this.setState(gasSpeeds[defaultSpeed]);
                isDefaultSpeedUpdated = true;
            }
            return true;
        } catch (err) {
            this.setState({catchErr: err});
        }
    };

    showAdvancedGas = (e, v) => {
        this.setState({showGasDetails: v});
        if(!v) {//When we disable advanced, we select a speed to ensure its auto
            this.props.changeSpeed(this.props.blockchainGas.selectedSpeed);
        }
    };

    autoSpeed = async (e, sliderMarker) => {
        switch (sliderMarker) {
            case 1:
                this.setState(gasSpeeds['slow']);
                break;
            case 3:
                this.setState(gasSpeeds['fast']);
                break;
            default:
                this.setState(gasSpeeds['avg']);
        }
        await this.recalculateAuto();
    }

    recalculateAuto = async () => {
        this.props.changeSpeed(this.state.selectedSpeed);
        this.recalculateFeeFromProps();
    }

    // Routinely checks for gas updates
    monitorBasePrice = async () => {
        clearInterval(intervalId);
        const monitorInterval = ProviderUtils.activeBlockchain() === BLOCKCHAIN_MATIC? 5000: 15000;//we're more aggressive with Polygon
        await this.props.loadGasOracle(ProviderUtils.activeBlockchain(), ProviderUtils.activeNetwork());
        intervalId = setInterval(() => {
            if (isUnloading) {
                typeof intervalId > 0 && clearInterval(intervalId);
                intervalId = null;
                return;
            }
            this.props.loadGasOracle(ProviderUtils.activeBlockchain(), ProviderUtils.activeNetwork());
        }, monitorInterval);
    }

    recalculateFeeFromProps() {
        const gasLimit = this.props.blockchainGas.gasLimit;
        const price = (this.props.blockchainGas.feeType === GAS_FEE_TYPE_LONDON) ? this.props.blockchainGas.maxFeePerGas : this.props.blockchainGas.gasPrice;
        this.calculateFee(gasLimit, price);
    }

    calculateFee(gasLimit, gasPrice) {
        if (gasLimit.error || gasPrice.error || !gasLimit.value || !gasPrice.value) {
            this.setState({gasError: gasLimit.helperText || gasPrice.helperText});
            return;
        }
        const gasFee = EtherUtil.calculateFee(gasLimit.value, gasPrice.value);
        if (!gasFee) {
            this.setState({gasError: 'Error calculating transaction fee'});
        } else {
            const {gasRateUSD} = this.state;
            let gasFeeUSD = gasRateUSD > 0 && gasFee > 0 ? (gasFee * gasRateUSD).toFixed(2) : null;
            this.setState({gasFee: gasFee, gasError: null, gasFeeUSD: gasFeeUSD});
            this.props.changeFee(gasFee, GasUtils.gasUnitsForBlockchain());
        }
    }

    estimateTxGas = async (sendingAddress, sendingAmount) => {
        try {
            if (sendingAddress && sendingAddress.length === 42 && sendingAmount) {
                this.props.changeGasEstimate(0);
                let estimate = await EtherUtil.estimateTxGas(
                    sendingAddress,
                    this.props.blockchain.sendingCurrencyObj.address,
                    this.props.blockchain.sendingCurrency,
                    sendingAmount,
                    this.props.blockchain.sendingTrxType,
                    this.props.blockchain.sendingCurrencyObj.bits,
                    this.props.blockchain.wallet,
                    this.props.transformGasPrice,
                );

                if (estimate) {//BigNumber
                    this.props.changeGasEstimate(estimate.toString());
                }
            }
            // Fix for IOWN-1052
            this.setState({ showErr: null });
        } catch (err) {
            this.props.changeGasEstimate(0);
            const idx = (err && err.message ? err.message.indexOf('execution reverted') : -1);
            const errEdit = (err.message && idx !== -1 ? 'Transaction will likely revert: ' + err.message.substring(idx + 20) : 'Error occurred while estimating gas limit');
            this.setState({showErr: errEdit});
        }
    }

    render() {
        if (this.state.catchErr) throw this.state.catchErr;

        const { priority, showErr, gasFee, gasFeeUSD, gasUnits} = this.state;
        const {
            blockchainGas: {feeType, gasLoading, gasLoaded, error, maxFeePerGas, maxPriorityFeePerGas, gasLimit, gasPrice },
            blockchain: {sendingAddress, sendingAmount},
            keepHiddenOn = false
        } = this.props;
        let showGasDetails = this.state.showGasDetails;
        const gasError = error || maxFeePerGas.error || maxPriorityFeePerGas.error || gasLimit.error || gasPrice.error;
        const gasErrorText = error || maxFeePerGas.helperText || maxPriorityFeePerGas.helperText || gasLimit.helperText || gasPrice.helperText;
        return (
            <Container className={keepHiddenOn? 'hidden-input': ''}>
                {showErr && (
                    <Alert variant="outlined" severity="error" onClose={() => {
                        this.setState({showErr: null});
                    }}>{this.state.showErr}</Alert>
                )}
                { gasLoading && !gasLoaded && ( <ProcessingAlert message={'Preparing transaction fee...'} /> )}
                { gasLoaded && (
                    <>
                        <Grid item xs={12} className="spaced-top">
                            <Typography variant="subtitle2" gutterBottom>
                                Speed of Transaction
                            </Typography>
                            <Slider style={{marginLeft: "5%", width: "90%", marginRight: "5%"}}
                                    onChangeCommitted={this.autoSpeed}
                                    defaultValue={2}
                                    aria-labelledby="gas-slider"
                                    step={1}
                                    value={priority}
                                    marks={[
                                        {value: 2, label: "Avg"},
                                        {value: 3, label: "Fast"}
                                    ]}
                                    min={2}
                                    max={3}
                            />
                            <Typography variant="subtitle2" gutterBottom>
                                <FormGroup aria-label="position" row className="checkbox-full">
                                    <FormControlLabel
                                        value="showGasDetails"
                                        control={<Checkbox color="primary" onChange={this.showAdvancedGas}/>}
                                        label="Advanced"
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </Typography>
                        </Grid>

                        {showGasDetails && feeType === GAS_FEE_TYPE_LONDON && (
                            <AdvancedGasLondon                                
                                calculateFee={(gl, gp) => this.calculateFee(gl, gp)}
                            />
                        )}
                        {showGasDetails && feeType === GAS_FEE_TYPE_LEGACY && (
                            <AdvancedGasLegacy
                                calculateFee={(gl, gp) => this.calculateFee(gl, gp)}
                            />
                        )}
                    </>
                )}
                {gasLoaded && sendingAddress.value && sendingAmount.value && (
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" gutterBottom>
                            Estimate
                            fee: {Number(gasFee).toFixed(4)} {gasUnits.toUpperCase()} {gasFeeUSD && gasFeeUSD > 0 && (<>(${gasFeeUSD})</>)}
                        </Typography>
                    </Grid>
                )}
                {gasError && (
                    <Grid item xs={12}>
                        <Alert variant="outlined" severity="error" className="left-grid">{gasErrorText}</Alert>
                    </Grid>
                )}
            </Container>
        );
    };

}

const mapState2props = state => ({
    dashboard: state.dashboard,
    blockchain: state.blockchain,
    blockchainGas: state.blockchainGas,
    in4x: state.in4x
});

const mapDispatch2props = {
    loadGasOracle,
    changeSpeed,
    changeGasEstimate,
    changeFeeType,
    changeFee,
    getExchangeRate,
    resetGas,
};

export default compose(
    connect(mapState2props, mapDispatch2props)
)(TransactionFeeConfiguration);