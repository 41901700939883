import { transformError } from "../utils/transformErrors";
import {handleFetch} from "../utils/fetch";
import ProviderUtils from "../utils/blockchain/ProviderUtils";
import EtherUtil from "../utils/ethers";
import {
    API_STAKING_INFO
} from "../constants/apiRoutes";
import {performResult} from "../utils/stateManipulator";

const initialState = {
    // Staking info (backend)
    stakingAddress: '',
    stakingRate: 0,
    stakingAllowed: false,
    stakingMinTicket: 0,

    // Blockchain info
    stakingReward: 0, //to be awarded
    stakingBalance: '?', // --> rendered as a spinner

    stakingTransactions: [],
    stakingTransactionsLoading: false,
    stakingTransactionsLoaded: true,

    stakingDataLoading: false,
    stakingDataLoaded: false,

    texts: {},

    error: null
};

const STAKING_DATA_REQ_SENT = 'sdrs';
const STAKING_DATA_INFO_LOADED = 'sdil'; //we loaded info but no blockchain data yet
const STAKING_TRANSACTIONS_REQ_SUCCESS = 'strs';
const STAKING_DATA_REQ_FAIL = 'sdrsf';
const STAKING_DATA_REQ_SUCCESS = 'sdrss';
const STAKING_DATA_RESET = 'sdr';

export const resetStakingData = () => ({ type: STAKING_DATA_RESET });

export const getStakingData = (cur, wallet) => dispatch => {
    if (!wallet || !Object.keys(wallet).length) {
        dispatch({type: STAKING_DATA_REQ_FAIL, err: transformError('WALLET_NOT_AVAILABLE') });
        return;
    }
    if(!cur || !cur.blockchain) {
         dispatch({type: STAKING_DATA_REQ_FAIL, err: transformError('STAKING_CURRENCY_UNAVAILABLE') });
        return;   
    }

    dispatch({type: STAKING_DATA_REQ_SENT});
    try {
        getStakingInfo(cur.name, cur.blockchain, ProviderUtils.activeNetwork()).then((stakingInfo) => {
            dispatch({type: STAKING_DATA_INFO_LOADED, stakingInfo });
            const stakingAddress = stakingInfo.stakingAddress;
            EtherUtil.getStakingBalance(stakingAddress, cur.blockchain, wallet).then((balance) => {
                dispatch({type: STAKING_DATA_REQ_SUCCESS, balance: balance});
            });
            EtherUtil.getStakingTransactions(stakingAddress, cur.blockchain, wallet).then((data) => {
                dispatch({type: STAKING_TRANSACTIONS_REQ_SUCCESS, data});
            })
        }).catch((err) => {
                dispatch({type: STAKING_DATA_REQ_FAIL, err: transformError(err)});
            }
        );
    } catch (err) {
        dispatch({type: STAKING_DATA_REQ_FAIL, err: transformError(err)});
    }
};

const getStakingInfo = (currency, blockchain, network) => {
    return new Promise((resolve, reject) => {
        handleFetch(API_STAKING_INFO, "POST", {currency, blockchain, network})
            .then(res => performResult(res, () => {
                resolve(res.payload);
            }))
            .catch(err => reject(err));
    });
}

export const staking = (state = initialState, action) => {
    switch (action.type) {
        case STAKING_DATA_RESET:
            return {...initialState};
        case STAKING_DATA_REQ_SENT:
            return { ...state, stakingDataLoading: true, stakingTransactionsLoading: true, stakingDataLoaded: false, stakingData: {}, stakingBalance: '?', error: null };
        case STAKING_DATA_INFO_LOADED:
            return {...state, ...action.stakingInfo}; //We just merge the DTO
        case STAKING_DATA_REQ_FAIL:
            return { ...state, stakingBalanceLoading: false, stakingDataLoading: false, stakingDataLoaded: false, stakingTransactionsLoading: false,
                 stakingData: {}, stakingBalance: {}, error: action.err };
        case STAKING_DATA_REQ_SUCCESS:
            return { ...state, stakingBalance: action.balance, stakingDataLoading: false, stakingDataLoaded: true, error: null };
        case STAKING_TRANSACTIONS_REQ_SUCCESS:
            return { ...state, stakingTransactions: action.data.trxList, stakingReward: action.data.rewards, stakingTransactionsLoading: false, stakingTransactionsLoaded: true, error: null };
    	default:
            return state;
	}
}