import {ValidationUtil} from "src/utils/validationUtil";
import BNUtils from "src/utils/bnUtils";
import {RegExps} from "src/constants/regExps";

const BAL_ERROR_NATIVE_BAL = 1;
const BAL_ERROR_AMOUNT_BAL = 2;
const BAL_ERROR_COMBINED_BAL = 3;
const BAL_ERROR_INVALID_AMOUNT = 9;
const BAL_ERROR_INVALID_FEE = 10;

export const errorToMessage = (errorCode) => {
	switch(errorCode) {
		case BAL_ERROR_NATIVE_BAL: return "Insufficient native balance for gas (miner fee)";
		case BAL_ERROR_AMOUNT_BAL: return "Insufficient balance";
		case BAL_ERROR_COMBINED_BAL: return "Insufficient balance for amount + gas";
		case BAL_ERROR_INVALID_AMOUNT: return "Invalid amount";
		case BAL_ERROR_INVALID_FEE: return "Invalid calculated fee";
		default: return '';
	}
}

/**
 * Checks if amount is valid for a native currency (ie amount + fee are <= balance)
 */
export const getNativeAmountError = (amount, trxGasFee, sendingCurObj, balances) => {
	if(amount === '') {//We still have only a fee, but no amount
		return false;
	}

	if(!amount || !ValidationUtil.isValid(amount, RegExps.numeric)) {
        return BAL_ERROR_INVALID_AMOUNT;
    }
	const nativeBalace = balances && balances[sendingCurObj.blockchain] && balances[sendingCurObj.blockchain][sendingCurObj.name];
	// Amount is valid if anout + fee (as big numbers) <= native balace
	return amount && nativeBalace && trxGasFee && BNUtils.isEtherValidForBalance(amount, trxGasFee, nativeBalace)? false: BAL_ERROR_COMBINED_BAL;
}

/**
 * Checks if amount is valid for a non-native currency (ie amount <= balance)
 */
export const getAmountError = (amount, sendingCurObj, balances) => {
	if(amount === '') {//Not entered yet
		return false;
	}
	const amountBalance = balances && balances[sendingCurObj.blockchain] && balances[sendingCurObj.blockchain][sendingCurObj.name];
	return amount && amountBalance && BNUtils.isAmountValidForBalance(amount, amountBalance, sendingCurObj.bits)? false: BAL_ERROR_AMOUNT_BAL;
}

/**
 * Checks if we can pay for the fee of the transaction
 */
export const getFeeError = (trxFee, sendingCurObj, nativeCur, balances) => {
	if(trxFee === '') {
		return false;
	}
	if(!trxFee || !ValidationUtil.isValid(trxFee, RegExps.numeric)) {
        return BAL_ERROR_INVALID_FEE;
    }
	const nativeBalace = balances && balances[sendingCurObj.blockchain] && balances[sendingCurObj.blockchain][nativeCur];
	return trxFee && nativeBalace && BNUtils.isEtherValidForBalance(0, trxFee, nativeBalace)? false: BAL_ERROR_NATIVE_BAL;
}