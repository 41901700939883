export const gasSpeedSliderConfig = {
    style: { marginLeft: "5%", width: "90%", marginRight: "5%" },
    marks: [
        {value: 2, label: "Avg"},
        {value: 3, label: "Fast"}
    ],
    step: 1,
    min: 2,
    max: 3,
};

export const londonFields = [
    {
        key: "maxPriorityFeePerGas",
        label: "Miner Incentive (GWEI)",
        placeholder: "0.0",
        error: false,
        helperText: "",
        adornment: "",
    },
    {
        key: "maxFeePerGas",
        label: "Max Fee (GWEI)",
        placeholder: "0.0",
        error: false,
        helperText: "",
        adornment: "",
    },
    {
        key: "gasLimit",
        label: "Limit (WEI)",
        placeholder: "0.0",
        error: false,
        helperText: "",
        adornment: "",
    }
];

export const legacyGasFields = [
    {
        key: "gasPrice",
        label: "Price (GWEI)",
        placeholder: "0.0",
        error: false,
        helperText: "",
        adornment: "",
    },
    {
        key: "gasLimit",
        label: "Limit (WEI)",
        placeholder: "0.0",
        error: false,
        helperText: "",
        adornment: "",
    }
]