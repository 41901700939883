import { 
  ARCHIVE_ENABLED_KEYS, 
  KEY_MULTI_USER_ACTIVE, 
  KEY_MULTI_USER_ACCOUNTS, 
  KEY_SUFFIX_ARCHIVE, 
  KEY_ACTIVE_MNEMONIC, 
  KEY_ACTIVE_PK,
  KEY_ACTIVE_BIO_HASH
} from "../constants/storageKeys";
import { decryptDES, encryptDES } from "./cryptoUtil";
import StorageUtils from "./storageUtils";
import WalletUtils from "./blockchain/WalletUtils";

const MISSING_ACTIVE_FIX = 'Unlock Your Wallet';
export default class MultiAccountUtils {

  static verifyInfo = (userInfo) => {
    if(userInfo && userInfo.email) {
      MultiAccountUtils.addActiveAccount(userInfo.email);
    }
  }

  static getAccounts() {
    let accounts = StorageUtils.getItem(KEY_MULTI_USER_ACCOUNTS);
    if (accounts) accounts = JSON.parse(accounts);
    else {      
      accounts = [];
      if(StorageUtils.getItem(KEY_ACTIVE_PK)) {
        accounts.push(MISSING_ACTIVE_FIX);
      }
    }
    return accounts;
  };

  static archiveActive() {
    if(!StorageUtils.getItem(KEY_ACTIVE_PK)) { return; } //Nothing to archive
    const active = StorageUtils.getItem(KEY_MULTI_USER_ACTIVE);
    if(!active) throw new Error("You need to unlock your active wallet first");
    let archive = {};
    ARCHIVE_ENABLED_KEYS.map((key, idx) => {      
      let v = StorageUtils.getItem(key);
      if (v) archive[key] = v;
      return key;
    });
    StorageUtils.setItem(active + KEY_SUFFIX_ARCHIVE, JSON.stringify(archive));
  };

  static clearArchivable() {
    ARCHIVE_ENABLED_KEYS.map((key, idx) => {
      StorageUtils.removeItem(key);
      return key;
    });
  }

  static addAccount = (email, encryptedKey, encryptedMnemonic) => {
    MultiAccountUtils.archiveActive();
    MultiAccountUtils.addActiveAccount(email.toLowerCase());
    StorageUtils.setItem(KEY_ACTIVE_MNEMONIC, encryptedMnemonic);
    StorageUtils.setItem(KEY_ACTIVE_PK, encryptedKey);
    StorageUtils.removeItem(KEY_ACTIVE_BIO_HASH); //Ensures biometrics is NOT enabled for new users by default
  };

  static addActiveAccount = (email) => {
    let accounts = StorageUtils.getItem(KEY_MULTI_USER_ACCOUNTS);
    if (accounts) accounts = JSON.parse(accounts);
    else accounts = [];
    if (accounts.indexOf(email) === -1) {
      accounts.push(email);
      StorageUtils.setItem(KEY_MULTI_USER_ACCOUNTS, JSON.stringify(accounts));
    }
    StorageUtils.setItem(KEY_MULTI_USER_ACTIVE, email);
  }

  static changeActivePassword = async(oldPasswordHash, newPasswordHash) => {
     const activeMN = StorageUtils.getItem(KEY_ACTIVE_MNEMONIC);
     const activePK = StorageUtils.getItem(KEY_ACTIVE_PK);
     if(!activeMN || !activePK) throw new Error("Failed to re-encrypt wallet data");
     const mn = decryptDES(activeMN, oldPasswordHash);
     const pk = decryptDES(activePK, oldPasswordHash);
     const wallet = await WalletUtils.accessWalletByPrivateKey(pk);
     if(!wallet) throw new Error("Old passsword is invalid");
     StorageUtils.setItem(KEY_ACTIVE_MNEMONIC, encryptDES(mn, newPasswordHash));
     StorageUtils.setItem(KEY_ACTIVE_PK, encryptDES(pk, newPasswordHash));
  };

  static switchAccount = (email) => {
    const active = StorageUtils.getItem(KEY_MULTI_USER_ACTIVE);
    if (active === email || email === MISSING_ACTIVE_FIX) {
      return;
    }
    let accounts = StorageUtils.getItem(KEY_MULTI_USER_ACCOUNTS);
    if (accounts) accounts = JSON.parse(accounts);
    else accounts = [];
    if (accounts.indexOf(email) === -1) throw new Error("Invalid account");

    MultiAccountUtils.archiveActive();
    MultiAccountUtils.clearArchivable();
    
    const archived = StorageUtils.getItem(email + KEY_SUFFIX_ARCHIVE);
    if (!archived) throw new Error("Invalid account data in store");
    const archivedSession = JSON.parse(archived);
    Object.entries(archivedSession).forEach(pair => {
      StorageUtils.setItem(pair[0], pair[1]);
    });
    StorageUtils.setItem(KEY_MULTI_USER_ACTIVE, email);
  };
}