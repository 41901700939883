import React from 'react';
import BarcodeScanner from "../../components/QRScanner/BarcodeScanner";

import { compose } from "redux";
import { connect } from "react-redux";

import { setConnectCode } from '../../ducks/walletConnect';

import {
    ROUTE_NX_PAYMENT,
    ROUTE_NX_PAYMENT_BY_ADDRESS,
    ROUTE_WALLET_CONNECT,
    DEEP_LINK_BASE,
    ROUTE_DEFAULT_LOGGEDIN,
    EXTERNAL_NX_PAYMENT_WEB
} from "../../constants/routes";

const HomeScanner = (props) => {

    const onValidate = (text) => {
        return !text ||
            text.includes(ROUTE_NX_PAYMENT) ||
            text.includes(EXTERNAL_NX_PAYMENT_WEB) ||
            (text.startsWith('0x') && text.length === 42) ||
            (text.startsWith('wc:'));
    }

    const onScan = (text) => {
        if (!text) {
            props.history.push(ROUTE_DEFAULT_LOGGEDIN);
        } else if (text.includes(ROUTE_NX_PAYMENT)) {
            props.history.push(text.substring(DEEP_LINK_BASE.length));
        } else if (text.includes(EXTERNAL_NX_PAYMENT_WEB)) {
            props.history.push(ROUTE_NX_PAYMENT + text.substring(text.indexOf(EXTERNAL_NX_PAYMENT_WEB), EXTERNAL_NX_PAYMENT_WEB.length));
        } else if (text.startsWith('0x') && text.length === 42) { //An ethereum wallet
            props.history.push(ROUTE_NX_PAYMENT_BY_ADDRESS + text);
        } else if (text.startsWith('wc:')) { //We've scanned a WalletConnect bridge QR
            props.setConnectCode(text);
            props.history.push(ROUTE_WALLET_CONNECT);
        }
    }

    return (
        <BarcodeScanner
            onScan={onScan}
            onValidate={onValidate}
            onScannerClose={props.history.goBack}
            prompt="Scan QR Code"
        />
    );
}

const mapState2props = state => ({
    walletConnect: state.walletConnect,
});

const mapDispatch2props = {
    setConnectCode
}

export default compose(
  connect(mapState2props, mapDispatch2props)
)(HomeScanner);