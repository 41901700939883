import React from "react"
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "@material-ui/core/Link";
import {openLink} from '../../utils/linksOpenPolyfill'
const CookiePolicy = (props) => {
    return (
        <Container component="main" maxWidth="sm">
            <Typography variant="h5" align="center" paragraph>
                Cookie Policy
            </Typography>

            <Typography variant="h6" align="center" paragraph>
                Cookies – Collection & Use of Data Collected Automatically
            </Typography>
            <Typography align="justify" paragraph>
                Cookies are small files that a site or its service provider transfers to your
                computer’s hard drive through your web browser (if you have permitted) that enables the site’s or
                service provider’s systems to recognize your browser and capture and remember certain information.
                They are widely used in order to make websites work, or work more efficiently, as well as to provide
                information to the owners of the site.
            </Typography>
            <Typography align="justify" paragraph>
                Cookies are stored on the hard drive of your computer and do not necessarily transmit your Personal Data
                to&nbsp;<i>iOWN</i>.
            </Typography>
            <Typography align="justify" paragraph>
                Cookies are used to help&nbsp;<i>iOWN</i>&nbsp;understand your preferences based on previous or current
                site activity, which enables&nbsp;<i>iOWN</i>&nbsp;to provide you with improved services.
            </Typography>
            <Typography align="justify" paragraph>
                Cookies are also used for security purposes and to compile aggregate Data about site traffic and site
                interaction so that better site experiences and tools can be offered in the future.
            </Typography>
            <Typography align="justify">
                <i>iOWN</i> may also use trusted third-party service providers that track this information
                on <i>iOWN</i>’s behalf. Our service provider(s) will place cookies on the hard drive of your computer
                and will receive data that we select to educate us notably on:
            </Typography>
            <List>
                <ListItemText inset>
                    a. How visitors navigate around our Site
                </ListItemText>
                <ListItemText inset>
                    b. What products are browsed
                </ListItemText>
                <ListItemText inset>
                    c. General transaction information
                </ListItemText>
            </List>
            <Typography align="justify" paragraph>
                Our service provider(s) analyses this data and provide(s) us with aggregate reports.
                The data and analysis provided by our service provider(s) will be used to assist us in better
                understanding our visitors’ interests in our Site and how to better serve those interests. The data
                collected by our service provider(s) may be linked to and combined with data that we collect about
                you while you are using the Platform. Our service provider(s) is/are contractually restricted from
                using information they receive from our Site other than to assist us.
            </Typography>
            <Typography align="justify" paragraph>
                You can choose to have your computer warn you (through your browser settings) each time a cookie is
                being sent, or you can choose to turn off all cookies. If you choose to turn cookies off, some of the
                features that make your site experience more efficient may not function properly.
            </Typography>
            <Typography align="justify" paragraph>
                Specifically for analytics providers, we use Google Analytics, which is a web analytics service offered
                by Google that tracks and reports website traffic. Google uses the data collected to track and monitor
                the use of our website. This data is shared with other Google services. Google may use the collected
                data to contextualize and personalize the ads of its own advertising network.
            </Typography>
            <Typography paragraph align="justify">
                You can opt-out of having your activity on the website made available to Google Analytics by installing
                the Google Analytics opt-out browser add-on (<Link href="https://tools.google.com/dlpage/gaoptout"
                                                                   target="_blank"
                                                                   rel="noopener noreferrer"
                                                                   onClick={(e)=> openLink(e, "https://tools.google.com/dlpage/gaoptout")}>https://tools.google.com/dlpage/gaoptout</Link>)
                for your web browser. The Google Analytics opt-out browser add-on provides visitors with the ability to
                prevent their data from being collected and used by Google Analytics. The add-on prevents the Google
                Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics
                about visits activity. For more information on the privacy practices of Google, please visit the Google
                Privacy &amp; Terms web page:&nbsp;<Link href="http://www.google.com/intl/en/policies/privacy/"
                                                         target="_blank"
                                                         rel="noopener noreferrer"
                                                         onClick={(e)=> openLink(e,"http://www.google.com/intl/en/policies/privacy/")}>http://www.google.com/intl/en/policies/privacy/</Link>
            </Typography>

            <Button color="primary" onClick={() => props.history.goBack()} className="spaced__btn" fullWidth
                    variant="contained">Back</Button>
        </Container>
    )
};

export default CookiePolicy;