import forge from 'node-forge';

// 3DES key and IV sizes
const keySize = 24;
const ivSize = 8;

export const encryptDES = (input, password) => {
    const derivedBytes = forge.pbe.opensslDeriveBytes(password, null, keySize + ivSize);
    const buffer = forge.util.createBuffer(derivedBytes);
    const key = buffer.getBytes(keySize);
    const iv = buffer.getBytes(ivSize);

    const cipher = forge.cipher.createCipher('3DES-CBC', key);
    cipher.start({iv: iv});
    cipher.update(forge.util.createBuffer(input, 'binary'));
    cipher.finish();

    const output = forge.util.createBuffer();
    output.putBuffer(cipher.output);

    return output.getBytes();
};

export const decryptDES = (input, password) => {
    input = forge.util.createBuffer(input, 'binary');

    const derivedBytes = forge.pbe.opensslDeriveBytes(password, null, keySize + ivSize);
    const buffer = forge.util.createBuffer(derivedBytes);
    const key = buffer.getBytes(keySize);
    const iv = buffer.getBytes(ivSize);

    const decipher = forge.cipher.createDecipher('3DES-CBC', key);
    decipher.start({iv: iv});
    decipher.update(input);
    decipher.finish(); // check 'result' for true/false const result = decipher.finish()

    return decipher.output.getBytes();
};