import React from "react";

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';

const CurrencyUSDRateField = ({rate}) => {
    return (
        <TextField
	        fullWidth
	        placeholder={"0.0"}
	        InputProps={{
	            startAdornment: <InputAdornment position="start">$</InputAdornment>,
	            endAdornment: <InputAdornment position="end">
	                <Typography style={{ fontSize: "small", color: "grey" }}>(Estimate Value)</Typography></InputAdornment>
	        }}
	        variant="outlined"
	        value={rate}
	    />
    )
};

export default CurrencyUSDRateField;