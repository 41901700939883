import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import sha512 from "js-sha512";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Container, Typography, Paper } from "@material-ui/core";
import AppRouter from "./AppRouter";
import { transformError } from "./utils/transformErrors";

import StorageUtils from "./utils/storageUtils";

import {
  getSafeArea,
  handleDerrivedPropsChange, 
  loadAssets, 
  refreshAssets,
  isPauseTimedout,
  setNetworkTimeout,
} from "./utils/appUtils";
import {
  isGuestRoute,
  isScannerRoute,
  isDeepLinkAllowed,
  isIn4xRoute,
  ROUTE_DEFAULT_LOGGEDIN,
  ROUTE_SHOW_MNEMONIC,
  ROUTE_SIGNIN,
} from "./constants/routes";
import { KEY_ACTIVE_MNEMONIC, KEY_MULTI_USER_ACTIVE } from "./constants/storageKeys";

import CheckPassword from "./views/CheckPassword";
import BiometricsUnlock from './components/BiometricsUnlock';
import Header from "./components/Header";
import CustomSnackbar from "./components/CustomSnackbar";
import IownIcon from "src/components/IownIcon";
import { getUserInfo, logout, getAssets, setAssetsLoaded } from "./ducks/dashboard";
import { checkWalletPassword, loginWithWallet } from "./ducks/signin";
import { changeBalance, setGasCurWarning } from "./ducks/blockchain";
import { getIn4xProfile } from "./ducks/in4x";

import { decryptDES } from "./utils/cryptoUtil";

import AssetLoaderProgress from "./components/AssetLoaderProgress";
import FileStorage from "./utils/fileStorage";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0082BC"
    },
    secondary: {
      main: "#f80000"
    }
  },
  typography: {
    fontFamily: [
      "Mont",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(",")
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": ["Mont"]
      }
    },
    MuiButton: {
      root: {
        borderRadius: "18px",
        fontFamily: "Mont"
      }
    },
    MuiTypography: {
      subtitle2: {
        fontFamily: "Mont"
      },
      h5: {
        fontFamily: "Mont",
        color: "#0082BC",
        fontWeight: 600,
        fontSize: "20px"
      }
    },
    MuiFilledInput: {
      root: {
        border: "1px solid #0082BC",
        borderRadius: "4px"
      }
    },

    MuiContainer: {
      root: {
        paddingLeft: "8px",
        paddingRight: "8px",
      }
    }
  }
});

class App extends Component {
  state = {
    toResume: false,
    checkPass: false,
    showAlert: false,
    decryptedMnemonic: null,
    catchErr: null,
    isRefreshingBalances: false,
    isBalancesLoaded: false,
    safeArea: {},
    pausedAt: 0,
    throwNetworkTimeOut: false,
    isIn4x: false,
    isSessionRestored: false
  };

  componentDidMount() {
    if(!window['cordova']) {
      this.setState({ isSessionRestored: true });
    }
    document.addEventListener("deviceready", () => {
      try {
        // Restore previous session
        FileStorage.restoreSessionFromFile().then(() => {
          FileStorage.storeSessionToFile().then(() => {
            this.setState({ isSessionRestored: true });
          });
        });

        document.addEventListener("backbutton", e => {
          if(isScannerRoute(this.props.history.location.pathname)) {//Backdrop needs to be hidden
            e && e.preventDefault();
          }
        }, false); 

        document.addEventListener("pause", e => {
          this.setState({pausedAt: Date.now()});
        }, false);

        document.addEventListener("resume", e => {
            setTimeout(() => {
              const { history } = this.props;
              if (isPauseTimedout(this.state.pausedAt) && 
                !isGuestRoute(history.location.pathname) && !isScannerRoute(history.location.pathname)) {
                this.setState({ toResume: true });
              }
            }, 0);
        }, false);

        const history = this.props?.history;
        window.handleOpenURL = (url) => {
          const active = StorageUtils.getItem(KEY_MULTI_USER_ACTIVE);
          const activeUser = active && active.length > 0;
          setTimeout(() => {
            const dUrl = url.replace('iownw:/', '');
            if(isDeepLinkAllowed(dUrl, activeUser)) {
              history?.push(dUrl);
            }
          }, 100);
        }

        getSafeArea().then((sa) => {
          this.setState({safeArea: sa});
        });
      } catch (err) {
        this.setState({ catchErr: err });
      }
    }, false);
  }

  static getDerivedStateFromProps(props, prevState) {
    return handleDerrivedPropsChange(props, prevState);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    try {
      const isIn4x = isIn4xRoute(this.props.history.location.pathname);
      if(isIn4x !== this.state.isIn4x) {
        this.setState({isIn4x});
      }
      if (prevState.checkPass && this.state.checkPass && this.state.decryptedMnemonic) {
        this.setState({ checkPass: false });
      }
      if (!prevState.checkPass && this.props.history.location.pathname === ROUTE_SHOW_MNEMONIC) {
          this.setState({
            decryptedMnemonic: null,
            checkPass: true
          });
      }
      if (!prevState.showAlert && (this.props.serverError !== prevProps.serverError || this.props.serverError !== prevProps.userInfoError)) {
        this.setState({ showAlert: true });
      }
      const wallet = this.props.blockchain.wallet;
      if(
          wallet && Object.keys(wallet).length &&
          !isGuestRoute(this.props.history.location.pathname)
          && !this.state.throwNetworkTimeOut
      ) {
        const throwTimeOut = () => this.setState({
          throwNetworkTimeOut: true,
          showAlert: true,
          toResume: false,
          checkPass: false,
        });
        setNetworkTimeout({ callback: throwTimeOut, ...this.props })
        //TODO fix how this works: move to ducks
        loadAssets(this.props, this.state);
        refreshAssets(this.props, this.state, this.setState);
      }
    } catch (err) {
      this.setState({ catchErr: err });
    }
  }

  handleLogin = async ({ password }) => {    
    await (this.handleUnlock(sha512(password)));
  };

  handleUnlock = async (hashPassword) => {
    try {
      const wallet = await this.props.checkWalletPassword(hashPassword);
      if (wallet) {
        const result = await this.props.loginWithWallet(wallet, hashPassword);
        if (result && result.success) {
          const encryptedMnemonic = StorageUtils.getItem(KEY_ACTIVE_MNEMONIC);
          const decryptedMnemonic = decryptDES(encryptedMnemonic, hashPassword);
          if(!decryptedMnemonic) {
            throw new Error('Error: Cannot decrypt private key');
          }
          this.setState({
            toResume: false,
            checkPass: false,
            decryptedMnemonic
          });
        }
      }
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  handleBack = () => {
    try {
      if (!this.state.toResume && this.props.history.location.pathname === ROUTE_SHOW_MNEMONIC) {
        this.props.history.push(ROUTE_DEFAULT_LOGGEDIN);
      } else {
        if (this.props.userLoaded) { this.props.logout(); }
        this.props.history.push(ROUTE_SIGNIN);
        this.setState({ toResume: false, checkPass: false });
      }
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  handleCloseAlert = () => {
    try {
      this.setState({ showAlert: false });
      if (this.state.throwNetworkTimeOut) {;
        this.props.history.push(ROUTE_SIGNIN);
      }
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };
  render() {
    if (this.state.catchErr) throw this.state.catchErr;
    let isScanner = isScannerRoute(this.props.history.location.pathname);
    const safeArea = this.state.safeArea;
    const snackBarErrorMsg = this.props.serverError || transformError('SERVER_CONNECTION_TIMEOUT');

    return (
      <div id="app" style={(safeArea && safeArea.top? {paddingTop: safeArea.top+'px'}:{})}>
        { !this.state.isSessionRestored && (
            <Container style={{marginTop: '200px'}}>
              <AssetLoaderProgress color="primary" />
            </Container>
        )}
        { this.state.isSessionRestored && (
            <MuiThemeProvider theme={theme}>
              <Header id="header" />
              <Paper elevation={3} className={`view-paper ${isScanner? "scanning":""}`} style={(safeArea && safeArea.bottom? {height: 'calc(88vh - '+safeArea.bottom+'px'}:{})}>
                { this.state.checkPass ? (
                    <Container component="main" maxWidth="sm" className="spaced-top">
                      <IownIcon />
                      <Typography variant="h5" align="center" gutterBottom color="primary">Verify Your Password</Typography>
                      <CheckPassword
                          onSubmit={this.handleLogin}
                          goBack={this.handleBack}
                          buttonName={"Submit"}
                      />
                      <BiometricsUnlock onSuccess={this.handleUnlock} />
                    </Container>
                ) : (
                    <AppRouter decryptedMnemonic={this.state.decryptedMnemonic} />
                )}
              </Paper>
              <CustomSnackbar
                  anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                  variant="error"
                  open={this.state.showAlert}
                  message={snackBarErrorMsg}
                  onClose={this.handleCloseAlert}
              />
              <div id="shadow-backdrop" />
              <img src="/img/qr-scan.png" id="qr-scan-img" alt="Scan QR" />
            </MuiThemeProvider>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  wallet: state.blockchain.wallet,
  userLoaded: state.dashboard.userLoaded,
  signinActiveStep: state.signin.activeStep,
  userInfoError: state.dashboard.editUserInfoError,
  serverError: state.signup.serverError,
  dashboard: state.dashboard,
  blockchain: state.blockchain,
  in4x: state.in4x,
});

const mapDispatchToProps = {
  getUserInfo,
  logout,
  checkWalletPassword,
  loginWithWallet,
  getAssets,
  changeBalance,
  setGasCurWarning,
  getIn4xProfile,
  setAssetsLoaded,
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(App);