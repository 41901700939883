import React from "react";
import Recaptcha from 'react-grecaptcha';
import { Box, Typography } from '@material-ui/core';
import { getError } from "../../utils/transformErrors";

var isMounted = false;

class RecaptchaBox extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      captcha: null
    };

    window.addEventListener("orientationchange", () => {
      if (!this.state.captcha) this.resetRecaptcha();
    });
  };

  componentWillMount() {
    isMounted = true;
  }

  componentWillUnmount() {
    isMounted = false;
    window.removeEventListener("orientationchange", this.resetRecaptcha);
  };

  resetRecaptcha = () => {
    if (window.grecaptcha && isMounted) window.grecaptcha.reset();
  };

  render() {
    const { onChange, onExpired } = this.props;
    return (
      <Box pt={4}>
          { (process.env.REACT_APP_RECAPTCHA_KEY && process.env.REACT_APP_RECAPTCHA_KEY !== '') ? (
                  <Recaptcha 
                      sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                      callback={onChange}
                      expiredCallback={onExpired}
                  />
            ) : (
              <Typography color='error'>{getError('CAPTCHA_CONFIGURE_ERROR')}</Typography>
            )
          }
      </Box>
    );
  };
};

export default RecaptchaBox;