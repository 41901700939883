import { actwareFetch } from "../actware/actwareFetch";


export const AddAccountWithCookie = (userName, password) => {
    let url = "/ClientBin/In4ma-Actware-Web-AuthenticationService.svc/json/AddAccountWithCookie";
    let data = {
        userName: userName,
        password: password,
        domainName: "iown.ae@7A207144-A21A-4660-A011-70DABE2E51A2",
        deviceCode: "web"
    };
    return actwareFetch(url, "POST", data);
};

export const ChangePassword = (oldPassword, newPassword) => {
    let url = "/ClientBin/In4ma-Actware-Web-AuthenticationService.svc/json/ChangePassword";
    let data = {
        accountDTO: {
            ID: 0,
            OldPassword: oldPassword,
            NewPassword: newPassword
        }
    };
    return actwareFetch(url, "POST", data);

}