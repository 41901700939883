import React from "react";
import {
  Box,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import ReportIcon from '@material-ui/icons/Report';

const BalanceBlock = ({balance, label, icon, onClick, className = ''}) => {
	return(
		    <Box onClick={onClick} className={`dashboard__actions dashboard__actions-balance`+className} align="left" pt={3}>
          <Box className={`dashboard__actions-text`} align="left" pr={1} pl={1}>
            <Typography variant="h6" align="left">
                &nbsp;{label}
            </Typography>
            <img src={icon} alt="coin-icon" className="dashboard__actions-icon-eth"/>
            <Typography className="dashboard__actions-text-balance" variant="h5" align="left" gutterBottom>
                  { (balance === '' || balance === '?') && (
                    <CircularProgress color="secondary" />
                  )}
                  { balance !== '?' && balance >= 0 && (
                    <>{Number(balance).toLocaleString(undefined, {maximumFractionDigits: 18})}</>
                  )}
                  { balance === '!' && (
                    <ReportIcon />
                  )}
            </Typography>
          </Box>
  	    </Box>
  	);
}

export default BalanceBlock;