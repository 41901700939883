import { reset } from 'redux-form';
import sha512 from "js-sha512";
import { handleFetch } from "../utils/fetch";
import { performResult } from "../utils/stateManipulator";
import { transformError } from "../utils/transformErrors";
import StorageUtils from "../utils/storageUtils";
import { resetBlockchain } from "./blockchain";
import { resetStakingData } from "./staking";
import { ChangePassword } from '../actware/webServices';
import { KEY_ACTIVE_ETH_NET } from "../constants/storageKeys";
import { CURRENCY_IOWN } from "../constants/currencies"
import { BLOCKCHAIN_BSC } from "../constants/blockchain"
import { EVENT_USER_ASSETS_LOADED } from "../constants/events";
import MultiAccountUtils from "../utils/multiAccountUtils";
import { API_USER_PROFILE, API_USER_ASSETS, API_EDIT_PASSWORD, API_LOGOUT } from "../constants/apiRoutes";
import { ASSET_TYPE_SPOT, ASSET_TYPE_LIQUID, ASSET_TYPE_DIGITAL, ASSET_TYPE_LEGACY_LIQUID } from "../constants/routes";

const initialState = {
    transactions: [],
    userLoaded: false,
    userInfo: {
        fullName: "",
        phone: "",
        email: "",
        wallet: "",
        emailVerified: ""
    },
    currencyIown: null,
    userInfoLoaded: false,

    editUserInfoLoaded: false,
    editUserInfoSuccess: "",
    editUserInfoError: "",

    editPasswordLoaded: false,
    editPasswordSuccess: "",
    editPasswordError: "",

    userAssetTypes: [],
    userAssetTypesLoaded: false,
    userAssetTypesLoading: false,
    showDigitalAssetTypes: false,
    kycLevel: false,
    assetBalancesLoading: false,
    assetBalancesLoaded: false,
};

const ASSET_BALANCES_LOADED = "ASSET_BALANCES_LOADED";

const USER_ASSET_TYPES_SENT = "USER_ASSET_TYPES_SENT";
const USER_ASSET_TYPES_SUCCESS = "USER_ASSET_TYPES_SUCCESS";
const USER_ASSET_TYPES_FAILED = "USER_ASSET_TYPES_FAILED";

const GET_USER_INFO_SENT = "GET_USER_INFO_SENT";
const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
const GET_USER_INFO_FAILED = "GET_USER_INFO_FAILED";

const EDIT_USER_INFO_SENT = "EDIT_USER_INFO_SENT";
const EDIT_USER_INFO_SUCCESS = "EDIT_USER_INFO_SUCCESS";
const EDIT_USER_INFO_FAILED = "EDIT_USER_INFO_FAILED";

const EDIT_PASSWORD_SENT = "EDIT_PASSWORD_SENT";
const EDIT_PASSWORD_SUCCESS = "EDIT_PASSWORD_SUCCESS";
const EDIT_PASSWORD_FAILED = "EDIT_PASSWORD_FAILED";

const RESET_ASSETS = "RESET_ASSETS";
const RESET_EDIT = "RESET_EDIT";

const LOGOUT = "LOGOUT";
const LOGOUT_FAILED = "LOGOUT_FAILED";

export const getAssets = () => dispatch => {
    dispatch({ type: USER_ASSET_TYPES_SENT });
    const network = StorageUtils.getItem(KEY_ACTIVE_ETH_NET) || process.env.REACT_APP_DEFAULT_NETWORK || "mainnet";
    return handleFetch(API_USER_ASSETS, "GET", {network: network})
        .then(res => performResult(res, () => {
            dispatch({ type: USER_ASSET_TYPES_SUCCESS, data: res.payload });
            return res;
        }))
        .catch(err => dispatch({ type: USER_ASSET_TYPES_FAILED, err: transformError(err)}));
};

export const setAssetsLoaded = () => ({ type: ASSET_BALANCES_LOADED });

export const getUserInfo = () => dispatch => {
    dispatch({ type: GET_USER_INFO_SENT });
    return handleFetch(API_USER_PROFILE, "GET", null)
        .then(res => performResult(res, () => {
            MultiAccountUtils.verifyInfo(res.payload);
            dispatch({ type: GET_USER_INFO_SUCCESS, data: res.payload });
        }))
        .catch(err => dispatch({ type: GET_USER_INFO_FAILED, err: transformError(err) }));
};

export const editUserInfo = data => dispatch => {
    dispatch({ type: EDIT_USER_INFO_SENT });
    return handleFetch(API_USER_PROFILE, "POST", data)
        .then(res => performResult(res, () => {
            dispatch({ type: EDIT_USER_INFO_SUCCESS, data });
        }))
        .catch(err => dispatch({ type: EDIT_USER_INFO_FAILED, err: transformError(err) }));
};

export const editPassword = data => dispatch => {
    dispatch({ type: EDIT_PASSWORD_SENT });
    const oldHash = sha512(data.oldPassword);
    const confHash = sha512(data.confirm);
    const hashPassword = sha512(data.password);
    const newData = {
        ...data,
        password: hashPassword,
        oldPassword: oldHash,
        confirm: confHash
    };
    return handleFetch(API_EDIT_PASSWORD, "POST", newData)
        .then(res => performResult(res, () => {
            ChangePassword(data.oldPassword, data.password);
            dispatch({ type: EDIT_PASSWORD_SUCCESS, data });
            dispatch(reset('edit-password'));
            MultiAccountUtils.changeActivePassword(oldHash, hashPassword);
            dispatch(logout());
        }))
        .catch(err => dispatch({ type: EDIT_PASSWORD_FAILED, err: transformError(err) }));
};

export const resetAssets = () => ({ type: RESET_ASSETS });

export const resetEdit = () => ({ type: RESET_EDIT });

export const logout = () => dispatch => {
    dispatch({ type: LOGOUT });
    dispatch(resetBlockchain());
    dispatch(resetStakingData());
    dispatch(serverLogout());
};

export const serverLogout = () => dispatch => {
    return handleFetch(API_LOGOUT, "GET", null)
        .then(res => performResult(res, null))
        .catch(err => dispatch({ type: LOGOUT_FAILED, err: transformError(err) }));
};

export const dashboard = (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT:
            return { ...initialState, wallet: null };

        case ASSET_BALANCES_LOADED:
            return {...state, assetBalancesLoading: false, assetBalancesLoaded: true }

        case GET_USER_INFO_SENT:
            return { ...state, userInfoLoaded: true, userLoaded: true }

        case GET_USER_INFO_SUCCESS:
            return { ...state, userInfo: action.data, userInfoLoaded: false }
            
        case GET_USER_INFO_FAILED:
            return { ...state, userInfo: {}, userInfoLoaded: false,  editUserInfoError: action.err}

        case EDIT_USER_INFO_SENT:
            return { ...state, editUserInfoLoaded: true }

        case EDIT_USER_INFO_SUCCESS:
            return { ...state, userInfo: { ...state.userInfo, phone: action.data.phone, fullName: action.data.fullName }, editUserInfoLoaded: false, editUserInfoError: "", editUserInfoSuccess: "User info changed!" }

        case EDIT_USER_INFO_FAILED:
            return { ...state, editUserInfoLoaded: false, editUserInfoError: action.err, editUserInfoSuccess: "" }

        case EDIT_PASSWORD_SENT:
            return { ...state, editPasswordLoaded: true }

        case EDIT_PASSWORD_SUCCESS:
            return { ...state, editPasswordLoaded: false, editPasswordError: "", editPasswordSuccess: "Your password changed!" }

        case EDIT_PASSWORD_FAILED:
            return { ...state, editPasswordLoaded: false, editPasswordError: action.err, editPasswordSuccess: "" }

        case RESET_ASSETS:
            return { ...state, userAssetTypesLoaded: false, userAssetTypesLoading: false, userAssetTypes: [], 
                kycLevel: false, showDigitalAssetTypes: false, currencyIown: null }

        case RESET_EDIT:
            return { ...state, editUserInfoError: "", editUserInfoSuccess: "", editPasswordError: "", editPasswordSuccess: "" }

        case USER_ASSET_TYPES_SENT:
            return { ...state, userAssetTypesLoaded: false, userAssetTypesLoading: true, userAssetTypes: [] };

        case USER_ASSET_TYPES_SUCCESS:
            let showDigital = false, kycLevel = false, currencyIown = null;
            action.data.forEach((cur, idx) => {
                if(cur.asset === ASSET_TYPE_DIGITAL) {
                    showDigital = true;
                    return false;
                }
                if(cur.asset === ASSET_TYPE_SPOT) {
                    kycLevel = cur.kyc
                }
                if(cur.asset === ASSET_TYPE_LEGACY_LIQUID) {
                    action.data[idx].asset = ASSET_TYPE_LIQUID;
                }
                if(cur.blockchain === BLOCKCHAIN_BSC && cur.name === CURRENCY_IOWN) {
                    currencyIown = cur;
                }
            });
            window.dispatchEvent(new CustomEvent(EVENT_USER_ASSETS_LOADED));
            return { ...state, userAssetTypesLoaded: true, userAssetTypesLoading: false, userAssetTypes: action.data, 
                kycLevel: kycLevel, showDigitalAssetTypes: showDigital, currencyIown
            };

        case USER_ASSET_TYPES_FAILED:
            return { ...state, userAssetTypesLoaded: false, userAssetTypesLoading: false, userAssetTypesError: action.err,  };
        default:
            return state;
    }
};