import React, {useEffect, useState} from 'react'
import {compose} from "redux";
import {connect} from "react-redux";

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CircularProgress from '@material-ui/core/CircularProgress';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Container} from "@material-ui/core";
import Button from "@material-ui/core/Button";

import {ROUTE_DEFAULT_LOGGEDIN, ROUTE_NX_TRANSACTION_HISTORY} from "../../constants/routes";
import { getCurrencyIcon, CURRENCY_USD } from "../../constants/currencies";

const EWalletAssetBalance = (props) => {

  const icon = getCurrencyIcon(CURRENCY_USD);

  const {
    history, dashboard, blockchain
  } = props;

  const [balance, setBalance] = useState(null);
  const [currency, setCurrency] = useState(null);

  useEffect(() => {
    if (dashboard?.userAssetTypesLoaded && dashboard?.userAssetTypes?.length > 0 && !currency) {
      const cur = dashboard?.userAssetTypes.filter(item => {
        return item.asset === 'spot' && item.blockchain === 'in4x';
      });
      setCurrency(cur[0]);
    }
  },[dashboard, currency]);

  useEffect(() => {
    if (currency && blockchain?.balance && blockchain?.balance[currency?.blockchain] && balance === null) {
      const bal =
          blockchain?.balance[currency?.blockchain][currency?.name] >= 0 ?
              blockchain?.balance[currency?.blockchain][currency?.name] :
              '?';
      setBalance(bal);
    }
  },[blockchain?.balance, currency, balance]);

  const handleClose = (action) => {
    switch(action) {
      case 'wiretransfers':
        history.push(ROUTE_NX_TRANSACTION_HISTORY + "transfers");
        break;
      case 'cardtopups':
        history.push(ROUTE_NX_TRANSACTION_HISTORY + "indacoin");
        break;
      case 'otctopups':
        history.push(ROUTE_NX_TRANSACTION_HISTORY + "otc");
        break;
      case 'payments':
        history.push(ROUTE_NX_TRANSACTION_HISTORY + "payments");
        break;
      case 'cryptoswap':
        history.push(ROUTE_NX_TRANSACTION_HISTORY + "swaps");
        break;
      default:
        return null;
    }
  };

  const goToDashboard = () => {
    history.push(ROUTE_DEFAULT_LOGGEDIN);
  }

  return (
    <Container>
      <Box className={`dashboard__actions dashboard__actions-balance`} align="left" pt={3}>
        <Box className={`dashboard__actions-text bg-nx`} align="left" pr={1} pl={1}>
          <Typography variant="h6" align="left" gutterBottom style={{'lineHeight': '1.1', 'paddingLeft': '0.5rem'}}>
              USD<span style={{'fontSize': '40%', display: 'block'}}>Amount in progress</span>
          </Typography>
          <img src={icon} alt="coin-icon" className="dashboard__actions-icon-eth" />
          <Typography className="dashboard__actions-text-balance" variant="h5" align="left" gutterBottom>
              { balance === '?' && (
                <CircularProgress color="secondary" />
              )}
              { balance !== '?' && balance >= 0 && (
                <>$ {balance}</>
              )}
          </Typography>
        </Box>
	    </Box>
      <List component="nav" aria-label="main ewallet-actions" className="ewallet-actions">
        <ListItem button onClick={() => handleClose('payments')}>
          <ListItemIcon>
            <CreditCardIcon className="color-nx" />
          </ListItemIcon>
          <ListItemText primary="My Payments" className="color-nx" />
        </ListItem>
        <ListItem button onClick={() => handleClose('wiretransfers')}>
          <ListItemIcon>
            <AccountBalanceIcon className="color-nx"/>
          </ListItemIcon>
          <ListItemText primary="My Wire Transfers" className="color-nx"/>
        </ListItem>
        <ListItem button onClick={() => handleClose('otctopups')}>
          <ListItemIcon>
            <AttachMoneyIcon className="color-nx"/>
          </ListItemIcon>
          <ListItemText primary="My Cash Exchanges" className="color-nx" />
         </ListItem>
        <ListItem button onClick={() => handleClose('cardtopups')}>
          <ListItemIcon>
            <CreditCardIcon className="color-nx"/>
          </ListItemIcon>
          <ListItemText primary="My Card Topups" className="color-nx" />
        </ListItem>
        <ListItem button onClick={() => handleClose('cryptoswap')}>
          <ListItemIcon>
            <img src="/img/token-exchanges-icon.svg" style={{ width:'26px' }} alt="Exchange icon"/>
          </ListItemIcon>
          <ListItemText primary="Token Exchanges" className="color-nx" />
        </ListItem>
      </List>
      <Button
          fullWidth
          className="spaced__btn bg-nx"
          variant="contained"
          onClick={goToDashboard}
      >Back</Button>
    </Container>
  );
}

const mapState2props = state => ({
  dashboard: state.dashboard,
  blockchain: state.blockchain
});

export default compose(
    connect(mapState2props, {})
)(EWalletAssetBalance);