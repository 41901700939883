import React, { useContext, useEffect, useState, useCallback } from 'react'
import { Container, Typography, Grid } from "@material-ui/core"
import { ROUTE_STAKE_DASH } from "src/constants/routes";
import { TRX_TYPE_WITHDRAW } from "src/constants/blockchain";
import Stepper from "./components/stepper";
import SAMobileStepper from "src/components/SAMobileStepper";
import { ReduxBridge } from "src/components/new-approach/contexts/redux-context-bridge";
import TransactionFailedModal from "src/views/modals/TransactionFailedModal";
import { getPercent, isNextDisabled } from "./utils";

const UnStakeTokens = (props) => {
    const { history, match } = props;
    const { params: { blockchain, currency} } = match;

    const {
        storeData = {},
        actions = {},
    } = useContext(ReduxBridge);
    const {
        changeGasCalculationConfig = () => {},
        resetGas = () => {},
    } = actions;
    const [activeStep, setActiveStep] = useState(0);
    const [isErrored, setIsErrored] = useState(null);


    const goToDashBoard = useCallback(() => history.push(ROUTE_STAKE_DASH), [history]);
    const handleOnError = (error) => setIsErrored(error);

    const handleErrorModalClose = () => {
        setIsErrored(null);
        goToDashBoard();
    }

    // redux keeps overwriting own state deleting existing staking info
    const [stakeData, setStakeData] = useState(null);
    useEffect(() => {
        if (stakeData) return;
        setStakeData({
            ...storeData,
            blockchain,
            currency,
            withdrawAmount: getPercent(75, storeData?.stakingBalance),
        })
    }, [blockchain, currency, stakeData, storeData])

    const handleNextClick = () => setActiveStep(prevState => prevState + 1);
    const handlePrevClick = () => setActiveStep(prevState => {
        if (!prevState) {
            goToDashBoard();
            return
        }
        if (prevState === 1) resetGas();

        return prevState - 1;
    });

    const { stakingAddress, stakingBalance, dashboard } = stakeData || {};
    useEffect(() => {
        if (!stakingAddress || stakingBalance === "?" || !dashboard?.currencyIown) return;

        changeGasCalculationConfig({
            transactionType: TRX_TYPE_WITHDRAW,
            sendingAddress: stakingAddress,
            amount: stakingBalance,
            currencyName: currency,
            currencyObject: dashboard?.currencyIown
        });
    }, [changeGasCalculationConfig, currency, dashboard, stakingAddress, stakingBalance]);

    useEffect(() => {
        if (stakeData && !stakeData?.stakingAddress) {
            goToDashBoard();
        }
    },[goToDashBoard, stakeData])

    //TODO: This should be encapsulated inside modal
    const errorMessage = isErrored?.helperText || "Something Went Wrong";
    const disableNext = isNextDisabled({ activeStep, ...storeData });

    return (
        <Container
            className="home"
            component="main"
            maxWidth="sm"
        >
            <TransactionFailedModal
                openOn={isErrored}
                onClose={handleErrorModalClose}
                errorText={errorMessage}
            />
            <Grid item xs={12} className="centered-grid">
                <Typography
                    variant="h5"
                    align="center"
                    gutterBottom
                >
                    Un-stake iOWN
                </Typography>
                <Stepper
                    step={activeStep}
                    stakeData={stakeData}
                    onNextClick={handleNextClick}
                    onPrvClick={handlePrevClick}
                    onErorr={handleOnError}
                    actions={actions}
                    goToDashBoard={goToDashBoard}
                    gasReady={!disableNext}
                />
            </Grid>
            <SAMobileStepper
                maxSteps={3}
                activeStep={activeStep}
                onNext={handleNextClick}
                onPrev={handlePrevClick}
                disabledNext={disableNext}
            />
        </Container>

    )
};

export default UnStakeTokens;


