import EtherUtil from "src/utils/ethers";
import ProviderUtils from "src/utils/blockchain/ProviderUtils";
import { ethers } from "ethers";
import { erc20Abi } from "src/utils/abi/erc20";
import { swapRouterV2 } from "src/utils/abi/swapRouterV2";
import { API_GET_SWAP_CURRENCIES } from "src/constants/apiRoutes";
import { TRX_TYPE_SWAP_TOKENS } from "src/constants/blockchain";
import { fetchHelper } from "src/utils/helpers";
import WalletUtil from "src/utils/blockchain/WalletUtils";

export const getCurrencyObject = (assets = [], currency = "") => {
    return assets ? assets.find(item => {
        return item.name === currency &&
            item.network === ProviderUtils.activeNetwork() &&
            item.blockchain === ProviderUtils.activeBlockchain();
    }) : [];
};

export const getSwappableCurrencies = async (params) => {
    const {
        swapToCurrency = "",
        onSuccess = val => val,
    } = params;

    fetchHelper({
        endPoint: API_GET_SWAP_CURRENCIES,
        method: "POST",
        fetchParams: {
            currency: swapToCurrency,
            network: ProviderUtils.activeNetwork(),
            blockchain: ProviderUtils.activeBlockchain()
        },
        onSuccess: ({ currencies }) => onSuccess(currencies),
    });
};

export const getIsSwapActivated = async (params) => {
    const {
        swapFromCurrency = "",
        swapContractAddress = "",
        walletPasswordHash = "",
        assets = [],
        onSuccess = () => {},
        onError = val => val,
    } = params;

    const swapFrom = getCurrencyObject(assets, swapFromCurrency);
    const userWallet = await WalletUtil.getWalletInstance(walletPasswordHash);

    fetchHelper({
        fetcher: EtherUtil.blockChainExecute,
        fetchParams: {
            wallet: userWallet,
            contractAddress: swapFrom?.address,
            contractAbi: erc20Abi,
            transactionType: 'allowance',
            transactionParams: [
                `${userWallet?.address}`,
                swapContractAddress,
            ]
        },
        onSuccess: (result) => {
            const nextAllowance = ethers.utils.formatUnits(result, swapFrom?.bits);
            const next = Number(nextAllowance) > 0 ? "activated" : "unactivated";
            return onSuccess(next)
        },
        onFail: (error) => onError(error?.message)
    });
};

export const activateSwap = async (params) => {
    const {
        swapFromCurrency = "",
        swapContractAddress = "",
        walletPasswordHash = "",
        assets = [],
        onSuccess = val => val,
        onError = val => val,
    } = params;

    const swapFrom = getCurrencyObject(assets, swapFromCurrency);
    const userWallet = await WalletUtil.getWalletInstance(walletPasswordHash);

    fetchHelper({
        fetcher: EtherUtil.blockChainExecute,
        fetchParams: {
            wallet: userWallet,
            contractAddress: swapFrom?.address,
            contractAbi: erc20Abi,
            transactionType: 'approve',
            transactionParams: [
                swapContractAddress,
                ethers.constants.MaxUint256
            ],
        },
        onSuccess: () => {
            onSuccess("activated");
        },
        onFail: (error) => onError(error?.message)
    })
};

export const getSwapRate = async (params) => {
    const {
        swapFromCurrency = "",
        swapToCurrency = "",
        swapContractAddress = "",
        walletPasswordHash = "",
        assets = [],
        onSuccess = () => {},
        onError = val => val,
        swapAmounts = {
            send: "1",
        },
    } = params;

    const swapFrom = getCurrencyObject(assets, swapFromCurrency);
    const swapTo = getCurrencyObject(assets, swapToCurrency);
    const userWallet = await WalletUtil.getWalletInstance(walletPasswordHash);

    fetchHelper({
        fetcher: EtherUtil.blockChainExecute,
        fetchParams: {
            wallet: userWallet,
            contractAddress: swapContractAddress,
            contractAbi: swapRouterV2,
            transactionType: "getAmountsOut",
            transactionParams: [
                ethers.utils.parseUnits(swapAmounts.send, swapFrom?.bits),
                [`${swapFrom?.address}`,`${swapTo?.address}`]
            ],
        },
        onSuccess: (result) => {
            const [amountIn, amountOut] = result;
            const inAmount = ethers.utils.formatUnits(amountIn, swapFrom?.bits);
            const outAmount = ethers.utils.formatUnits(amountOut, swapTo?.bits);
            const rate = Number(outAmount) / Number(inAmount);

            onSuccess(rate);
        },
        onFail: (error) => onError(error?.message)
    });
};

export const swapTokens = async (params) => {
    const {
        swapFromCurrency = "",
        swapToCurrency = "",
        swapContractAddress = "",
        swapAmounts = {},
        gasData = {},
        walletPasswordHash = "",
        onSuccess = val => val,
        onError = val => val,
        onMintFinish = val => val,
        estimateGasOnly = false,
        assets = [],
    } = params;
    const {
        send,
        receive,
        deadline,
    } = swapAmounts;
    const swapFrom = getCurrencyObject(assets, swapFromCurrency);
    const swapTo = getCurrencyObject(assets, swapToCurrency);
    const userWallet = await WalletUtil.getWalletInstance(walletPasswordHash);
    const nowInSeconds = (Date.now() / 1000).toFixed();
    const deadLineInSeconds = Number(deadline) * 60;

    const execParams = [
        ethers.utils.parseUnits(`${receive}`, swapTo?.bits),
        ethers.utils.parseUnits(`${send}`, swapFrom?.bits),
        [`${swapFrom?.address}`,`${swapTo?.address}`],
        userWallet?.address,
        (Number(nowInSeconds) + deadLineInSeconds),
    ];

    const transactionOverrides = {
        ...gasData,
        // TODO: TBD gasPrice(london)
        gasPrice: ethers.utils.parseUnits(`${gasData?.gasPrice || 1}`, "gwei"),
    };

    fetchHelper({
        fetcher: EtherUtil.blockChainExecute,
        fetchParams: {
            wallet: userWallet,
            estimate: estimateGasOnly,
            contractAddress: swapContractAddress,
            contractAbi: swapRouterV2,
            transactionType: TRX_TYPE_SWAP_TOKENS,
            transactionParams: execParams,
            transactionOverrides,
        },
        onSuccess: (result) => {
            if (estimateGasOnly) {
                onSuccess(result.toNumber());
                return;
            }
            EtherUtil.monitorPendingTransaction(result, (receipt) => {
                onMintFinish(receipt);
            });
            onSuccess(result?.hash)
        },
        onFail: (error) => {
            onError(error?.message);
        }
    });
};