import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { getIn4xBank, changeActiveCurrency } from "../../ducks/in4x";
import CurrencyPicker from "../../components/CurrencyPicker";
import AssetLoaderProgress from "../../components/AssetLoaderProgress";
import {
  ROUTE_E_WALLET_METHODS,
  ROUTE_CASH_TOKEN,
  ROUTE_BUY_TOKEN,
  ROUTE_E_WALLET_BANK_DEPOSIT,
  ROUTE_E_WALLET_BANK_WITHDRAW,
  ROUTE_NX_ACTIVATE,
  ROUTE_DEFAULT_LOGGEDIN,
  ROUTE_CRYPTO_SWAP,
} from "../../constants/routes";
import { openLink } from "src/utils/linksOpenPolyfill";
import { getWertLink, getChangellyLink } from "./utils";

class EWalletMethods extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      action: '',
      kycLevel: 0,
      bc: '',
      cur: '',
    };
  }

  componentWillMount = async() => {
    const action = this.props.history.location.pathname.substring(this.props.history.location.pathname.indexOf(ROUTE_E_WALLET_METHODS) + ROUTE_E_WALLET_METHODS.length);
    const parts = action.split("/");
    if (parts.length !== 3) {
      return;
    }
    this.setState({action: parts[0].toLowerCase(), bc: parts[1].toLowerCase(), cur: parts[2].toLowerCase()});
    if (!this.props.in4x.infoLoaded) {
      await this.props.getIn4xBank();
    }
  }

  pickCurrency = (curObj) => {
    if(curObj) {
      this.setState({cur: curObj.name, bc: curObj.blockchain});
      this.selectCurrency();
    }
  }

  selectCurrency = () => {
    const { activeCurrency } = this.props.in4x;
    if(!activeCurrency || activeCurrency.name !== this.state.cur) {
      this.props.dashboard.userAssetTypes.forEach((value) => {
        if(value.name.toLowerCase() === this.state.cur) {
          this.props.changeActiveCurrency(value);
          return false;
        }
      });
    }
  }

  render() {
    if (this.state.catchErr) throw this.state.catchErr;

    const { action, cur } = this.state;
    const {
      userAssetTypesLoading,
      userAssetTypesLoaded,
      kycLevel,
      userInfo
    } = this.props.dashboard;
    const { activeCurrency } = this.props.in4x;
    let wireTransferLink, cashTransferLink, ccLink, cryptoSwapLink;
    const isWertAvailable = activeCurrency?.deposit?.includes("wert");

    const wertLink = getWertLink({ ...activeCurrency, ...userInfo });
    const changellyLink = getChangellyLink({ ...activeCurrency, ...userInfo })
    const handleOpenWertTopUp = (event) => openLink(event, wertLink);
    const handleOpenChangellyTopUp = (event) => openLink(event, changellyLink);

    if(kycLevel === false || kycLevel <= 1) {
      wireTransferLink = ROUTE_NX_ACTIVATE;
      ccLink = ROUTE_NX_ACTIVATE;
      cashTransferLink = kycLevel === 1 ? ROUTE_CASH_TOKEN + activeCurrency.blockchain + '/' + activeCurrency.name: ROUTE_NX_ACTIVATE;
      cryptoSwapLink = kycLevel === 1 ? ROUTE_CRYPTO_SWAP + activeCurrency.blockchain + '/' + activeCurrency.name: ROUTE_NX_ACTIVATE;
    } else {
      wireTransferLink = (action !== 'withdraw'? ROUTE_E_WALLET_BANK_DEPOSIT: ROUTE_E_WALLET_BANK_WITHDRAW) + activeCurrency.blockchain + '/' + activeCurrency.name;
      cashTransferLink = ROUTE_CASH_TOKEN + activeCurrency.blockchain + '/' + activeCurrency.name;
      ccLink = ROUTE_BUY_TOKEN + activeCurrency.blockchain + '/' + activeCurrency.name;
      cryptoSwapLink = ROUTE_CRYPTO_SWAP + activeCurrency.blockchain + '/' + activeCurrency.name;
    }
    const actionTxt = action.charAt(0).toUpperCase()+action.slice(1);
    if(userAssetTypesLoaded && (Object.keys(activeCurrency).length === 0 || activeCurrency.name.toLowerCase() !== cur)) {
      this.selectCurrency();
    }
    const actionType = action === 'withdraw' || action === 'sell'? 'withdraw' : 'deposit';
    const isWireEnabled = activeCurrency[actionType] && activeCurrency[actionType].indexOf('wire') !== -1;
    const isCCEnabled = activeCurrency[actionType] && activeCurrency[actionType].indexOf('inda') !== -1;
    const isOTCEnabled = activeCurrency[actionType] && activeCurrency[actionType].indexOf('otc') !== -1;
    const isCryptoSwapEnabled = activeCurrency[actionType] && activeCurrency[actionType].indexOf('swap') !== -1;
    const invalidCurrency = !cur || cur === 'any' || !activeCurrency || Object.keys(activeCurrency).length === 0;

  	return (
  		<Container className="home dashboard" component="main" maxWidth="sm">
        { userAssetTypesLoaded && !invalidCurrency && activeCurrency && (
          <Grid container spacing={3}>
            <Grid item xs>
              <Typography variant="h5" align="left" gutterBottom color="primary" style={{textAlign: "center"}}>
                Choose {actionTxt} Method
              </Typography>
            </Grid>
            { isWireEnabled && (<Grid item xs={12}>
              <Typography  style={{textAlign: "center"}}>Account (Wire Transfer)</Typography>
              <Link to={wireTransferLink}>
                <Typography style={{textAlign: "center"}}><img src="/img/wire-transfer-new.png" alt="card" style={{width: "75%"}}/></Typography>
              </Link>
            </Grid>)}
            { isCCEnabled && (<Grid item xs={12}>
              <Typography  style={{textAlign: "center"}}>Card (Provided by INDACoin)</Typography>
              <Link to={ccLink}>
                <Typography style={{textAlign: "center"}}><img src="/img/credit-card-new.png" alt="card" style={{width: "75%"}}/></Typography>
              </Link>
            </Grid>)}
            { isWertAvailable && (
                <Grid item xs={12}>
                  <Typography  style={{ textAlign: "center" }}>
                    Card (Provided by Wert)
                  </Typography>
                    <div onClick={handleOpenWertTopUp}>
                      <Typography style={{ textAlign: "center" }}>
                        <img
                            src="/img/credit-card-new.png"
                            alt="card"
                            style={{ width: "75%" }}
                        />
                      </Typography>
                    </div>
                </Grid>
            )}
            { isWertAvailable && (
                <Grid item xs={12}>
                  <Typography  style={{ textAlign: "center" }}>
                    Card (Provided by Changelly)
                  </Typography>
                  <div onClick={handleOpenChangellyTopUp}>
                    <Typography style={{ textAlign: "center" }}>
                      <img
                          src="/img/credit-card-new.png"
                          alt="card"
                          style={{ width: "75%" }}
                      />
                    </Typography>
                  </div>
                </Grid>
            )}
            { isOTCEnabled && (<Grid item xs={12}>
              <Typography  style={{textAlign: "center"}}>Cash {actionTxt} (OTC)</Typography>
              <Link to={cashTransferLink}>
                <Typography style={{textAlign: "center"}}><img src="/img/cash-deposit-new.png" alt="card" style={{width: "75%"}}/></Typography>
              </Link>
            </Grid>)}
            { isCryptoSwapEnabled && (<Grid item xs={12}>
              <Typography  style={{textAlign: "center"}}>Buy with Other Crypto</Typography>
              <Link to={cryptoSwapLink}>
                <Typography style={{textAlign: "center"}}><img src="/img/crypto-swap-new.png" alt="card" style={{width: "75%"}}/></Typography>
              </Link>
            </Grid>)}
          </Grid>
        )}
        { !userAssetTypesLoading && invalidCurrency && (
          <Grid container spacing={3} justifyContent="center" alignItems="center" className="spaced-top">
            <Grid item xs={12} >
              <Typography variant="h5" gutterBottom color="primary" align="center">
                Choose {actionTxt} Currency
              </Typography>
            </Grid>
            <CurrencyPicker handleToggle={this.pickCurrency} hideEther disableMenus />
          </Grid>
        )}
        { userAssetTypesLoading && (
          <AssetLoaderProgress />
        )}
        <Button onClick={() => this.props.history.push(ROUTE_DEFAULT_LOGGEDIN)} className="spaced__btn" fullWidth variant="contained">Cancel</Button>
  		</Container>
  	);
  };

}

const mapState2props = state => ({
  in4x: state.in4x,
  dashboard: state.dashboard,
});

const mapDispatch2props = {
  getIn4xBank,
  changeActiveCurrency
};

export default compose(
  connect(mapState2props, mapDispatch2props)
)(EWalletMethods);