import ProviderUtils from "./ProviderUtils";
import { BLOCKCHAIN_ETH, BLOCKCHAIN_BSC, BLOCKCHAIN_MATIC, NETWORK_MAINNET } from "../../constants/blockchain";

const ethers = require("ethers");

const etherscanAPIKey = process.env.REACT_APP_ETHERSCAN_API_KEY;
const bscscanAPIKey = process.env.REACT_APP_BSCSCAN_API_KEY;
const polygonscanAPIKey = process.env.REACT_APP_POLYGONSCAN_API_KEY;

let apiKeys = {};
apiKeys[BLOCKCHAIN_ETH] = etherscanAPIKey;
apiKeys[BLOCKCHAIN_BSC] = bscscanAPIKey;
apiKeys[BLOCKCHAIN_MATIC] = polygonscanAPIKey;


/**
 * @name BlockExplorerUtils 
 * @description A utilities class for connecting to Etherscan-like services
 */
export default class BlockExplorerUtils {
	
	static etherscanPostfix() {
        const activeNetwork = ProviderUtils.activeNetwork();
		return (activeNetwork !== NETWORK_MAINNET ? activeNetwork : "");
	}

    static bscscanPostfix() {
        return (ProviderUtils.activeNetwork() !== NETWORK_MAINNET? "testnet": "");
    }

    static polygonscanPostfix() {
        return (ProviderUtils.activeNetwork() !== NETWORK_MAINNET? "mumbai": "");
    }

    static scanPostFix(blockchain){
        switch(blockchain) {
            case BLOCKCHAIN_ETH:
                return BlockExplorerUtils.etherscanPostfix();
            case BLOCKCHAIN_BSC:
                return BlockExplorerUtils.bscscanPostfix();
            case BLOCKCHAIN_MATIC:
                return BlockExplorerUtils.polygonscanPostfix();
            default:
                return '';

        }
    }

    static explorerBase(blockchain, isApi) {
        let explorerUri = '';
        switch(blockchain) {
            case BLOCKCHAIN_BSC: explorerUri = 'bscscan.com'; break;
            case BLOCKCHAIN_ETH: explorerUri = 'etherscan.io'; break;
            case BLOCKCHAIN_MATIC: explorerUri = 'polygonscan.com'; break;
            default: throw new Error('UNSUPPORTED_BLOCKCHAIN');
        }
        let postfix = BlockExplorerUtils.scanPostFix(blockchain);
        if(isApi) {
            postfix = 'api' + (postfix.length > 0? '-': '') + postfix + '.';
        } else {
            postfix = postfix + (postfix.length > 0? '.': '');
        }
        return `https://${postfix}${explorerUri}`;
    }

    static scanUrl(txHash, blockchain) {
        return `${BlockExplorerUtils.explorerBase(blockchain)}/tx/${txHash}`;
    }

    static scanAddress(address, blockchain) {
        return `${BlockExplorerUtils.explorerBase(blockchain)}/address/${address}`;
    }

    static scanToken(address, blockchain) {
        return `${BlockExplorerUtils.explorerBase(blockchain)}/token/${address}`;
    }

    static scanNft(address, blockchain, tokenId) {
        return `${BlockExplorerUtils.explorerBase(blockchain)}/token/${address}?a=${tokenId}`;
    }

    static openseaNftAsset(address, blockchain, tokenId) {
        return (ProviderUtils.activeNetwork() !== NETWORK_MAINNET? `https://testnets.opensea.io/assets/mumbai` : `https://opensea.io/assets/${blockchain}`) + `/${address}/${tokenId}`;
    }

    static openseaNftCollection(collectionUrl) {
        return (ProviderUtils.activeNetwork() !== NETWORK_MAINNET? `https://testnets.opensea.io/collection/` : `https://opensea.io/collection/`) + collectionUrl;
    }

	static getTransactionStatus = async(hash) => {
		const bc = ProviderUtils.activeBlockchain();
		switch(bc) {
			case BLOCKCHAIN_ETH:
				return BlockExplorerUtils.getEthTransactionStatus(hash);
			case BLOCKCHAIN_BSC:
				return BlockExplorerUtils.getBscTransactionStatus(hash);
            case BLOCKCHAIN_MATIC:
                return BlockExplorerUtils.getMaticTransactionStatus(hash);
			default: throw new Error('No active blockchain to check transaciont hash for');
		}
	}

	static getEthTransactionStatus = async(hash) => {
		const baseUrl = BlockExplorerUtils.explorerBase(BLOCKCHAIN_ETH, true);
        const query = `module=transaction&action=getstatus&txhash=${hash}&apiKey=${etherscanAPIKey}`;
        const response = await fetch(`${baseUrl}/api?${query}`).then(res => (res.status === 200) ? res.json() : res);
        return response.status === "1" ? response.result : [];
    }

    static getBscTransactionStatus = async(hash) => {
		const baseUrl = BlockExplorerUtils.explorerBase(BLOCKCHAIN_BSC, true);
        const query = `module=transaction&action=getstatus&txhash=${hash}&apiKey=${bscscanAPIKey}`;
        const response = await fetch(`${baseUrl}/api?${query}`).then(res => (res.status === 200) ? res.json() : res);
        return response.status === "1" ? response.result : [];
    }

    static getMaticTransactionStatus = async(hash) => {
        const baseUrl = BlockExplorerUtils.explorerBase(BLOCKCHAIN_MATIC, true);
        const query = `module=transaction&action=getstatus&txhash=${hash}&apiKey=${polygonscanAPIKey}`;
        const response = await fetch(`${baseUrl}/api?${query}`).then(res => (res.status === 200) ? res.json() : res);
        return response.status === "1" ? response.result : [];   
    }

    static isSufficentBalance = async(balBN) => {
        if(balBN === '?') {
            return true;
        }
        const blockchain = ProviderUtils.activeBlockchain();
       	const baseUrl = BlockExplorerUtils.explorerBase(blockchain, true);
        const apiKey = apiKeys[blockchain];
        const query = `module=gastracker&action=gasoracle&apiKey=${apiKey}`;
        const response = await fetch(`${baseUrl}/api?${query}`).then(res => (res.status === 200) ? res.json() : res);

        if(response && response.result && response.result.ProposeGasPrice) {
            const proposed = Math.ceil(response.result.ProposeGasPrice*Math.pow(10,9));
            return balBN.gte(ethers.BigNumber.from(proposed).mul(50000));
        }
        return false;
    }

    /**
     * @name getEthTransactionsWithBlock
     * @description fetches transaction with pagination and start block from block explorer api
     * @param {string} `address` user's wallet address
     * @param {number} `page`
     * @param {number} `startBlock`
     * @param {number} `offset`
     * @return {Promise<Array>}
     */
    static getEthTransactionsWithBlock = async(address, page, startBlock, offset = 15) => {
        const scanUrl = BlockExplorerUtils.explorerBase(BLOCKCHAIN_ETH, true);
        const query = `module=account&action=txlist&address=${address}&startBlock=${startBlock}&endBlock=latest&sort=desc&page=${page}&offset=${offset}&apiKey=${etherscanAPIKey}`;
        const response = await fetch(`${scanUrl}/api?${query}`).then(res => (res.status === 200) ? res.json() : res);
        return response.status === "1" ? response.result : [];
    }

    /**
     * @name getBscTransactionsWithBlock
     * @description fetches binance smart chain transaction with pagination and start block from bscscan api
     * @param {string} `address` user's wallet address
     * @param {number} `page`
     * @param {number} `startBlock`
     * @param {number} `offset`
     * @return {Promise<Array>}
     */
    static getBscTransactionsWithBlock = async(address, page, startBlock, offset = 15) => {
        const scanUrl = BlockExplorerUtils.explorerBase(BLOCKCHAIN_BSC, true);
        const query = `module=account&action=txlist&address=${address}&startBlock=${startBlock}&endBlock=latest&sort=desc&page=${page}&offset=${offset}&apiKey=${bscscanAPIKey}`;
        const response = await fetch(`${scanUrl}/api?${query}`).then(res => (res.status === 200) ? res.json() : res);
        return response.status === "1" ? response.result : [];
    }

    static getMaticTransactionsWithBlock = async(address, page, startBlock, offset = 15) => {
        const scanUrl = BlockExplorerUtils.explorerBase(BLOCKCHAIN_MATIC, true);
        const query = `module=account&action=txlist&address=${address}&startBlock=${startBlock}&endBlock=latest&sort=desc&page=${page}&offset=${offset}&apiKey=${polygonscanAPIKey}`;
        const response = await fetch(`${scanUrl}/api?${query}`).then(res => (res.status === 200) ? res.json() : res);
        return response.status === "1" ? response.result : [];
    }

    /**
     * @name getTokenTransactionsWithBlock
     * @description fetches iown transaction with pagination from etherscan api
     * @param {string} `address`
     * @param tokenAddress
     * @param blockchain
     * @param {number} `page`
     * @param startBlock
     * @param {number} `offset`
     * @param {boolean} `isNft` Controls which action to get under account in the URL
     * @return {Promise<Array>}
     */
    static getTokenTransactionsWithBlock = async (address, tokenAddress, blockchain, page, startBlock, offset = 15, isNft = false) => {
        const scanUrl = BlockExplorerUtils.explorerBase(blockchain, true);
        const apiKey = apiKeys[blockchain];
        const moduleAction = isNft? 'tokennfttx': 'tokentx';
        const query = `module=account&action=${moduleAction}&address=${address}&contractAddress=${tokenAddress}&startBlock=${startBlock}&endBlock=latest&sort=desc&page=${page}&offset=${offset}&apiKey=${apiKey}`;
        const response = await fetch(`${scanUrl}/api?${query}`).then(res => (res.status === 200) ? res.json() : res);
        return response.status === "1" ? response.result : []
    };

    /**
     * @name getGasOracle
     * @description Fetches the blockchain gas oracle from the block explorer
     * @param {string} `blockchain` Blockchain which is active
     * @return {Promise<Object>}
     */
    static getGasOracle = async(blockchain) => {
        const scanUrl = BlockExplorerUtils.explorerBase(blockchain, true);
        const apiKey = apiKeys[blockchain];
        const query = `module=gastracker&action=gasoracle&apiKey=${apiKey}`;
        const response = await fetch(`${scanUrl}/api?${query}`).then(res => (res.status === 200) ? res.json() : res);        
        if(response && response.result && response.result.SafeGasPrice && response.result.ProposeGasPrice && response.result.FastGasPrice) {
            let base = Math.floor(Number(response.result.suggestBaseFee));
            if(!base) {//Response in BSC testnet doesnt have suggestBaseFee
                base = 0;
            }
            let r = {
                safeLow: Number(response.result.SafeGasPrice) - base,
                average: Number(response.result.ProposeGasPrice) - base,
                fast: Number(response.result.FastGasPrice) - base,
            }
            if(r.average <= r.safeLow) {
                r.average = r.safeLow + 0.5;
            }
            if(r.fast <= r.average) {
                r.fast = r.average + 0.5;
            }
            if(response.result.gasUsedRatio && response.result.gasUsedRatio.length > 0) {
                r.adjustment = BlockExplorerUtils.calculateEthscanAdjustment(response.result.gasUsedRatio);
            }
            r.maxFee = r;
            return r;
        }
        //Etherscan de-activated, and using this a maxPriorityFeePerGas (hardcoded)
        const r = (ProviderUtils.activeNetwork() !== NETWORK_MAINNET? {
            safeLow: 1,
            average: 2,
            fast: 4,
        } : {
            safeLow: 4,
            average: 7,
            fast: 15,
        });
        r.maxFee = r;
        return r;
    }

    static calculateEthscanAdjustment = (gasUsedRatio) => {
        const adj = [2, 3, 5, 10, 15];
        let ratios = gasUsedRatio.split(',');
        let adjustment = 0;
        for(let i = 0; i < 5; i++) {
            adjustment += ratios[i] * adj[i];
        }
        return adjustment.toFixed(2);
    }
}