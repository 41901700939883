import React from "react"
import { compose } from "redux";
import { connect } from "react-redux";

import { Container, Grid, Typography, CircularProgress, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import SAMobileStepper from "../../components/SAMobileStepper";
import { ROUTE_NX_TRANSACTION_HISTORY } from "../../constants/routes";
import { loadAssets, refreshAssets } from "../../utils/appUtils";

import { getAssets } from "../../ducks/dashboard";
import {
  clearTransactionData,
  changeBalance,
  setGasCurWarning
} from "../../ducks/blockchain";
import {
	createIn4xProfile,
	getIn4xProfile
} from "../../ducks/in4x";

class NXActivate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    	activeStep: 0,
    	error: false,
    	isRefresh: false,
    }
  }

  componentDidMount = async() => {
  	if(!this.props.in4x.profileLoaded) {
  		await this.props.getIn4xProfile();
  	}
  	if(this.props.in4x.profile.KYC_LEVEL !== false && this.props.in4x.profile.KYC_LEVEL !== 0) {
  		this.setState({activeStep: 1});
  	}
  }

  componentDidUpdate = async() => {
    if(this.state.isRefresh) {
      loadAssets(this.props, this.state, true);
      refreshAssets(this.props, this.state, this.setState, true);
      this.setState({isRefresh: false});
    }
  }

  refresh = async() => {
    this.setState({isRefresh: true});
  }

  nextStep = () => {
		if(this.state.activeStep >= 1) {
			this.props.history.push(ROUTE_NX_TRANSACTION_HISTORY);
		} else {
			this.setState({error: false});
			this.createProfile();
			this.setState((state) => ({ activeStep: state.activeStep + 1 }))
		}
	}

	createProfile = async() => {
		let res = await this.props.createIn4xProfile();
		this.setState({isRefresh: true});

		if(this.props.in4x.in4xAccountCreated) {
			await this.refresh();
		} else if(this.props.in4x.error) {
			this.setState({error: this.props.in4x.error});
		} else if(res && res.err) {
			this.setState({error: res.err});
		} else {
			this.setState({error: 'Failed to submit your request'});
		}
	}

	prevStep = () => {
		if(this.state.activeStep <= 0) {
			this.props.history.push(ROUTE_NX_TRANSACTION_HISTORY);
		} else {
			this.setState((state) => ({ activeStep: state.activeStep - 1 }));
		}
	}

	render() {
		const { activeStep, error } = this.state;
		const { in4xAccountCreating } = this.props.in4x;
		const { kycLevel } = this.props.dashboard;

		return (
			<Container>
				<Grid container spacing={1} className="centered-grid spaced-top">
					<Grid item xs={12}>
						<Typography className="color-nx" variant="h6" align="center" gutterBottom>Welcome to IN4X Global</Typography>
					</Grid>
					{ activeStep === 0 && (
						<Grid item xs={12} className="centered-grid">
							<img src="/img/kyc.png" alt="KYC Process" style={{maxWidth: "300px"}} />
							{ !in4xAccountCreating && (
								<>
								<Typography className="color-nx spaced-top" align="center" gutterBottom>IN4X Global allows you to exchange currencies, topup and withdraw.</Typography>
								<Typography className="color-nx spaced-top" align="center" gutterBottom>To activate your account you'll need to do a simple KYC process</Typography>
								<Typography className="color-nx spaced-top" align="center" gutterBottom>Please have your Passport/Indentity Card and Proof of address ready.</Typography>
								</>
							)}
						</Grid>
					)}
					{ !in4xAccountCreating && activeStep === 1 && !error && kycLevel === false && (
						<Grid item xs={12} className="centered-grid" >
							<img src="/img/kyc.png" alt="KYC Process" style={{maxWidth: "300px"}}/>
							<Alert severity="info" align="left">Please check your email and reply back with the requested information to validate your identity.</Alert>
							<Button className="bg-nx spaced-top" variant="contained" fullWidth onClick={this.createProfile}>Resend Email</Button>
						</Grid>
					)}
					{ !in4xAccountCreating && activeStep === 1 && !error && kycLevel === 0 && (
						<Grid item xs={12} className="centered-grid" >
							<img src="/img/kyc.png" alt="KYC Process" />
							<Alert severity="error" align="left">Your KYC documents were rejected.</Alert>
							<Typography className="color-nx spaced-top" gutterBottom>Please make sure that you submit valid KYC information to complete the verification process.</Typography>
							<Button className="bg-nx spaced-top" variant="contained" fullWidth onClick={this.createProfile}>Resend Email</Button>
						</Grid>
					)}					
					{ !in4xAccountCreating && activeStep === 1 && !error && kycLevel === 1 && (
						<Grid item xs={12} className="centered-grid" >
							<img src="/img/kyc.png" alt="KYC Process" style={{maxWidth: "300px"}}/>
							<Alert severity="info" align="left">Please check your email and reply back with the requested information to validate your identity.</Alert>
							<Button className="bg-nx spaced-top" variant="contained" fullWidth onClick={this.createProfile}>Resend Email</Button>
						</Grid>
					)}
					{ !in4xAccountCreating && activeStep === 1 && !error && kycLevel > 1 && kycLevel < 10 && (
						<Grid item xs={12} className="centered-grid" >
							<img src="/img/kyc.png" alt="KYC Process" style={{maxWidth: "300px"}}/>
							<Alert severity="success" align="left">Your KYC information has already been accepted.</Alert>
						</Grid>
					)}
					{ !in4xAccountCreating && activeStep === 1 && !error && kycLevel === 10 && (
						<Grid item xs={12} className="centered-grid" >
							<img src="/img/kyc.png" alt="KYC Process" style={{maxWidth: "300px"}}/>
							<Alert severity="error" align="left">Your KYC has expired, please check your email and resubmit your information.</Alert>
							<Button className="bg-nx spaced-top" variant="contained" fullWidth onClick={this.createProfile}>Resend Email</Button>
						</Grid>
					)}
					{ !in4xAccountCreating && activeStep === 1 && !error && kycLevel === 11 && (
						<Grid item xs={12} className="centered-grid" >
							<img src="/img/kyc.png" alt="KYC Process" style={{maxWidth: "300px"}}/>
							<Alert severity="info" align="left">Your KYC information is being reviewed.</Alert>
						</Grid>
					)}
					{ !in4xAccountCreating && activeStep === 1 && error && (
						<Grid item xs={12} className="centered-grid">
							<img src="/img/kyc.png" alt="KYC Process" />
							<Alert severity="error">{'An error occurred while creating your Profie!'}</Alert>
						</Grid>
					)}
					{ in4xAccountCreating && (
						<Grid item xs={12} className="centered-grid" >
							<Typography className="spaced-top color-nx" align="center" gutterBottom>Processing...</Typography>
							<CircularProgress />
						</Grid>)}
				</Grid>
				<SAMobileStepper maxSteps={2} activeStep={activeStep} onNext={this.nextStep} onPrev={this.prevStep} />
	    </Container>
		);
	}
}

const mapState2props = state => ({
	blockchain: state.blockchain,
  dashboard: state.dashboard,
  in4x: state.in4x,
});

const mapDispatch2props = {
	createIn4xProfile,
	getAssets, //Must be attached for refresh method
	changeBalance,
  clearTransactionData,
  getIn4xProfile,
	loadAssets,
	refreshAssets,
	setGasCurWarning
}

export default compose(
  connect(mapState2props, mapDispatch2props)
)(NXActivate);