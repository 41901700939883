import GasUtils from "src/utils/blockchain/GasUtils";
import ProviderUtils from "src/utils/blockchain/ProviderUtils";
import { BLOCKCHAIN_MATIC } from "src/constants/blockchain";
import EtherUtil from "src/utils/ethers";

export const getGas = async (params = {}) => {
    const {
        updateGasLoading = () => {},
        onUpdate = val => val,
    } = params;

    updateGasLoading()

    const gasData = await GasUtils.loadGasOracle();
    const gasDefault = gasData[gasData?.defaultSpeed];

    onUpdate(gasData, gasDefault);
    updateGasLoading();
};

export const pollGas = (params = {}) => {
    const {
        onUpdate = val => val,
        updateGasLoading = () => {},
        id = undefined,
    } = params;

    if (id) {
        clearInterval(id);
        return;
    }

    if (!id) getGas({
        updateGasLoading,
        onUpdate,
    });

    const monitorInterval = ProviderUtils.activeBlockchain() === BLOCKCHAIN_MATIC ? 5000: 15000;
    return setInterval(async () => await getGas({
        updateGasLoading,
        onUpdate,
    }), monitorInterval);
};

export const getGasSpeedSliderNumeric = (params) => {
    const {
        gasSpeed = 1,
        revers = false,
    } = params;
    const speedTypes = {
       slow: 1,
       avg: 2,
       fast: 3,
    }

    if (revers) {
        const pairs = Object.entries(speedTypes);
        const nextSpeed = pairs.find(item => {
            const [, value] = item;
            return value === Number(gasSpeed);
        });

        return nextSpeed[0]
    }

    return speedTypes[gasSpeed]
};

export const getGasFee = (params) => {
    const {
        estimatedLimit = "0",
        oracleGasForecastData = {},
        gasManualData = {},
    } = params;

    const price = gasManualData?.gasPrice || oracleGasForecastData[oracleGasForecastData?.defaultSpeed]?.gasPrice;
    const limit = gasManualData?.gasLimit || estimatedLimit;

    return EtherUtil.calculateFee(limit, price)
}