import React from "react";
import {
    Grid, Link,
    Typography,
} from "@material-ui/core";

import { EXTERNAL_STAKING } from "../../../../../../src/constants/routes";
import { openLink } from "../../../../../../src/utils/linksOpenPolyfill";

const UnStakeInformStep = () => {

    const onKnowMoreClick = (event) => {
        openLink(event, EXTERNAL_STAKING)
    };

    return (
        <Grid
            item
            xs={12}
            className="centered-grid"
        >
            <Typography
                align="left"
                color="primary"
                className="spaced-top"
                gutterBottom
            >
                You are about to un-stake the complete amount that is locked. 
                Please note that a penalty fee of 25% of the staked amount will be charged and deducted upon un-staking and NFT will not be received unless the staking term is complete.
            </Typography>
            <Typography color="primary" align="left" gutterBottom>
                To learn more about the staking program&nbsp;
                <Link href={EXTERNAL_STAKING}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={onKnowMoreClick}
                      style={{textDecoration: 'underline'}}
                >click here</Link>
            </Typography>
        </Grid>
    )
};

export default UnStakeInformStep;