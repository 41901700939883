 /**
  * @name openLink
  * @description open link in browser on ios and android
  * @param {obj} 'click event object'
  * @param {string} 'URL address'
  */

  export const openLink = (e,url) => {
      e && e.preventDefault();
      if(window.cordova && window.cordova.InAppBrowser && window.cordova.InAppBrowser.open) {
        window.cordova.InAppBrowser.open(url, '_system');
      } else {
        window.open(url, '_system');
      }
  } 