import {transformError} from "../utils/transformErrors";
import {handleFetch} from "../utils/fetch";
import {performResult} from "../utils/stateManipulator";
import ProviderUtils from "../utils/blockchain/ProviderUtils";
import {
    API_ADDRESS_BOOK_ADD,
    API_ADDRESS_BOOK_COMMON,
    API_ADDRESS_BOOK_REMOVE,
    API_ADDRESS_BOOK_SAVED,
} from "../constants/apiRoutes";

const initialState = {

    commonAddresses: [],
    savedAddresses: [],

    commonAddressesDataLoading: false,
    commonAddressesDataLoaded: false,

    savedAddressesDataLoading: false,
    savedAddressesDataLoaded: false,

    addressAdding: false,
    addressAddDone: false,

    addressRemoving: false,
    addressRemoveDone: false,

    error: null
};

const ADDRESS_BOOK_COMMON_GET_REQ_SENT = 'abcgrs';
const ADDRESS_BOOK_COMMON_GET_REQ_SUCCESS = 'abcgrss';
const ADDRESS_BOOK_COMMON_GET_REQ_FAIL = 'abcgrf';

const ADDRESS_BOOK_SAVED_GET_REQ_SENT = 'absgrs';
const ADDRESS_BOOK_SAVED_GET_REQ_SUCCESS = 'absgrss';
const ADDRESS_BOOK_SAVED_GET_REQ_FAIL = 'absgrf';

const ADDRESS_BOOK_ADD_REQ_SENT = 'abars';
const ADDRESS_BOOK_ADD_REQ_SUCCESS = 'abarss';
const ADDRESS_BOOK_ADD_REQ_FAIL = 'abarf';

const ADDRESS_BOOK_REMOVE_REQ_SENT = 'abrrs';
const ADDRESS_BOOK_REMOVE_REQ_SUCCESS = 'abrrss';
const ADDRESS_BOOK_REMOVE_REQ_FAIL = 'abrrf';

const ADDRESS_BOOK_RESET = 'abr';

export const getCommonAddressBookData = () => dispatch => {
    dispatch({type: ADDRESS_BOOK_COMMON_GET_REQ_SENT});
    getCommonAddresses().then((data) => {
        dispatch({type: ADDRESS_BOOK_COMMON_GET_REQ_SUCCESS, data });
    }).catch((err) => {
        dispatch({type: ADDRESS_BOOK_COMMON_GET_REQ_FAIL, err: transformError(err)});
    });
};

export const getSavedAddressBookData = (wallet) => dispatch => {
    dispatch({type: ADDRESS_BOOK_SAVED_GET_REQ_SENT});
    getSavedAddresses(wallet).then((data) => {
        dispatch({type: ADDRESS_BOOK_SAVED_GET_REQ_SUCCESS, data });
    }).catch((err) => {
        dispatch({type: ADDRESS_BOOK_SAVED_GET_REQ_FAIL, err: transformError(err)});
    });
};

export const addAddressBookItem = (wallet, address, name) => dispatch => {
    dispatch({type: ADDRESS_BOOK_ADD_REQ_SENT});
    addAddress(wallet, address, name, ProviderUtils.activeBlockchain(), ProviderUtils.activeNetwork()).then((data) => {
        dispatch({type: ADDRESS_BOOK_ADD_REQ_SUCCESS, data });
    }).catch((err) => {
        dispatch({type: ADDRESS_BOOK_ADD_REQ_FAIL, err: transformError(err)});
    });
};

export const removeAddressBookItem = (wallet, address) => dispatch => {
    dispatch({type: ADDRESS_BOOK_REMOVE_REQ_SENT});
    removeAddress(wallet, address, ProviderUtils.activeBlockchain(), ProviderUtils.activeNetwork()).then(() => {
        dispatch({type: ADDRESS_BOOK_REMOVE_REQ_SUCCESS, address });
    }).catch((err) => {
        dispatch({type: ADDRESS_BOOK_REMOVE_REQ_FAIL, err: transformError(err)});
    });
};

const getCommonAddresses = () => {
    return new Promise((resolve, reject) => {
        handleFetch(API_ADDRESS_BOOK_COMMON, "GET", {})
            .then(res => performResult(res, () => {
                if (res?.success) {
                    resolve(res?.predefinedAddressBookList);
                } else {
                    resolve([]);
                }
            }))
            .catch(err => reject(err));
    });
}

const getSavedAddresses = (wallet) => {
    return new Promise((resolve, reject) => {
        handleFetch(API_ADDRESS_BOOK_SAVED, "GET", {wallet: wallet})
            .then(res => performResult(res, () => {
                if (res?.success) {
                    resolve(res?.savedList);
                } else {
                    resolve([]);
                }
            }))
            .catch(err => reject(err));
    });
}

const addAddress = (wallet, address, name, blockchain, network) => {
    return new Promise((resolve, reject) => {
        const body = {
            wallet,
            addressBook : {
                address, name, blockchain, network
            }
        }
        handleFetch(API_ADDRESS_BOOK_ADD, "POST", body)
            .then(res => performResult(res, () => {
                if (res?.success) {
                    resolve(res?.newAddressBook);
                }
            }))
            .catch(err => reject(err));
    });
}

const removeAddress = (wallet, addressItem, blockchain, network) => {
    return new Promise((resolve, reject) => {
        const body = {
            addressBook : {
                id : addressItem.id,
                address : addressItem.address,
                blockchain,
                network
            }
        };
        handleFetch(API_ADDRESS_BOOK_REMOVE, "POST", body)
            .then(res => performResult(res, () => {
                if (res?.success) {
                    resolve();
                }
            }))
            .catch(err => reject(err));
    });
}

export const resetAddressbook = () => ({ type: ADDRESS_BOOK_RESET });

export const addresses = (state = initialState, action) => {
    switch (action.type) {
        case ADDRESS_BOOK_RESET:
            return {...initialState};
        case ADDRESS_BOOK_COMMON_GET_REQ_SENT:
            return { ...state, commonAddressesDataLoading: true, commonAddressesDataLoaded: false, error: null };
        case ADDRESS_BOOK_COMMON_GET_REQ_SUCCESS:
            return {...state, commonAddressesDataLoading: false, commonAddressesDataLoaded: true, commonAddresses: action.data, error: null };
        case ADDRESS_BOOK_COMMON_GET_REQ_FAIL:
            return {...state, commonAddressesDataLoading: false, commonAddressesDataLoaded: false, error: action.err };
        case ADDRESS_BOOK_SAVED_GET_REQ_SENT:
            return { ...state, savedAddressesDataLoading: true, savedAddressesDataLoaded: false, error: null };
        case ADDRESS_BOOK_SAVED_GET_REQ_SUCCESS:
            return {...state, savedAddressesDataLoading: false, savedAddressesDataLoaded: true, savedAddresses: action.data, error: null };
        case ADDRESS_BOOK_SAVED_GET_REQ_FAIL:
            return {...state, savedAddressesDataLoading: false, savedAddressesDataLoaded: false, error: action.err };
        case ADDRESS_BOOK_ADD_REQ_SENT:
            return { ...state, addressAdding: true, addressAddDone: false, error: null };
        case ADDRESS_BOOK_ADD_REQ_SUCCESS:
            state.savedAddresses.push(action.data);
            return {...state, addressAdding: false, addressAddDone: true, error: null };
        case ADDRESS_BOOK_ADD_REQ_FAIL:
            return {...state, addressAdding: false, addressAddDone: true, error: action.err };
        case ADDRESS_BOOK_REMOVE_REQ_SENT:
            return { ...state, addressRemoving: true, addressRemoveDone: false, error: null };
        case ADDRESS_BOOK_REMOVE_REQ_SUCCESS:
            const addressesAfterRemoval = state.savedAddresses.filter((item) => item.address !== action.address.address);
            return {...state, savedAddresses: addressesAfterRemoval, addressRemoving: false, addressRemoveDone: true, error: null };
        case ADDRESS_BOOK_REMOVE_REQ_FAIL:
            return {...state, addressRemoving: false, addressRemoveDone: true, error: action.err };
    	default:
            return state;
	}
}
