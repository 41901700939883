import { useRef } from "react";
import { handleFetch } from "src/utils/fetch";
import { transformError } from "src/utils/transformErrors";

export const useDebounced = (...params) => {
    const [
        callBack = () => {},
        awaitMs = 100,
    ] = params;
    const timeOutReference = useRef(null);

    return (...args) => {
        clearTimeout(timeOutReference.current);
        timeOutReference.current = setTimeout(() => callBack(...args), awaitMs);
    };
};

/**
 * @name fetchHelper - fetch helper function
 * @param {object} params - helper params
 * @param {object} params.fetchParams - params to be passed as fetch body
 * @param {string} params.endPoint - fetch endpoint optional
 * @param {string} params.method - fetch method POST / GET / DELETE / E.T.C
 * @param {function(result)} params.onSuccess - callback on success
 * @param {function(error)} params.onFail - callback on error
 * @param {promise} params.fetcher - optional promise that will be used instead of fetch
 */
export const fetchHelper = (params) => {
    const {
        fetchParams = {},
        endPoint = "",
        method = "",
        onSuccess = val => val,
        onFail = val => val,
        fetcher,
    } = params;

    if (!fetcher) {
        (async () => {
            try {
                const result = await handleFetch(
                    endPoint,
                    method,
                    {...fetchParams}
                );
                onSuccess(result);
            } catch (error) {
                const errorTransformed = transformError(error);
                onFail(errorTransformed);
            }
        })()
        return;
    }

    (async () => {
        try {
            const result = await fetcher({...fetchParams});
            onSuccess(result);
        } catch (error) {
            const nextError = error?.error?.body || error.body;
            onFail(JSON.parse(nextError)?.error);
        }
    })()
}


