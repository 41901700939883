import React, { useState } from "react";
import BallotIcon from '@material-ui/icons/Ballot';
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import AddressField from "src/components/Forms/AddressField";
import {Link} from "react-router-dom";
import {ROUTE_NFT_INVENTORY, ROUTE_SCANNER} from "src/constants/routes";
import "./styles.scss";

const SendNftFields = props => {

	const {
		isMultiSend = false,
        disabled,
        tokenId,
		inputValue = "",
        sendingAddress,
		sendingCurrencyObj,
        onChangeId = value => value,
        onChangeAddress = address => address
    } = props;
	const {
		error,
		helperText,
	} = tokenId;

	const [NFTId, setNFTId] = useState(inputValue);
	const handleChange = ({ target }) => {
		const { value } = target;
		setNFTId(value);
		onChangeId(`${value}`);
	};

	const multiSelectPath = {
		pathname: ROUTE_NFT_INVENTORY + sendingCurrencyObj.blockchain + '/' + sendingCurrencyObj.name,
		state: {
			isMultiSend: true,
			preselectedNFTs: NFTId,
		}
	};

	return (
		<Grid container spacing={1} justifyContent="center" alignItems="center">
			<Grid item xs={12} className="relative-grid">
                <TextField
		            fullWidth
		            label={"NFT ID"}
		            placeholder={"102"}
		            error={error}
		            helperText={helperText}
		            required
		            onChange={handleChange}
					value={NFTId}
		            variant="outlined"
		            disabled={disabled}
		        />
				{
					isMultiSend && (
						<Link to={multiSelectPath} style={{position: "absolute", right: '15px', top: '30px', cursor: 'pointer'}}>
							<BallotIcon className={"nft-id-styles"} />
						</Link>
					)
				}
            </Grid>
            <Grid item xs={12} className="relative-grid">
            	<AddressField
            		value={sendingAddress.value}
            		label={"To Address"} 
            		error={sendingAddress.error} 
            		helperText={sendingAddress.helperText}
            		onChange={onChangeAddress}
            	/>
				<Link to={ROUTE_SCANNER} style={{position: "absolute", right: '15px', top: '30px', cursor: 'pointer'}}>
					<img src="/img/qrcode-scan.png" alt="qr-icon" className="qr-scanner-icon" />
				</Link>
            </Grid>
		</Grid>
	);
}

export default SendNftFields;