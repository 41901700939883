import React, { useEffect, useState } from "react";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { minVal, maxVal } from "../../../utils/validation";

const USDField = (props) => {
    const {
        input,
        label,
        required,
        meta: { error, dirty },
        type,
        autoComplete,
        className,
        disabled,
        maxAmount,
        minAmount
    } = props;
    const { value } = input;
    const [inputValue, setInputValue] = useState(value);
    const handleOnChange = (data) => {
        setInputValue(data.target.value)
        input.onChange(data);
    };

    useEffect(() => {
        if (!!value && value !== inputValue) {
            setInputValue(value);
            return;
        }
    }, [value, inputValue]);

    const nextError = dirty && !!error ? error : maxVal(maxAmount)(inputValue) || minVal(minAmount)(inputValue);

    return (
        <TextField
            fullWidth
            label={label}
            type={type}
            value={inputValue}
            error={!!nextError}
            helperText={nextError}
            required={required}
            margin="normal"
            variant="outlined"
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                disabled: (disabled === true)
            }}
            autoComplete={autoComplete}
            className={"input-nx " + className}
            onChange={handleOnChange}
        />
    );
};

export default USDField;