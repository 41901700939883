import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { Container, Grid, Typography } from '@material-ui/core';

import { ROUTE_SEND, ROUTE_DEFAULT_LOGGEDIN } from '../../../constants/routes';
import { EVENT_PROVIDER_BLOCKCHAIN_CHANGE } from '../../../constants/events';
import { changeSendingCurrency } from "../../../ducks/blockchain";
import { getAssets } from "../../../ducks/dashboard";
import SendTokens from '../../../components/SendTokens';
import BlockchainLabel from '../../../components/BlockchainLabel';

class SendMoney extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            catchErr: null
        }
    };

    componentDidMount = async() => {        
        try {
            if(!this.props.blockchain.sendingCurrency) {
                await this.loadCurrencyFromLocation();
            }
            var self = this; //this is a hack because this in the function is "window"
            this.onBlockchainChanged = function(e) {
                self.setState({});
            }
            window.addEventListener(EVENT_PROVIDER_BLOCKCHAIN_CHANGE, this.onBlockchainChanged);
        } catch (err) {
            this.setState({ catchErr: err });
        }
    }

    componentWillUnmount = () => {
        if(this.onBlockchainChanged) {
            window.removeEventListener(EVENT_PROVIDER_BLOCKCHAIN_CHANGE, this.onBlockchainChanged);
        }
    }

    loadCurrencyFromLocation = async() => {
        await this.props.getAssets();
        const pathname = this.props.history.location.pathname;
        const loc = pathname.substring(pathname.indexOf(ROUTE_SEND) + ROUTE_SEND.length).split("/");
        if(loc.length !== 2) {
            return false;
        }
        let locationBc = loc[0].toLowerCase();
        let locationCur = loc[1].toLowerCase();
        let { userAssetTypes } = this.props.dashboard;

        if(userAssetTypes && userAssetTypes.length > 1) {
            userAssetTypes.forEach((cur) => {
                if(cur.name === locationCur && cur.blockchain === locationBc) {
                    // selectedCurrencyObj = cur;
                    this.props.changeSendingCurrency(cur.name, cur);
                    return false;
                }
            });
        }
    }

    handleBack = () => {
    	this.props.history.push(ROUTE_DEFAULT_LOGGEDIN);
    }

  	render() {
  		if(this.state.catchErr) throw this.state.catchErr;
  		const { sendingCurrencyObj } = this.props.blockchain;
  		return (
            <Container className="home" component="main" maxWidth="sm">
      			<Grid item xs={12} className="centered-grid">
                    <BlockchainLabel /> 
        			<Typography variant="h5" align="center" gutterBottom>
    	                Send {sendingCurrencyObj.ticker}
    	            </Typography>
        			<SendTokens onTransactionSuccess={this.handleBack} onTransactionFail={this.handleBack} onTransactionBack={this.handleBack} />	        			
        		</Grid>
            </Container>
  		)
  	}
}

const mapState2props = state => ({
    blockchain: state.blockchain,
    dashboard: state.dashboard
});

const mapDispatch2props = {
	changeSendingCurrency,
    getAssets
};

export default compose(
    connect(mapState2props, mapDispatch2props)
)(SendMoney);