
import { 
	isGuestRoute,
	isNotRedirectableOnSigning,
	ROUTE_SHOW_MNEMONIC,
	ROUTE_SIGNIN,
} from "../constants/routes";

import { 
	KEY_ACTIVE_PK,
} from "../constants/storageKeys";
import {
  CURRENCY_ETH,
  CURRENCY_BNB
} from "../constants/currencies";
import { EVENT_USER_BALANCES_LOADED } from "../constants/events";

import EtherUtil from "./ethers";
import BlockExplorerUtils from "./blockchain/BlockExplorerUtils";
import StorageUtils from "./storageUtils";


export const isPauseTimedout = pausedAt => {
  return pausedAt <= 0 || pausedAt < Date.now() - 60000; //paused 1 minute ago
};

export const getSafeArea = async() => {
    return new Promise((resolve, reject) => {
      if(window.cordova && window.cordova.platformId && window.cordova.platformId === 'android') {
        reject('SafeArea: Not needed');
      } else if(window.plugins && window.plugins.safearea) {
        window.plugins.safearea.get((r) => {
          resolve(r);
        }, (rej) => {
          reject('SafeArea: '+rej);
        });
      } else {
        reject('SafeArea: Plugin not available');
      }
    });
};

export const goToSigning = props => {
    const { history } = props;
    const nextUrl = (isNotRedirectableOnSigning(history.location.pathname)? "" : "?next=" + history.location.pathname+history.location.search);
    history.push(ROUTE_SIGNIN + nextUrl);
}

export const handleNonResumeInvalidWallet = (props) => {
    const { history } = props;
    const url = history.location.pathname;
    const isGuest = isGuestRoute(url);
    const isNewUser = !StorageUtils.getItem(KEY_ACTIVE_PK);
    if (!isNewUser && !isGuest) {
      if (props.userLoaded) {
         props.logout(); 
      }
      goToSigning(props);
    }
}

/**
 * @dev Gets a derrived state from props: used to see if we need to "logout" use (to show the unlock screeen)
 */
export const handleDerrivedPropsChange = (props, prevState) => {
	try {
      let newState = prevState;
      const { wallet, history } = props;
      const isGuest = isGuestRoute(history.location.pathname);
      const url = history.location.pathname;
      if (url === ROUTE_SHOW_MNEMONIC && (prevState.toResume || !prevState.decryptedMnemonic)) {
        newState = {
          ...newState,
          checkPass: true
        }
      }

      if (prevState.toResume && !isGuest && !prevState.checkPass) {
        if (props.userLoaded) { props.logout(); }
        newState = {
          ...newState,
          toResume: false
        }
        goToSigning(props);
      }

      if (!prevState.toResume && (!wallet || !Object.keys(wallet).length)) {
        handleNonResumeInvalidWallet(props);
      } else if (!props.userLoaded && !isGuest) {
        props.getUserInfo();
      } else if (prevState.checkPass && url !== ROUTE_SHOW_MNEMONIC) {
        newState = {
          ...newState,
          checkPass: false
        };
      }
      return newState;
    } catch (err) {
      return {
        catchErr: err
      };
    }
}

/**
 * @dev Loads the assets if the user from backend
 *  This method and its usage need to be reviewed/refactored: moved from MyAssets component to be available appwide,
 *  when deep linking was added
 */
export const loadAssets = async(props, state, force) => {
  return new Promise((resolve, reject) => {
    if(props.dashboard.userAssetTypesLoading) {
      return;
    }
    else if(props.dashboard.userAssetTypesLoaded && !force) {
      resolve();
    } else {
        props.dashboard.userAssetTypes = [];
        props.dashboard.assetBalancesLoading = false;
        props.dashboard.assetBalancesLoaded = false;
        const promise = props.getAssets();
        promise.then(({ payload }) => {
            props?.context?.updateAssets(payload);
        });
        return promise;
    }
  });
};

/**
 * @dev Loads the assets of the user from backend
 *  This method and its usage need to be reviewed/refactored: moved from MyAssets component to be available appwide,
 *  when deep linking was added
 */
export const refreshAssets = async(props, state, setState, force) => {
    if(!props.dashboard.userAssetTypes.length || props.dashboard.assetBalancesLoading || (!force && props.dashboard.assetBalancesLoaded)) {
      return;
    }
    const userAssetTypes = props.dashboard.userAssetTypes;
    props.dashboard.assetBalancesLoading = true;
    props.dashboard.assetBalancesLoaded = false;
    const promises = [];
    const wallet = props.blockchain.wallet;
    if (!wallet || !Object.keys(wallet).length || !userAssetTypes) {
      return;
    }

    promises.push(userAssetTypes.map(async(cur, idx) => {
      window.reduxStore.dispatch(props.changeBalance(cur.name, cur.blockchain, '?', null)); //shows loading image
      if(cur.type === 'erc20') { //balance looks the same
        return new Promise(resolve => {
          EtherUtil.getTokenBalance(cur.address, cur.blockchain, cur.network, wallet).then((balBN) => {
            let bal = EtherUtil.lowValue(balBN, cur.bits);
            window.reduxStore.dispatch(props.changeBalance(cur.name, cur.blockchain, bal, balBN[0]));
            resolve({
                name: cur.name,
                blockchain: cur.blockchain,
                balance: bal,
            });
          }).catch(() => {
            window.reduxStore.dispatch(props.changeBalance(cur.name, cur.blockchain, '!', 0));
            resolve();
          });
        });
      } else if (cur.type === 'erc721') {
        return new Promise(resolve => {
          EtherUtil.getNftBalance(cur.address, cur.blockchain, cur.network, wallet).then((bal) => {
            window.reduxStore.dispatch(props.changeBalance(cur.name, cur.blockchain, bal, bal));
            resolve({
                name: cur.name,
                blockchain: cur.blockchain,
                balance: bal,
            });
          }).catch(() => {
            window.reduxStore.dispatch(props.changeBalance(cur.name, cur.blockchain, '!', 0));
            resolve();
          });
        });
      } else if(cur.type === 'erc223G') {
        return new Promise(resolve => {
          EtherUtil.getTokenBalance(cur.address, cur.blockchain, cur.network, wallet).then((balBN) => {
            let balance = EtherUtil.lowValue(balBN, cur.bits);
            window.reduxStore.dispatch(props.changeBalance(cur.name, cur.blockchain, balance, balBN));
            resolve({
                name: cur.name,
                blockchain: cur.blockchain,
                balance: balance,
            });
          }).catch(() => {
            window.reduxStore.dispatch(props.changeBalance(cur.name, cur.blockchain, '!', 0));
            resolve();
          });
        });
      } else if(cur.type === 'coin') {
        return new Promise(resolve => {
          EtherUtil.getBalance(cur.blockchain, cur.network, wallet).then((balBN) => {
            let balance = EtherUtil.lowValue(balBN, cur.bits);
            window.reduxStore.dispatch(props.changeBalance(cur.name, cur.blockchain, balance, balBN));
            if(cur.name === CURRENCY_ETH || cur.name === CURRENCY_BNB) {
              BlockExplorerUtils.isSufficentBalance(balBN).then((res) => {
                if(!res) {
                  props.setGasCurWarning(cur.name);
                }
              });
            }
            resolve({
                name: cur.name,
                blockchain: cur.blockchain,
                balance: balance,
            });
          }).catch(() => {
            window.reduxStore.dispatch(props.changeBalance(cur.name, cur.blockchain, '!', 0));
            resolve();
          });
        });
      } else if(cur.type === 'uown') {
        return new Promise(resolve => {
          EtherUtil.getUownBalance(cur.address, cur.principal, wallet).then((balance) => {
            balance = EtherUtil.lowValue(balance, cur.bits);
            window.reduxStore.dispatch(props.changeBalance(cur.name, cur.blockchain, balance));
            resolve({
                name: cur.name,
                blockchain: cur.blockchain,
                balance: balance,
            });
          }).catch((err) => {
            window.reduxStore.dispatch(props.changeBalance(cur.name, cur.blockchain, '!', 0));
            resolve();
          });
        });          
      } else if(cur.type === 'custom') {
        return new Promise(async resolve => {
          let res = await props.getIn4xProfile();
          if(props.in4x.profile && typeof props.in4x.profile.BALANCE === 'number') {
            window.reduxStore.dispatch(props.changeBalance(cur.name, cur.blockchain, props.in4x.profile.BALANCE));
          } else if(res && res.data && res.data.BALANCE) {
            window.reduxStore.dispatch(props.changeBalance(cur.name, cur.blockchain, res.data.BALANCE));
          } else {
            window.reduxStore.dispatch(props.changeBalance(cur.name, cur.blockchain, 0.0));
          }
            resolve({
                name: cur.name,
                blockchain: cur.blockchain,
                balance: props?.in4x?.profile?.BALANCE || res?.data?.BALANCE,
            });
        });
      }
      return null;
    }));

    return Promise.all(promises).then((results) => {
        Promise.all(results[0]).then(props.context.updateBalances)
        window.dispatchEvent(new CustomEvent(EVENT_USER_BALANCES_LOADED));
        props.dashboard.assetBalancesLoaded = true;
    }).finally(()=> {
      props.dashboard.assetBalancesLoading = false;
    })
};

/**
 * @dev Throw loading timeout error
 * @param params { Object } params - object container
 * @param callback { function } callback - function to be called after timeout
 * @param dashboard { Object } dashboard - objectContaining loader states
 * @param delay { Number } delay - seconds to await before callback is triggered
 * if data loading takes more the 5s throw user notification about
 * network connection issues
 */
let timeOutInstance = null;
export const setNetworkTimeout = (params) => {
    const { callback = () => {}, dashboard = {}, delay = 5 } = params;
    const { userAssetTypesLoading, userAssetTypesLoaded } = dashboard;

    if(!timeOutInstance && userAssetTypesLoading) {
        timeOutInstance = setTimeout(callback, delay * 1000);
    }

    if (timeOutInstance && !userAssetTypesLoading && userAssetTypesLoaded) {
        clearTimeout(timeOutInstance);
        timeOutInstance = null;
    }
}