import React from "react";
import PropTypes from "prop-types";
import { CardContent, Grid, Paper, Typography } from "@material-ui/core";
import { Block, SyncAlt } from "@material-ui/icons";

import { openLink } from 'src/utils/linksOpenPolyfill';
import "./styles.scss";

const ActiveSessions = props => {
    const {
        activeSessionsList = [],
        onDisconnectClick = () => {},
    } = props;

    if(Object.keys(activeSessionsList).length === 0) {
        return (<></>);
    }

    const sessionsList = Object.keys(activeSessionsList).map((peerId) => {
        const item = activeSessionsList[peerId];
        const { peerMeta: { name, url }} = item;
        const handleDisconnectClick = () => onDisconnectClick(peerId);
        const displayName = name || url;
        return (
            <Grid item xs={12} key={`active-connect-session-${peerId}`}>
                <Paper ariant="outlined" elevation={2} >
                    <CardContent style={{ paddingBottom: 16 }}>
                        <Grid container direction={"row"}>
                            <SyncAlt style={{ marginRight: 20 }} color={'primary'} onClick={() => openLink(null, url)}/>
                            <Typography
                                align="left"
                                color="primary"
                                variant="body2"
                                component="p"
                                className={"ellipsed-text"}
                                style={{ fontSize: 16 }}
                            >
                                {displayName}
                            </Typography>
                            <Block
                                style={{ marginLeft: "auto" }}
                                color={'error'}
                                onClick={handleDisconnectClick}
                            />
                        </Grid>
                    </CardContent>
                </Paper>
            </Grid>
        )
    });

    return (
        <Grid container spacing={2} className={'spaced-top'} justifyContent={'center'}>
            <Grid item xs={12}>
                <Typography
                    variant="h6"
                    align="center"
                    gutterBottom
                    color="primary"
                >
                    Active Sessions
                </Typography>
            </Grid>
            { sessionsList }
        </Grid>
    )
};

ActiveSessions.propTypes = {
    activeSessionsList: PropTypes.object,
};

export default ActiveSessions;