import React, {
    useState,
    useEffect,
    useContext, useRef,
} from "react";
import PropTypes from "prop-types";
import { Container } from "@material-ui/core";
import { UserAssetsContext } from "src/contexts/user-assets";
import { useDebounced } from "src/utils/helpers";
import { ROUTE_DEFAULT_LOGGEDIN } from "src/constants/routes";
import ProviderUtils from "src/utils/blockchain/ProviderUtils";
import SwapConfig from "./components/swap-config";
import TransactionProcess from "./components/process";
import {
    getSwappableCurrencies,
    getIsSwapActivated,
    activateSwap,
    getSwapRate,
    swapTokens,
} from "./utils";

const TokensSwap = (props) => {
    const {
        history,
        match: {
            params: {
                currency = ""
            }
        }
    } = props;
    const {
        assets = [],
        balances = [],
        walletPasswordHash = "",
    } = useContext(UserAssetsContext);
    const [swappableCurrencies, setSwappableCurrencies] = useState([]);
    const [toCurrency, setToCurrency] = useState("");
    const [swapContractAddress, setSwapContractAddress] = useState("");
    const [isSwapActivated, setIsSwapActivated] = useState("");
    const [isOnConfirm, setIsOnConfirm] = useState(false);
    const [swapRate, setSwapRate] = useState("");
    const [swapAmounts, setSwapAmounts] = useState(null);
    const [gasLimit, setGasLimit] = useState("");
    const [transactionHash, setTransactionHash] = useState("");
    const [error, setError] = useState("");
    const [transactionReceipt, setTransactionReceipt] = useState(null);
    const gasDataRef = useRef({});

    const goHome = () => history.push(ROUTE_DEFAULT_LOGGEDIN);
    const handleBack = () => {
        setIsOnConfirm(false);
        if (!isOnConfirm || transactionHash) {
            goHome()
        }
    };

    const handleLoadGasLimit = useDebounced(
        (nextAmounts) => {
            swapTokens({
                swapFromCurrency: currency,
                swapToCurrency: toCurrency,
                swapContractAddress,
                assets,
                swapAmounts: nextAmounts,
                walletPasswordHash,
                estimateGasOnly: true,
                onSuccess: setGasLimit,
                onError: setError,
            });
        },
        1500,
    );

    const handleLoadRate = useDebounced(
        (nextAmounts) => {
            getSwapRate({
                swapFromCurrency: currency,
                swapToCurrency: toCurrency,
                swapContractAddress,
                walletPasswordHash,
                swapAmounts: nextAmounts,
                assets,
                onSuccess: setSwapRate,
            });
        },
        1000,
    );

    const handleOnSwapToChange = (swapToCurrency) => {
        setToCurrency(swapToCurrency);
        const swapCurrencyObj = swappableCurrencies.find(item => item.value === swapToCurrency);
        setSwapContractAddress(swapCurrencyObj?.swapContractAddress);
        getIsSwapActivated({
            swapFromCurrency: currency,
            swapContractAddress: swapCurrencyObj?.swapContractAddress,
            walletPasswordHash,
            assets,
            onSuccess: setIsSwapActivated,
            onError: setError,
        });
    };

    const handleCloseError = () => {
        setError("");
    };

    const handleAmountsChange = (nextAmounts) => {
        setSwapAmounts(nextAmounts);
        setGasLimit("");
        setError("");

        if (nextAmounts?.send !== swapAmounts?.send) {
            handleLoadRate(nextAmounts);
        }

        if (nextAmounts?.send === swapAmounts?.send) {
            handleLoadGasLimit(nextAmounts);
        }
    };

    const handleCTAClick = () => {
        if (isSwapActivated === "unactivated") {
            activateSwap({
                swapFromCurrency: currency,
                swapContractAddress,
                walletPasswordHash,
                assets,
                onSuccess: setIsSwapActivated,
                onError: setError,
            });
            return;
        }

        setIsOnConfirm(true);
    };

    const handleSwapConfirm = () => {
        swapTokens({
            swapFromCurrency: currency,
            swapToCurrency: toCurrency,
            swapContractAddress,
            assets,
            swapAmounts,
            walletPasswordHash,
            gasData: { ...gasDataRef.current },
            onSuccess: setTransactionHash,
            onError: setError,
            onMintFinish: setTransactionReceipt,
        });
    };

    useEffect(() => {
        getSwappableCurrencies({
            swapToCurrency: currency,
            onSuccess: setSwappableCurrencies,
        });
    }, [currency])

    useEffect(() => {
        if (isSwapActivated === "activated") {
            getSwapRate({
                swapFromCurrency: currency,
                swapToCurrency: toCurrency,
                swapContractAddress,
                walletPasswordHash,
                assets,
                onSuccess: setSwapRate,
            });
        }
    }, [assets, currency, isSwapActivated, swapContractAddress, toCurrency, walletPasswordHash])

    const { balance = "" } = balances ? balances.find(item => {
        return item?.name === currency && item.blockchain === ProviderUtils.activeBlockchain();
    }) : {};

    return (
        <Container
            className="home"
            component="main"
            maxWidth="sm"
        >
            { isOnConfirm && (
                <TransactionProcess
                    receipt={transactionReceipt}
                    error={error}
                    transactionHash={transactionHash}
                    onLogin={handleSwapConfirm}
                    onFinish={goHome}
                />
            )}
            {!isOnConfirm && (
                <SwapConfig
                    currency={currency}
                    toCurrency={toCurrency}
                    balance={balance}
                    swapRate={swapRate}
                    gasLimit={gasLimit}
                    gasDataRef={gasDataRef}
                    error={error}
                    swappableCurrencies={swappableCurrencies}
                    swapAmounts={swapAmounts}
                    isSwapActivated={isSwapActivated}
                    onSwapChange={handleOnSwapToChange}
                    onAmountsChange={handleAmountsChange}
                    onCloseError={handleCloseError}
                    onCTAClick={handleCTAClick}
                    onBackClick={handleBack}
                />
            )}
        </Container>
    )
};

TokensSwap.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
}

export default TokensSwap;