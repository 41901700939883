import React from "react";

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

let DropdownSelect = ({id,name,label,value,options,onChange,useKV,disabled}) => {
	return (
		<FormControl variant="outlined" className="override-nx spaced-top-s" fullWidth align="left"> 
			<InputLabel id={`${id}-label`}>{label}</InputLabel>
			<Select
			  	labelId={`${id}-label`}
	          	id={id}
	          	name={name}
	          	label={label}
	          	autoWidth
	          	value={disabled ? '' : value}
	          	onChange={onChange}
				disabled={disabled}
	        >
	          <MenuItem value="">
	            <em>Please choose</em>
	          </MenuItem>
	          { options.map((o, i) => (
	          	<MenuItem key={i} value={useKV? o.value:o}>{useKV? o.label:o}</MenuItem>
	          ))}
	        </Select>
        </FormControl>
    )
}

export default DropdownSelect;