import React from "react";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const IndaCoinPaymentDetails = props => {
	const { indaPayment } = props;
	const createdAt = indaPayment && indaPayment.CREATED_AT ? (new Date(indaPayment.CREATED_AT * 1000)).toLocaleString() : '';
	return (
		<Grid container spacing={1} justifyContent="center" alignItems="center">
			<Accordion TransitionProps={{ unmountOnExit: true }} defaultExpanded>
		        <AccordionSummary
		          expandIcon={<ExpandMoreIcon />}
		          aria-controls="panel1a-content"
		          id="panel1a-header"
		        >
		        	<Typography className="color-nx">Card Transaction Details</Typography>
		        </AccordionSummary>
				<AccordionDetails style={{padding: '0'}}>
	    			<TableContainer component={Paper}>
				      <Table size="small" aria-label="a dense table">
				        <TableBody>
				            <TableRow key="ti">
				              <TableCell component="td" scope="row">Transaction ID</TableCell>
				              <TableCell align="right"><Typography fontSize="2" className="color-nx">{indaPayment.TRANSACTION_ID}</Typography></TableCell>
				            </TableRow>
				            <TableRow key="cd">
				              <TableCell component="td" scope="row">Payment Date</TableCell>
				              <TableCell align="right"><Typography fontSize="2" className="color-nx">{createdAt}</Typography></TableCell>
				            </TableRow>
				            <TableRow key="ui">
				              <TableCell component="td" scope="row">User</TableCell>
				              <TableCell align="right"><Typography fontSize="2" className="color-nx">{indaPayment.EMAIL_ADDRESS}</Typography></TableCell>
				            </TableRow>
				            <TableRow key="ain">
				              <TableCell component="td" scope="row">Payment Amount</TableCell>
				              <TableCell align="right"><Typography fontSize="2" className="color-nx">{indaPayment.CUR_IN} {indaPayment.AMOUNT_IN}</Typography></TableCell>
				            </TableRow>
				         	<TableRow key="aout">
				              <TableCell component="th" scope="row">Receievd Amount</TableCell>
				              <TableCell align="right"><Typography fontSize="2" className="color-nx">{indaPayment.CUR_OUT} {indaPayment.AMOUNT_OUT}</Typography></TableCell>
				            </TableRow>
				            <TableRow key="st">
				              <TableCell component="td" scope="row">Payment Status</TableCell>
				              <TableCell align="right"><Typography fontSize="2" color="secondary">{indaPayment.STATUS}</Typography></TableCell>
				            </TableRow>
				            { indaPayment.TRANSACTION_HASH && (<TableRow key="hash">
				              <TableCell component="td" scope="row">Transaction</TableCell>
				              <TableCell align="right"><Typography fontSize="2" className="color-nx">{indaPayment.TRANSACTION_HASH}</Typography></TableCell>
				            </TableRow>)}
				        </TableBody>
				      </Table>
				    </TableContainer>
			    </AccordionDetails>
			</Accordion>
  		</Grid>
	)
};

export default IndaCoinPaymentDetails;