import React from "react";
import PropTypes from "prop-types";
import { Button, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import { SettingsEthernet } from "@material-ui/icons";
import QRScanner from "src/components/QRScanner/QRScanner";

const WalletConnectBlock = props => {
    const {
        isCameraOpen = false,
        handleOnScan = () => {},
        handleScanClose = () => {},
        handleCameraOpen = () => {},
        scannedCode = '',
        handleOnCodeChange = () => {},
        handleConnectClick = () => {},
    } = props;

    const connectButtonType = !scannedCode ? "disabled" : "primary";

    return (
        <Grid container className="centered-grid spaced-top">
            <Grid item xs={12}>
                <img src="/img/walletconnect.svg" style={{height:"50px", marginBottom: "1rem"}} alt="WalletConenct" />
                <Typography variant="h5" align="center" gutterBottom color="primary">
                    WalletConnect
                </Typography>
                <Typography align="center" gutterBottom>
                    Scan a WalletConnect QR code or paste WalletConnect code (URI) in the box below
                </Typography>
            </Grid>
            <Grid item xs={12} className="centered-grid">
                {
                    isCameraOpen && (
                        <QRScanner
                            onScan={handleOnScan}
                            onScanClose={handleScanClose}
                        />
                    )
                }
                <Button
                    color="primary"
                    className="spaced__btn"
                    fullWidth
                    variant="contained"
                    onClick={handleCameraOpen}
                >
                    Open Camera
                </Button>
                <Grid container className="spaced-top">
                    <Grid item xs={10}>
                        <TextField
                            type="text"
                            value={scannedCode}
                            variant="outlined"
                            label="Wallet Connect Code"
                            onChange={handleOnCodeChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton onClick={handleConnectClick} disabled={!scannedCode}>
                            <SettingsEthernet
                                fontSize={'large'}
                                color={connectButtonType}
                            />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

WalletConnectBlock.propTypes = {
    isCameraOpen: PropTypes.bool,
    handleOnScan: PropTypes.func,
    handleScanClose: PropTypes.func,
    handleCameraOpen: PropTypes.func,
    scannedCode: PropTypes.string,
    handleOnCodeChange: PropTypes.func,
    handleConnectClick: PropTypes.func,
};

export default WalletConnectBlock;