import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import history from "../../history";
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import CropFreeIcon from '@material-ui/icons/CropFree';
import PageviewIcon from '@material-ui/icons/Pageview';
import ReceiptIcon from '@material-ui/icons/Receipt';
import BallotIcon from '@material-ui/icons/Ballot';
import BalanceBlock from "../BalanceBlock";

import BlockExplorerUtils from 'src/utils/blockchain/BlockExplorerUtils';
import { openLink } from 'src/utils/linksOpenPolyfill';

import { getCurrencyIcon } from "src/constants/currencies";
import { 
  ROUTE_NFT_INVENTORY, 
  ROUTE_SEND_NFT, 
  ROUTE_SHOW_ADDRESS, 
  ROUTE_TRANSACTION_HISTORY
 } from "src/constants/routes";

import { changeSendingCurrency } from "src/ducks/blockchain";

const NftAsetBalance = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  let { CUR, balance, blockchain: {wallet} } = props;

  const handleClose = (action) => {
  		switch(action) {
  		  case 'inventory':
  		  	props.changeSendingCurrency(props.CUR.name, props.CUR);
  		  	history.push({
                pathname: ROUTE_NFT_INVENTORY + props.CUR.blockchain + '/' + props.CUR.name,
                state: {
                    isMultiSend: false
                }
            });
  		  	break;
          case 'multiSend':
  		  	props.changeSendingCurrency(props.CUR.name, props.CUR);
  		  	history.push({
                pathname: ROUTE_NFT_INVENTORY + props.CUR.blockchain + '/' + props.CUR.name,
                state: {
                    isMultiSend: true
                }
            });
  		  	break;
	      case 'send':
	      	props.changeSendingCurrency(props.CUR.name, props.CUR);
	      	history.push(ROUTE_SEND_NFT + props.CUR.blockchain + '/' + props.CUR.address + '/0');
	      	break;
	      case 'receive':
	      	history.push(ROUTE_SHOW_ADDRESS);
	      	break;
	      case 'history':
	      	props.changeSendingCurrency(props.CUR.name, props.CUR);
	        history.push(ROUTE_TRANSACTION_HISTORY + props.CUR.blockchain + '/' + props.CUR.name + "/" + props.CUR.address);
	        break;
	      default: 
	        setAnchorEl(null);
	    }
	    if(props.onToggle) {
	      props.onToggle(props.CUR);
	    }
  }
  
  const scannerLink = BlockExplorerUtils.scanToken(CUR.address, CUR.blockchain) + '?a='+ wallet.address;
  const openseaLink = CUR.openseaCollection? BlockExplorerUtils.openseaNftCollection(CUR.openseaCollection): null;
  const icon = CUR.icon || getCurrencyIcon(CUR.ticker);

  return (
  	<>
        <BalanceBlock balance={balance} label={CUR?.name} icon={icon} onClick={(e) => {setAnchorEl(e.target)}} />
        <Menu
          className="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          { balance>0 && (<MenuItem onClick={()=>{handleClose('inventory')}}><PhotoLibraryIcon />&nbsp;My Inventory</MenuItem>)}
          { balance>0 && (<MenuItem onClick={()=>{handleClose('multiSend')}}><BallotIcon />&nbsp;Multi Send</MenuItem>)}
          <MenuItem onClick={()=>{handleClose('receive')}}><CropFreeIcon />&nbsp;Receive</MenuItem>
          { openseaLink && (<MenuItem onClick={()=>{openLink(null, openseaLink)}}><img src="/img/opensea.png" alt="OpenSea" style={{width:"20px"}} />&nbsp;Open Sea</MenuItem>)}
          { scannerLink && (<MenuItem onClick={()=>{openLink(null, scannerLink)}}><PageviewIcon />&nbsp;Block Explorer</MenuItem>)}
          <MenuItem onClick={()=>{handleClose('history')}}><ReceiptIcon />&nbsp;Transaction History</MenuItem>
        </Menu>
      </>
    );
}

const mapState2props = state => ({
  blockchain: state.blockchain
});

const mapDispatch2props = {
  changeSendingCurrency,
}

export default compose(
    connect(mapState2props, mapDispatch2props)
)(NftAsetBalance);