import React from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import CustomSnackbar from "../../components/CustomSnackbar";
import CopyIcon from '@material-ui/icons/FileCopy';
import Typography from '@material-ui/core/Typography';

const CopyToClipboardButton = (props) => {
	const { label = "Copy to Clipboard" } = props;
	const [isAlertShown, setAlertShown] = React.useState(false);
	return (
		<span className="copy__code">
	        <Typography>{label}</Typography>
	        <CopyToClipboard text={props.text} onCopy={() => { setAlertShown(true); }}>
	        	<CopyIcon className="fill-nx"/>
	        </CopyToClipboard>
	        <CustomSnackbar
	            anchorOrigin={{horizontal: "center", vertical: "bottom"}}
	            variant="success"
	            open={isAlertShown}
	            message={"Copied to clipboard"}
	            onClose={() => { setAlertShown(false); }}
	            autoHideDuration={6000}
	        />
		</span>
	);
};

export default CopyToClipboardButton;