import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

//Components:
import CurrencyAmountField from "../Forms/CurrencyAmountField";
import CurrencyUSDRateField from "../Forms/CurrencyUSDRateField";
import AddressField from "../Forms/AddressField";
import TransactionFeeConfiguration from "../TransactionFeeConfiguration";
import TransactionStatusBlock from "../TransactionStatusBlock";

import ProcessingAlert from "src/common/processing-alert";
import BlockchainTransactionConfirm from 'src/common/blockchain-transaction-confirm';
//Views
import TransactionCreatedModal from "../../views/modals/TransactionCreatedModal";
import TransactionFailedModal from "../../views/modals/TransactionFailedModal";

import EtherUtil from "../../utils/ethers";
import GasUtils from "../../utils/blockchain/GasUtils";
import { getError } from "../../utils/transformErrors";
import { exRateIdxKey } from "../../utils/in4xUtils";

import { ROUTE_STAKE_DASH } from "../../constants/routes";
import { CURRENCY_USD } from "../../constants/currencies";
import { TRX_TYPE_APPROVE, TRX_TYPE_DEPOSIT } from "../../constants/blockchain";

import { checkWalletPassword, loginWithWallet } from "src/ducks/signin";
import { getAssets } from "src/ducks/dashboard";
import { changeGasEstimate, resetGas } from "src/ducks/blockchainGas";
import { getStakingData } from "src/ducks/staking";
import { getExchangeRate } from "src/ducks/in4x";
import {
    addPendingTransactionToBackend,
    addTransaction,
    changeBalance,
    changeSendingAddress,
    changeSendingAmount,
    changeSendingCurrency,
    changeSendingTrxType,
    removePending,
    removePendingListFromBackend,
    clearTransactionData
} from "src/ducks/blockchain";

import history from "../../history";
import Typography from "@material-ui/core/Typography";

class ApproveAndTransactTokens extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            exRate: 0,
            rate: 0,
            successModal: false,
            failedModal: false,
            error: "",
            checkPass: false,
            catchErr: null,
            submittedApproval: false,
            submittedDeposit: false,
            approveHash: "",
            executeHash: "",
            isProcessing: false,
            executeTrxType: props.trxType,
            actionLabel: props.actionLabel,
            targetAddressLabel: props.targetAddressLabel,
            texts: props.texts
        };
    }

    componentWillMount = async () => {
        try {
            this.props.changeSendingTrxType(TRX_TYPE_APPROVE);
            if (this.props.blockchain.sendingAmount && this.props.blockchain.sendingAmount.value) {
                this.changeRate(this.props.blockchain.sendingAmount.value);
            }
        } catch (err) {
            this.setState({ catchErr: err });
        }
    }

    shouldComponentUpdate = async() => {
        if(!this.props.blockchain.sendingCurrency || this.state.isLoaded || this.props.in4x.error) {
            return false;
        }
        const key = exRateIdxKey(CURRENCY_USD, this.props.blockchain.sendingCurrency);

        if(this.props.in4x.exRates[key]) {
            this.setState({exRate: this.props.in4x.exRates[key].high, isLoading: false, isLoaded: true});
        } else if(!this.props.in4x.in4xRateLoading) {
            this.props.getExchangeRate(CURRENCY_USD, this.props.blockchain.sendingCurrency);
            this.setState({isLoading: true});
        }
        return true;
    }

    componentWillUnmount = () => {
        this.props.clearTransactionData();
        this.props.resetGas();
    }

    handleBackButtonClick = () => {
        if(this.props.onTransactionBack) {
            this.props.onTransactionBack();
        } else {
            history.push(ROUTE_STAKE_DASH);
        }
    };

    handleCloseModal = () => {
        try {
            this.setState({ successModal: false, failedModal: false, error: "" });
            if(this.props.onTransactionSuccess) {
                this.props.onTransactionSuccess(this.state.approveHash, this.state.executeHash);
            } else {
                history.push(ROUTE_STAKE_DASH);
            }
        } catch (err) {
            this.setState({ catchErr: err });
        }
    };

    handleErrorModal = () => {
        try {
            this.setState({ successModal: false, failedModal: false, error: "" });
            if(this.props.onTransactionFail) {
                this.props.onTransactionFail();
            } else {
                history.push(ROUTE_STAKE_DASH);
            }
        } catch(err) {
            this.setState({ catchErr: err });
        }
    }

    changeRate = (amount) => {
        const exRate = this.state.exRate;
        const trate = (amount > 0 && exRate > 0) ? (amount * exRate).toFixed(4) : 0;
        this.setState({ rate: trate });
    }

    onBlockchainError = (err) => {
        if (err && err.error && err.error.message) {
            this.setState({failedModal: true, error: err.error.message});
        }
        if (err && err.code && err.code === "REPLACEMENT_UNDERPRICED") {
            this.setState({ failedModal: true, error: 'ERROR_UNDERPRICED_REPLACEMENT_TRX', isProcessing: false });
        } else if (err && err.toString().indexOf('Gas price is too low')) {
            this.setState({ failedModal: true, error: err.toString(), isProcessing: false });
        } else {
            this.setState({ failedModal: true, error: 'ERROR_CONFIRM_TRX', isProcessing: false });
        }
        if(this.props.onTransactionFail) {
            this.props.onTransactionFail(err);
        }
    }

    submitApproveTransaction = async() => {
        try {
            const { actionLabel } = this.state;
            this.setState({processingLabel: `Approving ${actionLabel}...`, isProcessing: true, checkPass: false });
            const { wallet, sendingAddress, sendingAmount, sendingCurrencyObj } = this.props.blockchain;
            this.props.changeSendingAmount(sendingAmount.value);
            let gasOverrides = GasUtils.generateTransactionGas(this.props.blockchainGas);
            const txReceipt = await EtherUtil.newApproveTransaction(
                wallet,
                sendingCurrencyObj.address, //iOWN Token Address
                sendingAddress.value, //Staking contract
                sendingAmount.value, //Amount we're staking
                sendingCurrencyObj.bits,
                gasOverrides
            );
            if (typeof txReceipt === "object") {
                await this.onApproveTransactionSubmitted(txReceipt);
                this.setState({processingLabel: 'Waiting for approve mining...'});
                this.props.changeSendingTrxType(TRX_TYPE_DEPOSIT);
            } else {
                let error;
                if (!txReceipt || !txReceipt.indexOf) {
                    error = 'ERROR_CONFIRM_TRX';
                }
                this.setState({ failedModal: true, error, checkPass: false });
            }
        } catch(err) {
            this.onBlockchainError(err);
        }
    }

    onApproveTransactionSubmitted = async(trxReceipt) => {
        const { wallet: { address }, sendingAddress, sendingAmount } = this.props.blockchain;
        const { actionLabel, executeTrxType } = this.state;
        this.props.addTransaction(trxReceipt);
        this.setState({ approveHash: trxReceipt.hash, checkPass: false, submittedApproval: true, processingLabel: `Estimating ${actionLabel} gas...` });
        EtherUtil.monitorPendingTransaction(trxReceipt, () => {
            this.props.removePending(trxReceipt.hash);
            this.props.removePendingListFromBackend(address, [trxReceipt.hash]);
            EtherUtil.estimateTxGas(sendingAddress.value, //Staking address
                this.props.blockchain.sendingCurrencyObj.address, //iOWN address
                this.props.blockchain.sendingCurrency,
                sendingAmount.value,
                executeTrxType,
                this.props.blockchain.sendingCurrencyObj.bits,
                this.props.blockchain.wallet)
            .then((estimate) => {
                if (estimate) {//BigNumber
                    this.props.changeGasEstimate(estimate.toString());
                    this.submitDepositTransaction();
                }
            });
        });
    }

    submitDepositTransaction = async() => {
        try {
            const { actionLabel } = this.state;
            this.setState({processingLabel: `Executing ${actionLabel} ...`});
            const { wallet, sendingAddress, sendingAmount, sendingCurrencyObj } = this.props.blockchain;
            let gasOverrides = GasUtils.generateTransactionGas(this.props.blockchainGas);
            const txReceipt = await EtherUtil.newDepositTransaction(
                wallet,
                sendingAddress.value,  //Staking contract
                sendingAmount.value,   //Amount we're staking
                sendingCurrencyObj.bits,
                gasOverrides
            );
            if (typeof txReceipt === "object") {
                this.onDepositTransactionSubmitted(txReceipt);
            } else {
                let error;
                if (!txReceipt || !txReceipt.indexOf) {
                    error = 'ERROR_CONFIRM_TRX';
                }
                this.setState({ failedModal: true, error, checkPass: false });
            }
        } catch(err) {
            this.onBlockchainError(err);
        }
    }

    onDepositTransactionSubmitted = (trxReceipt) => {
        const {
            wallet, sendingAddress, sendingAmount, sendingCurrency, sendingTrxType
        } = this.props.blockchain;
        const { actionLabel } = this.state;
        this.props.addTransaction(trxReceipt);
        this.props.addPendingTransactionToBackend(wallet.address, {
            blockchain: trxReceipt.blockchain,
            network: trxReceipt.network,
            hash: trxReceipt.hash,
            receiver: sendingAddress.value,
            currency: sendingCurrency,
            amount: sendingAmount.value,
            trxType: sendingTrxType
        });
        this.setState({ executeHash: trxReceipt.hash, checkPass: false, processingLabel: `Waiting for ${actionLabel} mining...` });
        EtherUtil.monitorPendingTransaction(trxReceipt, () => {
            this.props.removePending(trxReceipt.hash);
            this.props.removePendingListFromBackend(wallet.address, [trxReceipt.hash]);
            this.setState({successModal: true, submittedDeposit: true, isProcessing: false});
        });
    }

    render() {
        if (this.state.catchErr) throw this.state.catchErr;
        let { rate, processingLabel, executeHash, error, checkPass, isProcessing, submittedDeposit } = this.state;
        const {
            blockchain: {
                sendingAmount,
                sendingAddress,
                submitEnabled,
                sendingCurrencyObj,
                gasFee
            },
            blockchainGas: {
                gasReady,
                estimateGas
            },
            dashboard: {
                userAssetTypesLoading,
                assetBalancesLoading,
                assetBalancesLoaded,
            },
            changeSendingAddress,
            disabled,
            hideBack,
            texts,
        } = this.props;

        const validationError = sendingAmount.error || sendingAddress.error || gasFee.error;
        const validationHelperText = sendingAmount.helperText || sendingAddress.helperText || gasFee.helperText;

        const isSubmitAllowed = submitEnabled && gasReady && !validationError && !!estimateGas;
        const ticker = (sendingCurrencyObj && sendingCurrencyObj.ticker? sendingCurrencyObj.ticker:'');
        return (
            <Grid container spacing={1} justifyContent="center" alignItems="center" pt={3}>
                <TransactionCreatedModal openOn={this.state.successModal} onClose={this.handleCloseModal} trxhash={executeHash} />
                <TransactionFailedModal openOn={this.state.failedModal} onClose={this.handleErrorModal} errorText={getError(error)} />

                {!checkPass && !isProcessing && !submittedDeposit && (
                    <>
                        <Grid container spacing={1} justifyContent="center" alignItems="center">
                            <Grid item xs>
                                {assetBalancesLoaded && !assetBalancesLoading && (
                                	<CurrencyAmountField 
                                		value={sendingAmount.value} 
                                		error={sendingAmount.error} 
                                		helperText={sendingAmount.helperText} 
                                		adornment={ticker}
                                		disabled={disabled}
                                	/>)}
                                {(assetBalancesLoading || userAssetTypesLoading) && (
                                    <ProcessingAlert message={"Loading blockchain assets..."} />
                                )}
                            </Grid>

                            {rate > 0 && (
                                <Grid item xs={12}>
                                    <CurrencyUSDRateField rate={rate} />
                                </Grid>
                            )}
                            <Grid item xs={12} className="relative-grid">
                            	<AddressField
                            		value={sendingAddress.value}
                            		label={"Staking Address"} 
                            		error={sendingAddress.error} 
                            		helperText={sendingAddress.helperText}
                            		onChange={changeSendingAddress}
                            		disabled={disabled}
                            	/>
                            </Grid>
                            <TransactionFeeConfiguration />
                        </Grid>
                        { validationError && (
                          <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">{validationHelperText}</Alert>
                          </Grid>
                        )}
                        <Button color="primary" className="spaced__btn" fullWidth disabled={!isSubmitAllowed}
                            onClick={() => this.setState({ checkPass: true }) } variant="contained">
                            Send Transaction
                        </Button>
                        { !hideBack && (<Button color="default" className="spaced__btn" fullWidth variant="contained" onClick={() => {this.handleBackButtonClick()}}>
                            Back
                        </Button>)}
                    </>
                )}
                { checkPass && (
                    <BlockchainTransactionConfirm onBack={this.handleBackButtonClick} onValidPassword={this.submitApproveTransaction} hideLabel={true} />
                )}
                { isProcessing && (
                    <ProcessingAlert message={processingLabel} />
                )}
                { submittedDeposit && (
                    <Grid item xs={12} className="centered-grid spaced-top-s">
                        <Typography variant="h5" align="center" color="primary" gutterBottom>
                            { texts?.success || 'Transaction executed successfully' }
                        </Typography>
                        { texts?.notice && (<Alert variant="outlined" severity="info" align="left">
                            { texts.notice }
                          </Alert>
                        )}
                    </Grid>
                )}
                { executeHash && (
                    <TransactionStatusBlock hash={executeHash} />
                )}
            </Grid>
        );
    }
}

const mapState2props = state => ({
    blockchain: state.blockchain,
    blockchainGas: state.blockchainGas,
    dashboard: state.dashboard,
    in4x: state.in4x,
});

const mapDispatch2props = {
    addTransaction,
    changeSendingAddress,
    changeSendingAmount,
    changeBalance,
    changeSendingCurrency,
    changeSendingTrxType,
    getStakingData,
    removePending,
    removePendingListFromBackend,
    addPendingTransactionToBackend,
    checkWalletPassword,
    loginWithWallet,
    getAssets,
    clearTransactionData,
    getExchangeRate,
    changeGasEstimate,
    resetGas
};

export default compose(
    connect(mapState2props, mapDispatch2props)
)(ApproveAndTransactTokens);
