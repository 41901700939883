/**
 * @name performResult
 * @description callback for async action creators
 * @param {object} `res` object returned from server-side
 * @param {function} `cb` function that will be called if request was successful
 * @return any
 * @throws server-side error - if request wasn't successful
 */
export function performResult(res, cb) {
    if (res.success) return cb();
    else throw res.payload;
}

/**
 * @name performDefaultResult
 * @description default callback for async action creators
 * @param {object} `res` object returned from server-side
 * @param {string} `type` action type for successful result
 * @param {function} `dispatch` redux dispatch function
 * @return void
 * @throws server-side error - if request wasn't successful
 */
export function performDefaultResult(res, type, dispatch) {
    if (res.success) {
        dispatch({
            type,
        });
    } else throw res.payload;
}
