import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    Grid,
    TextField,
    IconButton,
} from "@material-ui/core";
import { Settings } from "@material-ui/icons"
import CurrencyAmountField from "src/components/Forms/CurrencyAmountField";
import ProcessingAlert from "src/common/processing-alert";
import OwnStateTextField from "src/common/own-state-text-field";
import { sanitizeInputNumber } from "src/utils/normalize";
import "./styles.scss";

const SwapForm = (props) => {
    const {
        balance = "",
        isSwapActivated = "",
        fromCurrency = "",
        toCurrency = "",
        swapRate = "",
        onAmountsChange = () => {},
        defaultSlippage = 0.5,
        defaultDeadline = 20,
    } = props;
    const defaultAmounts = {
        send: "",
        receive: "",
        deadline: defaultDeadline
    };

    const [swapAmounts, setSwapAmounts] = useState(defaultAmounts);
    const [swapAdvanceVisible, setSwapAdvanceVisible] = useState(false);

    const [slippage, setSlippage] = useState(defaultSlippage);

    const handleAmountChange = (value = "", nexSlippage) => {
        if (!value) {
            setSwapAmounts(defaultAmounts);
            onAmountsChange(defaultAmounts);
        }

        const nextValue = sanitizeInputNumber(value);
        const nextReceive = Number(nextValue) * Number(swapRate);
        const slippageValue = nexSlippage || slippage;
        const nextSlippage = slippageValue / 100 * nextReceive;
        const nextSlippageAmount = nextSlippage > nextReceive ? 0 : nextSlippage;
        const nextAmounts = {
            send: nextValue,
            receive: nextReceive - nextSlippageAmount,
            deadline: swapAmounts?.deadline,
        };

        setSwapAmounts(nextAmounts)
        onAmountsChange(nextAmounts)
    };

    const handleSwapSlippageChange = (value) => {
        handleAmountChange(swapAmounts.send, value);
        setSlippage(value);
    };

    const handleDeadlineChange = (value) => {
        const next = {
            ...swapAmounts,
            deadline: value,
        };
        setSwapAmounts(next);

        if (!swapAmounts.send) return;

        onAmountsChange(next);
    };

    const toggleAdvancedSwapFields = () => {
        setSwapAdvanceVisible(prevState => !prevState)
    };

    const swapFromTicker = fromCurrency.toUpperCase();
    const swapToTicker = toCurrency.toUpperCase();
    const nextDisplayRate = Number(swapRate).toFixed(3);

    useEffect(() => {
        const { send } = swapAmounts;
        if (!swapRate || !send) return;
        handleAmountChange(send);
        // update only on swapRate change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [swapRate]);

    if (!toCurrency || isSwapActivated === "unactivated") return null;

    if (!swapRate) return (<ProcessingAlert message={"Fetching Rates"} />);

    const isInsufficientBalance = Number(balance) < Number(swapAmounts?.send);
    const amountHelperText = isInsufficientBalance ? "Insufficient balance" : "";

    return (
        <Grid item xs={12} className={"spaced-top"}>
            <div className={"swap-rate-block"}>
                <TextField
                    label={"Rate"}
                    variant={"outlined"}
                    value={`1 ${swapFromTicker} = ${nextDisplayRate} ${swapToTicker}`}
                    fullWidth
                    disabled
                />
                <IconButton
                    color={"default"}
                    className={"settings-icon"}
                    onClick={toggleAdvancedSwapFields}
                >
                    <Settings />
                </IconButton>
            </div>
            { swapAdvanceVisible && (
                <div className={"swap-config-fields"}>
                    <OwnStateTextField
                        adornment={"%"}
                        type={"number"}
                        value={slippage}
                        label={"Slippage"}
                        variant={"outlined"}
                        onChange={handleSwapSlippageChange}
                    />
                    <OwnStateTextField
                        adornment={"Min."}
                        type={"number"}
                        value={defaultDeadline}
                        label={"Deadline"}
                        variant={"outlined"}
                        onChange={handleDeadlineChange}
                    />
                </div>
            )}
            <CurrencyAmountField
                label={"Swap Amount"}
                value={swapAmounts?.send}
                onChange={handleAmountChange}
                adornment={swapFromTicker}
                error={isInsufficientBalance}
                helperText={amountHelperText}
            />
            <CurrencyAmountField
                label={"Receive Amount"}
                value={swapAmounts.receive}
                adornment={swapToTicker}
                disabled
            />
        </Grid>
    )
};

SwapForm.propTypes = {
    isSwapActivated: PropTypes.string,
    fromCurrency: PropTypes.string,
    toCurrency: PropTypes.string,
    swapRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onAmountsChange :  PropTypes.func,
    defaultSlippage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultDeadline: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SwapForm;