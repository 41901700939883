import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import ReduxBridgeContext from "./components/new-approach/contexts/redux-context-bridge";
import UserAssetsContextProvider from "./contexts/user-assets";
import {Router} from "react-router-dom";
import history from "./history";
import { initializeStore } from "./ducks";
import ErrorBoundary from "./ErrorBoundary";
import AppWrapper from "./AppWrapper";

import "./assets/styles/index.sass";

window.reduxStore = initializeStore();

ReactDOM.render(
    <Provider store={window.reduxStore}>
        <ErrorBoundary>
            <ReduxBridgeContext>
                <UserAssetsContextProvider>
                    <Router history={history}>
                        <AppWrapper />
                    </Router>
                </UserAssetsContextProvider>
            </ReduxBridgeContext>
        </ErrorBoundary>
    </Provider>,
    document.getElementById("root")
);
