import { getAppEn } from "../utils/config";

export const actwareFetch = async (url, method, body) => {
    if(!window.cordova) return;
    const URI = getAppEn() === "development" ? `http://www.in4actware.com` + url : 'https://www.in4actware.com' + url;
    const headers = {
        'Content-Type': 'application/json'
    };

    if (window.cordova) {
        window.cordova.plugin.http.setDataSerializer('json');

        const request = (url, options) => {
            return new Promise((resolve, reject) =>
                window.cordova.plugin.http.sendRequest(url, options, function (response) {
                    try {
                        return resolve(JSON.parse(response.data));
                    } catch (error) {
                        return reject(error);
                    }
                }, function (response) {
                    if (response.status === 404) return reject('SERVER_CONFIGURATION_FAILED');
                    return resolve(JSON.parse(response.error));
                })
            );
        };
        return request(URI, {
            method,
            headers,
            credentials: 'include',
            data: method !== "GET" ? body : { Test: "Test" },
        });
    } else {
        return fetch(URI, {
            method,
            headers,
            credentials: 'include',
            body: method !== "GET" ? JSON.stringify(body) : undefined,
        }).then(res => {
            // eslint-disable-next-line no-throw-literal
            if (!res.ok && res.status > 500) throw 'SERVER_CONNECTION_FAILED';
            // eslint-disable-next-line no-throw-literal
            if (!res.ok && res.status === 404) throw 'SERVER_CONFIGURATION_FAILED';
            else return res.json();
        });
    }
};
