import React from "react";
import PropTypes from "prop-types";
import UnStakeInformStep from "../inform";
import UnStakeConfirm from "../confirm";
import UnStakeProcess from "../process";

const Stepper = props => {
    const { step = 0 } = props;
    switch (step) {
        case 0:
            return (<UnStakeInformStep {...props} />);
        case 1:
            return (<UnStakeConfirm { ...props } />);
        case 2:
            return (<UnStakeProcess {...props} />);
        default:
            return null;
    }
};

Stepper.propTypes = {
    step: PropTypes.number,
};

export default Stepper;

