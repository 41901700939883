import React from "react";
import PropTypes from "prop-types";
import Modal from "src/components/Modal";
import ModalBody from "./components/ModalBody";
import BlockExplorerUtils from "src/utils/blockchain/BlockExplorerUtils";
import ProviderUtils from "src/utils/blockchain/ProviderUtils";

const TransactionFail = (props) => {
    const {
        isVisible = false,
        onCloseClick = () => {},
        errorText = "",
        transactionHash = "",
    } = props;
    const transactionURl = BlockExplorerUtils.scanUrl(
        transactionHash,
        ProviderUtils.activeBlockchain()
    );

    return (
        <Modal
            open={isVisible}
            onClose={onCloseClick}
            modalBody={
                <ModalBody
                    transactionHash={transactionHash}
                    transactionURl={transactionURl}
                    errorText={errorText}
                    onCloseClick={onCloseClick}
                />
            }
        />
    )
};

TransactionFail.propTypes = {
    isVisible: PropTypes.bool,
    onCloseClick: PropTypes.func,
    errorText: PropTypes.string,
    transactionHash: PropTypes.string,
};

export default TransactionFail;