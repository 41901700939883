import React from "react"
import { compose } from "redux";
import { connect } from "react-redux";
import { getFormValues, destroy } from "redux-form";

import Container from '@material-ui/core/Container';
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import BuyTokensForm from '../../../components/Forms/BuyTokensForm';

import { ROUTE_BUY_TOKEN, ROUTE_WALLET_ASSETS, ROUTE_BUY_TOKEN_STATUS, ASSET_TYPE_LIQUID } from "../../../constants/routes";
import { IN4X_METHOD_CARD } from '../../../constants/in4xConstants';
import { getFeeSchedule, getExchangeRate, createCardExchangeRequest } from "../../../ducks/in4x";
import { FORM_BUY_TOKENS } from '../../../ducks/FormData';
import { getError } from "../../../utils/transformErrors";
import BlockchainLabel from "../../../common/blockchain-label";

class BuyTokens extends React.Component {

	constructor(props) {
  	super(props);

		const url = props.history.location.pathname.substring(props.history.location.pathname.indexOf(ROUTE_BUY_TOKEN) + ROUTE_BUY_TOKEN.length).split("/");
		const urlBlockchain = url[0];
		const urlCurrency = url[1];
		this.state = {
			catchErr: null,
			bc: urlBlockchain?.length > 0? urlBlockchain.toUpperCase() : '',
			cur: urlCurrency?.length > 0? urlCurrency.toUpperCase() : '',
			inAppBrowserRef: null,
			isRedirecting: false,
			isWaiting: true
		}
 	}

	componentWillMount = async() => {
		if(this.state.cur) {
			this.props.getFeeSchedule();
			await this.props.getExchangeRate('USD', this.state.cur);
			if(this.props.in4x.error) {
				this.setState({catchErr: getError(this.props.in4x.error)});
			}
		}
	}

	componentWillUnmount = () => {
		destroy(FORM_BUY_TOKENS);
	}

	onBuySubmit = async() => {
		await this.props.createCardExchangeRequest(this.props.formValues, 'USD', this.state.cur);
		if(this.props.in4x.error) {
			this.setState({catchErr: getError(this.props.in4x.error)});
		}
		const trxReq = this.props.in4x.trxReq;

		if(trxReq && trxReq.url) {
			this.redirectToCheckout(trxReq.url);
			this.props.history.push(ROUTE_BUY_TOKEN_STATUS + trxReq.transaction_id);
		}
	}

	onBuyCancel = async() => {
		this.props.history.push(ROUTE_WALLET_ASSETS + ASSET_TYPE_LIQUID);
	}

	redirectToCheckout(url) {		
		const target = "_system";
        const options = "location=yes,hidden=yes,enableViewportScale=yes,closebuttoncaption=Done,zoom=no,clearcache=no,clearsessioncache=no,cleardata=no";
        let inAppBrowserRef;
        if(window.cordova && window.cordova.InAppBrowser) {
        	inAppBrowserRef = window.cordova.InAppBrowser.open(url, target, options);
        	inAppBrowserRef.addEventListener('exit', () => {
        		this.updateState('Payment cancelled', false);
        	});   	
        	inAppBrowserRef.addEventListener('loaderror', (e) => {
	            this.updateState('Error loading checkout page', false);
	        });

        	inAppBrowserRef.addEventListener('loadstop', (event) => {        		
        		inAppBrowserRef.show();
        		if(!event || !event.url) {
        			return;
        		}        		
	        });
         	this.setState({inAppBrowserRef: inAppBrowserRef, isRedirecting: true});          
        } else if(process.env.NODE_ENV === 'development') {        	
        	window.open(url);
        } else {
        	this.updateState('Payment cancelled', false);
        }
	}

	updateState = async(message, isRedirecting) => {
		this.setState({catchErr: getError(message), isRedirecting: isRedirecting});
	}

	render() {
    	const { cur, catchErr, isRedirecting } = this.state;
    	const { exRate, in4xRateLoading, in4xExchangeLoading, isWaiting, in4xFees, in4xLimits, in4xFeesLoading } = this.props.in4x;
    	const payCur = 'USD';
	    return (
	        <Container component="main" className="" maxWidth="sm">
	        	<Grid container justifyContent="center" alignItems="center" className="centered-grid">
					<Grid item xs={12} className="spaced-top-s">
						<BlockchainLabel/>
						<Typography variant="h5" align="center" gutterBottom className="color-nx">Buy {cur.toUpperCase()}</Typography>
					</Grid>
	        		{ !isWaiting && !isRedirecting && !in4xExchangeLoading && !in4xFeesLoading && !catchErr && (
	        			<BuyTokensForm 
	        				base={payCur}
	        				quote={cur}
							rate={exRate.low}
							isLoading={in4xRateLoading}
	        				limits={in4xLimits}
	        				fees={in4xFees}
	        				method={IN4X_METHOD_CARD}
	        				handleSubmit={this.onBuySubmit} handleCancel={this.onBuyCancel}
	        			/>
	        		)}
	        		{ !isRedirecting && in4xExchangeLoading && !in4xFeesLoading && !catchErr && (
	        			<Grid item xs={12} className="centered-grid">
					    	<CircularProgress />
					    	<Typography color="primary" gutterBottom>Processing your request...</Typography>
				    	</Grid>
	        		)}
	        		{ isRedirecting && !catchErr && (
	        			<Grid item xs={12} className="centered-grid">
					    	<CircularProgress />
					    	<Typography color="primary" gutterBottom>Proceeding to checkout page...</Typography>
				    	</Grid>
	        		)}
	        		{ isWaiting && !catchErr && (
	        			<Grid item xs={12} className="centered-grid">
						    	<CircularProgress />
						    	<Typography color="primary" gutterBottom>Waiting for payment...</Typography>
					    	</Grid>
	        		)}
	        		{ catchErr && (<Alert variant="outlined" severity="error" onClose={() => { this.setState({catchErr: null}); }}>{this.state.catchErr}</Alert>)}
	        	</Grid>
        	</Container>
	    )
	}
}

const mapState2props = state => ({
	in4x: state.in4x,
  	formValues: getFormValues(FORM_BUY_TOKENS)(state),
});

const mapDispatch2props = {
	getFeeSchedule,
  	getExchangeRate,
  	createCardExchangeRequest
};

export default compose(
  connect(mapState2props, mapDispatch2props)
)(BuyTokens);