import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import moment from 'moment';

import AssetLoaderProgress from '../../components/AssetLoaderProgress';
import TransactionStatusBlock from '../../components/TransactionStatusBlock';

import { Container, Grid, Typography, CircularProgress, Button, Paper } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';

import { 
	ROUTE_NX_TRANSACTION_HISTORY,
} from "../../constants/routes";
import { 
	getIn4xWireTransfer
} from "../../ducks/in4x";

// Component: Should be a simple view which loads this
class In4xWiretransferTransaction extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount = async() => {
  	const locSplit = this.props.history.location.pathname.split('/');
  	if(locSplit.length >= 4) {
  		const ref = locSplit[3];
  		this.setState({ref: ref});
  		await this.props.getIn4xWireTransfer(ref);
  	}
  }

  goBack = () => {
  	this.props.history.push(ROUTE_NX_TRANSACTION_HISTORY + 'transfers');
  };


  render() {
  	const { userAssetTypesLoading, assetBalancesLoading } = this.props.dashboard;
  	const { transferInfo, in4xTransferLoading, in4xTransferLoaded, error } = this.props.in4x;
		return (
			<Container>
				<Grid item xs={12} className="centered-grid spaced-top">
					<Typography align="center" className="color-nx" variant="h6">IN4X Wire Transfer</Typography>
					{ in4xTransferLoading &&  (
						<Grid item xs={12} className="centered-grid">	         		
	         		<CircularProgress />
	         		<Typography className="color-nx" align="center" gutterBottom>Loading Transaction...</Typography>
	         	</Grid>
					)}
					{ !in4xTransferLoading && (userAssetTypesLoading || assetBalancesLoading) && (
						<AssetLoaderProgress />
					)}

					{ !in4xTransferLoading && in4xTransferLoaded && transferInfo && (
						<Accordion TransitionProps={{ unmountOnExit: true }} defaultExpanded>
			        <AccordionSummary
			          expandIcon={<ExpandMoreIcon />}
			          aria-controls="panel1a-content"
			          id="panel1a-header"
			        >
			        	<Typography className="color-nx">Transfer Summary</Typography>
			        </AccordionSummary>
      				<AccordionDetails style={{padding: '0'}}>
			    			<TableContainer component={Paper}>
						      <Table size="small" aria-label="a dense table">
						        <TableBody>
						        		<TableRow key="td">
						              <TableCell component="td" scope="row">IN4X Reference</TableCell>
						              <TableCell align="right"><Typography fontSize="2" className="color-nx" style={{wordBreak: "break-all"}}>{transferInfo.REFERENCE_ID}</Typography></TableCell>
						            </TableRow>
						            <TableRow key="pl">
						              <TableCell component="td" scope="row">Bank Reference</TableCell>
						              <TableCell align="right"><Typography fontSize="2" className="color-nx" style={{wordBreak: "break-all"}}>{transferInfo.BANK_REFERENCE.toUpperCase()}</Typography></TableCell>
						            </TableRow>
						            <TableRow key="dir">
						              <TableCell component="td" scope="row">Direction</TableCell>
						              <TableCell align="right"><Typography fontSize="2" className="color-nx" style={{wordBreak: "break-all"}}>{transferInfo.DIRECTION === 'IN'? 'Deposit': 'Withdraw'}</Typography></TableCell>
						            </TableRow>
						            <TableRow key="am">
						              <TableCell component="td" scope="row">Amount</TableCell>
						              <TableCell align="right"><Typography fontSize="2" className="color-nx">{'USD ' + transferInfo.AMOUNT}</Typography></TableCell>
						            </TableRow>
						            { transferInfo.TOPUP_CUR && transferInfo.AMOUNT_TOKEN > 0 && (
						            	<TableRow key="at">
						              	<TableCell component="td" scope="row">Topup Amount</TableCell>
						              	<TableCell align="right"><Typography fontSize="2" className="color-nx">{transferInfo.TOPUP_CUR.toUpperCase() + ' ' + transferInfo.AMOUNT_TOKEN}</Typography></TableCell>
						            	</TableRow>
						            )}
						            { transferInfo.NX_FEE && transferInfo.NX_FEE > 0 && (<TableRow key="pf">
   						              <TableCell component="th" scope="row">Fee</TableCell>
   						              <TableCell align="right"><Typography fontSize="2" className="color-nx">{'USD ' + transferInfo.NX_FEE}</Typography></TableCell>
    						        </TableRow>
    						        )}
						            <TableRow key="ps">
						              <TableCell component="td" scope="row">Status</TableCell>
						              <TableCell align="right"><Typography fontSize="2" className="color-nx">{transferInfo.STATUS.toUpperCase()}</Typography></TableCell>
						            </TableRow>
						            <TableRow key="ts">
						              <TableCell component="td" scope="row">Time</TableCell>
						              <TableCell align="right"><Typography fontSize="2" className="color-nx">{transferInfo.CREATED_AT ? moment.unix(transferInfo.CREATED_AT).format("hh:mm A DD-MM-YYYY") : '-'}</Typography></TableCell>
						            </TableRow>
						        </TableBody>
						      </Table>
						      { transferInfo.TRANSACTION_HASH && transferInfo.TRANSACTION_HASH.length > 0 && (
			            	<TransactionStatusBlock hash={transferInfo.TRANSACTION_HASH}/>
			            )}
						    </TableContainer>
					    </AccordionDetails>
						</Accordion>
					)}
					{ !in4xTransferLoading && !in4xTransferLoaded && error && (
						<Grid item xs={12} align="center" className="centered-grid">
							<Alert variant="outlined" severity="error">Invalid Wire Transfer Request/link</Alert>
							<Typography align="center" className="spaced-top">
								The link that took you here appears to be invalid! Please verify that the link is correct, and that it is still valid.
							</Typography>
						</Grid>
					)}
					<Grid item xs={12} className="centered-grid spaced-top">
		      	<Button onClick={this.goBack} fullWidth variant="contained" color="default" >Back</Button>
		      </Grid>
				</Grid>
			</Container>
		);
	}
}

const mapState2props = state => ({
  in4x: state.in4x,
  dashboard: state.dashboard
});

const mapDispatch2props = {
	getIn4xWireTransfer
};

export default compose(
  connect(mapState2props, mapDispatch2props)
)(In4xWiretransferTransaction);