import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change, getFormValues } from 'redux-form'

import { Button, Typography } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';

import NXCustomInput from '../NXField';
import USDField from '../USDField';
import AmountField from "../AmountField";
import ExchangeCurrencyField from "../ExchangeCurrencyField";

import { IN4X_ACTION_WITHDRAW } from '../../../constants/in4xConstants';
import { feeForAmount, minLimit, maxLimit } from '../../../utils/in4xUtils';
import { required, minLength3 } from "../../../utils/validation";

import {
	getSellTokensValues
} from '../../../ducks/FormData';

class SellTokensForm extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
		    catchErr: null,
		    loc: '',
		    minAmount: 0,
		    maxAmount: 0
		};
	}

	componentDidMount = () => {
		const { limits, method, quote } = this.props;
		if(limits && Object.keys(limits).length > 0 && method && this.state.maxAmount === 0) {
			this.setState({
				minAmount: minLimit(limits, IN4X_ACTION_WITHDRAW, method, quote), 
				maxAmount: maxLimit(limits, IN4X_ACTION_WITHDRAW, method, quote)
			});
		}
	}

	changedAmount = (amount) => {
		const { fees, method, quote, rate } = this.props;
		amount = Number(amount);
		if(amount <= 0) {
			this.props.dispatch(change('sell-tokens', 'fee', 0));
			this.props.dispatch(change('sell-tokens', 'total', 0));
			return;
		}
		const amountToken = rate > 0 ? (amount * Number(rate)).toFixed(4) : 0;
		if(fees && method) {
			let feeAmount = feeForAmount(fees, IN4X_ACTION_WITHDRAW, method, quote, amount);
			feeAmount = rate > 0 ? (feeAmount * rate).toFixed(4) : feeAmount;
			if(feeAmount >= 0) {
				this.props.dispatch(change('sell-tokens', 'fee', feeAmount));
				this.props.dispatch(change('sell-tokens', 'total', (amount + Number(feeAmount))));
			}
		} else {
			this.props.dispatch(change('sell-tokens', 'fee', 0));
			this.props.dispatch(change('sell-tokens', 'total', (amountToken)));
		}
		this.props.dispatch(change('sell-tokens', 'receiveAmount', amountToken));
	}

	render() {
    	const { catchErr, minAmount, maxAmount } = this.state;
    	if (catchErr) throw catchErr;
    	const { handleSubmit, handleCancel, blockchain, quote, rate, isLoading, showLocation, hideButtons, invalid } = this.props;

			let { formValues } = this.props;
			if(!formValues) formValues = {};

    	return (
    		<>
				<form name="sell-tokens" onSubmit={handleSubmit}>
						{ blockchain && (
                <Typography variant="subtitle2" align="center" gutterBottom className={"network-badge bg-"+blockchain} style={{marginBottom: "1rem"}}>
                    Blockchain { blockchain.toUpperCase() }
                </Typography>
            )}
				    <Field
						name="payCur"
						component={ExchangeCurrencyField}
						type="text"
						disabled
						label="Pay"
						className="override-nx"
					/>
					<Field
						name="recCur"
						component={ExchangeCurrencyField}
						type="text"
						disabled
						label="Receive"
						className="override-nx"
					/>
					<AmountField
						minValue={minAmount}
						maxValue={maxAmount}
						onChange={this.changedAmount}
						component={NXCustomInput}
					/>
				    { isLoading && (
				    	<>
					    	<CircularProgress />
					    	<Typography color="primary" gutterBottom>Loading rate...</Typography>
				    	</>
				   	)}
				   	{ !isLoading && rate && (
				   		<Field
					    	type="text" 
					    	name="receiveAmount"
					    	label="Receive"
					    	component={USDField} 
					    	InputProps={{
                    startAdornment: <InputAdornment position="start">{quote.toUpperCase()}</InputAdornment>
                }}
					    	disabled
					    	fullWidth
					    	variant="outlined"
					    	className="override-nx spaced-top-s"
					    />
				   	)}
				   	{ !isLoading && formValues.fee > 0 && (
				   		<Field
				   			className="override-nx spaced-top-s"
				   			component={NXCustomInput} 
				   			variant="outlined"
					    	type="text"
					    	name="fee"
					    	label="Fee" 
					    	disabled
					    	fullWidth
					    />
				   	)}
				   	{ !isLoading && formValues.total > 0 && (
				   		<Field
				   			className="override-nx spaced-top-s"
				   			component={NXCustomInput} 
					    	type="text" 
					    	variant="outlined"
					    	name="total"
					    	label="Total (To be Transferred)" 
					    	disabled
					    	fullWidth
					    />
				   	)}
				   	{ !isLoading && showLocation && (
				   		<Field name="location"
				   			className="override-nx"
			                component={NXCustomInput}
			                type="text"
			                label="Location (City and Country)"
			                validate={[required, minLength3]}
			                required
			                autoComplete={"city"}
			            />
				   	)}
				    { !hideButtons && (
				    	<>
				    	<Button className="bg-nx spaced-top" variant="contained" fullWidth onClick={handleSubmit} disabled={isLoading || formValues.receiveAmount <= 0 || invalid}>Submit</Button>
				    	<Button variant="contained" fullWidth onClick={handleCancel} className="spaced-top">Cancel</Button>
				    	</>
				    )}
				</form>
			</>
		)
	}
}

SellTokensForm = reduxForm({
    form: 'sell-tokens',
    enableReinitialize: true,
    destroyOnUnmount: true
})(SellTokensForm);

SellTokensForm = connect(
  state => ({
    initialValues: state.FormData.SellTokens,
    formValues: getFormValues('sell-tokens')(state), //linked here to hide fee + total if they're empty
  }),
  { load: getSellTokensValues }               // bind FormData values
)(SellTokensForm);

export default SellTokensForm;