import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import { Link } from "react-router-dom";

import { Container, Grid, Typography, CircularProgress, Button } from '@material-ui/core';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import Alert from '@material-ui/lab/Alert';

import SAMobileStepper from "../../components/SAMobileStepper";
import PaymentForm from '../../components/Forms/PaymentForm';
import AssetLoaderProgress from '../../components/AssetLoaderProgress';
import SendTokens from "../../components/SendTokens";
import TransactionStatusBlock from "../../components/TransactionStatusBlock";
import TokenAssetBalance from "../../components/Balances/TokenAssetBalance";
import In4xPaymentSummary from "../../components/In4xPaymentSummary";

import { 
	ROUTE_DEFAULT_LOGGEDIN, 
	ROUTE_NX_ACTIVATE,
	ROUTE_NX_TRANSACTION_HISTORY,
	ROUTE_E_WALLET_METHODS,
	ROUTE_NX_PAYMENT_BY_ADDRESS
} from "../../constants/routes";
import {
	BLOCKCHAIN_BSC,
	NETWORK_MAINNET,
	NETWORK_TESTNET
} from "../../constants/blockchain";
import {
	CURRENCY_IOWN,
	CURRENCY_USDT,
	CURRENCY_USDC,
	CURRENCY_BUSD
} from "../../constants/currencies";
import { 
	getIn4xPaymentInfo, 
	getExchangeRate,
	updatePaymentInfo,
	getFeeSchedule
} from "../../ducks/in4x";
import { 
	changeSendingCurrency,
	changeSendingAmount,
  changeSendingAddress 
} from "../../ducks/blockchain";
import ProviderUtils from '../../utils/blockchain/ProviderUtils';
import { setMakePaymentValues } from '../../ducks/FormData';

// Componenet: Should be a simple view which loads this
class NXPaymentRequest extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    	catchErr: null,
    	ref: false,
    	activeStep: 0,
    	showLogo: false,
    	cur: null,
    	payTo: '',
    	fee: 0,
    	countEligible: false,
    	isProcessed: false,
    	balanceError: false,
    	blockchain: null,
    	network: null,
		propsIn4xError: false,
    };
  }

  componentDidMount = async() => {
  	const locSplit = this.props.history.location.pathname.split('/');
  	const type = this.props.history.location.pathname.indexOf(ROUTE_NX_PAYMENT_BY_ADDRESS) !== -1? 'address':'reference';
  	if(locSplit.length >= 4) {
  		const ref = locSplit[3];
  		this.setState({ref: ref});
  		this.props.getFeeSchedule();
  		await this.props.getIn4xPaymentInfo(ref, type);

  		if(this.props.in4x.in4xPayment && Object.keys(this.props.in4x.in4xPayment).length !== 0 && this.props.in4x.in4xPayment.status.toLowerCase() !== 'draft') {
  			const network = this.props.in4x.in4xPayment.blockchainNetwork || NETWORK_MAINNET;
  			if(this.checkMismatchingNetworks(network)) {
  				this.setState({catchErr: 'You are on a network that doesn\'t match this payment'}); //TODO User error message converter
  				return;
  			}
  			this.setState({
  				ref: this.props.in4x.in4xPayment.referenceId, //ensure we're not using the wallet address from URL as ref
  				payTo: this.props.in4x.in4xPayment.receiveWalletAddress,
  				cur: this.props.in4x.in4xPayment.payCurrency, 
  				blockchain: this.props.in4x.in4xPayment.blockchain || BLOCKCHAIN_BSC,
  				network,
  				showLogo: !!this.props.in4x.in4xPayment.partnerLogo
  			});
  			if(this.props.in4x.in4xPayment.status.toLowerCase() !== 'in progress') {
  				this.setState({isProcessed: true, activeStep: 4, showLogo: false});
  			}
  		}
  		
  	}
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
	  //No prev check because appUtils balance loading is wrong and should be in ducks/blockchain or ducks/dashbaord
	  if(this?.props?.in4x?.error && !this.state.propsIn4xError) {
		  this.setState({propsIn4xError: true});
	  }
	  if(this.state.propsIn4xError && Object.keys(this.props.in4x.in4xPayment).length > 0) {
		  this.setState({propsIn4xError: false});
	  }
	  if(prevProps.dashboard.assetBalancesLoaded && prevProps.in4x.in4xPaymentLoaded) {
		  this.checkEligibleCurrencies();
	  }
  }

  checkMismatchingNetworks(paymentNetwork) {
  	const activeNetwork = ProviderUtils.activeNetwork();
  	if(paymentNetwork === activeNetwork) {
  		return false;
  	}
  	if(paymentNetwork === NETWORK_TESTNET && activeNetwork !== NETWORK_MAINNET) {
  		return false;
  	}
  	return true; //mismatch
  }

  nextStep = () => {
  	if(this.state.showLogo) {
  		this.setState({showLogo: false});
  		return;
  	}
		switch(this.state.activeStep) {
			case 0: 
				this.checkEligibleCurrencies(true);
				break;
			case 2:
				this.goToSendMoney();
				break;
			case 4:
				this.props.history.push(ROUTE_NX_TRANSACTION_HISTORY);
				break;
			default:
				this.setState((state) => ({ activeStep: state.activeStep + 1 }));
		}
  };

  prevStep = () => {
  	if(this.state.isProcessed) {
  		this.props.history.push(ROUTE_NX_TRANSACTION_HISTORY + 'payments');
  		return;
  	}
  	switch(this.state.activeStep) {
  		case 0:
  			this.props.history.push(ROUTE_DEFAULT_LOGGEDIN);
  			break;
  		case 2:
  			const { in4x: { in4xPayment: { payCurrency }}} = this.props;
  			this.setState((state) => ({ activeStep: payCurrency? state.activeStep - 2 : state.activeStep - 1 }));
  			break;
  		case 4:
  			this.props.history.push(ROUTE_NX_TRANSACTION_HISTORY + 'payments');
  			break;
  		default:
  			this.setState((state) => ({ activeStep: state.activeStep - 1 }));
  	}
  };

  checkEligibleCurrencies(toNext) {
  	const { activeStep, cur, isCurrencyPreselected, balanceError } = this.state;
  	if(this.state.countEligible !== false) {
  		if(toNext && cur && !balanceError) {
  			this.setFormValues();
  			this.setState((state) => ({ activeStep: state.activeStep + 2 }));
  		}
 			return;
 		}
 		const { balance } = this.props.blockchain;
 		const { blockchain } = this.state;
 		if(activeStep === 0 && this.props.dashboard.assetBalancesLoaded && !isCurrencyPreselected) {
 			let countEligible = 0;
 			this.props.dashboard.userAssetTypes.forEach((v, idx) => {
 				if(!balance[blockchain] || balance[blockchain][v.name] === '?') {
 					countEligible = false;	//invalid balance
 					return false;
 				}
 				if(this.isAllowedCurrency(v, blockchain) && balance[blockchain][v.name] && balance[blockchain][v.name] !== 0 && balance[blockchain][v.name] !== '0.0' && balance[blockchain][v.name] !== '?') {
 					countEligible++;
 				}
 				if(cur && v.ticker.toLowerCase() === cur.toLowerCase() && v.blockchain === blockchain) {
 					this.selectCurrency(v);
 					this.setState({isCurrencyPreselected: true});
 				}
 			});
 			if(countEligible !== false) {
				this.setState({countEligible: countEligible});
 			}
 		}
  }

  selectCurrency = async(cur) => {
  	this.setState({ cur: cur.ticker });
  	this.props.changeSendingCurrency(cur.name, cur);
		await this.props.getExchangeRate(this.props.in4x.in4xPayment.orderCurrency, cur.ticker.toLowerCase());
		if(this.props.in4x.error) {
			this.setState({catchErr: this.props.in4x.error});
		}
		this.setFormValues();
  };

  setFormValues = () => {
  	const { in4xPayment, exRate } = this.props.in4x; //in4xFees
  	const adjusted = (in4xPayment && exRate && exRate.high > 0? (Number(in4xPayment.payAmount) - Number(in4xPayment.receivedSofar) / Number(exRate.high)).toFixed(4): 0);
		const fee = this.props.in4x.in4xPayment.nxFee;
		const total = (Number(adjusted) + Number(fee));
  	this.props.setMakePaymentValues({
  		amount: adjusted,
  		fee: fee,
  		total: total,
  		partner: in4xPayment.partnerId
  	});
  	const { balance, sendingCurrency } = this.props.blockchain;
  	if(balance[sendingCurrency] !== '?' && exRate.high && balance[sendingCurrency] < Number(exRate.high) * Number(total)) {
  		this.setState({balanceError: true});
  	}
  	else if(this.state.activeStep > 0 && this.state.activeStep < 4) {
  		this.setState((state) => ({ activeStep: state.activeStep + 1, balanceError: false }));
  	}
  }

  transactionSuccess = async(trxHash) => {
  	if(trxHash) {
  		// this is no longer meaningful, needs to be updated on in4x backend
  		this.setState((state) => ({ activeStep: state.activeStep + 1 }));
  	}
  }

  transactionFail = () => {
  	this.setState({catchErr: 'Failed to submit blockchain transaction for payment'});
  }

  onCancel = () => {
  	this.props.history.push(ROUTE_DEFAULT_LOGGEDIN);
  };

  goToSendMoney = () => {
  	const amount = this.props.formValues.total;
  	this.props.changeSendingAmount(amount);
  	this.props.changeSendingAddress(this.state.payTo);
  	this.setState(state => ({ activeStep: state.activeStep + 1, finalAmount: amount }));
  };

  isAllowedCurrency = (cur, blockchain) => {
  	const c = cur.name? cur.name : cur;
  	if(cur.blockchain !== blockchain) {
  		return false;
  	}
  	const { balance } = this.props.blockchain;
  	switch(c) {
  		case CURRENCY_IOWN:
  		case CURRENCY_USDT:
  		case CURRENCY_USDC:
  		case CURRENCY_BUSD:
  			return (balance[blockchain] && balance[blockchain][c] > 0); 
  		default: 
  			return false;
  	}
  };

  render() {
  	const { activeStep, showLogo, catchErr, insufficientBalance, countEligible, cur, balanceError, blockchain, propsIn4xError } = this.state;
  	const { in4xPayment, in4xPaymentLoading, in4xPaymentLoaded } = this.props.in4x;
  	const { exRate, in4xRateLoading, in4xExchangeLoading } = this.props.in4x;
  	const { balance, sendingCurrency } = this.props.blockchain;
  	const { userAssetTypes, userAssetTypesLoading, userAssetTypesLoaded, assetBalancesLoading, assetBalancesLoaded, kycLevel } = this.props.dashboard;

  	const topupRouteLink = '/#' + ROUTE_E_WALLET_METHODS + 'topup/any';
  	const disabledNext = (activeStep === 1) || !!balanceError || in4xExchangeLoading || !!catchErr || !in4xPayment || activeStep === 3;
  	const hasEligible = countEligible > 0;

		return (
			<Container>
				<Grid item xs={12} align="center" className="spaced-top">
					{ !showLogo && !in4xPaymentLoading && (
						<Grid item xs={12} >
							{ !!in4xPayment.partnerLogo && (
								<img src={in4xPayment.partnerLogo} alt={in4xPayment.partnerName} style={{maxWidth:"200px", marginBottom: "0.5rem"}} />
							)}
						</Grid>
						)
					}
					{ catchErr && (<Alert variant="outlined" severity="error" onClose={() => { this.setState({catchErr: null}); }}>{this.state.catchErr}</Alert>)}
					{ activeStep === 0 && in4xPaymentLoading &&  (
						<Grid item xs={12} className="centered-grid nx-payment-loader">	         		
	         		<CircularProgress />
	         		<Typography className="color-nx" align="center" gutterBottom>Loading ...</Typography>
	         	</Grid>
					)}
					{ activeStep === 0 && !in4xPaymentLoading && (userAssetTypesLoading || assetBalancesLoading) && (
	         	<AssetLoaderProgress />
	        )}

	        { activeStep === 0 && !in4xPaymentLoading && showLogo && (
	        		<Grid item xs={12} className="centered-grid nx-partner-logo">
	        			<div style={{width: '100%'}}><img src={in4xPayment.partnerLogo} alt={in4xPayment.partnerName} /></div>
	        			<Button className="bg-nx spaced-top" variant="contained" onClick={this.nextStep}>Continue to payment <PlayCircleFilledIcon /></Button>
	        		</Grid>
	        )}

					{ ( activeStep < 1 || activeStep > 3 ) && !in4xPaymentLoading && in4xPaymentLoaded && in4xPayment && !showLogo && (
						<In4xPaymentSummary in4xPayment={in4xPayment} />
					)}
					{ !in4xPaymentLoading && !in4xPaymentLoaded && propsIn4xError && (
						<Grid item xs={12} className="centered-grid">
							<Alert variant="outlined" severity="error">Invalid Payment Request/link</Alert>
							<Typography align="center" className="spaced-top">
								The link that took you here appears to be invalid! Please verify that the link is correct, and that it is still valid.
							</Typography>
						</Grid>
					)}
					{ activeStep === 1 && userAssetTypesLoaded && assetBalancesLoaded && in4xPaymentLoaded && !showLogo && (
	         		<Grid item xs={12} className="centered-grid">
		         		<Typography className="color-nx" variant="subtitle2" align="center" gutterBottom>Choose your payment method</Typography>
		         		{ !hasEligible && !showLogo && (
									<Grid item xs={12} className="centered-grid">
										<Alert severity="warning" style={{marginBottom: "2em"}}>No Eligible Balance!</Alert>
	            			<Link to={ROUTE_E_WALLET_METHODS + 'topup/any'}>
	            				<Button variant="contained" className="bg-nx" fullWidth>Top Up</Button>
	            			</Link>
	            		</Grid>
								)}
								{ balanceError && !showLogo && (
									<Grid item xs={12} className="centered-grid">
										<Alert severity="warning" style={{marginBottom: "2em"}}>Balance is insufficient!
		            			<Link to={ROUTE_E_WALLET_METHODS + 'topup/' + sendingCurrency}>
		            				<Button className="color-nx btn-right-float">Top Up</Button>
		            			</Link>
	            			</Alert>
	            		</Grid>
								)}
		         		{ userAssetTypes && userAssetTypes.map((CUR, idx) => (
		         			( this.isAllowedCurrency(CUR, blockchain) && (<TokenAssetBalance key={"cur-"+CUR.name} CUR={CUR} balance={balance[blockchain][CUR.name]} onToggle={this.selectCurrency} /> ))
		         		))}
							</Grid>
	         )}
	         { activeStep === 2 && (
	         		<>
		        		{ !in4xExchangeLoading && !catchErr && in4xPayment && (
		        			<Grid item xs={12} className="centered-grid">
		        				<Typography className="color-nx" variant="subtitle2" align="center" gutterBottom>Transaction Summary</Typography>
		        				<Grid item xs={12} className="centered-grid">
				        			<PaymentForm 
				        				base={this.state.cur} 
				        				quote={in4xPayment.orderCurrency.toUpperCase()} 
				        				rate={exRate.high > 0? (Number(exRate.high)).toFixed(4): 0} 
				        				isLoading={in4xRateLoading} 
				        				handleSubmit={this.nextStep} 
				        				handleCancel={this.onCancel}
				        				targetLabel={in4xPayment.orderDescription}
				        				requestId={in4xPayment.referenceId}
				        				targetId={in4xPayment.merchantOrderId}
				        				balance={balance[cur.toLowerCase()]}
				        				hideButtons
				        			/>
			        			</Grid>
		        			</Grid>
		        		)}
		        		{ in4xExchangeLoading && !catchErr && (
		        			<Grid item xs={12} className="centered-grid">
							    	<CircularProgress />
							    	<Typography className="color-nx" gutterBottom>Processing your request...</Typography>
						    	</Grid>
		        		)}
		        		{ insufficientBalance && (
		        			<>
		        				<Alert variant="outlined" severity="error" onClose={() => { this.setState({catchErr: null}); }}>Insufficient Balance for this Payment</Alert>
		        				<Typography align="center" gutterBottom>You don't have enough balance to proceed with this payment.</Typography>
		        				{ kycLevel === false && (
		        					<Link href={'#'+ROUTE_NX_ACTIVATE}>
		        						<Button variant="contained" color="primary" fullWidth>Active Your IN4X Account</Button>
		        					</Link>
		        				)}
		        				{ kycLevel >= 1 && (
		        					<Link href={topupRouteLink}>
		        						<Button variant="contained" color="primary" fullWidth>Topup Your Account</Button>
		        					</Link>
		        				)}
		        			</>
		        		)}
	        		</>
	        	)}
	        	{ activeStep === 3 && (
	        		<Grid item xs={12} className="centered-grid forced-nx">
	        			<Typography className="color-nx" align="center" gutterBottom>Submit Payment Transaction</Typography>
	        			<SendTokens hideBack disabled
	        				onTransactionSuccess={(trxHash) => { this.transactionSuccess (trxHash); }} 
	        				onTransactionFail={this.transactionFail} 
	        				onTransactionBack={this.prevStep} />	        			
	        		</Grid>
	        	)}
	        	{ activeStep === 4 && in4xPayment.TRANSACTION_HASH && in4xPayment.TRANSACTION_HASH.length > 0 && (
							<TransactionStatusBlock hash={in4xPayment.TRANSACTION_HASH}/>	
						)}
				</Grid>
				<SAMobileStepper maxSteps={5} activeStep={activeStep} onNext={this.nextStep} onPrev={this.prevStep} disabledNext={disabledNext} />
			</Container>
		);
	}
}

const mapState2props = state => ({
  dashboard: state.dashboard,
  blockchain: state.blockchain,
  in4x: state.in4x,
  formValues: getFormValues('payment-form')(state), //disabled form, always valid
});

const mapDispatch2props = {
	getIn4xPaymentInfo,
  getExchangeRate,
  updatePaymentInfo,
  changeSendingCurrency,
  changeSendingAmount,
  changeSendingAddress,
  getFeeSchedule,
  setMakePaymentValues
};

export default compose(
  connect(mapState2props, mapDispatch2props)
)(NXPaymentRequest);