import React from "react";

import { Grid, Button, Typography } from "@material-ui/core";
import Modal from "../../components/Modal";
import PriorityHigh from '@material-ui/icons/PriorityHigh';

const MnemonicImportanceModal = (props) => {
  return (
    <Modal
      open={props.openOn}
      onClose={props.onClose}
      modalBody={(
          <Grid container spacing={3}>
              <Grid item xs={12}>
                  <Typography
                      variant={'h6'}
                      color={'error'}
                      gutterBottom
                      style={{display: 'flex', alignItems: 'center'}}
                  >
                      <PriorityHigh color="error"/>&nbsp;Important:
                  </Typography>

                  <Typography gutterBottom>
                      Make sure you don't forget this password! You
                      will have to re-create your wallet from your 12 key secret phrase.
                  </Typography>
              </Grid>
              <Grid item xs={12}>
                  <Grid container spacing={3}>
                      <Grid item xs={6}>
                          <Button
                              fullWidth
                              variant="contained"
                              onClick={props.onClose}
                          >
                              Close
                          </Button>
                      </Grid>
                      <Grid item xs={6}>
                          <Button
                              fullWidth
                              variant="contained"
                              color="primary"
                              onClick={props.onConfirm}
                          >
                              Confirm
                          </Button>
                      </Grid>
                  </Grid>
              </Grid>
          </Grid>
      )}
    ><>{""}</></Modal>
  );
}

export default MnemonicImportanceModal;