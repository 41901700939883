import React from 'react'
import { Field, reduxForm } from 'redux-form'
import CustomInput from '../Field';
import {required} from "../../../utils/validation";

const SignInOldForm = props => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Field
            name="email"
            component={CustomInput}
            type="email"
            label="Email"
            required
            autoComplete={"email"}
            className="hidden-input"
        />
        <Field
            name="password"
            component={CustomInput}
            type="password"
            validate={[required]}
            label="Type your password"
            required
            autoComplete={"current-password"}
        />
    </form>
  )
}

export default reduxForm({
  form: 'signin-old'
})(SignInOldForm)