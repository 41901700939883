import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import sha512 from "js-sha512";
import { withStyles } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import ContactsIcon from '@material-ui/icons/Contacts';
import history from "../../history";

//Components:
import CurrencyUSDRateField from "../Forms/CurrencyUSDRateField";
import AddressField from "../Forms/AddressField";

import TransactionFeeConfiguration from "../TransactionFeeConfiguration";
import AssetLoaderProgress from "../AssetLoaderProgress";
import BiometricsUnlock from '../BiometricsUnlock';
//Views
import TransactionCreatedModal from "../../views/modals/TransactionCreatedModal";
import TransactionFailedModal from "../../views/modals/TransactionFailedModal";
import CheckPassword from "../../views/CheckPassword";

import EtherUtil from "../../utils/ethers";
import BNUtils from "../../utils/bnUtils";
import GasUtils from "../../utils/blockchain/GasUtils";
import { getError } from "../../utils/transformErrors";
import GtagUtil from "../../utils/gtagUtils";
import { exRateIdxKey } from "../../utils/in4xUtils";

import { ROUTE_ADDRESS_BOOK, ROUTE_DEFAULT_LOGGEDIN } from "../../constants/routes";
import { CURRENCY_ETH, CURRENCY_USD } from "../../constants/currencies";
import { BLOCKCHAIN_ETH, BLOCKCHAIN_BSC, BLOCKCHAIN_MATIC } from "../../constants/blockchain";
import { ERROR_COMMON, ERROR_GAS_TOO_LOW, ERROR_PROVIDER_GAS, ERROR_UNDERPRICED_REPLACEMENT_TRX } from "../../constants/errorType";

import { checkWalletPassword, loginWithWallet } from "src/ducks/signin";
import { getAssets } from "src/ducks/dashboard";
import { getExchangeRate } from "src/ducks/in4x";
import {
    addPendingTransactionToBackend,
    addTransaction,
    changeBalance,
    changeSendingAddress,
    changeSendingAmount,
    changeSendingCurrency,
    removePending,
    removePendingListFromBackend,
    clearTransactionData
} from "src/ducks/blockchain";
import {getCommonAddressBookData, getSavedAddressBookData, addAddressBookItem} from "src/ducks/addresses";
import SaveAddressModal from "src/wallet-views/address-book/SaveAddressModal";
import ProviderUtils from "src/utils/blockchain/ProviderUtils";

// TODO: Move this to sass files
const styles = () => ({
    cssLabel: {
        '&$cssFocused': {
            color: "#0082BC",
        },
    },
    cssAsterisk: {
        color: "#0082BC",
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: "#0082BC",
        },
        '&:hover': {
            borderColor: "#0082BC",
        },
    },
    cssFocused: {
        borderColor: "#0082BC",
        '&:hover': {
            borderColor: "#0082BC",
        },
    },
    notchedOutline: {
        borderColor: "#0082BC",
        '&:hover': {
            borderColor: "#0082BC",
        },
    },
    button: {
        minWidth: "100px",
    },
    link: {
        color: "#0082BC",
    },
    input: {
        position: 'relative',
    },
    icon: {
        position: "absolute",
        right: '15px',
        top: '30px',
        cursor: 'pointer'
    },
    addressBookIcon: {
        position: "absolute",
        right: '20px',
        top: '35px',
        cursor: 'pointer',
        color: '#0082BC'
    }
});

let isUnloading = false;

class SendTokens extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            exRate: 0,
            rate: 0,
            successModal: false,
            failedModal: false,
            error: "",
            hash: "",
            isSubmitting: false,
            checkPass: false,
            submitted: false,
            catchErr: null,
            passwordError: false,
            showEnsureAddress: false,
            addressBookName: '',
            addToAddressBook: false,
            saveAddressModal: false
        };
    }

    componentDidMount = async () => {
        if (isUnloading) { return true; }
        try {
            if (isUnloading) { return true; }
            if (this.props.blockchain.sendingAmount && this.props.blockchain.sendingAmount.value) {
                this.changeRate(this.props.blockchain.sendingAmount.value);
            }
            this.checkAddressBookForTheAddress();
        } catch (err) {
            this.setState({ catchErr: err });
        }
    }

    shouldComponentUpdate = async() => {
        if(!this.props.blockchain.sendingCurrency || this.state.isLoaded || this.props.in4x.error) {
            return false;
        }
        const key = exRateIdxKey(CURRENCY_USD, this.props.blockchain.sendingCurrency);
        if(this.props.in4x.exRates[key]) {
            this.setState({exRate: this.props.in4x.exRates[key].high, isLoading: false, isLoaded: true});
        } else if(!this.props.in4x.in4xRateLoading) {
            this.props.getExchangeRate(CURRENCY_USD, this.props.blockchain.sendingCurrency);
            this.setState({isLoading: true});
        }
        if(!this.props.blockchain.sendingCurrencyObj || Object.keys(this.props.blockchain.sendingCurrencyObj).length < 1) {
            this.selectCurrencyFromUrl();
        }
        return true;
    }

    componentWillMount = () => {
        isUnloading = false;
        if (!this.props.addresses.commonAddressesDataLoaded) {
            this.props.getCommonAddressBookData();
        }
        if (!this.props.addresses.savedAddressesDataLoaded) {
            const wallet = this.props?.blockchain?.wallet?.address;
            this.props.getSavedAddressBookData(wallet);
        }
    }

    componentWillUnmount = () => {
        isUnloading = true;
        this.props.clearTransactionData();
    }

    handleBackButtonClick = () => {
        if(this.props.onTransactionBack) {
            this.props.onTransactionBack();
        } else {
            history.push(ROUTE_DEFAULT_LOGGEDIN);
        }
    };

    getCurrencyName = currency => currency === CURRENCY_ETH ? "Wei" : "Token Bit (10^-18)";

    getErrorText = () => {
        try {
            const { error } = this.state;
            //TODO why do we have custom handling?
            switch (error) {
                case "ERROR_ETH_BALANCE_TOO_LOW":
                    return "You don't have enough ETH to pay the transaction fee.";

                case ERROR_GAS_TOO_LOW:
                    return "Fee amount isn't enough/Or Always Failing Transaction.";

                case ERROR_PROVIDER_GAS:
                    return "Gas price is lower than the network price (Possibly price increased?)"

                case ERROR_UNDERPRICED_REPLACEMENT_TRX:
                    return "You have a stuck transaction, and are attempting to replace it with a new one with lower fee, you need to wait for the stuck transaction.";

                case ERROR_COMMON:
                    return "There was an error during transaction confirm. Let us know, or try again later."

                default:
                    return getError(error);
                    
            }
        } catch (err) {
            this.setState({ catchErr: err });
        }
    };

    selectCurrencyFromUrl = () => {
        const pathname = history.location.pathname.split('/');
        if(pathname && pathname.length > 3) {
            const blockchain = pathname[pathname.length - 2];
            const currency = pathname[pathname.length - 1];
            const { balance } = this.props.blockchain;
            if(balance && balance[blockchain] && balance[blockchain][currency]) {
                this.props.changeSendingCurrency(balance[blockchain][currency].name, balance[blockchain][currency]);
            }
        }
    }

    handleSubmit = () => {
        this.setState({ checkPass: true });
    };

    handleCancelSubmit = () => {
        this.setState({ checkPass: false });
    };

    handleCloseModal = () => {
        try {
            this.setState({ successModal: false, failedModal: false, hash: "", error: "" });
            if(this.props.onTransactionSuccess) {
                this.props.onTransactionSuccess(this.state.hash);
            } else {
                history.push(ROUTE_DEFAULT_LOGGEDIN);
            }
        } catch (err) {
            this.setState({ catchErr: err });
        }
    };

    handleErrorModal = () => {
        try {
            this.setState({ successModal: false, failedModal: false, hash: "", error: "" });
            if(this.props.onTransactionFail) {
                this.props.onTransactionFail();
            } else {
                history.push(ROUTE_DEFAULT_LOGGEDIN);
            }
        } catch(err) {
            this.setState({ catchErr: err });
        }
    }

    entireBalance = () => {
        try {            
            const { blockchain: { balance, sendingCurrency, sendingCurrencyObj, gasFee } } = this.props;   
            if(!balance[sendingCurrencyObj.blockchain] || !balance[sendingCurrencyObj.blockchain][sendingCurrency]) {
                return;
            }
            const amount = sendingCurrency === GasUtils.gasUnitsForBlockchain() ? 
                BNUtils.maxEthBalance(balance[sendingCurrencyObj.blockchain][sendingCurrency], gasFee.value) : 
                balance[sendingCurrencyObj.blockchain][sendingCurrency];
            this.changeAmount(amount);
        } catch (err) {
            this.setState({ catchErr: err });
        }
    };

    changeAmount = (amount) => {
        try {
            const { blockchain: { sendingCurrencyObj }} = this.props;
            this.props.changeSendingAmount((amount+'').length > 0 ? BNUtils.ensureDecimals(amount, sendingCurrencyObj.bits) : '');
            this.changeRate(amount);
        } catch (err) {
            this.setState({ catchErr: err });
        }
    }

    changeAddress = (address) => {
        this.props.changeSendingAddress(address);
        setTimeout(() => {
            this.checkAddressBookForTheAddress();
        });
    }

    openSaveAddressModal = () => {
        this.setState({saveAddressModal: true});
    }

    handleSubmitSaveAddressModal = (address, name) => {
        const wallet = this.props?.blockchain?.wallet?.address;
        this.props.addAddressBookItem(wallet, address, name);
        this.setState({saveAddressModal: false});
        setTimeout(() => {
            this.checkAddressBookForTheAddress();
        }, 300);
    }

    handleCloseSaveAddressModal = () => {
        this.setState({saveAddressModal: false});
    }

    checkAddressBookForTheAddress() {
        const {blockchain: {sendingAddress}, addresses: {commonAddresses, savedAddresses}} = this.props;
        if (sendingAddress.value && !sendingAddress.error) {
            let name = '';
            const blockchain = ProviderUtils.activeBlockchain();
            const network = ProviderUtils.activeNetwork();
            const savedAddress = savedAddresses?.find(item => item.address === sendingAddress.value && item.blockchain === blockchain && item.network === network);
            if (savedAddress) {
                name = savedAddress.name;
            }
            const savedCommonAddress = commonAddresses?.find(item => item.address === sendingAddress.value && item.blockchain === blockchain && item.network === network);
            if (savedCommonAddress) {
                name = savedCommonAddress.name;
            }
            if (name) {
                this.setState({addressBookName: name});
                this.setState({addToAddressBook: false});
            } else {
                this.setState({addToAddressBook: true});
            }
        } else {
            this.setState({addressBookName: ''});
            this.setState({addToAddressBook: false});
        }

        if (sendingAddress?.value?.length > 0) {
            this.setState({ showEnsureAddress: false });
        } else {
            this.setState({ showEnsureAddress: true });
        }
    }

    changeRate = (amount) => {
        const exRate = this.state.exRate;
        const trate = (amount > 0 && exRate > 0) ? (amount * exRate).toFixed(4) : 0;
        this.setState({ rate: trate });
    }

    handleCheckPassword = ({ password }) => {
        const hashPassword = sha512(password);
        this.submitNewTx(hashPassword);
    };

    submitNewTx = async (hashPassword) => {
        try {
            const wallet = await this.props.checkWalletPassword(hashPassword);
            if (wallet) {
                const result = await this.props.loginWithWallet(wallet, hashPassword);
                if (result && result.success) {
                    this.setState({ isSubmitting: true, passwordError: false });
                    const { 
                        wallet, sendingAddress, sendingAmount, sendingCurrency, sendingCurrencyObj, sendingTrxType
                    } = this.props.blockchain;
                    let tx;
                    this.props.changeSendingAmount(sendingAmount.value);
                    let txProps = GasUtils.generateTransactionGas(this.props.blockchainGas);
                    if (sendingCurrency === GasUtils.gasUnitsForBlockchain()) { //We're transferring native currency
                        tx = await EtherUtil.newSimpleTransaction(wallet, sendingAddress.value, sendingAmount.value, txProps);
                    } else {
                        tx = await EtherUtil.newTokenTransaction(wallet, sendingCurrency, sendingCurrencyObj.address, 
                            sendingAddress.value, sendingAmount.value, txProps, sendingCurrencyObj.bits);
                    }
                    if (typeof tx === "object") {
                        this.props.addTransaction(tx);
                        this.setState({ successModal: true, hash: tx.hash, checkPass: false, submitted: true, isSubmitting: false });
                        EtherUtil.monitorPendingTransaction(tx, () => {
                            this.props.removePending(tx.hash);
                            this.props.removePendingList(wallet.address, [tx.hash]);
                        });
                        this.props.addPendingTransactionToBackend(wallet.address, {
                            blockchain: tx.blockchain,
                            network: tx.network,
                            hash: tx.hash,
                            receiver: sendingAddress.value,
                            currency: sendingCurrency,
                            amount: sendingAmount.value,
                            trxType: sendingTrxType
                        });
                        GtagUtil.trackTransfer(sendingAmount.value, sendingCurrency, tx.blockchain);
                    } else {
                        let error;
                        if (!tx || !tx.indexOf) {
                            error = ERROR_COMMON;
                        } 

                        this.setState({ failedModal: true, error, checkPass: false, submitted: false, isSubmitting: false });
                    }
                    return;
                }
            }
            this.setState({ passwordError: true, isSubmitting: false });
        } catch (err) {
            if (err && err.error && err.error.message) {
                this.setState({failedModal: true, error: err.error.message});
            } else if (err && err.code && err.code === "REPLACEMENT_UNDERPRICED") {
                this.setState({ failedModal: true, error: ERROR_UNDERPRICED_REPLACEMENT_TRX });
            } else if (err && err.toString().includes('Gas price is too low')) {
                this.setState({ failedModal: true, error: err.toString() });
            } else {
                this.setState({ failedModal: true, error: ERROR_COMMON });
            }
        }
    };

    render() {
        if (this.state.catchErr) throw this.state.catchErr;
        let { rate, isSubmitting, passwordError, hash, addressBookName, addToAddressBook} = this.state;
        const {
            blockchain: {
                sendingAmount,
                sendingAddress,
                submitEnabled,
                sendingCurrency,
                sendingCurrencyObj,
                gasFee
            },
            blockchainGas: {
                gasReady,
            },
            dashboard: {
                userAssetTypesLoading,
                assetBalancesLoading,
                assetBalancesLoaded,
            },
            classes,
            disabled,
            hideBack,
            swap,
        } = this.props;
        const validationError = sendingAmount.error || sendingAddress.error || gasFee.error;
        const validationHelperText = sendingAmount.helperText || sendingAddress.helperText || gasFee.helperText;
        const isSubmitAllowed = submitEnabled && gasReady;
        const ticker = (sendingCurrencyObj && sendingCurrencyObj.ticker? sendingCurrencyObj.ticker:'');

        return (
            <Grid container spacing={1} justifyContent="center" alignItems="center" pt={3}>
                <TransactionCreatedModal openOn={this.state.successModal} onClose={this.handleCloseModal} trxhash={hash} />
                <TransactionFailedModal openOn={this.state.failedModal} onClose={this.handleErrorModal} errorText={this.getErrorText()} />
                <SaveAddressModal addressToSave={this.props.blockchain.sendingAddress.value} openOn={this.state.saveAddressModal} onClose={this.handleCloseSaveAddressModal} onSubmit={this.handleSubmitSaveAddressModal}/>

                {!this.state.checkPass && !this.state.submitted && (
                    <>
                        <Grid container spacing={1} justifyContent="center" alignItems="center">
                            <Grid item xs={12} className={classes.input}>
                                {assetBalancesLoaded && !assetBalancesLoading && (
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label={"Amount"}
                                        placeholder={"0.0"}
                                        error={sendingAmount.error}
                                        helperText={sendingAmount.helperText}
                                        required
                                        onChange={e => {
                                            this.changeAmount(e.target.value);
                                        }}
                                        value={sendingAmount.value}
                                        margin="normal"
                                        variant="outlined"
                                        disabled={disabled}
                                    />)}
                                {!disabled && gasFee.value && assetBalancesLoaded && !assetBalancesLoading && (
                                    <Button variant="contained" color="primary" onClick={this.entireBalance} className={classes.icon}>
                                        <Typography noWrap>
                                            Max
                                        </Typography>
                                    </Button>
                                )}
                                {(assetBalancesLoading || userAssetTypesLoading) && (
                                    <AssetLoaderProgress />
                                )}
                                {((!rate || rate <= 0) && !sendingAmount.value) && (
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            {`Minimum amount is 1 ${this.getCurrencyName(sendingCurrency)}`}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>                                
                            {rate > 0 && (
                                <Grid item xs={12}>
                                    <CurrencyUSDRateField rate={rate} />
                                </Grid>
                            )}
                            <Grid item xs={12} className={classes.input}>
                                <AddressField
                                    value={sendingAddress.value}
                                    label={"To Address"} 
                                    error={sendingAddress.error} 
                                    helperText={sendingAddress.helperText}
                                    onChange={this.changeAddress}
                                    disabled={disabled}
                                />
                                {!disabled && (<Link to={ROUTE_ADDRESS_BOOK}>
                                    <ContactsIcon className={classes.addressBookIcon} />
                                </Link>)}
                                { addressBookName && (
                                    <Typography align="left" gutterBottom color="primary" style={{ paddingLeft: '25px' }}>
                                        {addressBookName}
                                    </Typography>
                                )}
                                { addToAddressBook && (
                                    <Typography align="left" gutterBottom color="primary" style={{ textDecoration: 'underline', paddingLeft: '25px' }} onClick={this.openSaveAddressModal}>
                                        Add to Address Book
                                    </Typography>

                                )}
                                { !disabled && sendingCurrencyObj.blockchain && this.state.showEnsureAddress && (<Typography variant="body2" align="center" gutterBottom color="secondary" size="0.1">
                                    Ensure address is on 
                                    {sendingCurrencyObj.blockchain === BLOCKCHAIN_BSC && (" BNB Chain (BEP20)")}
                                    {sendingCurrencyObj.blockchain === BLOCKCHAIN_ETH && (" Ethereum (ERC20)")}
                                    {sendingCurrencyObj.blockchain === BLOCKCHAIN_MATIC && (" Polygon")}
                                </Typography>)}
                            </Grid>
                            <TransactionFeeConfiguration />
                        </Grid>
                        { validationError && (
                          <Grid item xs={12}>
                            <Alert variant="outlined" severity="error" className="left-grid">{validationHelperText}</Alert>
                          </Grid>
                        )}
                        <Button color="primary" className="spaced__btn" fullWidth disabled={!isSubmitAllowed}
                            onClick={this.handleSubmit} variant="contained">
                            Send Transaction
                        </Button>
                        { !hideBack && (<Button color="default" className="spaced__btn" fullWidth variant="contained" onClick={() => {this.handleBackButtonClick()}}>
                            Back
                        </Button>)}
                    </>
                )}
                { this.state.checkPass && (
                    <Grid item xs={12} className="centered-grid spaced-top">
                        <Typography variant="h5" align="center" color="primary" gutterBottom>
                            Confirm Your Transaction
                        </Typography>
                        {isSubmitting && (
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <CircularProgress />
                                <Typography align="center" gutterBottom>Submitting to Blockchain ... </Typography>
                            </Grid>
                        )}
                        {!isSubmitting && (
                            <Grid item xs={12}>
                                <CheckPassword
                                    onSubmit={this.handleCheckPassword}
                                    goBack={this.handleCancelSubmit}
                                    signin={{failed: passwordError, error: 'Invalid Password'}}
                                />
                                <BiometricsUnlock onSuccess={this.submitNewTx} />
                            </Grid>
                        )}
                    </Grid>
                )}
                { this.state.submitted && (
                    <Grid item xs={12} className="centered-grid">
                        <Typography variant="h5" align="center" color="primary" gutterBottom>
                            Transaction Processed
                        </Typography>
                        <Alert variant="outlined" severity="success">
                            {swap ? `We have successfully received your ${ticker} transfer, our team will process iOWN transfer soon` : 'Transaction created'}
                        </Alert>
                    </Grid>
                )}
            </Grid>
        );
    }
}

const mapState2props = state => ({
    blockchain: state.blockchain,
    blockchainGas: state.blockchainGas,
    dashboard: state.dashboard,
    in4x: state.in4x,
    addresses: state.addresses
});

const mapDispatch2props = {
    addTransaction,
    changeSendingAddress,
    changeSendingAmount,
    changeBalance,
    changeSendingCurrency,
    removePending,
    removePendingList: removePendingListFromBackend,
    addPendingTransactionToBackend,
    checkWalletPassword,
    loginWithWallet,
    getAssets,
    clearTransactionData,
    getExchangeRate,
    getCommonAddressBookData,
    getSavedAddressBookData,
    addAddressBookItem,
};

export default compose(
    withStyles(styles),
    connect(mapState2props, mapDispatch2props)
)(SendTokens);
