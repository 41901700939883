import StorageUtils from "../storageUtils";
import { KEY_ACTIVE_BLOCKCHAIN, KEY_ACTIVE_ETH_NET } from "../../constants/storageKeys";
import {
  BLOCKCHAIN_BSC,
  BLOCKCHAIN_ETH,
  BLOCKCHAIN_MATIC,
  NETWORK_MAINNET,
  NETWORK_GOERLI,
  NETWORK_TESTNET,
  NETWORK_MUMBAI
} from "../../constants/blockchain";
import {
  QUICK_NODE,
  FALLBACK,
  ALCHEMY
} from "src/constants/providers";
import { EVENT_PROVIDER_BLOCKCHAIN_CHANGE, EVENT_PROVIDER_NETWORK_CHANGE } from "../../constants/events";
const ethers = require("ethers");

/**
 * @name ProviderUtils 
 * @description A utilities class for managing different blockchain providers for connecting to nodes/blockchains
 */
export default class ProviderUtils {

  /**
   * @name changeDefaultBlockchain
   * @description Sets the current default blockchain
   * @param {string} `blockchain` A blockchain name (ex: eth, bsc)
   */
  static changeDefaultBlockchain = blockchain => {
    if (ProviderUtils.activeBlockchain() !== blockchain && blockchain && blockchain !== 'in4x') {
      StorageUtils.setItem(KEY_ACTIVE_BLOCKCHAIN, blockchain);
      if (ProviderUtils.activeNetwork() !== NETWORK_MAINNET) {
        StorageUtils.setItem(KEY_ACTIVE_ETH_NET, ProviderUtils.pickTestnetForBlockchain(blockchain));
      }
      var bsEvent = new CustomEvent(EVENT_PROVIDER_BLOCKCHAIN_CHANGE, { detail: { blockchain }});
      window.dispatchEvent(bsEvent);
    }
  }

  /**
   * @name changeDefaultNetwork
   * @description Sets the current default network
   * @param {string} `network` A blockchain network name (ex: mainnet, ropsten, testnet...etc)
   */
  static changeDefaultNetwork = network => {
    if(ProviderUtils.activeNetwork() !== network) {
      StorageUtils.setItem(KEY_ACTIVE_ETH_NET, network);
      var bsEvent = new CustomEvent(EVENT_PROVIDER_NETWORK_CHANGE, { detail: { network }});
      window.dispatchEvent(bsEvent);
    }
  }

  /**
   * @name resetNetwork
   * @description Resets the network to suitable one on wallet unlock: Remember WalletConnect will cause a loop if we try to reset Blockchain
   *  purpose here is to prevent testnet access unless its allowed
   */
  static resetNetwork() {
    if(process.env.REACT_APP_ALLOW_TEST_MODE !== 'true' && ProviderUtils.activeNetwork() !== NETWORK_MAINNET) {
      StorageUtils.setItem(KEY_ACTIVE_ETH_NET, NETWORK_MAINNET);
    }
    // StorageUtils.setItem(KEY_ACTIVE_BLOCKCHAIN, process.env.REACT_APP_DEFAULT_BLOCKCHAIN || BLOCKCHAIN_BSC);
  }


  /**
   * @name activeNetwork
   * @description Get the current active currency's network
   * @returns {string}
   */
  static activeNetwork() {
    return StorageUtils.getItem(KEY_ACTIVE_ETH_NET) || process.env.REACT_APP_DEFAULT_NETWORK || NETWORK_MAINNET;
  }

  /**
   * @name activeNetwork
   * @description Get the current active currency's blockchain 
   * @returns {string} Example: eth|bsc
   */
  static activeBlockchain() {
    return StorageUtils.getItem(KEY_ACTIVE_BLOCKCHAIN) || process.env.REACT_APP_DEFAULT_BLOCKCHAIN || BLOCKCHAIN_BSC;
  }

  /**
   * @name activeProvider
   * @description Get the current active currency's suitable provider
   * @returns {JsonRpcProvider}
   */
  static activeProvider() {
    return ProviderUtils.providerFor(ProviderUtils.activeNetwork(), ProviderUtils.activeBlockchain());
  }

  /**
   * @name activeNetworkId
   * @description Get the current active currency's suitable network ID as returned from provider
   * @returns {string} 01, 03...etc
   */
  static activeNetworkId() {
    return ProviderUtils.activeProvider().getNetwork();
  }

  /**
   * @name providerFor
   * @description Get the provider for a network and a blockchain (uses providerUrlFor)
   * @param {string} `network` A network name (ex: mainnet, ropsten, testnet...etc)
   * @param {string} `blockchain`  A valid blockchain (ex: eth|bsc...etc)
   * @returns {JsonRpcProvider}
   */
  static providerFor = (network, blockchain) => {
    let provider = new ethers.providers.JsonRpcProvider(ProviderUtils.providerUrlFor(blockchain, network));
    provider.connectedBlockchain = blockchain;
    provider.connectedNetwork = network;
    return provider;
  }

  /**
   * @name providerFor
   * @description Get the provider for a network and a blockchain
   * @param {string} `network` A network name (ex: mainnet, ropsten, testnet...etc)
   * @param {string} `blockchain`  A valid blockchain (ex: eth|bsc...etc)
   * @returns {string}
   */
  static providerUrlFor = (blockchain, network) => {
    const key = `PROVIDER_${blockchain.toUpperCase()}_${network.toUpperCase()}_URL`;
    if(process.env[key]) {
        return process.env[key];
    } else {
      try {
          return ProviderUtils.quickNodeFor(blockchain, network);
      } catch(quickNodeError) {
          return ProviderUtils.fallbackProviderFor(blockchain, network);
      }
    }
  }

  /**
   * @dev Returns an QuickNode JSON-RPC URL
   * @param {string} `blockchain` A blockchain code
   * @param {string} `network` A network code
   * @returns {string} JSON-RPC URL to be used with 'new ethers.providers.JsonRpcProvider'
   */
  static quickNodeFor = (blockchain, network) => {
      const key = `${blockchain.toUpperCase()}_${network.toUpperCase()}`;
      if(QUICK_NODE[key]) {
          return QUICK_NODE[key];
      }
      throw new Error('No JSON-RPC QuickNode Provider found for blockchain/network ' + key);
  }

  /**
   * @dev Returns an Alchemy JSON-RPC URL
   * @param {string} `blockchain` A blockchain code
   * @param {string} `network` A network code
   * @returns {string} JSON-RPC URL to be used with 'new ethers.providers.JsonRpcProvider'
   */
  static alchemyFor = (blockchain, network) => {
      if (blockchain !== BLOCKCHAIN_MATIC) throw new Error('Unsupported Blockchain for NFTs ' + blockchain)
      switch (network) {
          case NETWORK_MAINNET:
              return ALCHEMY.MATIC_MAINNET;
          default:
              return ALCHEMY.MATIC_MUMBAI;
      }
  }

  /**
   * @dev Returns a fallback JSON-RPC URL
   * @param {string} `blockchain` A blockchain code
   * @param {string} `network` A network code
   * @returns {string} JSON-RPC URL to be used with 'new ethers.providers.JsonRpcProvider'
   */
   static fallbackProviderFor = (blockchain, network) => {
      const key = `${blockchain.toUpperCase()}_${network.toUpperCase()}`;
      if(FALLBACK[key]) {
          return FALLBACK[key];
      }
      throw new Error('No JSON-RPC Provider found for blockchain/network ' + key);
  }

  static pickTestnetForBlockchain = blockchain => {
    switch (blockchain) {
      case BLOCKCHAIN_ETH:
        return NETWORK_GOERLI;
      case BLOCKCHAIN_MATIC:
        return NETWORK_MUMBAI;
      default:
        return NETWORK_TESTNET;
    }
  }

  static activeBlockchainName() {
    const activeBlockchain = ProviderUtils.activeBlockchain();
    switch (activeBlockchain) {
      case BLOCKCHAIN_ETH:
        return 'Ethereum';
      case BLOCKCHAIN_BSC:
        return 'BNB Chain';
      case BLOCKCHAIN_MATIC:
        return 'Polygon';
      default:
        return '';
    }
  }
}