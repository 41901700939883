import React from "react";
import Container from '@material-ui/core/Container';
import { LinearProgress, Typography, Grid } from "@material-ui/core";
import { compose } from "redux";
import { connect } from "react-redux";

import Balances from "../../components/Balances";
import BottomBar from "../../components/BottomBar";
import SwipeableHook from "../../components/SwipeableHook";

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { ROUTE_WALLET_ASSETS, ASSET_TYPE_NFT, ASSET_TYPE_LIQUID, ASSET_TYPE_SPOT, ASSET_TYPE_DIGITAL } from "../../constants/routes";
import { EVENT_PROVIDER_BLOCKCHAIN_CHANGE, EVENT_PROVIDER_NETWORK_CHANGE } from "../../constants/events";
import { loadAssets, getSafeArea } from "../../utils/appUtils";

import {
  clearTransactionData,
  changeBalance,
  setGasCurWarning
} from "../../ducks/blockchain";
import { resetGas } from "../../ducks/blockchainGas";
import { getAssets } from "../../ducks/dashboard";
import { getIn4xProfile, resetIn4x } from "../../ducks/in4x";

import CustomSnackbar from "../../components/CustomSnackbar";
// For Swiping down, left and right
const DIRECTIONS = ['Left', 'Right', 'Up', 'Down'];

function getBoundSwipes(component) {
  let boundSwipes = {};
  DIRECTIONS.forEach((dir)=>{
    if (component.state[`onSwiped${dir}Applied`]) {
      // @ts-ignore
      boundSwipes[`onSwiped${dir}`] = component.onSwipedDirection.bind(component, dir);
    }
  });
  return boundSwipes;
}

let historyListener;
let latestHistory = null;

class MyAssets extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openAlert: false,
      refreshingBalances: false,
      catchErr: null,
      safeArea: null,
      currentAssetType: (this.props.match.params.assetType ? this.props.match.params.assetType : ASSET_TYPE_LIQUID),
    };
    this.balanceElementRef = React.createRef();
  }

  componentWillMount = () => {
      getSafeArea().then((sa) => {
        this.setState({safeArea: sa});
      }).catch(() => {
        // ignore
      });
  }

  handleAssetsChange = (event) => {
    this.changeAssetType(event, ASSET_TYPE_LIQUID);
  }

  componentDidMount = () => {
    const { handleAssetsChange } = this;

    if(this.state.currentAssetType === ASSET_TYPE_DIGITAL && !this.props.dashboard.showDigitalAssetTypes) {
      this.changeAssetType(null, ASSET_TYPE_LIQUID);
    }

    window.addEventListener(EVENT_PROVIDER_BLOCKCHAIN_CHANGE, handleAssetsChange);
    window.addEventListener(EVENT_PROVIDER_NETWORK_CHANGE, handleAssetsChange);

    if(!historyListener) {
      historyListener = this.props.history.listen((location) => {
        if(location.pathname.indexOf(ROUTE_WALLET_ASSETS) !== -1) {
           const assetType = location.pathname.substring(location.pathname.indexOf(ROUTE_WALLET_ASSETS) + ROUTE_WALLET_ASSETS.length);
           if(latestHistory !== assetType) {
             latestHistory = assetType;
             this.changeAssetType(null, assetType);
           }
        }
      });
    }
  }

  componentWillUnmount() {
    const { handleAssetsChange } = this;
    window.removeEventListener(EVENT_PROVIDER_BLOCKCHAIN_CHANGE, handleAssetsChange);
    window.removeEventListener(EVENT_PROVIDER_NETWORK_CHANGE, handleAssetsChange);
    latestHistory = null;
  }

  handleCloseAlert = () => {
    try {
      this.setState({ openAlert: false });
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  changeAssetType = async (event, assetType) => {
    if(assetType === 'refresh') {
      return this.refresh();
    }
    this.setState({currentAssetType: assetType});
    if(this.balanceElementRef && this.balanceElementRef.current) {
      this.balanceElementRef.current.state.collapsed = false;
    }
    this.props.history.replace('/my-assets/'+assetType);
    this.props.blockchain.sendingCurrency = ( assetType === ASSET_TYPE_SPOT? "usd" : null );
    latestHistory = assetType;
  };

//Swiping stuff:
  onSwiped(args) {
    if(Math.abs(args.deltaY) < 100) {
       this.setState({
        swiping: false,
        swiped: false,
        swipingDirection: null
      });
      return; // prevent small swipes
    }
    this.setState({
      swiped: true,
      swiping: false,
    });
    this.refresh();
  }

  onSwipedLeft(args) {
    if(Math.abs(args.deltaX) < 100) {
      this.setState({
        swiping: false,
        swiped: false,
        swipingDirection: null
      });
      return; // prevent small swipes
    }
    this.setState({
      swiped: true,
      swiping: false,
    });
    let next;
    switch(this.state.currentAssetType) {
      case ASSET_TYPE_NFT:
        next = ASSET_TYPE_LIQUID; break;
      case ASSET_TYPE_LIQUID:
        next = (this.props.dashboard.showDigitalAssetTypes? ASSET_TYPE_DIGITAL:null); break;
      default: next = null;
    }
    if(next) this.changeAssetType(null, next);
  }

  onSwipedRight(args) {
    if(Math.abs(args.deltaX) < 100) {
      this.setState({
        swiping: false,
        swiped: false,
        swipingDirection: null
      });
      return; // prevent small swipes
    }
    this.setState({
      swiped: true,
      swiping: false,
    });
    let next;
    switch(this.state.currentAssetType) {
      case ASSET_TYPE_DIGITAL:
        next = ASSET_TYPE_LIQUID; break;
      case ASSET_TYPE_LIQUID:
        next = ASSET_TYPE_NFT; break;
      default: next = null;
    }
    if(next) this.changeAssetType(null, next);
  }

  onSwiping(args) {
    if(args.velocity < 0.1 || (Math.abs(args.deltaY) < 100 && Math.abs(args.deltaX) < 100)) {
      return;
    }
    this.setState({
      swiping: true,
      swiped: false,
      swipingDirection: args.dir,
    });
  }

  refresh = async() => {
    loadAssets(this.props, this.state, true); //balances load will be triggered by app.js
    this.props.resetIn4x();
  }

  render() {
    if (this.state.catchErr) throw this.state.catchErr;
    const { openAlert, safeArea } = this.state;
    const { showDigitalAssetTypes, userAssetTypesLoading }= this.props.dashboard;
    const boundSwipes = getBoundSwipes(this);
    let swipeableDirProps = {
      onSwiping: (...args)=>this.onSwiping(...args),
      onSwipedDown: (...args)=>this.onSwiped(...args),
      onSwipedLeft: (...args)=>this.onSwipedLeft(...args),
      onSwipedRight: (...args)=>this.onSwipedRight(...args),
    }

    const swipingRightDisallowed = this.state.currentAssetType === ASSET_TYPE_NFT;
    const swipingLeftSiallowed = this.state.currentAssetType === ASSET_TYPE_DIGITAL || (this.state.currentAssetType === ASSET_TYPE_LIQUID && !showDigitalAssetTypes);
    return (
      <>
        <SwipeableHook
            {...boundSwipes}
            {...swipeableDirProps}
            preventDefaultTouchmoveEvent={false}
            trackTouch={true}
            trackMouse={true}
            className="callout hookComponent vp-height"
            >
          <Container className="home dashboard" component="main" maxWidth="sm">
              { this.state.swiping && this.state.swipingDirection === 'Down' && (
                <Grid item xs={12} className="swipe-down-grid">
                  <ExpandLessIcon color="primary" className="swipe-icon down"/>
                  <Typography color="primary">Release to refresh balances!</Typography>
                </Grid>
              )} 
              { userAssetTypesLoading && (
                <Grid item xs={12} className="centered-grid spaced-bottom">
                  <Typography color="primary">Refreshing Assets...</Typography>
                  <LinearProgress />
                </Grid>
              )}
              
              { this.state.swiping && this.state.swipingDirection === 'Right' && !swipingRightDisallowed && ( <ChevronLeftIcon color="primary" className="swipe-icon left" />)}
              { this.state.swiping && this.state.swipingDirection === 'Left' && !swipingLeftSiallowed && ( <ChevronRightIcon color="primary" className="swipe-icon right" />)}

              <Balances 
                currentAssetType={this.state.currentAssetType}
                ref={this.balanceElementRef}
              />
              <CustomSnackbar
                  anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                  variant="success"
                  open={openAlert}
                  message={"Copied to clipboard"}
                  onClose={this.handleCloseAlert}
                  autoHideDuration={6000}
              />
          </Container>
        </SwipeableHook>
        <BottomBar className="bottom-bar" 
          changeAssetType={this.changeAssetType}
          currentAssetType={this.state.currentAssetType}
          showAsset={showDigitalAssetTypes}
          safeArea={safeArea}
        />
      </>
    );
  }
}

const mapState2props = state => ({
  dashboard: state.dashboard,
  blockchain: state.blockchain,
  in4x: state.in4x,
  iod: state.iod,
});

//setGasCurWarning Should be here for appUtils usage
const mapDispatch2props = {
  getAssets,
  changeBalance,
  clearTransactionData,
  resetGas,
  getIn4xProfile,
  setGasCurWarning, 
  resetIn4x
};

export default compose(
  connect(mapState2props, mapDispatch2props)
)(MyAssets);