import { utils } from "ethers";
import EtherUtil from "src/utils/ethers";

import { chainIdToBlockchain, chainIdToNetwork, getBlockchainName } from 'src/constants/blockchain';
import { EIP155_SIGNING_METHODS } from '../../constants';

const cameCasetoTitle = text => {
	const result = text.replace(/([A-Z]{1,})/g, " $1");
	return result.charAt(0).toUpperCase() + result.slice(1);
}

const mapObjectToLabelValue = dataObject => {
	if(dataObject && typeof dataObject === 'object') {
		const mappedAr = Object.keys(dataObject).map((key) => {
			const datum = dataObject[key];
			if(typeof datum === 'object') {
				return mapObjectToLabelValue(datum);
			}
			return {
				label: cameCasetoTitle(key),
				value: datum
			};
		});
		return [].concat(...mappedAr);
	}
	return dataObject;
}

export const callRequestMethodLabel = (callRequestMethod) => {
	switch(callRequestMethod) {
		case EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION:
			return 'Sign Trx Request';
		case EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION:
			return 'Send Trx Request';
		case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA:
		case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3:
		case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4:
			return 'Sign Typed Data';
		case EIP155_SIGNING_METHODS.ETH_SIGN:
			return 'Sign Trx';
		case EIP155_SIGNING_METHODS.PERSONAL_SIGN:
			return 'Sign Message Request';
		case EIP155_SIGNING_METHODS.WALLET_SWITCH_CHAIN:
			return 'Switch Active Blockchain';
		default:
			return callRequestMethod;
	}
};

export const parseSignTypedData = (dataItem) => {
	const isEncoded = dataItem.includes('{');
	if (isEncoded) {
		const dataObject = JSON.parse(dataItem);
		return mapObjectToLabelValue(dataObject.message);
	}

	return [
		{
			label: "Wallet",
			value: dataItem,
		}
	];
};

export const parseTransactionSign = (dataItem) => {
	const { from, to, value, data } = dataItem;
	let decoded = EtherUtil.decodeTransactionData(data, value).toString();
	return [
		{
			label: 'From Address',
			value: from,
		},
		{
			label: 'To Address',
			value: to,
		},
		{
			label: 'Data',
			value: decoded,
		},
		{
			label: 'Value',
			value: value,
		}
	];
};

export const parsePersonalSign = (dataItem) => {
	const isHexed = !utils.isAddress(dataItem) && utils.isHexString(dataItem);

	if (isHexed) {
		return [
			{
				label: "Value",
				value: utils.toUtf8String(dataItem),
			},
		];
	}

	return [
		{
			label: "Wallet",
			value: dataItem,
		},
	];
};

export const parseSwitchWallet = (dataItem) => {
	if(dataItem.chainId) {
		const bc = getBlockchainName(chainIdToBlockchain(dataItem.chainId));
		const network = chainIdToNetwork(dataItem.chainId);
		const net = network.charAt(0).toUpperCase() + network.slice(1);
		return [
			{
				label: "Blockchain",
				value: bc
			},
			{
				label: "Network",
				value: net
			}
		];
	}
	return [
		{
			label: "Wallet",
			value: dataItem,
		},
	];
}