import React from 'react';
import ErrorsModal from "./views/modals/ErrorsModal";

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: null,
    info: null
  };

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error: error,
      info: info
    });
  }

  closeHasErrorModal = () => {
    this.setState((state) => ({
      hasError: !state.hasError,
      error: null,
      info: null
    }));
    window.location.reload();
  };

  render() {
    return ( 
      <> {
        this.state.hasError && (
          <ErrorsModal openOn={this.state.hasError} onClose={this.closeHasErrorModal} error={this.state.error} info={this.state.info} />
        )
      } {!this.state.hasError && (this.props.children) } 
      </>
    );
  }
}

export default ErrorBoundary;