import { getDefaultLimitForCurrency } from "../constants/defaultGasSettings";

import {handleFetch} from "../utils/fetch";
import EtherUtil from "../utils/ethers";
import ProviderUtils from "../utils/blockchain/ProviderUtils";
import GasUtils from "../utils/blockchain/GasUtils";

import { errorToMessage, getNativeAmountError, getAmountError, getFeeError } from "src/utils/blockchain/BalanceUtils";

import { performDefaultResult } from "../utils/stateManipulator";
import { ValidationUtil } from "../utils/validationUtil";
import { API_TRX_PENDING_GET, API_TRX_PENDING_SAVE, API_TRX_PENDING_REMOVE } from "../constants/apiRoutes";
import {TRX_TYPE_NFT_MULTI_TRANSFER, TRX_TYPE_SAFE_TRANSFER_FROM} from "../constants/blockchain";

const initialState = {
    failed: false,
    error: "",
    wallet: {},

    page: 1,
    perPage: 5,
    pageCount: 1,
    totalCount: 0,

    transactionsLoaded: false,
    transactions: [],
    dataLoaded: false,
    pending: [],

    lastBlock: 0,
    balance: {},
    //TODO: This should be used as BN rather than balance[]
    balanceBN: {},
    nftInventory: {},
    nftError: '',
    isNftInventoryLoading: false,
    sendingTrxType: '',
    sendingCurrency: '',
    sendingCurrencyObj: {},
    sendingAmount: {
        value: "",
        error: false,
        helperText: "",
    },
    sendingAddress: {
        value: "",
        error: false,
        helperText: "",
    },
    sendingTokenId: {
        value: "",
        error: false,
        helperText: ""
    },
    gasFee: {
        value: '',
        error: false,
        helperText: "",
    },
    gasBalanceLow: []
};

const ADD_TRANSACTION = "ADD_TRANSACTION";

const GAS_BALANCE_LOW = "GAS_BALANCE_LOW";
const CHANGE_FEE = "CHANGE_FEE";
const CHANGE_BALANCE = "CHANGE_BALANCE";
const CHANGE_TRANSACTIONS = "CHANGE_TRANSACTIONS";
const CHANGE_WALLET = "CHANGE_WALLET";

const CHANGE_SENDING_AMOUNT = "CHANGE_SENDING_AMOUNT";
const CHANGE_SENDING_ADDRESS = "CHANGE_SENDING_ADDRESS";
const CHANGE_SENDING_CURRENCY = "CHANGE_SENDING_CURRENCY";
const CHANGE_SENDING_TRX_TYPE = "CHANGE_SENDING_TRX_TYPE";
const CHANGE_SENDING_TOKENID = "CHANGE_SENDING_TOKENID";

const NFT_LOAD_ERROR = "NFT_LOAD_ERROR";

const CLEAR_TRANSACTION = "CLEAR_TRANSACTION";
const LOCK_TRANSACTIONS = "LOCK_TRANSACTIONS";
const CHANGE_PAGE_COUNT = "CHANGE_PAGE_COUNT";
const RESET_BLOCKCHAIN = "RESET_BLOCKCHAIN";
const REMOVE_PENDING = "REMOVE_PENDING";

const FETCH_PENDING_SUCCESS = "FETCH_PENDING_SUCCESS";
const FETCH_PENDING_FAILED = "FETCH_PENDING_FAILED";

const ADD_PENDING_SUCCESS = "ADD_PENDING_SUCCESS";
const ADD_PENDING_FAILED = "ADD_PENDING_FAILED";

const REMOVE_PENDING_SUCCESS = "REMOVE_PENDING_SUCCESS";
const REMOVE_PENDING_FAILED = "REMOVE_PENDING_FAILED";

export const addTransaction = tx => ({ type: ADD_TRANSACTION, tx });

export const setGasCurWarning = (cur) => ({type: GAS_BALANCE_LOW, cur});

export const setNFTLoadError = (error, nftAddress) => dispatch => dispatch({ type: NFT_LOAD_ERROR, error, nftAddress })
export const changeBalance = (currency, blockchain, amount, amountBN) => ({type: CHANGE_BALANCE, currency, blockchain, amount, amountBN});
export const changeTransactions = (transactions, page) => ({type: CHANGE_TRANSACTIONS, transactions, page});
export const changeWallet = wallet => ({type: CHANGE_WALLET, wallet});
export const changeSendingAmount = (sendingAmount, doNotTransform) => ({ type: CHANGE_SENDING_AMOUNT, sendingAmount, doNotTransform });
export const changeSendingAddress = (sendingAddress) => ({type: CHANGE_SENDING_ADDRESS, sendingAddress});
export const changeSendingTrxType = (sendingTrxType) => ({type: CHANGE_SENDING_TRX_TYPE, sendingTrxType});
export const changeFee = (fee, feeCurrency) => ({type: CHANGE_FEE, fee, feeCurrency});
export const changeSendingCurrency = (sendingCurrency, sendingCurrencyObj) => ({ type: CHANGE_SENDING_CURRENCY, sendingCurrency, sendingCurrencyObj });
export const clearTransactionData = () => ({ type: CLEAR_TRANSACTION });
export const lockTransactions = () => ({ type: LOCK_TRANSACTIONS });
export const changePageCount = (pageCount, totalCount) => ({ type: CHANGE_PAGE_COUNT, pageCount, totalCount });
export const resetBlockchain = () => ({ type: RESET_BLOCKCHAIN });
export const removePending = hash => ({ type: REMOVE_PENDING, hash });

export const fetchPending = (address, pendingCurrency) => dispatch => {
    return handleFetch(API_TRX_PENDING_GET, "POST", {currency: pendingCurrency, blockchain: ProviderUtils.activeBlockchain(), network: ProviderUtils.activeNetwork()})
        .then(async res => {
            const pending = await EtherUtil.filterPendingTransactions(res.payload, address, dispatch);
            dispatch({ type: FETCH_PENDING_SUCCESS, pending});
        })
        .catch(err => dispatch({ type: FETCH_PENDING_FAILED, error: err }));
};

export const addPendingTransactionToBackend = (address, reqParams) => dispatch => {
    if (typeof reqParams?.trxType === 'object') {
        let trxType = '';
        Object.values(reqParams?.trxType).forEach(item => {
            trxType = trxType + item;
        });
        reqParams.trxType = trxType;
    }
    handleFetch(API_TRX_PENDING_SAVE, "POST", reqParams)
        .then(res => performDefaultResult(res, ADD_PENDING_SUCCESS, dispatch))
        .catch(err => dispatch({ type: ADD_PENDING_FAILED, error: err }));
};

export const removePendingListFromBackend = (address, hashes) => dispatch => {
    handleFetch(API_TRX_PENDING_REMOVE, "POST", {hashes})
        .then(res => performDefaultResult(res, REMOVE_PENDING_SUCCESS, dispatch))
        .catch(err => dispatch({ type: REMOVE_PENDING_FAILED, error: err }));
};

function isReadyToSubmit (state) {
    const options = {
        notEmpty: ["sendingAmount", "sendingAddress", "gasFee"],
        isValid: ["sendingAmount", "sendingAddress", "gasFee"],
    };
    return ValidationUtil.isReadyToSubmit(state, options);
}

export const blockchain = (state = initialState, action) => {
    let error;
    let newState;
    let pending;

    switch (action.type) {
        case RESET_BLOCKCHAIN:
            return {...initialState};

        case REMOVE_PENDING:
            pending = [...state.pending];
            const index = pending.findIndex(i => i.hash === action.hash);
            if (index !== -1) {
                pending.splice(index, 1);
            }
            return {...state, pending, error: null};

        case FETCH_PENDING_SUCCESS:
            return {...state, pending: action.pending, error: null};

        case FETCH_PENDING_FAILED:
            return {...state, pending: [], error: action.error};

        case LOCK_TRANSACTIONS:
            return {...state, transactionsLoaded: false};

        case CHANGE_PAGE_COUNT:
            return {...state, pageCount: action.pageCount, totalCount: action.totalCount};

        case CHANGE_WALLET:
            return {...state, wallet: action.wallet};

        case CHANGE_BALANCE:
            let balance = {...state.balance}, balanceBN = {...state.balanceBN};
            if(!balance[action.blockchain]) {
                balance[action.blockchain] = {};
            }
            if(!balanceBN[action.blockchain]) {
                balanceBN[action.blockchain] = {};
            }
            balance[action.blockchain][action.currency] = action.amount;
            balanceBN[action.blockchain][action.currency] = action.amountBN;
            return {...state, balance: balance, balanceBN: balanceBN};

        case GAS_BALANCE_LOW:
            let gasBalanceLow = state.gasBalanceLow;
            if(!gasBalanceLow.includes(action.cur)) {
                gasBalanceLow.push(action.cur);
            }
            return {...state, gasBalanceLow};

        case ADD_TRANSACTION:
            pending = [action.tx, ...state.pending];
            return {...state, pending};

        case CHANGE_TRANSACTIONS:
            return {...state, transactionsLoaded: true, transactions: action.transactions, page: action.page};

        case CHANGE_SENDING_CURRENCY:
            let wallet = state.wallet;
            let minLimit = getDefaultLimitForCurrency(action.sendingCurrency);
            ProviderUtils.changeDefaultBlockchain(action.sendingCurrencyObj.blockchain);
            if(state.wallet && state.wallet.provider && state.wallet.provider.connectedBlockchain !== action.sendingCurrencyObj.blockchain) {
                wallet.connect(ProviderUtils.activeProvider());
            }
            return {...state,
                wallet: wallet,
                sendingCurrency: action.sendingCurrency,
                sendingCurrencyObj: action.sendingCurrencyObj, 
                gasLimit: {value: minLimit, error: false, helperText: ""}
            };

        case CHANGE_SENDING_AMOUNT:
            //0 Amount can be used to cancel transactions
            if(action.sendingAmount && !action.doNotTransform) {
                action.sendingAmount = (action.sendingAmount+'').replace(/[^0-9.]/g, ''); //keeps only digits
            }
            const nativeCur = GasUtils.gasUnitsForBlockchain(); let feeError = false;
            if (state.sendingCurrency === nativeCur) {//Native currency
                error = getNativeAmountError(action.sendingAmount, state.gasFee.value, state.sendingCurrencyObj, state.balance);
                newState = {...state, sendingAmount: {value: action.sendingAmount.toString(), error: !!error, helperText: errorToMessage(error)}};
            } else if(state.sendingTrxType === TRX_TYPE_SAFE_TRANSFER_FROM) {
                error = getAmountError(1, state.sendingCurrencyObj, state.balance);
                feeError = getFeeError(state.gasFee.value, state.sendingCurrencyObj, nativeCur, state.balance);
                newState = {...state , sendingAmount: {value: action.sendingAmount.toString(), error: !!error, helperText: errorToMessage(error)},
                    gasFee: {...state.gasFee, error: !!feeError, helperText: errorToMessage(feeError)}};
            } else if(state.sendingTrxType === TRX_TYPE_NFT_MULTI_TRANSFER) {
                feeError = getFeeError(
                    state.gasFee.value,
                    state.sendingCurrencyObj,
                    nativeCur,
                    state.balance
                );
                newState = {
                    ...state ,
                    sendingAmount: {
                        value: action.sendingAmount.toString(),
                        error: false,
                        helperText: ""
                    },
                    gasFee: {
                        ...state.gasFee,
                        error: !!feeError,
                        helperText: errorToMessage(feeError)
                    }
                };
            } else {// Non-native currency
                error = getAmountError(action.sendingAmount, state.sendingCurrencyObj, state.balance);
                feeError = getFeeError(state.gasFee.value, state.sendingCurrencyObj, nativeCur, state.balance);
                newState = {...state, sendingAmount: {value: action.sendingAmount.toString(), error: !!error, helperText: errorToMessage(error)},
                    gasFee: {...state.gasFee, error: !!feeError, helperText: errorToMessage(feeError)}
                };
            }
            return {...newState, submitEnabled: isReadyToSubmit(newState)};

        case CHANGE_SENDING_ADDRESS:
            const chainLabel = ProviderUtils.activeBlockchainName();
            error = !ValidationUtil.isValid(action.sendingAddress, null, value => EtherUtil.isValidAddress(value));
            newState = {...state, sendingAddress: {value: action.sendingAddress.toString(), error, helperText: error ? `Invalid ${chainLabel} Address` : ''}};
            return {...newState, submitEnabled: isReadyToSubmit(newState)};

        case CHANGE_SENDING_TRX_TYPE:
            return {...state, sendingTrxType: action.sendingTrxType };

        case CHANGE_SENDING_TOKENID:
            return {...state, sendingTokenId: { value: action.sendingTokenId, error: false, helperText: "" }};

        case CHANGE_FEE:
            if (state.sendingCurrency === action.feeCurrency) {//We need to make sure that there's enough money to pay amount + fee
                error = getNativeAmountError(state.sendingAmount.value, action.fee, state.sendingCurrencyObj, state.balance);
                newState = {...state, sendingAmount: {...state.sendingAmount, error: !!error, helperText: errorToMessage(error)},
                    gasFee: {value: action.fee.toString(), error: !!error, helperText: errorToMessage(error)}
                };
            } else if(state.sendingTrxType === TRX_TYPE_SAFE_TRANSFER_FROM) {
                error = getAmountError(1, state.sendingCurrencyObj, state.balance);
                if(!error) {
                    error = getFeeError(action.fee, state.sendingCurrencyObj, action.feeCurrency, state.balance);
                }
                newState = {...state, sendingAmount: {...state.sendingAmount, error: !!error, helperText: errorToMessage(error)},
                    gasFee: { value: action.fee, error: !!error, helperText: errorToMessage(error)}
                };
            }else if(state.sendingTrxType === TRX_TYPE_NFT_MULTI_TRANSFER) {
                error = getFeeError(
                    action.fee,
                    state.sendingCurrencyObj,
                    action.feeCurrency,
                    state.balance
                );
                newState = {
                    ...state,
                    sendingAmount: {
                        ...state.sendingAmount,
                        error: !!error,
                        helperText: errorToMessage(error)
                    },
                    gasFee: {
                        value: action.fee,
                        error: !!error,
                        helperText: errorToMessage(error)
                    }
                };
            } else {//We need to check amount against balance and fee against fee balance
                error = getAmountError(state.sendingAmount.value, state.sendingCurrencyObj, state.balance);
                if(!error) {
                    error = getFeeError(action.fee, state.sendingCurrencyObj, action.feeCurrency, state.balance);
                }
                newState = {...state, sendingAmount: {...state.sendingAmount, error: !!error, helperText: errorToMessage(error)},
                    gasFee: { value: action.fee, error: !!error, helperText: errorToMessage(error)}
                };
            }
            return {...newState, submitEnabled: isReadyToSubmit(newState)};

        case NFT_LOAD_ERROR:
            return {...state, nftError: action.error, isNftInventoryLoading: false };

        case CLEAR_TRANSACTION:
            return {
                ...state,
                sendingAmount: {...initialState.sendingAmount},
                sendingAddress: {...initialState.sendingAddress},
                sendingTrxType: initialState.sendingTrxType,
                submitEnabled: initialState.submitEnabled,
            };

        default:
            return state;
    }
};