import React from "react";
import { compose } from "redux";
import { Route, Switch, withRouter } from "react-router-dom";

import {
    ROUTE_COMING_SOON,
    ROUTE_COOKIES_POLICY,
    ROUTE_CREATE_WALLET,
    ROUTE_EDIT_ACCOUNT,
    ROUTE_EDIT_PASSWORD,
    ROUTE_INDEX,
    ROUTE_PRIVATE_POLICY,
    ROUTE_STAKE_DASH,
    ROUTE_SEND_DEFN,
    ROUTE_SEND_NFT_DEFN,
    ROUTE_STAKE_DEFN,
    ROUTE_UNSTAKE_DEFN,
    ROUTE_SHOW_ADDRESS,
    ROUTE_SHOW_MNEMONIC,
    ROUTE_SIGNIN,
    ROUTE_SIGNIN_CLEAR_DATA,
    ROUTE_SIGNUP,
    ROUTE_SUCCESS_REGISTRATION,
    ROUTE_TRANSACTION_DEFN,
    ROUTE_WALLET_ASSETS_DEFN,
    ROUTE_TRANSACTION_HISTORY_DEFN,
    ROUTE_SCANNER,
    ROUTE_E_WALLET_METHODS_DEFN,
    ROUTE_E_WALLET_BANK_DEPOSIT_DEFN,
    ROUTE_E_WALLET_BANK_WITHDRAW_DEFN,
    ROUTE_BIOMETRICS_ACTIVATE,
    ROUTE_IMPORT_WALLET,
    ROUTE_IMPORT_WALLET_SCANNER,
    ROUTE_RESUME,
    ROUTE_NEW_ACCOUNT,
    ROUTE_BUY_TOKEN_DEFN,
    ROUTE_BUY_TOKEN_STATUS_DEFN,
    ROUTE_CASH_TOKEN_DEFN,
    ROUTE_INVEST_DEFN,
    ROUTE_NX_ACTIVATE,
    ROUTE_NX_WIRETRANSFER_DEFN,
    ROUTE_NX_CASHTRANSFER_DEFN,
    ROUTE_NX_PAYMENT_DEFN,
    ROUTE_NX_PAYMENT_BY_ADDRESS_DEFN,
    ROUTE_HOME_SCANNER,
    ROUTE_WALLET_CONNECT,
    ROUTE_NX_TRANSACTION_HISTORY,
    ROUTE_NX_TRANSACTION_HISTORY_DEFN,
    ROUTE_CRYPTO_SWAP_DEFN,
    ROUTE_CRYPTO_SWAP_HISTORY_DEFN,
    ROUTE_NFT_INVENTORY_DEFN,
    ROUTE_RESET_PASSWORD,
    ROUTE_RECOVER_PASSWORD,
    ROUTE_SWAP_DEFN,
    ROUTE_ADDRESS_BOOK,
} from "./constants/routes";

import Home from "./views/Home";
import SignUp from "./views/SignUp";
import SignIn from "./views/SignIn";
import SignInClearData from "./views/SignInClearData";
import CreateWallet from "./views/CreateWallet";
import SuccessRegistration from "./views/SuccessRegistration";
import ComingSoon from "./views/ComingSoon";
import MyAssets from "./views/MyAssets";
import StakingDashboard from "./views/StakingDashboard";
import UnStakeTokens from "./components/new-approach/unstaking";
import TransactionHistory from "./views/TransactionHistory";
import EditAccount from "./views/EditAccount";
import EditPassword from "./views/EditPassword";
import ShowAddress from "./views/ShowAddress";
import TransactionInfo from "./views/TransactionInfo";
import SendMoney from "./views/WalletActions/SendMoney";
import StakeTokensAction from "./views/WalletActions/StakeTokens";
import ShowMnemonic from "./views/ShowMnemonic";
import PrivacyPolicy from "./views/PrivacyPolicy";
import CookiePolicy from "./views/CookiePolicy";
import EWalletMethods from "./views/EWalletMethods";
import WireTransferDeposit from "./views/EWalletMethods/WireTransferDeposit";
import WireTransferWithdraw from "./views/EWalletMethods/WireTransferWithdraw";
import BiometricsEnable from "./views/BiometricsEnable";
import ImportWallet from "./views/ImportWallet";
import Resume from "./views/Resume";
import NewAccountSelection from "./views/NewAccountSelection";
import AddressQRScanner from "./components/QRScanner/AddressQRScanner";
import IodOTPScanner from "./components/QRScanner/IodOTPScanner";
import BuyTokens from "./views/WalletActions/BuyTokens";
import CashTransaction from "./views/CashTransaction";
import Invest from "./views/Invest";
import NXActivate from "./views/In4x/NXActivate";
import NXPaymentRequest from "./views/In4x/NXPaymentRequest";
import HomeScanner from "./views/In4x/HomeScanner";
import WalletConnectView from "./wallet-views/wallet-connect";
import In4xWiretransferTransaction from "./views/In4x/In4xWiretransferTransaction";
import In4xCashtransferTransaction from "./views/In4x/In4xCashtransferTransaction";
import BuyTokensStatus from "./views/BuyTokensStatus"
import In4xTransactionHistory from "./views/In4x/In4xTransactionHistory";
import CryptoSwap from "src/wallet-views/crypto-swap";
import TokensSwap from "./wallet-views/tokens-swap";
import In4xSwapTransaction from "./views/In4x/In4xSwapTransaction";
import NftInventory from "./wallet-views/nft-inventory";
import SendNft from "./wallet-views/send-nft";
import EWalletAssetBalance from "./components/Balances/EWalletAssetBalance";
import ResetPassword from "./wallet-views/recover-password/reset";
import RecoverPassword from "./wallet-views/recover-password/recovery";
import AddressBook from "src/wallet-views/address-book";

const AppRouter = (props) => {
    return (
        <Switch>
            <Route exact path={ROUTE_INDEX} component={Home}/>
            <Route exact path={ROUTE_SIGNUP} component={SignUp}/>
            <Route exact path={ROUTE_SIGNIN} component={SignIn}/>
            <Route exact path={ROUTE_SIGNIN_CLEAR_DATA} component={SignInClearData}/>
            <Route exact path={ROUTE_CREATE_WALLET} component={CreateWallet}/>
            <Route exact path={ROUTE_SUCCESS_REGISTRATION} component={SuccessRegistration}/>
            <Route exact path={ROUTE_PRIVATE_POLICY} component={PrivacyPolicy}/>
            <Route exact path={ROUTE_COOKIES_POLICY} component={CookiePolicy}/>
            <Route exact path={ROUTE_COMING_SOON} component={ComingSoon}/>
            <Route exact path={ROUTE_WALLET_ASSETS_DEFN} component={MyAssets}/>
            <Route exact path={ROUTE_TRANSACTION_HISTORY_DEFN} component={TransactionHistory}/>
            <Route exact path={ROUTE_EDIT_ACCOUNT} component={EditAccount}/>
            <Route exact path={ROUTE_EDIT_PASSWORD} component={EditPassword}/>
            <Route exact path={ROUTE_SHOW_ADDRESS} component={ShowAddress}/>
            <Route exact path={ROUTE_TRANSACTION_DEFN} component={TransactionInfo}/>
            <Route exact path={ROUTE_STAKE_DASH} component={StakingDashboard} />
            <Route exact path={ROUTE_SEND_DEFN} component={SendMoney}/>
            <Route exact path={ROUTE_SEND_NFT_DEFN} component={SendNft} />
            <Route exact path={ROUTE_STAKE_DEFN} component={StakeTokensAction}/>
            <Route exact path={ROUTE_UNSTAKE_DEFN} component={UnStakeTokens}/>
            <Route exact path={ROUTE_BUY_TOKEN_DEFN} component={BuyTokens}/>
            <Route exact path={ROUTE_CASH_TOKEN_DEFN} component={CashTransaction}/>
            <Route exact path={ROUTE_SCANNER} component={AddressQRScanner}/>
            <Route exact path={ROUTE_E_WALLET_METHODS_DEFN} component={EWalletMethods}/>
            <Route exact path={ROUTE_E_WALLET_BANK_DEPOSIT_DEFN} component={WireTransferDeposit}/>
            <Route exact path={ROUTE_E_WALLET_BANK_WITHDRAW_DEFN} component={WireTransferWithdraw}/>
            <Route exact path={ROUTE_BIOMETRICS_ACTIVATE} component={BiometricsEnable}/>
            <Route exact path={ROUTE_RESUME} component={Resume}/>
            <Route exact path={ROUTE_IMPORT_WALLET} component={ImportWallet}/>
            <Route exact path={ROUTE_IMPORT_WALLET_SCANNER} component={IodOTPScanner}/>
            <Route exact path={ROUTE_NEW_ACCOUNT} component={NewAccountSelection}/>
            <Route exact path={ROUTE_SHOW_MNEMONIC}
                   render={routeProps => (<ShowMnemonic {...routeProps} mnemonic={props.decryptedMnemonic}/>)}
            />
            <Route exact path={ROUTE_INVEST_DEFN} component={Invest}/>
            <Route exact path={ROUTE_NX_ACTIVATE} component={NXActivate}/>
            <Route exact path={ROUTE_NX_WIRETRANSFER_DEFN} component={In4xWiretransferTransaction}/>
            <Route exact path={ROUTE_NX_CASHTRANSFER_DEFN} component={In4xCashtransferTransaction}/>
            <Route exact path={ROUTE_BUY_TOKEN_STATUS_DEFN} component={BuyTokensStatus}/>
            <Route exact path={ROUTE_NX_PAYMENT_DEFN} component={NXPaymentRequest}/>
            <Route exact path={ROUTE_NX_PAYMENT_BY_ADDRESS_DEFN} component={NXPaymentRequest}/>
            <Route exact path={ROUTE_HOME_SCANNER} component={HomeScanner}/>
            <Route exact path={ROUTE_WALLET_CONNECT} component={WalletConnectView} />
            <Route exact path={ROUTE_NX_TRANSACTION_HISTORY} component={EWalletAssetBalance}/>
            <Route exact path={ROUTE_NX_TRANSACTION_HISTORY_DEFN} component={In4xTransactionHistory}/>
            <Route exact path={ROUTE_CRYPTO_SWAP_DEFN} component={CryptoSwap}/>
            <Route exact path={ROUTE_CRYPTO_SWAP_HISTORY_DEFN} component={In4xSwapTransaction}/>
            <Route exact path={ROUTE_SWAP_DEFN} component={TokensSwap}/>
            <Route exact path={ROUTE_NFT_INVENTORY_DEFN} component={NftInventory}/>
            <Route exact path={ROUTE_RESET_PASSWORD} component={ResetPassword}/>
            <Route exact path={ROUTE_RECOVER_PASSWORD} component={RecoverPassword}/>
            <Route exact path={ROUTE_ADDRESS_BOOK} component={AddressBook}/>
        </Switch>
    );
}

export default compose(withRouter)(AppRouter);