import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change, getFormValues } from 'redux-form'

import { Button, Typography, CircularProgress } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';

import NXCustomInput from '../NXField';
import AmountField from "../AmountField";
import ExchangeCurrencyField from '../ExchangeCurrencyField';

import { IN4X_ACTION_DEPOSIT } from '../../../constants/in4xConstants';
import { feeForAmount, minLimit, maxLimit } from '../../../utils/in4xUtils';
import { exRateIdxKey } from "../../../utils/in4xUtils";

import { FORM_BUY_TOKENS, getBuyTokensValues } from '../../../ducks/FormData';

class BuyTokensForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		    catchErr: null,
		    minAmount: 0,
		    maxAmount: 0
		};
	}

	componentDidMount = () => {
		const { limits, method, quote, base, initialValues } = this.props;
		if(limits && Object.keys(limits).length > 0 && method && this.state.maxAmount === 0) {
			this.setState({
				minAmount: minLimit(limits, IN4X_ACTION_DEPOSIT, method, quote), 
				maxAmount: maxLimit(limits, IN4X_ACTION_DEPOSIT, method, quote)
			});
		}
		this.props.dispatch(change(FORM_BUY_TOKENS, 'payCur', base?.toUpperCase() || 'USD'));
		this.props.dispatch(change(FORM_BUY_TOKENS, 'recCur', quote?.toUpperCase() || 'IOWN'));

		const receiveAmount = this.getReceiveAmount(initialValues?.amount);
		this.props.dispatch(change(FORM_BUY_TOKENS, 'receiveAmount', Number(receiveAmount)));
	}

	changedAmount = (amount) => {
		const { fees, method, quote } = this.props;
		amount = Number(amount);
		if(amount <= 0) {
			this.props.dispatch(change(FORM_BUY_TOKENS, 'fee', 0));
			this.props.dispatch(change(FORM_BUY_TOKENS, 'total', 0));
			return;
		}
		if(fees && method) {
			const feeAmount = feeForAmount(fees, IN4X_ACTION_DEPOSIT, method, quote, amount);
			if(feeAmount >= 0) {
				this.props.dispatch(change(FORM_BUY_TOKENS, 'fee', feeAmount));
				this.props.dispatch(change(FORM_BUY_TOKENS, 'total', (amount + feeAmount)));
			}
		} else {
			this.props.dispatch(change(FORM_BUY_TOKENS, 'fee', 0));
			this.props.dispatch(change(FORM_BUY_TOKENS, 'total', (amount)));
		}
		const amountToken = this.getReceiveAmount(amount);
		this.props.dispatch(change(FORM_BUY_TOKENS, 'receiveAmount', Number(amountToken)));
	}

	getReceiveAmount(amount = 0) {
		const { base, quote, in4x } = this.props;
		const exRates = in4x?.exRates;
		const rateKey = exRateIdxKey(base, quote);
		const rate = exRates && exRates[rateKey]?.low;
		return rate	&& rate > 0 ? (amount * rate).toFixed(4) : 0;
	}

	render() {
    	const { catchErr, minAmount, maxAmount } = this.state;
    	if (catchErr) throw this.state.catchErr;
    	const { handleSubmit, handleCancel, base, quote, isLoading, hideButtons, invalid } = this.props;
    	let { formValues, showAgent } = this.props;
			if(!formValues) formValues = {}; //Findout why this is undefined on 1st load
    	return (
    		<>
				<form name={FORM_BUY_TOKENS} onSubmit={handleSubmit}>
						<Field
							name="payCur"
							component={ExchangeCurrencyField}
							type="text"
							disabled
							label="Pay"
							className="override-nx"
						/>
						<Field
							name="recCur"
							component={ExchangeCurrencyField}
							type="text"
							disabled
							label="Receive"
							className="override-nx"
						/>
						<AmountField
							minValue={minAmount}
							maxValue={maxAmount}
							onChange={this.changedAmount}
						/>
				    { isLoading && (
				    	<>
					    	<CircularProgress />
					    	<Typography color="primary" gutterBottom>Loading rate...</Typography>
				    	</>
				   	)}
				   	{ !isLoading && (
				   		<Field
				   			component={NXCustomInput}
					    	type="text" 
					    	name="receiveAmount"
					    	label="Receive (Estimate)"
					    	disabled
					    	adornment={quote.toUpperCase()}
					    />
				   	)}
				   	{ !isLoading && formValues.fee > 0 && (
				   		<Field
				   			component={NXCustomInput} 
				   			variant="outlined"
					    	type="text" 
					    	name="fee"
					    	label="Fee" 
					    	disabled
					    	adornment={base.toUpperCase()}
					    />
				   	)}
				   	{ !isLoading && formValues.total > 0 && (
				   		<Field
				   			component={NXCustomInput} 
					    	type="text" 
					    	variant="outlined"
					    	name="total"
					    	label="Total (To be Transferred)" 
					    	disabled
					    	adornment={base.toUpperCase()}
					    />
				   	)}
			   		{ showAgent && (
			   			<>
			   				<Typography variant="subtitle2" align="left" gutterBottom className="color-nx spaced-top">Do you have an agent?</Typography>
				   			<Field
					   			component={NXCustomInput} 
						    	type="text" 
						    	variant="outlined"
						    	name="agentCode"
						    	label="Agent Code" 
						    />
						  </>
					  )}
				   	{ !isLoading && quote === 'iown' && (
				   		<Alert severity="warning" style={{textAlign: 'left', marginTop: '1.5rem'}}>Actual token amount will be calculated (from iOWN Rate) at exchange time</Alert>
				   	)}
				    { !hideButtons && (
				    	<>
					    	<Button className="bg-nx spaced-top" variant="contained" fullWidth onClick={handleSubmit} disabled={isLoading || invalid}>Submit</Button>
					    	<Button variant="contained" fullWidth onClick={handleCancel} className="spaced-top">Cancel</Button>
				    	</>
				    )}
				</form>
			</>
		)
	}
}

BuyTokensForm = reduxForm({
    form: FORM_BUY_TOKENS,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: true
})(BuyTokensForm);

BuyTokensForm = connect(
  state => ({
	in4x: state.in4x,
    initialValues: state.FormData.BuyTokens,
    formValues: getFormValues(FORM_BUY_TOKENS)(state), //linked here to hide fee + total if they're empty
  }),
  { load: getBuyTokensValues }               // bind FormData values
)(BuyTokensForm);

export default BuyTokensForm;