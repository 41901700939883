import React from "react";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Mnemonic from "../../components/mnemonic/Mnemonic";
import IownIcon from "src/components/IownIcon";
import CopyToClipboardButton from 'src/components/CopyToClipboardButton';

import PriorityHigh from '@material-ui/icons/PriorityHigh';
import { KEY_CFG_MNEMONIC_SHOWN } from "../../constants/storageKeys";
import StorageUtils from "../../utils/storageUtils";
import WalletUtils from "src/utils/blockchain/WalletUtils";

class ShowMnemonic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      catchErr: null,
      copyPk: WalletUtils.pkFromMnemonic(props.mnemonic)
    }
    StorageUtils.setItem(KEY_CFG_MNEMONIC_SHOWN, true);
  }

  goBack = () => {
    try {
      this.props.history.goBack();
    } catch (err) {
      this.setState({ catchErr: err });
    }
  };

  render() {
    if (this.state.catchErr) throw this.state.catchErr;

    return (
      <Container component="main" maxWidth="sm" className="spaced-top">
        <Grid container spacing={1} justifyContent="center" alignItems="center" direction="column">
          <Grid item xs={12}>
            <IownIcon />
            <Typography variant="h5" align="center" gutterBottom color="primary">Your Wallet Secret Phrase</Typography>
            <Typography variant="subtitle2" align="left" gutterBottom style={{ 'display': 'flex' }}>
              <PriorityHigh color="error" fontSize="small" /><div>&nbsp;Important: make sure you write this on a
                piece of paper, secure it and never share it with anyone, <span style={{ color: 'red', 'fontWeight': 'bold' }}>losing it will cause the loss of all your funds</span>!</div>
            </Typography>
            {this.props.mnemonic && (
              <Mnemonic mnemonic={this.props.mnemonic.split(" ")} />
            )}
            <Typography variant="subtitle2" align="left" gutterBottom style={{ 'display': 'flex', marginTop: '20px' }}>
              <PriorityHigh color="error" fontSize="small" /><div>&nbsp;Important: <span style={{ color: 'red', 'fontWeight': 'bold' }}>If you lose these words we can't recover them for you</span>!</div>
            </Typography>
            <CopyToClipboardButton text={this.state.copyPk} label="Copy Private Key to clipboard" />
            <Button
              color="primary" className="spaced__btn" fullWidth variant="contained"
              onClick={this.goBack}
            >
              Back
            </Button>
          </Grid>
        </Grid>  
      </Container>
    );
  }
}

export default ShowMnemonic;