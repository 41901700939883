import React from "react";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Grid } from "@material-ui/core";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import HelpIcon from '@material-ui/icons/Help';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Modal from "../../components/Modal";

const WireTransferConfirmationModal = (props) => {

	return (
		<Modal
      open={props.openOn}
      onClose={props.onClose}
      modalBody={
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <List component="nav"
            className="forced-nx"
              aria-labelledby="nested-list-subheader"
              subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Confirmation
              </ListSubheader>
            } disablePadding>
            <ListItem>
              <ListItem button disableGutters onClick={() => { props.onClose('success'); }}>
                <ListItemIcon>
                  <AccountBalanceIcon className="color-nx"/>
                </ListItemIcon>
                <ListItemText primary="I created the transfer in my bank" className="color-nx"/>
              </ListItem>
            </ListItem>
            <ListItem>
              <ListItem button disableGutters onClick={() => { props.onClose('help'); }}>
                <ListItemIcon>
                  <HelpIcon className="color-nx"/>
                </ListItemIcon>
                <ListItemText primary="I need help" className="color-nx" />
              </ListItem>
            </ListItem>
            <ListItem>
              <ListItem button disableGutters onClick={() => { props.onClose('cancel'); }}>
                <ListItemIcon>
                  <ArrowBackIcon className="color-nx"/>
                </ListItemIcon>
                <ListItemText primary="Cancel" className="color-nx" />
              </ListItem>
            </ListItem>
          </List>
          </Grid>
        </Grid>
      }
    ><>{""}</></Modal>
	);
}

export default WireTransferConfirmationModal;