/**
 * @name StorageUtils
 * @description A storage encapsulating class that allows chaning storage type and handling errors and alterations if needed before returning values
 */
import FileStorage from "./fileStorage";

export default class StorageUtils {

	static getItem(key) {
		return StorageUtils._getKey(key);
	}

	static setItem(key, value) {
		return StorageUtils._setKey(key, value);
	}

	static removeItem(key) {
		return StorageUtils._removeKey(key);
	}

	static clear() {
		return StorageUtils._clear();
	}

	static _getKey(key) {
		try {
			return localStorage.getItem(key);
		} catch(e) {
			alert('ERROR READING '+key);
		}
	}

	static _setKey(key, value) {
		try {
			return localStorage.setItem(key, value);
		} catch(e) {
			alert('ERROR SET '+key);
		}	
	}

	static _removeKey(key) {
		try {
			FileStorage.removeStorageKey(key).then();
			return localStorage.removeItem(key);
		} catch(e) {
			alert('ERROR REMOVE '+key);
		}
	}

	static _clear() {
		try {
			FileStorage.clearStorage().then();
			return localStorage.clear();
		} catch(e) {
			alert('ERROR CLEAR');
		}
	}
}