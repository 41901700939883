import React, { useContext } from "react";
import { UserAssetsContext } from "src/contexts/user-assets";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import NewSignIn from './NewSignIn';
import OldSignIn from './OldSignIn';
import { withStyles } from "@material-ui/core";
import { KEY_ACTIVE_PK } from "src/constants/storageKeys";
import StorageUtils from "src/utils/storageUtils";
import IownIcon from "src/components/IownIcon";

const styles = theme => ({
    title: {
        fontWeight: 600,
        fontSize: "31px",
        textAlign: "center",
        marginTop: "20px",
        marginBottom: "20px",
        color: "#0082BC"
    },
})

const SignIn = (props) => {
    const isNewUser = !StorageUtils.getItem(KEY_ACTIVE_PK);
    const { updatePasswordHash } = useContext(UserAssetsContext);
    const { classes } = props;

    return (
        <Container component="main" maxWidth="sm" className="centered-grid spaced-top">
            <IownIcon />
            <Typography variant="h5" align="center" gutterBottom className={classes.title}>
                {isNewUser ? "Login" : "Unlock Your Wallet"}
            </Typography>

            {isNewUser ?
                <NewSignIn /> :
                <OldSignIn updatePasswordHash={updatePasswordHash} />
            }
        </Container>
    );
};

export default withStyles(styles)(SignIn);
