import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import TransactionFeeConfiguration from "../../../../../../src/components/TransactionFeeConfiguration";
import Button from "@material-ui/core/Button";

const checkFields = ["sendingAmount", "sendingAddress", "gasFee"];

const UnStakeConfirm = (props) => {
    const {
        stakeData = {},
        onNextClick = () => {},
        gasReady,
    } = props;

    const {
        withdrawAmount = "-",
        stakingBalance = "-",
        stakingAddress = "-",
    } = stakeData;

    const [error, setError] = useState(null);

    const onAlertCloseClick = () => setError(null);

    useEffect(() => {
        const errorField = checkFields.find(itemKey => stakeData[itemKey]?.error);
        setError(errorField);
    }, [stakeData]);

    return (
        <Grid container >
            <Grid container spacing={3}>
                {
                    !!error && (
                        <Alert
                            variant="outlined"
                            severity="error"
                            onClose={onAlertCloseClick}
                        >
                            {error?.helperText}
                        </Alert>
                    )
                }
            </Grid>
            <Grid container spacing={3}>
                <TextField
                    type="text"
                    value={Number(stakingBalance).toFixed(2)}
                    variant="outlined"
                    label="Staked Amount"
                    className="spaced-top"
                    disabled
                    fullWidth
                />
                <TextField
                    type="text"
                    value={(Number(stakingBalance)-Number(withdrawAmount)).toFixed(2)}
                    variant="outlined"
                    label="Un-Staking Fee"
                    className="spaced-top"
                    disabled
                    fullWidth
                />
                <TextField
                    type="text"
                    value={withdrawAmount}
                    variant="outlined"
                    label="Amount to Withdraw"
                    className="spaced-top"
                    disabled
                    fullWidth
                />
                <TextField
                    type="text"
                    value={stakingAddress}
                    variant="outlined"
                    label="Staking Address"
                    className="spaced-top"
                    disabled
                    fullWidth
                />

                <TransactionFeeConfiguration />

                <Button
                    color="primary"
                    className="spaced__btn"
                    fullWidth
                    disabled={!!error || !gasReady}
                    onClick={onNextClick}
                    variant="contained"
                >
                    Withdraw
                </Button>
            </Grid>
        </Grid>
    )
};

UnStakeConfirm.propTypes = {
    stakeData: PropTypes.object,
    onNextClick: PropTypes.func,
};

export default UnStakeConfirm;