/* eslint-disable no-throw-literal */
import { handleFetch } from "./fetch";
import { transformError } from "./transformErrors";
import { API_VALIDATION_EMAIL, API_VALIDATION_PHONE } from "../constants/apiRoutes";
/**
 * @name composeAsyncValidators
 * @description compose validators
 * @param {array} `validatorFns`
 * @returns {Promise<object>}
 */
const composeAsyncValidators = (validatorFns) => {
  return async (values, dispatch, props, field) => {
    let errors;
    for (const validatorFn of validatorFns) {
      try {
        await validatorFn(values, dispatch, props, field);
      } catch (err) {
        errors = Object.assign({}, errors, err);
      }
    }

    if (errors) throw errors;
  };
};

/**
 * @name emailValidate
 * @description email validation
 * @param {object} `values`
 * @returns {Promise<object>}
 */
const emailValidate = values => {
  return handleFetch(API_VALIDATION_EMAIL, "POST", {
    email: values.email,
  }).then(res => {
    if (!res.success) throw { email: transformError(res.payload) };
  }).catch((err) => {
    if (typeof err === 'string') throw { email: transformError(err) };
    else throw err;
  });
};

/**
 * @name phoneValidate
 * @description phone validation
 * @param {object} `values`
 * @returns {Promise<object>}
 */
const phoneValidate = values => {
  var phone = values.phone || "";
  return handleFetch(API_VALIDATION_PHONE, "POST", {
    phone: phone,
  }).then(res => {
    if (!res.success) throw { phone: transformError(res.payload) }
  }).catch((err) => {
    if (typeof err === 'string') throw { email: transformError(err) };
    else throw err;
  });
};

const asyncValidate = composeAsyncValidators([emailValidate, phoneValidate]);

export default asyncValidate;