import isEmail from 'validator/lib/isEmail';
import isAlpha from 'validator/lib/isAlpha';
import { RegExps } from "../constants/regExps";
import { getError } from "./transformErrors";

/**
 * @name isWeakPassword
 * @description validates password field
 * @param {string} `password` field password
 * @returns {boolean}
 */
const isWeakPassword = password => {
  return !!(RegExps.passwordLetters.test(password) ||
    !RegExps.digits.test(password) ||
    !RegExps.specialCharacters.test(password) ||
    !RegExps.minLength8.test(password) ||
    RegExps.bannedSpecialCharacters.test(password) ||
    (password.toUpperCase() === password) ||
    (password.toLowerCase() === password));
};

/**
 * @name required
 * @description check for required field
 * @param {any} `value` field password
 * @returns {string}
 */
export const required = value => (value ? undefined : getError('REQUIRED'));

export const maxLength = max => value => value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const minLength = min => value => value && value.length < min ? `Must be ${min} characters or more` : undefined;
export const minLength3 = minLength(3);
export const minLength5 = minLength(5);
export const minLength8 = minLength(8);
export const maxLength16 = maxLength(16);


/**
 * @name number
 * @description check for number input value
 * @param {any} `value`
 * @returns {string}
 */
export const number = value => value && isNaN(Number(value)) ? getError('NUMBER_FORMAT') : undefined;

/**
 * @name email
 * @description check for email input value
 * @param {any} `value`
 * @returns {string}
 */
export const email = value => value && !isEmail(value) ? getError('INVALID_EMAIL') : undefined;

/**
 * @name alpha
 * @description check for alpha input value
 * @param {any} `value`
 * @returns {string}
 */
export const alpha = value => value && !isAlpha(value) ? getError('ALPHA_FORMAT') : undefined;

// /** 
//  * @name isPhoneValid
//  * @description check phone number
//  * @param {string} `value`
//  * @returns {boolean}
// */
// const phoneUtil = phoneNumber.PhoneNumberUtil.getInstance();
// export const isPhoneValid = value => {
//     let number = null
//     try {
//         number = phoneUtil.parseAndKeepRawInput(value)
//       } catch (e) {
//         return false;
//       }
//     if (number) {
//         return phoneUtil.isValidNumber(number);
//     }
// }

export const minVal = min => value =>  min >= 0 && value && Number(value) < min ? 'Minimum is ' + min : undefined;
export const maxVal = max => value =>  max >= 0 && value && Number(value) > max ? 'Maximum is ' + max : undefined;

/**
 * @name phone
 * @description check for phone input value
 * @param {any} `value`
 * @returns {string}
 */
export const phone = value => value && (!/^[+|00]\d{10,12}$/.test(value)) ? //!isMobilePhone(value) --> doesn't match Qatari numbers
  getError('PHONE_NOT_VALID') : undefined;

/**
 * @name password
 * @description check for password input value
 * @param {any} `value`
 * @returns {string}
 */
export const password = value => value && isWeakPassword(value) ? getError('PASSWORD_FORMAT') : undefined;

/**
 * @name passwordConfirm
 * @description compare passwords fields
 * @param {any} `value`
 * @param {object} `allValues` form values
 * @returns {string}
 */
export const passwordConfirm = (value, allValues) => value && allValues.password !== value ? getError('PASSWORD_FIELD_MISMATCH') : undefined;


/**
 * @name newPasswordConfirm
 * @description compare passwords fields
 * @param {any} `value`
 * @param {object} `allValues` form values
 * @returns {string}
 */
export const newPasswordConfirm = (value, allValues) => value && allValues.password !== value ? getError('NEW_PASSWORD_FIELD_MISMATCH') : undefined;

/**
 * @name validIban
 * @description Checks IBAN against standard regex (international)
 * @param {any} `value`
 * @returns {string}
 */
export const validIban = value => {
  return value && !/^([A-Z]{2}[ -]?[0-9]{2})(?=(?:[ -]?[A-Z0-9]){9,30}$)((?:[ -]?[A-Z0-9]{3,5}){2,7})([ -]?[A-Z0-9]{1,3})?$/.test(value) ? 
    getError('IBAN_FORMAT') : undefined;
}

/**
 * @name validSwift
 * @description Checks Swift Code against standard regex (international)
 * @param {any} `value`
 * @returns {string}
 */
export const validSwift = value => {
  return value && !/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(value) ? 
    getError('SWIFT_FORMAT') : undefined;
}