/* eslint-disable no-loop-func */
import React from "react";
import { connect } from "react-redux";
import { getFormValues } from 'redux-form';
import sha512 from "js-sha512";
import { compose } from "redux";

import { withStyles, Checkbox, Grid } from "@material-ui/core";
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import PriorityHigh from '@material-ui/icons/PriorityHigh';
import FormControlLabel from '@material-ui/core/FormControlLabel'; //Along with Checkbox

import Mnemonic from "../../components/mnemonic/Mnemonic";
import CustomSnackbar from "../../components/CustomSnackbar";
import SuccessRegistration from "../SuccessRegistration";
import SAMobileStepper from "../../components/SAMobileStepper";
import IownIcon from "src/components/IownIcon";

import {
    changeMnemonicConfirm,
    enableNextMnemonicStep,
    generateMnemonic,
    handleCreateWallet,
    registerAccount,
    backupMnemonic
} from "../../ducks/signup";
import { 
    changeWallet 
} from "../../ducks/blockchain";
import { 
    ROUTE_INDEX,
    ROUTE_SIGNUP,
    ROUTE_DEFAULT_LOGGEDIN 
} from "../../constants/routes";

import WalletUtils from "../../utils/blockchain/WalletUtils";
import { encryptDES } from "../../utils/cryptoUtil";
import history from '../../history';
import { openLink } from '../../utils/linksOpenPolyfill';
import MultiAccountUtils from "../../utils/multiAccountUtils";

const styles = () => ({
    iconTitle: {
        display: 'flex',
    }
});

class CreateWallet extends React.Component {

    constructor(props) {
        super(props);

        this.props.generateMnemonic();

        this.state = {
            activeStep: 2,
            openAlert: false,
            loading: false,
            alertText: "",
            catchErr: null,
            backupConcent: false,
            backupAllowed: false,
        };

        if (!Object.keys(this.props.signup.form).length) {
            history.replace(ROUTE_SIGNUP);
        }
    }

    prevStep = () => {
        try {
          this.props.history.push(this.state.activeStep >=4 ? ROUTE_INDEX : ROUTE_SIGNUP);
        } catch (err) {
          this.setState({ catchErr: err });
        }
    }

    nextStep = () => {
        switch(this.state.activeStep) {
            case 2:
                this.showMnemonicConfirm();
                break;
            case 3:
                this.createWallet();
                break;
            default:
                this.props.history.push(this.props.signup.isActivated? ROUTE_DEFAULT_LOGGEDIN : ROUTE_INDEX);
        }
    }

    showMnemonicConfirm = () => {
        try {
            const { mnemonicConfirm } = this.props.signup;
            const enabledIndexes = [];
            let counter = 0;
            let newMnemonicConfirm;
            while (counter !== 3) {
                newMnemonicConfirm = mnemonicConfirm.map((i, j) => (Math.random() <= 0.5 && (counter !== 3)) ? (counter++ , enabledIndexes.push(j), "") : i);
            }
            this.props.enableNextMnemonicStep(newMnemonicConfirm, enabledIndexes);
            this.setState((state) => ({ activeStep: state.activeStep + 1 }));
        } catch (err) {
            this.setState({ catchErr: err });
        }
    };

    createWallet = async () => {
        try {
            const {
                mnemonic,
                mnemonicConfirm,
            } = this.props.signup;            
            const isValid = mnemonic.every((i, j) => i === mnemonicConfirm[j].trim());
            if (isValid) {
                await this.registerUser();;
                this.setState((state) => ({ activeStep: state.activeStep + 1 }));
            } else {
                this.setState({ openAlert: true, alertText: "Warning! You Typed an Incorrect Secret Phrase" })
            }
        } catch (err) {
            this.setState({ catchErr: err });
        }
    };

    registerUser = async() => {
        const {
            mnemonicRaw,
            mnemonicConfirm,
            form: {
                fullName,
                phone,
                email,
                password,
                token,
            }
        } = this.props.signup; 
        this.setState({ loading: true });
        const hashPassword = sha512(password);
        const wallet = await WalletUtils.createFromMnemonic(mnemonicRaw);
        const encryptedKey = encryptDES(wallet.privateKey, hashPassword);
        const encryptedMnemonic = encryptDES(mnemonicConfirm.join(' '), hashPassword);
        MultiAccountUtils.addAccount(email, encryptedKey, encryptedMnemonic);
        await this.props.registerAccount({
            fullName,
            phone,
            email,
            token,
            password: hashPassword,
            wallet: wallet.address
        });
        if (this.state.backupAllowed && this.state.backupConcent) {
            //Only if user concents
            await this.props.backupMnemonic({
                wallet: wallet.address,
                mnemonic: mnemonicRaw
            });
        }
        // RegisterUser(fullName, email, password, phone, wallet.address);
        this.props.changeWallet(wallet);
        this.setState({ loading: false });
        
    }

    changeBackupConcent = () => {
        this.setState({ backupConcent: !this.state.backupConcent });
    };

    handleCloseAlert = () => {
        try {
            this.setState({ openAlert: false });
        } catch (err) {
            this.setState({ catchErr: err });
        }
    };

    render() {
        if (this.state.catchErr) throw this.state.catchErr;

        const { mnemonic, mnemonicRaw, mnemonicConfirm, enabledIndexes, isActivated } = this.props.signup;
        const { openAlert, loading, alertText, activeStep, backupConcent, backupAllowed } = this.state;
        const disabledNext = (activeStep < 2 || loading || (activeStep === 4 && !isActivated));
        const backButtonText = activeStep >= 4? 'Home' : 'Cancel';

        return (
            <Container component="main" maxWidth="sm">
                {activeStep === 3 && (
                    <>
                        <IownIcon />
                        <Typography variant="h5" align="center" gutterBottom>
                            Please Confirm Your Secret Phrase:
                        </Typography>
                        {
                            <Mnemonic
                                editable
                                mnemonic={mnemonicConfirm}
                                enabledIndexes={enabledIndexes}
                                onChange={this.props.changeMnemonicConfirm}
                            />
                        }
                        { loading && (
                            <Grid item xs={12} className="centered-grid spaced-top">                     
                                <CircularProgress />
                                <Typography color="primary" align="center" gutterBottom>Creating wallet...</Typography>
                            </Grid>
                        ) }
                    </>
                )}
                { activeStep === 2 && (
                    <>
                        <IownIcon />
                        <Typography variant="h5" align="center" gutterBottom>
                            Your Wallet Secret Phrase
                        </Typography>
                        <Typography variant="subtitle2" className={this.props.classes.iconTitle} gutterBottom>
                            <PriorityHigh color="error" fontSize="small" /><div>&nbsp;Important: make sure you write this on a
                                piece of paper, secure it and never share it with anyone, <span style={{ color: 'red', 'fontWeight': 'bold' }}>losing it will cause the loss of all your funds</span>!</div>
                        </Typography>
                        {
                            mnemonicRaw ?
                                <Mnemonic mnemonic={mnemonic} />
                                :
                                <div className="loader">
                                    <CircularProgress />
                                </div>
                        }
                        {backupAllowed ? (
                            <>
                                <br />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={backupConcent}
                                            color="primary"
                                            onChange={this.changeBackupConcent}
                                        />
                                    }
                                    label={
                                        <Typography variant="subtitle2" align="center" className={this.props.classes.iconTitle} gutterBottom>
                                            Backup this secret on iOWNX KeyVault
                              </Typography>
                                    }
                                />
                                <Typography variant="subtitle2" align="center" className={this.props.classes.iconTitle}
                                    gutterBottom>
                                    <PriorityHigh color="error" fontSize="small" />&nbsp;Do this Backup ONLY if you don't know how to secure your secret/password, and want us to back it up for you, and you still have to write it down and secure it!
                                </Typography>
                                <a href={`https://www.iownx.com/key-vault`} target="_blank" rel="noopener noreferrer" onClick={(e) => openLink(e, "https://www.iownx.com/key-vault")}>
                                    <Button color="primary" fullWidth>
                                        <span className={'noUpperText'}>About KeyVault Backup</span>
                                    </Button>
                                </a>
                            </>) : ''}
                        <Typography variant="subtitle2" align="center" className={this.props.classes.iconTitle} gutterBottom style={{ marginTop: '20px' }}>
                            <PriorityHigh color="error" fontSize="small" />
                            <div>You will be asked to verify this next</div>
                        </Typography>
                        <Typography variant="subtitle2" className={this.props.classes.iconTitle} gutterBottom >
                            <PriorityHigh color="error" fontSize="small" />
                            <div>You wont be able to come back to this screen (hitting back will reset you signup process)</div>
                        </Typography>
                    </>
                )}
                { activeStep === 4 && (
                    <SuccessRegistration />
                )}

                <CustomSnackbar
                    anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                    variant="error"
                    open={openAlert}
                    message={alertText}
                    onClose={this.handleCloseAlert}
                />
                <SAMobileStepper maxSteps={5} activeStep={this.state.activeStep} onNext={this.nextStep} onPrev={this.prevStep} disabledNext={disabledNext} backButtonText={backButtonText} />
            </Container>
        );
    }
};

const mapState2props = state => ({
    signup: state.signup,
    startFormValues: getFormValues('signup-start')(state),
    endFormValues: getFormValues('signup-end')(state),
});

const mapDispatch2props = {
    generateMnemonic,
    enableNextMnemonicStep,
    changeMnemonicConfirm,
    handleCreateWallet,
    registerAccount,
    changeWallet,
    backupMnemonic
};

export default compose(
    withStyles(styles),
    connect(mapState2props, mapDispatch2props)
)(CreateWallet);