import {CURRENCY_ETH, CURRENCY_BNB} from './currencies';

export const DEFAULT_LIMIT_NATIVE = 21000;
export const DEFAULT_LIMIT_TOKEN = 60000;
export const DEFAULT_LIMIT_MARGIN = 0; //1000 Extra margin on top of estimate when setting limit
export const DEFAULT_FEE_NATIVE = 0.00021;
export const DEFAULT_FEE_TOKEN = 0.0006;

export const getDefaultLimitForCurrency = currency => {
	switch(currency) {
		case CURRENCY_ETH:
		case CURRENCY_BNB:
			return DEFAULT_LIMIT_NATIVE;
		default:
			return DEFAULT_LIMIT_TOKEN;
	}
}

export const getDefaultFeeForCurrency = currency => {
	switch(currency) {
		case CURRENCY_ETH:
		case CURRENCY_BNB:
			return DEFAULT_FEE_NATIVE;
		default:
			return DEFAULT_FEE_TOKEN;
	}
}