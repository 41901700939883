const STORAGE_FILE_NAME = "storage.json";
let storageFile;

let storageDataObject = {};

export default class FileStorage {

    static async setStorageKey(key, value) {
        storageDataObject[key] = value;
        await FileStorage.writeStorageFile(storageDataObject);
    }

    static async removeStorageKey(key) {
        delete storageDataObject[key];
        await FileStorage.writeStorageFile(storageDataObject);
    }

    static async clearStorage() {
        storageDataObject = {};
        await FileStorage.writeStorageFile(storageDataObject);
    }

    static async restoreSessionFromFile() {
        const results = await FileStorage.readStorageFile();
        if (results) {
            Object.entries(results).forEach(([key, value]) => {
                if (!localStorage.getItem(key) && value) {
                    localStorage.setItem(key, value);
                }
            });
            storageDataObject = results;
        }
    }

    static async storeSessionToFile() {
        storageDataObject = await FileStorage.writeStorageFile(localStorage);
    }

    static async readStorageFile() {
        const fileEntry = await FileStorage._initStorageFile();
        if (fileEntry) {
            return new Promise(resolve => {
                fileEntry.file((file) => {
                    const reader = new FileReader();
                    reader.onloadend = function() {
                        if (this.result) {
                            try {
                                resolve(JSON.parse(this.result));
                            } catch (e) {
                                resolve({});
                            }
                        } else {
                            resolve({});
                        }
                    };
                    reader.readAsText(file);
                }, (error) => {
                    resolve({});
                });
            });
        } else {
            return Promise.resolve({});
        }
    }

    static async writeStorageFile(dataObj) {
        const fileEntry = await FileStorage._initStorageFile();
        if (fileEntry) {
            return new Promise(resolve => {
                fileEntry?.createWriter(function (fileWriter) {
                    fileWriter.onwriteend = function() {
                        resolve(dataObj);
                    };
                    fileWriter.onerror = function (e) {
                        resolve();
                    };

                    const fileData = new Blob([JSON.stringify(dataObj, null, 2)], {type : 'application/json'});
                    fileWriter.write(fileData);
                });
            });
        } else {
            return Promise.resolve();
        }
    }

    static _initStorageFile() {
        if (storageFile) {
            return Promise.resolve(storageFile);
        }
        const cordova = window['cordova'];
        if (cordova) {
            return new Promise(resolve => {
                const dataDirectory = cordova?.file?.dataDirectory;
                const resolveLocalFileSystemURL = window.resolveLocalFileSystemURL;

                if (resolveLocalFileSystemURL && dataDirectory) {

                    resolveLocalFileSystemURL(dataDirectory, (entry) => {
                        if (entry) {
                            entry?.getFile(STORAGE_FILE_NAME, { create:true }, (file) => {
                                if (file) {
                                    storageFile = file;
                                    resolve(file);
                                } else {
                                    resolve();
                                }
                            });
                        } else {
                            resolve();
                        }
                    }, (error) => {
                        resolve();
                    });
                } else {
                    resolve();
                }
            });
        } else {
           return Promise.resolve();
        }
    }

}
