
const initialState = {
	BuyTokens: {
		amount: '',
		fee: '',
		total: '',
		receiveAmount: 0
	},
	SellTokens: {
		amount: '',
		fee: '',
		total: '',
		receiveAmount: 0
	},
	TopupEth: {
		isAdd: false,
		prevAmount: 0,
		amount: 100,
		fee: 0,
		total: 0,
		receiveAmount: 0
	},
	WireTransfer: {
		amountBase: 0,
		amountQuote: 0,
		amountEth: 0,
		payCur: '',
		recCur: 'iown',
		eth: 0,
		direction: 'in',
		total: 0,
		feeAmount: 0,
		feeEth: 0,
		ref: '',
	},
	SenderBank: {},
	MakePayment: {
		partner: '',
		requestId: '',
		amount: '',
		fee: 0,
		total: 0,
	},
	StakeTokens: {}
};

export const FORM_CRYPTO_SWAP = 'crypto-swap-form';
export const FORM_BUY_TOKENS = 'buy-tokens-form';
export const FORM_TOPUP_GAS_TOKENS = 'topup-eth-form';
export const FORM_WIRETRANSFER = "wire-transfer-form";
export const FROM_STAKE_TOKENS = "staking-form";

const FORM_GET = 'FORM_GET';
const FORM_SET_BUY = 'FORM_SET_BUY';
const FORM_SET_SELL = 'FORM_SET_SELL';
const FORM_SET_TOPUP = 'FORM_SET_TOPUP';
const FORM_SET_WIRE = 'FORM_SET_WIRE';
const FORM_SET_SENDER_BANK = 'FORM_SET_SENDER_BANK';
const FORM_SET_PAYMENT = 'FORM_SET_PAYMENT';
const FORM_SET_STAKING = 'FORM_SET_STAKING';
const RESET_ALL = 'RESET_ALL';

export const getBuyTokensValues = () => ({ type: FORM_GET, formType: 'BuyTokens' });
export const setBuyTokensValues = (values) => ({ type: FORM_SET_BUY, values });

export const getSellTokensValues = () => ({ type: FORM_GET, formType: 'SellTokens' });
export const setSellTokensValues = (values) => ({ type: FORM_SET_SELL, values });

export const getTopupEthValues = () => ({ type: FORM_GET, formType: 'TopupEth' });
export const setTopupEthValues = (values) => ({ type: FORM_SET_TOPUP, values });

export const getWireTransferValues = () => ({ type: FORM_GET, formType: 'WireTransfer' });
export const setWireTransferValues = (values) => ({ type: FORM_SET_WIRE, values });

export const getMakePaymentValues = () => ({ type: FORM_GET, formType: 'MakePayment' });
export const setMakePaymentValues = (values) => ({ type: FORM_SET_PAYMENT, values });

export const getSenderBankValues = () => ({ type: FORM_GET, formType: 'SenderBank' });
export const setSenderBankValues = (values) => ({ type: FORM_SET_SENDER_BANK, values });

export const getStakingValues = () => ({ type: FORM_GET, formType: 'StakeTokens' });
export const setStakingValues = (values) => ({ type: FORM_SET_STAKING, values });

export const resetForms = () => ({type: RESET_ALL});

export const FormData = (state = initialState, action) => {
    switch (action.type) {
    	case FORM_GET:
			return state[action.formType];
    	case FORM_SET_BUY:
			return {...state, BuyTokens: {...state.BuyTokens, ...action.values}};
		case FORM_SET_SELL:
			return {...state, SellTokens: {...state.SellTokens, ...action.values}};
		case FORM_SET_TOPUP:
			return {...state, TopupEth: {...state.TopupEth, ...action.values}};
		case FORM_SET_WIRE:
			return {...state, WireTransfer: {...state.WireTransfer, ...action.values}};
		case FORM_SET_PAYMENT:
			return {...state, MakePayment: {...state.MakePayment, ...action.values}};
		case FORM_SET_SENDER_BANK:
			return {...state, SenderBank: {...state.SenderBank, ...action.values}};
		case FORM_SET_STAKING:
			return {...state, StakeTokens: {...state.StakeTokens, ...action.values}};
		case RESET_ALL:
			return {...initialState};
    	default:
            return state;
   	}
}