import React from "react";

import { Grid, Button, Typography } from "@material-ui/core";
import Modal from "../../components/Modal";
import Alert from '@material-ui/lab/Alert';
import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import { ShowUserSnap, IsUserSnapLoaded } from "../../UserSnap";

const ErrorsModal = (props) => {
  const [showInfo, setShowInfo] = React.useState(null);
  return(
    <Modal
      open={props.openOn}
      onClose={props.onClose}
      modalBody={(
        <Grid
              container
              direction="column"
              justifyContent="center"
              spacing={3}
          >
              <Grid item>
                <img className="header__logo" src="/img/logo.svg" alt="Logo" />
              </Grid>
              <Grid item>
                <Alert variant="filled" severity="error">
                  An Error Has Occured!
                </Alert>
              </Grid>
              { props.error && (
                  <Grid item>
                    <Typography variant="h6">
                      {props.error.toString()}
                    </Typography>
                  </Grid>
              )}
              { props.info && (
                <FormGroup row>
                  <FormControlLabel className="small-font wrap-padding"
                    control={<Checkbox checked={showInfo} onChange={() => { setShowInfo(!showInfo) }} name="Show Details" />}
                    label="Show Details"
                  />
                </FormGroup>
              )}
              { props.info && showInfo && (
                  <Grid item>
                      <Typography variant="subtitle2">
                          Where it occurred:
                      </Typography>
                      <Typography variant="body2">
                          {props.info.componentStack}
                      </Typography>
                  </Grid>
              )}
              { IsUserSnapLoaded() && (<Grid item>
                  <Button
                      fullWidth
                      variant="contained"
                      onClick={ShowUserSnap}
                  >
                      Report
                  </Button>
              </Grid>)}
              <Grid item>
                  <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={props.onClose}
                  >
                      Back
                  </Button>
              </Grid>              
          </Grid>
      )}
    ><>{""}</></Modal>
  );
 }

 export default ErrorsModal;