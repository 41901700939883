import { ValidationUtil } from "../utils/validationUtil";
import { ROUTE_CREATE_WALLET } from "../constants/routes";

import { handleFetch } from "../utils/fetch";
import { performResult } from "../utils/stateManipulator";
import WalletUtils from "../utils/blockchain/WalletUtils";
import GtagUtil from "../utils/gtagUtils";

import history from '../history';
import { reset } from 'redux-form';
import { transformError } from "../utils/transformErrors";

import { API_CREATE_WALLET, API_SIGNUP, API_SIGNUP_CAPTCHA, API_BACKUP_MNEMONIC, API_SIGNUP_ACTIVATION_CHECK } from "../constants/apiRoutes";

const initialState = {
  submitEnabled: false,
  submitLoading: false,
  isActivated: false,
  activationLoading: false,
  serverError: "",

  mnemonicRaw: "",
  mnemonic: ["", "", "", "", "", "", "", "", "", "", "", ""],
  mnemonicConfirm: [],
  enabledIndexes: [],
  walletAddress: "",
  form: {},
};

const CHANGE_CURRENT_STEP = "CHANGE_CURRENT_STEP";
const CHANGE_START_INFO = "CHANGE_START_INFO";
const CHANGE_PASSWORD = "CHANGE_PASSWORD";
const CHANGE_CONFIRM = "CHANGE_CONFIRM";

const REGISTER_SENT = "REGISTER_SENT";
const REGISTER_SUCCESS = "REGISTER_SUCCESS";
const REGISTER_FAILED = "REGISTER_FAILED";

const REGISTER_WALLET_SENT = "REGISTER_WALLET_SENT";
const REGISTER_WALLET_SUCCESS = "REGISTER_WALLET_SUCCESS";
const REGISTER_WALLET_FAILED = "REGISTER_WALLET_FAILED";
const CLEAN_REGISTER_WALLET_ERROR = 'CLEAN_REGISTER_WALLET_ERROR';

const ACTIVATION_CHECK_SENT = "ACTIVATION_CHECK_SENT";
const ACTIVATION_CHECK_SUCCESS = "ACTIVATION_CHECK_SUCCESS";
const ACTIVATION_CHECK_FAILED = "ACTIVATION_CHECK_FAILED"

const GENERATE_MNEMONIC = "GENERATE_MNEMONIC";
const NEXT_STEP_MNEMONIC = "NEXT_STEP_MNEMONIC";
const CHANGE_MNEMONIC_CONFIRM = "CHANGE_MNEMONIC_CONFIRM";

const CREATE_WALLET = "CREATE_WALLET";
const SET_FORM_INFO = "SET_FORM_INFO";

const BACKUP_FAILED = "BACKUP_FAILED";

export const changeCurrentStep = activeStep => ({ type: CHANGE_CURRENT_STEP, activeStep });
export const changeStartInfo = data => ({ type: CHANGE_START_INFO, data });
export const changePassword = password => ({ type: CHANGE_PASSWORD, password });
export const changeConfirm = confirm => ({ type: CHANGE_CONFIRM, confirm });
export const setFormInfo = (props) => ({ type: SET_FORM_INFO, payload: props });

export const generateMnemonic = () => {
  const mnemonicRaw = WalletUtils.generateMnemonic();
  return {
    type: GENERATE_MNEMONIC,
    mnemonicRaw
  }
};

export const setMnemonicRaw = (mnemonicRaw) =>  ({ type: GENERATE_MNEMONIC, mnemonicRaw});

export const enableNextMnemonicStep = (mnemonicConfirm, enabledIndexes) => ({ type: NEXT_STEP_MNEMONIC, mnemonicConfirm, enabledIndexes });
export const changeMnemonicConfirm = (value, index) => ({
  type: CHANGE_MNEMONIC_CONFIRM,
  index,
  value
});

export const handleCreateWallet = wallet => dispatch => {
  dispatch({ type: REGISTER_WALLET_SENT });
  return handleFetch(API_CREATE_WALLET, "POST", { wallet: wallet.address })
    .then(res => performResult(res, () => {
      dispatch({ type: REGISTER_WALLET_SUCCESS });
      dispatch({ type: CREATE_WALLET, wallet });
    }))
    .catch(err => dispatch({ type: REGISTER_WALLET_FAILED, err: transformError(err) }));
};

export const clearOldServerError = () => ({ type: CLEAN_REGISTER_WALLET_ERROR });

export const registerAccount = data => dispatch => {
  dispatch({ type: REGISTER_SENT });

  return handleFetch(API_SIGNUP, "POST", data)
    .then(res => performResult(res, () => {
      dispatch({ type: REGISTER_SUCCESS });
      dispatch(reset('signup-start'));
      dispatch(reset('signup-end'));
      dispatch({ type: CREATE_WALLET, data });
      history.push(ROUTE_CREATE_WALLET);
    }))
    .catch(err => dispatch({ type: REGISTER_FAILED, err: transformError(err) }));
};

export const registerCaptcha = data => dispatch => {
  return handleFetch(API_SIGNUP_CAPTCHA, "POST", { captcha: data.captcha })
    .then(res => performResult(res, () => {
      delete data.captcha;
      const newData = {
        ...data,
        token: res.token,
      };
      dispatch(setFormInfo(newData));
      return res;
    }))
    .catch(err => dispatch({ type: REGISTER_FAILED, err: transformError(err) }));
};

export const backupMnemonic = data => dispatch => {
  return handleFetch(API_BACKUP_MNEMONIC, "POST", { wallet: data.wallet, mnemonic: data.mnemonic })
    .then(res => performResult(res, () => {
      return res;
    }))
    .catch(err => dispatch({ type: BACKUP_FAILED, err: transformError(err) }));
}

export const signup = (state = initialState, action) => {
  let error;
  let newState;

  switch (action.type) {
    case CHANGE_CURRENT_STEP:
      return { ...state, activeStep: action.activeStep };

    case CHANGE_START_INFO:
      error = !ValidationUtil.isValid(action.data.value);
      newState = { ...state, [action.data.name]: { value: action.data.value, error } };

      return {
        ...newState,
        submitEnabled: isReadyToSubmit(newState),
      };

    case CHANGE_PASSWORD:
      error = ValidationUtil.isWeakPassword(action.password);
      newState = { ...state, password: { value: action.password, error, helperText: error ? "Password is weak" : "" } };

      return {
        ...newState,
        submitEnabled: isReadyToSubmit(newState),
      };

    case CHANGE_CONFIRM:
      error = !(state.password.value.toLowerCase() === action.confirm.toLowerCase());
      newState = { ...state, confirm: { value: action.confirm, error, helperText: error ? 'Password not confirmed' : '' } };

      return {
        ...newState,
        submitEnabled: isReadyToSubmit(newState),
      };

    case REGISTER_SENT:
      return { ...state, submitLoading: true }

    case REGISTER_SUCCESS:
      GtagUtil.trackSignup('Email');
      return { ...state, submitLoading: false, serverError: "" }

    case REGISTER_FAILED:
      return { ...state, submitLoading: false, serverError: action.err }

    case REGISTER_WALLET_SENT:
      return { ...state, submitLoading: true }

    case REGISTER_WALLET_SUCCESS:
      return {
        ...state,
        submitLoading: false,
        serverError: "",
      }

    case REGISTER_WALLET_FAILED:
      return {
        ...state,
        submitLoading: false,
        serverError: action.err,
      }

    case CLEAN_REGISTER_WALLET_ERROR:
      return {
        ...state,
        serverError: ""
      }

    case GENERATE_MNEMONIC:
      return {
        ...state,
        mnemonicRaw: action.mnemonicRaw,
        mnemonic: action.mnemonicRaw.split(" "),
        mnemonicConfirm: action.mnemonicRaw.split(" "),
      };

    case NEXT_STEP_MNEMONIC:
      return {
        ...state,
        mnemonicConfirm: action.mnemonicConfirm,
        enabledIndexes: action.enabledIndexes,
      };

    case CHANGE_MNEMONIC_CONFIRM:
      const { mnemonicConfirm } = state;
      mnemonicConfirm[action.index] = action.value;

      return {
        ...state,
        mnemonicConfirm,
      };

    case CREATE_WALLET:
      return {
        ...initialState,
        walletAddress: action.data.wallet,
      };

    case SET_FORM_INFO:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload
        },
      };

    case ACTIVATION_CHECK_SENT:
      return {...state, activationLoading: true, isActivated: false, serverError: '' }

    case ACTIVATION_CHECK_SUCCESS:
      return {...state, activationLoading: false, isActivated: action.data.isActive, serverError: '' }

    case ACTIVATION_CHECK_FAILED:
      return {...state, activationLoading: false, isActivated: false, serverError: action.err }

    default:
      return state;
  }
};

export const checkActivation = wallet => dispatch => {
  dispatch({ type: ACTIVATION_CHECK_SENT });
  return handleFetch(API_SIGNUP_ACTIVATION_CHECK, "POST", { wallet })
    .then(res => performResult(res, () => {
        return dispatch({ type: ACTIVATION_CHECK_SUCCESS, data: res.payload });
    }))
    .catch(err => dispatch({ type: ACTIVATION_CHECK_FAILED, err: transformError(err) }));
}

function isReadyToSubmit(state) {
  let options = {
    notEmpty: ["fullName", "email", "phone", "password", "confirm"],
    isValid: ["fullName", "email", "phone", "password", "confirm"]
  };

  return ValidationUtil.isReadyToSubmit(state, options);
}