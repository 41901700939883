import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunkMiddleware from "redux-thunk";
import {reducer as formReducer} from 'redux-form';
import {signup} from "./signup";
import {signin} from "./signin";
import {dashboard} from "./dashboard";
import {blockchain} from "./blockchain";
import {blockchainGas} from "./blockchainGas";
import {iod} from "./iod";
import {in4x} from "./in4x";
import {staking} from "./staking";
import {addresses} from "./addresses";
import {walletConnect} from "./walletConnect";
import {FormData} from './FormData';

const reducer = combineReducers({
    signup,
    signin,
    dashboard,
    blockchain,
    blockchainGas,
    iod,
    in4x,
    staking,
    addresses,
    walletConnect,
    FormData,
    form: formReducer
});

export function initializeStore(initialState = {}, options = {}) {
    const enhancers = [];
    const middleware = [thunkMiddleware];
    if (!options.isServer && process.env.NODE_ENV === 'development') {
        const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

        if (typeof devToolsExtension === 'function') {
            enhancers.push(devToolsExtension())
        }
    }
    const composedEnhancers = compose(
        applyMiddleware(...middleware),
        ...enhancers
    );
    return createStore(reducer, initialState, composedEnhancers);
}